// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiKey,
    ApiKeyFromJSON,
    ApiKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetApiKeys200Response
 */
export interface GetApiKeys200Response  {
    /**
     * 
     * @type {number}
     * @memberof GetApiKeys200Response
     */
    count?: number;
    /**
     * 
     * @type {Array<ApiKey>}
     * @memberof GetApiKeys200Response
     */
    records?: Array<ApiKey>;
}

export function GetApiKeys200ResponseFromJSON(json: any): GetApiKeys200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(ApiKeyFromJSON),
    };
}

export function GetApiKeys200ResponseToJSON(value?: GetApiKeys200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(ApiKeyToJSON),
    };
}


