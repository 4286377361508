import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { getClientRoutes } from 'features/clients/clientRoutes'
import { getActiveClient } from 'app/selectors'
import { ExternalLink } from 'components/ExternalLink'

export const AppMenu = () => {
  const activeClient = useSelector(getActiveClient)
  const routes = getClientRoutes(activeClient)
  const location = useLocation()

  const isActive = (menuItemPath) => {
    return location.pathname.includes(menuItemPath)
  }

  const hideLeftMenu = () => {
    if (document.body && document.body.classList.contains('sidebar-enable')) {
      document.body.classList.remove('sidebar-enable')
    }
  }

  const MenuItem = ({ item, className, linkClassName }) => {
    let icon
    if (item.icon) {
      icon = item.icon.startsWith('mdi') ? (
        <i className={item.icon}></i>
      ) : (
        <img src={item.icon} height={19} className="mr-2" alt="" />
      )
    }
    return (
      <li className={classNames('side-nav-item', className)}>
        <Link
          to={item.path}
          className={classNames('side-nav-link-ref', 'side-sub-nav-link', linkClassName)}
          onClick={hideLeftMenu}
        >
          {icon}
          {item.badge && <span className={`badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>}
          <span>{item.name}</span>
        </Link>
      </li>
    )
  }

  return (
    <ul className="metismenu side-nav" id="menu-bar">
      {routes
        .filter((route) => !route.noMenu)
        .map((route, index) => {
          if (route.path === 'divider') {
            return (
              <hr
                key={index}
                style={{
                  borderTop: '2px solid #2e3f51',
                  marginTop: '0.7rem',
                  marginBottom: '0.7rem',
                }}
              />
            )
          }
          return (
            <MenuItem
              key={index}
              item={{ path: route.path, name: route.name, icon: route.icon }}
              className={classNames({ 'mm-active': isActive(route.path) })}
              linkClassName="side-nav-link"
            />
          )
        })}
      <li className={classNames('side-nav-item')}>
        <ExternalLink
          to="https://support.rotessa.com/"
          className={classNames('side-nav-link-ref', 'side-sub-nav-link', 'side-nav-link')}
          onClick={hideLeftMenu}
        >
          <i className="mdi mdi-help-circle-outline" />
          <span>Support</span>
        </ExternalLink>
      </li>
    </ul>
  )
}
