import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'

import { Row, Col, CardBody, Card } from 'reactstrap'
import { CustomerActivity } from 'features/customers/customerActivity/customerActivity'
import { TransactionSchedulesTable } from 'features/customers/transactionSchedules/transactionSchedulesTable'
import { TransactionHistoryTable } from 'features/customers/transactionHistory/transactionHistoryTable'
import { CustomerNotesTable } from 'features/customers/customerNotes/customerNotesTable'
import { customerQuery } from 'app/queries'
import { getActiveClient, getCustomer } from 'app/selectors'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { useSetBreadcrumbTitle } from 'hooks/useSetBreadcrumbTitle'
import { CustomerBankAndAuthDetails } from './customerBankAndAuthDetails'
import { CustomerContactDetails } from './customerContactDetails'
import { CustomerIcons } from './customerIcons'
import { CustomerIntegrationDetails } from './customerIntegrationDetails'
import { performCustomerAction } from 'api'
import { ActionPromiseValue } from 'redux-query'
import { FormServerNotifications } from 'components/FormServerNotifications'
import { CustomerNotificationSettings } from './customerNotificationSettings'
import { FormServerErrors } from 'components/FormServerErrors'
import { isReleased } from 'utilities'

export interface CustomerDashboardProps {
  customerId?: number
}

export const CustomerDashboard = ({ customerId }: CustomerDashboardProps) => {
  const customer = useSelector(getCustomer) || {}
  const activeClient = useSelector(getActiveClient) || {}

  const [errors, setErrors] = useState<string[]>([])
  const [notifications, setNotifications] = useState<string[]>([])

  const [mutationState, doMutation] = useMutation((action: string) =>
    performCustomerAction({
      customerAction: {
        ids: [customer.id],
        name: action,
      },
    }),
  )

  const [requestState, doRequest] = useRequest(customerQuery(true, customerId))

  const doAction = (action: string, onResponse?: (response: ActionPromiseValue<unknown>) => void) => {
    doMutation(action)?.then((response) => {
      if (onResponse) {
        onResponse(response)
      }
      setNotifications(response?.body?.messages?.notifications || [])
      setErrors(response?.body?.messages?.errors || [])
      doRequest()
    })
  }

  useSetBreadcrumbTitle(customerId?.toString(), customer.name)
  useClearEntitiesOnUnmount(['customer'])

  return (
    <React.Fragment>
      <FormServerErrors errors={errors} />
      <FormServerNotifications className="mb-0 mt-3" notifications={notifications} />
      <Row>
        <Col xs="auto">
          <div className="page-title-box">
            <h4 className="page-title">{customer?.name}</h4>
          </div>
        </Col>
        <Col className="d-flex align-items-center">
          <CustomerIcons statuses={customer.statuses} customer={customer || '0'} className="dashboard-icon" />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col lg={8}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <TransactionSchedulesTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <TransactionHistoryTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CustomerNotesTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col lg={4}>
          <>
            <Row>
              <Col>
                <CustomerContactDetails />
              </Col>
            </Row>
            {activeClient.integration && customer.apiService && (
              <Row>
                <Col>
                  <CustomerIntegrationDetails doAction={doAction} />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <CustomerBankAndAuthDetails />
              </Col>
            </Row>
          </>
          {isReleased('customer_notifications_released') && (
            <Row>
              <Col>
                <CustomerNotificationSettings doAction={doAction} />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <CustomerActivity />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
