import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import flinksRed from 'assets/images/customer_dashboard_icons/Flinks-red.png'
import flinksYellow from 'assets/images/customer_dashboard_icons/Flinks-yellow.png'
import microdepositRed from 'assets/images/customer_dashboard_icons/Microdeposit-red.png'
import microdepositYellow from 'assets/images/customer_dashboard_icons/Microdeposit-yellow.png'
import padGreen from 'assets/images/customer_dashboard_icons/PAD-green.png'
import padYellow from 'assets/images/customer_dashboard_icons/PAD-yellow.png'
import qboAutoImportInvoices from 'assets/images/customer_dashboard_icons/QBO-auto-import-invoices.png'
import qboOfflineAutoImportInvoices from 'assets/images/customer_dashboard_icons/QBO-offline-auto-import-invoices.png'
import qboOffline from 'assets/images/customer_dashboard_icons/QBO-offline.png'
import qbo from 'assets/images/customer_dashboard_icons/QBO.png'
import verifiedGreen from 'assets/images/customer_dashboard_icons/Verified-green.png'
import xeroAutoImportInvoices from 'assets/images/customer_dashboard_icons/Xero-auto-import-invoices.png'
import xeroOfflineAutoImportInvoices from 'assets/images/customer_dashboard_icons/Xero-offline-auto-import-invoices.png'
import xeroOffline from 'assets/images/customer_dashboard_icons/Xero-offline.png'
import xero from 'assets/images/customer_dashboard_icons/Xero.png'
import api from 'assets/images/customer_dashboard_icons/API.png'
import archived from 'assets/images/customer_dashboard_icons/Archived.png'
import notificationEnabled from 'assets/images/customer_dashboard_icons/Bell-Notifications-On.png'
import notificationIncomplete from 'assets/images/customer_dashboard_icons/Bell-Notifications-Warning.png'
import notificationUnsubscribed from 'assets/images/customer_dashboard_icons/Bell-Notifications-Grey.png'
import { Customer } from 'api'
import { humanize_string } from 'utilities'

export interface CustomerIconsProps {
  statuses: object
  customer: Customer
  className?: string
}

export const CustomerIcons = ({ statuses, customer, className }: CustomerIconsProps) => {
  const icons: string[] = []

  if (statuses) {
    icons.push(statuses['verified'] ? 'verified' : '')
    icons.push(statuses['authType'])
    icons.push(statuses['mdVerification'])
    icons.push(statuses['flinksVerification'])
    icons.push(statuses['integration'])
    icons.push(statuses['authRequested'] ? 'auth_requested' : '')
    icons.push(statuses['archived'] ? 'archived' : '')
    icons.push(statuses['api'] ? 'api' : '')
    let notificationsIcon = ''
    if (
      Object.values(statuses['notifications'] || {})?.some(
        (setting_status) => setting_status == 'enabled' || setting_status == 'incomplete',
      )
    ) {
      if (Object.values(statuses['notifications'] || {})?.some((setting_status) => setting_status == 'incomplete')) {
        notificationsIcon = 'notification_incomplete'
      } else {
        notificationsIcon = 'notification_enabled'
      }
    } else if (
      Object.values(statuses['notifications'] || {})?.some((setting_status) => setting_status == 'unsubscribed')
    ) {
      notificationsIcon = 'notification_unsubscribed'
    }
    icons.push(notificationsIcon)
  }

  let notification_enabled_text =
    humanize_string(
      Object.entries((statuses && statuses['notifications']) || {})
        ?.filter(([_setting_key, setting_status]) => setting_status === 'enabled')
        ?.map((i) => i[0])
        .join(' and '),
    ) || ''

  let notification_unsubscribed_text =
    humanize_string(
      Object.entries((statuses && statuses['notifications']) || {})
        ?.filter(([_setting_key, setting_status]) => setting_status === 'unsubscribed')
        ?.map((i) => i[0])
        .join(' and '),
    ) || ''
  if (notification_unsubscribed_text) {
    notification_unsubscribed_text += ' notifications are unsubscribed.'
  }
  if (notification_enabled_text) {
    notification_enabled_text += ' notifications are enabled. '
    notification_enabled_text += notification_unsubscribed_text
  }
  const iconFiles = {
    in_person: [padGreen, 'Authorization completed - In Person'],
    online: [padGreen, 'Authorization completed - Online'],
    md_failed_inactive: [microdepositRed, 'Microdeposit failed'],
    md_failed_declined: [microdepositRed, 'Microdeposit failed'],
    md_pending: [microdepositYellow, 'Microdeposit verification pending'],
    verified: [
      verifiedGreen,
      `Verified - ${
        customer.bankVerification === 'Microdeposit Verified' ? 'Microdeposit' : 'Instant Bank Verification'
      }`,
    ],
    flinks_failed: [flinksRed, 'Instant Bank Verification failed'],
    flinks_pending: [flinksYellow, 'Instant Bank Verification pending'],
    quickbooks_auto_import_invoices_and_auto_update_on: [
      qboAutoImportInvoices,
      'Customer created with QuickBooks Online - automatic invoice imports and automatic update on',
    ],
    quickbooks_auto_update_on: [qboAutoImportInvoices, 'Customer created with QuickBooks Online - automatic update on'],
    quickbooks_auto_import_invoices_on: [
      qboAutoImportInvoices,
      'Customer created with QuickBooks Online - automatic invoice imports on',
    ],
    quickbooks_auto_import_invoices_off: [qbo, 'Customer created with QuickBooks Online'],
    quickbooks_disconnected_auto_import_invoices_and_auto_update_on: [
      qboOfflineAutoImportInvoices,
      'Customer created with QuickBooks Online - automatic invoice imports and automatic update disconnected',
    ],
    quickbooks_disconnected_auto_update_on: [
      qboOfflineAutoImportInvoices,
      'Customer created with QuickBooks Online - automatic update disconnected',
    ],
    quickbooks_disconnected_auto_import_invoices: [
      qboOfflineAutoImportInvoices,
      'Customer created with QuickBooks Online - automatic invoice imports disconnected',
    ],
    quickbooks_disconnected: [qboOffline, 'QuickBooks Online disconnected'],
    xero_auto_import_invoices_and_auto_update_on: [
      xeroAutoImportInvoices,
      'Customer created with Xero - automatic invoice imports and automatic update on',
    ],
    xero_auto_update_on: [xeroAutoImportInvoices, 'Customer created with Xero - automatic update on'],
    xero_auto_import_invoices_on: [xeroAutoImportInvoices, 'Customer created with Xero - automatic invoice imports on'],
    xero_auto_import_invoices_off: [xero, 'Customer created with Xero'],
    xero_disconnected_auto_import_invoices_and_auto_update_on: [
      xeroOfflineAutoImportInvoices,
      'Customer created with Xero - automatic invoice imports and automatic update disconnected',
    ],
    xero_disconnected_auto_update_on: [
      xeroOfflineAutoImportInvoices,
      'Customer created with Xero - automatic update disconnected',
    ],
    xero_disconnected_auto_import_invoices: [
      xeroOfflineAutoImportInvoices,
      'Customer created with Xero - automatic invoice imports disconnected',
    ],
    xero_disconnected: [xeroOffline, 'Xero disconnected'],
    auth_requested: [padYellow, 'Authorization request pending'],

    api: [api, 'API'],
    archived: [archived, 'Archived'],
    notification_incomplete: [notificationIncomplete, 'Missing contact info, notifications will not be sent'],
    notification_enabled: [notificationEnabled, notification_enabled_text],
    notification_unsubscribed: [notificationUnsubscribed, notification_unsubscribed_text],
  }
  return (
    <>
      {(icons || []).map(
        (s, i) =>
          iconFiles[s] && (
            <span key={i}>
              <img id={s + customer.id} src={iconFiles[s][0]} className={className} alt="" />
              <UncontrolledTooltip style={{ maxWidth: '500px' }} placement="bottom" target={s + customer.id}>
                {iconFiles[s][1]}
              </UncontrolledTooltip>
            </span>
          ),
      )}
    </>
  )
}
