import { Card, CardImg, Fade } from 'reactstrap'

import notificationImage from 'assets/images/Rotessa-Sidebar-Icon-Notifications-Update.png'
import { useSelector } from 'react-redux'
import { getActiveClient } from 'app/selectors'
import React from 'react'
import { Link } from 'react-router-dom'

export const Announcement = () => {
  const activeClient = useSelector(getActiveClient)

  if (!activeClient) return null

  return (
    <Fade>
      <Card className="menu-wizard">
        <CardImg src={notificationImage} height={100} style={{ objectFit: 'contain', objectPosition: 'left top' }} />
        <h4>Reminders and Receipts</h4>
        <p>
          Now you can effortlessly notify your customers about their upcoming payments and deliver receipts for
          confirmation.
        </p>
        <Link
          color="secondary"
          className="btn btn-rotessa-secondary white"
          to="/client/settings/notification_settings/"
        >
          Enable Now
        </Link>
      </Card>
    </Fade>
  )
}
