// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetReleaseHelpers200Response
 */
export interface GetReleaseHelpers200Response  {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof GetReleaseHelpers200Response
     */
    releaseHelpers?: { [key: string]: boolean; };
}

export function GetReleaseHelpers200ResponseFromJSON(json: any): GetReleaseHelpers200Response {
    return {
        'releaseHelpers': !exists(json, 'release_helpers') ? undefined : json['release_helpers'],
    };
}

export function GetReleaseHelpers200ResponseToJSON(value?: GetReleaseHelpers200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'release_helpers': value.releaseHelpers,
    };
}


