import { getActiveClient, getEntities } from 'app/selectors'
import { CenterContentMedium } from 'components/CenterContent'
import { ExternalLink } from 'components/ExternalLink'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap'
import { authUrl, isPermitted } from 'utilities'
import copy from 'clipboard-copy'
import { getAuthorizationLinkSettings } from 'api'
import { getDonorAuthSettingsConfig } from 'features/auth_settings/donor/donorAuthSettingsManager'
import { getProcessingAuthSettingsConfig } from 'features/auth_settings/processing/processingAuthSettingsManager'
import { useRequest } from 'redux-query-react'
import emailAuthImage from 'assets/images/authorizations/Email-Authorization.png'
import authLinkImage from 'assets/images/authorizations/Link-Authorization.png'
import pdfAuthImage from 'assets/images/authorizations/Paper-Authorization.png'
import { useIsMobile } from 'hooks/useIsMobile'

export const AuthorizationPage = () => {
  const activeClient = useSelector(getActiveClient)
  const isMobile = useIsMobile()

  let authType = ''
  if (activeClient?.clientType === 'Donation') {
    authType = 'donation'
  } else if (activeClient?.newAuthSettingsEnabled) {
    authType = 'processing'
  } else {
    authType = 'tuition'
  }

  const getAuthSettingsConfigForType = (clientId: number) => {
    if (authType === 'processing') {
      return getProcessingAuthSettingsConfig(clientId)
    }
    if (authType === 'donation') {
      return getDonorAuthSettingsConfig(clientId)
    }
    if (authType === 'tuition') {
      return getAuthorizationLinkSettings(
        {
          clientId: clientId,
        },
        {
          force: true,
          transform: (body) => {
            return { authorizationLinkSetting: body }
          },
          update: {
            authorizationLinkSetting: (_, newValue) => newValue,
          },
        },
      )
    }
  }
  const [requestState, _doRequest] = useRequest(activeClient && getAuthSettingsConfigForType(activeClient?.id))

  const authLinkCode = {
    processing: useSelector((state) => getEntities(state).processingAuthSetting)?.code,
    donation: useSelector((state) => getEntities(state).donorAuthSetting)?.code,
    tuition: useSelector((state) => getEntities(state).authorizationLinkSetting)?.formUrl,
  }[authType]
  const publicLinkUrl = authUrl(authLinkCode, authType)

  const authTemplateLink =
    activeClient?.countryCode === 'US'
      ? 'https://rotessa.com/resources/ach-form-generator/'
      : 'https://rotessa.com/resources/pad-form-generator/'

  return (
    <>
      <CenterContentMedium>
        <div className="page-title-box">
          <h4 className="page-title page-title-wrap">Three ways to collect your customer’s authorization</h4>
        </div>
        <Card>
          <CardBody>
            <Row>
              <Col xs={isMobile ? 12 : 9} className="d-flex flex-column">
                <h3>Email customers a private link to your authorization form</h3>
                <p>
                  <span className="badge bg-info-lighten text-info p-1 px-2 text-wrap">
                    <i>Recommended for QuickBooks Online and Xero users</i>
                  </span>
                </p>
                <p className="mb-2">
                  Send one or multiple customers an authorization form through email. When a customer completes the
                  form, their details in Rotessa will be updated.
                </p>
                <div className="mt-auto">
                  <Link to="/client/email_customer_authorization_select">
                    <Button color="primary" className={`btn no-wrap px-4${isMobile ? ' w-100' : ''}`}>
                      Send Email Authorizations
                    </Button>
                  </Link>
                  {isPermitted('authorization_settings.edit_authorization_settings', activeClient) && (
                    <Link
                      to="/client/authorizations/settings#email"
                      className={isMobile ? 'd-flex justify-content-center' : ''}
                    >
                      <Button color="link" className="btn no-wrap">
                        <i className="mdi mdi-pencil mx-1" />
                        Edit and Preview
                      </Button>
                    </Link>
                  )}
                </div>
              </Col>
              {isMobile ? null : (
                <Col xs={3} className="card-image-right">
                  <img src={emailAuthImage} alt="alt" />
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Row>
              <Col xs={isMobile ? 12 : 9} className="d-flex flex-column">
                <h3>Share a public link to your authorization form</h3>
                <p className="mb-2">
                  Your Rotessa account has a customizable and secure authorization form link. Share it publicly on a
                  website or in an onboarding process.
                </p>
                <Row noGutters className="mt-auto">
                  <Col sm={7}>
                    <InputGroup>
                      <Input readOnly value={publicLinkUrl} />
                      <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={() => copy(publicLinkUrl)}>
                          Copy
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col sm={5}>
                    {isPermitted('authorization_settings.edit_authorization_settings', activeClient) && (
                      <Link
                        to="/client/authorizations/settings#link"
                        className={isMobile ? 'd-flex justify-content-center' : ''}
                      >
                        <Button color="link" className="btn no-wrap">
                          <i className="mdi mdi-pencil mx-1" />
                          Edit and Preview
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </Col>
              {isMobile ? null : (
                <Col xs={3} className="card-image-right">
                  <img src={authLinkImage} alt="alt" />
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Row>
              <Col xs={isMobile ? 12 : 9} className="d-flex flex-column">
                <h3>Create and print your authorization form</h3>
                <p className="mb-2">
                  Create and print an authorization form with our PDF generator for in-person authorization.
                </p>
                <ExternalLink to={authTemplateLink} className="mt-auto">
                  <Button color="primary" className={`px-4${isMobile ? ' w-100' : ''}`}>
                    Create PDF Authorization
                  </Button>
                </ExternalLink>
              </Col>
              {isMobile ? null : (
                <Col xs={3} className="card-image-right ">
                  <img src={pdfAuthImage} alt="alt" />
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </CenterContentMedium>
    </>
  )
}
