import React, { useState } from 'react'

import { AvForm } from 'availity-reactstrap-validation'
import { Button, Col, Row } from 'reactstrap'
import { BankAccountFields } from 'components/BankAccountFields'
import { FlinksConnect } from 'features/flinks/FlinksConnect'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getEntities } from 'app/selectors'
import { useWindowSize } from 'hooks/useWindowSize'
import flinksPreview from 'assets/images/flinks_preview.png'
import { InfoPopover } from 'components/InfoPopover'
import instantVerificationIcon from 'assets/images/Rotessa-instant-bank-verification.png'
import { ProcessingAuthHeader } from './processingAuthHeader'
import { ExternalLink } from 'components/ExternalLink'
import { useTranslation, Trans } from 'react-i18next'
import { Errors } from 'hooks/useFormServerErrors'

export interface AuthBankInfoProps {
  readOnly?: boolean
  onSubmit: Function
  validate: Function
  handleFieldChange: Function
  serverErrors?: Errors
}

export const ProcessingAuthBankInfo = (props: AuthBankInfoProps) => {
  const windowSize = useWindowSize()
  const processingAuthInfo = useSelector((state) => getEntities(state).processingAuthInfo)
  const [doFlinks, setDoFlinks] = useState(() => processingAuthInfo?.step?.enable_flinks)
  const [flinksAccepted, setFlinksAccepted] = useState(false)
  const [flinksComplete, setFlinksComplete] = useState(false)
  const { t } = useTranslation()
  const [altFlowQuestionKey, setAltFlowQuestionKey] = useState('flinks.alt_flow_connect_question')

  const onComplete = () => {
    setFlinksComplete(true)
    props.onSubmit()
  }

  if (flinksComplete) {
    return null
  }

  if (doFlinks && processingAuthInfo?.step?.enable_flinks) {
    if (!flinksAccepted) {
      return (
        <>
          <div className="text-center">
            <img src={instantVerificationIcon} height={100} className="mt-3 mb-2" alt="" />
            <p style={{ fontSize: 24 }}>
              <Trans i18nKey="flinks.header">
                We use <b>Flinks</b> to instantly verify your bank information
              </Trans>
            </p>
          </div>
          <Row className="ml-2 mr-2 mb-2">
            <Col xs="auto" className="p-0">
              <i className="mdi mdi-lock mdi-24px" />
            </Col>
            <Col>
              <span>
                <b>{t('flinks.secure')}</b>
                <br />
                {t('flinks.secure_text')}
              </span>
            </Col>
          </Row>
          <Row className="ml-2 mr-2 mb-2">
            <Col xs="auto" className="p-0">
              <i className="mdi mdi-eye-off mdi-24px" />
            </Col>
            <Col>
              <span>
                <b>{t('flinks.private')}</b>
                <br />
                {t('flinks.private_text')}
              </span>
            </Col>
          </Row>
          <div className="text-center">
            <p style={{ lineHeight: '100%', color: '#bbb' }}>
              <small>
                {t('flinks.selecting')}
                <br />
                <ExternalLink to={t('flinks.agree_link')}>{t('flinks.agree_link_text')}</ExternalLink>
              </small>
              <br />
            </p>
            <Button className="mb-2 w-100" color="primary" onClick={() => setFlinksAccepted(true)}>
              {t('continue')}
            </Button>
            <br />
            {t('flinks.manual_or')}{' '}
            <Link to={window.location} onClick={() => setDoFlinks(false)}>
              {' '}
              <u>{t('flinks.manual_link')}</u>
            </Link>
          </div>
        </>
      )
    }
    return (
      <>
        <ProcessingAuthHeader />
        {props.readOnly ? (
          <img src={flinksPreview} width="100%" className="mb-2" alt="" />
        ) : (
          <FlinksConnect
            countryCode={processingAuthInfo?.client?.countryCode}
            tag={processingAuthInfo?.id}
            height={Math.max(500, windowSize.height - 700)}
            onStep={(step) => step === 'INSTITUTION_SELECTED' && setAltFlowQuestionKey('flinks.alt_flow_question')}
            onComplete={onComplete}
            type="Processing"
          />
        )}
        <p className="text-center">
          {t(altFlowQuestionKey)}
          <Link to={window.location} onClick={() => setDoFlinks(false)}>
            {' '}
            <u>{t('flinks.alt_flow')}</u>
          </Link>
        </p>
      </>
    )
  }

  return (
    <AvForm onValidSubmit={(_event, values) => props.onSubmit(values)} className="gray-input-background">
      <ProcessingAuthHeader />
      <h4 className="header-title">
        {t('authorization.processing.manual_bank_info.header')}
        {processingAuthInfo?.step?.require_verification && (
          <InfoPopover popoverText={t('authorization.processing.manual_bank_info.header_info')} />
        )}
      </h4>
      <BankAccountFields
        countryCode={processingAuthInfo?.client?.countryCode}
        required={true}
        serverErrors={props.serverErrors}
        fieldNamePrefix="bankAccount"
        threeRows
      />

      <div className="text-center">
        <Button className="mb-2 w-100" color="primary">
          {t('continue')}
        </Button>
        {processingAuthInfo?.step?.enable_flinks && (
          <>
            <br />
            {t('authorization.processing.manual_bank_info.instant_bank_or')}{' '}
            <Link to={window.location} onClick={() => setDoFlinks(true)}>
              <u>{t('authorization.processing.manual_bank_info.instant_bank_link')}</u>
            </Link>
          </>
        )}
      </div>
    </AvForm>
  )
}
