import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useRequest } from 'redux-query-react'
import { RootState } from 'app/rootReducer'
import NumberFormat from 'react-number-format'
import { Card, CardBody, Badge, Row, Col } from 'reactstrap'
import { getSettlements } from 'api'
import { formatDateGreg, getSort } from 'utilities'
import { getActiveClient, getEntities } from 'app/selectors'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { OnTableChangeProps } from 'components/Table'
import { Table } from 'components/Table'

const CountCurrencyFormatter = ({ amount, count }, row) => {
  if (amount === '0.00' && count === 0) {
    return (
      <span>
        <Badge pill className="mr-2 count-badge badge badge-outline-medium">
          {count}
        </Badge>
        <span className="text-medium" style={{ verticalAlign: 'middle' }}>
          <NumberFormat
            value={amount}
            displayType={'text'}
            fixedDecimalScale={true}
            decimalScale={2}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <span>{value}</span>}
          />
        </span>
      </span>
    )
  } else {
    return (
      <span>
        <Badge pill className="mr-2 count-badge badge badge-outline-secondary">
          {count}
        </Badge>
        <span style={{ verticalAlign: 'middle' }}>
          <NumberFormat
            value={amount}
            displayType={'text'}
            fixedDecimalScale={true}
            decimalScale={2}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <span>{value}</span>}
          />
        </span>
      </span>
    )
  }
}

const SettlementLinkFormatter = (cell, row) => {
  return (
    <>
      <Link to={`/client/settlements/${row.id}`}>{formatDateGreg(cell)}</Link>
      {row.holdback && (
        <Badge pill color="danger" className="ml-1">
          Heldback
        </Badge>
      )}
    </>
  )
}

export const Settlements = () => {
  const activeClient = useSelector(getActiveClient)
  const settlements = useSelector((state: RootState) => getEntities(state).settlements) || []
  const settlementsCount = useSelector((state: RootState) => getEntities(state).settlementsCount) || 0
  const history = useHistory()
  const columns = [
    {
      dataField: 'settlementDate',
      text: 'Settlement Date',
      formatter: SettlementLinkFormatter,
      sort: true,
    },
    {
      dataField: 'total',
      text: 'Total',
      formatter: CountCurrencyFormatter,
      sort: false,
    },
    {
      dataField: 'approved',
      text: 'Approved',
      formatter: CountCurrencyFormatter,
      sort: false,
    },
    {
      dataField: 'chargeback',
      text: 'Chargeback',
      formatter: CountCurrencyFormatter,
      sort: false,
    },
    {
      dataField: 'declined',
      text: 'Declined',
      formatter: CountCurrencyFormatter,
      sort: false,
    },
  ]

  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 25,
    sortField: 'settlementDate',
    sortOrder: 'desc',
  })

  useClearEntitiesOnUnmount(['settlements', 'settlementsCount'])

  const [requestState, doRequest] = useRequest(
    !activeClient?.id
      ? null
      : getSettlements(
          {
            clientId: activeClient.id,
            sort: getSort(tableProps.sortField, tableProps.sortOrder),
            skip: (tableProps.page - 1) * tableProps.pageSize,
            limit: tableProps.pageSize,
          },
          {
            force: true,
            transform: (response) => {
              return {
                settlements: response.records,
                settlementsCount: response.count,
              }
            },
            update: {
              settlements: (oldValue, newValue) => newValue,
              settlementsCount: (oldValue, newValue) => newValue,
            },
          },
        ),
  )

  const onTableChange = (_type: string, props: OnTableChangeProps) => {
    setTableProps({
      page: props.page || tableProps.page,
      pageSize: props.sizePerPage || tableProps.pageSize,
      sortField: props.sortField || tableProps.sortField,
      sortOrder: props.sortOrder || tableProps.sortOrder,
    })
  }

  const onRowClick = (e, row, rowIndex) => {
    history.push(`/client/settlements/${row.id}`)
  }

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Settlements</h4>
            <p>
              A settlement report will reflect the amount of money Rotessa sends you based on approved transactions.
              Settlements are processed within 5 business days of the process date (allowing enough time for declines to
              be reported).
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Table
                loading={requestState.isPending}
                data={settlements}
                onTableChange={onTableChange}
                columns={columns}
                paginationProps={{
                  page: tableProps.page,
                  totalSize: settlementsCount,
                  sizePerPage: tableProps.pageSize,
                  fillHeight: true,
                }}
                sortField="settlementDate"
                sortOrder="desc"
                onRowClick={onRowClick}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
