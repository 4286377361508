// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntegrationCustomer,
    IntegrationCustomerFromJSON,
    IntegrationCustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetIntegrationCustomer200Response
 */
export interface GetIntegrationCustomer200Response  {
    /**
     * 
     * @type {IntegrationCustomer}
     * @memberof GetIntegrationCustomer200Response
     */
    integrationCustomer?: IntegrationCustomer;
}

export function GetIntegrationCustomer200ResponseFromJSON(json: any): GetIntegrationCustomer200Response {
    return {
        'integrationCustomer': !exists(json, 'integration_customer') ? undefined : IntegrationCustomerFromJSON(json['integration_customer']),
    };
}

export function GetIntegrationCustomer200ResponseToJSON(value?: GetIntegrationCustomer200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'integration_customer': IntegrationCustomerToJSON(value.integrationCustomer),
    };
}


