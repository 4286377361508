import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, Row, Col, Fade, CardGroup } from 'reactstrap'

import directEmail from 'assets/images/customerFlow/Direct_email_pad.png'
import link from 'assets/images/customerFlow/Link_pad.png'
import paper from 'assets/images/customerFlow/Paper_pad.png'
import { ExternalLink } from 'components/ExternalLink'
import { TitleBar } from './titleBar'
import { CenterContent } from 'components/CenterContent'

export const CustomerAuthorizationCollect = () => {
  return (
    <Fade>
      <TitleBar title="How would you like to collect the authorization agreement?" />
      <CenterContent>
        <Row>
          <Col m>
            <CardGroup className="justify-content-center">
              <Card className="m-2 customer-flow-card">
                <Link to="/client/customers/new/authorization-email">
                  <CardHeader className="text-center">
                    <img src={directEmail} height={100} className="mt-1 mb-1" alt="Collect by direct email" />
                  </CardHeader>
                  <CardBody className="text-center">
                    <p className="card-label">Direct Email</p>
                  </CardBody>
                </Link>
              </Card>
              <Card className="m-2 customer-flow-card">
                <Link to="/client/customers/new/public-link">
                  <CardHeader className="text-center">
                    <img src={link} height={100} className="mt-1 mb-1" alt="Collect by link" />
                  </CardHeader>
                  <CardBody className="text-center">
                    <p className="card-label">Public Link</p>
                  </CardBody>
                </Link>
              </Card>
              <Card className="m-2 customer-flow-card">
                <ExternalLink to="https://rotessa.com/resources/pad-form-generator/">
                  <CardHeader className="text-center">
                    <img src={paper} height={100} className="mt-1 mb-1" alt="Collect by paper" />
                  </CardHeader>
                  <CardBody className="text-center">
                    <p className="card-label">Paper</p>
                  </CardBody>
                </ExternalLink>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </CenterContent>
    </Fade>
  )
}
