// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProcessingAuthSetting
 */
export interface ProcessingAuthSetting  {
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    flinksOptedIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    enableFlinks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    enableIntroduction?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    allowTransactionSchedule?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    requireVerification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    requireAddress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    requirePhone?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessingAuthSetting
     */
    clientId: number;
    /**
     * 
     * @type {Date}
     * @memberof ProcessingAuthSetting
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProcessingAuthSetting
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    requireAuthorizationAmount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    verificationMethod?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    displayClientAddress?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    introduction: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    termsAndConditions: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    emailMessage: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    phone?: string;
    /**
     * 
     * @type {Address}
     * @memberof ProcessingAuthSetting
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    nameDefault?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingAuthSetting
     */
    enableGreeting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessingAuthSetting
     */
    defaultLanguage?: string;
}

export function ProcessingAuthSettingFromJSON(json: any): ProcessingAuthSetting {
    return {
        'flinksOptedIn': !exists(json, 'flinks_opted_in') ? undefined : json['flinks_opted_in'],
        'enableFlinks': !exists(json, 'enable_flinks') ? undefined : json['enable_flinks'],
        'enableIntroduction': !exists(json, 'enable_introduction') ? undefined : json['enable_introduction'],
        'allowTransactionSchedule': !exists(json, 'allow_transaction_schedule') ? undefined : json['allow_transaction_schedule'],
        'requireVerification': !exists(json, 'require_verification') ? undefined : json['require_verification'],
        'requireAddress': !exists(json, 'require_address') ? undefined : json['require_address'],
        'requirePhone': !exists(json, 'require_phone') ? undefined : json['require_phone'],
        'code': json['code'],
        'clientId': json['client_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'requireAuthorizationAmount': !exists(json, 'require_authorization_amount') ? undefined : json['require_authorization_amount'],
        'verificationMethod': !exists(json, 'verification_method') ? undefined : json['verification_method'],
        'displayClientAddress': !exists(json, 'display_client_address') ? undefined : json['display_client_address'],
        'introduction': json['introduction'],
        'termsAndConditions': json['terms_and_conditions'],
        'emailMessage': json['email_message'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'nameDefault': !exists(json, 'name_default') ? undefined : json['name_default'],
        'enableGreeting': !exists(json, 'enable_greeting') ? undefined : json['enable_greeting'],
        'defaultLanguage': !exists(json, 'default_language') ? undefined : json['default_language'],
    };
}

export function ProcessingAuthSettingToJSON(value?: ProcessingAuthSetting): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'flinks_opted_in': value.flinksOptedIn,
        'enable_flinks': value.enableFlinks,
        'enable_introduction': value.enableIntroduction,
        'allow_transaction_schedule': value.allowTransactionSchedule,
        'require_verification': value.requireVerification,
        'require_address': value.requireAddress,
        'require_phone': value.requirePhone,
        'code': value.code,
        'client_id': value.clientId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'require_authorization_amount': value.requireAuthorizationAmount,
        'verification_method': value.verificationMethod,
        'display_client_address': value.displayClientAddress,
        'introduction': value.introduction,
        'terms_and_conditions': value.termsAndConditions,
        'email_message': value.emailMessage,
        'email': value.email,
        'phone': value.phone,
        'address': AddressToJSON(value.address),
        'name_default': value.nameDefault,
        'enable_greeting': value.enableGreeting,
        'default_language': value.defaultLanguage,
    };
}


