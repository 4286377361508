import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import { RootState } from 'app/rootReducer'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap'
import { TransactionSchedule, searchTransactionSchedules, deleteTransactionSchedule } from 'api'
import actionRequired from 'assets/images/customer_dashboard_icons/Action-required-icon-2.png'

import {
  ActionFormatterFactory,
  CurrencyFormatter,
  DateFormatter,
  TooltipFormatter,
  OnTableChangeProps,
  Table,
} from 'components/Table'

import { getSort } from 'utilities'
import { getCustomer, getEntities } from 'app/selectors'
import { TableNewButton } from 'components/TableHeader'
import { Link, useHistory } from 'react-router-dom'
import { ColumnProps } from 'components/Table'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { FormServerErrors } from 'components/FormServerErrors'
import { FormServerNotifications } from 'components/FormServerNotifications'

export interface TransactionSchedulesTableProps {
  readOnly?: boolean
}

const InstallmentsFormatter = (cell, row) => {
  if (!cell) return 'Indefinite'
  return (
    <>
      <span>{cell}</span>
      {cell !== 1 && row.completedInstallments !== null && (
        <span style={{ color: '#39AFD1' }}>&nbsp;({row.completedInstallments} processed)</span>
      )}
    </>
  )
}

export const TransactionSchedulesTable = (props: TransactionSchedulesTableProps) => {
  const history = useHistory()
  const customer = useSelector(getCustomer) || {}
  const transactionSchedules = useSelector((state: RootState) => getEntities(state).transactionSchedules) || []
  const transactionSchedulesCount = useSelector((state: RootState) => getEntities(state).transactionSchedulesCount) || 0
  const [scheduleToDelete, setScheduleToDelete] = useState<TransactionSchedule>()
  const [deletionErrors, setDeletionErrors] = useState([])
  const permit = {
    email: useIsPermitted('customers.email_auth_request'),
    new: useIsPermitted('transaction_schedules.new'),
    edit: useIsPermitted('transaction_schedules.edit'),
    remove: useIsPermitted('transaction_schedules.remove'),
  }
  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 5,
    sortField: 'nextProcessDate',
    sortOrder: 'asc',
  })
  const columns: ColumnProps[] = [
    {
      dataField: 'amount',
      formatter: CurrencyFormatter,
      text: 'Amount',
      sort: true,
    },
    {
      dataField: 'nextProcessDate',
      formatter: DateFormatter,
      text: 'Next Date',
      sort: true,
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      formatter: DateFormatter,
      sort: false,
    },
    {
      dataField: 'frequency',
      text: 'Frequency',
      sort: false,
    },
    {
      dataField: 'installments',
      text: 'Installments',
      formatter: InstallmentsFormatter,
      sort: false,
    },
    {
      dataField: 'comment',
      formatter: TooltipFormatter,
      text: 'Comment',
      sort: true,
    },
  ]
  if (!props.readOnly && (permit.edit || permit.remove)) {
    columns.push({
      dataField: '',
      text: '',
      formatter: ActionFormatterFactory([
        {
          name: 'Edit',
          onClick: (row) => history.push(`/client/customers/${row.customerId}/edit_transaction_schedule/${row.id}`),
          hidden: (_row) => !permit.edit,
        },
        {
          name: <span className="text-danger">Remove</span>,
          onClick: (schedule) => setScheduleToDelete(schedule),
          hidden: (_row) => !permit.remove,
        },
      ]),
      sort: false,
      align: 'right',
      classes: 'sticky-right',
    })
  }

  const [requestState, doRequest] = useRequest(
    !customer?.id
      ? null
      : searchTransactionSchedules(
          {
            customerId: customer.id,
            skip: (tableProps.page - 1) * tableProps.pageSize,
            limit: tableProps.pageSize,
            sort: getSort(tableProps.sortField, tableProps.sortOrder),
          },
          {
            force: true,
            transform: (response) => {
              return {
                transactionSchedules: response.records,
                transactionSchedulesCount: response.count,
              }
            },
            update: {
              transactionSchedules: (_, newValue) => newValue,
              transactionSchedulesCount: (_, newValue) => newValue,
            },
          },
        ),
  )

  const [mutationState, doMutation] = useMutation((id: number) => deleteTransactionSchedule({ id: id }))

  function onTableChange(_type: string, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) {
    setTableProps({
      page: page || tableProps.page,
      pageSize: sizePerPage || tableProps.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  const handleClickDelete = () => {
    if (!props.readOnly && scheduleToDelete?.id) {
      doMutation(scheduleToDelete.id)?.then((response) => {
        if (response.body.status === 400) {
          setDeletionErrors(response?.body?.messages || [])
        }
        doRequest()
      })
    } else {
      doRequest()
    }
    setScheduleToDelete(undefined)
  }

  return (
    <>
      {!props.readOnly && (
        <Modal isOpen={scheduleToDelete?.id !== undefined}>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalBody>
            Are you sure you want to remove this transaction schedule?
            {customer.autoImportInvoices && (
              <FormServerNotifications
                className="mt-2"
                notifications={[
                  <>
                    <p>This customer has automatic invoice import enabled.</p>
                    <p className="mb-0">
                      This transaction will re-import unless automatic invoice import is disabled for this customer.
                    </p>
                  </>,
                ]}
              />
            )}
          </ModalBody>
          <ModalFooter className="text-right">
            <Button onClick={() => setScheduleToDelete(undefined)} className="btn-rotessa-secondary min-width">
              No
            </Button>
            <Button color="primary" onClick={handleClickDelete} className="ml-2">
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Row className="clearfix mb-2">
        <Col xs="auto">
          <h4 className="header-title">Transaction Schedules</h4>
        </Col>

        {!props.readOnly && customer.statuses && (
          <Col className="text-right">
            {!customer.statuses['complete'] && permit.email ? (
              <Link to={`/client/email_customer_authorization_request?ids=${customer.id}`}>
                <Button color="primary" className="ml-1">
                  {customer.requiredAction === 'send_auth_request' && (
                    <img src={actionRequired} height={15} style={{ marginBottom: '4px' }} className="mr-1" alt="" />
                  )}
                  Email Authorization Request
                </Button>
              </Link>
            ) : customer.statuses['complete'] && permit.new ? (
              <TableNewButton link={`${customer.id}/new_transaction_schedule`} />
            ) : null}
          </Col>
        )}
      </Row>
      <FormServerErrors errors={deletionErrors} />
      <Table
        loading={requestState.isPending || mutationState.isPending}
        data={transactionSchedules}
        columns={columns}
        paginationProps={{
          page: tableProps.page,
          sizePerPage: tableProps.pageSize,
          totalSize: transactionSchedulesCount,
        }}
        sortField={tableProps.sortField}
        sortOrder={tableProps.sortOrder}
        onTableChange={onTableChange}
      />
    </>
  )
}
