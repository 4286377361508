// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorizationLinkSetting
 */
export interface AuthorizationLinkSetting  {
    /**
     * 
     * @type {number}
     * @memberof AuthorizationLinkSetting
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationLinkSetting
     */
    allowTransactionSchedule?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationLinkSetting
     */
    allowMicrodeposit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationLinkSetting
     */
    requireAddress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationLinkSetting
     */
    requirePhone?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationLinkSetting
     */
    formUrl: string;
    /**
     * 
     * @type {number}
     * @memberof AuthorizationLinkSetting
     */
    clientId: number;
    /**
     * 
     * @type {Date}
     * @memberof AuthorizationLinkSetting
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AuthorizationLinkSetting
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationLinkSetting
     */
    requireAuthorizationAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationLinkSetting
     */
    displayClientAddress?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationLinkSetting
     */
    introduction: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationLinkSetting
     */
    termsAndConditions: string;
}

export function AuthorizationLinkSettingFromJSON(json: any): AuthorizationLinkSetting {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'allowTransactionSchedule': !exists(json, 'allow_transaction_schedule') ? undefined : json['allow_transaction_schedule'],
        'allowMicrodeposit': !exists(json, 'allow_microdeposit') ? undefined : json['allow_microdeposit'],
        'requireAddress': !exists(json, 'require_address') ? undefined : json['require_address'],
        'requirePhone': !exists(json, 'require_phone') ? undefined : json['require_phone'],
        'formUrl': json['form_url'],
        'clientId': json['client_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'requireAuthorizationAmount': !exists(json, 'require_authorization_amount') ? undefined : json['require_authorization_amount'],
        'displayClientAddress': !exists(json, 'display_client_address') ? undefined : json['display_client_address'],
        'introduction': json['introduction'],
        'termsAndConditions': json['terms_and_conditions'],
    };
}

export function AuthorizationLinkSettingToJSON(value?: AuthorizationLinkSetting): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'allow_transaction_schedule': value.allowTransactionSchedule,
        'allow_microdeposit': value.allowMicrodeposit,
        'require_address': value.requireAddress,
        'require_phone': value.requirePhone,
        'form_url': value.formUrl,
        'client_id': value.clientId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'require_authorization_amount': value.requireAuthorizationAmount,
        'display_client_address': value.displayClientAddress,
        'introduction': value.introduction,
        'terms_and_conditions': value.termsAndConditions,
    };
}


