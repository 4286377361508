// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOwnerSignupDetails200Response
 */
export interface GetOwnerSignupDetails200Response  {
    /**
     * 
     * @type {Client}
     * @memberof GetOwnerSignupDetails200Response
     */
    client?: Client;
    /**
     * 
     * @type {boolean}
     * @memberof GetOwnerSignupDetails200Response
     */
    formCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetOwnerSignupDetails200Response
     */
    veriffCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetOwnerSignupDetails200Response
     */
    veriffUrl?: string;
}

export function GetOwnerSignupDetails200ResponseFromJSON(json: any): GetOwnerSignupDetails200Response {
    return {
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'formCompleted': !exists(json, 'form_completed') ? undefined : json['form_completed'],
        'veriffCompleted': !exists(json, 'veriff_completed') ? undefined : json['veriff_completed'],
        'veriffUrl': !exists(json, 'veriff_url') ? undefined : json['veriff_url'],
    };
}

export function GetOwnerSignupDetails200ResponseToJSON(value?: GetOwnerSignupDetails200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client': ClientToJSON(value.client),
        'form_completed': value.formCompleted,
        'veriff_completed': value.veriffCompleted,
        'veriff_url': value.veriffUrl,
    };
}


