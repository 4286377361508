import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateEntities } from 'redux-query'

export const useSetBreadcrumbTitle = (breadcrumb_to_replace: string | null | undefined, new_title: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (breadcrumb_to_replace) {
      dispatch(
        updateEntities({
          breadcrumbTitles: (prev: any) => {
            if (!prev) prev = {}
            prev[breadcrumb_to_replace] = new_title
            return prev
          },
        }),
      )
    }
  })
}
