import React, { useEffect, useState } from 'react'
import { getEntities } from 'app/selectors'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Link, useLocation } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

import {
  getMicrodepositVerificationDetails,
  Microdeposit,
  MicrodepositVerification,
  MicrodepositVerificationFormDetails,
  verifyMicrodeposit,
} from 'api'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'
import { FormServerErrors } from 'components/FormServerErrors'
import { Loading } from 'components/Loading'
import { useTranslation } from 'react-i18next'

export const MAX_MICRODEPOSIT_VERIFICATION_ATTEMPTS = 3

export const MicrodepositVerificationPage = () => {
  const { t, i18n } = useTranslation()
  const query = new URLSearchParams(useLocation().search)
  const formUrl = query.get('verification_form_url')
  const microdepositFormDetails: MicrodepositVerificationFormDetails =
    useSelector((state) => getEntities(state).microdepositFormDetails) || {}

  const [formLoaded, setFormLoaded] = useState(false)
  const [serverErrors, setServerErrors] = useState<String[]>([])
  const [isVerified, setIsVerified] = useState(false)
  const [language, setLanguage] = useState('en')

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    setLanguage(microdepositFormDetails.language || 'en')
  }, [microdepositFormDetails.language])

  const FormContent = {
    loading: <Loading />,
    verified: t('microdeposit.message_verified'),
    notFound: t('microdeposit.message_notFound'),
    maxAttempts: t('microdeposit.message_maxAttempts'),
  }

  const MicrodepositVerificationForm = ({ details, handleSubmit, disabled, errors }) => {
    const microdeposit: Microdeposit = {}
    return (
      <AvForm model={microdeposit} onValidSubmit={handleSubmit} disabled={disabled}>
        <Row>
          <Col>
            <h4 className="text-center">
              {t('microdeposit.verify_title')} {details.publicName}
            </h4>
            <p>
              {details.publicName} {t('microdeposit.description')}
            </p>
            <h5 className="text-center">{t('microdeposit.enter_amounts')}</h5>
          </Col>
        </Row>
        <FormServerErrors errors={errors} />
        <Row className="mb-2">
          <Col>
            <AvField
              name="amountOneAttempt"
              label={t('microdeposit.first_amount')}
              type="text"
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: t('required_field'),
                },
                pattern: {
                  value: /^\d+\.\d{2}$/,
                  errorMessage: t('microdeposit.amount_error'),
                },
              }}
            />
          </Col>
          <Col>
            <AvField
              name="amountTwoAttempt"
              label={t('microdeposit.second_amount')}
              type="text"
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: t('required_field'),
                },
                pattern: {
                  value: /^\d+\.\d{2}$/,
                  errorMessage: t('microdeposit.amount_error'),
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">
              {t('microdeposit.remaining_attmepts')}: {3 - (details.attempts || 0)}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button disabled={disabled} color="primary">
              {t('microdeposit.verify_account')}
            </Button>
          </Col>
        </Row>
      </AvForm>
    )
  }

  const MicrodepositVerified = ({ details }) => {
    return (
      <div className="text-center">
        <h4>{t('microdeposit.success_header', { business_name: details.publicName })}</h4>
        <p>{t('microdeposit.success_questions', { business_name: details.publicName })}.</p>
        <p>{t('microdeposit.success_close')}</p>
      </div>
    )
  }

  const [requestState, doRequest] = useRequest(
    !formUrl
      ? null
      : getMicrodepositVerificationDetails(
          { formUrl: formUrl },
          {
            force: true,
            transform: (body) => {
              return { microdepositFormDetails: body }
            },
            update: { microdepositFormDetails: (_, newValue) => newValue },
          },
        ),
  )

  const [mutationState, doMutation] = useMutation((values: MicrodepositVerification) => {
    return verifyMicrodeposit({
      microdepositVerification: values,
    })
  })

  const handleValidSubmit = (event, values) => {
    values.formUrl = formUrl
    values.language = language
    doMutation(values)?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      response?.body?.status === 200 ? setIsVerified(true) : doRequest()
    })
  }

  const toggleLanguage = (event) => {
    event.preventDefault()
    const newLanguage = language === 'en' ? 'fr' : 'en'
    setLanguage(newLanguage)
  }

  if (!formLoaded && requestState.isFinished) setFormLoaded(true)
  if (!isVerified && microdepositFormDetails?.status === 'Verified') setIsVerified(true)

  const content = !formLoaded
    ? FormContent.loading
    : microdepositFormDetails?.publicName === null
    ? FormContent.notFound
    : microdepositFormDetails?.attempts === 3
    ? FormContent.maxAttempts
    : null
  const form = (
    <MicrodepositVerificationForm
      details={microdepositFormDetails}
      errors={serverErrors}
      handleSubmit={handleValidSubmit}
      disabled={mutationState.isPending}
    />
  )
  const verified = <MicrodepositVerified details={microdepositFormDetails} />

  return (
    <Row>
      <Col md={{ size: 4, offset: 4 }}>
        <Card className="mt-4">
          <CardBody>
            {content ? <h4 className="text-center">{content}</h4> : isVerified ? verified : form}
            <div className="text-center mt-3">
              <Link to={() => ({})} onClick={toggleLanguage}>
                <u>{t('language_toggle')}</u>
              </Link>
            </div>
          </CardBody>
        </Card>
        <PoweredByRotessaFooter type="blue_footer" />
      </Col>
    </Row>
  )
}
