// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Get2faUri200Response
 */
export interface Get2faUri200Response  {
    /**
     * 
     * @type {boolean}
     * @memberof Get2faUri200Response
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Get2faUri200Response
     */
    uri?: string;
}

export function Get2faUri200ResponseFromJSON(json: any): Get2faUri200Response {
    return {
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
    };
}

export function Get2faUri200ResponseToJSON(value?: Get2faUri200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enabled': value.enabled,
        'uri': value.uri,
    };
}


