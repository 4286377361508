import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'
import { Card, CardBody, CardFooter, Fade } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { DonorAuthorization, submitDonorAuthorization, GetDonorAuthorizationDetails200ResponseFromJSON } from 'api'
import { getEntities } from 'app/selectors'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { DonorAuthDonationInfo } from './donorAuthDonationInfo'
import { DonorAuthBankInfo } from './donorAuthBankInfo'
import { updateEntities } from 'redux-query'
import { DonorAuthFinished, DonorAuthFinishedFooter } from './donorAuthFinished'
import { DonorAuthContactInfo } from './donorAuthContactInfo'
import { FormServerErrors } from 'components/FormServerErrors'
import { LoadingMask } from 'components/LoadingMask'
import { Link } from 'react-router-dom'
import { DonorAuthHeader } from './donorAuthHeader'
import { DonorAuthEmailSent, DonorAuthEmailSentFooter } from './donorAuthEmailSent'

export interface DonorAuthFormManagerProps {
  readOnly?: boolean
}

export const DonorAuthFormManager = (props: DonorAuthFormManagerProps) => {
  const dispatch = useDispatch()
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo) || {}

  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)

  const [t, i18n] = useTranslation()

  const [mutationState, doMutation] = useMutation((values: DonorAuthorization) =>
    submitDonorAuthorization({ id: donorAuthInfo.id, donorAuthorization: values }),
  )

  function handleSubmit(values: DonorAuthorization) {
    if (props.readOnly) return
    doMutation({ language: donorAuthInfo.language, ...values })?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      if (response?.body?.status === 200) {
        dispatch(
          updateEntities({
            donorAuthInfo: (_oldValue: any) => {
              let result = { ..._oldValue }
              let newValue = GetDonorAuthorizationDetails200ResponseFromJSON(response.body)
              Object.keys(newValue).forEach((key) => {
                if (newValue[key] !== undefined) {
                  result[key] = newValue[key]
                }
              })
              return result
            },
          }),
        )
      }
    })
  }

  useEffect(() => {
    i18n.changeLanguage(donorAuthInfo.language)

    return () => {
      if (props.readOnly && i18n.language !== 'en') {
        i18n.changeLanguage('en')
      }
    }
  }, [donorAuthInfo.language])

  const toggleLanguage = (event) => {
    event.preventDefault()
    const newLanguage = donorAuthInfo.language === 'en' ? 'fr' : 'en'
    handleSubmit({ language: newLanguage, submittedStep: donorAuthInfo?.step?.name })

    if (props.readOnly) {
      dispatch(
        updateEntities({
          donorAuthInfo: (olddonorAuthInfo: any) => ({
            ...olddonorAuthInfo,
            language: newLanguage,
          }),
        }),
      )
    }
  }

  let content = <></>
  let footer_content = <></>

  switch (donorAuthInfo?.step?.name) {
    case 'donation':
      content = (
        <DonorAuthDonationInfo
          handleFieldChange={handleFieldChange}
          validate={validate}
          onSubmit={(values) => handleSubmit({ ...values, submittedStep: 'donation' })}
          serverErrors={serverErrors || {}}
        />
      )
      break
    case 'contact':
      content = (
        <DonorAuthContactInfo
          handleFieldChange={handleFieldChange}
          validate={validate}
          onSubmit={(values) => handleSubmit({ ...values, submittedStep: 'contact' })}
        />
      )
      break
    case 'flinks':
      content = (
        <DonorAuthBankInfo
          readOnly={props.readOnly}
          handleFieldChange={handleFieldChange}
          validate={validate}
          onSubmit={(values) => handleSubmit({ ...values, submittedStep: 'flinks' })}
        />
      )
      break
    case 'schedule_email_verification':
      content = <DonorAuthEmailSent />
      footer_content = <DonorAuthEmailSentFooter />
      break
    case 'new_customer_finished':
      content = <DonorAuthFinished />
      footer_content = <DonorAuthFinishedFooter />
      break
    case 'schedule_email_finished':
      content = <DonorAuthFinished />
      footer_content = <DonorAuthFinishedFooter />
      break
  }

  let cardClasses = 'loading-container-min-height'

  if (['contact', 'donation'].includes(donorAuthInfo?.step?.name)) {
    cardClasses = 'card-no-background'
  }

  return (
    <Fade>
      <DonorAuthHeader />
      <div className="donation-content">
        <Card className={cardClasses}>
          <CardBody>
            <div>
              {mutationState.isPending && <LoadingMask />}
              <FormServerErrors errors={(donorAuthInfo?.messages && donorAuthInfo?.messages['base']) || []} />
              <Fade>{content}</Fade>
            </div>
          </CardBody>
          {['schedule_email_verification', 'new_customer_finished', 'schedule_email_finished'].includes(
            donorAuthInfo?.step?.name,
          ) && <CardFooter>{footer_content}</CardFooter>}
        </Card>
        <div className="text-center mt-3 mb-2 language-toggle">
          <Link to={() => ({})} onClick={toggleLanguage}>
            <u>{t('language_toggle')}</u>
          </Link>
        </div>
        <PoweredByRotessaFooter type="donation_footer" source="authPage" />
      </div>
    </Fade>
  )
}
