import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { useRequest } from 'redux-query-react'

import SimpleBar from 'simplebar-react'
import { Timeline } from 'components/Timeline'
import { TimelineItem } from 'components/TimelineItem'

import { RootState } from 'app/rootReducer'
import { searchCustomerActivity } from 'api'
import { getCustomer, getEntities } from 'app/selectors'
import { formatDateTime, getSort } from 'utilities'
import { Loading } from 'components/Loading'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'

import quickbooksIcon from 'assets/images/quickbooks_icon.png'
import xeroIcon from 'assets/images/xero_icon.png'
import { ACTIVITY_STATUS_CLASSES } from 'app/constants'

export function Icon({ iconType }) {
  const bgLightenClass = ' bg-' + ACTIVITY_STATUS_CLASSES[iconType] + '-lighten'
  const textClass = ' text-' + ACTIVITY_STATUS_CLASSES[iconType]
  const newSharedClassNames = ' timeline-icon text' + textClass + bgLightenClass
  return (
    <span>
      {
        {
          transactionSchedule: <i className={'mdi mdi-clock ' + newSharedClassNames}></i>,
          scheduleCreated: <i className={'mdi mdi-clock ' + newSharedClassNames}></i>,
          quickbooksScheduleCreated: <img className={newSharedClassNames} height={22} src={quickbooksIcon} alt="" />,
          xeroScheduleCreated: <img className={newSharedClassNames} height={22} src={xeroIcon} alt="" />,
          scheduleDeleted: <i className={'mdi mdi-delete-outline ' + newSharedClassNames}></i>,
          scheduleAmountUpdated: <i className={'mdi mdi-swap-vertical ' + newSharedClassNames}></i>,
          financialTransaction: <i className={'mdi mdi-bank ' + newSharedClassNames}></i>,
          transactionCreated: <i className={'mdi mdi-bank ' + newSharedClassNames}></i>,
          transactionApproved: <i className={'mdi mdi-calendar-check-outline' + newSharedClassNames}></i>,
          transactionDeclined: <i className={'mdi mdi-cancel ' + newSharedClassNames}></i>,
          transactionSettled: <i className={'mdi mdi-bank ' + newSharedClassNames}></i>,
          paymentReported: <i className={'mdi mdi-bank ' + newSharedClassNames}></i>,
          quickbooksPaymentReported: <img className={newSharedClassNames} height={22} src={quickbooksIcon} alt="" />,
          xeroPaymentReported: <img className={newSharedClassNames} height={22} src={xeroIcon} alt="" />,
          Authorization: <i className={'mdi mdi-file-document ' + newSharedClassNames}></i>,
          customerNotificationSettingsUpdated: <i className={'mdi mdi-bell ' + newSharedClassNames}></i>,
        }[iconType]
      }
    </span>
  )
}

export const CustomerActivity = () => {
  const customer = useSelector(getCustomer) || {}
  const customerActivity = useSelector((state: RootState) => getEntities(state).customerActivity) || []

  useClearEntitiesOnUnmount(['customerActivity', 'customerActivityCount'])

  const [requestState, doRequest] = useRequest(
    customer?.id &&
      searchCustomerActivity(
        {
          customerId: customer.id,
          skip: 0,
          limit: 5,
          sort: getSort('createdAt', 'desc'),
        },
        {
          force: true,
          transform: (response) => {
            return {
              customerActivity: response.records,
              customerActivityCount: response.count,
            }
          },
          update: {
            customerActivity: (oldValue, newValue) => newValue,
            customerActivityCount: (oldValue, newValue) => newValue,
          },
        },
      ),
  )

  return (
    <Card>
      <CardBody>
        <h4 className="header-title mb-3">Activity</h4>
        {customerActivity?.length > 0 ? (
          <SimpleBar style={{ maxHeight: '430px', width: '100%' }}>
            <Timeline>
              {customerActivity.map((item, i) => (
                <TimelineItem key={i}>
                  <Icon iconType={item.type}></Icon>
                  <div className="timeline-item-info">
                    <a className="text-secondary mb-1 d-block">
                      {formatDateTime(item.dateAndTime, 'mmmm d, yyyy "at" h:MM TT', false)}
                    </a>
                    <small className="text-secondary">{item.action}</small>
                    <p className="mb-0 pb-2">
                      <small className="text-muted">{item.userName}</small>
                    </p>
                  </div>
                </TimelineItem>
              ))}
            </Timeline>
          </SimpleBar>
        ) : requestState.isPending ? (
          <Loading />
        ) : (
          <div className="text-center">No data</div>
        )}
      </CardBody>
    </Card>
  )
}
