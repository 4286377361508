// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchCustomers200Response
 */
export interface SearchCustomers200Response  {
    /**
     * 
     * @type {number}
     * @memberof SearchCustomers200Response
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchCustomers200Response
     */
    beforeSearchCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchCustomers200Response
     */
    includeQboFilter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchCustomers200Response
     */
    includeXeroFilter?: boolean;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof SearchCustomers200Response
     */
    records?: Array<Customer>;
}

export function SearchCustomers200ResponseFromJSON(json: any): SearchCustomers200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'beforeSearchCount': !exists(json, 'before_search_count') ? undefined : json['before_search_count'],
        'includeQboFilter': !exists(json, 'include_qbo_filter') ? undefined : json['include_qbo_filter'],
        'includeXeroFilter': !exists(json, 'include_xero_filter') ? undefined : json['include_xero_filter'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(CustomerFromJSON),
    };
}

export function SearchCustomers200ResponseToJSON(value?: SearchCustomers200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'before_search_count': value.beforeSearchCount,
        'include_qbo_filter': value.includeQboFilter,
        'include_xero_filter': value.includeXeroFilter,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(CustomerToJSON),
    };
}


