import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest } from 'redux-query-react'

import { getSort } from 'utilities'

import { RootState } from 'app/rootReducer'

import { searchFinancialTransactions } from 'api'
import {
  DateFormatter,
  TooltipFormatter,
  OnTableChangeProps,
  Table,
  TransactionStatusFormatter,
} from 'components/Table'

import { getCustomer, getEntities } from 'app/selectors'
import { TableHeader } from 'components/TableHeader'
import { Link, useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format'

const TransactionHistoryLinkFormatter = (cell, row) => {
  return (
    <Link to={`/client/customers/${row.customerId}/transaction_history/${row.id}`}>
      <NumberFormat
        value={cell}
        displayType={'text'}
        fixedDecimalScale={true}
        decimalScale={2}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <div>{value}</div>}
      />
    </Link>
  )
}

export const TransactionHistoryTable = () => {
  const customer = useSelector(getCustomer)
  const financialTransactions = useSelector((state: RootState) => state.entities.financialTransactions) || []
  const financialTransactionsCount =
    useSelector((state: RootState) => getEntities(state).financialTransactionsCount) || 0
  const history = useHistory()

  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 5,
    sortField: 'createdAt',
    sortOrder: 'desc',
  })
  const columns = [
    {
      dataField: 'amount',
      formatter: TransactionHistoryLinkFormatter,
      text: 'Amount',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: TransactionStatusFormatter,
      sort: false,
      classes: 'td-badge',
    },
    {
      dataField: 'createdAt',
      formatter: DateFormatter,
      text: 'Batched On',
      sort: true,
    },
    {
      dataField: 'processDate',
      formatter: DateFormatter,
      text: 'Process Date',
      sort: true,
    },
    {
      dataField: 'earliestApprovalDate',
      formatter: DateFormatter,
      text: 'Settlement Date',
      sort: true,
    },
    {
      dataField: 'comment',
      formatter: TooltipFormatter,
      text: 'Comment',
      sort: true,
    },
  ]

  const [requestState, doRequest] = useRequest(
    customer?.id &&
      searchFinancialTransactions(
        {
          customerId: customer.id,
          skip: (tableProps.page - 1) * tableProps.pageSize || 0,
          limit: tableProps.pageSize || 5,
          sort: getSort(tableProps.sortField, tableProps.sortOrder),
        },
        {
          force: true,
          transform: (response) => {
            return {
              financialTransactions: response.records,
              financialTransactionsCount: response.count,
            }
          },
          update: {
            financialTransactions: (_, newValue) => newValue,
            financialTransactionsCount: (_, newValue) => newValue,
          },
        },
      ),
  )

  function onTableChange(type, { page, sizePerPage, filter, sortField, sortOrder }: OnTableChangeProps) {
    setTableProps({
      page: page,
      pageSize: sizePerPage,
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  const onRowClick = (e, row, rowIndex) => {
    history.push(`/client/customers/${customer.id}/transaction_history/${row.id}`)
  }

  return (
    <>
      <TableHeader onClickRefresh={doRequest} hideImport hideExport tableTitle="Transaction History" />
      <Table
        loading={requestState.isPending}
        data={financialTransactions}
        columns={columns}
        paginationProps={{
          page: tableProps.page,
          sizePerPage: tableProps.pageSize,
          totalSize: financialTransactionsCount,
        }}
        sortField={tableProps.sortField}
        sortOrder={tableProps.sortOrder}
        onTableChange={onTableChange}
        onRowClick={onRowClick}
      />
    </>
  )
}
