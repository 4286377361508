import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'

import { getSort } from 'utilities'

import { RootState } from 'app/rootReducer'

import { deleteCustomerNote, searchCustomerNotes } from 'api'
import { ActionFormatterFactory, ColumnProps, DateFormatter, OnTableChangeProps, Table } from 'components/Table'

import { getCustomer, getEntities } from 'app/selectors'
import { TableHeader } from 'components/TableHeader'
import { useIsPermitted } from 'hooks/useIsPermitted'

export const CustomerNotesTable = () => {
  const customer = useSelector(getCustomer) || {}
  const customerNotes = useSelector((state: RootState) => getEntities(state).customerNotes) || []
  const customerNotesCount = useSelector((state: RootState) => getEntities(state).customerNotesCount) || 0
  const permit = {
    new: useIsPermitted('customer_notes.new'),
    remove: useIsPermitted('customer_notes.remove'),
  }
  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 5,
    sortField: 'createdAt',
    sortOrder: 'desc',
  })
  const columns: ColumnProps[] = [
    {
      dataField: 'description',
      text: 'Note',
      sort: false,
    },
    {
      dataField: 'createdAt',
      formatter: DateFormatter,
      text: 'Created',
      sort: true,
    },
    {
      dataField: 'createdBy',
      text: 'User',
      sort: false,
    },
  ]
  if (permit.remove) {
    columns.push({
      dataField: '',
      text: '',
      formatter: ActionFormatterFactory([
        {
          name: <span className="text-danger">Remove</span>,
          onClick: (row) => {
            doRemove(row.id)?.then(doRequest)
          },
        },
      ]),
      sort: false,
      align: 'right',
      classes: 'sticky-right',
    })
  }

  const [requestState, doRequest] = useRequest(
    customer?.id &&
      searchCustomerNotes(
        {
          customerId: customer.id,
          skip: (tableProps.page - 1) * tableProps.pageSize || 0,
          limit: tableProps.pageSize || 5,
          sort: getSort(tableProps.sortField, tableProps.sortOrder),
        },
        {
          force: true,
          transform: (response) => {
            return {
              customerNotes: response.records,
              customerNotesCount: response.count,
            }
          },
          update: {
            customerNotes: (_, newValue) => newValue,
            customerNotesCount: (_, newValue) => newValue,
          },
        },
      ),
  )

  const [removeRequestState, doRemove] = useMutation((id: number) => deleteCustomerNote({ id: id }))

  function onTableChange(type, { page, sizePerPage, filter, sortField, sortOrder }: OnTableChangeProps) {
    setTableProps({
      page: page,
      pageSize: sizePerPage,
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  return (
    <>
      <TableHeader
        newLink={permit.new ? `${customer.id}/new_note` : undefined}
        onClickRefresh={doRequest}
        hideImport
        hideExport
        tableTitle="Notes"
      />
      <Table
        loading={requestState.isPending}
        data={customerNotes}
        columns={columns}
        paginationProps={{
          page: tableProps.page,
          sizePerPage: tableProps.pageSize,
          totalSize: customerNotesCount,
        }}
        sortField={tableProps.sortField}
        sortOrder={tableProps.sortOrder}
        onTableChange={onTableChange}
      />
    </>
  )
}
