import React from 'react'

import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { getEntities } from 'app/selectors'
import { ProcessingAuthHeader } from './processingAuthHeader'
import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'

export interface AuthIntroProps {
  onSubmit: Function
}

export const ProcessingAuthIntro = (props: AuthIntroProps) => {
  const processingAuthInfo = useSelector((state) => getEntities(state).processingAuthInfo)
  const introduction = () => ({ __html: DOMPurify.sanitize(processingAuthInfo.step.introduction) })
  const { t } = useTranslation()
  return (
    <>
      <ProcessingAuthHeader />
      <p className="text-center" dangerouslySetInnerHTML={introduction()} />
      <div className="text-center">
        <Button color="primary" onClick={() => props.onSubmit()} className="w-100">
          {t('continue')}
        </Button>
      </div>
    </>
  )
}
