import { Errors } from 'hooks/useFormServerErrors'
import React, { Ref } from 'react'
import { Alert, Form } from 'reactstrap'
import camelCase from 'camelcase'
import { AvForm } from 'availity-reactstrap-validation'

export interface FormServerErrorsProps {
  errors: string[]
}

export const FormServerErrors = ({ errors }: FormServerErrorsProps) => {
  return (
    <>
      {errors.map((error, i) => (
        <Alert color="danger" key={i}>
          {error}
        </Alert>
      ))}
    </>
  )
}

export interface SmartFormServerErrorsProps {
  errors?: Errors
  avFormRef: React.MutableRefObject<AvForm>
}

/**
 * Displays Form server errors only for errors that do not appear on the AvForm
 * @param avFormRef Reference to the AvForm component.
 */
export const SmartFormServerErrors = ({ errors, avFormRef }: SmartFormServerErrorsProps) => {
  let inputNames: string[] = []
  let filteredErrors: string[] = []
  if (errors) {
    inputNames = Object.keys(avFormRef.current?._inputs || {}).map((name) => name.split('.').pop()!)
    Object.entries(errors).forEach((error) => {
      if (!inputNames.includes(camelCase(error[0]))) {
        try {
          filteredErrors.push(...error[1])
        } catch (_) {}
      }
    })
  }
  return <FormServerErrors errors={filteredErrors} />
}
