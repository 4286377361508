import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { CenterContent } from 'components/CenterContent'
import { Col, Row, Card, CardHeader, CardBody, CardGroup, Fade } from 'reactstrap'

import newCustomer from 'assets/images/customerFlow/New_customer.png'
import newTransaction from 'assets/images/transactionFlow/New_transaction.png'
import { isPermitted } from 'utilities'
import { getActiveClient } from 'app/selectors'
import { useSelector } from 'react-redux'
import { TitleBar } from './titleBar'

export const NewItemFlow = () => {
  const activeClient = useSelector(getActiveClient) || {}
  const history = useHistory()

  if (isPermitted('customers.new', activeClient) && !isPermitted('transaction_schedules.new', activeClient)) {
    history.push('/client/customers/new')
  } else if (isPermitted('transaction_schedules.new', activeClient) && !isPermitted('customers.new', activeClient)) {
    history.push('/client/transactions/new')
  }

  return (
    <Fade>
      <TitleBar title="What would you like to create?" />
      <CenterContent>
        <Col xl={{ size: 16 }}>
          <Row>
            <Col m>
              <CardGroup className="justify-content-center">
                {isPermitted('customers.new', activeClient) && (
                  <Card className="m-2 customer-flow-card">
                    <Link to="/client/customers/new">
                      <CardHeader className="text-center ">
                        <img src={newCustomer} height={100} className="mt-1 mb-1" alt="Add a new customer" />
                      </CardHeader>
                      <CardBody className="text-center">
                        <p className="card-label">New Customer</p>
                      </CardBody>
                    </Link>
                  </Card>
                )}
                {isPermitted('transaction_schedules.new', activeClient) && (
                  <Card className="m-2 customer-flow-card">
                    <Link to="/client/transactions/new">
                      <CardHeader className="text-center">
                        <img
                          src={newTransaction}
                          height={100}
                          className="mt-1 mb-1"
                          alt="Add a new transaction schedule"
                        />
                      </CardHeader>
                      <CardBody className="text-center">
                        <p className="card-label">New Transaction</p>
                      </CardBody>
                    </Link>
                  </Card>
                )}
              </CardGroup>
            </Col>
          </Row>
        </Col>
      </CenterContent>
    </Fade>
  )
}
