// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportSettlementTransactionsPdf200Response
 */
export interface ExportSettlementTransactionsPdf200Response  {
    /**
     * 
     * @type {number}
     * @memberof ExportSettlementTransactionsPdf200Response
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportSettlementTransactionsPdf200Response
     */
    url?: string;
}

export function ExportSettlementTransactionsPdf200ResponseFromJSON(json: any): ExportSettlementTransactionsPdf200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function ExportSettlementTransactionsPdf200ResponseToJSON(value?: ExportSettlementTransactionsPdf200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'url': value.url,
    };
}


