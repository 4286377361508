import React, { useState } from 'react'

export function Accordion(props: { startingIndex?: number; children: any }) {
  const [currentIndex, setCurrentIndex] = useState(props.startingIndex || -1)
  const btnOnClick = (index: number) => {
    if (currentIndex === index) {
      setCurrentIndex(-1)
    } else {
      setCurrentIndex(index)
    }
  }

  let accordionItems = React.Children.toArray(props.children)
  accordionItems = accordionItems.map((item, i) => {
    return React.cloneElement(item, { btnOnClick: () => btnOnClick(i), isOpen: i === currentIndex })
  })

  return <div className="accordion rotessa-accordion">{accordionItems}</div>
}
