// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetConstants200ResponseCollection,
    GetConstants200ResponseCollectionFromJSON,
    GetConstants200ResponseCollectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetConstants200Response
 */
export interface GetConstants200Response  {
    /**
     * 
     * @type {GetConstants200ResponseCollection}
     * @memberof GetConstants200Response
     */
    collection?: GetConstants200ResponseCollection;
}

export function GetConstants200ResponseFromJSON(json: any): GetConstants200Response {
    return {
        'collection': !exists(json, 'collection') ? undefined : GetConstants200ResponseCollectionFromJSON(json['collection']),
    };
}

export function GetConstants200ResponseToJSON(value?: GetConstants200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'collection': GetConstants200ResponseCollectionToJSON(value.collection),
    };
}


