// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillingInvoice,
    BillingInvoiceFromJSON,
    BillingInvoiceToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBillingInvoices200Response
 */
export interface GetBillingInvoices200Response  {
    /**
     * 
     * @type {Array<BillingInvoice>}
     * @memberof GetBillingInvoices200Response
     */
    billingInvoices?: Array<BillingInvoice>;
    /**
     * 
     * @type {number}
     * @memberof GetBillingInvoices200Response
     */
    billingInvoicesTotalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBillingInvoices200Response
     */
    status?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetBillingInvoices200Response
     */
    messages?: { [key: string]: string; };
}

export function GetBillingInvoices200ResponseFromJSON(json: any): GetBillingInvoices200Response {
    return {
        'billingInvoices': !exists(json, 'billing_invoices') ? undefined : (json['billing_invoices'] as Array<any>).map(BillingInvoiceFromJSON),
        'billingInvoicesTotalCount': !exists(json, 'billing_invoices_total_count') ? undefined : json['billing_invoices_total_count'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function GetBillingInvoices200ResponseToJSON(value?: GetBillingInvoices200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'billing_invoices': value.billingInvoices === undefined ? undefined : (value.billingInvoices as Array<any>).map(BillingInvoiceToJSON),
        'billing_invoices_total_count': value.billingInvoicesTotalCount,
        'status': value.status,
        'messages': value.messages,
    };
}


