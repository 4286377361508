// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnsubscribedByUuid200Response
 */
export interface UnsubscribedByUuid200Response  {
    /**
     * 
     * @type {number}
     * @memberof UnsubscribedByUuid200Response
     */
    status?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UnsubscribedByUuid200Response
     */
    alreadyUnsubscribed?: boolean;
}

export function UnsubscribedByUuid200ResponseFromJSON(json: any): UnsubscribedByUuid200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'alreadyUnsubscribed': !exists(json, 'already_unsubscribed') ? undefined : json['already_unsubscribed'],
    };
}

export function UnsubscribedByUuid200ResponseToJSON(value?: UnsubscribedByUuid200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'already_unsubscribed': value.alreadyUnsubscribed,
    };
}


