import { useEffect, useState } from 'react'

const readDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export const useWindowSize = () => {
  const [dimensions, setDimensions] = useState(readDimensions())
  const updateDimensions = () => {
    setDimensions(readDimensions())
  }
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  })

  return dimensions
}
