// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntegrationInvoice,
    IntegrationInvoiceFromJSON,
    IntegrationInvoiceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConnectIntegrationInvoices200Response
 */
export interface ConnectIntegrationInvoices200Response  {
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationInvoices200Response
     */
    invoicesAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationInvoices200Response
     */
    invoicesOutstanding?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationInvoices200Response
     */
    invoicesImported?: number;
    /**
     * 
     * @type {Array<IntegrationInvoice>}
     * @memberof ConnectIntegrationInvoices200Response
     */
    invoices?: Array<IntegrationInvoice>;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationInvoices200Response
     */
    newlyImportedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationInvoices200Response
     */
    selectedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationInvoices200Response
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectIntegrationInvoices200Response
     */
    errorMessage?: string;
}

export function ConnectIntegrationInvoices200ResponseFromJSON(json: any): ConnectIntegrationInvoices200Response {
    return {
        'invoicesAvailable': !exists(json, 'invoices_available') ? undefined : json['invoices_available'],
        'invoicesOutstanding': !exists(json, 'invoices_outstanding') ? undefined : json['invoices_outstanding'],
        'invoicesImported': !exists(json, 'invoices_imported') ? undefined : json['invoices_imported'],
        'invoices': !exists(json, 'invoices') ? undefined : (json['invoices'] as Array<any>).map(IntegrationInvoiceFromJSON),
        'newlyImportedCount': !exists(json, 'newly_imported_count') ? undefined : json['newly_imported_count'],
        'selectedCount': !exists(json, 'selected_count') ? undefined : json['selected_count'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'errorMessage': !exists(json, 'error_message') ? undefined : json['error_message'],
    };
}

export function ConnectIntegrationInvoices200ResponseToJSON(value?: ConnectIntegrationInvoices200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'invoices_available': value.invoicesAvailable,
        'invoices_outstanding': value.invoicesOutstanding,
        'invoices_imported': value.invoicesImported,
        'invoices': value.invoices === undefined ? undefined : (value.invoices as Array<any>).map(IntegrationInvoiceToJSON),
        'newly_imported_count': value.newlyImportedCount,
        'selected_count': value.selectedCount,
        'status': value.status,
        'error_message': value.errorMessage,
    };
}


