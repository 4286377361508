// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BankAccount,
    BankAccountFromJSON,
    BankAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface FinancialTransaction
 */
export interface FinancialTransaction  {
    /**
     * 
     * @type {number}
     * @memberof FinancialTransaction
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialTransaction
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    statusReason?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    txNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    processDate?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    settledDate?: string;
    /**
     * 
     * @type {number}
     * @memberof FinancialTransaction
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialTransaction
     */
    transactionScheduleId?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialTransaction
     */
    batchId?: number;
    /**
     * 
     * @type {Date}
     * @memberof FinancialTransaction
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FinancialTransaction
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    customerName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FinancialTransaction
     */
    sentToExternalService?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    earliestApprovalDate?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    authorizationType?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    zapierIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof FinancialTransaction
     */
    platformPayoutAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialTransaction
     */
    platformId?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialTransaction
     */
    declineDate?: string;
    /**
     * 
     * @type {BankAccount}
     * @memberof FinancialTransaction
     */
    bankAccount?: BankAccount;
}

export function FinancialTransactionFromJSON(json: any): FinancialTransaction {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusReason': !exists(json, 'status_reason') ? undefined : json['status_reason'],
        'txNumber': !exists(json, 'tx_number') ? undefined : json['tx_number'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'processDate': !exists(json, 'process_date') ? undefined : json['process_date'],
        'settledDate': !exists(json, 'settled_date') ? undefined : json['settled_date'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'transactionScheduleId': !exists(json, 'transaction_schedule_id') ? undefined : json['transaction_schedule_id'],
        'batchId': !exists(json, 'batch_id') ? undefined : json['batch_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'bankName': !exists(json, 'bank_name') ? undefined : json['bank_name'],
        'customerName': !exists(json, 'customer_name') ? undefined : json['customer_name'],
        'sentToExternalService': !exists(json, 'sent_to_external_service') ? undefined : json['sent_to_external_service'],
        'earliestApprovalDate': !exists(json, 'earliest_approval_date') ? undefined : json['earliest_approval_date'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'authorizationType': !exists(json, 'authorization_type') ? undefined : json['authorization_type'],
        'zapierIdentifier': !exists(json, 'zapier_identifier') ? undefined : json['zapier_identifier'],
        'platformPayoutAmount': !exists(json, 'platform_payout_amount') ? undefined : json['platform_payout_amount'],
        'platformId': !exists(json, 'platform_id') ? undefined : json['platform_id'],
        'declineDate': !exists(json, 'decline_date') ? undefined : json['decline_date'],
        'bankAccount': !exists(json, 'bank_account') ? undefined : BankAccountFromJSON(json['bank_account']),
    };
}

export function FinancialTransactionToJSON(value?: FinancialTransaction): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'amount': value.amount,
        'status': value.status,
        'status_reason': value.statusReason,
        'tx_number': value.txNumber,
        'comment': value.comment,
        'process_date': value.processDate,
        'settled_date': value.settledDate,
        'customer_id': value.customerId,
        'transaction_schedule_id': value.transactionScheduleId,
        'batch_id': value.batchId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'bank_name': value.bankName,
        'customer_name': value.customerName,
        'sent_to_external_service': value.sentToExternalService,
        'earliest_approval_date': value.earliestApprovalDate,
        'country_code': value.countryCode,
        'authorization_type': value.authorizationType,
        'zapier_identifier': value.zapierIdentifier,
        'platform_payout_amount': value.platformPayoutAmount,
        'platform_id': value.platformId,
        'decline_date': value.declineDate,
        'bank_account': BankAccountToJSON(value.bankAccount),
    };
}


