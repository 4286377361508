// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CsvCustomerImport,
    CsvCustomerImportFromJSON,
    CsvCustomerImportToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomersImport
 */
export interface CustomersImport  {
    /**
     * 
     * @type {number}
     * @memberof CustomersImport
     */
    clientId?: number;
    /**
     * 
     * @type {Array<CsvCustomerImport>}
     * @memberof CustomersImport
     */
    selectedCustomers?: Array<CsvCustomerImport>;
}

export function CustomersImportFromJSON(json: any): CustomersImport {
    return {
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'selectedCustomers': !exists(json, 'selected_customers') ? undefined : (json['selected_customers'] as Array<any>).map(CsvCustomerImportFromJSON),
    };
}

export function CustomersImportToJSON(value?: CustomersImport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client_id': value.clientId,
        'selected_customers': value.selectedCustomers === undefined ? undefined : (value.selectedCustomers as Array<any>).map(CsvCustomerImportToJSON),
    };
}


