// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequiredEmailRedirect
 */
export interface RequiredEmailRedirect  {
    /**
     * 
     * @type {string}
     * @memberof RequiredEmailRedirect
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RequiredEmailRedirect
     */
    redirectUrl: string;
}

export function RequiredEmailRedirectFromJSON(json: any): RequiredEmailRedirect {
    return {
        'email': json['email'],
        'redirectUrl': json['redirect_url'],
    };
}

export function RequiredEmailRedirectToJSON(value?: RequiredEmailRedirect): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'redirect_url': value.redirectUrl,
    };
}


