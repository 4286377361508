// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError  {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    trace?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    activeClientId?: number;
}

export function ModelErrorFromJSON(json: any): ModelError {
    return {
        'message': !exists(json, 'message') ? undefined : json['message'],
        'trace': !exists(json, 'trace') ? undefined : json['trace'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'activeClientId': !exists(json, 'active_client_id') ? undefined : json['active_client_id'],
    };
}

export function ModelErrorToJSON(value?: ModelError): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'message': value.message,
        'trace': value.trace,
        'path': value.path,
        'active_client_id': value.activeClientId,
    };
}


