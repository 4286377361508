// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorizationEmailSetting
 */
export interface AuthorizationEmailSetting  {
    /**
     * 
     * @type {number}
     * @memberof AuthorizationEmailSetting
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthorizationEmailSetting
     */
    clientId: number;
    /**
     * 
     * @type {Date}
     * @memberof AuthorizationEmailSetting
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AuthorizationEmailSetting
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationEmailSetting
     */
    customEmailMessage: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationEmailSetting
     */
    customTermsAndConditions: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationEmailSetting
     */
    enableGreeting?: boolean;
}

export function AuthorizationEmailSettingFromJSON(json: any): AuthorizationEmailSetting {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'customEmailMessage': json['custom_email_message'],
        'customTermsAndConditions': json['custom_terms_and_conditions'],
        'enableGreeting': !exists(json, 'enable_greeting') ? undefined : json['enable_greeting'],
    };
}

export function AuthorizationEmailSettingToJSON(value?: AuthorizationEmailSetting): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'client_id': value.clientId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'custom_email_message': value.customEmailMessage,
        'custom_terms_and_conditions': value.customTermsAndConditions,
        'enable_greeting': value.enableGreeting,
    };
}


