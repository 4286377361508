// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MicrodepositVerificationFormDetails
 */
export interface MicrodepositVerificationFormDetails  {
    /**
     * 
     * @type {string}
     * @memberof MicrodepositVerificationFormDetails
     */
    publicName?: string;
    /**
     * 
     * @type {string}
     * @memberof MicrodepositVerificationFormDetails
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof MicrodepositVerificationFormDetails
     */
    attempts?: number;
    /**
     * 
     * @type {string}
     * @memberof MicrodepositVerificationFormDetails
     */
    language?: string;
}

export function MicrodepositVerificationFormDetailsFromJSON(json: any): MicrodepositVerificationFormDetails {
    return {
        'publicName': !exists(json, 'public_name') ? undefined : json['public_name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'attempts': !exists(json, 'attempts') ? undefined : json['attempts'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function MicrodepositVerificationFormDetailsToJSON(value?: MicrodepositVerificationFormDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'public_name': value.publicName,
        'status': value.status,
        'attempts': value.attempts,
        'language': value.language,
    };
}


