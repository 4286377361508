// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomersCsvImport200Response
 */
export interface CustomersCsvImport200Response  {
    /**
     * 
     * @type {number}
     * @memberof CustomersCsvImport200Response
     */
    status?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersCsvImport200Response
     */
    errors?: Array<string>;
}

export function CustomersCsvImport200ResponseFromJSON(json: any): CustomersCsvImport200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    };
}

export function CustomersCsvImport200ResponseToJSON(value?: CustomersCsvImport200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'errors': value.errors,
    };
}


