// @flow
import React from 'react'
import { Link } from 'react-router-dom'

import { ProfileDropdown } from './profileDropdown'
import logo from 'assets/images/logo.png'
import { BreadcrumbView } from './breadcrumb'
type TopbarProps = {
  showRightSidebar?: any
  hideLogo?: boolean
  navCssClasses?: string
  openLeftMenuCallBack?: any
  disableMobileMenu?: boolean
}

export const TopBar = (props: TopbarProps) => {
  const hideLogo = props.hideLogo || true
  const containerCssClasses = !hideLogo ? 'container-fluid' : ''

  const showHideLeftMenu = () => {
    if (document.body && document.body.classList.contains('sidebar-enable')) {
      document.body.classList.remove('sidebar-enable')
    } else {
      document.body.classList.add('sidebar-enable')
    }
  }

  return (
    <>
      <div className={`navbar-custom`}>
        <div className={containerCssClasses}>
          {!hideLogo && (
            <Link to="/" className="topnav-logo">
              <span className="topnav-logo-lg">
                <img src={logo} alt="logo" height="20" />
              </span>
            </Link>
          )}

          <button
            className="button-menu-mobile open-left disable-btn mobile-menu-enable"
            id="mobile_menu_button"
            onClick={showHideLeftMenu}
            disabled={props.disableMobileMenu}
            style={props.disableMobileMenu ? { opacity: 0 } : undefined}
          >
            <i id="mobile-menu-closed" className="mdi mdi-menu"></i>
            <i id="mobile-menu-open" className="mdi mdi-menu-open"></i>
          </button>

          <span className="topbar-left-menu float-left">
            <BreadcrumbView />
          </span>

          <span className="topbar-middle-logo float-center ">
            <span className="center-helper"></span>
            <span className="topnav-logo-sm">
              <img src={logo} alt="logo" />
            </span>
          </span>

          <span className="topbar-right-menu float-right">
            <ProfileDropdown />
          </span>
        </div>
      </div>
    </>
  )
}
