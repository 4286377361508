import React, { useRef, useState } from 'react'
import { useMutation } from 'redux-query-react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col } from 'reactstrap'

import { CustomerNote, createCustomerNote } from 'api'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'

export interface CustomerNoteFormProps {
  customerId?: number
}

export const CustomerNoteForm = ({ customerId }: CustomerNoteFormProps) => {
  const history = useHistory()
  const newCustomerNote: CustomerNote = {
    customerId: customerId || 0,
    description: '',
  }
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const formRef = useRef<AvForm>()

  const [{ isPending }, doMutation] = useMutation((values: CustomerNote) =>
    createCustomerNote({ customerNote: values }),
  )

  const handleValidSubmit = (event, values) => {
    doMutation({
      ...values,
      customerId: customerId,
    })?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      if (response?.body?.status === 200) {
        history.push(`/client/customers/${customerId}`)
      }
    })
  }
  return (
    <Row>
      <Col md={{ size: 6, offset: 3 }}>
        <div className="page-title-box">
          <h4 className="page-title">New Customer Note</h4>
        </div>
        <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
        <AvForm disabled={isPending} onValidSubmit={handleValidSubmit} model={newCustomerNote} ref={formRef}>
          <Card>
            <CardBody>
              <AvField
                name="description"
                type="textarea"
                required
                onChange={handleFieldChange}
                validate={{ async: validate }}
              />
            </CardBody>
          </Card>

          <div className="text-right">
            <Button onClick={() => history.goBack()} disabled={isPending} className="btn-rotessa-secondary min-width">
              Cancel
            </Button>
            <Button className="ml-1" disabled={isPending} color="primary">
              Submit
            </Button>
          </div>
        </AvForm>
      </Col>
    </Row>
  )
}
