import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest, useMutation } from 'redux-query-react'

import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col, Fade, Label } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { Customer, searchCustomers, updateCustomer } from 'api'
import { getActiveClient, getEntities } from 'app/selectors'
import { StateProvinceSelect } from 'components/ProvinceSelect'
import { PostalCodeField } from 'components/PostalCodeField'
import { Loading } from 'components/Loading'
import { useSetBreadcrumbTitle } from 'hooks/useSetBreadcrumbTitle'
import { CenterContent } from 'components/CenterContent'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { validatePhone } from 'utilities'

export interface CustomerFormProps {
  clientId?: number
  customerId: number
}

export const CustomerContactForm = ({ customerId }: CustomerFormProps) => {
  const history = useHistory()
  const [isPending, setIsPending] = useState(false)
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const activeClient = useSelector(getActiveClient) || {}
  const formRef = useRef<AvForm>()

  const customer: Customer = useSelector((state) => getEntities(state).customer) || {}
  useSetBreadcrumbTitle(customer?.id?.toString(), customer?.name || '')
  const [requestState, doRequest] = useRequest(
    !activeClient?.id
      ? null
      : searchCustomers(
          {
            clientId: activeClient?.id || 0,
            customerId: customerId,
          },
          {
            force: true,
            transform: (response) => {
              return { customer: response.records?.[0] }
            },
            update: {
              customer: (_, newValue) => newValue,
            },
          },
        ),
  )

  const [saveCustomerState, doSaveCustomer] = useMutation((customer: Customer) => {
    return updateCustomer({
      id: customerId,
      customer: customer,
    })
  })

  function handleValidSubmit(event, customer) {
    setIsPending(true)
    customer.clientId = activeClient.id
    doSaveCustomer(customer)?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      if (response?.body?.status === 200 && response?.body?.id) {
        history.push(`/client/customers/${response.body.id}`)
      } else {
        setIsPending(false)
      }
    })
  }

  if (requestState.isPending) {
    return <Loading />
  }

  const title = 'Edit Contact Details'

  return (
    <Fade>
      <div className="mb-2">
        <CenterContent>
          <div className="page-title-box">
            <h4 className="page-title">{title}</h4>
          </div>
          <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
          <AvForm disabled={isPending} onValidSubmit={handleValidSubmit} model={customer} ref={formRef}>
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Basic Info</h4>
                <Row>
                  <Col lg={6}>
                    <Label>
                      Name <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      validate={{ async: validate }}
                      onChange={handleFieldChange}
                      name="name"
                      type="text"
                      placeholder="John Smith"
                      required
                    />
                  </Col>
                  <Col lg={6}>
                    <AvField
                      validate={{
                        async: validate,
                        email: {
                          value: true,
                          errorMessage: 'Please enter a valid email address',
                        },
                      }}
                      onChange={handleFieldChange}
                      name="email"
                      label="Email"
                      type="text"
                      placeholder="john@smith.com"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <AvField
                      validate={{ tel: validatePhone, async: validate }}
                      onChange={handleFieldChange}
                      name="phone"
                      label="Phone"
                      placeholder="10 digits"
                      type="text"
                    />
                  </Col>
                  <Col lg={6}>
                    <AvField
                      validate={{ async: validate }}
                      onChange={handleFieldChange}
                      name="customerType"
                      label="Customer Type"
                      type="select"
                      required
                    >
                      <option value="Personal">Personal</option>
                      <option value="Business">Business</option>
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <AvField
                      validate={{ async: validate }}
                      onChange={handleFieldChange}
                      name="customIdentifier"
                      label="Custom Identifier"
                      placeholder="for integrations"
                      type="text"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col md={9}>
                    <h4 className="header-title mb-3">Address</h4>
                  </Col>
                </Row>
                <Row>
                  <AvField
                    validate={{ async: validate }}
                    onChange={handleFieldChange}
                    name="addressesAttributes[0].id"
                    type="hidden"
                  />
                  <Col lg={6}>
                    <AvField
                      validate={{ async: validate }}
                      onChange={handleFieldChange}
                      name="addressesAttributes[0].address1"
                      label="Street"
                      type="text"
                    />
                  </Col>
                  <Col lg={6}>
                    <AvField
                      validate={{ async: validate }}
                      onChange={handleFieldChange}
                      name="addressesAttributes[0].city"
                      label="City"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <StateProvinceSelect
                      name="addressesAttributes[0].provinceCode"
                      countryCode={activeClient.countryCode}
                      serverErrors={serverErrors}
                    />
                  </Col>
                  <Col lg={6}>
                    <PostalCodeField
                      name="addressesAttributes[0].postalCode"
                      countryCode={activeClient.countryCode}
                      serverErrors={serverErrors}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <div className="inline-container">
                <Button
                  disabled={isPending}
                  onClick={() => history.goBack()}
                  className="btn-rotessa-secondary min-width ml-1 mb-2"
                >
                  Cancel
                </Button>
                <Button disabled={isPending} color="primary" className="ml-1 mb-2">
                  Submit
                </Button>
              </div>
            </div>
          </AvForm>
        </CenterContent>
      </div>
    </Fade>
  )
}
