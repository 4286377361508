import React, { useEffect, useState } from 'react'
import { Col, Fade, Row } from 'reactstrap'

import { Badge, Button, Card, CardBody, CustomInput, Label } from 'reactstrap'

import { Errors } from 'hooks/useFormServerErrors'
import { getSort } from 'utilities'

import {
  Customer,
  NotificationSettings,
  NotificationSettingsFromJSON,
  getNotificationSettings,
  performCustomerAction,
  searchCustomers,
  updateNotificationSettings,
} from 'api'
import {
  getActiveClient,
  getCustomerActions,
  getCustomers,
  getCustomersBeforeSearchCount,
  getCustomersCount,
  getEntities,
  justReplace,
} from 'app/selectors'
import { InfoPopover } from 'components/InfoPopover'
import { Loading } from 'components/Loading'
import { LoadingMask, LoadingMaskCentered } from 'components/LoadingMask'
import { Notification } from 'components/Notification'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { useDispatch, useSelector } from 'react-redux'
import { updateEntities } from 'redux-query'
import { useMutation, useRequest } from 'redux-query-react'

import { AvForm } from 'availity-reactstrap-validation'
import { MyEditor } from 'components/Editor'
import { ExternalLink } from 'components/ExternalLink'
import { FormServerErrors } from 'components/FormServerErrors'
import { FormServerNotifications } from 'components/FormServerNotifications'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'
import { ColumnProps, OnTableChangeProps, Table, sizePerPageRenderer } from 'components/Table'
import { TableSearchField } from 'components/TableHeader'
import { CustomerIcons } from 'features/customers/customerIcons'
import { useIsMobile } from 'hooks/useIsMobile'
import { cloneDeep } from 'lodash'
import { Link } from 'react-router-dom'

type optionType = {
  type: string
  name?: string
  label?: string | JSX.Element
  path?: string
  options?: {
    value: string
    label: string
  }[]
  info?: string
  disabled?: boolean
  new?: boolean
  code?: JSX.Element
  root?: string
}

export type optionsType = {
  type: string
  name: string
  info?: string
  items: optionType[]
  anchor?: string
  last_item?: boolean
}[]

export const NotificationSettingsPage = () => {
  // start table portion
  const MAX_ROWS_BEFORE_SCROLLING = 10
  const activeClient = useSelector(getActiveClient) || {}
  const customers = useSelector(getCustomers) || []
  const customersCount = useSelector(getCustomersCount) || 0
  const beforeSearchCount = useSelector(getCustomersBeforeSearchCount) || 0
  const [customersForTable, setCustomersForTable] = useState([] as Customer[])
  useClearEntitiesOnUnmount(['customers'])
  const [search, setSearch] = useState('')
  const isMobile = useIsMobile()
  const customerActions = useSelector(getCustomerActions) || {}
  const [customerTableErrors, setCustomerTableErrors] = useState<string[]>([])
  const [customerTableNotifications, setCustomerTableNotifications] = useState<string[]>([])
  const [isMutatingCustomers, setIsMutatingCustomers] = useState<boolean>(false)

  const PAGE_SIZE_OPTIONS = [200]

  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
    selectedRows: [],
    sortField: 'createdAt',
    sortOrder: 'desc',
  })

  const [customerActionMutationMutationState, doCustomerActionMutation] = useMutation(
    (customerIds: number[], action: string, isBulk: boolean) =>
      performCustomerAction({
        customerAction: {
          ids: customerIds,
          name: action,
          isBulk: isBulk,
        },
      }),
  )

  const handleSubmit = () => {
    const enablePaymentReminderNotificationIds: number[] = []
    const disablePaymentReminderNotificationIds: number[] = []
    const enablePaymentReceiptNotificationIds: number[] = []
    const disablePaymentReceiptNotificationIds: number[] = []

    customersForTable.forEach((updatedCustomer, index) => {
      const existingCustomer = customers[index]
      if (
        !existingCustomer.notificationSettings?.paymentReminder?.enabled &&
        updatedCustomer.notificationSettings?.paymentReminder?.enabled
      ) {
        enablePaymentReminderNotificationIds.push(existingCustomer.id)
      } else if (
        existingCustomer.notificationSettings?.paymentReminder?.enabled &&
        !updatedCustomer.notificationSettings?.paymentReminder?.enabled
      ) {
        disablePaymentReminderNotificationIds.push(existingCustomer.id)
      }

      if (
        !existingCustomer.notificationSettings?.paymentReceipt?.enabled &&
        updatedCustomer.notificationSettings?.paymentReceipt?.enabled
      ) {
        enablePaymentReceiptNotificationIds.push(existingCustomer.id)
      } else if (
        existingCustomer.notificationSettings?.paymentReceipt?.enabled &&
        !updatedCustomer.notificationSettings?.paymentReceipt?.enabled
      ) {
        disablePaymentReceiptNotificationIds.push(existingCustomer.id)
      }
    })

    setIsMutatingCustomers(true)
    Promise.all([
      doCustomerActionMutation(
        enablePaymentReminderNotificationIds,
        customerActions.enablePaymentReminderNotifications,
        true,
      ),
      doCustomerActionMutation(
        disablePaymentReminderNotificationIds,
        customerActions.disablePaymentReminderNotifications,
        true,
      ),
    ])
      .then((responses) => {
        responses.forEach((response) => {
          setCustomerTableErrors((prev) => {
            return prev.concat(response?.body?.messages?.errors || [])
          })
          setCustomerTableNotifications((prev) => {
            return prev.concat(response?.body?.messages?.notifications || [])
          })
        })
        Promise.all([
          doCustomerActionMutation(
            enablePaymentReceiptNotificationIds,
            customerActions.enablePaymentReceiptNotifications,
            true,
          ),
          doCustomerActionMutation(
            disablePaymentReceiptNotificationIds,
            customerActions.disablePaymentReceiptNotifications,
            true,
          ),
        ])
          .then((responses) => {
            responses.forEach((response) => {
              setCustomerTableErrors((prev) => {
                return prev.concat(response?.body?.messages?.errors || [])
              })
              setCustomerTableNotifications((prev) => {
                return prev.concat(response?.body?.messages?.notifications || [])
              })
            })
          })
          .finally(() => doSearchCustomersRequest())
      })
      .catch(() => doSearchCustomersRequest())
  }

  const onTableChange = (_type: string, { page, sizePerPage }: OnTableChangeProps) => {
    setTableProps({
      ...tableProps,
      page: page || tableProps.page,
      pageSize: sizePerPage || tableProps.pageSize,
      selectedRows: [],
    })
  }

  const [searchCustomersRequestState, doSearchCustomersRequest] = useRequest(
    !activeClient?.id
      ? null
      : searchCustomers(
          {
            clientId: activeClient.id,
            skip: (tableProps.page - 1) * tableProps.pageSize,
            limit: tableProps.pageSize,
            search: search,
            filters: { active: true },
            sort: getSort(tableProps.sortField, tableProps.sortOrder),
          },
          {
            force: true,
            transform: (response) => {
              return {
                customers: response.records,
                customersCount: response.count,
                customersBeforeSearchCount: response.beforeSearchCount,
              }
            },
            update: {
              customers: (_, newValue) => newValue,
              customersCount: (_, newValue) => newValue,
              customersBeforeSearchCount: (_, newValue) => newValue,
            },
          },
        ),
  )

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setTableProps({ ...tableProps, page: 1 })
  }

  useEffect(() => {
    if (searchCustomersRequestState.isFinished && customers) {
      setCustomersForTable(cloneDeep(customers))
    }
  }, [customers])

  useEffect(() => {
    if (isMutatingCustomers) {
      setIsMutatingCustomers(false)
    }
  }, [customersForTable])

  const changedCustomers = () => {
    return customersForTable.filter((customer, index) => JSON.stringify(customer) !== JSON.stringify(customers[index]))
  }

  const MobileCustomerDetailsFormatter = (_customerName, row) => {
    return (
      <>
        <Row noGutters className="clearfix">
          <Col style={{ verticalAlign: 'middle' }} xs="auto">
            {row['name']}
          </Col>
          <Col className="ml-4" xs="auto d-flex align-items-center ">
            {
              <CustomerIcons
                statuses={{ notifications: row['statuses']['notifications'] }}
                customer={row}
                className="table-icon"
              />
            }
          </Col>
        </Row>

        <Row noGutters className="clearfix">
          <Col xs="auto">{<small className="text-medium">{row['email']}</small>}</Col>
        </Row>
        <Row noGutters className="mt-1 mb-1">
          <Col className="d-flex align-items-center word-wrap">
            <small>Payment Reminder</small>
            <CustomInput
              type="switch"
              inline
              id={`paymentReminder${row.id}`}
              className="ml-2"
              name={'paymentReminder'}
              checked={row.notificationSettings?.paymentReminder?.enabled}
              disabled={row.notificationSettings?.paymentReminder?.unsubscribed}
              onChange={(event) => {
                setCustomersForTable((prev) => {
                  let updatedRows = [...prev]
                  let customer = updatedRows.find((customer) => customer.id === row.id)
                  if (customer) {
                    customer.notificationSettings!.paymentReminder ??
                      (customer.notificationSettings!.paymentReminder = {})
                    customer.notificationSettings!.paymentReminder.enabled = event.target.checked
                  }
                  return updatedRows
                })
              }}
            />
          </Col>
        </Row>
        <Row noGutters className="mt-1 mb-1">
          <Col className="d-flex align-items-center word-wrap">
            <small>Payment Receipt</small>
            <CustomInput
              type="switch"
              inline
              id={`paymentReceipt${row.id}`}
              className="ml-2"
              name={'paymentReceipt'}
              checked={row.notificationSettings?.paymentReceipt?.enabled}
              disabled={row.notificationSettings?.paymentReceipt?.unsubscribed}
              onChange={(event) => {
                setCustomersForTable((prev) => {
                  const updatedRows = [...prev]
                  const customer = updatedRows.find((customer) => customer.id === row.id)
                  if (customer) {
                    customer.notificationSettings!.paymentReceipt ??
                      (customer.notificationSettings!.paymentReceipt = {})
                    customer.notificationSettings!.paymentReceipt.enabled = event.target.checked
                  }
                  return updatedRows
                })
              }}
            />
          </Col>
        </Row>
      </>
    )
  }

  const CustomerStatusesFormatter = (statuses, row) => {
    return (
      <CustomerIcons statuses={{ notifications: statuses['notifications'] }} customer={row} className="table-icon" />
    )
  }

  const PaymentReminderHeaderFormatter = (cell, row) => {
    return (
      <>
        <div className="text-right mb-1">
          Payment
          <br />
          Reminder
        </div>
        <div className="float-right">
          <CustomInput
            type="switch"
            id={`paymentReminderHeader`}
            name={'paymentReminderHeader'}
            checked={
              customersForTable.length > 0 &&
              customersForTable.every((r) => r.notificationSettings?.paymentReminder?.enabled)
            }
            disabled={customersForTable.length === 0}
            onChange={(event) => {
              setCustomersForTable((prev) => {
                let updatedRows = [...prev]
                updatedRows.map((customer, index) => {
                  customer.notificationSettings!.paymentReminder ??
                    (customer.notificationSettings!.paymentReminder = {})
                  customer.notificationSettings!.paymentReminder.enabled = event.target.checked
                })
                return updatedRows
              })
            }}
          />
        </div>
      </>
    )
  }

  const PaymentReminderFormatter = (cell, row) => {
    return (
      <div className="d-flex align-items-center float-right">
        <div>
          <CustomInput
            type="switch"
            id={`paymentReminder${row.id}`}
            name={'paymentReminder'}
            checked={row.notificationSettings?.paymentReminder?.enabled}
            disabled={row.notificationSettings?.paymentReminder?.unsubscribed}
            onChange={(event) => {
              setCustomersForTable((prev) => {
                let updatedRows = [...prev]
                let customer = updatedRows.find((customer) => customer.id === row.id)
                if (customer) {
                  customer.notificationSettings!.paymentReminder ??
                    (customer.notificationSettings!.paymentReminder = {})
                  customer.notificationSettings!.paymentReminder.enabled = event.target.checked
                }
                return updatedRows
              })
            }}
          />
        </div>
      </div>
    )
  }
  const PaymentReceiptHeaderFormatter = (cell, row) => {
    return (
      <>
        <div className="text-right mb-1">
          Payment
          <br />
          Receipt
        </div>
        <div className="float-right">
          <CustomInput
            type="switch"
            id={`paymentReceiptHeader`}
            name={'paymentReceiptHeader'}
            checked={
              customersForTable.length > 0 &&
              customersForTable.every((r) => r.notificationSettings?.paymentReceipt?.enabled)
            }
            disabled={customersForTable.length === 0}
            onChange={(event) => {
              setCustomersForTable((prev) => {
                let updatedRows = [...prev]
                updatedRows.map((customer, index) => {
                  customer.notificationSettings!.paymentReceipt ?? (customer.notificationSettings!.paymentReceipt = {})
                  customer.notificationSettings!.paymentReceipt.enabled = event.target.checked
                })
                return updatedRows
              })
            }}
          />
        </div>
      </>
    )
  }

  const PaymentReceiptFormatter = (cell, row) => {
    return (
      <div className="d-flex align-items-center float-right">
        <div>
          <CustomInput
            type="switch"
            id={`paymentReceipt${row.id}`}
            name={'paymentReceipt'}
            checked={row.notificationSettings?.paymentReceipt?.enabled}
            disabled={row.notificationSettings?.paymentReceipt?.unsubscribed}
            onChange={(event) => {
              setCustomersForTable((prev) => {
                let updatedRows = [...prev]
                let customer = updatedRows.find((customer) => customer.id === row.id)
                if (customer) {
                  customer.notificationSettings!.paymentReceipt ?? (customer.notificationSettings!.paymentReceipt = {})
                  customer.notificationSettings!.paymentReceipt.enabled = event.target.checked
                }
                return updatedRows
              })
            }}
          />
        </div>
      </div>
    )
  }

  const CustomerDetailsFormatter = (cell, row) => {
    return (
      <p className="mb-2 align-middle" style={{ lineHeight: 1 }}>
        <span>{row.name}</span>
        <br />
        <small className="text-muted">{row.email}</small>
      </p>
    )
  }

  const columns: ColumnProps[] = isMobile
    ? [
        {
          dataField: 'name',
          text: '',
          formatter: MobileCustomerDetailsFormatter,
          sort: false,
          classes: 'align-middle w-75',
          headerStyle: { borderStyle: 'none', width: '75%' },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              e.stopPropagation()
            },
          },
        },
      ]
    : [
        {
          dataField: 'name',
          text: '',
          formatter: CustomerDetailsFormatter,
          sort: false,
          classes: 'align-middle w-75',
          headerStyle: { borderStyle: 'none', width: '75%' },
        },
        {
          dataField: 'statuses',
          formatter: CustomerStatusesFormatter,
          text: '',
          sort: false,
          classes: 'td-badge',
        },
        {
          dataField: '',
          text: '',
          formatter: PaymentReminderFormatter,
          sort: false,
          classes: 'align-middle w-25 ',
          headerFormatter: PaymentReminderHeaderFormatter,
          headerStyle: { borderStyle: 'none', width: '25%' },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              e.stopPropagation()
            },
          },
        },
        {
          dataField: '',
          text: '',
          formatter: PaymentReceiptFormatter,
          sort: false,
          classes: 'align-middle w-25 ',
          headerFormatter: PaymentReceiptHeaderFormatter,
          headerStyle: { borderStyle: 'none', width: '25%' },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              e.stopPropagation()
            },
          },
        },
      ]

  // end table portion

  const dispatch = useDispatch()
  const notificationSettings = useSelector((state) => getEntities(state).notificationSettings)
  const [originalNotificationSettings, setOriginalNotificationSettings] = useState<NotificationSettings>()
  const [waitForFinished, setWaitForFinished] = useState(true)
  const [changesSaved, setChangesSaved] = useState(false)
  const [hideChangesSavedTimeout, setHideChangesSavedTimeout] = useState<NodeJS.Timeout>()
  const hash = window.location.hash.replace(/[#\/]/gm, '')

  const [serverErrors, setServerErrors] = useState<Errors>()

  useClearEntitiesOnUnmount(['notificationSettings'])

  useEffect(() => {
    if (!originalNotificationSettings && notificationSettings) {
      setOriginalNotificationSettings(cloneDeep(notificationSettings))
    }
  }, [notificationSettings, waitForFinished])

  const [requestState, doRequest] = useRequest(
    activeClient?.id &&
      getNotificationSettings(
        { clientId: activeClient.id },
        {
          force: true,
          transform: (body) => ({ notificationSettings: body.notificationSettings }),
          update: { notificationSettings: justReplace },
        },
      ),
  )

  const [mutationState, doMutation] = useMutation((values: NotificationSettings) =>
    updateNotificationSettings({
      clientId: activeClient.id,
      notificationSettings: { ...values },
    }),
  )

  const handleMutationResponse = (response) => {
    if (response?.body?.status === 200) {
      setChangesSaved(true)
      hideChangesSavedTimeout && clearTimeout(hideChangesSavedTimeout)
      setHideChangesSavedTimeout(setTimeout(() => setChangesSaved(false), 3000))
      const newNotificationSettings = NotificationSettingsFromJSON(response?.body?.notification_settings)
      dispatch(updateEntities({ notificationSettings: (_old) => newNotificationSettings }))
      setOriginalNotificationSettings(cloneDeep(newNotificationSettings))
    }
    setServerErrors(response?.body?.messages || {})
  }

  const handleToggle = (event) => {
    const [root, name] = event.target.name.split('.')

    let change = {}
    if (root) {
      change[root] = {}
      change[root][name] = event.target.checked
    } else {
      change[name] = event.target.checked
    }
    doMutation(change)?.then((response) => {
      handleMutationResponse(response)
    })
  }

  const handleRadio = (event) => {
    const [root, name] = event.target.name.split('.')

    let change = {}
    if (root) {
      if (!change[root]) {
        change[root] = {}
      }
      change[root][name] = event.target.value
    } else {
      change[name] = event.target.value
    }

    doMutation(change)?.then((response) => {
      handleMutationResponse(response)
    })
  }

  const handleFieldChange = (name: string, value: any, root?: string) => {
    let change = {}
    if (root) {
      change[root] = {}
      change[root][name] = value
    } else {
      change[name] = value
    }

    doMutation(change)?.then((response) => {
      handleMutationResponse(response)
    })
  }

  const generateOption = (option: optionType, i: number) => {
    let optionContent
    switch (option.type) {
      case 'toggle':
        optionContent = (
          <CustomInput
            type="switch"
            id={`${option.root!}${option.name!}`}
            name={`${option.root!}.${option.name!}`}
            label={option.label}
            className="inline-block mb-2"
            checked={
              option.root ? notificationSettings?.[option.root!]?.[option.name!] : notificationSettings?.[option.name!]
            }
            onChange={(event) => {
              handleToggle(event)
            }}
            disabled={option.disabled}
          />
        )
        break
      case 'radio':
        optionContent = (
          <>
            <Label inline className="mb-2">
              {option.label}
            </Label>
            {option.options?.map((radio, i) => (
              <CustomInput
                key={i}
                type="radio"
                id={`${option.root!}${option.name!}_${radio.value}`}
                name={`${option.root!}.${option.name!}`}
                value={radio.value}
                label={radio.label}
                className="inline-block ml-3"
                checked={
                  (option.root
                    ? notificationSettings?.[option.root!]?.[option.name!]
                    : notificationSettings?.[option.name!]) === radio.value
                }
                onChange={(event) => {
                  handleRadio(event)
                }}
                disabled={option.disabled}
              />
            ))}
          </>
        )
        break
      case 'code':
        optionContent = option.code
        break
      case 'label':
        optionContent = <Label className="mb-2">{option.label}</Label>
        break
      case 'editor':
        optionContent = (
          <>
            <Label>{option.label}</Label>
            <MyEditor
              onChange={(text) => {
                handleFieldChange(option.name!, text, option.root)
              }}
              value={
                (option.root
                  ? notificationSettings?.[option.root!]?.[option.name!]
                  : notificationSettings?.[option.name!]) || ''
              }
              withBorder
            />
          </>
        )
        break
    }

    return (
      <div key={i} className="mb-1">
        {optionContent}
        {option.info && <InfoPopover popoverText={option.info} />}
        {option.new && (
          <Badge pill color="info-lighten" className="ml-1">
            New
          </Badge>
        )}
      </div>
    )
  }

  const options: optionsType = [
    {
      type: 'header',
      name: 'EMAIL PAYMENT REMINDERS',
      anchor: 'payment_reminder',
      items: [
        {
          type: 'label',
          label: (
            <span>
              Reminders are sent three days before the process date.
              <InfoPopover
                popoverText={
                  <span>
                    Send email reminders to your customers for upcoming payments.{' '}
                    <ExternalLink to="https://support.rotessa.com/article/58-payment-reminders?utm_source=appfeaturetooltip">
                      Learn more.
                    </ExternalLink>
                  </span>
                }
              />
            </span>
          ),
        },
        {
          type: 'toggle',
          root: 'paymentReminder',
          name: 'enabledByDefault',
          label: <span>Enable by default for new customers</span>,
        },
        {
          type: 'radio',
          root: 'paymentReminder',
          name: 'language',
          label: 'Default language:',
          options: [
            { value: 'en', label: 'English' },
            { value: 'fr', label: 'French' },
          ],
        },
        {
          type: 'label',
          label: <span className="header-title">PREVIEW</span>,
        },
        {
          type: 'code',
          label: 'Preview',
          code: (
            <div>
              <div className="auth-preview-header">
                <i className="dot ml-2" />
                <i className="dot ml-1" />
                <i className="dot ml-1" />
              </div>
              <div className="auth-preview-container mb-4">
                <Card>
                  <CardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          notificationSettings?.paymentReminder?.bodyContent[
                            notificationSettings?.paymentReminder?.language
                          ],
                      }}
                    />
                    <hr />
                    <PoweredByRotessaFooter
                      text={
                        notificationSettings?.paymentReminder?.footerText[
                          notificationSettings?.paymentReminder?.language
                        ]
                      }
                      noMargin
                    />
                    <div className="d-flex justify-content-center mt-1">
                      <Link to="#" className="text-muted">
                        <small>
                          <u className="text-xs">
                            {
                              notificationSettings?.paymentReminder?.unsubscribe[
                                notificationSettings?.paymentReminder?.language
                              ]
                            }
                          </u>
                        </small>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      type: 'header',
      name: 'EMAIL PAYMENT RECEIPTS',
      anchor: 'payment_receipt',
      items: [
        {
          type: 'label',
          label: (
            <span>
              Receipts are sent on the settlement date.
              <InfoPopover
                popoverText={
                  <span>
                    Send email receipts to your customers for their payments.{' '}
                    <ExternalLink to="https://support.rotessa.com/article/59-payment-receipts?utm_source=appfeaturetooltip">
                      Learn more.
                    </ExternalLink>
                  </span>
                }
              />
            </span>
          ),
        },
        {
          type: 'toggle',
          root: 'paymentReceipt',
          name: 'enabledByDefault',
          label: <span>Enable by default for new customers</span>,
        },
        {
          type: 'radio',
          root: 'paymentReceipt',
          name: 'language',
          label: 'Default language:',
          options: [
            { value: 'en', label: 'English' },
            { value: 'fr', label: 'French' },
          ],
        },
        {
          type: 'label',
          label: <span className="header-title">PREVIEW</span>,
        },
        {
          type: 'code',
          label: 'Preview',
          code: (
            <div>
              <div className="auth-preview-header">
                <i className="dot ml-2" />
                <i className="dot ml-1" />
                <i className="dot ml-1" />
              </div>
              <div className="auth-preview-container mb-4">
                <Card>
                  <CardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          notificationSettings?.paymentReceipt?.bodyContent[
                            notificationSettings?.paymentReceipt?.language
                          ],
                      }}
                    />
                    <hr />
                    <PoweredByRotessaFooter
                      text={
                        notificationSettings?.paymentReceipt?.footerText[notificationSettings?.paymentReceipt?.language]
                      }
                      noMargin
                    />
                    <div className="d-flex justify-content-center mt-2">
                      <Link to="#" className="text-muted">
                        <small>
                          <u className="text-xs">
                            {
                              notificationSettings?.paymentReceipt?.unsubscribe[
                                notificationSettings?.paymentReceipt?.language
                              ]
                            }
                          </u>
                        </small>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          ),
        },
      ],
      last_item: true,
    },
  ]

  if (requestState.isFinished && waitForFinished && notificationSettings) {
    setWaitForFinished(false)
  }
  if (requestState.isPending || waitForFinished) return <Loading />
  return (
    <Col>
      <Fade in={changesSaved}>
        <Notification text="Changes saved" color="success" />
      </Fade>
      <div className="page-title-box">
        <h4 className="page-title">Notification Settings</h4>
      </div>
      {requestState.isPending && <LoadingMask />}
      <Row>
        <Col xl={6}>
          <AvForm disabled={requestState.isPending || mutationState.isPending} model={notificationSettings}>
            <FormServerErrors errors={(serverErrors && serverErrors?.global) || []} />

            <Card>
              <CardBody>
                {options.map((header, i) => {
                  return (
                    <>
                      <h4 className="header-title mb-3">{header.name}</h4>
                      <>{header.items.filter((option) => option.type !== 'hidden').map(generateOption)}</>
                      {!header.last_item && <hr></hr>}
                    </>
                  )
                })}
              </CardBody>
            </Card>
          </AvForm>
        </Col>

        <Col xl={6} className="position-relative">
          <Card>
            <CardBody>
              <h4 className="header-title mb-3">Customer Notification Settings</h4>
              {isMutatingCustomers && <LoadingMaskCentered />}
              <FormServerErrors errors={customerTableErrors || []} />
              <FormServerNotifications notifications={customerTableNotifications || []} />
              <Row className="mb-2 justify-content-between">
                <Col md="6" xs="12" className="col-6">
                  <TableSearchField onChange={handleSearchChange} className="w-100" />
                </Col>
                {search && (
                  <Col xs="12" md="auto" className="d-flex align-items-center">
                    <span className="text-muted">
                      {customersCount}/{beforeSearchCount} matches
                    </span>
                  </Col>
                )}
                <Col xs="12" md="auto" className={`${isMobile ? 'mb-2 order-first' : 'text-right float-right'}`}>
                  <Button
                    className="w-100"
                    disabled={changedCustomers().length === 0}
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save {changedCustomers().length} Customers
                  </Button>
                </Col>
              </Row>
              <Table
                loading={searchCustomersRequestState.isPending}
                data={customersForTable}
                columns={columns}
                sortField={tableProps.sortField}
                sortOrder={tableProps.sortOrder}
                paginationProps={{
                  page: tableProps.page,
                  totalSize: customersCount,
                  sizePerPage: tableProps.pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  sizePerPageRenderer: sizePerPageRenderer,
                }}
                onTableChange={onTableChange}
                scrollBody={customersForTable.length > MAX_ROWS_BEFORE_SCROLLING}
                scrollBodyHeight="500px"
                style={{ borderStyle: 'none' }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}
