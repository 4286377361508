import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Alert,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useRequest } from 'redux-query-react'
import { Link, useHistory } from 'react-router-dom'
import {
  exportSettlementTransactions,
  searchSettlementTransactions,
  exportSettlementTransactionsPdf,
  reportSettlementTransactions,
} from 'api'
import { formatDateGreg, getSort, isPermitted, titleify } from 'utilities'
import { getActiveClient, getEntities, getTransactionStatuses } from 'app/selectors'
import { useSetBreadcrumbTitle } from 'hooks/useSetBreadcrumbTitle'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import {
  ColumnProps,
  CurrencyFormatter,
  DateFormatter,
  OnTableChangeProps,
  Table,
  TransactionStatusFormatter,
} from 'components/Table'
import { TransactionTotalBadge } from 'features/transactionReport/TransactionReportTable'
import { requestAsync } from 'redux-query'
import { ExportButton } from 'components/ExportButton'
import qboImage from 'assets/images/quickbooks_icon.png'
import xeroImage from 'assets/images/xero_icon.png'
import { LoadingMask } from 'components/LoadingMask'

const CustomerLinkFormatter = (name, row) => {
  return row.customerId ? <Link to={`/client/customers/${row.customerId}`}>{name}</Link> : name
}

export const SettlementDetails = ({ settlementId }) => {
  const dispatch = useDispatch()
  const activeClient = useSelector(getActiveClient)
  const settlementInfo = useSelector((state) => getEntities(state).settlementInfo) || {}
  const settlementTransactions = useSelector((state) => getEntities(state).settlementTransactions) || []
  const unreportedSettlements = useSelector((state) => getEntities(state).unreportedSettlements) || false
  const [settlementPaymentsReported, setSettlementPaymentsReported] = useState(false)
  const settlementTransactionsCount = useSelector((state) => getEntities(state).settlementTransactionsCount) || []
  const [settlementPaymentsReportedMessage, setSettlementPaymentsReportedMessage] = useState('')
  const formattedSettlementDate = settlementInfo.settlementDate && formatDateGreg(settlementInfo.settlementDate)
  const [loading, setLoading] = useState(false)
  const transactionStatuses = useSelector(getTransactionStatuses)
  const history = useHistory()
  useSetBreadcrumbTitle(settlementId?.toString(), formattedSettlementDate)
  useClearEntitiesOnUnmount(['settlementInfo', 'settlementTransactions', 'settlementTransactionsCount'])

  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 25,
    sortField: 'processDate',
    sortOrder: 'desc',
  })
  const [requestState, doRequest] = useRequest(
    !settlementId
      ? null
      : searchSettlementTransactions(
          {
            id: settlementId,
            skip: (tableProps.page - 1) * tableProps.pageSize,
            limit: tableProps.pageSize,
            sort: getSort(tableProps.sortField, tableProps.sortOrder),
          },
          {
            force: true,
            transform: (response) => {
              return {
                settlementInfo: response.settlement,
                settlementTransactions: response.records,
                settlementTransactionsCount: response.count,
                unreportedSettlements: response.unreportedSettlements,
              }
            },
            update: {
              settlementInfo: (_, newValue) => newValue,
              settlementTransactions: (_, newValue) => newValue,
              settlementTransactionsCount: (_, newValue) => newValue,
              unreportedSettlements: (_, newValue) => newValue,
            },
          },
        ),
  )

  const doReportPayments = (e) => {
    e.preventDefault()
    ;(dispatch(
      requestAsync(
        reportSettlementTransactions(
          {
            id: settlementId,
          },
          {
            force: true,
          },
        ),
      ),
    ) as any).then((response) => {
      if (response?.body?.status !== 200) {
        setSettlementPaymentsReportedMessage(response?.body?.messages[0])
      }
      setSettlementPaymentsReported(true)
    })
  }

  const handleClickAcknowledge = () => {
    setSettlementPaymentsReported(false)
  }

  const SettlementTransactionStatusFormatter = (status, row) => {
    const id = `settlement-transaction-stattus-formater-${row.id}`
    return (
      <>
        {TransactionStatusFormatter(status, row)}
        {row.reportedToIntegration && row.integrated === 'xero' && (
          <>
            <img id={id} src={xeroImage} className="client-integration-logo" alt="Xero" height="29" />
            <UncontrolledTooltip target={id} placement="bottom">
              Paid in Xero
            </UncontrolledTooltip>
          </>
        )}
        {row.reportedToIntegration && row.integrated === 'quickbooks' && (
          <>
            <img id={id} src={qboImage} className="client-integration-logo" alt="QuickBooks Online" height="29" />
            <UncontrolledTooltip target={id} placement="bottom">
              Paid in QuickBooks Online
            </UncontrolledTooltip>
          </>
        )}
      </>
    )
  }

  const columns: ColumnProps[] = [
    {
      dataField: 'processDate',
      text: 'Process Date',
      formatter: DateFormatter,
      sort: true,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: CurrencyFormatter,
      sort: false,
    },
    {
      dataField: 'customerName',
      text: 'Customer',
      formatter: CustomerLinkFormatter,
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: SettlementTransactionStatusFormatter,
      sort: true,
      classes: 'td-badge',
    },
    {
      dataField: 'comment',
      text: 'Comment',
      sort: true,
    },
  ]

  const integrationName = (
    <>
      {activeClient?.integration === 'xero' && 'Xero'}
      {activeClient?.integration === 'quickbooks' && 'QuickBooks Online'}
    </>
  )

  const onRowClick = (e, row, rowIndex) => {
    history.push(`/client/customers/${row.customerId}`)
  }

  const onTableChange = (_type: string, props: OnTableChangeProps) => {
    setTableProps({
      page: props.page || tableProps.page,
      pageSize: props.sizePerPage || tableProps.pageSize,
      sortField: props.sortField || tableProps.sortField,
      sortOrder: props.sortOrder || tableProps.sortOrder,
    })
  }

  return (
    <>
      <Modal isOpen={settlementPaymentsReported}>
        <ModalHeader>Settlement Payments Reporting</ModalHeader>
        <ModalBody>
          {!settlementPaymentsReportedMessage && (
            <p>
              Settlement payments for <b>{formattedSettlementDate}</b> have been sent to {integrationName}. This may
              take up to 60 seconds to be reflected in your accounting software.
            </p>
          )}
          {settlementPaymentsReportedMessage && (
            <p>
              Settlement payments for <b>{formattedSettlementDate}</b> could not be reported.
              <br />
              <br />
              <b>Error Message:</b> {settlementPaymentsReportedMessage}
            </p>
          )}
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={handleClickAcknowledge}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Settlement for {formattedSettlementDate || '...'}</h4>
          </div>
          {activeClient?.integration &&
            unreportedSettlements &&
            isPermitted('integrations.resend_settlements', activeClient) && (
              <>
                <Alert color="secondary" className="alert-with-icon">
                  <i className="mdi mdi-alert-outline mr-1" />
                  One or more of your transactions failed to report to {integrationName}.&nbsp;
                  {activeClient.integrationPaymentAccountSet ? (
                    <a href="" className="underline" onClick={doReportPayments}>
                      Resend the report now.
                    </a>
                  ) : (
                    <>Payment account is not set.</>
                  )}
                </Alert>
              </>
            )}
          <Card>
            {loading && <LoadingMask />}
            <CardBody>
              <Row className="clearfix mb-2">
                <Col xs="auto">
                  {['approved', 'declined', 'chargeback', 'total'].map(
                    (status, i) =>
                      settlementInfo[status]?.count > 0 && (
                        <TransactionTotalBadge
                          key={i}
                          sum={Number.parseFloat(settlementInfo[status].amount)}
                          count={settlementInfo[status].count}
                          status={transactionStatuses[status] || titleify(status)}
                        />
                      ),
                  )}
                  {settlementInfo.holdback && (
                    <Badge pill color="danger" className={`status-badge totals badge-danger-lighten mr-1`}>
                      Heldback
                    </Badge>
                  )}
                </Col>
              </Row>
              <Table
                data={settlementTransactions}
                onTableChange={onTableChange}
                loading={requestState.isPending}
                paginationProps={{
                  page: tableProps.page,
                  totalSize: settlementTransactionsCount,
                  sizePerPage: tableProps.pageSize,
                  fillHeight: true,
                }}
                columns={columns}
                sortField={tableProps.sortField}
                sortOrder={tableProps.sortOrder}
                onRowClick={onRowClick}
              />
              <Row>
                {settlementId && (
                  <Col className="text-center">
                    <UncontrolledButtonDropdown className="ml-1 mb-2 centered-dropdown" direction="down">
                      <DropdownToggle caret color="tertiary" className="btn-rotessa-tertiary">
                        <i className="mdi mdi-download-outline mr-1" />
                        Export settlement details
                      </DropdownToggle>
                      <DropdownMenu className="narrow">
                        <DropdownItem>
                          <ExportButton
                            exportName={`Rotessa Settlement Export for ${formattedSettlementDate}.csv`}
                            setLoadingHandler={setLoading}
                            noIcon
                            exportQueryConfig={exportSettlementTransactions(
                              {
                                id: settlementId,
                              },
                              {
                                force: true,
                              },
                            )}
                          >
                            CSV
                          </ExportButton>
                        </DropdownItem>
                        <DropdownItem>
                          <ExportButton
                            exportName={`Rotessa Settlement Export for ${formattedSettlementDate}.pdf`}
                            setLoadingHandler={setLoading}
                            noIcon
                            exportQueryConfig={exportSettlementTransactionsPdf(
                              {
                                id: settlementId,
                              },
                              {
                                force: true,
                              },
                            )}
                          >
                            PDF
                          </ExportButton>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
