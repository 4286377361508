// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    ClientOnboarding,
    ClientOnboardingFromJSON,
    ClientOnboardingToJSON,
    Owner,
    OwnerFromJSON,
    OwnerToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubmitStateRequest
 */
export interface SubmitStateRequest  {
    /**
     * 
     * @type {ClientOnboarding}
     * @memberof SubmitStateRequest
     */
    processingClientOnboarding?: ClientOnboarding;
    /**
     * 
     * @type {number}
     * @memberof SubmitStateRequest
     */
    clientId?: number;
    /**
     * 
     * @type {Client}
     * @memberof SubmitStateRequest
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof SubmitStateRequest
     */
    currentState?: string;
    /**
     * 
     * @type {User}
     * @memberof SubmitStateRequest
     */
    user?: User;
    /**
     * 
     * @type {Array<Owner>}
     * @memberof SubmitStateRequest
     */
    owners?: Array<Owner>;
}

export function SubmitStateRequestFromJSON(json: any): SubmitStateRequest {
    return {
        'processingClientOnboarding': !exists(json, 'processing_client_onboarding') ? undefined : ClientOnboardingFromJSON(json['processing_client_onboarding']),
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'currentState': !exists(json, 'current_state') ? undefined : json['current_state'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'owners': !exists(json, 'owners') ? undefined : (json['owners'] as Array<any>).map(OwnerFromJSON),
    };
}

export function SubmitStateRequestToJSON(value?: SubmitStateRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'processing_client_onboarding': ClientOnboardingToJSON(value.processingClientOnboarding),
        'client_id': value.clientId,
        'client': ClientToJSON(value.client),
        'current_state': value.currentState,
        'user': UserToJSON(value.user),
        'owners': value.owners === undefined ? undefined : (value.owners as Array<any>).map(OwnerToJSON),
    };
}


