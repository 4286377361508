// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey  {
    /**
     * 
     * @type {number}
     * @memberof ApiKey
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    key?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiKey
     */
    createdAt?: Date;
}

export function ApiKeyFromJSON(json: any): ApiKey {
    return {
        'id': json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
    };
}

export function ApiKeyToJSON(value?: ApiKey): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'key': value.key,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    };
}


