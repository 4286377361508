// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationSettingsPaymentReminderBodyContent,
    NotificationSettingsPaymentReminderBodyContentFromJSON,
    NotificationSettingsPaymentReminderBodyContentToJSON,
} from './';

/**
 * 
 * @export
 * @interface NotificationSettingsPaymentReminder
 */
export interface NotificationSettingsPaymentReminder  {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsPaymentReminder
     */
    enabledByDefault?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationSettingsPaymentReminder
     */
    language?: string;
    /**
     * 
     * @type {NotificationSettingsPaymentReminderBodyContent}
     * @memberof NotificationSettingsPaymentReminder
     */
    bodyContent?: NotificationSettingsPaymentReminderBodyContent;
    /**
     * 
     * @type {NotificationSettingsPaymentReminderBodyContent}
     * @memberof NotificationSettingsPaymentReminder
     */
    footerText?: NotificationSettingsPaymentReminderBodyContent;
    /**
     * 
     * @type {NotificationSettingsPaymentReminderBodyContent}
     * @memberof NotificationSettingsPaymentReminder
     */
    unsubscribe?: NotificationSettingsPaymentReminderBodyContent;
}

export function NotificationSettingsPaymentReminderFromJSON(json: any): NotificationSettingsPaymentReminder {
    return {
        'enabledByDefault': !exists(json, 'enabled_by_default') ? undefined : json['enabled_by_default'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'bodyContent': !exists(json, 'body_content') ? undefined : NotificationSettingsPaymentReminderBodyContentFromJSON(json['body_content']),
        'footerText': !exists(json, 'footer_text') ? undefined : NotificationSettingsPaymentReminderBodyContentFromJSON(json['footer_text']),
        'unsubscribe': !exists(json, 'unsubscribe') ? undefined : NotificationSettingsPaymentReminderBodyContentFromJSON(json['unsubscribe']),
    };
}

export function NotificationSettingsPaymentReminderToJSON(value?: NotificationSettingsPaymentReminder): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enabled_by_default': value.enabledByDefault,
        'language': value.language,
        'body_content': NotificationSettingsPaymentReminderBodyContentToJSON(value.bodyContent),
        'footer_text': NotificationSettingsPaymentReminderBodyContentToJSON(value.footerText),
        'unsubscribe': NotificationSettingsPaymentReminderBodyContentToJSON(value.unsubscribe),
    };
}


