// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationSettingsPaymentReminderBodyContent
 */
export interface NotificationSettingsPaymentReminderBodyContent  {
    /**
     * 
     * @type {string}
     * @memberof NotificationSettingsPaymentReminderBodyContent
     */
    en?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSettingsPaymentReminderBodyContent
     */
    fr?: string;
}

export function NotificationSettingsPaymentReminderBodyContentFromJSON(json: any): NotificationSettingsPaymentReminderBodyContent {
    return {
        'en': !exists(json, 'en') ? undefined : json['en'],
        'fr': !exists(json, 'fr') ? undefined : json['fr'],
    };
}

export function NotificationSettingsPaymentReminderBodyContentToJSON(value?: NotificationSettingsPaymentReminderBodyContent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'en': value.en,
        'fr': value.fr,
    };
}


