import React, { useEffect, useState } from 'react'
import loadingFailIcon from 'assets/images/Rotessa-loading-fail.png'

export const Loading = () => {
  const [loadingIcon, setLoadingIcon] = useState(
    <div className="spinner-grow" role="status">
      <span className="sr-only">Loading...</span>
    </div>,
  )

  useEffect(() => {
    const timer = setTimeout(
      () =>
        setLoadingIcon(
          <div>
            <img src={loadingFailIcon} style={{ width: '40px' }} alt="" />
            <div>
              <small>Unable to load</small>
            </div>
          </div>,
        ),
      30000,
    )
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div
        className="mt-4"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loadingIcon}
      </div>
    </>
  )
}
