import React, { useState } from 'react'
import { Card, CardBody, Fade } from 'reactstrap'

import { customersCsvPreview, CustomersCsvPreview200ResponseFromJSON } from 'api'
import { getActiveClient } from 'app/selectors'
import { AvForm } from 'availity-reactstrap-validation'
import { CenterContentNarrow } from 'components/CenterContent'
import { ExternalTertiaryLink } from 'components/ExternalLink'
import { FileUploader } from 'components/FileUploader'
import { FormServerErrors } from 'components/FormServerErrors'
import { TitleBar } from 'features/customers/customerFlow/titleBar'
import { useSetBreadcrumbTitle } from 'hooks/useSetBreadcrumbTitle'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { updateEntities } from 'redux-query'
import { useMutation } from 'redux-query-react'
import { isPermitted } from 'utilities'

const csvCustomerTemplateURL = (activeClient) => {
  if (activeClient.countryCode === 'CA') {
    return (
      <ExternalTertiaryLink to="/csv_templates/New_Rotessa_Customer_Import_Template.csv" download>
        <i className="mdi mdi-download" /> Download customer import template
      </ExternalTertiaryLink>
    )
  } else {
    return (
      <ExternalTertiaryLink to="/csv_templates/New_Rotessa_US_Customer_Import_Template.csv" download>
        <i className="mdi mdi-download" /> Download customer import template
      </ExternalTertiaryLink>
    )
  }
}

export const CustomerImportPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const activeClient = useSelector(getActiveClient)
  const [errors, setErrors] = useState([] as string[])

  useSetBreadcrumbTitle('csv', 'CSV')

  const changeCustomerFileHandler = (file, accepted) => {
    if (accepted) {
      handleCustomerValidSubmit(file)
    } else {
      setErrors(['File not accepted. Please check that the file is a CSV.'])
    }
  }

  const handleCustomerValidSubmit = (file) => {
    doCustomerMutation(file)?.then((response) => {
      if (response?.body?.count > 0) {
        dispatch(
          updateEntities({
            previewCsvCustomers: (_) => CustomersCsvPreview200ResponseFromJSON(response?.body).records,
            previewCsvCustomersCount: (_) => response?.body?.count,
          }),
        )
        history.push('/client/customers/imports/preview-customers')
      } else {
        setErrors(response?.body?.errors || response?.body?.messages?.base || ['Unable to upload file.'])
      }
    })
  }

  const [{ isPending }, doCustomerMutation] = useMutation((data: any) => {
    return customersCsvPreview({
      clientId: activeClient.id,
      file: data,
    })
  })

  return (
    <Fade>
      <TitleBar
        title="Import Customers from CSV"
        subtitle="Import your existing customers from your database using Rotessa's CSV import. First, download the import template and format your data according to our specific headings to import the data correctly."
      />
      {isPermitted('customers.csv_imports', activeClient) && (
        <>
          <CenterContentNarrow>
            <Card>
              <CardBody>
                <p className="text-center">{csvCustomerTemplateURL(activeClient)}</p>
                <AvForm onValidSubmit={handleCustomerValidSubmit}>
                  <FileUploader
                    onFileUpload={(file, accepted) => {
                      changeCustomerFileHandler(file, accepted)
                    }}
                    accept=".csv"
                    showPreview={true}
                  />
                  <div className="mt-2">
                    <FormServerErrors errors={errors} />
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </CenterContentNarrow>
        </>
      )}
    </Fade>
  )
}
