import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRequest } from 'redux-query-react'
import { updateEntities } from 'redux-query'
import { Redirect, useLocation } from 'react-router-dom'

import { Client } from 'api'
import { searchClientsConfig } from 'features/clients/ClientManager'
import { getClients } from 'app/selectors'
import { Loading } from 'components/Loading'
import { ACTIVE_CLIENT_KEY } from 'app/constants'

export const PostOauthRedirect = ({ clientId }) => {
  const query = new URLSearchParams(useLocation().search)
  const newConnectionStatus = query.get('connection_status')
  const clientIdNumber = Number.parseInt(clientId)
  const clients: Client[] = useSelector(getClients) || []
  const [requestState, doRequest] = useRequest(searchClientsConfig())
  const activeClient = clientId && clients.find((c) => c.id === clientIdNumber)
  const dispatch = useDispatch()

  useEffect(() => {
    if (activeClient?.id) {
      localStorage.setItem(ACTIVE_CLIENT_KEY, activeClient.id.toString())
      dispatch(
        updateEntities({
          activeClient: (_) => activeClient,
        }),
      )
    }
    if (newConnectionStatus) {
      dispatch(
        updateEntities({
          newConnectionStatus: (_) => newConnectionStatus,
        }),
      )
    }
  }, [activeClient, newConnectionStatus])

  if (requestState.isFinished && activeClient?.id) {
    if (!newConnectionStatus) {
      return <Redirect to="/client/integrations/set-clearing-account" />
    } else {
      return <Redirect to="/client/integrations" />
    }
  }

  return <Loading />
}
