import React, { useState } from 'react'
import { Fade, Card, CardHeader, Col, Row, Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { updateEntities } from 'redux-query'
import quickbooksLogo from 'assets/images/quickbooks_logo.png'
import xeroLogo from 'assets/images/xero_icon.png'
import zapierLogo from 'assets/images/zapier_logo.png'
import { Notification } from 'components/Notification'
import { CenterContentWide } from 'components/CenterContent'
import { IntegrationInfoPanelFixed } from './integrationInfoPanel'
import { TitleBar } from 'features/customers/customerFlow/titleBar'
import quickbooks_connect_btn from 'assets/images/quickbooks_connect_btn.svg'
import zapier_connect_btn from 'assets/images/zapier_connect_btn.svg'
import xero_connect_btn from 'assets/images/xero_connect_btn.svg'
import qboImg from 'assets/images/integrationsFlow/QuickBooks-Online-First-Image.jpg'
import xeroImg from 'assets/images/integrationsFlow/Xero-First-Image.jpg'
import qboImg2 from 'assets/images/integrationsFlow/QuickBooks-Online-Second-Image.jpg'
import xeroImg2 from 'assets/images/integrationsFlow/Xero-Second-Image.jpg'
import reconcileIntegrationImg from 'assets/images/integrationsFlow/Integration-image-reconciled-payments.jpg'
import zapierImg from 'assets/images/integrationsFlow/Zapier-First-Image.jpg'
import zapierImg2 from 'assets/images/integrationsFlow/Zapier-Second-Image.jpg'
import zapierImg3 from 'assets/images/integrationsFlow/Zapier-Third-Image.jpg'

export const IntegrationIntro = ({ quickbooksOauthUrl, xeroOauthUrl, newConnectionStatus }) => {
  const [isOpen, setIsOpen] = useState(
    newConnectionStatus === 'failed' || newConnectionStatus === 'failed_no_connections' || false,
  )
  const [hideConnectionNotificationTimeout, setHideConnectionNotificationTimeout] = useState<NodeJS.Timeout>()
  const [selectedIntegration, setSelectedIntegration] = useState('')
  const [selectedIntegrationURL, setSelectedIntegrationURL] = useState('')
  const dispatch = useDispatch()

  const integrations = {
    'QuickBooks Online': {
      logo: quickbooksLogo,
      connectButton: quickbooks_connect_btn,
      content: [
        { imgSrc: qboImg, text: 'Import invoices from QuickBooks Online to schedule payments' },
        { imgSrc: qboImg2, text: 'Payments are processed on your invoice date – get paid on time, every time.' },
        {
          imgSrc: reconcileIntegrationImg,
          text: 'Rotessa reconciles all your successful invoice payments in QuickBooks Online automatically.',
        },
      ],
    },
    Xero: {
      logo: xeroLogo,
      connectButton: xero_connect_btn,
      content: [
        { imgSrc: xeroImg, text: 'Import invoices from Xero to schedule payments' },
        { imgSrc: xeroImg2, text: 'Payments are processed on your invoice date – get paid on time, every time.' },
        {
          imgSrc: reconcileIntegrationImg,
          text: 'Rotessa reconciles all your successful invoice payments in Xero automatically.',
        },
      ],
    },
    Zapier: {
      logo: zapierLogo,
      connectButton: zapier_connect_btn,
      content: [
        { imgSrc: zapierImg, text: 'Connect Rotessa and Zapier to automate your workflow.' },
        {
          imgSrc: zapierImg2,
          text:
            'Zapier connects Rotessa to thousands of popular apps to create and automate your customers and transactions.',
        },
        {
          imgSrc: zapierImg3,
          text: 'No code required for setup – simple automation lets you focus on your business.',
        },
      ],
    },
  }

  function handleConnectionNotificationTimer() {
    hideConnectionNotificationTimeout && clearTimeout(hideConnectionNotificationTimeout)
    setHideConnectionNotificationTimeout(setTimeout(() => setIsOpen(false), 6000))
  }

  const onClickHandler = (integrationType: string, integrationURL: string) => {
    setSelectedIntegration(integrationType)
    setSelectedIntegrationURL(integrationURL)
  }

  const notificationMessage =
    newConnectionStatus === 'failed_no_connections'
      ? 'Integration connection was cancelled. No new connection selected. If you have Rotessa app connections in Xero please disconnect the organization you want to connect first.'
      : 'Integration connection was cancelled.'

  return (
    <>
      <Fade
        in={isOpen}
        onEnter={handleConnectionNotificationTimer}
        onExit={() => dispatch(updateEntities({ newConnectionStatus: (_) => undefined }))}
      >
        <Notification text={notificationMessage} color="warning" />
      </Fade>
      <TitleBar
        title={'Software Integrations'}
        subtitle={
          'Automate your daily tasks and simplify your receivables workflow with a custom Rotessa integration. '
        }
        noBack
      />

      <CenterContentWide>
        <Row className="justify-content-center">
          <Card className="integration-card m-2">
            <CardHeader className="text-center p-0">
              <Col className="p-0">
                <Button
                  color="primary"
                  className="btn-integration-selection rounded"
                  onClick={() => onClickHandler('QuickBooks Online', quickbooksOauthUrl)}
                  active={selectedIntegration === 'QuickBooks Online'}
                  name="quickbooksButton"
                >
                  <img
                    src={quickbooksLogo}
                    height={50}
                    className={`mt-3 mb-3 ${
                      selectedIntegration !== 'QuickBooks Online' && selectedIntegration !== ''
                        ? 'btn-integration-img-grayscale'
                        : ''
                    }`}
                    alt="QuickBooks Online"
                  />
                </Button>
              </Col>
            </CardHeader>
          </Card>
          <Card className="integration-card m-2">
            <CardHeader className="text-center p-0">
              <Col className="p-0">
                <Button
                  color="primary"
                  className="btn-integration-selection rounded"
                  onClick={() => onClickHandler('Xero', xeroOauthUrl)}
                  active={selectedIntegration === 'Xero'}
                  name="xeroButton"
                >
                  <img
                    src={xeroLogo}
                    height={50}
                    className={`mt-3 mb-3 ${
                      selectedIntegration !== 'Xero' && selectedIntegration !== ''
                        ? 'btn-integration-img-grayscale'
                        : ''
                    }`}
                    alt="Xero"
                  />
                </Button>
              </Col>
            </CardHeader>
          </Card>
          <Card className="integration-card m-2">
            <CardHeader className="text-center p-0">
              <Col className="p-0">
                <Button
                  color="primary"
                  className="btn-integration-selection rounded"
                  onClick={() => onClickHandler('Zapier', 'https://zapier.com/apps/rotessa/integrations')}
                  active={selectedIntegration === 'Zapier'}
                  name="zapierButton"
                >
                  <img
                    src={zapierLogo}
                    height={50}
                    className={`mt-3 mb-3 ${
                      selectedIntegration !== 'Zapier' && selectedIntegration !== ''
                        ? 'btn-integration-img-grayscale'
                        : ''
                    }`}
                    alt="Zapier"
                  />
                </Button>
              </Col>
            </CardHeader>
          </Card>
        </Row>

        {selectedIntegration && (
          <>
            {IntegrationInfoPanelFixed(integrations[selectedIntegration])}
            <Row className="justify-content-center mt-3">
              <a href={selectedIntegrationURL}>
                <img
                  src={integrations[selectedIntegration].connectButton}
                  height={35}
                  alt={`Connect to ${selectedIntegration}`}
                  className="mw-100"
                />
              </a>
            </Row>
          </>
        )}
      </CenterContentWide>
    </>
  )
}
