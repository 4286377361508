import React, { useRef, useState } from 'react'

import { CenterContent } from 'components/CenterContent'

import { updateClientLogo } from 'api'
import { getActiveClient } from 'app/selectors'
import { FormServerErrors } from 'components/FormServerErrors'
import { LoadingMask } from 'components/LoadingMask'
import { Errors } from 'hooks/useFormServerErrors'
import AvatarEditor from 'react-avatar-editor'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Col, FormGroup } from 'reactstrap'
import { updateEntities } from 'redux-query'
import { useMutation } from 'redux-query-react'
import { fileNameFromUrl } from 'utilities'

const MIN_SCALE = 0.25
const MAX_SCALE = 3
const SCALE_STEP = 0.01

export const AuthLogoEditor = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const editorEl = useRef(null)
  const fileInputEl = useRef(null)
  const [scale, setScale] = useState(1)
  const activeClient = useSelector(getActiveClient) || {}
  const [image, setImage] = useState<File>()
  const [errors, setErrors] = useState<Errors>()

  const [uploadFileState, doUploadFile] = useMutation((logo: File) =>
    updateClientLogo({
      id: activeClient.id,
      logo: logo,
    }),
  )

  const handleSave = () => {
    const editor = editorEl.current as any
    editor.getImageScaledToCanvas().toBlob((blob) => {
      const file = new File([blob], image?.name || fileNameFromUrl(activeClient?.logo?.url) || 'image')
      doUploadFile(file)?.then((response) => {
        setErrors(response?.body?.messages || {})
        if (response?.body?.status === 200 && response?.body?.id) {
          dispatch(
            updateEntities({
              activeClient: (currentActiveClient: any) => ({
                ...currentActiveClient,
                logo: { url: response.body.id },
              }),
            }),
          )
          history.push('/client/authorizations/settings')
        }
        else {
          setErrors({ logo: ['Unable to upload file.'] })
        }
      })
    })
  }

  const handleFileChange = (event) => {
    event.preventDefault()
    setImage(event.target.files[0])
  }

  return (
    <CenterContent>
      <div className="page-title-box">
        <h4 className="page-title">Upload Company Logo</h4>
      </div>
      <Card>
        <CardBody>
          {uploadFileState.isPending && <LoadingMask />}
          <FormServerErrors errors={(errors && errors['logo']) || []} />
          <div className="text-center">
            <p>
              <i className="mdi mdi-arrow-all mr-1" />
              Drag to reposition
            </p>
            <AvatarEditor
              ref={editorEl}
              image={image || activeClient?.logo?.url}
              crossOrigin="anonymous"
              borderRadius={4}
              style={{ borderRadius: 4 }}
              width={300}
              height={150}
              border={30}
              scale={scale}
            />
            <FormGroup row className="mt-2">
              <Col sm={1}>
                <i className="mdi mdi-minus" onClick={() => setScale(Math.max(MIN_SCALE, scale - SCALE_STEP))} />
              </Col>
              <Col sm={10}>
                <input
                  className="custom-range"
                  type="range"
                  name="scale"
                  min={MIN_SCALE}
                  max={MAX_SCALE}
                  step={SCALE_STEP}
                  value={scale}
                  onChange={(e) => setScale(Number.parseFloat(e.target.value))}
                />
              </Col>
              <Col sm={1}>
                <i className="mdi mdi-plus" onClick={() => setScale(Math.min(MAX_SCALE, scale + SCALE_STEP))} />
              </Col>
            </FormGroup>
            <input
              ref={fileInputEl}
              onChange={handleFileChange}
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: 'none' }}
            />
            <Button color="link" onClick={() => (fileInputEl.current as any).click()}>
              <i className="mdi mdi-upload mr-1" />
              Upload a new image file
            </Button>
          </div>
        </CardBody>
      </Card>
      <div className="text-right mb-2">
        <Button
          disabled={uploadFileState.isPending}
          onClick={() => history.goBack()}
          className="btn-rotessa-secondary min-width ml-1"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={uploadFileState.isPending || !(image || activeClient?.logo?.url)}
          color="primary"
          className="ml-1"
        >
          Submit
        </Button>
      </div>
    </CenterContent>
  )
}
