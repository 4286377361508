import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'

import { Card, CardBody } from 'reactstrap'

import { addApiKey, deleteApiKey, getApiKeys } from 'api'
import { getActiveClient, getEntities } from 'app/selectors'
import { ColumnProps, OnTableChangeProps, Table, DateFormatter, ActionFormatterFactory } from 'components/Table'
import { TableHeader } from 'components/TableHeader'

interface TableState {
  page: number
  pageSize: number
}

const defaultTableState: TableState = {
  page: 1,
  pageSize: 5,
}

export const ApiKeysTable = () => {
  const [tableState, setTableState] = useState(defaultTableState)
  const activeClient = useSelector(getActiveClient) || {}
  const apiKeys = useSelector((state) => getEntities(state).apiKeys) || []
  const apiKeysCount = useSelector((state) => getEntities(state).apiKeysCount) || 0

  const [requestQueryState, handleRefresh] = useRequest(
    !activeClient?.id
      ? null
      : getApiKeys(
          {
            clientId: activeClient.id,
            skip: (tableState.page - 1) * tableState.pageSize,
            limit: tableState.pageSize,
          },
          {
            force: true,
            transform: (response) => {
              return {
                apiKeys: response.records,
                apiKeysCount: response.count,
              }
            },
            update: {
              apiKeys: (oldValue, newValue) => newValue,
              apiKeysCount: (oldValue, newValue) => newValue,
            },
          },
        ),
  )

  const [addQueryState, doCreateApiKey] = useMutation(() =>
    addApiKey({
      clientId: activeClient.id,
    }),
  )

  const [deleteQueryState, doDeleteApiKey] = useMutation((id: number) =>
    deleteApiKey({
      id: id,
    }),
  )

  function onTableChange(type, { page, sizePerPage, filter, sortField, sortOrder }: OnTableChangeProps) {
    setTableState({
      page: page,
      pageSize: sizePerPage,
    })
  }

  if (typeof requestQueryState.status === 'number' && requestQueryState.status >= 400) {
    return <p>Sorry! Something went wrong. Please try again.</p>
  }

  const columns: ColumnProps[] = [
    {
      dataField: 'key',
      text: 'Key',
      sort: false,
    },
    {
      dataField: 'createdAt',
      text: 'Created',
      formatter: DateFormatter,
      sort: false,
    },
    {
      dataField: '',
      text: '',
      formatter: ActionFormatterFactory([
        {
          name: <span className="text-danger">Remove</span>,
          onClick: (row) => {
            doDeleteApiKey(row.id)?.then(() => handleRefresh())
          },
        },
      ]),
      sort: false,
      align: 'right',
      classes: 'sticky-right',
    },
  ]

  function handleClickNew() {
    doCreateApiKey()?.then(() => handleRefresh())
  }

  const isPending = requestQueryState.isPending || addQueryState.isPending || deleteQueryState.isPending

  return (
    <>
      <TableHeader onClickNew={handleClickNew} hideImport hideExport onClickRefresh={handleRefresh} />
      <Table
        loading={isPending}
        data={apiKeys}
        columns={columns}
        paginationProps={{
          page: tableState.page,
          sizePerPage: tableState.pageSize,
          totalSize: apiKeysCount,
        }}
        sortField="createdAt"
        sortOrder="desc"
        onTableChange={onTableChange}
      />
    </>
  )
}

export const ApiKeysView = () => {
  return (
    <div>
      <div className="page-title-box">
        <h4 className="page-title">API Keys</h4>
      </div>
      <Card>
        <CardBody>
          <ApiKeysTable />
        </CardBody>
      </Card>
    </div>
  )
}
