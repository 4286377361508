// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    ClientOnboarding,
    ClientOnboardingFromJSON,
    ClientOnboardingToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubmitClientOnboardingState200Response
 */
export interface SubmitClientOnboardingState200Response  {
    /**
     * 
     * @type {number}
     * @memberof SubmitClientOnboardingState200Response
     */
    status?: number;
    /**
     * 
     * @type {ClientOnboarding}
     * @memberof SubmitClientOnboardingState200Response
     */
    processingClientOnboarding?: ClientOnboarding;
    /**
     * 
     * @type {ClientOnboarding}
     * @memberof SubmitClientOnboardingState200Response
     */
    clientOnboarding?: ClientOnboarding;
    /**
     * 
     * @type {Client}
     * @memberof SubmitClientOnboardingState200Response
     */
    client?: Client;
    /**
     * 
     * @type {User}
     * @memberof SubmitClientOnboardingState200Response
     */
    user?: User;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof SubmitClientOnboardingState200Response
     */
    messages?: { [key: string]: Array<string>; };
}

export function SubmitClientOnboardingState200ResponseFromJSON(json: any): SubmitClientOnboardingState200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'processingClientOnboarding': !exists(json, 'processing_client_onboarding') ? undefined : ClientOnboardingFromJSON(json['processing_client_onboarding']),
        'clientOnboarding': !exists(json, 'client_onboarding') ? undefined : ClientOnboardingFromJSON(json['client_onboarding']),
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function SubmitClientOnboardingState200ResponseToJSON(value?: SubmitClientOnboardingState200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'processing_client_onboarding': ClientOnboardingToJSON(value.processingClientOnboarding),
        'client_onboarding': ClientOnboardingToJSON(value.clientOnboarding),
        'client': ClientToJSON(value.client),
        'user': UserToJSON(value.user),
        'messages': value.messages,
    };
}


