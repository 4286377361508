import React, { useEffect, useState } from 'react'
import { Row, Col, Input, FormFeedback, Fade, Badge, Alert } from 'reactstrap'

import { FormGroup, InputGroup, InputGroupAddon, Button, Label, CustomInput, Card, CardBody } from 'reactstrap'

import { Link } from 'react-router-dom'
import { donationAuthBaseUrl, isReleased, validatePhone } from 'utilities'
import { Errors } from 'hooks/useFormServerErrors'

import { DonorAuthFormManager } from 'features/authorizations/donor/donorAuthFormManager'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveClient, getEntities, justReplace } from 'app/selectors'
import { useMutation, useRequest } from 'redux-query-react'
import { DonorAuthSetting, DonorAuthSettingFromJSON, getDonorAuthSettings, updateDonorAuthSettings } from 'api'
import { Loading } from 'components/Loading'
import { updateEntities } from 'redux-query'
import { InfoPopover } from 'components/InfoPopover'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { LoadingMask } from 'components/LoadingMask'
import { Notification } from 'components/Notification'
import { CenterContentWide } from 'components/CenterContent'
import { Accordion } from 'components/Accordion'
import { AccordionItem } from 'components/AccordionItem'
import { FormServerErrors } from 'components/FormServerErrors'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { MyEditor } from 'components/Editor'
import { PostalCodeField } from 'components/PostalCodeField'
import { StateProvinceSelect } from 'components/ProvinceSelect'
import { optionType, optionsType } from '../processing/processingAuthSettingsManager'
import deepEqual from 'deep-equal'
import { cloneDeep } from 'lodash'
import { ExternalLink } from 'components/ExternalLink'

const ALL_PREVIEW_STEPS = ['donation', 'contact', 'flinks', 'schedule_email_verification', 'schedule_email_finished']
const NO_CONTACT_PREVIEW_STEPS = ['donation', 'flinks', 'schedule_email_verification', 'schedule_email_finished']

export const getDonorAuthSettingsConfig = (clientId: number) =>
  getDonorAuthSettings(
    { clientId: clientId },
    {
      force: true,
      transform: (body) => ({ donorAuthSetting: body.donorAuthSetting }),
      update: { donorAuthSetting: justReplace },
    },
  )

export const DonorAuthSettingsManager = () => {
  const dispatch = useDispatch()
  const activeClient = useSelector(getActiveClient)
  const donorAuthSetting = useSelector((state) => getEntities(state).donorAuthSetting)
  const [originalDonorAuthSetting, setOriginalDonorAuthSetting] = useState<DonorAuthSetting>()
  const [waitForFinished, setWaitForFinished] = useState(true)
  const [previewStep, setPreviewStep] = useState(0)
  const [changesSaved, setChangesSaved] = useState(false)
  const [hideChangesSavedTimeout, setHideChangesSavedTimeout] = useState<NodeJS.Timeout>()
  const hash = window.location.hash.replace(/[#\/]/gm, '')

  const previewSteps = donorAuthSetting?.requireAdditionalInformation ? ALL_PREVIEW_STEPS : NO_CONTACT_PREVIEW_STEPS

  const [serverErrors, setServerErrors] = useState<Errors>()

  useClearEntitiesOnUnmount(['donorAuthInfo', 'donorAuthSetting'])

  useEffect(() => {
    if (!originalDonorAuthSetting && donorAuthSetting) {
      setOriginalDonorAuthSetting(cloneDeep(donorAuthSetting))
    }
  }, [donorAuthSetting, waitForFinished])

  const disableSave = !originalDonorAuthSetting || deepEqual(originalDonorAuthSetting, donorAuthSetting)

  useEffect(() => {
    dispatch(
      updateEntities({
        donorAuthInfo: (oldDonorAuthInfo: any) => ({
          ...oldDonorAuthInfo,
          id: 'Testing',
          address: donorAuthSetting?.address,
          email: donorAuthSetting?.email,
          phone: donorAuthSetting?.phone,
          client: {
            businessName: activeClient?.operatingAs || activeClient?.businessName,
            countryCode: activeClient?.countryCode,

            logo: activeClient?.logo,
          },
          step: {
            name: previewSteps[previewStep],
            enable_comment_description: donorAuthSetting?.enableCommentDescription,
            comment_description: donorAuthSetting?.commentDescription,
            require_additional_information: donorAuthSetting?.requireAdditionalInformation,
            terms_and_conditions: donorAuthSetting?.termsAndConditions,
          },
          language: donorAuthSetting?.defaultLanguage,
        }),
      }),
    )
  }, [donorAuthSetting, activeClient, previewStep])

  const [requestState, doRequest] = useRequest(activeClient && getDonorAuthSettingsConfig(activeClient.id))

  const [mutationState, doMutation] = useMutation((values: DonorAuthSetting) =>
    updateDonorAuthSettings({
      donorAuthSetting: { ...values, clientId: activeClient.id },
    }),
  )

  const handleMutationResponse = (response) => {
    if (response?.body?.status === 200) {
      setChangesSaved(true)
      hideChangesSavedTimeout && clearTimeout(hideChangesSavedTimeout)
      setHideChangesSavedTimeout(setTimeout(() => setChangesSaved(false), 3000))
    }
    setServerErrors(response?.body?.messages || {})
    const newDonorAuthSetting = DonorAuthSettingFromJSON(response?.body?.donor_auth_setting)
    dispatch(updateEntities({ donorAuthSetting: (_old) => newDonorAuthSetting }))
    setOriginalDonorAuthSetting(cloneDeep(newDonorAuthSetting))
  }

  const handleToggle = (event) => {
    dispatch(
      updateEntities({
        donorAuthSetting: (oldDonorAuthSetting: DonorAuthSetting) => {
          return { ...oldDonorAuthSetting, [event.target.name]: event.target.checked }
        },
      }),
    )
  }

  const handleRadio = (event) => {
    dispatch(
      updateEntities({
        donorAuthSetting: (oldDonorAuthSetting: DonorAuthSetting) => {
          return { ...oldDonorAuthSetting, [event.target.name]: event.target.value }
        },
      }),
    )
  }

  const handleFieldChange = (name: string, value: any, root?: string) => {
    dispatch(
      updateEntities({
        donorAuthSetting: (oldDonorAuthSetting: DonorAuthSetting) => {
          let change = { ...oldDonorAuthSetting }
          if (root) {
            if (!change[root]) {
              change[root] = {}
            }
            change[root][name] = value
          } else {
            change[name] = value
          }
          return change
        },
      }),
    )
  }

  const handleSave = () => {
    doMutation(donorAuthSetting)?.then((response) => {
      handleMutationResponse(response)
    })
  }

  const generateOption = (option: optionType, i: number) => {
    let optionContent
    switch (option.type) {
      case 'toggle':
        optionContent = (
          <CustomInput
            type="switch"
            id={option.name!}
            name={option.name!}
            label={option.label}
            className="inline-block"
            checked={donorAuthSetting?.[option.name!]}
            onChange={handleToggle}
            disabled={option.disabled}
          />
        )
        break
      case 'radio':
        optionContent = (
          <>
            <Label inline className="mb-0">
              {option.label}
            </Label>
            {option.options?.map((radio, i) => (
              <CustomInput
                key={i}
                type="radio"
                id={`${option.name!}_${radio.value}`}
                name={option.name!}
                value={radio.value}
                label={radio.label}
                className="inline-block ml-3"
                checked={donorAuthSetting?.[option.name!] === radio.value}
                onChange={handleRadio}
                disabled={option.disabled}
              />
            ))}
          </>
        )
        break
      case 'code':
        optionContent = option.code
        break
      case 'label':
        optionContent = <Label className="mb-0">{option.label}</Label>
        break
      case 'editor':
        optionContent = (
          <>
            <Label>{option.label}</Label>
            <MyEditor
              onChange={(text) => {
                handleFieldChange(option.name!, text)
              }}
              value={donorAuthSetting[option.name!] || ''}
              withBorder
            />
          </>
        )
        break
    }

    return (
      <div key={i} className="mb-1">
        {optionContent}
        {option.info && <InfoPopover popoverText={option.info} />}
        {option.new && (
          <Badge pill color="info-lighten" className="ml-1">
            New
          </Badge>
        )}
      </div>
    )
  }

  if (requestState.isFinished && waitForFinished && donorAuthSetting) {
    setWaitForFinished(false)
  }

  if (requestState.isPending || waitForFinished) return <Loading />

  const customLinkUrlCode = (
    <FormGroup>
      <InputGroup>
        <Input readOnly value={donationAuthBaseUrl} />
        <InputGroupAddon addonType="append" className="gray-input-background">
          <Input
            name="code"
            value={donorAuthSetting.code}
            onChange={(event) => handleFieldChange('code', event.target.value)}
            invalid={!!serverErrors?.code}
          />
        </InputGroupAddon>

        <FormFeedback>{serverErrors?.code}</FormFeedback>
      </InputGroup>
    </FormGroup>
  )

  const logoSettingsCode = (
    <div>
      <Link to="/client/donor_authorization_settings/edit_logo" className="auth-setting-logo text-muted">
        {activeClient?.logo ? (
          <img src={activeClient?.logo?.url} alt="Upload image file" />
        ) : (
          <div>
            <div className="d-flex justify-content-center">
              <i className="mdi mdi-arrow-up"></i>
            </div>
            <div>Upload image file</div>
          </div>
        )}
      </Link>
    </div>
  )

  const contactInfoCode = (
    <>
      <Row>
        <Col md={6}>
          <AvField
            type="text"
            name="address.address1"
            label="Address"
            onChange={(event) => handleFieldChange('address1', event.target.value, 'address')}
          />
        </Col>
        <Col md={6}>
          <AvField
            type="text"
            name="address.city"
            label="City"
            onChange={(event) => handleFieldChange('city', event.target.value, 'address')}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <StateProvinceSelect
            name="address.provinceCode"
            countryCode={activeClient.countryCode}
            onChange={(event) => handleFieldChange('provinceCode', event.target.value, 'address')}
          />
        </Col>
        <Col md={6}>
          <PostalCodeField
            name="address.postalCode"
            countryCode={activeClient.countryCode}
            onChange={(event) => handleFieldChange('postalCode', event.target.value, 'address')}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AvField
            type="email"
            name="email"
            label="Email"
            onChange={(event) => handleFieldChange('email', event.target.value)}
          />
        </Col>
        <Col md={6}>
          <AvField
            type="phone"
            name="phone"
            label="Phone number"
            validate={{ tel: validatePhone }}
            onChange={(event) => handleFieldChange('phone', event.target.value)}
          />
        </Col>
      </Row>
    </>
  )

  const options: optionsType = [
    {
      type: 'header',
      name: 'Logo',
      anchor: 'logo',
      items: [
        {
          type: 'code',
          name: 'logoSettings',
          code: logoSettingsCode,
        },
      ],
    },
    {
      type: 'header',
      name: 'Contact information',
      anchor: 'contact_information',
      items: [
        {
          type: 'code',
          name: 'contactInformation',
          code: contactInfoCode,
        },
      ],
    },
    {
      type: 'header',
      name: 'Comment description',
      anchor: 'comment',
      items: [
        {
          type: 'toggle',
          name: 'enableCommentDescription',
          label: 'Enable comment description',
          info:
            'Use this text field to provide instructions to your donors about where they should designate their donation.',
        },
        {
          type: donorAuthSetting?.enableCommentDescription ? 'editor' : 'hidden',
          name: 'commentDescription',
          label: 'Edit comment description',
        },
      ],
    },
    {
      type: 'header',
      name: 'Terms and conditions',
      anchor: 'terms_and_conditions',
      items: [
        {
          type: 'editor',
          name: 'termsAndConditions',
          label:
            activeClient.countryCode == 'CA' ? (
              <Alert color="info">
                <p className="mb-1">
                  As of January 1, 2024, under{' '}
                  <ExternalLink to="https://rotessa.com/2023/12/05/important-information-about-pad-agreements/">
                    Rule H1
                  </ExternalLink>{' '}
                  pre-authorized debits require the following:
                </p>
                <ul className="pl-3 mb-0">
                  {!isReleased('h1_terms_2024_released', activeClient) && (
                    <li>Identification of payment service provider</li>
                  )}
                  <li>
                    PAD agreements are required for one-time transactions, valid for only that transaction with the
                    amount and date stated
                  </li>
                  <li>
                    PAD agreement must identify the event that triggers a pre-authorized withdrawal when not on a set
                    schedule (i.e. payments are sporadic)
                  </li>
                </ul>
              </Alert>
            ) : (
              ''
            ),
        },
      ],
    },
    {
      type: 'header',
      name: 'Language',
      anchor: 'language',
      items: [
        {
          type: 'radio',
          name: 'defaultLanguage',
          label: 'Default authorization language:',
          options: [
            { value: 'en', label: 'English' },
            { value: 'fr', label: 'French' },
          ],
          new: true,
        },
      ],
    },
    {
      type: 'header',
      name: 'Additional information',
      anchor: 'additional_information',
      items: [
        {
          type: 'toggle',
          name: 'requireAdditionalInformation',
          label: 'Require additional information step',
          info: '',
        },
      ],
    },
    {
      type: 'header',
      name: 'Email',
      anchor: 'email',
      items: [
        {
          type: 'label',
          label: 'Edit default email message',
        },
        {
          type: 'toggle',
          name: 'enableGreeting',
          label: "Hi (customer's name),",
          info: 'If enabled, the email greeting will automatically include the customer name stored in Rotessa.',
        },
        {
          type: 'editor',
          name: 'emailMessage',

          label: '',
        },
      ],
    },
    {
      type: 'header',
      name: 'Link URL',
      anchor: 'link',
      info: 'Customize the URL to something your customers will recognize (Ex: company name).',
      items: [
        {
          type: 'code',
          name: 'customLinkUrl',
          code: customLinkUrlCode,
        },
      ],
    },
  ]

  return (
    <CenterContentWide>
      <Fade in={changesSaved}>
        <Notification text="Changes saved" color="success" />
      </Fade>
      <div className="page-title-box">
        <h4 className="page-title">Donor Authorization Settings</h4>
      </div>
      {(requestState.isPending || mutationState.isPending) && <LoadingMask />}
      <Row>
        <Col lg={6} xl={7}>
          <AvForm
            disabled={requestState.isPending || mutationState.isPending}
            onValidSubmit={handleSave}
            model={donorAuthSetting}
          >
            <Card>
              <CardBody className="p-0">
                <FormServerErrors errors={(serverErrors && serverErrors?.global) || []} />
                <Accordion startingIndex={options.findIndex((o) => o.anchor === hash)}>
                  {options.map((header, i) => {
                    return (
                      <AccordionItem
                        key={i}
                        header={
                          <span>
                            {header.name}
                            {header.info && <InfoPopover popoverText={header.info} />}
                          </span>
                        }
                        body={<>{header.items.filter((option) => option.type !== 'hidden').map(generateOption)}</>}
                        className="px-3"
                      />
                    )
                  })}
                </Accordion>
              </CardBody>
            </Card>
            <Button disabled={disableSave} color="primary" className="ml-1 mb-2">
              Save
            </Button>
          </AvForm>
        </Col>

        <Col lg={6} xl={5} className="position-relative">
          <div className="auth-preview-controls text-center">
            <Button
              color="link"
              onClick={() => setPreviewStep(Math.max(0, previewStep - 1))}
              disabled={previewStep === 0}
            >
              <i className="mdi mdi-arrow-left-drop-circle" />
            </Button>
            Preview: Step {previewStep + 1} of {previewSteps.length}
            <Button
              color="link"
              onClick={() => setPreviewStep(Math.min(previewSteps.length - 1, previewStep + 1))}
              disabled={previewStep === previewSteps.length - 1}
            >
              <i className="mdi mdi-arrow-right-drop-circle" />
            </Button>
          </div>
          <div className="auth-preview-header">
            <i className="dot ml-2" />
            <i className="dot ml-1" />
            <i className="dot ml-1" />
          </div>
          <div className="auth-preview-container mb-4">
            <div className="mt-2">
              <DonorAuthFormManager readOnly />
            </div>
          </div>
        </Col>
      </Row>
    </CenterContentWide>
  )
}
