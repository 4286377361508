import React from 'react'
import { Card, Col, Row } from 'reactstrap'
import { Table } from './Table'

export interface OnTableChangeProps {
  page: number
  sizePerPage: number
  filter: string
  sortField: string
  sortOrder: string
}

export interface ColumnProps {
  dataField: string
  headerFormatter?: Function
  formatter?: (cell: any, row?: any, rowIndex?: any) => any
  text: string
  sort: boolean
  headerAlign?: string
  align?: string
  hidden?: boolean
  style?: any
  headerStyle?: any
  headerAttrs?: any
  classes?: string
  headerClasses?: string
  events?: any
}

export interface TableSelectionProps {
  mode: string
  clickToSelect?: boolean
  onSelectionChange: Function
  onSelect?: Function
  onSelectAll?: Function
  selected?: any[]
  nonSelectable?: any[]
  nonSelectableClasses?: string
  selectColumnPosition?: string
  selectColumnStyle?: object
}

export interface SideBySideTablesProps {
  loadingLeft: boolean
  loadingRight: boolean
  displayLoadingMaskLeft?: boolean
  displayLoadingMaskRight?: boolean
  titleLeft?: string
  titleRight?: string
  dataLeft: any[]
  dataRight: any[]
  columnsLeft: ColumnProps[]
  columnsRight: ColumnProps[]
  keyFieldLeft?: string
  keyFieldRight?: string
  sortFieldLeft: string
  sortFieldRight: string
  sortOrderLeft: string
  sortOrderRight: string
  selectionPropsLeft?: TableSelectionProps
  selectionPropsRight?: TableSelectionProps
  paginationPropsLeft?: any
  paginationPropsRight?: any
  onTableChangeLeft: (type: string, props: OnTableChangeProps) => void
  onTableChangeRight: (type: string, props: OnTableChangeProps) => void
  scrollBody?: boolean
  scrollBodyHeight?: string
  rowClassesLeft?: string | ((row, index) => string)
  rowClassesRight?: string | ((row, index) => string)
  onRowClickLeft?: (e: Event, row: any, rowIndex: number) => void
  onRowClickRight?: (e: Event, row: any, rowIndex: number) => void
  styleLeft?: object
  styleRight?: object
  headerRight?: any
  headerLeft?: any
  footerLeft?: any
  footerRight?: any
}

export const SideBySideTables = ({
  loadingLeft,
  loadingRight,
  displayLoadingMaskLeft,
  displayLoadingMaskRight,
  titleLeft,
  titleRight,
  dataLeft,
  dataRight,
  columnsLeft,
  columnsRight,
  keyFieldLeft,
  keyFieldRight,
  sortFieldLeft,
  sortFieldRight,
  sortOrderLeft,
  sortOrderRight,
  selectionPropsLeft,
  selectionPropsRight,
  paginationPropsLeft,
  paginationPropsRight,
  onTableChangeLeft,
  onTableChangeRight,
  scrollBody,
  scrollBodyHeight,
  onRowClickLeft,
  onRowClickRight,
  styleLeft,
  styleRight,
  headerRight,
  headerLeft,
  footerLeft,
  footerRight,
}: SideBySideTablesProps) => {
  return (
    <Row className="side-by-side-tables-row">
      <Col lg={6} xl={5}>
        <p className="exterior-card-title" style={{ paddingLeft: '20px' }}>
          {titleLeft}
        </p>
        <Card className="side-by-side-table-card">
          {headerLeft}
          <Table
            loading={loadingLeft}
            displayLoadingMask={displayLoadingMaskLeft}
            data={dataLeft}
            columns={columnsLeft}
            keyField={keyFieldLeft}
            sortField={sortFieldLeft}
            sortOrder={sortOrderLeft}
            paginationProps={paginationPropsLeft}
            selectionProps={selectionPropsLeft}
            onTableChange={onTableChangeLeft}
            scrollBody={scrollBody}
            scrollBodyHeight={scrollBodyHeight}
            onRowClick={onRowClickLeft}
            style={styleLeft}
          />
          {footerLeft}
        </Card>
      </Col>
      <Col lg={6} xl={5}>
        <p className="exterior-card-title" style={{ paddingLeft: '20px' }}>
          {titleRight}
        </p>
        <Card className="side-by-side-table-card">
          {headerRight}
          <Table
            loading={loadingRight}
            displayLoadingMask={displayLoadingMaskRight}
            data={dataRight}
            columns={columnsRight}
            keyField={keyFieldRight}
            sortField={sortFieldRight}
            sortOrder={sortOrderRight}
            paginationProps={paginationPropsRight}
            selectionProps={selectionPropsRight}
            onTableChange={onTableChangeRight}
            scrollBody={scrollBody}
            scrollBodyHeight={scrollBodyHeight}
            onRowClick={onRowClickRight}
            style={styleRight}
          />
          {footerRight}
        </Card>
      </Col>
    </Row>
  )
}
