import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { QueryConfig, requestAsync } from 'redux-query'
import { downloadFile, openPdfFile } from 'utilities'
import { CSVLink } from 'react-csv'

export interface ExportButtonProps {
  exportName: string
  exportQueryConfig: QueryConfig<unknown>
  children?: React.ReactNode
  setErrorsHandler?: Function
  setLoadingHandler?: Function
  noIcon?: boolean
}

export interface ExportButtonCSVLinkProps {
  csvLink: CSVLink
  children?: React.ReactNode
  noIcon?: boolean
}

export const ExportButton = (props: ExportButtonProps) => {
  const dispatch = useDispatch()

  const doExport = (e) => {
    e.preventDefault()
    props.setLoadingHandler?.(true)
    ;(dispatch(requestAsync(props.exportQueryConfig)) as any).then((response) => {
      props.setErrorsHandler?.(response?.body?.messages || [])
      props.setLoadingHandler?.(false)
      if (response?.body?.messages == null) {
        if (response?.body.csv) {
          const csv = response?.body?.csv
          downloadFile(`${props.exportName} ${new Date()}.csv`, csv)
        } else if (response?.body?.url) {
          const pdf = response.body.url
          openPdfFile(pdf, `${props.exportName}.pdf`)
        }
      }
    })
  }

  return (
    <Button onClick={doExport} className="btn btn-rotessa-tertiary no-wrap text-center">
      {!props.noIcon && <i className="mdi mdi-download-outline mr-1" />}
      {props.children || 'Export'}
    </Button>
  )
}

export const ExportButtonCSVLink = (props: ExportButtonCSVLinkProps) => {
  return (
    <Button
      onClick={() => props.csvLink.ref.current?.link?.click()}
      className="btn btn-rotessa-tertiary no-wrap text-center"
    >
      {!props.noIcon && <i className="mdi mdi-download-outline mr-1" />}
      {props.csvLink}
      {props.children || 'Export'}
    </Button>
  )
}

export interface OpenFileProps {
  exportQueryConfig: QueryConfig<unknown>
  children?: React.ReactNode
  setErrorsHandler?: Function
  setLoadingHandler?: Function
}

export const OpenFileLink = (props: OpenFileProps) => {
  const dispatch = useDispatch()

  const doOpenFile = (e) => {
    e.preventDefault()
    props.setLoadingHandler?.(true)
    ;(dispatch(requestAsync(props.exportQueryConfig)) as any).then((response) => {
      props.setErrorsHandler?.(response?.body?.messages?.base || [])
      props.setLoadingHandler?.(false)
      if (response?.body?.messages == null && response?.body.url) {
        window.open(response.body.url, '_blank')
      }
    })
  }

  return (
    <Button color="link" className="inline-text" onClick={doOpenFile}>
      {props.children || 'Open File'}
    </Button>
  )
}
