import { Client, User } from 'api'
import showdown from 'showdown'
import dateFormat from 'dateformat'
import TagManager from 'react-gtm-module'
import i18n from '_i18n/i18n'
import store from 'app/store'

export const getSort = (field, order) => {
  const snake_field = field.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
  return `${snake_field}_${order}`
}

export const fixTimeZone = (dateString: string): Date => {
  const d = new Date(dateString)
  const offsetMillis = d.getTimezoneOffset() * 60000
  return new Date(d.getTime() + offsetMillis)
}

const dateify = (date: string | Date, correctTimeZone = true): Date => {
  return typeof date === 'string' ? stringToDate(date, correctTimeZone) : date
}

export const stringToDate = (date: string, correctTimeZone = true): Date => {
  return correctTimeZone ? fixTimeZone(date) : new Date(date)
}

export const formatDateYmd = (date: string | Date): string => {
  try {
    return dateify(date)?.toISOString()?.split('T')?.[0]
  } catch (error) {
    return typeof date === 'string' ? date : ''
  }
}

/**
 * Updates the date to be have a time of 00:00:00 UTC.
 * This is to preserve the original date from the clients local timezone when sending to server side,
 * even if it would normally be a different date when converted to UTC.
 */
export const utcSafeDate = (date: Date) => {
  const utcDate = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T00:00:00Z`
  return new Date(utcDate)
}

export const formatDateGreg = (date: string | Date): string => {
  return dateFormat(dateify(date), 'mediumDate')
}

export const formatDateLong = (date: string | Date) => {
  return dateFormat(dateify(date), 'longDate')
}

export const formatDateTime = (date: string | Date, format, correctTimeZone = true) => {
  return dateFormat(dateify(date, correctTimeZone), format)
}

export const daysToMillis = (days: number) => {
  return hoursToMillis(24 * days)
}

export const hoursToMillis = (hours: number) => {
  return minutesToMillis(60 * hours)
}

export const minutesToMillis = (minutes: number) => {
  return 1000 * 60 * minutes
}

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const titleify = (s: string) => {
  return s
    .replace(/([A-Z])/g, ' $1')
    .split(/_|-/)
    .map((w) => capitalize(w))
    .join(' ')
}

export const camelToSnakeCase = (s: string) => {
  return s.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const maskBankInfo = (s) => {
  return '*'.repeat(Math.max(0, s.length - 2)) + s.substr(-2)
}

export const randomString = (length: number) => {
  const chars = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

export const formatCurrency = (value) => {
  if (!Number.parseFloat(value)) return '$0'
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  }).format(value)
}

const isHtml = (html: string): boolean => {
  return html.match(/<(div|p|a|b|br|strong|ul|ol|li)>/) !== null
}
const converter = new showdown.Converter({
  simpleLineBreaks: true,
})
export const htmlToMarkdown = (html: string) => {
  if (isHtml(html)) {
    const fixedHtml = html.replaceAll(/(<br>)+<\/strong>/g, '</strong><br>').replaceAll(/<(\/)?div>/g, '<$1p>')

    return converter
      .makeMarkdown(fixedHtml)
      .replaceAll(/<br>/g, '\n')
      .replaceAll(/\n{3,}/g, '\n\n')
      .trim()
  }
  return html
}

export const markdownToHtml = (markdown: string) => {
  if (!isHtml(markdown)) {
    return converter.makeHtml(markdown).replaceAll(/\n/g, '<br>')
  }
  return markdown
}

export const clientDisplayName = (activeUser: User, client: Client): string => {
  let displayName = client?.operatingAs || client?.businessName || ''

  if (activeUser?.rotessaStaff) {
    displayName = client?.id + ' - ' + displayName
  }

  return displayName
}

export const prepInput = (input) => {
  const modifiedInput = camelToSnakeCase(input)
  const trimLength = modifiedInput.indexOf('.')
  if (trimLength > -1) {
    return modifiedInput.slice(trimLength + 1)
  } else {
    return modifiedInput
  }
}

export const isPermitted = (permissionName: string, client: Client) => {
  const [category, name] = permissionName?.split('.') || []
  return category && name && client?.permissions?.[category] && client?.permissions?.[category].indexOf(name) !== -1
}

export const isReleased = (releaseHelperName: string, client?: Client) => {
  let releaseHelpers = store.getState().entities.releaseHelpers || {}
  if (client) {
    releaseHelpers = { ...releaseHelpers, ...client.releaseHelpers }
  }
  return !!releaseHelpers[releaseHelperName]
}

export const gtmEvent = (eventName: string) => {
  TagManager.dataLayer({ dataLayer: { event: eventName } })
}

export const downloadFile = (name: string, content: string, type = 'text/csv', charset = 'utf-8') => {
  const data = new Blob([content], { type: `${type};charset=${charset};` })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(data)
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadPdfFile = (url: string, name: string) => {
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
}

export const openPdfFile = (url: string, name: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const constrainRecordKeyLength = (records: any[], readKey: string, writeKey?: string, percentile = 0.8) => {
  if (records.length < 5) return
  const sorted = records.sort((a, b) =>
    a[readKey]?.length > b[readKey]?.length ? 1 : a[readKey]?.length < b[readKey]?.length ? -1 : 0,
  )
  const chopLength = sorted[Math.round(percentile * sorted.length)][readKey].length
  records.forEach((record) => {
    if (record[readKey].length > chopLength) {
      record[writeKey || readKey] = record[readKey].slice(0, chopLength - 1) + '…'
    }
  })
}

export const fileNameFromUrl = (url) => {
  return url?.length > 0 ? url.substr(url.lastIndexOf('/') + 1) : ''
}

export const processingAuthBaseUrl = `${window.location.origin}/authorize/`
export const donationAuthBaseUrl = `${window.location.origin}/donation/`
export const processingAuthUrl = (code) => `${processingAuthBaseUrl}${code}`
export const donationAuthUrl = (code) => `${donationAuthBaseUrl}${code}`
export const linkAuthUrl = (code) => `${window.location.origin}/link_authorizations?form_url=${code}`
export const authUrl = (code, authType) => {
  switch (authType) {
    case 'donation':
      return donationAuthUrl(code)
    case 'tuition':
      return linkAuthUrl(code)
    default:
      return processingAuthUrl(code)
  }
}

export const isValidPhone = (phone: string | null) => {
  if (phone === null || phone === '') {
    return null
  }
  return phone.replaceAll(/[^0-9]/g, '').length >= 10
}

export const validatePhone = (value, ctx, input, cb) => {
  if (value) {
    cb(isValidPhone(value) || i18n.t('contact.phone_error'))
  } else {
    cb(true)
  }
}

export const truncateText = (text, maxLength, truncateWith = '...') => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}${truncateWith}` : text
}

export const humanize_string = (str) => {
  if (str == undefined) return ''

  let i,
    frags = str.split('_')

  frags[0] = frags[0].charAt(0).toUpperCase() + frags[0].slice(1)
  for (i = 1; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0) + frags[i].slice(1)
  }

  return frags.join(' ')
}

export const ArrayJoinFormatter = (cell) => {
  return cell?.join(' ')
}

export const ArrayFirstItemFormatter = (cell) => {
  return cell && cell[0]
}
