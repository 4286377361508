import { createAction, createReducer } from '@reduxjs/toolkit'
import { AUTH_HEADER_KEYS } from './constants'

export interface AuthState {
  isLoggedIn: boolean
}

export const login = createAction('auth/login')
export const logout = createAction('auth/logout')

const initialState: AuthState = {
  isLoggedIn: localStorage.getItem(AUTH_HEADER_KEYS[0]) !== null,
}

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login, (state, _action) => {
      const wasLoggedIn = state.isLoggedIn
      state.isLoggedIn = true

      if (!wasLoggedIn) {
        const afterPath = new URLSearchParams(window.location.search).get('after')
        if (afterPath) {
          window.location.assign(afterPath)
        }
      }
    })
    .addCase(logout, (state, _action) => {
      AUTH_HEADER_KEYS.forEach((key) => {
        localStorage.removeItem(key)
      })
      state.isLoggedIn = false
      window.location.assign('/login')
    })
})
