import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Fade } from 'reactstrap'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'
import warning_sign_icon from 'assets/images/donor_auth_icons/warning_sign_icon.svg'

export const DonorAuthEmailExpired = () => {
  const [t, i18n] = useTranslation()
  const [language, setLanguage] = useState('en')

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const toggleLanguage = (event) => {
    setLanguage(language === 'en' ? 'fr' : 'en')
  }
  return (
    <Fade>
      <div className="donation-content">
        <Card>
          <CardBody>
            <div className="mt-2 text-center">
              <img src={warning_sign_icon} alt="warning sign icon" height={35} />
              <h3 className="mt-3 text-almost-black">{t('authorization.donation.email_expired.header')}</h3>
              <p>
                <Trans i18nKey="authorization.donation.email_expired.try_again" components={{ bold: <strong /> }} />
              </p>
            </div>
          </CardBody>
        </Card>
        <div className="text-center mt-3 mb-2">
          <Link to={() => ({})} onClick={toggleLanguage}>
            <u>{t('language_toggle')}</u>
          </Link>
        </div>
        <PoweredByRotessaFooter type="donation_footer" source="authPage" />
      </div>
    </Fade>
  )
}
