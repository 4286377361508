// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    Client,
    ClientFromJSON,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProcessingAuthorizationDetails200Response
 */
export interface GetProcessingAuthorizationDetails200Response  {
    /**
     * 
     * @type {number}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    status?: number;
    /**
     * 
     * @type {Client}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    client?: Client;
    /**
     * 
     * @type {object}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    step?: object;
    /**
     * 
     * @type {string}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    id?: string;
    /**
     * 
     * @type {Address}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    phone?: string;
    /**
     * 
     * @type {object}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    messages?: object;
    /**
     * 
     * @type {string}
     * @memberof GetProcessingAuthorizationDetails200Response
     */
    language?: string;
}

export function GetProcessingAuthorizationDetails200ResponseFromJSON(json: any): GetProcessingAuthorizationDetails200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'step': !exists(json, 'step') ? undefined : json['step'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function GetProcessingAuthorizationDetails200ResponseToJSON(value?: GetProcessingAuthorizationDetails200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'client': ClientToJSON(value.client),
        'step': value.step,
        'id': value.id,
        'address': AddressToJSON(value.address),
        'email': value.email,
        'phone': value.phone,
        'messages': value.messages,
        'language': value.language,
    };
}


