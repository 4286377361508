import React from 'react'
import { Card, CardBody, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { HashRouter, Route, Switch, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import 'easymde/dist/easymde.min.css'

import { AuthorizationLinkSettings } from 'features/authorization_settings/authorizationLinkSettings'
import { AuthorizationEmailSettings } from 'features/authorization_settings/authorizationEmailSettings'
import { AuthorizationTemplates } from 'features/authorization_settings/authorizationTemplates'
import { AuthorizationResources } from 'features/authorization_settings/authorizationResources'

const AuthorizationsOverview = () => {
  return (
    <>
      <h4 className="header-title mb-3">Authorizations Overview</h4>
      <p>
        To withdraw money from your customers' accounts, you need a pre-authorized debit agreement, also known as a
        payment agreement. This gives you their account details and permission. It is legally required as it outlines
        the agreement between you and the customer.
      </p>
      <p>
        The pages in this section provide several tools to simplify the process of collecting authorizations
        electronically or in person.
      </p>
    </>
  )
}

const authSettingsPages = [
  {
    hash: '/',
    name: 'Overview',
    component: AuthorizationsOverview,
  },
  {
    hash: '/email',
    name: 'Email Settings',
    component: AuthorizationEmailSettings,
  },
  {
    hash: '/link',
    name: 'Link Settings',
    component: AuthorizationLinkSettings,
  },
  {
    hash: '/in-person',
    name: 'In Person',
    component: AuthorizationTemplates,
  },
  {
    hash: '/resources',
    name: 'Resources',
    component: AuthorizationResources,
  },
]

export const AuthorizationSettingsPage = () => {
  const location = useLocation()

  return (
    <HashRouter>
      <Row style={{ maxWidth: '900px', margin: 'auto' }}>
        <Col xs={12} sm={{ size: 8, offset: 4 }}>
          <div className="page-title-box">
            <h4 className="page-title">Authorization Settings</h4>
          </div>
        </Col>
      </Row>
      <Row style={{ maxWidth: '900px', margin: 'auto' }}>
        <Col xs={12} sm={4}>
          <ListGroup>
            {authSettingsPages.map((page, i) => (
              <ListGroupItem
                key={i}
                tag="a"
                href={`#${page.hash}`}
                action
                className={classNames({
                  active: location.hash?.substr(1) === page.hash,
                })}
              >
                {page.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col xs={12} sm={8}>
          <Card>
            <CardBody>
              <Switch>
                {authSettingsPages.map((page, i) => (
                  <Route exact key={i} path={page.hash}>
                    <page.component />
                  </Route>
                ))}
              </Switch>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </HashRouter>
  )
}
