// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RolePermission
 */
export interface RolePermission  {
    /**
     * 
     * @type {number}
     * @memberof RolePermission
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof RolePermission
     */
    roleId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RolePermission
     */
    manageIntegrations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RolePermission
     */
    crdApiKeys?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RolePermission
     */
    cudCustomers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RolePermission
     */
    editAuthorizationSettings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RolePermission
     */
    customerExport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RolePermission
     */
    cudTransactionSchedules?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RolePermission
     */
    readUnmaskedBankNumbers?: boolean;
}

export function RolePermissionFromJSON(json: any): RolePermission {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'roleId': !exists(json, 'role_id') ? undefined : json['role_id'],
        'manageIntegrations': !exists(json, 'manage_integrations') ? undefined : json['manage_integrations'],
        'crdApiKeys': !exists(json, 'crd_api_keys') ? undefined : json['crd_api_keys'],
        'cudCustomers': !exists(json, 'cud_customers') ? undefined : json['cud_customers'],
        'editAuthorizationSettings': !exists(json, 'edit_authorization_settings') ? undefined : json['edit_authorization_settings'],
        'customerExport': !exists(json, 'customer_export') ? undefined : json['customer_export'],
        'cudTransactionSchedules': !exists(json, 'cud_transaction_schedules') ? undefined : json['cud_transaction_schedules'],
        'readUnmaskedBankNumbers': !exists(json, 'read_unmasked_bank_numbers') ? undefined : json['read_unmasked_bank_numbers'],
    };
}

export function RolePermissionToJSON(value?: RolePermission): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'role_id': value.roleId,
        'manage_integrations': value.manageIntegrations,
        'crd_api_keys': value.crdApiKeys,
        'cud_customers': value.cudCustomers,
        'edit_authorization_settings': value.editAuthorizationSettings,
        'customer_export': value.customerExport,
        'cud_transaction_schedules': value.cudTransactionSchedules,
        'read_unmasked_bank_numbers': value.readUnmaskedBankNumbers,
    };
}


