import { ExternalLink } from 'components/ExternalLink'
import React from 'react'

const TestimonialShowcase = ({
  title,
  buttonLink,
  buttonText,
}: {
  title: string
  buttonLink: string
  buttonText: string
}): JSX.Element => {
  return (
    <div className="auth-user-testimonial">
      <div className="mb-3">
        <h3
          className="mt-3 sub-header"
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '38rem', fontSize: '1.8rem', fontWeight: 600 }}
        >
          {title}
        </h3>
        <ExternalLink
          to={buttonLink}
          style={{ backgroundColor: 'white', maxHeight: '2.5rem' }}
          className="mt-2 min-width btn btn-light align-items-center"
        >
          <b>
            {buttonText} <i style={{ fontSize: '1.2rem' }} className="mdi mdi-play align-middle" />
          </b>
        </ExternalLink>
      </div>
    </div>
  )
}

export default TestimonialShowcase
