// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HomepageTransactionsChartDataInner
 */
export interface HomepageTransactionsChartDataInner  {
    /**
     * 
     * @type {string}
     * @memberof HomepageTransactionsChartDataInner
     */
    processDate?: string;
    /**
     * 
     * @type {number}
     * @memberof HomepageTransactionsChartDataInner
     */
    amount?: number;
}

export function HomepageTransactionsChartDataInnerFromJSON(json: any): HomepageTransactionsChartDataInner {
    return {
        'processDate': !exists(json, 'process_date') ? undefined : json['process_date'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function HomepageTransactionsChartDataInnerToJSON(value?: HomepageTransactionsChartDataInner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'process_date': value.processDate,
        'amount': value.amount,
    };
}


