// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationSettingsPaymentReminder,
    NotificationSettingsPaymentReminderFromJSON,
    NotificationSettingsPaymentReminderToJSON,
} from './';

/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings  {
    /**
     * 
     * @type {NotificationSettingsPaymentReminder}
     * @memberof NotificationSettings
     */
    paymentReminder?: NotificationSettingsPaymentReminder;
    /**
     * 
     * @type {NotificationSettingsPaymentReminder}
     * @memberof NotificationSettings
     */
    paymentReceipt?: NotificationSettingsPaymentReminder;
}

export function NotificationSettingsFromJSON(json: any): NotificationSettings {
    return {
        'paymentReminder': !exists(json, 'payment_reminder') ? undefined : NotificationSettingsPaymentReminderFromJSON(json['payment_reminder']),
        'paymentReceipt': !exists(json, 'payment_receipt') ? undefined : NotificationSettingsPaymentReminderFromJSON(json['payment_receipt']),
    };
}

export function NotificationSettingsToJSON(value?: NotificationSettings): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'payment_reminder': NotificationSettingsPaymentReminderToJSON(value.paymentReminder),
        'payment_receipt': NotificationSettingsPaymentReminderToJSON(value.paymentReceipt),
    };
}


