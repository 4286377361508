import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest, useMutation } from 'redux-query-react'
import { useLocation } from 'react-router-dom'
import { AvForm } from 'availity-reactstrap-validation'
import { Button, Card, CardBody, Row, Col, Fade, Alert, CardHeader } from 'reactstrap'

import { EmailAuthorization, getEmailAuthorizationDetails, submitEmailAuthorization } from 'api'
import { getEntities } from 'app/selectors'
import { ProcessingAcceptTermsCheckbox } from './processingAuthAcceptTermsCheckbox'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'
import { Loading } from 'components/Loading'
import { BankAccountFields } from 'components/BankAccountFields'
import { FormServerErrors } from 'components/FormServerErrors'
import { Errors } from 'hooks/useFormServerErrors'

export const EmailAuthorizationForm = () => {
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { client, settings } = useSelector((state) => getEntities(state).emailAuthDetails) || {}

  const query = new URLSearchParams(useLocation().search)
  const customerId = Number.parseInt(query.get('cid') || '0')
  const customerName = query.get('name') || ''
  const customerEmail = query.get('email') || ''

  const [requestState, refreshRequest] = useRequest(
    getEmailAuthorizationDetails(
      {
        cid: customerId,
        name: customerName,
        email: customerEmail,
      },
      {
        force: true,
        transform: (body) => {
          return {
            emailAuthDetails: body,
          }
        },
        update: {
          emailAuthDetails: (_, newValue) => newValue,
        },
      },
    ),
  )

  const [mutationState, doSubmit] = useMutation((values: EmailAuthorization) => {
    return submitEmailAuthorization({
      emailAuthorization: values,
    })
  })

  function handleValidSubmit(_event, values) {
    values.customerId = customerId
    values.name = customerName
    values.email = customerEmail
    doSubmit(values)?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      if (response?.body?.status === 200) {
      }
    })
  }

  const newEmailAuth: EmailAuthorization = {
    name: customerName,
    email: customerEmail,
    customerId: customerId,
  }

  const isPending = requestState.isPending || mutationState.isPending

  const cardContent =
    !(customerId && customerName && customerEmail) || client?.newAuthSettingsEnabled ? (
      <>
        <h4 className="header-title">Payment Authorization</h4>
        <Alert color="warning">
          <i className="mdi mdi-alert-outline mr-1" />
          The link appears to have expired. Please reach out to the requesting organization for a new link.
        </Alert>
      </>
    ) : mutationState.isFinished ? (
      <CardBody className="text-center">
        <h2>Thank you</h2>
        <h4 className="header-title">for setting up payments with</h4>
        <h3>{client?.businessName}</h3>
      </CardBody>
    ) : requestState.isFinished ? (
      <AvForm disabled={isPending} onValidSubmit={handleValidSubmit} model={newEmailAuth}>
        <CardHeader className="text-center">
          {client?.logo && <img src={client.logo.url} style={{ maxHeight: '200px', maxWidth: '200px' }} alt="" />}
          <h4 className="header-title mt-3">Authorize automatic payments for</h4>
          <h3>{client?.businessName}</h3>
        </CardHeader>
        <CardBody>
          <FormServerErrors errors={(serverErrors && serverErrors['base']) || []} />
          <h4 className="header-title">Banking Information</h4>
          <BankAccountFields countryCode={client?.countryCode} required serverErrors={serverErrors} />

          <h4 className="header-title">Terms & Conditions</h4>
          <Row className="mb-2">
            <Col>
              <ProcessingAcceptTermsCheckbox
                businessName={client?.businessName}
                terms={settings?.customTermsAndConditions}
                serverErrors={serverErrors}
              />
            </Col>
          </Row>

          <div className="text-center">
            <Button disabled={isPending} color="primary">
              <i className="mdi mdi-lock-outline mr-1" />
              Authorize
            </Button>
          </div>
        </CardBody>
      </AvForm>
    ) : (
      <Loading />
    )

  return (
    <div
      style={{
        maxWidth: '700px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50px',
      }}
    >
      <Fade>
        <Card>{cardContent}</Card>
        <PoweredByRotessaFooter type="donation_footer" source="authPage" />
      </Fade>
    </div>
  )
}
