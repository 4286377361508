// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormUrl
 */
export interface FormUrl  {
    /**
     * 
     * @type {string}
     * @memberof FormUrl
     */
    formUrl?: string;
}

export function FormUrlFromJSON(json: any): FormUrl {
    return {
        'formUrl': !exists(json, 'form_url') ? undefined : json['form_url'],
    };
}

export function FormUrlToJSON(value?: FormUrl): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'form_url': value.formUrl,
    };
}


