import React, { useState } from 'react'
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap'
import { randomString } from 'utilities'

export interface InfoPopoverProps {
  popoverText: string | JSX.Element
  isHTMLString?: boolean
}

export const InfoPopover = (props: InfoPopoverProps) => {
  const [id, _setId] = useState(`popover${randomString(10)}`)

  return (
    <>
      <Button type="button" id={id} color="link" className="p-0">
        <i className="mdi mdi-information-outline ml-1" />
      </Button>
      <UncontrolledPopover placement="bottom" target={id} trigger="hover">
        {props.isHTMLString && typeof props.popoverText === 'string' ? (
          <PopoverBody dangerouslySetInnerHTML={{ __html: props.popoverText }} />
        ) : (
          <PopoverBody>{props.popoverText}</PopoverBody>
        )}
      </UncontrolledPopover>
    </>
  )
}
