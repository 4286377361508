import React from 'react'

export const ExternalLink = (props) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={props?.to}
      download={props?.download}
      className={props?.className}
      style={props?.style}
    >
      {props?.children}
    </a>
  )
}

export const ExternalTertiaryLink = (props) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={props?.to}
      download={props?.download}
      className="btn-rotessa-tertiary"
      style={props?.style}
    >
      {props?.children}
    </a>
  )
}
