// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplyPromoCodeBody
 */
export interface ApplyPromoCodeBody  {
    /**
     * 
     * @type {string}
     * @memberof ApplyPromoCodeBody
     */
    promoCode?: string;
}

export function ApplyPromoCodeBodyFromJSON(json: any): ApplyPromoCodeBody {
    return {
        'promoCode': !exists(json, 'promo_code') ? undefined : json['promo_code'],
    };
}

export function ApplyPromoCodeBodyToJSON(value?: ApplyPromoCodeBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'promo_code': value.promoCode,
    };
}


