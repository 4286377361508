// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Address
 */
export interface Address  {
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    provinceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof Address
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Address
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressType?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressCategory?: string;
}

export function AddressFromJSON(json: any): Address {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'address1': !exists(json, 'address_1') ? undefined : json['address_1'],
        'address2': !exists(json, 'address_2') ? undefined : json['address_2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'provinceCode': !exists(json, 'province_code') ? undefined : json['province_code'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'addressType': !exists(json, 'address_type') ? undefined : json['address_type'],
        'addressCategory': !exists(json, 'address_category') ? undefined : json['address_category'],
    };
}

export function AddressToJSON(value?: Address): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'address_1': value.address1,
        'address_2': value.address2,
        'city': value.city,
        'province_code': value.provinceCode,
        'postal_code': value.postalCode,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'address_type': value.addressType,
        'address_category': value.addressCategory,
    };
}


