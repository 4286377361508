// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    SettlementTransactionsClientOnboarding,
    SettlementTransactionsClientOnboardingFromJSON,
    SettlementTransactionsClientOnboardingToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettlementTransactions
 */
export interface SettlementTransactions  {
    /**
     * 
     * @type {number}
     * @memberof SettlementTransactions
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementTransactions
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactions
     */
    processDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactions
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactions
     */
    statusReason?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactions
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementTransactions
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactions
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactions
     */
    customerCustomIdentifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettlementTransactions
     */
    reportedToIntegration?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactions
     */
    integrated?: string;
    /**
     * 
     * @type {SettlementTransactionsClientOnboarding}
     * @memberof SettlementTransactions
     */
    clientOnboarding?: SettlementTransactionsClientOnboarding;
    /**
     * 
     * @type {Client}
     * @memberof SettlementTransactions
     */
    client?: Client;
}

export function SettlementTransactionsFromJSON(json: any): SettlementTransactions {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'processDate': !exists(json, 'process_date') ? undefined : json['process_date'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusReason': !exists(json, 'status_reason') ? undefined : json['status_reason'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'customerId': json['customer_id'],
        'customerName': !exists(json, 'customer_name') ? undefined : json['customer_name'],
        'customerCustomIdentifier': !exists(json, 'customer_custom_identifier') ? undefined : json['customer_custom_identifier'],
        'reportedToIntegration': !exists(json, 'reported_to_integration') ? undefined : json['reported_to_integration'],
        'integrated': !exists(json, 'integrated') ? undefined : json['integrated'],
        'clientOnboarding': !exists(json, 'client_onboarding') ? undefined : SettlementTransactionsClientOnboardingFromJSON(json['client_onboarding']),
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
    };
}

export function SettlementTransactionsToJSON(value?: SettlementTransactions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'amount': value.amount,
        'process_date': value.processDate,
        'status': value.status,
        'status_reason': value.statusReason,
        'comment': value.comment,
        'customer_id': value.customerId,
        'customer_name': value.customerName,
        'customer_custom_identifier': value.customerCustomIdentifier,
        'reported_to_integration': value.reportedToIntegration,
        'integrated': value.integrated,
        'client_onboarding': SettlementTransactionsClientOnboardingToJSON(value.clientOnboarding),
        'client': ClientToJSON(value.client),
    };
}


