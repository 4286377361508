import React from 'react'

export const BBB = () => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.bbb.org/manitoba/business-reviews/business-services/rotessa-in-steinbach-mb-36713#bbbseal"
      title="Rotessa Inc., Business Services, Steinbach, MB"
    >
      <div className="bbb"></div>
    </a>
  )
}
