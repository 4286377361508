import React, { useState } from 'react'
import { Button, Row, Col, Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'

interface TableHeaderProps {
  newLink?: string
  onClickNew?: () => void
  children?: any
  tableTitle?: string
  onChangeSearch?: (text: string) => void
  hideImport?: boolean
  hideExport?: boolean
  onClickRefresh?: () => void
  data?: any[]
  csvHeader?: any[]
  csvExportName?: string
}

export interface NewButtonProps {
  link?: string
  onClick?: () => void
}

export const TableNewButton = (props: NewButtonProps) => {
  const Wrapper = ({ children }) =>
    props?.link ? <Link to={props.link}>{children}</Link> : <React.Fragment>{children}</React.Fragment>
  return props?.link || props?.onClick ? (
    <Wrapper>
      <Button onClick={props?.onClick} color="primary" className="ml-1 mb-2">
        <i className="mdi mdi-plus-circle mr-1"></i>New
      </Button>
    </Wrapper>
  ) : null
}

export interface TableSearchFieldProps {
  onChange: Function
  className?: string
}

export const TableSearchField = ({ onChange, className }: TableSearchFieldProps) => {
  const [timeoutId, setTimeoutId] = useState(0)

  function handleSearchChange(event) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    setTimeoutId(window.setTimeout(() => onChange(event), 300))
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') event.preventDefault()
  }
  return onChange ? (
    <div className="table-search-container">
      <Input
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
        placeholder="Search"
        className={className || ''}
      />
      <span className="mdi mdi-magnify table-search"></span>
    </div>
  ) : null
}

export const TableHeader = ({
  newLink,
  onClickNew,
  children,
  tableTitle,
  onChangeSearch,
  hideImport,
  hideExport,
  data,
  csvHeader,
  csvExportName,
}: TableHeaderProps) => {
  return (
    <Row className="clearfix mb-2">
      {tableTitle && <h4 className="header-title ml-2">{tableTitle}</h4>}
      {onChangeSearch && (
        <Col xs="auto">
          <TableSearchField onChange={onChangeSearch} />
        </Col>
      )}
      {children && <Col xs="auto">{children}</Col>}
      <Col className="text-right">
        {!hideImport && (
          <Button color="btn-rotessa-secondary min-width" className="mr-1 mb-2">
            <i className="mdi mdi-upload-outline" />
            Import
          </Button>
        )}
        {!hideExport && data && (
          <CSVLink
            data={data}
            headers={csvHeader}
            filename={csvExportName}
            target="_blank"
            className="btn btn-rotessa-tertiary mb-2 no-wrap"
          >
            <i className="mdi mdi-download-outline mr-1" />
            Export
          </CSVLink>
        )}
        {(newLink || onClickNew) && <TableNewButton link={newLink} onClick={onClickNew} />}
      </Col>
    </Row>
  )
}
