import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, InputGroup, InputGroupAddon, Fade, Input, Form, Carousel, CarouselItem, Row, Col } from 'reactstrap'

import { getActiveClient } from 'app/selectors'
import { CenterContent } from 'components/CenterContent'
import { updateIntegration } from 'api'
import { useMutation } from 'redux-query-react'
import { TitleBar } from 'features/customers/customerFlow/titleBar'
import { useHistory } from 'react-router-dom'
import { integrationName } from 'components/Integrations'
import { CustomCarousel } from 'components/CustomCarousel'
import ClearinginfoImage1 from 'assets/images/custom_carousel/Rotessa-Clearing-Account-Info-1.jpg'
import ClearinginfoImage2 from 'assets/images/custom_carousel/Rotessa-Clearing-Account-Info-2.jpg'
import { QUICKBOOKS, XERO } from 'app/constants'

export const IntegrationClearingAccountForm = ({ integration, doRequest }) => {
  const activeClient = useSelector(getActiveClient) || {}
  const [accountId, setAccountId] = useState(integration?.selectedPaymentAccountId)
  const history = useHistory()

  const handleSavePaymentAccount = () => {
    doUpdateIntegration(accountId)?.then(() => doRequest())
    history.push('/client/integrations')
  }

  const [updateIntegrationState, doUpdateIntegration] = useMutation((accountId: string) =>
    updateIntegration({
      integrationUpdate: {
        clientId: activeClient.id,
        paymentAccountId: accountId,
      },
    }),
  )

  const carouselItems = [
    {
      caption: 'Why should you create a Rotessa clearing account?',
      content: (
        <Row>
          <Col xs={3} className="d-flex align-items-center">
            <img src={ClearinginfoImage1} className="w-100" />
          </Col>
          <Col>
            <h4>Why should you create a Rotessa clearing account?</h4>
            <p className="text-muted" style={{ lineHeight: 1.2 }}>
              Select your clearing account from {integrationName(activeClient.integration)} with the drop down menu
              below. Rotessa will post the status of the payment to your invoice in your accounting software. "Paid"
              invoices will be recorded in the clearing account for reconciliation purposes.
            </p>
          </Col>
        </Row>
      ),
    },
  ]
  if (activeClient.integration === QUICKBOOKS) {
    carouselItems.push({
      caption: 'How to create a Rotessa clearing account?',
      content: (
        <Row>
          <Col xs={3} className="d-flex align-items-center">
            <img src={ClearinginfoImage2} className="w-100" />
          </Col>
          <Col>
            <h4>How to create a Rotessa clearing account?</h4>
            <p className="text-muted" style={{ lineHeight: 1.2 }}>
              In QuickBooks Online, from the Lists menu, select Chart of Accounts. Select New and name this account
              Rotessa Clearing Account. Do not enter an opening balance. When you integrate Rotessa with your accounting
              software, select this account to post your invoice payments. Rotessa will mark these invoices as paid in
              your software and you can easily match these to your settlements in your bank feed.
            </p>
          </Col>
        </Row>
      ),
    })
  } else if (activeClient.integration === XERO) {
    carouselItems.push({
      caption: 'How to create a Rotessa clearing account?',
      content: (
        <Row>
          <Col xs={3} className="d-flex align-items-center">
            <img src={ClearinginfoImage2} className="w-100" />
          </Col>
          <Col>
            <h4>How to create a Rotessa clearing account?</h4>
            <p className="text-muted" style={{ lineHeight: 1.2 }}>
              In Xero, create a new account called Rotessa clearing account. When you integrate Rotessa with your
              accounting software, select this account to post your invoice payments. Rotessa will mark these invoices
              as paid in your software and you can easily match these to your settlements in your bank feed.
            </p>
          </Col>
        </Row>
      ),
    })
  }

  const isPending = updateIntegrationState.isPending

  return (
    <Fade>
      <TitleBar
        title="Select your clearing account"
        info="Typically, a clearing account contains undeposited funds to be matched to your regular operating account deposits. Once your Rotessa funds are settled in your operating account, you can safely reconcile transaction information from the clearing account to the transactions in your bank feed without any inconsistency in your books."
        subtitle={
          <p style={{ textAlign: 'center' }}>
            Select your clearing account in {integrationName(activeClient?.integration)} from the drop down menu below.
            Rotessa posts the status of the payment to your invoice in your accounting software. "Paid" invoices are
            posted in the clearing account for efficient reconciliation.
          </p>
        }
        noBack
      />
      <div className="mb-2 mt-2">
        <CenterContent>
          <Form className="mb-5">
            <InputGroup>
              <Input
                type="select"
                defaultValue={integration?.selectedPaymentAccountId}
                disabled={isPending}
                name="paymentAccountId"
                onChange={(e) => setAccountId(e.target.value)}
              >
                <option> -- </option>
                {integration?.paymentAccounts &&
                  integration?.paymentAccounts.map((account, i) => (
                    <option key={i} value={account.id}>
                      {account.name}
                    </option>
                  ))}
              </Input>
              <InputGroupAddon addonType="append">
                <Button disabled={isPending} color="primary" onClick={handleSavePaymentAccount}>
                  Save
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <CustomCarousel items={carouselItems} />
        </CenterContent>
      </div>
    </Fade>
  )
}
