import React, { useState } from 'react'
import { useMutation } from 'redux-query-react'
import { useHistory, useLocation } from 'react-router'
import { Button, Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { PasswordUpdate, updatePassword } from 'api'
import { useFormServerErrors } from 'hooks/useFormServerErrors'
import { AUTH_HEADER_KEYS, RESET_PASSWORD_PREFIX } from 'app/constants'
import { FormServerErrors } from 'components/FormServerErrors'

export const ResetPassword = () => {
  const history = useHistory()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  AUTH_HEADER_KEYS.forEach((key) => {
    localStorage.setItem(`${RESET_PASSWORD_PREFIX}${key}`, search.get(key) || '')
  })
  const [errors, setErrors] = useState({})
  const { handleFieldChange, validate } = useFormServerErrors(errors, setErrors)

  const [mutationState, doMutation] = useMutation((values: PasswordUpdate) => {
    return updatePassword({
      passwordUpdate: { ...values },
    })
  })

  function handleValidSubmit(_event, values) {
    doMutation(values)?.then((response) => {
      setErrors(response?.body?.errors || {})
      if (response?.body?.success) {
        AUTH_HEADER_KEYS.forEach((key) => {
          localStorage.removeItem(`${RESET_PASSWORD_PREFIX}${key}`)
        })
        history.replace('/reset_password_success')
      }
    })
  }

  const invalidLink = search.get('invalid_link') || 'false'
  const twoFactorEnabled = search.get('two_factor') || 'false'

  const handleClickAcknowledge = () => {
    history.replace(`/`)
  }

  return (
    <>
      <Modal isOpen={invalidLink === 'true'}>
        <ModalHeader>Invalid Password Reset Link</ModalHeader>
        <ModalBody>
          <p>
            The password reset link used is no longer valid. It has either expired or has already been used. Links
            expire after 24hrs or when a new password reset has been requested.
          </p>
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={handleClickAcknowledge}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col md={{ size: 4, offset: 4 }}>
          <div className="page-title-box">
            <h4 className="page-title">Enter a new Password</h4>
          </div>
          <AvForm disabled={mutationState.isPending} onValidSubmit={handleValidSubmit}>
            <Card>
              <CardBody>
                <FormServerErrors errors={(errors && Object.values(errors)) || []} />
                <AvField
                  name="password"
                  label="New Password"
                  type="password"
                  required
                  validate={{
                    async: validate,
                    minLength: { value: 8, errorMessage: 'Minimum length: 8' },
                    pattern: {
                      value: /^([\w]+[\W]+|[\W]+[\w]+)/,
                      errorMessage: 'Must contain at least 1 letter or number and 1 symbol',
                    },
                  }}
                  onChange={handleFieldChange}
                />
                <AvField
                  name="passwordConfirmation"
                  label="Confirm New Password"
                  type="password"
                  required
                  validate={{
                    async: validate,
                    match: {
                      value: 'password',
                      errorMessage: 'passwords do not match',
                    },
                  }}
                  onChange={handleFieldChange}
                />
                {twoFactorEnabled === 'true' && (
                  <AvField
                    name="code"
                    label="Verification Code"
                    type="text"
                    onChange={handleFieldChange}
                    required
                    placeholder="6 digits"
                  />
                )}
              </CardBody>
            </Card>

            <div className="text-right">
              <Button disabled={mutationState.isPending} color="primary" className="mb-2 ml-1">
                Submit
              </Button>
            </div>
          </AvForm>
        </Col>
      </Row>
    </>
  )
}
