import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { CustomRadioGroup, CustomRadioInput } from 'components/CustomRadio'
import { useTranslation } from 'react-i18next'

import { ExtendedClientOnboardingType, OnboardingPageProps, NextButton, StepType } from './onboardingManager'
import { Client, User } from 'api'
import { validatePhone } from 'utilities'

import { useDispatch, useSelector } from 'react-redux'
import { getClients, getEntities } from 'app/selectors'
import { updateEntities } from 'redux-query'

import greyArrow from 'assets/images/Grey-Arrow.svg'

export const dynamicUserSubSteps = (
  account: User,
  clientOnboarding: ExtendedClientOnboardingType,
  clients: Client[],
) => {
  const user_complete = account && account.firstName && account.lastName && account.mobilePhone
  let steps: StepType[] = []
  if (clients.length > 0) {
    steps.push({
      label: "The business you're representing",
      name: 'who_are_you',
      status: clientOnboarding.statuses?.who_are_you,
    })
  }
  if (!user_complete) {
    steps.push({ label: 'Your information', name: 'user_info', status: clientOnboarding.statuses?.user_info })
  }

  return steps
}

export const ReferredByComponent = ({
  onNext,
  clientOnboarding,
  handleFieldChange,
  validate,
  setSteps,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const account: User = useSelector((state) => getEntities(state).account) || {}
  const stashedClient = { countryCode: 'CA', ...clientOnboarding.stashedClientDetails }
  const clients: Client[] = useSelector(getClients) || []

  const handleNext = (event, values) => {
    onNext({
      e: event,
      clientValues: values,
    })?.then((response) => {
      if (response?.body?.status === 200) {
        dispatch(
          updateEntities({
            clientOnboarding: (prev: ExtendedClientOnboardingType) => ({
              ...prev,
              stashedClientDetails: { ...prev?.stashedClientDetails, ...values },
            }),
          }),
        )
      }
    })
  }

  useEffect(() => {
    setCurrentPage((prev) => ({ ...prev!, state: 'who_are_you' }))
    setSteps((prev) =>
      prev.map((step) => {
        let newStep = { ...step }
        if (step.name === 'user_info') {
          newStep.subSteps = dynamicUserSubSteps(account, clientOnboarding, clients)
        }
        return newStep
      }),
    )
  }, [account, clientOnboarding])
  return (
    <AvForm model={stashedClient} onValidSubmit={handleNext} ref={formRef}>
      <h3>How did you hear about us?</h3>
      <AvField
        name="referredBy"
        label="How Did You Hear About Us?"
        type="select"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        onChange={handleFieldChange}
        defaultValue=""
      >
        <option value="" disabled selected hidden>
          Choose one...
        </option>
        <option value="QuickBooks/Xero App Store">QuickBooks/Xero App Store</option>
        <option value="Social Media">Social Media</option>
        <option value="Search Engine">Search Engine</option>
        <option value="Word of mouth/Referral">Word of Mouth/Referral</option>
        <option value="Other">Other</option>
      </AvField>
      <NextButton />
    </AvForm>
  )
}

export const WhoAreYouRightPane = ({}) => {
  return (
    <>
      <h3>Why we ask</h3>
      <p>
        It’s important that Rotessa understands your relationship to the business or organization you are signing up in
        order to satisfy our KYC (know your customer) requirements. If you are setting up a Rotessa account on behalf of
        someone else, we will ask for additional information about your identity in the following steps.
      </p>
    </>
  )
}

export const WhoAreYouComponent = ({
  onNext,
  clientOnboarding,
  processingClientOnboarding,
  setSteps,
  activeClient,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const account: User = useSelector((state) => getEntities(state).account) || {}
  const dispatch = useDispatch()
  const [doThirdParty, setDoThirdParty] = useState<boolean>(false)
  const clients: Client[] = useSelector(getClients) || []

  const handleBack = () => {
    setDoThirdParty(false)
    setCurrentPage((prev) => ({ ...prev!, rightPane: WhoAreYouRightPane }))
  }

  const handleNext = (event, values) => {
    if (!doThirdParty && values['hasThirdParty'] === 'true') {
      setDoThirdParty(true)
      setCurrentPage((prev) => ({ ...prev!, rightPane: <></> }))
    } else {
      onNext({
        e: event,
        clientValues: {
          countryCode: clientOnboarding.stashedClientDetails?.countryCode,
          ...values,
          hasThirdParty: values['hasThirdParty'] === 'true' || doThirdParty,
        },
        processingClientOnboardingValues: {
          clientData: {
            ...processingClientOnboarding?.clientData,
            countryCode: clientOnboarding.stashedClientDetails?.countryCode,
            referredBy: clientOnboarding.stashedClientDetails?.referredBy,
            promoCode: clientOnboarding.stashedClientDetails?.promoCode,
          },
        },
      })?.then((response) => {
        if (response?.body?.status === 200) {
          dispatch(
            updateEntities({
              clientOnboarding: (prev: ExtendedClientOnboardingType) => ({
                ...prev,
                stashedClientDetails: {
                  ...prev?.stashedClientDetails,
                  ...values,
                  hasThirdParty: values['hasThirdParty'] === 'true' || doThirdParty,
                },
              }),
            }),
          )
        }
      })
    }
  }

  useEffect(() => {
    setSteps((prev) =>
      prev.map((step) => {
        let newStep = { ...step }
        if (step.name === 'user_info') {
          newStep.subSteps = dynamicUserSubSteps(account, clientOnboarding, clients)
        }
        return newStep
      }),
    )
  }, [account, clientOnboarding])

  return (
    <>
      <AvForm model={clientOnboarding.stashedClientDetails} onValidSubmit={handleNext} ref={formRef}>
        {doThirdParty ? (
          <>
            <div className="page-title-box">
              <Button className="text-left d-flex align-items-center btn-onboarding-cancel" onClick={handleBack}>
                <img src={greyArrow} />
                BACK
              </Button>
              <h3 className="mb-0 mt-0">Ok great!</h3>
              <p className="text-muted mt-2">
                Because you indicated that you are a third-party managing this account on someone else's behalf we want
                to know a bit of information about you before we proceed. In the following sections we’ll ask your
                company’s basic business information, as well as some personal information about you.
                <br />
                <br />
                After that’s complete we’ll get you to finish signing up the account you are managing!
              </p>
            </div>
          </>
        ) : (
          <>
            <h3>Let’s get started</h3>
            <CustomRadioGroup
              name="hasThirdParty"
              label="Please select the answer that best applies to your situation."
              required
            >
              <CustomRadioInput
                label={
                  <div style={{ fontSize: '1.15rem' }}>
                    I am an owner/director or employee of the business or organization I’m signing up.
                  </div>
                }
                value="false"
                name={'Processing'}
              />
              <CustomRadioInput
                label={
                  <div style={{ fontSize: '1.15rem' }}>
                    I am a third-party setting up this account on behalf of a business or organization.
                  </div>
                }
                value="true"
                name={'Third Party'}
              />
            </CustomRadioGroup>
          </>
        )}
        <NextButton />
      </AvForm>
    </>
  )
}

export const UserComponent = ({
  onNext,
  activeClient,
  clientOnboarding,
  handleFieldChange,
  validate,
  setSteps,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const user: User = useSelector((state) => getEntities(state).account) || {}
  const clients: Client[] = useSelector(getClients) || []

  const handleNext = (event, values) => {
    onNext({
      e: event,
      userValues: values,
      clientValues: {
        countryCode: clientOnboarding.stashedClientDetails?.countryCode,
        hasThirdParty: clientOnboarding.stashedClientDetails?.hasThirdParty,
      },
    })
  }

  useEffect(() => {
    setSteps((prev) =>
      prev.map((step) => {
        let newStep = { ...step }
        if (step.name === 'user_info') {
          newStep.subSteps = dynamicUserSubSteps(user, clientOnboarding, clients)
        }
        return newStep
      }),
    )
  }, [user, clientOnboarding])

  return (
    <AvForm onValidSubmit={handleNext} model={user} ref={formRef}>
      <h3>Your information</h3>
      <AvField
        name="firstName"
        type="text"
        label="First Name"
        placeholder="Your first name"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        onChange={handleFieldChange}
      />
      <AvField
        name="lastName"
        type="text"
        label="Last Name"
        placeholder="Your last name"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        onChange={handleFieldChange}
      />
      <AvField
        name="mobilePhone"
        type="tel"
        label="Mobile Phone Number"
        placeholder="Ex: 1234567890"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          tel: validatePhone,
          async: validate,
        }}
        onChange={handleFieldChange}
      />
      <NextButton />
    </AvForm>
  )
}
