// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SettlementTransactions,
    SettlementTransactionsFromJSON,
    SettlementTransactionsToJSON,
    Settlements,
    SettlementsFromJSON,
    SettlementsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchSettlementTransactions200Response
 */
export interface SearchSettlementTransactions200Response  {
    /**
     * 
     * @type {Settlements}
     * @memberof SearchSettlementTransactions200Response
     */
    settlement?: Settlements;
    /**
     * 
     * @type {number}
     * @memberof SearchSettlementTransactions200Response
     */
    count?: number;
    /**
     * 
     * @type {Array<SettlementTransactions>}
     * @memberof SearchSettlementTransactions200Response
     */
    records?: Array<SettlementTransactions>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchSettlementTransactions200Response
     */
    unreportedSettlements?: boolean;
}

export function SearchSettlementTransactions200ResponseFromJSON(json: any): SearchSettlementTransactions200Response {
    return {
        'settlement': !exists(json, 'settlement') ? undefined : SettlementsFromJSON(json['settlement']),
        'count': !exists(json, 'count') ? undefined : json['count'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(SettlementTransactionsFromJSON),
        'unreportedSettlements': !exists(json, 'unreported_settlements') ? undefined : json['unreported_settlements'],
    };
}

export function SearchSettlementTransactions200ResponseToJSON(value?: SearchSettlementTransactions200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'settlement': SettlementsToJSON(value.settlement),
        'count': value.count,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(SettlementTransactionsToJSON),
        'unreported_settlements': value.unreportedSettlements,
    };
}


