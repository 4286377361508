import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useRequest } from 'redux-query-react'
import { updateEntities } from 'redux-query'

import { getActiveClient, getClients } from 'app/selectors'
import { ACTIVE_CLIENT_KEY, CLIENT_STATUS } from 'app/constants'
import { Client, searchClients } from 'api'
import { getClientRoutes } from 'features/clients/clientRoutes'
import { LeftSidebar } from 'features/menu/LeftSidebar'
import { Alert, Container, Fade } from 'reactstrap'
import { Loading } from 'components/Loading'
import { TopBar } from 'features/topbar/topBar'
import { NotificationsManager } from 'features/notifications/notificationsManager'

export const searchClientsConfig = (includedIds?: number[]) => {
  return searchClients(
    { includedIds: includedIds, includePartialClients: true },
    {
      force: true,
      transform: (response) => {
        return {
          clients: response.records,
          clientsCount: response.count,
        }
      },
      update: {
        clients: (_, newValue) => newValue,
        clientsCount: (_, newValue) => newValue,
      },
    },
  )
}

export const ClientManager = () => {
  const dispatch = useDispatch()
  const clients: Client[] = useSelector(getClients) || null
  const currentActiveClient = useSelector(getActiveClient)
  const sessionClientId = Number.parseInt(
    localStorage.getItem(ACTIVE_CLIENT_KEY) || sessionStorage.getItem(ACTIVE_CLIENT_KEY) || '',
  )
  const alphabeticalClientId = clients?.[0]?.id
  const activeClientId: number | undefined = sessionClientId || alphabeticalClientId
  const activeClient =
    currentActiveClient ||
    clients?.find((client) => client.id === activeClientId) ||
    clients?.filter((c) => c.status !== CLIENT_STATUS.closed)?.[0] ||
    clients?.[0]

  const [requestState, doRequest] = useRequest(searchClientsConfig([sessionClientId]))
  const routes = getClientRoutes(activeClient, clients !== null)

  useEffect(() => {
    if (!activeClient?.id) return
    localStorage.setItem(ACTIVE_CLIENT_KEY, activeClient.id.toString())
    dispatch(
      updateEntities({
        activeClient: (_) => activeClient,
      }),
    )
  }, [activeClient])

  return (
    <Switch>
      {routes.map((route, i) => {
        return (
          <Route
            key={i}
            exact={route.exact}
            path={route.path}
            render={({ match }) => {
              return route.hideMenu ? (
                <Fade>{route.component && <route.component {...match.params} />}</Fade>
              ) : (
                <>
                  {activeClient?.status && (
                    <TopBar disableMobileMenu={route.path.match('/client/signup') !== null && clients?.length === 1} />
                  )}
                  <LeftSidebar />
                  <NotificationsManager />
                  <div className="content-page">
                    <div className="content">
                      <Container fluid>
                        {requestState.isPending || !activeClient?.status ? (
                          <Loading />
                        ) : (
                          <Fade>
                            {activeClient?.status === 'Closed' && (
                              <Alert color="info" className="mt-2 mb-0">
                                Your account has been closed. If you have any questions, please contact&nbsp;
                                <a href="mailto:support@rotessa.com">support@rotessa.com</a>
                              </Alert>
                            )}
                            {route.component && <route.component {...match.params} />}
                          </Fade>
                        )}
                      </Container>
                    </div>
                  </div>
                </>
              )
            }}
          />
        )
      })}

      {routes.length > 0 && <Redirect to={routes[0].path} />}
    </Switch>
  )
}
