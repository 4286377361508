import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'

export interface RightDrawerProps {
  onClose: Function
  enabled?: boolean
  title?: JSX.Element | string
  body?: JSX.Element | string
}

export const RightDrawer = (props: RightDrawerProps) => {
  const [enabled, setEnabled] = useState(props.enabled)

  useEffect(() => {
    setEnabled(props.enabled)
  }, [props.enabled])

  return (
    <div>
      <div className={enabled ? 'right-bar-enabled' : ''}>
        <div className="rightbar-overlay" onClick={() => props.onClose()}></div>
        <div className="right-bar">
          <div className="rightbar-title">
            <Button onClick={() => props.onClose()} className="btn btn-rotessa-tertiary pl-0" aria-label="Hide Filters">
              <i className="mdi mdi-arrow-right" />
            </Button>
            {props.title}
          </div>
          <div className="rightbar-body">{props.body}</div>
        </div>
      </div>
    </div>
  )
}
