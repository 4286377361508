import {
  ConnectIntegrationCustomers200ResponseFromJSON,
  getIntegrationCustomers,
  IntegrationCustomer,
  unlinkIntegration,
  updateCustomersIntegrationSettings,
} from 'api'
import { getActiveClient, getEntities } from 'app/selectors'
import actionRequired from 'assets/images/customer_dashboard_icons/Action-required-icon-2.png'
import rotessaParterImg from 'assets/images/integrationsFlow/Become-a-partner.jpg'
import enableAutoImportInvoicesImg from 'assets/images/integrationsFlow/Enable-auto-import-invoices-on-integrations.jpg'
import ratingImg from 'assets/images/integrationsFlow/Rate-our-integration-five-star.jpg'
import xeroDisconnectBtn from 'assets/images/xero_disconnect_btn.svg'
import { CenterContent } from 'components/CenterContent'
import { FormServerNotifications } from 'components/FormServerNotifications'
import { integrationName } from 'components/Integrations'
import { LoadingMaskCentered } from 'components/LoadingMask'
import { ColumnProps, OnTableChangeProps, Table } from 'components/Table'
import { TableSearchField } from 'components/TableHeader'
import { TitleBar } from 'features/customers/customerFlow/titleBar'
import { useIsMobile } from 'hooks/useIsMobile'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Fade,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { updateEntities } from 'redux-query'
import { useMutation, useRequest } from 'redux-query-react'
import { IntegrationInfoPanel } from './integrationInfoPanel'

export const IntegrationView = ({ integration, doRequest, displayNotification }) => {
  const dispatch = useDispatch()
  const MAX_ROWS_BEFORE_SCROLLING = 10
  const history = useHistory()
  const isMobile = useIsMobile()
  const activeClient = useSelector(getActiveClient) || {}
  const integrationCustomers = useSelector((state) => getEntities(state).integrationCustomers) || {}
  const [serverErrors, setServerErrors] = useState([] as string[])
  const [search, setSearch] = useState('')
  const [confirmUnlink, setConfirmUnlink] = useState(false)
  const [responseStatus, setResponseStatus] = useState(0)
  const [customersForTable, setCustomersForTable] = useState([] as IntegrationCustomer[])
  const [cachedCustomersForTable, setCachedCustomersForTable] = useState([] as IntegrationCustomer[])
  const [customerConnectionTotal, setCustomerConnectionTotal] = useState(0)
  const [notifications, setNotifications] = useState<string[]>([])
  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 25,
    selectedRows: [],
    sortField: 'name',
    sortOrder: 'asc',
  })
  const [unlinkState, doUnlink] = useMutation(() => unlinkIntegration({ clientId: activeClient.id }))
  const [mutationState, doMutation] = useMutation(() =>
    updateCustomersIntegrationSettings({
      connectCustomers: {
        clientId: activeClient.id,
        customers: changedCustomers().map((r: any) => {
          return {
            id: r.id,
            autoImportInvoices: r.autoImportInvoices,
            autoUpdate: r.autoUpdate,
          }
        }),
      },
    }),
  )

  const [requestState, doIntegrationCustomersRequest] = useRequest(
    activeClient?.id &&
      getIntegrationCustomers(
        {
          clientId: activeClient.id,
          search: search,
          filter: 'connected',
        },
        {
          force: true,
          transform: (body) => {
            return { integrationCustomers: body }
          },
          update: { integrationCustomers: (_, newValue) => newValue },
        },
      ),
  )

  const selectedAccount =
    integration?.paymentAccounts?.find((a) => a.id?.toString() === integration?.selectedPaymentAccountId?.toString()) ||
    {}

  const panelContent = [
    {
      imgSrc: enableAutoImportInvoicesImg,
      text: 'Enable automatic invoice imports to automatically retrieve invoices for your customers.',
    },
    {
      imgSrc: rotessaParterImg,
      text:
        'Are you an accountant or bookkeeper who wants to share Rotessa with your clients? Become a Rotessa Partner!',
    },
    {
      imgSrc: ratingImg,
      text: `Loving the ${integrationName(
        activeClient?.integration,
      )} and Rotessa Integration? Leave a review in the ${integrationName(activeClient?.integration)} App store!`,
    },
  ]

  useEffect(() => {
    if (requestState.isFinished && integrationCustomers.customers) {
      setCustomersForTable(integrationCustomers.customers)
      setCachedCustomersForTable(
        integrationCustomers.customers.map((customer) => {
          return { ...customer }
        }),
      )
    }
  }, [integrationCustomers.customers])

  const changedCustomers = () => {
    return customersForTable.filter(
      (customer, index) => JSON.stringify(customer) !== JSON.stringify(cachedCustomersForTable[index]),
    )
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setTableProps({ ...tableProps, page: 1 })
  }

  const onTableChange = (_type: string, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) => {
    setTableProps({
      page: page || tableProps.page,
      pageSize: sizePerPage || tableProps.pageSize,
      selectedRows: [],
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  const handleSubmit = () => {
    doMutation()?.then((response) => {
      if (response?.status === 200) {
        setResponseStatus(response?.body?.status)
        setCustomerConnectionTotal(response?.body?.selected_count)
        if (response?.body?.status !== 200 && response?.body?.selected_count !== response?.body?.newly_imported_count) {
          var message =
            response?.body?.selected_count -
            response?.body?.newly_imported_count +
            ' selected customer(s) encountered errors while connecting.'
          setServerErrors([message])
        } else {
          setServerErrors([response?.body?.error_message] || [])
        }
        setNotifications(response?.body?.notifications)
        dispatch(
          updateEntities({
            integrationCustomers: (_) => ConnectIntegrationCustomers200ResponseFromJSON(response.body),
          }),
        )
      } else {
        setServerErrors(['An unknown error occured. If this continues please contact support.'])
      }
    })
  }

  const handleClickAcknowledge = () => {
    history.push(`/client/customers`)
  }

  const MobileCustomerDetailsFormatter = (_customerName, row) => {
    return (
      <>
        <Row noGutters className="clearfix">
          <Col xs="auto">{row['name']}</Col>
        </Row>
        <Row noGutters className="clearfix">
          <Col xs="auto">{<small className="text-medium">{row['email']}</small>}</Col>
        </Row>
        <Row noGutters className="mt-1 mb-1">
          <Col className="d-flex align-items-center word-wrap">
            <small>Automatic invoice import</small>
            <CustomInput
              type="switch"
              inline
              id={`autoImportInvoices_${row.id}`}
              className="ml-2"
              name={'autoImportInvoices'}
              checked={row.autoImportInvoices}
              onChange={(event) => {
                setCustomersForTable((prev) => {
                  let updatedRows = [...prev]
                  let customer = updatedRows.find((customer) => customer.id === row.id)
                  if (customer) {
                    customer.autoImportInvoices = event.target.checked
                  }
                  return updatedRows
                })
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex align-items-center word-wrap">
            <small>Automatic customer update</small>
            <CustomInput
              type="switch"
              inline
              id={`autoUpdate_${row.id}`}
              className="ml-2"
              name={'autoUpdate'}
              checked={row.autoUpdate}
              onChange={(event) => {
                setCustomersForTable((prev) => {
                  let updatedRows = [...prev]
                  let customer = updatedRows.find((customer) => customer.id == row.id)
                  if (customer) {
                    customer.autoUpdate = event.target.checked
                  }
                  return updatedRows
                })
              }}
            />
          </Col>
        </Row>
      </>
    )
  }

  const IntegrationCustomerDetailsFormatter = (cell, row) => {
    return (
      <p className="mb-0 align-middle" style={{ lineHeight: 1 }}>
        <span>{row.name}</span>
        <br />
        <small className="text-muted">{row.email}</small>
      </p>
    )
  }

  const AutoImportInvoicesFormatter = (cell, row) => {
    return (
      <div className="d-flex align-items-center float-right">
        <div>
          <CustomInput
            type="switch"
            id={`autoImportInvoices_${row.id}`}
            name={'autoImportInvoices'}
            checked={row.autoImportInvoices}
            onChange={(event) => {
              setCustomersForTable((prev) => {
                let updatedRows = [...prev]
                let customer = updatedRows.find((customer) => customer.id === row.id)
                if (customer) {
                  customer.autoImportInvoices = event.target.checked
                }
                return updatedRows
              })
            }}
          />
        </div>
      </div>
    )
  }

  const AutoUpdateFormatter = (cell, row) => {
    return (
      <div className="d-flex align-items-center float-right">
        <div>
          <CustomInput
            type="switch"
            id={`autoUpdate_${row.id}`}
            name={'autoUpdate'}
            checked={row.autoUpdate}
            onChange={(event) => {
              setCustomersForTable((prev) => {
                let updatedRows = [...prev]
                let customer = updatedRows.find((customer) => customer.id == row.id)
                if (customer) {
                  customer.autoUpdate = event.target.checked
                }
                return updatedRows
              })
            }}
          />
        </div>
      </div>
    )
  }

  const AutoImportInvoicesHeaderFormatter = (cell, row) => {
    return (
      <>
        <div className="text-right mb-1">Automatic invoice import</div>
        <div className="float-right">
          <CustomInput
            type="switch"
            id={`autoImportInvoices_${row.id}`}
            name={'autoImportInvoices'}
            checked={customersForTable.length > 0 && customersForTable.every((r) => r.autoImportInvoices)}
            disabled={customersForTable.length === 0}
            onChange={(event) => {
              setCustomersForTable((prev) => {
                let updatedRows = [...prev]
                updatedRows.map((customer, index) => {
                  customer.autoImportInvoices = event.target.checked
                })
                return updatedRows
              })
            }}
          />
        </div>
      </>
    )
  }

  const AutoUpdateHeaderFormatter = (cell, row) => {
    return (
      <>
        <div className="text-right mb-1">Automatic customer update</div>
        <div className="float-right">
          <CustomInput
            type="switch"
            id={`autoUpdate_${row.id}`}
            name={'autoUpdate'}
            checked={customersForTable.length > 0 && customersForTable.every((r) => r.autoUpdate)}
            disabled={customersForTable.length == 0}
            onChange={(event) => {
              setCustomersForTable((prev) => {
                let updatedRows = [...prev]
                updatedRows.map((customer, index) => {
                  customer.autoUpdate = event.target.checked
                })
                return updatedRows
              })
            }}
          />
        </div>
      </>
    )
  }

  const columns: ColumnProps[] = isMobile
    ? [
        {
          dataField: 'name',
          text: '',
          formatter: MobileCustomerDetailsFormatter,
          sort: false,
          classes: 'align-middle w-75',
          headerStyle: { borderStyle: 'none', width: '75%' },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              e.stopPropagation()
            },
          },
        },
      ]
    : [
        {
          dataField: 'name',
          text: 'Name',
          formatter: IntegrationCustomerDetailsFormatter,
          sort: false,
          classes: 'align-middle w-75',
          headerStyle: { borderStyle: 'none', width: '75%' },
        },
        {
          dataField: 'autoImportInvoices',
          text: '',
          formatter: AutoImportInvoicesFormatter,
          sort: false,
          classes: 'align-middle w-25 ',
          headerFormatter: AutoImportInvoicesHeaderFormatter,
          headerStyle: { borderStyle: 'none', width: '25%' },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              e.stopPropagation()
            },
          },
        },
        {
          dataField: 'autoUpdate',
          text: '',
          formatter: AutoUpdateFormatter,
          sort: false,
          classes: 'align-middle w-25 ',
          headerFormatter: AutoUpdateHeaderFormatter,
          headerStyle: { borderStyle: 'none', width: '25%' },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              e.stopPropagation()
            },
          },
        },
      ]

  const handleUnlink = () => {
    setConfirmUnlink(false)
    doUnlink()
      ?.then(() => doRequest())
      .then(() => {
        displayNotification(`Successfully unlinked from ${integration.name}`)
      })
  }

  const paymentAccountDropdown = (
    <FormGroup>
      <InputGroup className="justify-content-center">
        <Input readOnly className="mw-100" value={selectedAccount?.name || 'Not Selected'} />
        <InputGroupAddon addonType="append">
          <Button color="primary" onClick={() => history.push(`/client/integrations/set-clearing-account`)}>
            Change
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  )

  const disconnectButton = useMemo(() => {
    let imageSource
    if (integration?.integrationName === 'xero') imageSource = xeroDisconnectBtn

    return (
      <Link to="#" onClick={() => setConfirmUnlink(true)} className="text-muted">
        {imageSource && 
          <img
            src={xeroDisconnectBtn}
            height={35}
            alt={`Disconnect from ${integration?.name}`}
            className="mw-100"
          />}
        {!imageSource && 
          <>
            <i className="mdi mdi-close-octagon mr-1" />
            Disconnect from {integration?.name}
          </>}
      </Link>
    )
    }, [integration])

  return (
    <Fade>
      {unlinkState.isPending && <LoadingMaskCentered />}
      <Modal isOpen={confirmUnlink}>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>
          Are you sure you want to unlink {activeClient?.businessName} from {integration?.name}?
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={handleUnlink}>
            Yes
          </Button>
          <Button color="secondary" onClick={() => setConfirmUnlink(false)} className="ml-2">
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={responseStatus === 200}>
        <ModalHeader>{customerConnectionTotal} Customers Updated</ModalHeader>
        <ModalBody>
          <p>The selected customers have been connected successfully.</p>
          <FormServerNotifications notifications={notifications} />
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={handleClickAcknowledge}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <CenterContent>
        <TitleBar
          noBack
          title={<img src={integration?.icon} height={100} className="mx-auto d-block m-0 " alt="" />}
          subtitle={
            <>
              <Row className="justify-content-center no-wrap mt-0">
                <span className="btn-link" color="primary">
                  Your account is connected to <strong>{integration?.name}</strong>
                </span>
              </Row>
              <Row className="justify-content-center btn-link no-wrap">
                {integration?.organization && <span>Connected Organization: {integration.organization}</span>}
              </Row>
              <Row className="justify-content-center no-wrap mb-1">
                <i className="mdi mdi-sync mr-1" />
                {integration?.lastImportedInvoices || 'Customers and invoices not yet updated'}
              </Row>
            </>
          }
        />
        <Row className="justify-content-center mb-4 mt-4">
          <Col xs="12" md="6">
            <Link to="/client/customers/connect" className="text-muted">
              <Button block className="rounded-btn tall-btn" color="primary" style={{ fontSize: '19px' }}>
                <i className="mdi mdi-import mr-1" />
                Import Customers
              </Button>
            </Link>
          </Col>
          <Col xs="12" md="6" className={isMobile ? 'mt-3' : ''}>
            <Link to="/client/customers/import-invoices" className="text-muted">
              <Button block className="rounded-btn tall-btn" color="primary" style={{ fontSize: '19px' }}>
                <>
                  <i className="mdi mdi-import mr-1" />
                  Import Invoices
                </>
              </Button>
            </Link>
          </Col>
        </Row>
        <Row className="uppercase-thin text-muted justify-content-center">
          <Col className="text-center mx-auto">Payment Account</Col>
        </Row>
        <Row className="justify-content-center">
          <Col>{paymentAccountDropdown}</Col>
        </Row>
        {!selectedAccount?.name && (
          <Row className="justify-content-center">
            <Col className="text-center mx-auto text-warning">
              <img src={actionRequired} height={15} className="mr-1" style={{ marginBottom: '4px' }} alt="" /> No
              payment account selected. {isMobile && <br />}Payments will not be reported to{' '}
              {integrationName(activeClient?.integration)}.
            </Col>
          </Row>
        )}
        <Row className="uppercase-thin text-muted justify-content-center mt-3 mb-1">
          <Col>Customers Imported from {integrationName(activeClient?.integration)}</Col>
        </Row>
        <Card>
          <CardBody>
            <Row className="mb-2 justify-content-between">
              <Col md="6" xs="12" className="col-6">
                <TableSearchField onChange={handleSearchChange} className="w-100" />
              </Col>
              {integrationCustomers.customersSearchTotal >= 0 && (
                <Col xs="12" md="auto" className="d-flex align-items-center">
                  <span className="text-muted">
                    {integrationCustomers.customersSearchTotal}/{integrationCustomers.customersTotal} matches
                  </span>
                </Col>
              )}
              <Col xs="12" md="auto" className={`${isMobile ? 'mb-2 order-first' : 'text-right float-right'}`}>
                <Button
                  className="w-100"
                  disabled={changedCustomers().length === 0}
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
            <Table
              loading={requestState.isPending}
              data={customersForTable}
              columns={columns}
              sortField={tableProps.sortField}
              sortOrder={tableProps.sortOrder}
              onTableChange={onTableChange}
              scrollBody={customersForTable.length > MAX_ROWS_BEFORE_SCROLLING}
              scrollBodyHeight="500px"
              style={{ 'border-style': 'none' }}
            />
          </CardBody>
        </Card>
        {!isMobile && (
          <div className="mt-3">
            <IntegrationInfoPanel content={panelContent} />
          </div>
        )}
      </CenterContent>

      <Row className="justify-content-center mt-3 mb-3">
        {disconnectButton}
      </Row>
    </Fade>
  )
}
