import React, { useState } from 'react'
import { getActiveClient, getEntities } from 'app/selectors'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import { searchMicrodeposits, sendMicrodepositReminder } from 'api'
import {
  ActionFormatterFactory,
  ColumnProps,
  DateFormatter,
  OnTableChangeProps,
  Table,
  TableState,
} from 'components/Table'
import { Badge, Button, Card, CardBody, Col, Form, FormGroup, Input, Row, UncontrolledTooltip } from 'reactstrap'
import RotessaDatepicker from 'components/Datepicker'
import { daysToMillis, getSort, isPermitted } from 'utilities'

const MicrodepositStatusFormatter = (status, row, rowIndex) => {
  const id = row.id && `microdeposit-status-formatter-${row.id}`
  const statusColor = {
    Verified: 'success-lighten',
    Failed: 'danger-lighten',
    Declined: 'danger-lighten',
  }
  const statusIcon = {
    Verified: 'check-bold',
    Failed: 'alert-outline',
    Declined: 'window-minimize',
  }
  const extraStatus = status === 'Declined' ? ` - ${row.statusReason}` : ''
  return (
    <div key={rowIndex}>
      <Badge id={id} pill color={statusColor[status] || 'warning-lighten'} className="circle-badge">
        <h4 className="m-0">
          <i className={`mdi mdi-${statusIcon[status] || 'timer-sand'}`} />
        </h4>
      </Badge>
      {id && (
        <UncontrolledTooltip target={id} placement="bottom">
          {status}
          {extraStatus}
        </UncontrolledTooltip>
      )}
    </div>
  )
}

const defaultTableState: TableState = {
  page: 1,
  pageSize: 5,
  sortField: 'createdAt',
  sortOrder: 'desc',
}

const filters = ['All', 'Requested', 'Verified', 'Declined', 'Failed']

interface MicrodepositSearchParams {
  filter: string
  startDate: Date
  endDate: Date
}

export const MicrodepositsTable = () => {
  const today = new Date()
  const defaultSearchParams: MicrodepositSearchParams = {
    filter: filters[0],
    startDate: new Date(today.valueOf() - daysToMillis(30)),
    endDate: today,
  }
  const [searchParams, setSearchParams] = useState(defaultSearchParams)
  const [tableState, setTableState] = useState(defaultTableState)
  const activeClient = useSelector(getActiveClient)
  const microdeposits = useSelector((state) => getEntities(state).microdeposits) || []
  const microdepositsCount = useSelector((state) => getEntities(state).microdepositsCount)
  const [selectedStartDate, setSelectedStartDate] = useState(defaultSearchParams.startDate.toString())
  const [selectedEndDate, setSelectedEndDate] = useState(defaultSearchParams.endDate.toString())
  const [selectedFilter, setSelectedFilter] = useState(filters[0])

  const [requestState, doRequest] = useRequest(
    activeClient?.id &&
      searchMicrodeposits(
        {
          clientId: activeClient.id,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          filter: searchParams.filter,
          skip: (tableState.page - 1) * tableState.pageSize,
          limit: tableState.pageSize,
          sort: getSort(tableState.sortField, tableState.sortOrder),
        },
        {
          force: true,
          transform: (body) => {
            return {
              microdeposits: body?.records,
              microdepositsCount: body?.count,
            }
          },
          update: {
            microdeposits: (_, newValue) => newValue,
            microdepositsCount: (_, newValue) => newValue,
          },
        },
      ),
  )

  const [mutationState, doMutation] = useMutation((microdepositId: number) =>
    sendMicrodepositReminder({ id: microdepositId }),
  )

  function onTableChange(_, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) {
    setTableState({
      page: page || tableState.page,
      pageSize: sizePerPage || tableState.pageSize,
      sortField: sortField || tableState.sortField,
      sortOrder: sortOrder || tableState.sortOrder,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSearchParams({
      filter: selectedFilter,
      startDate: new Date(selectedStartDate),
      endDate: new Date(selectedEndDate),
    })
  }

  const handleSendReminder = (microdeposit) => {
    if (microdeposit.id) {
      doMutation(microdeposit.id)
    }
  }

  const columns: ColumnProps[] = [
    {
      dataField: 'customer.name',
      text: 'Customer',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: MicrodepositStatusFormatter,
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'attempts',
      text: 'Attempts',
      sort: true,
    },
    {
      dataField: 'createdAt',
      formatter: DateFormatter,
      text: 'Created Date',
      sort: true,
    },
    {
      dataField: '',
      text: '',
      formatter: ActionFormatterFactory([
        {
          name: 'Verification Link',
          onClick: (row) => {
            const win = window.open(
              `${window.location.protocol}//${window.location.host}/microdeposit_verifications?verification_form_url=${row.formUrl}`,
              '_blank',
            )
            win?.focus()
          },
          disabled: (row) => row.status !== 'Requested',
        },
        {
          name: 'Send Reminder',
          onClick: (row) => handleSendReminder(row),
          disabled: (row) => row.status !== 'Requested',
          hidden: (_row) => !isPermitted('microdeposits.reminder', activeClient),
        },
      ]),
      sort: false,
      align: 'right',
      classes: 'sticky-right',
    },
  ]

  const isPending = requestState.isPending || mutationState.isPending

  return (
    <div>
      <div className="page-title-box">
        <h4 className="page-title">Microdeposits</h4>
      </div>
      <Card>
        <CardBody>
          <Row>
            <Col xs="auto">
              <Form inline disabled={isPending} onSubmit={handleSubmit} className="inline-form-top-aligned">
                <RotessaDatepicker
                  onChange={setSelectedStartDate}
                  hideAddon={false}
                  startDate={selectedStartDate}
                  addonPrepended={true}
                  addOnContent="Start Date"
                  className="mr-1 mb-2"
                />
                <RotessaDatepicker
                  onChange={setSelectedEndDate}
                  hideAddon={false}
                  startDate={selectedEndDate}
                  addonPrepended={true}
                  addOnContent="End Date"
                  className="mr-1 mb-2"
                />
                <FormGroup className="mb-2 mr-1">
                  <Input type="select" onChange={(e) => setSelectedFilter(e.target.value)} value={selectedFilter}>
                    {filters.map((f) => (
                      <option key={f} value={f}>
                        {f}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <Button color="primary" className="mb-2 mr-1">
                  Search
                </Button>
              </Form>
            </Col>
          </Row>
          <Table
            data={microdeposits}
            loading={isPending}
            columns={columns}
            paginationProps={{
              page: tableState.page,
              sizePerPage: tableState.pageSize,
              totalSize: microdepositsCount,
            }}
            sortField={tableState.sortField}
            sortOrder={tableState.sortOrder}
            onTableChange={onTableChange}
            scrollBody
          />
        </CardBody>
      </Card>
    </div>
  )
}
