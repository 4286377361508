import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateEntities } from 'redux-query'

export const useClearEntitiesOnUnmount = (entityNames: string[]) => {
  const dispatch = useDispatch()
  let clearEntities = {}
  entityNames.forEach((name) => (clearEntities[name] = (_) => undefined))
  useEffect(
    () => () => {
      dispatch(updateEntities(clearEntities))
    },
    [],
  )
}
