// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateAccount200Response
 */
export interface UpdateAccount200Response  {
    /**
     * 
     * @type {number}
     * @memberof UpdateAccount200Response
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccount200Response
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAccount200Response
     */
    messages?: Array<string>;
    /**
     * 
     * @type {User}
     * @memberof UpdateAccount200Response
     */
    user?: User;
}

export function UpdateAccount200ResponseFromJSON(json: any): UpdateAccount200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function UpdateAccount200ResponseToJSON(value?: UpdateAccount200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'id': value.id,
        'messages': value.messages,
        'user': UserToJSON(value.user),
    };
}


