// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface IntegrationCustomer
 */
export interface IntegrationCustomer  {
    /**
     * 
     * @type {number}
     * @memberof IntegrationCustomer
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCustomer
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCustomer
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCustomer
     */
    phone?: string;
    /**
     * 
     * @type {Address}
     * @memberof IntegrationCustomer
     */
    address?: Address;
    /**
     * 
     * @type {number}
     * @memberof IntegrationCustomer
     */
    rotessaId?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCustomer
     */
    rotessaName?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCustomer
     */
    rotessaEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCustomer
     */
    connectionStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationCustomer
     */
    autoImportInvoices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationCustomer
     */
    autoUpdate?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationCustomer
     */
    errors?: Array<string>;
}

export function IntegrationCustomerFromJSON(json: any): IntegrationCustomer {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'rotessaId': !exists(json, 'rotessa_id') ? undefined : json['rotessa_id'],
        'rotessaName': !exists(json, 'rotessa_name') ? undefined : json['rotessa_name'],
        'rotessaEmail': !exists(json, 'rotessa_email') ? undefined : json['rotessa_email'],
        'connectionStatus': !exists(json, 'connection_status') ? undefined : json['connection_status'],
        'autoImportInvoices': !exists(json, 'auto_import_invoices') ? undefined : json['auto_import_invoices'],
        'autoUpdate': !exists(json, 'auto_update') ? undefined : json['auto_update'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    };
}

export function IntegrationCustomerToJSON(value?: IntegrationCustomer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'address': AddressToJSON(value.address),
        'rotessa_id': value.rotessaId,
        'rotessa_name': value.rotessaName,
        'rotessa_email': value.rotessaEmail,
        'connection_status': value.connectionStatus,
        'auto_import_invoices': value.autoImportInvoices,
        'auto_update': value.autoUpdate,
        'errors': value.errors,
    };
}


