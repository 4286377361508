import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import qboImage from 'assets/images/quickbooks_icon.png'
import xeroImage from 'assets/images/xero_icon.png'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { Client, searchClients, User } from 'api'
import { getActiveClient, getClients, getEntities, justReplace, getActiveUser } from 'app/selectors'
import { updateEntities } from 'redux-query'
import { ACTIVE_PROCESSING_CLIENT_ONBOARDING_KEY, CLIENT_STATUS, SELECTABLE_CLIENT_TYPES } from 'app/constants'
import { clientDisplayName } from 'utilities'
import { useRequest } from 'redux-query-react'
import { integrationName } from 'components/Integrations'
import { RotessaPopover } from 'components/RotessaPopover'

export const ClientDropdown = () => {
  const newClient: Client = { id: 0, status: CLIENT_STATUS.created }
  const clients: Client[] = useSelector(getClients) || []
  const activeUser: User = useSelector(getActiveUser)
  const activeClient = useSelector(getActiveClient)
  const dispatch = useDispatch()
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [filter, setFilter] = useState('')
  const [timeoutFilter, setTimeoutFilter] = useState('')
  const filteredClients = useSelector((state) => getEntities(state).filteredClients)
  const showClients =
    filteredClients ||
    (filter === ''
      ? clients
      : clients.filter(
          (client) =>
            client.id === parseInt(filter, 10) ||
            clientDisplayName(activeUser, client)?.toLowerCase()?.startsWith(filter),
        ))
  const [timeoutId, setTimeoutId] = useState(0)

  const [requestState, doRequest] = useRequest(
    !activeUser?.rotessaStaff || timeoutFilter.length === 0
      ? null
      : searchClients(
          {
            startsWith: filter,
            filterId: parseInt(filter, 10),
            includePartialClients: true,
          },
          {
            force: true,
            transform: (body) => ({ filteredClients: body.records }),
            update: { filteredClients: justReplace },
          },
        ),
  )

  const clearFilteredClients = () => {
    dispatch(updateEntities({ filteredClients: (_) => undefined }))
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
    clearFilteredClients()
    if (isOpen) {
      setFilter('')
    }
  }
  const handleClickNew = () => {
    dispatch(
      updateEntities({
        activeClient: (_) => newClient,
        clients: (clients: Client[]) => clients.concat(newClient),
        clientOnboarding: () => ({
          state: 'who_are_you',
          statuses: {},
          stashedClientDetails: {},
        }),
      }),
    )

    sessionStorage.removeItem(ACTIVE_PROCESSING_CLIENT_ONBOARDING_KEY)
    history.push('/client/new_business')
  }

  const handleClickClient = (client) => {
    if (document.body && document.body.classList.contains('sidebar-enable')) {
      document.body.classList.remove('sidebar-enable')
    }
    dispatch(
      updateEntities({
        customers: (_) => undefined,
        homeData: (_) => undefined,
        activeClient: (_) => client,
      }),
    )
    client.processingClientOnboardingId
      ? sessionStorage.setItem(ACTIVE_PROCESSING_CLIENT_ONBOARDING_KEY, client.processingClientOnboardingId)
      : sessionStorage.removeItem(ACTIVE_PROCESSING_CLIENT_ONBOARDING_KEY)
    history.push('/')
  }

  const adminClientSearch = (event) => {
    if (activeUser?.rotessaStaff) {
      setFilter(event.target.value)
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      setTimeoutId(window.setTimeout(() => setTimeoutFilter(event.target.value), 300))
    }
  }

  if (
    clients.length === 0 ||
    (clients.length === 1 && [CLIENT_STATUS.created, CLIENT_STATUS.pending].indexOf(clients[0]?.status || '') !== -1)
  ) {
    return null
  }

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggleOpen} className="mt-2 mb-2 client-dropdown">
      <DropdownToggle caret>
        <RotessaPopover
          popoverContent={
            activeClient?.integration
              ? `${clientDisplayName(activeUser, activeClient)} is connected to ${integrationName(
                  activeClient?.integration,
                )}`
              : clientDisplayName(activeUser, activeClient)
          }
          placement="right"
          mobilePlacement="bottom"
          className="ellipsis"
        >
          {activeClient?.integration === 'xero' && <img src={xeroImage} className="client-integration-logo" alt="" />}
          {activeClient?.integration === 'quickbooks' && (
            <img src={qboImage} className="client-integration-logo" alt="" />
          )}
          {clientDisplayName(activeUser, activeClient) || (activeClient?.id === 0 && 'New Business')}
        </RotessaPopover>
      </DropdownToggle>
      <DropdownMenu className="client-dropdown-menu">
        {activeUser?.rotessaStaff && (
          <AvForm>
            <AvField
              type="string"
              value={filter}
              name="clientSearch"
              placeholder="Search client name or id"
              onChange={adminClientSearch}
              autoFocus
            />
          </AvForm>
        )}
        {showClients
          .sort((a, b) =>
            clientDisplayName(activeUser, a).toLowerCase() > clientDisplayName(activeUser, b).toLowerCase() ? 1 : -1,
          )
          .filter(
            (c) =>
              c.id !== activeClient?.id &&
              (SELECTABLE_CLIENT_TYPES.includes(c.clientType) ||
                c.processingClientOnboardingId ||
                c.hasProcessingDetails),
          )
          .map((client: Client, i) => {
            const tooltipContent = client.integration
              ? `${clientDisplayName(activeUser, client)} is connected to ${integrationName(client.integration)}`
              : clientDisplayName(activeUser, client)
            return (
              <RotessaPopover popoverContent={tooltipContent} placement="right" mobilePlacement="bottom">
                <DropdownItem
                  key={i}
                  onClick={() => handleClickClient(client)}
                  className={`ellipsis client-dropdown-status-${client?.status}`}
                >
                  {client.integration === 'xero' && <img src={xeroImage} className="client-integration-logo" alt="" />}
                  {client.integration === 'quickbooks' && (
                    <img src={qboImage} className="client-integration-logo" alt="" />
                  )}
                  {clientDisplayName(activeUser, client)}
                </DropdownItem>
              </RotessaPopover>
            )
          })}
        <DropdownItem divider />
        <DropdownItem onClick={handleClickNew}>
          <i className="mdi mdi-plus-circle mr-2"></i>New
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  )
}
