// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthorizationEmailSetting,
    AuthorizationEmailSettingFromJSON,
    AuthorizationEmailSettingToJSON,
    Client,
    ClientFromJSON,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetEmailAuthorizationDetails200Response
 */
export interface GetEmailAuthorizationDetails200Response  {
    /**
     * 
     * @type {Client}
     * @memberof GetEmailAuthorizationDetails200Response
     */
    client?: Client;
    /**
     * 
     * @type {AuthorizationEmailSetting}
     * @memberof GetEmailAuthorizationDetails200Response
     */
    settings?: AuthorizationEmailSetting;
}

export function GetEmailAuthorizationDetails200ResponseFromJSON(json: any): GetEmailAuthorizationDetails200Response {
    return {
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'settings': !exists(json, 'settings') ? undefined : AuthorizationEmailSettingFromJSON(json['settings']),
    };
}

export function GetEmailAuthorizationDetails200ResponseToJSON(value?: GetEmailAuthorizationDetails200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client': ClientToJSON(value.client),
        'settings': AuthorizationEmailSettingToJSON(value.settings),
    };
}


