import React, { useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { useTranslation } from 'react-i18next'

export interface AcceptTermsCheckboxProps {
  businessName: string
  terms: string
  serverErrors?: Errors
}

export const ProcessingAcceptTermsCheckbox = ({ businessName, terms }: AcceptTermsCheckboxProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const { t } = useTranslation()

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {businessName} {t('authorization.processing.agree.terms_and_conditions')}
        </ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: terms }} />
          <div className="text-center">
            <Button className="btn-rotessa-secondary" onClick={() => setIsOpen(false)}>
              {t('close')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <AvField
        name="agree"
        label={
          <>
            {t('authorization.processing.agree.terms_i_agree')}&nbsp;
            <Link to={(location) => ({ ...location, hash: '#terms' })} onClick={() => setIsOpen(true)}>
              <u>
                {t('authorization.processing.agree.terms_and_conditions_for')} {businessName}
              </u>
            </Link>
          </>
        }
        type="checkbox"
        required
        validate={{
          required: {
            value: true,
            errorMessage: t('authorization.processing.agree.terms_error'),
          },
          async: validate,
        }}
        onChange={handleFieldChange}
      />
    </>
  )
}
