// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CsvTransactionImport
 */
export interface CsvTransactionImport  {
    /**
     * 
     * @type {number}
     * @memberof CsvTransactionImport
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CsvTransactionImport
     */
    customIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof CsvTransactionImport
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CsvTransactionImport
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvTransactionImport
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvTransactionImport
     */
    frequency?: string;
    /**
     * 
     * @type {number}
     * @memberof CsvTransactionImport
     */
    installments?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CsvTransactionImport
     */
    valid?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CsvTransactionImport
     */
    errors?: Array<string>;
}

export function CsvTransactionImportFromJSON(json: any): CsvTransactionImport {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customIdentifier': !exists(json, 'custom_identifier') ? undefined : json['custom_identifier'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'installments': !exists(json, 'installments') ? undefined : json['installments'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    };
}

export function CsvTransactionImportToJSON(value?: CsvTransactionImport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'custom_identifier': value.customIdentifier,
        'amount': value.amount,
        'date': value.date,
        'comment': value.comment,
        'frequency': value.frequency,
        'installments': value.installments,
        'valid': value.valid,
        'errors': value.errors,
    };
}


