// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount  {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    transitNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    institutionNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    routingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    bankAccountType?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    verified?: string;
}

export function BankAccountFromJSON(json: any): BankAccount {
    return {
        'transitNumber': !exists(json, 'transit_number') ? undefined : json['transit_number'],
        'institutionNumber': !exists(json, 'institution_number') ? undefined : json['institution_number'],
        'routingNumber': !exists(json, 'routing_number') ? undefined : json['routing_number'],
        'accountNumber': !exists(json, 'account_number') ? undefined : json['account_number'],
        'bankAccountType': !exists(json, 'bank_account_type') ? undefined : json['bank_account_type'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
    };
}

export function BankAccountToJSON(value?: BankAccount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'transit_number': value.transitNumber,
        'institution_number': value.institutionNumber,
        'routing_number': value.routingNumber,
        'account_number': value.accountNumber,
        'bank_account_type': value.bankAccountType,
        'verified': value.verified,
    };
}


