import React, { useState } from 'react'

import { CenterContentNarrow } from 'components/CenterContent'
import { Card, CardBody, Form, Fade } from 'reactstrap'
import { Table } from 'components/Table'
import { TableSearchField } from 'components/TableHeader'
import { searchCustomers } from 'api'
import { getActiveClient, getCustomers, getEntities } from 'app/selectors'
import { useSelector } from 'react-redux'
import { useRequest } from 'redux-query-react'
import { getSort } from 'utilities'
import { ColumnProps, DateFormatter } from 'components/Table'
import { useHistory } from 'react-router-dom'
import { TitleBar } from '../customerFlow/titleBar'
import { CustomerIcons } from '../customerIcons'

export const ManualTransactionSchedule = () => {
  const [search, setSearch] = useState('')
  const activeClient = useSelector(getActiveClient) || {}
  const customers = useSelector(getCustomers) || []
  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 50,
    sortField: 'createdAt',
    sortOrder: 'desc',
  })
  const history = useHistory()

  const [requestState, doRequest] = useRequest(
    !activeClient?.id
      ? null
      : searchCustomers(
          {
            clientId: activeClient.id,
            skip: (tableProps.page - 1) * tableProps.pageSize,
            limit: tableProps.pageSize,
            filters: { active: true },
            search: search,
            sort: getSort('createdAt', 'desc'),
          },
          {
            force: true,
            transform: (response) => {
              return {
                customers: response.records,
                customersCount: response.count,
              }
            },
            update: {
              customers: (_, newValue) => newValue,
              customersCount: (_, newValue) => newValue,
            },
          },
        ),
  )

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setTableProps({ ...tableProps, page: 1 })
  }

  const onRowClick = (e, row, rowIndex) => {
    if (row.statuses['complete']) {
      history.push(`/client/customers/${row.id}/new_transaction_schedule`)
    }
  }

  const CustomerNameFormatter = (name, row) => {
    const disabled = !row.statuses['complete']

    return <span className={disabled ? 'text-muted' : ''}>{name}</span>
  }

  const CustomerStatusesFormatter = (statuses, row) => {
    return <CustomerIcons statuses={statuses} customer={row} className="table-icon" />
  }

  const columns: ColumnProps[] = [
    {
      dataField: 'name',
      formatter: CustomerNameFormatter,
      text: 'Name',
      sort: false,
      headerAttrs: {
        hidden: true,
      },
    },
    {
      dataField: 'statuses',
      formatter: CustomerStatusesFormatter,
      text: '',
      sort: false,
      classes: 'td-badge text-right',
      headerAttrs: {
        hidden: true,
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created Date',
      formatter: DateFormatter,
      sort: true,
      hidden: true,
      headerAttrs: {
        hidden: true,
      },
    },
  ]

  return (
    <Fade>
      <TitleBar
        title="Select a Customer"
        subtitle={
          <p style={{ textAlign: 'center' }}>
            Search for a customer to create a new transaction. The customer must be marked <strong>Complete</strong> to
            create a transaction.
          </p>
        }
      />
      <CenterContentNarrow>
        <Card>
          <CardBody>
            <Form className="mt-3 mb-4">
              <TableSearchField onChange={handleSearchChange} className="w-100 mb-1" />
              {customers.length >= 50 && <span className="text-secondary">Showing first 50 matches</span>}
            </Form>
            <Table
              loading={requestState.isPending}
              data={customers}
              columns={columns}
              sortField={tableProps.sortField}
              sortOrder={tableProps.sortOrder}
              onTableChange={() => null}
              scrollBody
              scrollBodyHeight="calc(100vh - 450px)"
              onRowClick={onRowClick}
              rowClasses={(row, rowIndex) => {
                return row.statuses['complete'] ? 'clickable-row' : ''
              }}
            />
          </CardBody>
        </Card>
      </CenterContentNarrow>
    </Fade>
  )
}
