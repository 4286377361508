import React, { useState } from 'react'
import { Card, CardBody, Carousel, CarouselIndicators, CarouselItem } from 'reactstrap'
import leftArrow from 'assets/images/custom_carousel/Arrow-left.png'
import rightArrow from 'assets/images/custom_carousel/Arrow-right.png'

export interface CustomCarouselProps {
  items: { caption: string; content: JSX.Element }[]
  interval?: number | boolean
  enableTouch?: boolean
}
export const CustomCarousel = ({ items, interval = false, enableTouch = true }: CustomCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handlePrevious = () => {
    if (activeIndex > 0) {
      setActiveIndex((prev) => prev - 1)
    }
  }

  const handleNext = () => {
    if (activeIndex < items.length - 1) {
      setActiveIndex((prev) => prev + 1)
    }
  }

  return (
    <Card>
      <CardBody>
        <Carousel previous={handlePrevious} next={handleNext} interval={interval} enableTouch={enableTouch}>
          {activeIndex > 0 && (
            <div className="carousel-control-arrow-left">
              <button className="btn-rotessa-tertiary" onClick={handlePrevious}>
                <img src={leftArrow} alt="Previous" />
              </button>
            </div>
          )}
          {items.map((item, i) => {
            let className = i === activeIndex ? 'active' : ''
            return <CarouselItem className={className}>{item.content}</CarouselItem>
          })}
          {activeIndex < items.length - 1 && (
            <div className="carousel-control-arrow-right">
              <button className="btn-rotessa-tertiary" onClick={handleNext}>
                <img src={rightArrow} alt="Next" />
              </button>
            </div>
          )}
          <div>
            <CarouselIndicators
              activeIndex={activeIndex}
              items={items.map((item, i) => ({ caption: item.caption, key: i.toString() }))}
              onClickHandler={setActiveIndex}
            ></CarouselIndicators>
          </div>
        </Carousel>
      </CardBody>
    </Card>
  )
}
