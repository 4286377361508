import React, { useRef, useState } from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
} from 'reactstrap'
import {
  ColumnProps,
  CurrencyFormatter,
  DateFormatter,
  OnTableChangeProps,
  Table,
  TransactionStatusFormatter,
} from 'components/Table'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getActiveClient, getEntities } from 'app/selectors'
import { useRequest } from 'redux-query-react'
import { Loading } from 'components/Loading'
import { CSVLink } from 'react-csv'
import { exportTransactionReportPdf, getBillingInvoiceById, getTransactionReportByDeclineDate } from 'api'
import { TRANSACTION_STATUS_CLASSES } from 'app/constants'
import { formatCurrency, formatDateGreg } from 'utilities'
import { useIsMobile } from 'hooks/useIsMobile'
import { ExportButton, ExportButtonCSVLink } from 'components/ExportButton'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { LoadingMask } from 'components/LoadingMask'
import {
  calculateTransactionReportTotals,
  CustomerLinkFormatter,
  TransactionTotalBadge,
} from './TransactionReportTable'

export const MobileTransactionReportFormatter = (_declineDate, row) => {
  return (
    <>
      <Row noGutters className="clearfix">
        <Col xs="auto">{row.customer?.name}</Col>
        <Col className={`text-right text-${TRANSACTION_STATUS_CLASSES[row.status]}`}>{formatCurrency(row.amount)}</Col>
      </Row>
      <Row>
        <Col>
          <small className="text-medium">{formatDateGreg(row.declineDate)}</small>
        </Col>
        <Col className="text-right">
          <small className="text-medium">{row.statusReason}</small>
        </Col>
      </Row>
    </>
  )
}

export const getData = (records: any[], tableProps: any) => {
  let sortVal = (obj) => obj[tableProps.sortField]
  let sortVal2 = (obj) => new Date(obj['declineDate'])
  switch (tableProps.sortField) {
    case 'customer':
      sortVal = (obj) => obj['customer']['name'].toLowerCase()
      break
    case 'amount':
      sortVal = (obj) => Number.parseFloat(obj['amount'])
      break
    case 'comment':
      sortVal = (obj) => obj['comment'].toLowerCase()
      break
    case 'declineDate':
      sortVal = (obj) => new Date(obj['declineDate']).getTime()
      sortVal2 = (obj) => obj['customer']['name'].toLowerCase()
  }
  const result = records.sort((a, b) => {
    let useSecond = false
    let valA = sortVal(a)
    let valB = sortVal(b)
    if (valA === valB) {
      useSecond = true
      valA = sortVal2(a)
      valB = sortVal2(b)
    }
    return useSecond || tableProps.sortOrder === 'asc' ? (valA < valB ? -1 : 1) : valA > valB ? -1 : 1
  })

  return result.map((v, i) => {
    return { ...v, _localid: i }
  })
}

export interface DeclinedTransactionReportProps {
  invoiceId: number
}

export const DeclinedTransactionReport = ({ invoiceId }: DeclinedTransactionReportProps): JSX.Element => {
  const isMobile = useIsMobile()
  const [tableLoaded, setTableLoaded] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const csvExportLink = useRef<CSVLink>()

  const activeClient = useSelector(getActiveClient)
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const [filter, setFilter] = useState(query.get('filter') || 'All')

  const transactionReportRecords = useSelector((state) => getEntities(state).transactionReportRecords) || []
  const transactionReportTotals = useSelector((state) => getEntities(state).transactionReportTotals) || {}
  const startDate = useSelector((state) => getEntities(state).invoice?.billingInvoice.startDate)
  const endDate = useSelector((state) => getEntities(state).invoice?.billingInvoice.endDate)

  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 5,
    sortField: 'declineDate',
    sortOrder: 'desc',
  })

  useClearEntitiesOnUnmount(['transactionReportRecords', 'transactionReportTotals', 'invoice'])

  const [billingInvoiceRequestState, _doBillingInvoiceRequest] = useRequest(
    activeClient?.id &&
      getBillingInvoiceById(
        {
          clientId: activeClient.id,
          id: invoiceId,
        },
        {
          force: true,
          transform: (response) => {
            return { invoice: response }
          },
          update: { invoice: (_, newValue) => newValue },
        },
      ),
  )

  const [transactionReportRequestState, _doTransactionReportRequest] = useRequest(
    activeClient?.id &&
      !!startDate &&
      !!endDate &&
      getTransactionReportByDeclineDate(
        {
          clientId: activeClient.id,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          filter: filter,
        },
        {
          force: true,
          transform: (body) => {
            return {
              transactionReportRecords: body?.records,
              transactionReportTotals: calculateTransactionReportTotals(body?.records || []),
            }
          },
          update: {
            transactionReportRecords: (_, newValue) => newValue,
            transactionReportTotals: (_, newValue) => newValue,
          },
        },
      ),
  )

  if (!tableLoaded && transactionReportRequestState.isFinished) {
    setTableLoaded(true)
  }

  const transactionReportColumns: ColumnProps[] = isMobile
    ? [
        {
          dataField: 'declineDate',
          text: 'Decline Date',
          sort: true,
          formatter: MobileTransactionReportFormatter,
        },
      ]
    : [
        {
          dataField: 'declineDate',
          formatter: DateFormatter,
          text: 'Decline Date',
          sort: true,
          classes: 'ellipsis',
        },
        {
          dataField: 'processDate',
          formatter: DateFormatter,
          text: 'Process Date',
          sort: true,
          classes: 'ellipsis',
        },
        {
          dataField: 'amount',
          formatter: CurrencyFormatter,
          text: 'Amount',
          sort: true,
        },
        {
          dataField: 'customer',
          formatter: CustomerLinkFormatter,
          text: 'Customer',
          sort: true,
          classes: 'ellipsis',
        },
        {
          dataField: 'status',
          formatter: TransactionStatusFormatter,
          text: 'Status',
          sort: true,
          classes: 'td-badge',
        },
        {
          dataField: 'comment',
          text: 'Comment',
          sort: true,
          classes: 'ellipsis',
        },
      ]

  function onTableChange(_type, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) {
    setTableProps({
      page: page || tableProps.page,
      pageSize: sizePerPage || tableProps.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  const onRowClick = (_e, row, _rowIndex) => {
    history.push(`/client/customers/${row.customer.id}`)
  }

  const csvHeader = [
    { label: 'Decline Date', key: 'declineDate' },
    { label: 'Process Date', key: 'processDate' },
    { label: 'Amount', key: 'amount' },
    { label: 'Customer', key: 'customer.name' },
    { label: 'Customer Custom Identifier', key: 'customer.customIdentifier' },
    { label: 'Status', key: 'status' },
    { label: 'Status Reason', key: 'statusReason' },
    { label: 'Settled Date', key: 'settledDate' },
    { label: 'Comment', key: 'comment' },
  ]
  const showLoadingMask = !tableLoaded || transactionReportRequestState.isPending
  return billingInvoiceRequestState.isFinished ? (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Declined Transactions</h4>
            <p>
              Declined transactions for the billing period from {formatDateGreg(startDate)} to {formatDateGreg(endDate)}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            {loadingExport && <LoadingMask />}
            <CardBody>
              {!showLoadingMask && (
                <>
                  {!!transactionReportTotals?.Chargeback?.count && (
                    <TransactionTotalBadge
                      sum={transactionReportTotals?.Chargeback?.sum}
                      count={transactionReportTotals?.Chargeback?.count}
                      status={'Chargeback'}
                    />
                  )}
                  {!!transactionReportTotals?.Declined?.count && (
                    <TransactionTotalBadge
                      sum={transactionReportTotals?.Declined?.sum}
                      count={transactionReportTotals?.Declined?.count}
                      status={'Declined'}
                    />
                  )}
                </>
              )}
              <Input
                style={{
                  width: 'fit-content',
                  float: 'right',
                  marginBottom: '10px',
                }}
                name="status"
                type="select"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                {['All', 'Declined', 'Chargeback'].map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Input>
              {transactionReportRequestState.isPending ? (
                <Loading />
              ) : (
                <>
                  <Table
                    loading={false}
                    data={getData(transactionReportRecords, tableProps)}
                    keyField="_localid"
                    columns={transactionReportColumns}
                    sortField={tableProps.sortField}
                    sortOrder={tableProps.sortOrder}
                    onTableChange={onTableChange}
                    onRowClick={onRowClick}
                    scrollBody
                    scrollBodyHeight="calc(100vh - 370px)"
                  />
                  {tableLoaded && (
                    <Row>
                      <Col className="text-center">
                        <UncontrolledButtonDropdown className="ml-1 mb-2 centered-dropdown" direction="down">
                          <DropdownToggle caret color="tertiary" className="btn-rotessa-tertiary">
                            <i className="mdi mdi-download-outline mr-1" />
                            Export transaction report
                          </DropdownToggle>
                          <DropdownMenu className="narrow">
                            <DropdownItem>
                              <ExportButton
                                noIcon
                                exportName={`transactions`}
                                exportQueryConfig={exportTransactionReportPdf(
                                  {
                                    clientId: activeClient.id,
                                    startDate: new Date(startDate),
                                    endDate: new Date(endDate),
                                    filter: filter,
                                    reportType: 'declined',
                                  },
                                  {
                                    force: true,
                                  },
                                )}
                                setLoadingHandler={setLoadingExport}
                              >
                                PDF
                              </ExportButton>
                            </DropdownItem>
                            <DropdownItem>
                              <ExportButtonCSVLink
                                noIcon
                                csvLink={
                                  <CSVLink
                                    data={transactionReportRecords}
                                    headers={csvHeader}
                                    hidden
                                    filename={'transactions.csv'}
                                    target="_blank"
                                    ref={csvExportLink}
                                  />
                                }
                              >
                                CSV
                              </ExportButtonCSVLink>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  ) : (
    <Loading />
  )
}
