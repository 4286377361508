// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordUpdate
 */
export interface PasswordUpdate  {
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    passwordConfirmation: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    currentPassword: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    code?: string;
}

export function PasswordUpdateFromJSON(json: any): PasswordUpdate {
    return {
        'password': json['password'],
        'passwordConfirmation': json['password_confirmation'],
        'currentPassword': json['current_password'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function PasswordUpdateToJSON(value?: PasswordUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'password': value.password,
        'password_confirmation': value.passwordConfirmation,
        'current_password': value.currentPassword,
        'code': value.code,
    };
}


