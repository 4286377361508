// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntegrationCustomer,
    IntegrationCustomerFromJSON,
    IntegrationCustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConnectIntegrationCustomers200Response
 */
export interface ConnectIntegrationCustomers200Response  {
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationCustomers200Response
     */
    customersTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationCustomers200Response
     */
    customersSearchTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationCustomers200Response
     */
    customersConnected?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationCustomers200Response
     */
    customersMatched?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationCustomers200Response
     */
    customersNotConnected?: number;
    /**
     * 
     * @type {Array<IntegrationCustomer>}
     * @memberof ConnectIntegrationCustomers200Response
     */
    customers?: Array<IntegrationCustomer>;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationCustomers200Response
     */
    newlyImportedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationCustomers200Response
     */
    selectedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectIntegrationCustomers200Response
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectIntegrationCustomers200Response
     */
    errorMessage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectIntegrationCustomers200Response
     */
    notifications?: Array<string>;
}

export function ConnectIntegrationCustomers200ResponseFromJSON(json: any): ConnectIntegrationCustomers200Response {
    return {
        'customersTotal': !exists(json, 'customers_total') ? undefined : json['customers_total'],
        'customersSearchTotal': !exists(json, 'customers_search_total') ? undefined : json['customers_search_total'],
        'customersConnected': !exists(json, 'customers_connected') ? undefined : json['customers_connected'],
        'customersMatched': !exists(json, 'customers_matched') ? undefined : json['customers_matched'],
        'customersNotConnected': !exists(json, 'customers_not_connected') ? undefined : json['customers_not_connected'],
        'customers': !exists(json, 'customers') ? undefined : (json['customers'] as Array<any>).map(IntegrationCustomerFromJSON),
        'newlyImportedCount': !exists(json, 'newly_imported_count') ? undefined : json['newly_imported_count'],
        'selectedCount': !exists(json, 'selected_count') ? undefined : json['selected_count'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'errorMessage': !exists(json, 'error_message') ? undefined : json['error_message'],
        'notifications': !exists(json, 'notifications') ? undefined : json['notifications'],
    };
}

export function ConnectIntegrationCustomers200ResponseToJSON(value?: ConnectIntegrationCustomers200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'customers_total': value.customersTotal,
        'customers_search_total': value.customersSearchTotal,
        'customers_connected': value.customersConnected,
        'customers_matched': value.customersMatched,
        'customers_not_connected': value.customersNotConnected,
        'customers': value.customers === undefined ? undefined : (value.customers as Array<any>).map(IntegrationCustomerToJSON),
        'newly_imported_count': value.newlyImportedCount,
        'selected_count': value.selectedCount,
        'status': value.status,
        'error_message': value.errorMessage,
        'notifications': value.notifications,
    };
}


