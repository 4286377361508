// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account  {
    /**
     * 
     * @type {User}
     * @memberof Account
     */
    user?: User;
    /**
     * 
     * @type {Array<Client>}
     * @memberof Account
     */
    clients?: Array<Client>;
}

export function AccountFromJSON(json: any): Account {
    return {
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'clients': !exists(json, 'clients') ? undefined : (json['clients'] as Array<any>).map(ClientFromJSON),
    };
}

export function AccountToJSON(value?: Account): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'user': UserToJSON(value.user),
        'clients': value.clients === undefined ? undefined : (value.clients as Array<any>).map(ClientToJSON),
    };
}


