import React from 'react'
import { AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'
import { Label } from 'reactstrap'

export interface CustomRadioGroupProps {
  name: string
  label?: string | JSX.Element
  className?: string
  required?: boolean
  handleFieldChange?: Function
  validate?: { [key: string]: any }
  children?: any
}

export const CustomRadioGroup = ({
  name,
  label,
  className,
  required,
  handleFieldChange,
  validate,
  children,
}: CustomRadioGroupProps) => {
  return (
    <div>
      <Label htmlFor={name}>{label}</Label>
      <AvRadioGroup
        name={name}
        className={`custom-radio-group ${className}`}
        required={required}
        validate={validate}
        onChange={handleFieldChange}
      >
        {children}
      </AvRadioGroup>
    </div>
  )
}

export interface CustomRadioInputProps {
  id?: string
  name: string
  value: string | number
  label?: string | JSX.Element
  onChange?: Function
}

export const CustomRadioInput = ({ id, name, label, onChange, value }: CustomRadioInputProps) => {
  if (id === undefined) {
    id = `${name}_${value}`
  }

  return (
    <>
      <div className="custom-radio-input">
        <Label className="custom-radio-label" htmlFor={id}>
          {label}
        </Label>
        <div className="custom-radio-append">
          <AvRadio id={id} name={name} value={value} onChange={onChange} />
        </div>
      </div>
    </>
  )
}
