import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'

import { RootState } from 'app/rootReducer'
import { useIsPermitted } from 'hooks/useIsPermitted'

export interface CustomerContactDetailsProps {
  readOnly?: boolean
}

export const CustomerContactDetails = (props: CustomerContactDetailsProps) => {
  const customer = useSelector((state: RootState) => state.entities.customer) || {}
  const permitEdit = useIsPermitted('customers.edit')
  var rows: any[] = []

  if (customer.phone) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-phone-outline mr-2" />
        </td>
        <td>{customer.phone}</td>
      </tr>
    ))
  }

  if (customer.email) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-email-outline mr-2" />
        </td>
        <td>{customer.email}</td>
      </tr>
    ))
  }
  if (customer.customerType) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i
            className={
              'mdi ' + (customer.customerType === 'Business' ? 'mdi-office-building' : 'mdi-account') + ' mr-2'
            }
          />
        </td>
        <td>{customer.customerType}</td>
      </tr>
    ))
  }
  if (customer.customIdentifier) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-identifier mr-2" />
        </td>
        <td>{customer.customIdentifier}</td>
      </tr>
    ))
  }
  if (customer.address?.address1) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-map-marker-outline mr-2" />
        </td>
        <td>
          {customer.address?.address1} {customer.address?.city}, {customer.address?.provinceCode}{' '}
          {customer.address?.postalCode}
        </td>
      </tr>
    ))
  }

  return (
    <Card>
      <CardBody>
        <Row className="d-flex align-items-center">
          <Col>
            <h4 className="header-title no-wrap mb-3">Contact Details</h4>
          </Col>
          <Col>
            {!props.readOnly && permitEdit && (
              <Link to={`${customer.id}/edit_contact_details`} className="float-right mb-3">
                <span>Edit</span>
              </Link>
            )}
          </Col>
        </Row>
        {rows.length > 0 ? (
          <table>
            <tbody>
              {rows.map((Row, i) => (
                <Row key={i} />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <i className="mdi mdi-information mr-2" />
            No data
          </div>
        )}
      </CardBody>
    </Card>
  )
}
