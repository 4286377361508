// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthorizationLinkSetting,
    AuthorizationLinkSettingFromJSON,
    AuthorizationLinkSettingToJSON,
    Client,
    ClientFromJSON,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetLinkAuthorizationDetails200Response
 */
export interface GetLinkAuthorizationDetails200Response  {
    /**
     * 
     * @type {Client}
     * @memberof GetLinkAuthorizationDetails200Response
     */
    client?: Client;
    /**
     * 
     * @type {AuthorizationLinkSetting}
     * @memberof GetLinkAuthorizationDetails200Response
     */
    settings?: AuthorizationLinkSetting;
}

export function GetLinkAuthorizationDetails200ResponseFromJSON(json: any): GetLinkAuthorizationDetails200Response {
    return {
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'settings': !exists(json, 'settings') ? undefined : AuthorizationLinkSettingFromJSON(json['settings']),
    };
}

export function GetLinkAuthorizationDetails200ResponseToJSON(value?: GetLinkAuthorizationDetails200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client': ClientToJSON(value.client),
        'settings': AuthorizationLinkSettingToJSON(value.settings),
    };
}


