// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerPayment
 */
export interface CustomerPayment  {
    /**
     * 
     * @type {number}
     * @memberof CustomerPayment
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerPayment
     */
    processDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPayment
     */
    status?: string;
}

export function CustomerPaymentFromJSON(json: any): CustomerPayment {
    return {
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'processDate': !exists(json, 'process_date') ? undefined : json['process_date'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function CustomerPaymentToJSON(value?: CustomerPayment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amount': value.amount,
        'process_date': value.processDate,
        'status': value.status,
    };
}


