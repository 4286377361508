import React, { useState } from 'react'
import { ImportPreviewTable } from 'components/ImportPreviewTable'
import { Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useMutation } from 'redux-query-react'
import { getActiveClient, getEntities } from 'app/selectors'
import { useSelector } from 'react-redux'
import { customersCsvImport, CsvCustomerImport } from 'api'
import { useHistory } from 'react-router-dom'
import { FormServerErrors } from 'components/FormServerErrors'
import { LoadingMask } from 'components/LoadingMask'
import { NotificationsText } from 'components/NotificationsText'

const CustomerExistsFormatter = (exists, row) => {
  if (exists) {
    return 'Match Found'
  } else {
    return 'New Customer'
  }
}

export const CustomerPreview = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const activeClient = useSelector(getActiveClient)
  const previewCsvCustomers = useSelector((state) => getEntities(state).previewCsvCustomers) || []
  const [errors, setErrors] = useState([] as string[])
  const [importComplete, setImportComplete] = useState(false)
  const history = useHistory()

  function getNonSelectableRows() {
    var nonSelectableRows: number[] = []
    previewCsvCustomers?.forEach((record) => {
      if (!record.valid) {
        nonSelectableRows.push(record.id)
      }
    })
    return nonSelectableRows
  }

  const [{ isPending }, doMutation] = useMutation((selectedRows: CsvCustomerImport[]) => {
    return customersCsvImport({
      customersImport: {
        clientId: activeClient.id,
        selectedCustomers: selectedRows,
      },
    })
  })

  const handleValidSubmit = () => {
    doMutation(selectedRows)?.then((response) => {
      setErrors(response?.body?.errors || [])
      if (response?.body?.status === 200) {
        setImportComplete(true)
      }
    })
  }

  const handleClickAcknowledge = () => {
    history.push(`/client/customers`)
  }

  const columns = [
    {
      dataField: 'select',
      text: '',
    },
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'customIdentifier',
      text: 'Custom Identifier',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'errors',
      text: 'Errors',
    },
    {
      dataField: 'existingCustomer',
      text: 'Status',
      formatter: CustomerExistsFormatter,
    },
  ]
  return (
    <>
      <Modal isOpen={importComplete}>
        <ModalHeader>Customers Imported</ModalHeader>
        <ModalBody>
          <p>
            {selectedRows.length - errors.length}/{selectedRows.length} selected customers were successfully imported or
            updated.
          </p>
          {errors.length > 0 && <p>The following customers had errors:</p>}
          <NotificationsText notifications={errors} displayAlert={true} color="danger" />
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={handleClickAcknowledge}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-title-box">
        <h4 className="page-title">Preview Customers Import</h4>
      </div>
      <Card>
        <CardBody>
          <div className="text-sm-right mb-2">
            <Button
              disabled={isPending || selectedRows.length <= 0}
              color="primary"
              className="mr-1"
              onClick={handleValidSubmit}
            >
              Import {selectedRows.length} Selected Customers
            </Button>
          </div>
          {isPending && <LoadingMask />}
          {!importComplete && <FormServerErrors errors={errors} />}
          <ImportPreviewTable
            loading={isPending}
            data={previewCsvCustomers}
            columns={columns}
            mode="checkbox"
            clickToSelect={true}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows || []}
            nonSelectable={getNonSelectableRows()}
          />
        </CardBody>
      </Card>
    </>
  )
}
