// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerNotificationSettingsPaymentReminder
 */
export interface CustomerNotificationSettingsPaymentReminder  {
    /**
     * 
     * @type {boolean}
     * @memberof CustomerNotificationSettingsPaymentReminder
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerNotificationSettingsPaymentReminder
     */
    notificationMethod?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerNotificationSettingsPaymentReminder
     */
    unsubscribed?: boolean;
}

export function CustomerNotificationSettingsPaymentReminderFromJSON(json: any): CustomerNotificationSettingsPaymentReminder {
    return {
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'notificationMethod': !exists(json, 'notification_method') ? undefined : json['notification_method'],
        'unsubscribed': !exists(json, 'unsubscribed') ? undefined : json['unsubscribed'],
    };
}

export function CustomerNotificationSettingsPaymentReminderToJSON(value?: CustomerNotificationSettingsPaymentReminder): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enabled': value.enabled,
        'notification_method': value.notificationMethod,
        'unsubscribed': value.unsubscribed,
    };
}


