// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerNote
 */
export interface CustomerNote  {
    /**
     * 
     * @type {number}
     * @memberof CustomerNote
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerNote
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerNote
     */
    createdById?: number;
    /**
     * 
     * @type {Date}
     * @memberof CustomerNote
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerNote
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerNote
     */
    createdBy?: string;
}

export function CustomerNoteFromJSON(json: any): CustomerNote {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customerId': json['customer_id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'description': json['description'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
    };
}

export function CustomerNoteToJSON(value?: CustomerNote): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'customer_id': value.customerId,
        'created_by_id': value.createdById,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'description': value.description,
        'created_by': value.createdBy,
    };
}


