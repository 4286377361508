import React, { useEffect, useState } from 'react'
import { useMutation } from 'redux-query-react'

import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { PasswordUpdate, updateAccountSettings } from 'api'
import { useFormServerErrors } from 'hooks/useFormServerErrors'
import logo from 'assets/images/logo-blue.png'
import { getActiveUser } from 'app/selectors'
import { useSelector } from 'react-redux'
import { FormServerErrors } from 'components/FormServerErrors'

export const ChangePassword = () => {
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState({})
  const { handleFieldChange, validate } = useFormServerErrors(errors, setErrors)
  const activeUser = useSelector(getActiveUser)
  const [mutationState, doMutation] = useMutation((values: PasswordUpdate) => {
    return updateAccountSettings({
      passwordUpdate: { ...values },
    })
  })

  function handleValidSubmit(_event, values) {
    doMutation(values)?.then((response) => {
      setErrors(response?.body?.json?.errors || {})
      if (response?.body?.json?.status === 'success') {
        setSuccess(true)
      }
    })
  }

  return (
    <div>
      <Modal isOpen={success}>
        <ModalHeader>Success</ModalHeader>
        <ModalBody>
          <p>Your password has been changed.</p>
          <div className="text-right">
            <Link to="/my_account">
              <Button color="primary">OK</Button>
            </Link>
          </div>
        </ModalBody>
      </Modal>
      <Link to="/" className="logo text-center logo-light non-menu-logo">
        <span className="logo logo-lg non-menu-logo">
          <img src={logo} alt="logo" height="20" />
        </span>
      </Link>
      <Row className="ml-1 mr-1 mb-4">
        <Col md={{ size: 4, offset: 4 }}>
          <div className="page-title-box">
            <h4 className="page-title">Change Password</h4>
          </div>
          <AvForm disabled={mutationState.isPending} onValidSubmit={handleValidSubmit}>
            <Card>
              <CardBody>
                <FormServerErrors errors={(errors && Object.values(errors)) || []} />
                <AvField
                  name="currentPassword"
                  label="Current Password"
                  type="password"
                  required
                  validate={{ async: validate }}
                  onChange={handleFieldChange}
                />
                <AvField
                  name="password"
                  label="New Password"
                  type="password"
                  required
                  validate={{
                    async: validate,
                    minLength: { value: 8, errorMessage: 'Minimum length: 8' },
                    pattern: {
                      value: /^([\w]+[\W]+|[\W]+[\w]+)/,
                      errorMessage: 'Must contain at least 1 letter or number and 1 symbol',
                    },
                  }}
                  onChange={handleFieldChange}
                />
                <AvField
                  name="passwordConfirmation"
                  label="Confirm New Password"
                  type="password"
                  required
                  validate={{
                    async: validate,
                    match: {
                      value: 'password',
                      errorMessage: 'Passwords do not match',
                    },
                  }}
                  onChange={handleFieldChange}
                />
                {activeUser?.twoFactorAuthentication && (
                  <AvField
                    name="code"
                    label="Verification Code"
                    type="text"
                    onChange={handleFieldChange}
                    required
                    placeholder="6 digits"
                  />
                )}
              </CardBody>
            </Card>

            <div className="text-right">
              <Link to="/my_account">
                <Button className="btn-rotessa-secondary min-width mb-2" type="button">
                  Cancel
                </Button>
              </Link>
              <Button disabled={mutationState.isPending} color="primary" className="mb-2 ml-1" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </Col>
      </Row>
    </div>
  )
}
