import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { authUrl, isPermitted } from 'utilities'
import copy from 'clipboard-copy'

import { Button, Card, CardBody, Fade, InputGroup, InputGroupAddon, Input, Row, Col } from 'reactstrap'

import { getActiveClient, getEntities } from 'app/selectors'
import { CenterContent } from 'components/CenterContent'
import { getAuthorizationLinkSettings } from 'api'
import { useRequest } from 'redux-query-react'
import { getProcessingAuthSettingsConfig } from 'features/auth_settings/processing/processingAuthSettingsManager'
import { getDonorAuthSettingsConfig } from 'features/auth_settings/donor/donorAuthSettingsManager'
import { ExternalLink } from 'components/ExternalLink'
import { TitleBar } from './titleBar'

export interface CustomerBankInfoProps {
  clientId?: number
}

export const CustomerAuthorizationLink = () => {
  const activeClient = useSelector(getActiveClient)

  const handleClickCopyFormUrl = () => {
    copy(authUrl(authLinkCode, authType))
    // TODO show a toast or something?
  }

  let authType = ''

  if (activeClient?.clientType === 'Donation') {
    authType = 'donation'
  } else if (activeClient?.newAuthSettingsEnabled) {
    authType = 'processing'
  } else {
    authType = 'tuition'
  }

  const getAuthSettingsConfigForType = (clientId: number) => {
    if (authType === 'processing') {
      return getProcessingAuthSettingsConfig(clientId)
    }
    if (authType === 'donation') {
      return getDonorAuthSettingsConfig(clientId)
    }
    if (authType === 'tuition') {
      return getAuthorizationLinkSettings(
        {
          clientId: clientId,
        },
        {
          force: true,
          transform: (body) => {
            return { authorizationLinkSetting: body }
          },
          update: {
            authorizationLinkSetting: (_, newValue) => newValue,
          },
        },
      )
    }
  }

  const [requestState, _doRequest] = useRequest(activeClient && getAuthSettingsConfigForType(activeClient?.id))

  const authLinkCode = {
    processing: useSelector((state) => getEntities(state).processingAuthSetting)?.code,
    donation: useSelector((state) => getEntities(state).donorAuthSetting)?.code,
    tuition: useSelector((state) => getEntities(state).authorizationLinkSetting)?.formUrl,
  }[authType]

  return (
    <Fade>
      <TitleBar
        title="Public Link"
        subtitle="Send this link to your customers or embed the link on your company website."
      />
      <div className="mb-2 mt-2">
        <CenterContent>
          <Card className="p-2">
            <CardBody>
              <InputGroup>
                <Input readOnly value={authUrl(authLinkCode, authType)} />
                <InputGroupAddon addonType="append">
                  <Button color="primary" onClick={handleClickCopyFormUrl}>
                    Copy
                  </Button>
                </InputGroupAddon>
              </InputGroup>

              <Row>
                <Col lg={{ size: 5 }}>
                  <div className="d-flex justify-content-center">
                    <ExternalLink to={authUrl(authLinkCode, authType)}>
                      <Button type="button" color="link" className="p-0 mt-2">
                        <i className="mdi mdi-eye-outline ml-1 mr-1" />
                        Preview link
                      </Button>
                    </ExternalLink>
                  </div>
                </Col>

                {isPermitted('authorization_settings.edit_authorization_settings', activeClient) && (
                  <Col lg={{ size: 7 }}>
                    <div className="d-flex justify-content-center">
                      <Link to="/client/authorizations/settings/">
                        <Button type="button" color="link" className="p-0 mt-2">
                          <i className="mdi mdi-pencil ml-1 mr-1" />
                          Edit authorization settings
                        </Button>
                      </Link>
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </CenterContent>
      </div>
    </Fade>
  )
}
