// inspired bya snippet found at https://github.com/amplitude/redux-query/issues/120
import { actionTypes } from 'redux-query'
import { AUTH_HEADER_KEYS, RESET_PASSWORD_PREFIX } from 'app/constants'
import { login, logout } from 'app/authReducer'
import { minutesToMillis } from 'utilities'

const { MUTATE_ASYNC, REQUEST_ASYNC } = actionTypes

const resetLogoutTimeout = (dispatch) => {
  const logoutKey = 'logoutCancelToken'
  const oldToken = Number.parseInt(localStorage.getItem(logoutKey) || '')
  if (oldToken) {
    clearTimeout(oldToken)
  }
  localStorage.setItem(
    logoutKey,
    setTimeout(() => {
      dispatch(logout())
      window.location.reload()
    }, minutesToMillis(30)).toString(),
  )
}

export const handleResponseAuthHeaders = (body, headers, store) => {
  if (body?.status === 401) {
    // redirect to login on any unauthorized response code
    store.dispatch(logout())
  } else if (headers) {
    let foundToken = false
    // update authentication keys if new expiry is higher than current
    if (headers['expiry'] > Number.parseInt(localStorage.getItem('expiry') || '0')) {
      AUTH_HEADER_KEYS.forEach((key) => {
        const value = headers[key]
        if (value) {
          foundToken = true
          localStorage.setItem(key, value)
        }
      })
      if (foundToken) {
        store.dispatch(login())
        resetLogoutTimeout(store.dispatch)
      }
    }
  }
}

export const UseAuthHeaders = (store) => (next) => (action) => {
  if (action && (action.type === MUTATE_ASYNC || action.type === REQUEST_ASYNC)) {
    const options = action.options || {}
    const headers = options.headers || {}
    const headerKeyPrefix = window.location.pathname === '/reset_password' ? RESET_PASSWORD_PREFIX : ''
    const authHeaders = AUTH_HEADER_KEYS.reduce((o, k) => {
      return { ...o, [k]: localStorage.getItem(`${headerKeyPrefix}${k}`) }
    }, {})
    const updatedAction = {
      ...action,
      options: {
        ...options,
        headers: {
          ...headers,
          ...authHeaders,
        },
      },
    }
    // Let the action continue, but now with the JWT header.
    const result = next(updatedAction)
    result?.then((queryState) => handleResponseAuthHeaders(queryState.body, queryState.headers, store))
    return result
  } else {
    // This isn't a redux-query action so just let it pass through
    next(action)
  }
}
