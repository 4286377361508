// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    BankAccount,
    BankAccountFromJSON,
    BankAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface DonorAuthorization
 */
export interface DonorAuthorization  {
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    submittedStep: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    termsAndConditions?: string;
    /**
     * 
     * @type {number}
     * @memberof DonorAuthorization
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    frequency?: string;
    /**
     * 
     * @type {Date}
     * @memberof DonorAuthorization
     */
    processDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof DonorAuthorization
     */
    installments?: number;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    comment?: string;
    /**
     * 
     * @type {Address}
     * @memberof DonorAuthorization
     */
    address?: Address;
    /**
     * 
     * @type {BankAccount}
     * @memberof DonorAuthorization
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthorization
     */
    language?: string;
}

export function DonorAuthorizationFromJSON(json: any): DonorAuthorization {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'submittedStep': json['submitted_step'],
        'termsAndConditions': !exists(json, 'terms_and_conditions') ? undefined : json['terms_and_conditions'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'processDate': !exists(json, 'process_date') ? undefined : new Date(json['process_date']),
        'installments': !exists(json, 'installments') ? undefined : json['installments'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'bankAccount': !exists(json, 'bank_account') ? undefined : BankAccountFromJSON(json['bank_account']),
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function DonorAuthorizationToJSON(value?: DonorAuthorization): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'submitted_step': value.submittedStep,
        'terms_and_conditions': value.termsAndConditions,
        'amount': value.amount,
        'frequency': value.frequency,
        'process_date': value.processDate === undefined ? undefined : value.processDate.toISOString(),
        'installments': value.installments,
        'comment': value.comment,
        'address': AddressToJSON(value.address),
        'bank_account': BankAccountToJSON(value.bankAccount),
        'language': value.language,
    };
}


