import { getEntities } from 'app/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import mail_icon from 'assets/images/donor_auth_icons/mail_icon.svg'

export const DonorAuthEmailSent = () => {
  const { t } = useTranslation()

  return (
    <div className="mt-2 text-center">
      <img src={mail_icon} alt="mail icon" height={35} />
      <h3 className="mt-3 text-almost-black">{t('authorization.donation.email_sent.success_thank_you')}</h3>
      <Trans i18nKey="authorization.donation.email_sent.email_verification" components={{ bold: <strong /> }} />
    </div>
  )
}

export const DonorAuthEmailSentFooter = () => {
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo)
  const { t } = useTranslation()

  return (
    <div className="text-center">
      {t('authorization.donation.email_sent.not_received_intro', {
        business_name: donorAuthInfo?.client?.businessName,
      })}
      {donorAuthInfo?.email &&
        t('authorization.donation.email_sent.not_received_client_email', {
          client_email: donorAuthInfo?.email,
        })}
      .
    </div>
  )
}
