// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectInvoices
 */
export interface ConnectInvoices  {
    /**
     * 
     * @type {number}
     * @memberof ConnectInvoices
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectInvoices
     */
    filter?: string;
    /**
     * 
     * @type {Date}
     * @memberof ConnectInvoices
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConnectInvoices
     */
    endDate?: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConnectInvoices
     */
    ids?: Array<number>;
}

export function ConnectInvoicesFromJSON(json: any): ConnectInvoices {
    return {
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
        'startDate': !exists(json, 'start_date') ? undefined : new Date(json['start_date']),
        'endDate': !exists(json, 'end_date') ? undefined : new Date(json['end_date']),
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
    };
}

export function ConnectInvoicesToJSON(value?: ConnectInvoices): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client_id': value.clientId,
        'filter': value.filter,
        'start_date': value.startDate === undefined ? undefined : value.startDate.toISOString().substring(0,10),
        'end_date': value.endDate === undefined ? undefined : value.endDate.toISOString().substring(0,10),
        'ids': value.ids,
    };
}


