import React, { useState } from 'react'
import { UserNotificationAccordionItem } from './UserNotificationAccordionItem'
import { Client } from 'api'

export function UserNotificationAccordion({ clients }: { clients: Array<Client> }) {
  const [currentIndex, setCurrentIndex] = useState(-1)
  const btnOnClick = (index: number) => {
    if (currentIndex === index) {
      setCurrentIndex(-1)
    } else {
      setCurrentIndex(index)
    }
  }

  return (
    <div className="accordion user-notification-accordion">
      {clients.map((client, index) => (
        <>
          {index > 0 && <hr className="m-0" />}
          <UserNotificationAccordionItem
            key={index}
            client={client}
            isOpen={index === currentIndex}
            btnOnClick={client.status === 'Approved' ? () => btnOnClick(index) : () => {}}
          />
        </>
      ))}
    </div>
  )
}
