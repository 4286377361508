import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import copy from 'clipboard-copy'

import { AvForm, AvInput, AvField } from 'availity-reactstrap-validation'
import { FormGroup, InputGroup, InputGroupAddon, Button, UncontrolledTooltip, Fade } from 'reactstrap'

import { getActiveClient, getEntities } from 'app/selectors'
import { getAuthorizationLinkSettings, updateAuthorizationLinkSettings } from 'api'
import { MdEditor } from 'components/Editor'
import { FormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { Loading } from 'components/Loading'
import { htmlToMarkdown, markdownToHtml } from 'utilities'

export const AuthorizationLinkSettings = () => {
  const linkAuthBaseUrl = `${window.location.host}/link_authorizations?form_url=`
  const activeClient = useSelector(getActiveClient)
  const setting = useSelector((state) => getEntities(state).authorizationLinkSetting)
  const [introduction, setIntroduction] = useState('')
  const [terms, setTerms] = useState('')
  const [formLoaded, setFormLoaded] = useState(false)
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)

  const [requestState, doRequest] = useRequest(
    activeClient?.id &&
      getAuthorizationLinkSettings(
        {
          clientId: activeClient.id,
        },
        {
          force: true,
          transform: (body) => {
            return { authorizationLinkSetting: body }
          },
          update: {
            authorizationLinkSetting: (_, newValue) => newValue,
          },
        },
      ),
  )

  const [{ isPending }, doMutation] = useMutation((values: any) => {
    return updateAuthorizationLinkSettings({
      authorizationLinkSetting: values,
    })
  })

  const handleClickCopyFormUrl = () => {
    copy(`${linkAuthBaseUrl}${setting?.formUrl}`)
    // TODO show a toast or something?
  }

  const handleValidSubmit = (event, values) => {
    doMutation({
      ...values,
      clientId: activeClient.id,
      introduction: markdownToHtml(introduction),
      termsAndConditions: markdownToHtml(terms),
    })?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      if (response?.body?.status === 200) {
        doRequest()
      }
    })
  }

  if (
    !formLoaded &&
    requestState.isFinished &&
    setting?.introduction !== undefined &&
    setting?.termsAndConditions !== undefined
  ) {
    setIntroduction(htmlToMarkdown(setting.introduction))
    setTerms(htmlToMarkdown(setting.termsAndConditions))
    setFormLoaded(true)
    return <Loading />
  }

  if (requestState.isPending || !setting) {
    return <Loading />
  }

  return (
    <Fade>
      <AvForm model={setting} onValidSubmit={handleValidSubmit} disabled={isPending}>
        <h4 className="header-title mb-3">Authorization Link Settings</h4>
        <p>
          Get permission from your customers to debit their account with one simple link. Include this link on your
          website or share it via your preferred messaging application.
        </p>
        <p>
          When your customer completes the form a new customer profile is created in Rotessa and you will receive an
          email notification.
        </p>

        <h5>Customize Link URL</h5>
        <FormServerErrors errors={(serverErrors && serverErrors['form_url'] && [serverErrors['form_url'][0]]) || []} />
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend" className="wrap-input">
              {linkAuthBaseUrl}
            </InputGroupAddon>
            <AvInput name="formUrl" />

            <InputGroupAddon addonType="append">
              <Button onClick={handleClickCopyFormUrl} id="copy-auth-link-url-button" color="dark">
                <i className="mdi mdi-content-copy" />
              </Button>
              <UncontrolledTooltip placement="bottom" target="copy-auth-link-url-button">
                Copy URL
              </UncontrolledTooltip>
            </InputGroupAddon>
            <InputGroupAddon addonType="append">
              <Button
                id="preview-auth-link-url-button"
                href={`${window.location.protocol}//${linkAuthBaseUrl}${setting?.formUrl}`}
                target="_blank"
                color="info"
              >
                <i className="mdi mdi-eye-outline" />
              </Button>
              <UncontrolledTooltip placement="bottom" target="preview-auth-link-url-button">
                Preview URL
              </UncontrolledTooltip>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <h5>Options</h5>
        <FormGroup>
          <AvField
            type="checkbox"
            name="displayClientAddress"
            label={`Display ${activeClient?.businessName} business address`}
            validate={{ async: validate }}
            onChange={handleFieldChange}
          />
          <AvField
            type="checkbox"
            name="requirePhone"
            label="Require your customer to include their phone number"
            validate={{ async: validate }}
            onChange={handleFieldChange}
          />
          <AvField
            type="checkbox"
            name="requireAddress"
            label="Require your customer to include their address"
            validate={{ async: validate }}
            onChange={handleFieldChange}
          />
          {activeClient?.countryCode === 'CA' && (
            <AvField
              type="checkbox"
              name="allowMicrodeposit"
              label="Send a microdeposit to validate bank information when a customer signs up"
              validate={{ async: validate }}
              onChange={handleFieldChange}
            />
          )}
          <AvField
            type="checkbox"
            name="allowTransactionSchedule"
            label="Allow your customer to define their payment schedule"
            validate={{ async: validate }}
            onChange={handleFieldChange}
          />
        </FormGroup>

        <h5 className="page-title">Introduction</h5>
        <FormServerErrors errors={(serverErrors && serverErrors['introduction']) || []} />
        <MdEditor onChange={setIntroduction} value={introduction} />

        <h5 className="page-title">Terms and Conditions</h5>
        <FormServerErrors errors={(serverErrors && serverErrors['terms_and_conditions']) || []} />
        <MdEditor onChange={setTerms} value={terms} />

        <div className="text-right">
          <Button disabled={isPending} className="mt-2" color="primary" type="submit">
            Save
          </Button>
        </div>
      </AvForm>
    </Fade>
  )
}
