import React from 'react'

import { useSelector } from 'react-redux'
import { getEntities } from 'app/selectors'

export const DonorAuthHeader = () => {
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo) || {}
  const logoUrl = donorAuthInfo.client?.logo?.url
  const name = donorAuthInfo.client?.businessName || ''
  const containerCssClasses = 'container-fluid text-center'

  return (
    <div style={{ position: 'relative' }}>
      <div className={`donation-header`}>
        <div className={containerCssClasses + ' donation-header-contents'}>
          <div className="inner">
            {logoUrl ? <img src={logoUrl} style={{ borderRadius: 4 }} alt="" /> : <h3>{name}</h3>}
          </div>
        </div>
      </div>
    </div>
  )
}
