import React from 'react'
import { Row, Col, PopoverBody, UncontrolledPopover } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { ImagePopover } from 'components/ImagePopover'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from 'hooks/useIsMobile'

export interface BankAccountFieldsProps {
  countryCode?: string
  fieldNamePrefix?: string
  required?: boolean
  serverErrors?: Errors
  twoRows?: boolean
  threeRows?: boolean
  startingTabIndex?: number
  allowMasked?: boolean
  disabled?: boolean
  placeholderLabel?: boolean
}

export const BankAccountFields = ({
  countryCode,
  fieldNamePrefix,
  required,
  serverErrors,
  twoRows,
  threeRows,
  startingTabIndex = 0,
  allowMasked = false,
  disabled = false,
  placeholderLabel = false,
}: BankAccountFieldsProps) => {
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, (_) => null)
  const isMobile = useIsMobile()
  const withPrefix = (fieldName) => {
    const prefix = fieldNamePrefix ? `${fieldNamePrefix}.` : ''
    return `${prefix}${fieldName}`
  }

  const labelWithImage = (fieldLabel, imagePath) => {
    return (
      <>
        {fieldLabel}
        <ImagePopover popoverImagePath={imagePath} />
      </>
    )
  }

  const { t } = useTranslation()

  const fields =
    countryCode === 'US'
      ? [
          <AvField
            name={withPrefix('bankAccountType')}
            label={placeholderLabel ? undefined : t('bank_account.account_type')}
            type="select"
            required={required}
            validate={{
              required: {
                value: { required },
                errorMessage: t('required_field'),
              },
              async: validate,
            }}
            onChange={handleFieldChange}
            tabIndex={startingTabIndex + 1}
            disabled={disabled}
          >
            {placeholderLabel ? (
              <option value="" disabled selected hidden>
                {t('bank_account.account_type')}
              </option>
            ) : (
              <option></option>
            )}
            <option value="Checking">{t('bank_account.account_type_checking')}</option>
            <option value="Savings">{t('bank_account.account_type_savings')}</option>
          </AvField>,
          <>
            <AvField
              name={withPrefix('routingNumber')}
              id="routingNumber"
              label={
                placeholderLabel
                  ? undefined
                  : labelWithImage(
                      t('bank_account.routing_number'),
                      '/sampleCheques/Authorization-explainer-routing-number(us).png',
                    )
              }
              type="text"
              placeholder={placeholderLabel ? t('bank_account.routing_number') : t('bank_account.routing_placeholder')}
              required={required}
              validate={{
                required: {
                  value: { required },
                  errorMessage: t('required_field'),
                },
                pattern: {
                  value: allowMasked ? /^[*\d]{9}$/ : /^\d{9}$/,
                  errorMessage: t('bank_account.routing_validation'),
                },
                async: validate,
              }}
              onChange={handleFieldChange}
              tabIndex={startingTabIndex + 2}
              disabled={disabled}
            />
            {placeholderLabel && (
              <UncontrolledPopover placement={isMobile ? 'top' : 'left'} target={'routingNumber'} trigger="hover">
                <PopoverBody>
                  <img src={'/sampleCheques/Authorization-explainer-routing-number(us).png'} width="250" />
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </>,
          <>
            <AvField
              name={withPrefix('accountNumber')}
              id="accountNumber"
              label={
                placeholderLabel
                  ? undefined
                  : labelWithImage(
                      t('bank_account.account_number'),
                      '/sampleCheques/Authorization-explainer-account-number(us).png',
                    )
              }
              type="text"
              placeholder={
                placeholderLabel ? t('bank_account.account_number') : t('bank_account.account_placeholder_us')
              }
              required={required}
              validate={{
                required: {
                  value: { required },
                  errorMessage: t('required_field'),
                },
                pattern: {
                  value: allowMasked ? /^[*\d]{6,17}$/ : /^\d{6,17}$/,
                  errorMessage: t('bank_account.account_validation_us'),
                },
                async: validate,
              }}
              onChange={handleFieldChange}
              tabIndex={startingTabIndex + 3}
              disabled={disabled}
            />
            {placeholderLabel && (
              <UncontrolledPopover placement={isMobile ? 'top' : 'left'} target={'accountNumber'} trigger="hover">
                <PopoverBody>
                  <img src={'/sampleCheques/Authorization-explainer-account-number(us).png'} width="250" />
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </>,
        ]
      : [
          <>
            <AvField
              name={withPrefix('transitNumber')}
              id="transitNumber"
              label={
                placeholderLabel
                  ? undefined
                  : labelWithImage(
                      t('bank_account.transit_number'),
                      '/sampleCheques/Authorization-explainer-transit-number.png',
                    )
              }
              type="text"
              placeholder={placeholderLabel ? t('bank_account.transit_number') : t('bank_account.transit_placeholder')}
              required={required}
              validate={{
                required: {
                  value: { required },
                  errorMessage: t('required_field'),
                },
                pattern: {
                  value: allowMasked ? /^[*\d]{5}$/ : /^\d{5}$/,
                  errorMessage: t('bank_account.transit_validation'),
                },
                async: validate,
              }}
              onChange={handleFieldChange}
              tabIndex={startingTabIndex + 1}
              disabled={disabled}
            />
            {placeholderLabel && (
              <UncontrolledPopover placement={isMobile ? 'top' : 'left'} target={'transitNumber'} trigger="hover">
                <PopoverBody>
                  <img src={'/sampleCheques/Authorization-explainer-transit-number.png'} width="250" />
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </>,
          <>
            <AvField
              name={withPrefix('institutionNumber')}
              id="institutionNumber"
              label={
                placeholderLabel
                  ? undefined
                  : labelWithImage(
                      t('bank_account.institution_number'),
                      '/sampleCheques/Authorization-explainer-institution-number.png',
                    )
              }
              type="text"
              placeholder={
                placeholderLabel ? t('bank_account.institution_number') : t('bank_account.institution_placeholder')
              }
              required={required}
              validate={{
                required: {
                  value: { required },
                  errorMessage: t('required_field'),
                },
                pattern: {
                  value: allowMasked ? /^[*\d]{3}$/ : /^\d{3}$/,
                  errorMessage: t('bank_account.institution_validation'),
                },
                async: validate,
              }}
              onChange={handleFieldChange}
              tabIndex={startingTabIndex + 2}
              disabled={disabled}
            />
            {placeholderLabel && (
              <UncontrolledPopover placement={isMobile ? 'top' : 'left'} target={'institutionNumber'} trigger="hover">
                <PopoverBody>
                  <img src={'/sampleCheques/Authorization-explainer-institution-number.png'} width="250" />
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </>,
          <>
            <AvField
              name={withPrefix('accountNumber')}
              id="accountNumber"
              label={
                placeholderLabel
                  ? undefined
                  : labelWithImage(
                      t('bank_account.account_number'),
                      '/sampleCheques/Authorization-explainer-account-number.png',
                    )
              }
              type="text"
              placeholder={placeholderLabel ? t('bank_account.account_number') : t('bank_account.account_placeholder')}
              required={required}
              validate={{
                required: {
                  value: { required },
                  errorMessage: t('required_field'),
                },
                pattern: {
                  value: allowMasked ? /^[*\d]{5,12}$/ : /^\d{5,12}$/,
                  errorMessage: t('bank_account.account_validation'),
                },
                async: validate,
              }}
              onChange={handleFieldChange}
              tabIndex={startingTabIndex + 3}
              disabled={disabled}
            />
            {placeholderLabel && (
              <UncontrolledPopover placement={isMobile ? 'top' : 'left'} target={'accountNumber'} trigger="hover">
                <PopoverBody>
                  <img src={'/sampleCheques/Authorization-explainer-account-number.png'} width="250" />
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </>,
        ]

  return threeRows ? (
    <>{fields.map((field) => field)}</>
  ) : twoRows ? (
    <>
      <Row>
        <Col lg={6}>{fields[0]}</Col>
        <Col lg={6}>{fields[1]}</Col>
      </Row>
      <Row className="mb-2">
        <Col>{fields[2]}</Col>
      </Row>
    </>
  ) : (
    <Row className="mb-2">
      <Col lg={4}>{fields[0]}</Col>
      <Col lg={4}>{fields[1]}</Col>
      <Col lg={4}>{fields[2]}</Col>
    </Row>
  )
}
