import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'

import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'

import { updateBankAccount, UpdateBankAccount } from 'api'
import { getActiveClient } from 'app/selectors'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { Errors } from 'hooks/useFormServerErrors'
import { BankAccountFields } from 'components/BankAccountFields'
import { CenterContent } from 'components/CenterContent'

export const ClientBankAccountForm = () => {
  const history = useHistory()
  const activeClient = useSelector(getActiveClient) || {}
  const [serverErrors, setServerErrors] = useState<Errors>()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const formRef = useRef<AvForm>()

  const location = useLocation()
  const bankAccountType = location.pathname.includes('billing') ? 'billing_bank_account' : 'settlement_bank_account'
  const bankAccountName = bankAccountType === 'billing_bank_account' ? 'billing' : 'settlement'

  const [mutationState, doMutation] = useMutation((values: UpdateBankAccount) => {
    return updateBankAccount({
      id: activeClient.id,
      updateBankAccount: values,
    })
  })

  function handleValidSubmit(_event, values: UpdateBankAccount) {
    values.accountType = bankAccountType
    doMutation(values)?.then((response) => {
      setServerErrors(response?.body?.messages)
      if (response?.body?.status < 400) {
        setShowSuccessModal(true)
      }
    })
  }

  const isPending = mutationState.isPending

  return (
    <div>
      <Modal isOpen={showSuccessModal}>
        <ModalHeader>Success</ModalHeader>
        <ModalBody>Your {bankAccountName} bank account has been updated.</ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={() => history.push('/client/settings')} className="ml-2">
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <CenterContent>
        <div className="page-title-box">
          <h4 className="page-title">Update {bankAccountName} Account</h4>
        </div>
        <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
        <AvForm disabled={isPending} onValidSubmit={handleValidSubmit} ref={formRef}>
          <Card>
            <CardBody>
              <p>
                Update your {bankAccountName} account details by verifying the current account here, then enter the new
                account information below.
              </p>
              <h4 className="header-title mb-3">Current Account</h4>
              <BankAccountFields fieldNamePrefix="currentAccount" countryCode={activeClient?.countryCode} />

              <h4 className="header-title mb-3 mt-4">New Account</h4>
              <BankAccountFields
                fieldNamePrefix="newAccount"
                countryCode={activeClient?.countryCode}
                serverErrors={serverErrors}
                startingTabIndex={3}
              />
            </CardBody>
          </Card>

          <div className="text-right">
            <Link to="/client/settings">
              <Button disabled={isPending} className="btn-rotessa-secondary min-width mb-2 ml-1" tabIndex={7}>
                Close
              </Button>
            </Link>
            <Button disabled={isPending} color="primary" className="mb-2 ml-1" tabIndex={8}>
              Submit
            </Button>
          </div>
        </AvForm>
      </CenterContent>
    </div>
  )
}
