// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileToJSON,
} from './';

/**
 * 
 * @export
 * @interface BillingInvoice
 */
export interface BillingInvoice  {
    /**
     * 
     * @type {number}
     * @memberof BillingInvoice
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingInvoice
     */
    invoiceNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingInvoice
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof BillingInvoice
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingInvoice
     */
    subtotal?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingInvoice
     */
    invoiceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInvoice
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInvoice
     */
    reference?: string;
    /**
     * 
     * @type {UploadedFile}
     * @memberof BillingInvoice
     */
    pdf?: UploadedFile;
    /**
     * 
     * @type {string}
     * @memberof BillingInvoice
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInvoice
     */
    endDate?: string;
}

export function BillingInvoiceFromJSON(json: any): BillingInvoice {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'invoiceNumber': !exists(json, 'invoice_number') ? undefined : json['invoice_number'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'subtotal': !exists(json, 'subtotal') ? undefined : json['subtotal'],
        'invoiceDate': !exists(json, 'invoice_date') ? undefined : json['invoice_date'],
        'dueDate': !exists(json, 'due_date') ? undefined : json['due_date'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'pdf': !exists(json, 'pdf') ? undefined : UploadedFileFromJSON(json['pdf']),
        'startDate': !exists(json, 'start_date') ? undefined : json['start_date'],
        'endDate': !exists(json, 'end_date') ? undefined : json['end_date'],
    };
}

export function BillingInvoiceToJSON(value?: BillingInvoice): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'invoice_number': value.invoiceNumber,
        'status': value.status,
        'total': value.total,
        'subtotal': value.subtotal,
        'invoice_date': value.invoiceDate,
        'due_date': value.dueDate,
        'reference': value.reference,
        'pdf': UploadedFileToJSON(value.pdf),
        'start_date': value.startDate,
        'end_date': value.endDate,
    };
}


