import { FinancialTransaction, searchFinancialTransactions } from 'api'
import { BANK_FIELDS } from 'app/constants'
import { getEntities, getTransactionStatuses } from 'app/selectors'
import { Loading } from 'components/Loading'
import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Fade, Row, Table } from 'reactstrap'
import { useRequest } from 'redux-query-react'
import { formatDateGreg, titleify } from 'utilities'

const ApprovalRow = (financialTransaction: FinancialTransaction) => {
  var title: string = 'Pending Approval'
  var date: string | undefined = financialTransaction.earliestApprovalDate
  const transactionStatuses = useSelector(getTransactionStatuses)

  switch (financialTransaction.status) {
    case transactionStatuses?.approved:
      title = 'Approved'
      break
    case transactionStatuses?.declined:
      title = 'Declined'
      date = financialTransaction.declineDate
      break
    case transactionStatuses?.chargeback:
      title = 'Chargeback'
      date = financialTransaction.declineDate
      break
  }

  return (
    <tr>
      <td>{title}</td>
      <td>{date && formatDateGreg(date)}</td>
    </tr>
  )
}

const SettlementRow = (financialTransaction: FinancialTransaction) => {
  var title: string = 'Settlement Expected'
  var date: string | undefined = financialTransaction.earliestApprovalDate
  const transactionStatuses = useSelector(getTransactionStatuses)

  switch (financialTransaction.status) {
    case transactionStatuses?.declined:
    case transactionStatuses?.chargeback:
      return null
    case transactionStatuses?.approved:
      title = 'Settlement Sent'
      date = financialTransaction.settledDate
      break
  }

  if (!date) return null

  return (
    <tr>
      <td>{title}</td>
      <td>{formatDateGreg(date)}</td>
    </tr>
  )
}

export const TransactionHistoryDetails = ({ customerId, financialTransactionId }) => {
  const financialTransactionDetail = useSelector((state) => getEntities(state).financialTransactionDetail) || {}

  const [requestState, doRequest] = useRequest(
    customerId &&
      financialTransactionId &&
      searchFinancialTransactions(
        {
          customerId: customerId,
          financialTransactionId: financialTransactionId,
        },
        {
          force: true,
          transform: (response) => {
            return { financialTransactionDetail: response.records?.[0] }
          },
          update: { financialTransactionDetail: (_, newValue) => newValue },
        },
      ),
  )

  return (
    <Row>
      <Col md={{ size: 4, offset: 4 }}>
        <div className="page-title-box">
          <h4 className="page-title">Transaction History</h4>
        </div>
        {requestState.isPending ? (
          <Loading />
        ) : (
          <Fade>
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Status</h4>
                <Table responsive borderless size="sm">
                  <tbody>
                    <tr>
                      <td>Submitted to Bank</td>
                      <td>
                        {financialTransactionDetail.createdAt && formatDateGreg(financialTransactionDetail.createdAt)}
                      </td>
                    </tr>
                    <tr>
                      <td>Process Date</td>
                      <td>
                        {financialTransactionDetail.processDate &&
                          formatDateGreg(financialTransactionDetail.processDate)}
                      </td>
                    </tr>
                    <ApprovalRow {...financialTransactionDetail} />
                    <SettlementRow {...financialTransactionDetail} />
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Info</h4>
                <Table responsive borderless size="sm">
                  <tbody>
                    <tr>
                      <td>Status</td>
                      <td>{financialTransactionDetail.status}</td>
                    </tr>
                    <tr>
                      <td>Earliest Approval Date</td>
                      <td>
                        {financialTransactionDetail.earliestApprovalDate &&
                          formatDateGreg(financialTransactionDetail.earliestApprovalDate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>${financialTransactionDetail.amount}</td>
                    </tr>
                    <tr>
                      <td>Comment</td>
                      <td>{financialTransactionDetail.comment}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Bank Account</h4>
                <Table responsive borderless size="sm">
                  <tbody>
                    {BANK_FIELDS.filter((field) => financialTransactionDetail?.bankAccount?.[field]).map((field, i) => (
                      <tr key={i}>
                        <td>{titleify(field)}</td>
                        <td>{financialTransactionDetail.bankAccount[field]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Fade>
        )}
      </Col>
    </Row>
  )
}
