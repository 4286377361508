import React from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import { getNotifications, hideNotification, Notification } from 'api'
import { getEntities, justReplace } from 'app/selectors'
import { UncontrolledAlert } from 'reactstrap'

export const NotificationsManager = () => {
  const notifications: Notification[] = useSelector((state) => getEntities(state).notifications) || []

  const [requestState, doRequest] = useRequest(
    getNotifications({
      force: true,
      transform: (response) => ({ notifications: response.records }),
      update: { notifications: justReplace },
    }),
  )

  const [mutationState, doMutation] = useMutation((id: string) => hideNotification({ notification: { id: id } }))

  const onDismiss = (id: string | undefined) => {
    if (!id) doRequest()
    else doMutation(id)?.then(() => doRequest())
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 80,
        right: 20,
        marginLeft: 20,
        zIndex: 99999,
      }}
    >
      {notifications.map((notification, i) => (
        <UncontrolledAlert key={i} color="info" toggle={() => onDismiss(notification.id)}>
          {notification.message}
        </UncontrolledAlert>
      ))}
    </div>
  )
}
