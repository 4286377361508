import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { InvoicesTable } from './invoicesTable'

export const InvoicesView = () => {
  return (
    <div>
      <div className="page-title-box">
        <h4 className="page-title">Invoices</h4>
      </div>
      <Card>
        <CardBody>
          <InvoicesTable />
        </CardBody>
      </Card>
    </div>
  )
}
