export const justReplace = (_, newValue) => newValue
export const justUpdate = (prevValue, newValue) => {
  return { ...prevValue, ...newValue }
}
export const getIsLoggedIn = (state) => state.auth.isLoggedIn
export const getQueries = (state) => state.queries
export const getEntities = (state) => state.entities
export const getClients = (state) => getEntities(state).clients
export const getActiveClient = (state) => getEntities(state).activeClient
export const getCustomers = (state) => getEntities(state).customers
export const getCustomersCount = (state) => getEntities(state).customersCount
export const getCustomersBeforeSearchCount = (state) => getEntities(state).customersBeforeSearchCount
export const getCustomer = (state) => getEntities(state).customer
export const getUsers = (state) => getEntities(state).users
export const getUsersCount = (state) => getEntities(state).usersCount
export const getActiveUser = (state) => getEntities(state).account

export const getOwnerTypes = (state) => getEntities(state).constants?.ownerTypes
export const getTransactionQuestionOptions = (state) => getEntities(state).constants?.transactionQuestionOptions
export const getClientAddressCategories = (state) => getEntities(state).constants?.client?.addressCategories
export const getClientAddressTypes = (state) => getEntities(state).constants?.client?.addressTypes
export const getBusinessTypes = (state) => getEntities(state).constants?.client?.businessTypes
export const getTransactionStatuses = (state) => getEntities(state).constants?.transactionStatuses
export const getBusinessCategories = (state) => getEntities(state).constants?.client?.businessCategories
export const getTransactionFrequencies = (state) => getEntities(state).constants?.transactionFrequencies
export const getProvinces = (state) => getEntities(state).constants?.provinces
export const getStates = (state) => getEntities(state).constants?.states
export const getCustomerActions = (state) => getEntities(state).constants?.customerActions
