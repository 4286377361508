// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    ClientOnboarding,
    ClientOnboardingFromJSON,
    ClientOnboardingToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetClientsClientOnboarding200Response
 */
export interface GetClientsClientOnboarding200Response  {
    /**
     * 
     * @type {ClientOnboarding}
     * @memberof GetClientsClientOnboarding200Response
     */
    clientOnboarding?: ClientOnboarding;
    /**
     * 
     * @type {Client}
     * @memberof GetClientsClientOnboarding200Response
     */
    client?: Client;
    /**
     * 
     * @type {ClientOnboarding}
     * @memberof GetClientsClientOnboarding200Response
     */
    processingClientOnboarding?: ClientOnboarding;
}

export function GetClientsClientOnboarding200ResponseFromJSON(json: any): GetClientsClientOnboarding200Response {
    return {
        'clientOnboarding': !exists(json, 'client_onboarding') ? undefined : ClientOnboardingFromJSON(json['client_onboarding']),
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'processingClientOnboarding': !exists(json, 'processing_client_onboarding') ? undefined : ClientOnboardingFromJSON(json['processing_client_onboarding']),
    };
}

export function GetClientsClientOnboarding200ResponseToJSON(value?: GetClientsClientOnboarding200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client_onboarding': ClientOnboardingToJSON(value.clientOnboarding),
        'client': ClientToJSON(value.client),
        'processing_client_onboarding': ClientOnboardingToJSON(value.processingClientOnboarding),
    };
}


