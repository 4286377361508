import React, { useRef } from 'react'

type AccordionItemProps = {
  header: string | JSX.Element
  body: string | JSX.Element
  isOpen?: boolean
  btnOnClick?: () => void
  className?: string
}

export function AccordionItem({ header, body, isOpen, btnOnClick, className }: AccordionItemProps) {
  return (
    <div className={`card mb-0 ${isOpen ? 'active' : ''} ${className}`}>
      <div className="card-header pl-0" onClick={btnOnClick}>
        <div className="m-0 custom-accordion-title d-block py-1">
          {header}
          {isOpen ? <i className={`mdi mdi-minus float-right`} /> : <i className={`mdi mdi-plus float-right`} />}
        </div>
      </div>
      <div className={`collapse ${isOpen ? 'show' : ''}`}>
        <div className="card-body accordion-item-content">{body}</div>
      </div>
    </div>
  )
}
