// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailAuthorization
 */
export interface EmailAuthorization  {
    /**
     * 
     * @type {number}
     * @memberof EmailAuthorization
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailAuthorization
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAuthorization
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailAuthorization
     */
    authorizedDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof EmailAuthorization
     */
    customerId: number;
    /**
     * 
     * @type {Date}
     * @memberof EmailAuthorization
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmailAuthorization
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmailAuthorization
     */
    requestRecord?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAuthorization
     */
    transitNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAuthorization
     */
    institutionNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAuthorization
     */
    routingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAuthorization
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAuthorization
     */
    bankAccountType?: string;
}

export function EmailAuthorizationFromJSON(json: any): EmailAuthorization {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'email': json['email'],
        'authorizedDate': !exists(json, 'authorized_date') ? undefined : new Date(json['authorized_date']),
        'customerId': json['customer_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'requestRecord': !exists(json, 'request_record') ? undefined : json['request_record'],
        'transitNumber': !exists(json, 'transit_number') ? undefined : json['transit_number'],
        'institutionNumber': !exists(json, 'institution_number') ? undefined : json['institution_number'],
        'routingNumber': !exists(json, 'routing_number') ? undefined : json['routing_number'],
        'accountNumber': !exists(json, 'account_number') ? undefined : json['account_number'],
        'bankAccountType': !exists(json, 'bank_account_type') ? undefined : json['bank_account_type'],
    };
}

export function EmailAuthorizationToJSON(value?: EmailAuthorization): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'authorized_date': value.authorizedDate === undefined ? undefined : value.authorizedDate.toISOString(),
        'customer_id': value.customerId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'request_record': value.requestRecord,
        'transit_number': value.transitNumber,
        'institution_number': value.institutionNumber,
        'routing_number': value.routingNumber,
        'account_number': value.accountNumber,
        'bank_account_type': value.bankAccountType,
    };
}


