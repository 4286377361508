import React, { ReactElement } from 'react'
import { AvField } from 'availity-reactstrap-validation'

type avFieldType = typeof AvField
export interface PrependAvFieldProps extends avFieldType {
  children?: any
  name: string
  validationEvent?: any
  validate?: {}
  value?: any
  defaultValue?: any
  trueValue?: any
  falseValue?: any
  checked?: boolean
  defaultChecked?: boolean
  state?: boolean
  type?: string
  multiple?: boolean
  disabled?: boolean
  readOnly?: boolean
  onKeyUp?: Function
  onInput?: Function
  onFocus?: Function
  onBlur?: Function
  onChange?: Function
  onReset?: Function
  onWheel?: Function
  hidden?: boolean
  tag?: any
  labelClass?: string
  label: string | ReactElement
  groupAttrs?: { className: string; style?: {} | undefined }
  placeholder?: string
  required?: boolean
  className?: string
  appendBottom?: any
  appendBottomLightBackground?: boolean
  formControlNarrow?: boolean
  inputMode?: string
  autoComplete?: string
  errorMessage?: string
}

export const PrependAvField = (props: PrependAvFieldProps) => {
  let filteredProps = { ...props }
  filteredProps.label = (
    <span className="input-group-text no-background" hidden={props.hidden}>
      {props.label}
    </span>
  )
  filteredProps.labelClass = `${props.labelClass} input-group-prepend prepend-icon ${
    props.formControlNarrow && 'form-control-narrow'
  } `
  filteredProps.groupAttrs = {
    ...filteredProps.groupAttrs,
    className: `${filteredProps.groupAttrs?.className} prepend-input-group`,
  }
  if (props.formControlNarrow) {
    filteredProps.className += ' form-control-narrow'
  }

  if (props.appendBottom) {
    filteredProps.className += ' append-top'
    filteredProps.groupAttrs = {
      ...filteredProps.groupAttrs,
      className: `${filteredProps.groupAttrs?.className} mb-0`,
    }
    return (
      <div className="prepend-av-field">
        <AvField {...filteredProps}>{props.children}</AvField>
        <div className={`append-bottom ${props.appendBottomLightBackground && 'light-background'} input-group-text`}>
          {props.appendBottom}
        </div>
      </div>
    )
  } else {
    return <AvField {...filteredProps}>{props.children}</AvField>
  }
}

export default PrependAvField
