import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest, useMutation } from 'redux-query-react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useHistory } from 'react-router-dom'
import { Alert, Button, Card, CardBody, Col, Fade, Row } from 'reactstrap'

import { User, searchUsers, addUser, updateUser } from 'api'
import { ADMIN_ROLES, ROLES } from 'app/constants'
import { ROLE_PERMISSIONS } from 'app/constants'
import { RootState } from 'app/rootReducer'
import { getActiveClient, getActiveUser, justReplace } from 'app/selectors'
import { CenterContent } from 'components/CenterContent'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { Loading } from 'components/Loading'
import { PermissionToggle } from './permissionToggle'
import { useTranslation } from 'react-i18next'

export interface UserFormProps {
  clientId?: number
  userId?: number
}

export const UserForm = ({ userId }: UserFormProps) => {
  const { t } = useTranslation()
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const history = useHistory()
  const activeClient = useSelector(getActiveClient) || {}
  const activeUser = useSelector(getActiveUser)
  const newUser: User = {
    firstName: '',
    lastName: '',
    email: '',
    clientId: activeClient?.id,
    role: '',
    rolePermission: {},
  }
  const [selectedPermissions, setSelectedPermissions] = useState({})
  const [selectedRole, setSelectedRole] = useState('client_read_only')
  const user: User = useSelector((state: RootState) => (userId ? state.entities.user : newUser)) || {}
  const isExistingPrimaryContact = userId && user.role === 'client_primary'
  const formRef = useRef<AvForm>()

  const setSelectedState = () => {
    if (userId) {
      if (user && user.role !== selectedRole) {
        setSelectedRole(user.role || '')
      }
      if (user && user.rolePermission !== selectedPermissions) {
        setSelectedPermissions(user.rolePermission || {})
      }
    }
  }
  useEffect(setSelectedState, [user])

  const [requestState, _doRequest] = useRequest(
    !userId
      ? null
      : searchUsers(
          {
            clientId: activeClient?.id,
            userId: userId,
          },
          {
            force: true,
            transform: (response) => ({ user: response.records?.[0] }),
            update: { user: justReplace },
          },
        ),
  )

  const [{ isPending }, saveUser] = useMutation((formUser: User) => {
    if (user?.roleId) {
      return updateUser({
        id: user?.roleId,
        user: formUser,
      })
    }
    formUser.redirectUrl = `${window.location.protocol}//${window.location.host}/reset_password`
    return addUser({
      user: formUser,
    })
  })

  function handleValidSubmit(event, user) {
    const form = event.target
    let errors = []
    user.clientId = activeClient.id
    if (isExistingPrimaryContact) {
      user.role = 'client_primary'
    } else if (form.querySelector(`input[name="client_admin"]`).checked) {
      user.role = 'client_admin'
    } else if (form.querySelector(`input[name="client_read_only"]`).checked) {
      user.role = 'client_read_only'
    } else if (form.querySelector(`input[name="client_custom"]`).checked) {
      user.role = 'client_custom'
      user.rolePermission = {}
      let hasPermission = false
      ROLE_PERMISSIONS.forEach((permission) => {
        let checked = form.querySelector(`input[name="${permission.value}"]`).checked
        user.rolePermission[permission.value] = checked
        if (checked) {
          hasPermission = true
        }
      })
      if (!hasPermission) {
        errors['base'] = ['Select at least one custom permission.']
      }
    } else {
      errors['base'] = ["Role can't be blank"]
    }
    if (errors['base']) {
      setServerErrors((errors as unknown) as Errors)
      window.scrollTo(0, 0)
      return
    } else {
      saveUser(user)?.then((response) => {
        if (response?.body?.status === 200) {
          history.push('/client/settings/users')
          if (activeUser.email === user.email) {
            window.location.reload()
          }
        } else {
          setServerErrors(response?.body?.messages || [])
          window.scrollTo(0, 0)
        }
      })
    }
  }

  const handleRoleToggle = (event) => {
    const roleName = event.target.name
    if (event.target.checked) {
      setSelectedRole(roleName)
    }
    handleFieldChange(event)
  }

  const handlePermissionToggled = (event) => {
    const permissionName = event.target.name

    let newPermissions = { ...selectedPermissions }
    newPermissions[permissionName] = event.target.checked

    if (permissionName === 'manageIntegrations' && event.target.checked) {
      newPermissions['cudTransactionSchedules'] = true
      newPermissions['cudCustomers'] = true
    } else if (
      newPermissions['manageIntegrations'] &&
      (!newPermissions['cudCustomers'] || !newPermissions['cudTransactionSchedules'])
    ) {
      newPermissions['manageIntegrations'] = false
    }

    if (permissionName === 'customerExport' && event.target.checked) {
      newPermissions['readUnmaskedBankNumbers'] = true
    } else if (newPermissions['customerExport'] && !newPermissions['readUnmaskedBankNumbers']) {
      newPermissions['customerExport'] = false
    }

    setSelectedPermissions(newPermissions)
    handleFieldChange(event)
  }

  const title = userId ? 'Edit' : 'Add a new'

  return (
    <CenterContent>
      <div className="page-title-box">
        <h4 className="page-title">{title} user</h4>
      </div>
      {userId && (!requestState.isFinished || requestState.isPending) ? (
        <Loading />
      ) : (
        <Fade>
          <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
          <AvForm disabled={isPending} onValidSubmit={handleValidSubmit} model={user} ref={formRef}>
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Details</h4>
                <Row>
                  <Col>
                    <AvField
                      validate={{
                        async: validate,
                        required: {
                          value: true,
                          errorMessage: t('required_field'),
                        },
                      }}
                      onChange={handleFieldChange}
                      name="firstName"
                      label="First Name"
                      type="text"
                      className="mb-2"
                      placeholder="First Name"
                      required={true}
                      disabled={!!userId && !!user.firstName}
                    />
                  </Col>
                  <Col>
                    <AvField
                      validate={{
                        async: validate,
                        required: {
                          value: true,
                          errorMessage: t('required_field'),
                        },
                      }}
                      onChange={handleFieldChange}
                      name="lastName"
                      label="Last Name"
                      type="text"
                      className="mb-2"
                      placeholder="Last Name"
                      required={true}
                      disabled={!!userId && !!user.lastName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvField
                      validate={{
                        async: validate,
                        email: {
                          value: true,
                          errorMessage: 'Please enter a valid email address',
                        },
                        required: {
                          value: !userId,
                          errorMessage: t('required_field'),
                        },
                      }}
                      onChange={handleFieldChange}
                      name="email"
                      label="Email"
                      type="text"
                      className="mb-2"
                      placeholder="ex@mple.com"
                      required={!userId}
                      disabled={!!userId}
                    />
                  </Col>
                  <Col>
                    <AvField
                      validate={{
                        async: validate,
                        required: {
                          value: ADMIN_ROLES.includes(selectedRole),
                          errorMessage: t('required_field'),
                        },
                      }}
                      onChange={handleFieldChange}
                      name="mobilePhone"
                      label="Mobile Phone"
                      type="text"
                      className="mb-2"
                      placeholder="234 567 8901"
                      required={ADMIN_ROLES.includes(selectedRole)}
                      disabled={!!userId && !!user.mobilePhone}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvField
                      validate={{
                        async: validate,
                        required: {
                          value: ADMIN_ROLES.includes(selectedRole),
                          errorMessage: t('required_field'),
                        },
                      }}
                      onChange={handleFieldChange}
                      name="position"
                      label="Position"
                      type="text"
                      className="mb-2"
                      placeholder="Ex: Bookkeeper"
                      required={ADMIN_ROLES.includes(selectedRole)}
                    />
                  </Col>
                  <Col></Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">User Permissions</h4>
                {isExistingPrimaryContact && (
                  <Alert color="info" className="alert-with-icon text-info p-1 pl-2 pr-2">
                    <i className="mdi mdi-information-outline mr-1" />
                    The primary contact user cannot be updated. Please transfer primary contact role first.
                  </Alert>
                )}
                <PermissionToggle
                  onChange={handleRoleToggle}
                  name="client_read_only"
                  labelHeading={ROLES.find((role) => role.value === 'client_read_only')?.label!}
                  labelDescription={ROLES.find((role) => role.value === 'client_read_only')?.description}
                  checked={selectedRole === 'client_read_only'}
                  disabled={isExistingPrimaryContact as boolean}
                />
                <hr className="mt-0 mb-2" />
                <PermissionToggle
                  onChange={handleRoleToggle}
                  name="client_admin"
                  labelHeading={ROLES.find((role) => role.value === 'client_admin')?.label!}
                  labelDescription={ROLES.find((role) => role.value === 'client_admin')?.description}
                  checked={selectedRole === 'client_admin'}
                  disabled={isExistingPrimaryContact as boolean}
                />
                <hr className="mt-0 mb-2" />
                <PermissionToggle
                  onChange={handleRoleToggle}
                  name="client_custom"
                  labelHeading={ROLES.find((role) => role.value === 'client_custom')?.label!}
                  labelDescription={ROLES.find((role) => role.value === 'client_custom')?.description}
                  checked={selectedRole === 'client_custom'}
                  disabled={isExistingPrimaryContact as boolean}
                />

                {selectedRole === 'client_custom' && (
                  <div>
                    {ROLE_PERMISSIONS.map((permission, i) => (
                      <div key={i} className="pl-4 pr-4 mt-1">
                        <PermissionToggle
                          onChange={handlePermissionToggled}
                          name={permission.value}
                          labelHeading={permission.label!}
                          labelDescription={permission.description}
                          checked={selectedPermissions[permission.value]}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>

            <div className="text-right mb-3">
              <Button
                disabled={isPending}
                onClick={() => history.goBack()}
                className="btn-rotessa-secondary min-width mr-1"
              >
                Cancel
              </Button>
              <Button disabled={isPending} color="primary">
                Submit
              </Button>
            </div>
          </AvForm>
        </Fade>
      )}
    </CenterContent>
  )
}
