// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Role
 */
export interface Role  {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    role?: string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    clientId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Role
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Role
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    dailySummaryEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    integrationDisconnectEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    newAuthorizationEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    microdepositSentEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    microdepositVerifiedEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    microdepositRejectedEmail?: boolean;
}

export function RoleFromJSON(json: any): Role {
    return {
        'id': json['id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'dailySummaryEmail': !exists(json, 'daily_summary_email') ? undefined : json['daily_summary_email'],
        'integrationDisconnectEmail': !exists(json, 'integration_disconnect_email') ? undefined : json['integration_disconnect_email'],
        'newAuthorizationEmail': !exists(json, 'new_authorization_email') ? undefined : json['new_authorization_email'],
        'microdepositSentEmail': !exists(json, 'microdeposit_sent_email') ? undefined : json['microdeposit_sent_email'],
        'microdepositVerifiedEmail': !exists(json, 'microdeposit_verified_email') ? undefined : json['microdeposit_verified_email'],
        'microdepositRejectedEmail': !exists(json, 'microdeposit_rejected_email') ? undefined : json['microdeposit_rejected_email'],
    };
}

export function RoleToJSON(value?: Role): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'role': value.role,
        'user_id': value.userId,
        'client_id': value.clientId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'daily_summary_email': value.dailySummaryEmail,
        'integration_disconnect_email': value.integrationDisconnectEmail,
        'new_authorization_email': value.newAuthorizationEmail,
        'microdeposit_sent_email': value.microdepositSentEmail,
        'microdeposit_verified_email': value.microdepositVerifiedEmail,
        'microdeposit_rejected_email': value.microdepositRejectedEmail,
    };
}


