import React from 'react'
import { Loading } from './Loading'

export const LoadingMask = () => (
  <div>
    <div
      style={{
        position: 'absolute',
        zIndex: 9999,
        height: 'calc(100% - 3rem)',
        width: 'calc(100% - 3rem)',
        background: 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <Loading />
    </div>
  </div>
)

export const LoadingMaskCentered = () => (
  <div>
    <div
      style={{
        position: 'absolute',
        zIndex: 9999,
        height: '100%',
        width: '100%',
        background: 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <Loading />
    </div>
  </div>
)

export const LoadingMaskNoBackground = () => (
  <div>
    <div
      style={{
        position: 'absolute',
        zIndex: 9999,
        height: '100%',
        width: '100%',
      }}
    >
      <Loading />
    </div>
  </div>
)
