import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col, Fade, Label, ButtonGroup } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { InfoPopover } from 'components/InfoPopover'
import { getEntities } from 'app/selectors'
import { CenterContentNarrow } from 'components/CenterContent'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { updateEntities } from 'redux-query'
import { TitleBar } from './titleBar'

export interface CustomerAuthorizationInfoProps {
  clientId?: number
}

export const CustomerAuthorizationInfo = (props: CustomerAuthorizationInfoProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const [isPending, setIsPending] = useState(false)
  const [customerType, setCustomerType] = useState('')
  const [authorizationType, setAuthorizationType] = useState('')
  const [touched, setTouched] = useState(false)
  const formRef = useRef<AvForm>()

  useSelector((state) => getEntities(state).partialCustomer)

  const onSubmit = (values) => {
    setTouched(true)
    if (authorizationType !== '' && customerType !== '') {
      dispatch(
        updateEntities({
          partialCustomer: (_oldValue: any) => {
            let result = { ..._oldValue, ...values, customerType, authorizationType }
            return result
          },
        }),
      )
      history.push('/client/customers/new/bank-information')
    }
  }

  return (
    <Fade>
      <TitleBar title="Type of Authorization Agreement" />
      <div className="mb-2 mt-2">
        <CenterContentNarrow>
          <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
          <AvForm
            disabled={isPending}
            onInvalidSubmit={() => setTouched(true)}
            onValidSubmit={(_event, values) => onSubmit(values)}
            ref={formRef}
          >
            <Card>
              <CardBody>
                <div className="pl-1">
                  <Row>
                    <Col>
                      <AvField
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Please enter a valid customer name',
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9àâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ _'/@.&\-,()+?#!~*;:]+$/,
                            errorMessage: 'Invalid characters in customer name',
                          },
                          async: validate,
                        }}
                        onChange={handleFieldChange}
                        name="name"
                        label="Customer Name"
                        type="text"
                        placeholder="John Smith"
                        style={{ maxWidth: '98%' }}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className={'mt-4' + !customerType && touched ? ' danger-title' : ''}>
                        Type of authorization agreement?
                      </Label>
                      <InfoPopover popoverText="A personal authorization is made by an individual, customer, or donor. A business authorization is made on behalf of a business entity." />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ButtonGroup className="w-100 justify-content-center">
                        <Row className="w-100">
                          <Col className="pl-0">
                            <Button
                              type="button"
                              color="primary"
                              className="btn-new-customer-actions rounded"
                              onClick={() => setCustomerType('Personal')}
                              active={customerType === 'Personal'}
                              name="personalButton"
                            >
                              Personal
                            </Button>
                          </Col>
                          <Col className="pr-1">
                            <Button
                              type="button"
                              className="btn-new-customer-actions rounded"
                              color="primary"
                              onClick={() => setCustomerType('Business')}
                              active={customerType === 'Business'}
                              name="businessButton"
                            >
                              Business
                            </Button>
                          </Col>
                        </Row>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {!customerType && touched && (
                        <>
                          <Label className="danger-message"> Please enter an authorization agreement</Label>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className={'mt-4' + !customerType && touched ? ' danger-title' : ''}>
                        How did you collect the authorization agreement?
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ButtonGroup className="w-100 justify-content-center">
                        <Row className="w-100">
                          <Col className="pl-0">
                            <Button
                              color="primary"
                              className="btn-new-customer-actions rounded"
                              onClick={() => setAuthorizationType('In Person')}
                              active={authorizationType === 'In Person'}
                            >
                              In-Person
                            </Button>
                          </Col>
                          <Col className="pr-1">
                            <Button
                              color="primary"
                              className="btn-new-customer-actions rounded"
                              onClick={() => setAuthorizationType('Online')}
                              active={authorizationType === 'Online'}
                            >
                              Online
                            </Button>
                          </Col>
                        </Row>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {!authorizationType && touched && (
                        <>
                          <Label className="danger-message">
                            Please select the authorization agreement collection type
                          </Label>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col>
                      <div className="text-right">
                        <div className="inline-container">
                          <Button disabled={isPending} color="primary" className="mt-3 mr-1 mb-2" type="submit">
                            Next
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </AvForm>
        </CenterContentNarrow>
      </div>
    </Fade>
  )
}
