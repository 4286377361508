import React, { useState, useEffect } from 'react'
import { useRequest } from 'redux-query-react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { IntegrationIntro } from './intro'
import { getIntegrations } from 'api'
import { getActiveClient, getEntities } from 'app/selectors'
import { Fade } from 'reactstrap'
import { integrationIcon, integrationName } from 'components/Integrations'
import { Notification } from 'components/Notification'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { IntegrationClearingAccountForm } from './integrationClearingAccountForm'
import { IntegrationView } from './view'
import { LoadingMaskCentered } from 'components/LoadingMask'

export const IntegrationManager = () => {
  const activeClient = useSelector(getActiveClient)
  const integration = useSelector((state) => getEntities(state).integration) || {}
  const [notificationMessage, setNotificationMessage] = useState('')
  useClearEntitiesOnUnmount(['integrations'])
  const newConnectionStatus = useSelector((state) => getEntities(state).newConnectionStatus) || {}

  const integrationsPages = [
    {
      path: '/client/integrations',
      component: IntegrationView,
    },
    {
      path: '/client/integrations/set-clearing-account',
      component: IntegrationClearingAccountForm,
    },
  ]

  const [requestState, doRequest] = useRequest(
    !activeClient
      ? null
      : getIntegrations(
          { clientId: activeClient.id },
          {
            force: true,
            transform: (body) => {
              return {
                integration: {
                  ...body,
                  icon: integrationIcon(body.integrationName),
                  name: integrationName(body.integrationName),
                },
              }
            },
            update: { integration: (_, newValue) => newValue },
          },
        ),
  )

  useEffect(() => {
    doRequest()
  }, [])

  const displayNotification = (message: string) => {
    setNotificationMessage(message)
  }

  let content
  if (requestState.isFinished) {
    if (integration.integrationStatus === 'connected') {
      content = (
        <Switch>
          {integrationsPages.map((page, i) => (
            <Route key={i} exact path={page.path}>
              <page.component
                integration={integration}
                doRequest={doRequest}
                displayNotification={displayNotification}
              />
            </Route>
          ))}
        </Switch>
      )
    } else if (integration.integrationStatus === 'not connected') {
      content = (
        <IntegrationIntro
          quickbooksOauthUrl={integration.quickbooksOauthUrl}
          xeroOauthUrl={integration.xeroOauthUrl}
          newConnectionStatus={newConnectionStatus}
        />
      )
    }
  } else {
    content = <LoadingMaskCentered />
  }

  return (
    <>
      <Fade in={notificationMessage !== ''}>
        <Notification text={notificationMessage} color="success" />
      </Fade>
      {content}
    </>
  )
}
