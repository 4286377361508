import React, { useState } from 'react'

import { Button, Col, Row } from 'reactstrap'
import { FlinksConnect } from 'features/flinks/FlinksConnect'
import { useSelector } from 'react-redux'
import { getEntities } from 'app/selectors'
import { useWindowSize } from 'hooks/useWindowSize'
import flinksPreview from 'assets/images/flinks_preview.png'
import { ExternalLink } from 'components/ExternalLink'
import { useTranslation, Trans } from 'react-i18next'

export interface AuthBankInfoProps {
  readOnly?: boolean
  onSubmit: Function
  validate: Function
  handleFieldChange: Function
}

export const DonorAuthBankInfo = (props: AuthBankInfoProps) => {
  const windowSize = useWindowSize()
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo)
  const [flinksAccepted, setFlinksAccepted] = useState(false)
  const [flinksComplete, setFlinksComplete] = useState(false)
  const { t } = useTranslation()

  const onComplete = () => {
    setFlinksComplete(true)
    props.onSubmit()
  }

  if (flinksComplete) {
    return null
  }

  if (!flinksAccepted) {
    return (
      <>
        <div className="text-center text-almost-black">
          <p style={{ fontSize: 24 }}>
            <Trans i18nKey="flinks.header">
              We use <b>Flinks</b> to instantly verify your bank information
            </Trans>
          </p>
        </div>
        <Row className="ml-2 mr-2 mb-2">
          <Col xs="auto" className="p-0">
            <i className="mdi mdi-lock mdi-24px" />
          </Col>
          <Col>
            <span className="text-almost-black flinks-label">
              <b>{t('flinks.secure')}</b>
            </span>
            <br />
            {t('flinks.secure_text')}
          </Col>
        </Row>
        <Row className="ml-2 mr-2 mb-2">
          <Col xs="auto" className="p-0">
            <i className="mdi mdi-eye-off mdi-24px" />
          </Col>
          <Col>
            <span className="text-almost-black flinks-label">
              <b>{t('flinks.private')}</b>
            </span>
            <br />
            {t('flinks.private_text')}
          </Col>
        </Row>
        <div className="text-center">
          <p style={{ lineHeight: '100%', color: '#bbb' }}>
            <small>
              {t('flinks.selecting')}
              <br />
              <ExternalLink to={t('flinks.agree_link')}>{t('flinks.agree_link_text')}</ExternalLink>
            </small>
            <br />
          </p>
          <Button className="continue-btn" color="primary" onClick={() => setFlinksAccepted(true)}>
            {t('authorization.donation.flinks.connect')} <i className="mdi mdi-arrow-right-thick" />
          </Button>
        </div>
      </>
    )
  }
  return (
    <>
      {props.readOnly ? (
        <img src={flinksPreview} width="100%" className="mb-2" alt="" />
      ) : (
        <>
          <FlinksConnect
            countryCode={donorAuthInfo?.client?.countryCode}
            tag={donorAuthInfo?.id}
            height={Math.max(500, windowSize.height - 700)}
            onStep={() => {}}
            onComplete={onComplete}
            type="Donation"
          />
        </>
      )}
      <p className="text-center mt-3 flinks-way-out">
        {t('authorization.donation.flinks.alt_flow_intro', { client_name: donorAuthInfo?.client?.businessName })}
        {donorAuthInfo?.email &&
          t('authorization.donation.flinks.alt_flow_client_email', { client_email: donorAuthInfo?.email })}
        {t('authorization.donation.flinks.alt_flow_end')}
      </p>
    </>
  )
}
