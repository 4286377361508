import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'

import SimpleMDEEditor, { SimpleMDEEditorProps } from 'react-simplemde-editor'
import 'easymde/dist/easymde.min.css'
import DOMPurify from 'dompurify'

export const MdEditor = (props: SimpleMDEEditorProps) => {
  const options: SimpleMDEEditorProps = {
    options: {
      hideIcons: ['code', 'image', 'table', 'side-by-side', 'fullscreen'],
      uploadImage: false,
      status: false,
      spellChecker: false,
    },
    ...props,
  }
  return <SimpleMDEEditor {...options} />
}

export interface MyEditorProps {
  onChange: Function
  value: string
  withBorder?: boolean
}

export const MyEditor = (props: MyEditorProps) => {
  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(DOMPurify.sanitize(props.value) || '')
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    return EditorState.createWithContent(contentState)
  })

  const handleChange = (newEditorState) => {
    const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()))
    props.onChange(html)
    setEditorState(newEditorState)
  }

  return (
    <>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleChange}
        toolbar={{
          options: ['inline', 'link', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
        }}
        editorClassName={props.withBorder ? 'editor-border' : undefined}
      />
    </>
  )
}
