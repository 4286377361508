import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, CardBody, Row, Col, Fade } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { getActiveClient } from 'app/selectors'
import { CenterContentNarrow } from 'components/CenterContent'
import { StateProvinceSelect } from 'components/ProvinceSelect'
import { PostalCodeField } from 'components/PostalCodeField'
import { useMutation, useRequest } from 'redux-query-react'
import { Customer, updateCustomer, searchCustomers } from 'api'
import { TitleBar } from './titleBar'
import { useSetBreadcrumbTitle } from 'hooks/useSetBreadcrumbTitle'
import { getCustomer } from 'app/selectors'
import { validatePhone } from 'utilities'

export interface CustomerOptionalInfoProps {
  customerId: number
}

export const CustomerOptionalInfo = ({ customerId }: CustomerOptionalInfoProps) => {
  const history = useHistory()
  const [isPending, setIsPending] = useState(false)
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const activeClient = useSelector(getActiveClient) || {}
  const customer = useSelector(getCustomer) || {}
  const formRef = useRef<AvForm>()

  const [requestState, doRequest] = useRequest(
    searchCustomers(
      {
        clientId: activeClient?.id || 0,
        customerId: customerId || 0,
      },
      {
        force: true,
        transform: (response) => {
          return { customer: response.records?.[0] }
        },
        update: {
          customer: (_, newValue) => newValue,
        },
      },
    ),
  )

  if (requestState.isFinished && !customer.id) {
    history.push('/client/customers/new/authorization-information')
  }

  useSetBreadcrumbTitle(customerId?.toString(), customer.name)

  const onSubmit = (values) => {
    setIsPending(true)
    doSaveCustomer(values)?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      if (response?.body?.status === 200 && response?.body?.id) {
        history.push(`/client/customers/${response.body.id}`)
      } else {
        setIsPending(false)
      }
    })
  }

  const [saveCustomerState, doSaveCustomer] = useMutation((customer: Customer) => {
    if (customer.customIdentifier === '') {
      customer.customIdentifier = undefined
    }
    return updateCustomer({
      id: customerId,
      customer: customer,
    })
  })

  return (
    <Fade>
      <TitleBar
        title="Optional Information"
        subtitle="This information is sometimes included in a paper or online authorization form. It is optional and not required to create a customer"
      />
      <CenterContentNarrow>
        <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
        <AvForm disabled={isPending} onValidSubmit={(_event, values) => onSubmit(values)} ref={formRef}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <AvField
                    validate={{
                      async: validate,
                      email: {
                        value: true,
                        errorMessage: 'Please enter a valid email address',
                      },
                    }}
                    onChange={handleFieldChange}
                    name="email"
                    label="Email"
                    type="text"
                    placeholder="john@smith.com"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvField
                    validate={{ tel: validatePhone, async: validate }}
                    onChange={handleFieldChange}
                    name="phone"
                    label="Phone"
                    placeholder="10 digits"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvField
                    validate={{ async: validate }}
                    onChange={handleFieldChange}
                    name="addressesAttributes[0].address1"
                    label="Address"
                    type="text"
                    placeholder="123 Main St."
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <AvField
                    validate={{ async: validate }}
                    onChange={handleFieldChange}
                    name="addressesAttributes[0].city"
                    label="City"
                    type="text"
                    placeholder="Toronto"
                  />
                </Col>
                <Col lg={4}>
                  <StateProvinceSelect
                    name="addressesAttributes[0].provinceCode"
                    countryCode={activeClient.countryCode}
                    serverErrors={serverErrors}
                  />
                </Col>
                <Col lg={4}>
                  <PostalCodeField
                    name="addressesAttributes[0].postalCode"
                    countryCode={activeClient.countryCode}
                    serverErrors={serverErrors}
                    placeholder="A1A A1A"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvField
                    validate={{
                      pattern: {
                        value: /^[a-zA-Z0-9àâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ _'/@.&\-,()+?#!~*;:]+$/,
                        errorMessage: 'Invalid characters in custom identifier',
                      },
                      async: validate,
                    }}
                    onChange={handleFieldChange}
                    name="customIdentifier"
                    label="Custom Identifier"
                    placeholder="For Integrations"
                    type="text"
                    groupAttrs={{ className: 'mb-1' }}
                  />
                  <p className="mb-3" style={{ lineHeight: 1 }}>
                    <small>
                      The Customer Identifier is unique to this customer. If using an integration, ensure that this is
                      an identical match to their name in your accounting software.
                    </small>
                  </p>
                </Col>
              </Row>
              <div className="text-right">
                <div className="inline-container">
                  <Button disabled={isPending} color="primary" className="ml-1 mb-2">
                    Create Customer
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </AvForm>
      </CenterContentNarrow>
    </Fade>
  )
}
