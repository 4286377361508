import React from 'react'
import { Col, Row } from 'reactstrap'

export const CenterContent = ({ children }) => (
  <Row>
    <Col className={'col-xxl-4 offset-xxl-4'} xl={{ size: 6, offset: 3 }} lg={{ size: 8, offset: 2 }}>
      {children}
    </Col>
  </Row>
)

export const CenterContentMedium = ({ children }) => (
  <Row>
    <Col
      className={'col-xxl-4 offset-xxl-4'}
      xxl={{ size: 6, offset: 3 }}
      xl={{ size: 8, offset: 2 }}
      lg={{ size: 12, offset: 0 }}
    >
      {children}
    </Col>
  </Row>
)

export const CenterContentWide = ({ children }) => (
  <Row>
    <Col xl={{ size: 10, offset: 1 }} lg={{ size: 12, offset: 0 }}>
      {children}
    </Col>
  </Row>
)

export const CenterContentNarrow = ({ children }) => (
  <Row>
    <Col xl={{ size: 4, offset: 4 }} lg={{ size: 6, offset: 3 }}>
      {children}
    </Col>
  </Row>
)
