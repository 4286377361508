// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SettlementsTotal,
    SettlementsTotalFromJSON,
    SettlementsTotalToJSON,
} from './';

/**
 * 
 * @export
 * @interface Settlements
 */
export interface Settlements  {
    /**
     * 
     * @type {number}
     * @memberof Settlements
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Settlements
     */
    settlementDate?: string;
    /**
     * 
     * @type {SettlementsTotal}
     * @memberof Settlements
     */
    total?: SettlementsTotal;
    /**
     * 
     * @type {SettlementsTotal}
     * @memberof Settlements
     */
    approved?: SettlementsTotal;
    /**
     * 
     * @type {SettlementsTotal}
     * @memberof Settlements
     */
    chargeback?: SettlementsTotal;
    /**
     * 
     * @type {SettlementsTotal}
     * @memberof Settlements
     */
    declined?: SettlementsTotal;
    /**
     * 
     * @type {boolean}
     * @memberof Settlements
     */
    holdback?: boolean;
}

export function SettlementsFromJSON(json: any): Settlements {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'settlementDate': !exists(json, 'settlement_date') ? undefined : json['settlement_date'],
        'total': !exists(json, 'total') ? undefined : SettlementsTotalFromJSON(json['total']),
        'approved': !exists(json, 'approved') ? undefined : SettlementsTotalFromJSON(json['approved']),
        'chargeback': !exists(json, 'chargeback') ? undefined : SettlementsTotalFromJSON(json['chargeback']),
        'declined': !exists(json, 'declined') ? undefined : SettlementsTotalFromJSON(json['declined']),
        'holdback': !exists(json, 'holdback') ? undefined : json['holdback'],
    };
}

export function SettlementsToJSON(value?: Settlements): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'settlement_date': value.settlementDate,
        'total': SettlementsTotalToJSON(value.total),
        'approved': SettlementsTotalToJSON(value.approved),
        'chargeback': SettlementsTotalToJSON(value.chargeback),
        'declined': SettlementsTotalToJSON(value.declined),
        'holdback': value.holdback,
    };
}


