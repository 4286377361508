import React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { useRequest } from 'redux-query-react'

import { ProcessingAuthFormManager } from './processingAuthFormManager'
import { getProcessingAuthorizationDetails } from 'api'
import { getEntities, justReplace } from 'app/selectors'
import { Loading } from 'components/Loading'
import { useIsMobile } from 'hooks/useIsMobile'

export const ProcessingAuthFormPage = () => {
  const { code } = useParams() as any
  const isMobile = useIsMobile()
  const processingAuthInfo = useSelector((state) => getEntities(state).processingAuthInfo) || {}

  const [requestState, _doRequest] = useRequest(
    getProcessingAuthorizationDetails(
      { code: code },
      {
        force: true,
        transform: (body) => ({ processingAuthInfo: body }),
        update: { processingAuthInfo: justReplace },
      },
    ),
  )

  if (processingAuthInfo?.id && processingAuthInfo.id !== code) {
    return <Redirect to={`/authorize/${processingAuthInfo.id}`} />
  }

  return requestState.isPending ? (
    <Loading />
  ) : (
    <div className={isMobile ? '' : 'mt-4'}>
      <ProcessingAuthFormManager />
    </div>
  )
}
