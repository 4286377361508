import React from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { useTranslation } from 'react-i18next'
import { PrependAvField } from './PrependAvField'

export interface PostalCodeFieldProps {
  name: string
  countryCode?: string
  required?: boolean
  serverErrors?: Errors
  placeholder?: string
  placeholderLabel?: boolean
  examplePlaceholder?: boolean
  onChange?: Function
}

export const PostalCodeField = ({
  name,
  countryCode,
  required,
  serverErrors,
  placeholder,
  placeholderLabel = false,
  examplePlaceholder = false,
  onChange,
}: PostalCodeFieldProps) => {
  const { t } = useTranslation()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, (_) => null)
  const label = countryCode === 'US' ? t('contact.zip_code') : t('contact.postal_code')
  const handleOnChange = (event) => {
    handleFieldChange(event)
    onChange && onChange(event)
  }
  if (placeholderLabel && !placeholder) {
    placeholder = label
  } else if (examplePlaceholder) {
    placeholder = countryCode === 'US' ? 'Ex: 90210' : 'Ex: A1A 1A1'
  }
  return (
    <AvField
      name={name}
      label={placeholderLabel ? undefined : label}
      type="text"
      required={required}
      validate={{
        required: {
          value: required,
          errorMessage: t('required_field'),
        },
        async: validate,
      }}
      onChange={handleOnChange}
      placeholder={placeholder}
    />
  )
}

export const PrependPostalCodeField = ({ name, countryCode, required, serverErrors }: PostalCodeFieldProps) => {
  const { t } = useTranslation()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, (_) => null)
  const label = countryCode === 'US' ? t('contact.zip_code') : t('contact.postal_code')

  return (
    <PrependAvField
      name={name}
      label={<i className="mdi mdi-postage-stamp" />}
      placeholder={label}
      type="text"
      required={required}
      validate={{ async: validate }}
      onChange={handleFieldChange}
    />
  )
}
