// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionSchedule
 */
export interface TransactionSchedule  {
    /**
     * 
     * @type {number}
     * @memberof TransactionSchedule
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSchedule
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSchedule
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    frequency?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    processDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionSchedule
     */
    installments?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSchedule
     */
    completedInstallments?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSchedule
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSchedule
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSchedule
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    nextProcessDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    latestScheduledProcessDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    contractNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionSchedule
     */
    createdById?: number;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSchedule
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    secondProcessDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    apiReferenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    apiDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    apiService?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    apiPaymentReportedStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    zapierIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionSchedule
     */
    platformPayoutAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSchedule
     */
    platformId?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionSchedule
     */
    customIdentifier?: string;
}

export function TransactionScheduleFromJSON(json: any): TransactionSchedule {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customerId': json['customer_id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'processDate': !exists(json, 'process_date') ? undefined : json['process_date'],
        'endDate': !exists(json, 'end_date') ? undefined : json['end_date'],
        'installments': !exists(json, 'installments') ? undefined : json['installments'],
        'completedInstallments': !exists(json, 'completed_installments') ? undefined : json['completed_installments'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'nextProcessDate': !exists(json, 'next_process_date') ? undefined : json['next_process_date'],
        'latestScheduledProcessDate': !exists(json, 'latest_scheduled_process_date') ? undefined : json['latest_scheduled_process_date'],
        'contractNumber': !exists(json, 'contract_number') ? undefined : json['contract_number'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'deletedAt': !exists(json, 'deleted_at') ? undefined : new Date(json['deleted_at']),
        'secondProcessDate': !exists(json, 'second_process_date') ? undefined : json['second_process_date'],
        'apiReferenceNumber': !exists(json, 'api_reference_number') ? undefined : json['api_reference_number'],
        'apiDocumentId': !exists(json, 'api_document_id') ? undefined : json['api_document_id'],
        'apiService': !exists(json, 'api_service') ? undefined : json['api_service'],
        'apiPaymentReportedStatus': !exists(json, 'api_payment_reported_status') ? undefined : json['api_payment_reported_status'],
        'zapierIdentifier': !exists(json, 'zapier_identifier') ? undefined : json['zapier_identifier'],
        'platformPayoutAmount': !exists(json, 'platform_payout_amount') ? undefined : json['platform_payout_amount'],
        'platformId': !exists(json, 'platform_id') ? undefined : json['platform_id'],
        'customIdentifier': !exists(json, 'custom_identifier') ? undefined : json['custom_identifier'],
    };
}

export function TransactionScheduleToJSON(value?: TransactionSchedule): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'customer_id': value.customerId,
        'amount': value.amount,
        'frequency': value.frequency,
        'process_date': value.processDate,
        'end_date': value.endDate,
        'installments': value.installments,
        'completed_installments': value.completedInstallments,
        'comment': value.comment,
        'active': value.active,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'next_process_date': value.nextProcessDate,
        'latest_scheduled_process_date': value.latestScheduledProcessDate,
        'contract_number': value.contractNumber,
        'created_by_id': value.createdById,
        'deleted_at': value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
        'second_process_date': value.secondProcessDate,
        'api_reference_number': value.apiReferenceNumber,
        'api_document_id': value.apiDocumentId,
        'api_service': value.apiService,
        'api_payment_reported_status': value.apiPaymentReportedStatus,
        'zapier_identifier': value.zapierIdentifier,
        'platform_payout_amount': value.platformPayoutAmount,
        'platform_id': value.platformId,
        'custom_identifier': value.customIdentifier,
    };
}


