// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CsvTransactionImport,
    CsvTransactionImportFromJSON,
    CsvTransactionImportToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionsImport
 */
export interface TransactionsImport  {
    /**
     * 
     * @type {number}
     * @memberof TransactionsImport
     */
    clientId?: number;
    /**
     * 
     * @type {Array<CsvTransactionImport>}
     * @memberof TransactionsImport
     */
    selectedTransactions?: Array<CsvTransactionImport>;
}

export function TransactionsImportFromJSON(json: any): TransactionsImport {
    return {
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'selectedTransactions': !exists(json, 'selected_transactions') ? undefined : (json['selected_transactions'] as Array<any>).map(CsvTransactionImportFromJSON),
    };
}

export function TransactionsImportToJSON(value?: TransactionsImport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client_id': value.clientId,
        'selected_transactions': value.selectedTransactions === undefined ? undefined : (value.selectedTransactions as Array<any>).map(CsvTransactionImportToJSON),
    };
}


