// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerAction
 */
export interface CustomerAction  {
    /**
     * 
     * @type {Array<number>}
     * @memberof CustomerAction
     */
    ids?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CustomerAction
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAction
     */
    isBulk?: boolean;
}

export function CustomerActionFromJSON(json: any): CustomerAction {
    return {
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isBulk': !exists(json, 'is_bulk') ? undefined : json['is_bulk'],
    };
}

export function CustomerActionToJSON(value?: CustomerAction): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ids': value.ids,
        'name': value.name,
        'is_bulk': value.isBulk,
    };
}


