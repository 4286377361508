import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useMutation, useRequest } from 'redux-query-react'
import { createCustomerAndSendAuthorizations, getAuthorizationEmailSettings } from 'api'
import { MyEditor } from 'components/Editor'
import DOMPurify from 'dompurify'

import { Button, Card, CardBody, Row, Col, Fade, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { getActiveClient, getEntities } from 'app/selectors'
import { CenterContent } from 'components/CenterContent'
import { TitleBar } from './titleBar'
import { InfoPopover } from 'components/InfoPopover'
import { LoadingMask } from 'components/LoadingMask'

export interface CustomerBankInfoProps {
  clientId?: number
}

export interface Recipient {
  email: string
  name: string
}

export const CustomerAuthorizationEmail = () => {
  const history = useHistory()
  const activeClient = useSelector(getActiveClient) || {}
  const setting = useSelector((state) => getEntities(state).authorizationEmailSetting)
  const [message, setMessage] = useState('')
  const [recipients, setRecipients] = useState([{ email: '', name: '' }] as Recipient[])
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const [formLoaded, setFormLoaded] = useState(false)
  const [complete, setComplete] = useState(false)
  const [enableGreeting, setEnableGreeting] = useState(true)

  const [requestState, doRequest] = useRequest(
    activeClient?.id &&
      getAuthorizationEmailSettings(
        {
          clientId: activeClient.id,
        },
        {
          force: true,
          transform: (body) => {
            return { authorizationEmailSetting: body }
          },
          update: {
            authorizationEmailSetting: (_, newValue) => newValue,
          },
        },
      ),
  )

  const [mutationState, doMutation] = useMutation((message: string) => {
    const filteredRecipients = recipients.filter((recipient) => recipient.email.length > 0)
    return createCustomerAndSendAuthorizations({
      createCustomersAndSendAuthorizations: {
        customers: filteredRecipients as any,
        message: message as any,
        clientId: activeClient.id,
      },
    })
  })

  const doSendEmail = (values) => {
    const filteredRecipients = values.recipients.filter((recipient) => recipient.email.length > 0)
    if (filteredRecipients.length !== 0) {
      doMutation(message)?.then((response) => {
        response?.body?.messages && setServerErrors(response.body.messages)
        if (response?.body?.status < 400) {
          setComplete(true)
        }
      })
    } else {
      setServerErrors({
        base: ['Please enter at least one recipient'],
      })
    }
  }

  const doAddRecipient = () => {
    setRecipients([...recipients, { email: '', name: '' }])
  }

  const handleEmailChange = (i, e) => {
    handleFieldChange(e)
    let newRecipients = [...recipients]
    newRecipients[i]['email'] = e.target.value
    setRecipients(newRecipients)
  }

  const handleNameChange = (i, e) => {
    handleFieldChange(e)
    let newRecipients = [...recipients]
    newRecipients[i]['name'] = e.target.value
    setRecipients(newRecipients)
  }

  if (!formLoaded && requestState.isFinished && setting?.customEmailMessage !== undefined) {
    setMessage(DOMPurify.sanitize(setting.customEmailMessage))
    setEnableGreeting(setting.enableGreeting)
    setFormLoaded(true)
  }

  const isPending = requestState.isPending || mutationState.isPending

  return (
    <Fade>
      <TitleBar
        title="Direct Email"
        subtitle={
          <>
            <p style={{ textAlign: 'center' }}>
              This email can be edited as needed to personalize your instructions to your customer.
              <InfoPopover popoverText="To change your direct email's default text, visit the Authorization Settings page." />
            </p>
          </>
        }
      />
      <div className="mb-2 mt-2">
        <Modal isOpen={complete}>
          <ModalHeader>Complete</ModalHeader>
          <ModalBody>
            <p>Customer authorization agreements have been sent successfully.</p>
          </ModalBody>
          <ModalFooter className="text-right">
            <Button color="primary" onClick={() => history.push('/client/customers')} className="ml-2">
              OK
            </Button>
          </ModalFooter>
        </Modal>
        {!requestState.isPending && (
          <CenterContent>
            <FormServerErrors errors={(serverErrors && serverErrors['base']) || []} />
            <Card>
              <CardBody>
                {isPending && <LoadingMask />}
                <AvForm onValidSubmit={(_event, values) => doSendEmail(values)}>
                  {recipients.map((recipient, i) => (
                    <Row key={i}>
                      <Col>
                        <AvField
                          name={`recipients[${i}][name]`}
                          label="Name"
                          type="text"
                          value={recipient.name}
                          validate={{
                            required: {
                              value: recipient.email !== '',
                              errorMessage: 'Please enter a name.',
                            },
                            async: validate,
                          }}
                          placeholder={'E.g. John Smith'}
                          onChange={(e) => handleNameChange(i, e)}
                          required
                        />
                      </Col>
                      <Col>
                        <AvField
                          name={`recipients[${i}][email]`}
                          label="Email"
                          type="email"
                          value={recipient.email}
                          validate={{
                            required: {
                              value: recipient.name !== '',
                              errorMessage: 'Please enter an email address.',
                            },
                            async: validate,
                          }}
                          placeholder={'E.g. john@example.com'}
                          onChange={(e) => handleEmailChange(i, e)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Row className="mt-3 mb-3 vertically-centered">
                    <Col>
                      <hr></hr>
                    </Col>
                    <Col xs={9} sm={6} md={7} xl={6} style={{ textAlign: 'center' }}>
                      <span className="add-recipient-button" onClick={doAddRecipient}>
                        <i className="mdi mdi-plus" /> Add Another Recipient
                      </span>
                    </Col>
                    <Col>
                      <hr></hr>
                    </Col>
                  </Row>
                  {enableGreeting && (
                    <p>
                      Hi (customer's name),{' '}
                      <InfoPopover popoverText="The email greeting will automatically include the customer name stored in Rotessa. This can be turned off in the Authorization Settings." />
                    </p>
                  )}
                  <MyEditor onChange={setMessage} value={message} withBorder />
                  <div className="text-right mt-2">
                    <Button disabled={isPending} type="submit" color="primary" className="ml-1">
                      Send Email
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </CenterContent>
        )}
      </div>
    </Fade>
  )
}
