export const AUTH_HEADER_KEYS = ['access-token', 'client', 'expiry', 'uid']
export const RESET_PASSWORD_PREFIX = 'reset-password-'

export const ACTIVE_CLIENT_KEY = 'app/active-client-id'

export const ACTIVE_PROCESSING_CLIENT_ONBOARDING_KEY = 'app/active-processing-client-onboarding-id'

export const ADMIN_ROLES = ['client_admin', 'client_primary']

export const SELECTABLE_CLIENT_TYPES = ['Processing', 'Donation', 'Tuition']

export const ROLES = [
  {
    value: 'client_admin',
    label: 'Full Access',
    description:
      'Allows the user full access to the Rotessa account permissions. Rotessa support will respond to requests from this user to update bank information or billing details.',
    color: 'success-lighten',
  },
  {
    value: 'client_primary',
    label: 'Primary Contact',
    description: '',
    color: 'info-lighten',
  },
  {
    value: 'client_custom',
    label: 'Custom',
    description: 'Select custom permissions for this user with the options below.',
    color: 'secondary-lighten',
  },
  {
    value: 'client_read_only',
    label: 'Read Only',
    description:
      'Allows the user to view customer information, transaction details, settlements, and transaction reports. This user will not be able to view unmasked bank information or export customer details.',
    color: 'warning-lighten',
  },
]

export const ROLE_PERMISSIONS = [
  {
    value: 'manageIntegrations',
    label: 'Manage Integrations',
    description:
      'Allows the user to connect, and update information with an integration. This user can also enable or disable auto import and resend settlement report information.',
  },
  {
    value: 'cudTransactionSchedules',
    label: 'Manage Transactions',
    description: 'Allows the user to view, create, update, and delete transaction schedules.',
  },
  {
    value: 'cudCustomers',
    label: 'Manage Customers',
    description: 'Allows the user to view, create, update, and delete customer information.',
  },
  {
    value: 'customerExport',
    label: 'Export Customers',
    description:
      'Allows the user to export the entire customer list which includes bank information for the customers.',
  },
  {
    value: 'readUnmaskedBankNumbers',
    label: 'View Bank Information',
    description: 'Allows the user to fully view revealed bank information for customers.',
  },
  {
    value: 'crdApiKeys',
    label: 'Manage API Keys',
    description: 'Allows the user to view, create, update, and delete API keys.',
  },
  {
    value: 'editAuthorizationSettings',
    label: 'Edit Authorization Settings',
    description:
      'Allows the user to edit settings on the online authorization form. This user can also opt-in or out of the paid feature for Instant Bank Verification.',
  },
]

export const CLIENT_STATUS = {
  created: 'Created',
  pending: 'Pending',
  approved: 'Approved',
  closed: 'Closed',
}

export const VERIFF_STATES = { incomplete: 'incomplete', complete: 'complete', failed: 'failed' }

export const TRANSACTION_STATUS_CLASSES = {
  Pending: 'info',
  Creating: 'secondary',
  Approved: 'success',
  Chargeback: 'danger',
  Declined: 'danger',
  Future: 'warning',
  'Sent to Bank': 'bright-info',
  Processed: 'dark-info',
}

export const ACTIVITY_STATUS_CLASSES = {
  scheduleCreated: 'secondary',
  xeroScheduleCreated: 'secondary',
  quickbooksScheduleCreated: 'secondary',
  scheduleDeleted: 'secondary',
  scheduleAmountUpdated: 'secondary',
  paymentReported: 'secondary',
  xeroPaymentReported: 'secondary',
  quickbooksPaymentReported: 'secondary',
  transactionApproved: 'green',
  transactionDeclined: 'danger',
  transactionCreated: 'cyan',
  transactionSettled: 'secondary',
  transactionSchedule: 'secondary',
  financialTransaction: 'secondary',
  customerNotificationSettingsUpdated: 'secondary',
}

export const CARD_COL_SIZE = { size: 6, offset: 3 }

export const QUICKBOOKS = 'quickbooks'
export const XERO = 'xero'

export const BANK_FIELDS = ['transitNumber', 'institutionNumber', 'bankAccountType', 'routingNumber', 'accountNumber']

export const MAX_FILE_SIZE = 6291456 //6MB = 6291456 Bytes (binary)
export const CLIENT_MAX_FILE_SIZE = 10485760 //10MB = 10,485,760 Bytes (binary)

export const COUNTRY_CODES = { canadian: 'CA', american: 'US' }
