// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransactionReportCustomer,
    TransactionReportCustomerFromJSON,
    TransactionReportCustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionReport
 */
export interface TransactionReport  {
    /**
     * 
     * @type {number}
     * @memberof TransactionReport
     */
    id?: number;
    /**
     * 
     * @type {TransactionReportCustomer}
     * @memberof TransactionReport
     */
    customer?: TransactionReportCustomer;
    /**
     * 
     * @type {number}
     * @memberof TransactionReport
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionReport
     */
    processDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionReport
     */
    settledDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionReport
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionReport
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionReport
     */
    statusReason?: string;
}

export function TransactionReportFromJSON(json: any): TransactionReport {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customer': !exists(json, 'customer') ? undefined : TransactionReportCustomerFromJSON(json['customer']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'processDate': !exists(json, 'process_date') ? undefined : json['process_date'],
        'settledDate': !exists(json, 'settled_date') ? undefined : json['settled_date'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusReason': !exists(json, 'status_reason') ? undefined : json['status_reason'],
    };
}

export function TransactionReportToJSON(value?: TransactionReport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'customer': TransactionReportCustomerToJSON(value.customer),
        'amount': value.amount,
        'process_date': value.processDate,
        'settled_date': value.settledDate,
        'comment': value.comment,
        'status': value.status,
        'status_reason': value.statusReason,
    };
}


