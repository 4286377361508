// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CsvTransactionImport,
    CsvTransactionImportFromJSON,
    CsvTransactionImportToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionCsvPreview200Response
 */
export interface TransactionCsvPreview200Response  {
    /**
     * 
     * @type {number}
     * @memberof TransactionCsvPreview200Response
     */
    count?: number;
    /**
     * 
     * @type {Array<CsvTransactionImport>}
     * @memberof TransactionCsvPreview200Response
     */
    records?: Array<CsvTransactionImport>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionCsvPreview200Response
     */
    errors?: Array<string>;
}

export function TransactionCsvPreview200ResponseFromJSON(json: any): TransactionCsvPreview200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(CsvTransactionImportFromJSON),
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    };
}

export function TransactionCsvPreview200ResponseToJSON(value?: TransactionCsvPreview200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(CsvTransactionImportToJSON),
        'errors': value.errors,
    };
}


