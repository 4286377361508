import React, { Component } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import DatePicker from 'react-datepicker'
import { FormServerErrors } from './FormServerErrors'
import PrependAvField from './PrependAvField'
import { ReactElement } from 'react-router/node_modules/@types/react'

type DatepickerInputProps = {
  onClick?: () => void
  value?: string
}
class DatepickerInput extends Component<DatepickerInputProps> {
  constructor(props) {
    super(props)

    this.onDateValueChange = this.onDateValueChange.bind(this)
  }

  onDateValueChange = () => {}
  render() {
    return (
      <input
        type="text"
        className="form-control date"
        id="dash-daterange"
        onClick={this.props.onClick}
        value={this.props.value}
        onChange={this.onDateValueChange}
      />
    )
  }
}

type DatepickerInputWithCustomAddonProps = {
  onClick?: () => void
  value?: string
  prepended?: boolean
  label?: string
}

class DatepickerInputWithCustomAddon extends Component<DatepickerInputWithCustomAddonProps> {
  render() {
    return (
      <div className="input-group">
        {this.props.prepended && (
          <div className="input-group-prepend">
            <span className="input-group-text">{this.props.label}</span>
          </div>
        )}
        <input
          type="text"
          className="form-control date"
          id="dash-daterange"
          onClick={this.props.onClick}
          onChange={() => null}
          value={this.props.value}
        />

        {!this.props.prepended && (
          <div className="input-group-append">
            <span className="input-group-text">{this.props.label}</span>
          </div>
        )}
      </div>
    )
  }
}

type RotessaDatepickerProps = {
  hideAddon?: boolean
  addonPrepended?: boolean
  addOnContent?: string
  dateFormat?: string
  name?: string
  disabled?: boolean
  startDate?: string
  onChange?: (date: string) => void
  className?: string
  maxDate?: Date
  minDate?: Date
}

type RotessaDatepickerState = {
  startDate?: Date
  maxDate?: Date
  minDate?: Date
}

class RotessaDatepicker extends Component<RotessaDatepickerProps, RotessaDatepickerState> {
  constructor(props: RotessaDatepickerProps) {
    super(props)
    this.state = {
      startDate: props?.startDate ? new Date(props.startDate) : new Date(),
      minDate: props?.minDate,
      maxDate: props?.maxDate,
    }
    this.handleDateSelectChange = this.handleDateSelectChange.bind(this)
  }

  handleDateSelectChange = (date: any) => {
    this.props?.onChange?.(date)
    this.setState({
      startDate: date,
    })
  }

  render() {
    const input =
      (this.props.hideAddon || false) === true ? (
        <DatepickerInput />
      ) : (
        <DatepickerInputWithCustomAddon label={this.props.addOnContent} prepended={this.props.addonPrepended} />
      )

    return (
      <DatePicker
        {...{ dateFormat: 'MMM dd, yyyy' }}
        {...this.props}
        customInput={input}
        selected={this.state.startDate}
        onChange={this.handleDateSelectChange}
        wrapperClassName={this.props.className}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
      />
    )
  }
}

export default RotessaDatepicker

export interface AvDatePickerProps {
  label: string
  name: string
  value?: Date
  onChange: Function
  disabled?: boolean
  required?: boolean
  serverErrors?: string[]
}

export const AvDatePicker = ({ label, name, value, onChange, disabled, required, serverErrors }: AvDatePickerProps) => {
  return (
    <>
      <DatePicker
        name={name}
        selected={value}
        onChange={onChange}
        dateFormat="MMM dd, yyyy"
        disabled={disabled}
        required={required}
        autoComplete="off"
        customInput={<AvField label={label} name={name} type="text" disabled={disabled} />}
      />
      <FormServerErrors errors={serverErrors || []} />
    </>
  )
}

export interface PrependAvDatePickerProps {
  label: string | ReactElement
  name: string
  value?: Date
  onChange: Function
  disabled?: boolean
  required?: boolean
  serverErrors?: string[]
  placeholder?: string
  inputMode?: string
}

export const PrependAvDatePicker = ({
  label,
  name,
  value,
  onChange,
  disabled,
  required,
  serverErrors,
  placeholder,
  inputMode,
}: PrependAvDatePickerProps) => {
  return (
    <>
      <DatePicker
        name={name}
        selected={value}
        onChange={onChange}
        dateFormat="MMM dd, yyyy"
        disabled={disabled}
        required={required}
        autoComplete="off"
        placeholderText={placeholder}
        customInput={<PrependAvField label={label} name={name} type="text" disabled={disabled} inputMode={inputMode} />}
      />
      <FormServerErrors errors={serverErrors || []} />
    </>
  )
}
