import { useWindowSize } from './useWindowSize'

export const useIsMobile = (): boolean => {
  const windowSize = useWindowSize()

  return windowSize && windowSize.width > 0 && windowSize.width < 800
}

export const useIsMedium = (): boolean => {
  const windowSize = useWindowSize()

  return windowSize && windowSize.width > 0 && windowSize.width < 992
}

export const useIsLarge = (): boolean => {
  const windowSize = useWindowSize()

  return windowSize && windowSize.width > 0 && windowSize.width < 1200
}
