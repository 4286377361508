import { configureStore, Action } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import { queryMiddleware } from 'redux-query'
import { RotessaNetworkInterface } from './networkInterface'
import rootReducer, { RootState } from 'app/rootReducer'
import { getQueries, getEntities } from 'app/selectors'
import { UseAuthHeaders } from 'app/middleware'

const store = configureStore({
  reducer: rootReducer,
  middleware: [UseAuthHeaders, queryMiddleware(RotessaNetworkInterface, getQueries, getEntities)],
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
