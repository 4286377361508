import React from 'react'

import { CenterContent } from 'components/CenterContent'
import { Col, Row, Card, CardHeader, CardBody, Fade, CardGroup } from 'reactstrap'

import newManualCustomerImg from 'assets/images/customerFlow/New_customer_manually.png'
import CSVUploadIcon from 'assets/images/CSV_upload.svg'
import { isPermitted } from 'utilities'
import { getActiveClient } from 'app/selectors'
import { useSelector } from 'react-redux'
import { TitleBar } from './titleBar'
import { Link } from 'react-router-dom'
import { integrationIcon } from 'components/Integrations'

export const NewCustomerFlow = () => {
  const activeClient = useSelector(getActiveClient)
  const doDisplayIntegration = isPermitted('integrations.customers', activeClient) && activeClient?.integration
  const doDisplayCSV = isPermitted('customers.csv_imports', activeClient)

  return (
    <Fade>
      <TitleBar title="How would you like to create a new customer?" />
      <CenterContent>
        <Col>
          <Row>
            <Col m>
              <CardGroup className="justify-content-center">
                <Card className="m-2 customer-flow-card">
                  <Link to="/client/customers/new/authorization">
                    <CardHeader className="text-center customer-flow-card-header">
                      <img
                        src={newManualCustomerImg}
                        height={100}
                        className="mt-1 mb-1"
                        alt="Manually create customer"
                      />
                    </CardHeader>
                    <CardBody className="text-center">
                      <p className="card-label">Manually</p>
                      <p className="text-muted mb-0" style={{ lineHeight: 1.2 }}>
                        Create a new customer manually with their name and email.
                      </p>
                    </CardBody>
                  </Link>
                </Card>
                {doDisplayIntegration && (
                  <Card className="m-2 customer-flow-card">
                    <Link to="/client/customers/connect">
                      <CardHeader className="customer-flow-card-header justify-content-center p-0 d-flex flex-wrap align-items-center">
                        <img
                          src={integrationIcon(activeClient?.integration)}
                          className="m-2 customer-flow-integration-icons"
                          alt="Import customers from integration"
                        />
                      </CardHeader>
                      <CardBody className="text-center">
                        <p className="card-label">Integration</p>
                        <p className="text-muted mb-0" style={{ lineHeight: 1.2 }}>
                          Connect multiple customers from your accounting software.
                        </p>
                      </CardBody>
                    </Link>
                  </Card>
                )}
                {doDisplayCSV && (
                  <Card className="m-2 customer-flow-card">
                    <Link to="/client/customers/new/imports/csv">
                      <CardHeader className="text-center customer-flow-card-header">
                        <img
                          src={CSVUploadIcon}
                          height={100}
                          className="mt-1 mb-1"
                          alt="Import transactions from CSV"
                        />
                      </CardHeader>
                      <CardBody className="text-center">
                        <p className="card-label">CSV Upload</p>
                        <p className="text-muted mb-0" style={{ lineHeight: 1.2 }}>
                          Import multiple customers with a .csv file.
                        </p>
                      </CardBody>
                    </Link>
                  </Card>
                )}
              </CardGroup>
            </Col>
          </Row>
        </Col>
      </CenterContent>
    </Fade>
  )
}
