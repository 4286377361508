// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplyPromoCode200Response
 */
export interface ApplyPromoCode200Response  {
    /**
     * 
     * @type {number}
     * @memberof ApplyPromoCode200Response
     */
    status?: number;
    /**
     * 
     * @type {Client}
     * @memberof ApplyPromoCode200Response
     */
    client?: Client;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ApplyPromoCode200Response
     */
    messages?: { [key: string]: Array<string>; };
}

export function ApplyPromoCode200ResponseFromJSON(json: any): ApplyPromoCode200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function ApplyPromoCode200ResponseToJSON(value?: ApplyPromoCode200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'client': ClientToJSON(value.client),
        'messages': value.messages,
    };
}


