import { getActiveClient } from 'app/selectors'
import { RightDrawer } from 'components/RightDrawer'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge, Button, CustomInput } from 'reactstrap'

export interface RightBarProps {
  onFilterChange: (filter: { [x: string]: boolean }, mutuallyExclusiveWith?: string[]) => void
  selectedFilters: object
  quickFilters?: { label: string; name: string; mutuallyExclusiveWith?: string[]; icon?: string }[]
}

export const FiltersDrawer = ({ selectedFilters, onFilterChange, quickFilters }: RightBarProps) => {
  const activeClient = useSelector(getActiveClient) || {}
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false)
  const title = (
    <>
      <h2>Filters</h2>
    </>
  )

  const filtersToExcludeFromCount = quickFilters
    ? ['active']
    : ['action_required', 'complete', 'quickbooks', 'xero', 'active']

  const selectedCount = Object.entries(selectedFilters).filter(
    (entry) => entry[1] && !filtersToExcludeFromCount.includes(entry[0]),
  ).length

  const selectedCountText = selectedCount > 0 ? <span>{selectedCount}</span> : null

  const filters: { type: string; label: string; name?: string; mutuallyExclusiveWith?: string[] }[] = [
    {
      type: 'title',
      label: 'Archived',
    },
    {
      type: 'toggle',
      name: 'active',
      label: 'Active',
    },
    {
      type: 'toggle',
      name: 'archived',
      label: 'Archived',
    },
    {
      type: 'title',
      label: 'Authorization status',
    },
    {
      type: 'toggle',
      name: 'authorization_status_complete',
      label: 'Authorization complete',
    },
    {
      type: 'toggle',
      name: 'authorization_status_pending',
      label: 'Authorization pending',
    },
    {
      type: 'toggle',
      name: 'authorization_status_failed',
      label: 'Authorization failed',
    },
    {
      type: 'title',
      label: 'Authorization type',
    },
    {
      type: 'toggle',
      name: 'authorization_type_in_person',
      label: 'In-person authorization',
    },
    {
      type: 'toggle',
      name: 'authorization_type_online',
      label: 'Online authorization',
    },
    {
      type: 'toggle',
      name: 'authorization_type_rotessa',
      label: 'Rotessa authorization',
    },
    {
      type: 'title',
      label: 'Bank verification status',
    },
    {
      type: 'toggle',
      name: 'verification_complete',
      label: 'Bank verification complete',
    },
    {
      type: 'toggle',
      name: 'microdeposit_pending',
      label: 'Microdeposits pending',
    },
    {
      type: 'toggle',
      name: 'microdeposit_failed',
      label: 'Microdeposits failed',
    },
    {
      type: 'toggle',
      name: 'flinks_pending',
      label: 'Instant Bank Verification pending',
    },
    {
      type: 'toggle',
      name: 'flinks_failed',
      label: 'Instant Bank Verification failed',
    },
    {
      type: 'title',
      label: 'Notifications',
    },
    {
      type: 'toggle',
      name: 'payment_receipt_notifications_enabled',
      label: 'Payment receipts enabled',
      mutuallyExclusiveWith: ['payment_receipt_notifications_disabled'],
    },
    {
      type: 'toggle',
      name: 'payment_receipt_notifications_disabled',
      label: 'Payment receipts disabled',
      mutuallyExclusiveWith: ['payment_receipt_notifications_enabled'],
    },
    {
      type: 'toggle',
      name: 'payment_reminder_notifications_enabled',
      label: 'Payment reminders enabled',
      mutuallyExclusiveWith: ['payment_reminder_notifications_disabled'],
    },
    {
      type: 'toggle',
      name: 'payment_reminder_notifications_disabled',
      label: 'Payment reminders disabled',
      mutuallyExclusiveWith: ['payment_reminder_notifications_enabled'],
    },
  ]

  if (activeClient.integration) {
    filters.push({
      type: 'title',
      label: 'Integration status',
    })
    if (activeClient.integration === 'quickbooks') {
      filters.push({
        type: 'toggle',
        label: 'Connected to QuickBooks Online',
        name: 'quickbooks',
      })
      filters.push({
        type: 'toggle',
        label: 'QuickBooks Online auto import invoices on',
        name: 'auto_import_invoices_on',
        mutuallyExclusiveWith: ['auto_import_invoices_off'],
      })
      filters.push({
        type: 'toggle',
        label: 'QuickBooks Online auto import invoices off',
        name: 'auto_import_invoices_off',
        mutuallyExclusiveWith: ['auto_import_invoices_on'],
      })
      filters.push({
        type: 'toggle',
        label: 'QuickBooks Online auto update customers on',
        name: 'auto_update_customers_on',
        mutuallyExclusiveWith: ['auto_update_customers_off'],
      })
      filters.push({
        type: 'toggle',
        label: 'QuickBooks Online auto update customers off',
        name: 'auto_update_customers_off',
        mutuallyExclusiveWith: ['auto_update_customers_on'],
      })
    } else if (activeClient.integration === 'xero') {
      filters.push({
        type: 'toggle',
        label: 'Connected to Xero',
        name: 'xero',
      })
      filters.push({
        type: 'toggle',
        label: 'Xero auto import invoices on',
        name: 'auto_import_invoices_on',
        mutuallyExclusiveWith: ['auto_import_invoices_off'],
      })
      filters.push({
        type: 'toggle',
        label: 'Xero auto import invoices off',
        name: 'auto_import_invoices_off',
        mutuallyExclusiveWith: ['auto_import_invoices_on'],
      })
      filters.push({
        type: 'toggle',
        label: 'Xero auto update customers on',
        name: 'auto_update_customers_on',
        mutuallyExclusiveWith: ['auto_update_customers_off'],
      })
      filters.push({
        type: 'toggle',
        label: 'Xero auto update customers off',
        name: 'auto_update_customers_off',
        mutuallyExclusiveWith: ['auto_update_customers_on'],
      })
    }
  }

  const body = (
    <>
      {quickFilters && (
        <div>
          {quickFilters.map((filter, i) => (
            <Badge
              pill
              color={selectedFilters[filter.name] ? 'secondary-lighten' : 'light'}
              className={'mb-1 btn' + (selectedFilters[filter.name] ? ' selected-filter' : ' unselected-filter')}
              key={i}
            >
              <Button
                onClick={() =>
                  onFilterChange({ [filter.name!]: !selectedFilters[filter.name] }, filter.mutuallyExclusiveWith)
                }
                className="btn btn-rotessa-tertiary pt-0 pb-0 no-wrap"
              >
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <img src={filter.icon} height={15} className="mr-1 pr-1" alt="" />
                  {filter.label}
                </div>
              </Button>
            </Badge>
          ))}
        </div>
      )}
      {filters
        .filter((filter) => filter.type !== 'hidden')
        .map((filter, i) => (
          <div key={i} className="mb-1">
            {filter.type === 'toggle' ? (
              <CustomInput
                type="switch"
                id={filter.name!}
                name={filter.name}
                label={filter.label}
                className="inline-block text-muted"
                checked={selectedFilters[filter.name!]}
                onChange={(event) =>
                  onFilterChange({ [filter.name!]: event.target.checked }, filter.mutuallyExclusiveWith)
                }
              />
            ) : (
              <h4 className="mt-2 mb-0 rightbar-heading">{filter.label}</h4>
            )}
          </div>
        ))}
    </>
  )

  return (
    <>
      <Button onClick={() => setShowFiltersDrawer(true)} className="btn btn-rotessa-tertiary p-0 no-wrap">
        <i className="mdi mdi-tune mr-1" />
        All Filters
      </Button>
      <Badge className="badge-pill ml-1" color="primary" style={{ marginBottom: '2px' }}>
        {selectedCountText}
      </Badge>
      <RightDrawer title={title} body={body} enabled={showFiltersDrawer} onClose={() => setShowFiltersDrawer(false)} />
    </>
  )
}
