import React from 'react'

import defaultAuthorizationLogo from 'assets/images/Default-authorization-logo.jpg'
import { useSelector } from 'react-redux'
import { getEntities } from 'app/selectors'
import { useTranslation } from 'react-i18next'

export const ProcessingAuthHeader = () => {
  const processingAuthInfo = useSelector((state) => getEntities(state).processingAuthInfo) || {}
  const fullyFinished = processingAuthInfo.step?.name === 'finished' && !processingAuthInfo.step.require_verification
  const logoUrl = processingAuthInfo.client?.logo?.url
  const { t } = useTranslation()
  const title = fullyFinished
    ? t('authorization.processing.common.header_verified')
    : t('authorization.processing.common.header_authorize')
  const name = !fullyFinished && processingAuthInfo.client.businessName
  const address = !fullyFinished && processingAuthInfo.address
  const email = !fullyFinished && processingAuthInfo.email
  const phone = !fullyFinished && processingAuthInfo.phone

  return (
    <div className="text-center">
      <h4 className="mb-3 processing-header-text">{title}</h4>
      <div className="processing-logo my-2">
        <img src={logoUrl || defaultAuthorizationLogo} style={{ borderRadius: 4 }} alt="" />
      </div>
      <h2>{name}</h2>
      <p className="text-muted">
        {address?.address1 && (
          <>
            {address?.address1}, {address?.city}, {address?.provinceCode} {address?.postalCode}
            {(email || phone) && <br />}
          </>
        )}
        {email}
        {email && phone ? ' | ' : ''}
        {phone}
      </p>
    </div>
  )
}
