// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerNotificationSettingsPaymentReminder,
    CustomerNotificationSettingsPaymentReminderFromJSON,
    CustomerNotificationSettingsPaymentReminderToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerNotificationSettings
 */
export interface CustomerNotificationSettings  {
    /**
     * 
     * @type {CustomerNotificationSettingsPaymentReminder}
     * @memberof CustomerNotificationSettings
     */
    paymentReminder?: CustomerNotificationSettingsPaymentReminder;
    /**
     * 
     * @type {CustomerNotificationSettingsPaymentReminder}
     * @memberof CustomerNotificationSettings
     */
    paymentReceipt?: CustomerNotificationSettingsPaymentReminder;
}

export function CustomerNotificationSettingsFromJSON(json: any): CustomerNotificationSettings {
    return {
        'paymentReminder': !exists(json, 'payment_reminder') ? undefined : CustomerNotificationSettingsPaymentReminderFromJSON(json['payment_reminder']),
        'paymentReceipt': !exists(json, 'payment_receipt') ? undefined : CustomerNotificationSettingsPaymentReminderFromJSON(json['payment_receipt']),
    };
}

export function CustomerNotificationSettingsToJSON(value?: CustomerNotificationSettings): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'payment_reminder': CustomerNotificationSettingsPaymentReminderToJSON(value.paymentReminder),
        'payment_receipt': CustomerNotificationSettingsPaymentReminderToJSON(value.paymentReceipt),
    };
}


