import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import { Card, CardBody, Button } from 'reactstrap'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'

import { getOwnerSignupDetails, setOwnerVerified } from 'api'
import { getEntities, justReplace } from 'app/selectors'
import { Loading } from 'components/Loading'
import logo from 'assets/images/logo-blue.png'
import { BBB } from 'components/bbb'

const doVeriff = (url: string, onFinished: Function) => {
  createVeriffFrame({
    url: url,
    onEvent: (msg) => {
      if (msg === MESSAGES.FINISHED) {
        onFinished()
      }
    },
  })
}

export const PublicOwners = (props) => {
  const ownerDetails = useSelector((state) => getEntities(state).ownerDetails)
  const ownerVeriffUrl = useSelector((state) => getEntities(state).ownerVeriffUrl)
  const ownerVeriffCompleted = useSelector((state) => getEntities(state).ownerVeriffCompleted)
  const [loading, setLoading] = useState(true)
  const [finished, setFinished] = useState(false)
  const code = props?.code || ownerDetails?.ownerFormUrl

  const [requestState, doRequest] = useRequest(
    !code
      ? null
      : getOwnerSignupDetails(
          { formUrl: code },
          {
            force: true,
            transform: (body) => {
              return {
                ownerDetails: body.client,
                ownerVeriffUrl: body.veriffUrl,
                ownerFormCompleted: body.formCompleted,
                ownerVeriffCompleted: body.veriffCompleted,
              }
            },
            update: {
              ownerDetails: justReplace,
              ownerVeriffUrl: justReplace,
              ownerFormCompleted: justReplace,
              ownerVeriffCompleted: justReplace,
            },
          },
        ),
  )

  const [verifyOwnerState, doVerifyOwner] = useMutation((formUrl: string) =>
    setOwnerVerified({ formUrl: { formUrl: formUrl } }),
  )

  if (loading) {
    if (requestState.isFinished && ownerDetails !== undefined) {
      setLoading(false)
      if (ownerVeriffCompleted) {
        setFinished(true)
      }
    }
    return <Loading />
  }

  return (
    <div style={{ maxWidth: '900px', margin: 'auto' }}>
      <Card className="mt-4 mb-2">
        <CardBody>
          <img src={logo} width={150} className="mb-2" alt="" />
          <h3>{ownerDetails?.businessName} Owner Verification</h3>
          {!finished && ownerVeriffUrl ? (
            <div className="text-center">
              <Button
                color="primary"
                onClick={() => {
                  doVeriff(ownerVeriffUrl, () => {
                    doVerifyOwner(code)?.then(() => setFinished(true))
                  })
                }}
              >
                Start photo ID verification
              </Button>
            </div>
          ) : (
            <>
              <p>
                The owner signup process is complete. If you have any questions, please contact us at{' '}
                <a href="mailto:support@rotessa.com">support@rotessa.com</a>
              </p>
              <h4>Thank you!</h4>
            </>
          )}
        </CardBody>
      </Card>
      <div className="text-center">
        <small>All of your information is transmitted and stored securely.</small>
        <div className="mt-2 mb-2">
          <BBB />
        </div>
      </div>
    </div>
  )
}
