// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Province
 */
export interface Province  {
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    code?: string;
}

export function ProvinceFromJSON(json: any): Province {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function ProvinceToJSON(value?: Province): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'code': value.code,
    };
}


