// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner  {
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Owner
     */
    verified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    addressId?: number;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    clientId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Owner
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Owner
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    formUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Owner
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    completionStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    share?: number;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    userId?: number;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Owner
     */
    addressesAttributes?: Array<Address>;
}

export function OwnerFromJSON(json: any): Owner {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'addressId': !exists(json, 'address_id') ? undefined : json['address_id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'position': !exists(json, 'position') ? undefined : json['position'],
        'dateOfBirth': !exists(json, 'date_of_birth') ? undefined : json['date_of_birth'],
        'formUrl': !exists(json, 'form_url') ? undefined : json['form_url'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'completionStatus': !exists(json, 'completion_status') ? undefined : json['completion_status'],
        'share': !exists(json, 'share') ? undefined : json['share'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'addressesAttributes': !exists(json, 'addresses_attributes') ? undefined : (json['addresses_attributes'] as Array<any>).map(AddressFromJSON),
    };
}

export function OwnerToJSON(value?: Owner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'verified': value.verified,
        'address_id': value.addressId,
        'client_id': value.clientId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'position': value.position,
        'date_of_birth': value.dateOfBirth,
        'form_url': value.formUrl,
        'primary': value.primary,
        'completion_status': value.completionStatus,
        'share': value.share,
        'user_id': value.userId,
        'addresses_attributes': value.addressesAttributes === undefined ? undefined : (value.addressesAttributes as Array<any>).map(AddressToJSON),
    };
}


