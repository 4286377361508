import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import { RootState } from 'app/rootReducer'
import { ExternalLink } from 'components/ExternalLink'
import { maskBankInfo } from 'utilities'
import { useIsPermitted } from 'hooks/useIsPermitted'

export interface CustomerDetailsProps {
  readOnly?: boolean
}

export const CustomerDetails = (props: CustomerDetailsProps) => {
  const customer = useSelector((state: RootState) => state.entities.customer) || {}
  const permitEdit = useIsPermitted('customers.edit')
  var rows: any[] = []

  if (customer.name) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-account mr-2" />
        </td>
        <td>
          <b>{customer.name}</b>
        </td>
      </tr>
    ))
  }

  if (customer.phone) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-phone-outline mr-2" />
        </td>
        <td>{customer.phone}</td>
      </tr>
    ))
  }

  if (customer.email) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-email-outline mr-2" />
        </td>
        <td>{customer.email}</td>
      </tr>
    ))
  }

  if (customer.address?.address1) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-map-marker-outline mr-2" />
        </td>
        <td>
          {customer.address?.address1}
          <br />
          {customer.address?.city}, {customer.address?.provinceCode}
          <br />
          {customer.address?.postalCode}
        </td>
      </tr>
    ))
  }

  if (customer.permissionForm?.url) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-paperclip mr-2" />
        </td>
        <td>
          <ExternalLink to={customer.permissionForm.url}>Authorization Form</ExternalLink>
        </td>
      </tr>
    ))
  }

  if (customer.bankAccount?.accountNumber?.length > 0) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-bank mr-2" />
        </td>
        <td>
          {[
            customer.bankAccount.bankAccountType || '',
            maskBankInfo(customer.bankAccount.routingNumber || ''),
            maskBankInfo(customer.bankAccount.transitNumber || ''),
            maskBankInfo(customer.bankAccount.institutionNumber || ''),
            maskBankInfo(customer.bankAccount.accountNumber || ''),
          ]
            .filter((a) => a)
            .join(', ')}
        </td>
      </tr>
    ))
  }

  return (
    <Card>
      <CardBody>
        {!props.readOnly && permitEdit && (
          <Link to={`${customer.id}/edit`} className="float-right">
            <i className="mdi mdi-square-edit-outline"></i>
          </Link>
        )}
        <h4 className="header-title mb-3">Details</h4>
        {rows.length > 0 ? (
          <table>
            <tbody>
              {rows.map((Row, i) => (
                <Row key={i} />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <i className="mdi mdi-information mr-2" />
            No data
          </div>
        )}
      </CardBody>
    </Card>
  )
}
