import React, { useState } from 'react'

import { CenterContent } from 'components/CenterContent'
import { Card, CardBody, Form, Fade, Row, Col, Button } from 'reactstrap'
import { OnTableChangeProps, Table } from 'components/Table'
import { TableSearchField } from 'components/TableHeader'
import { searchCustomers } from 'api'
import { getActiveClient, getCustomers } from 'app/selectors'
import { useSelector } from 'react-redux'
import { useRequest } from 'redux-query-react'
import { getSort } from 'utilities'
import { ColumnProps, DateFormatter } from 'components/Table'
import { useHistory } from 'react-router-dom'
import { FormServerErrors } from 'components/FormServerErrors'
import { CustomerStatusesFormatter } from './customersTable'
import complete from 'assets/images/customer_dashboard_icons/Blue-checkmark-ready-to-process.png'

export const EmailAuthorizationSelect = () => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const activeClient = useSelector(getActiveClient) || {}
  const customers = useSelector(getCustomers) || []
  const [errors, setErrors] = useState<string[]>([])
  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 50,
    selectedRows: [],
    sortField: 'createdAt',
    sortOrder: 'desc',
  })

  const [requestState, doRequest] = useRequest(
    !activeClient?.id
      ? null
      : searchCustomers(
          {
            clientId: activeClient.id,
            skip: (tableProps.page - 1) * tableProps.pageSize,
            limit: tableProps.pageSize,
            filters: { active: true, customer_email_auth_select_list: true },
            search: search,
            sort: getSort('createdAt', 'desc'),
          },
          {
            force: true,
            transform: (response) => {
              return {
                customers: response.records,
                customersCount: response.count,
              }
            },
            update: {
              customers: (_, newValue) => newValue,
              customersCount: (_, newValue) => newValue,
            },
          },
        ),
  )

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setTableProps({ ...tableProps, page: 1, selectedRows: [] })
  }

  const onTableChange = (_type: string, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) => {
    setTableProps({
      page: tableProps.page,
      pageSize: tableProps.pageSize,
      selectedRows: [],
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  const handleSendEmails = () => {
    if (tableProps.selectedRows.length === 0) {
      setErrors(['Please select one or more customers for the bulk action.'])
    } else {
      const idParams = tableProps.selectedRows.map((r: any) => r.id).join(',')
      history.push(`/client/email_customer_authorization_request?ids=${idParams}`)
    }
  }

  const CustomerNameFormatter = (name, row) => {
    return (
      <span>
        {name}
        {row.statuses['complete'] && (
          <img src={complete} height="13" className="ml-1" style={{ marginBottom: '4px' }} alt="" />
        )}
      </span>
    )
  }

  const columns: ColumnProps[] = [
    {
      dataField: 'name',
      formatter: CustomerNameFormatter,
      text: 'Name',
      sort: false,
    },
    {
      dataField: 'statuses',
      formatter: CustomerStatusesFormatter,
      text: '',
      sort: false,
      classes: 'td-badge',
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: false,
      classes: 'td-badge text-right',
      headerAttrs: {
        className: 'text-right',
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created Date',
      formatter: DateFormatter,
      sort: true,
      hidden: true,
      headerAttrs: {
        hidden: true,
      },
    },
  ]

  return (
    <Fade>
      <CenterContent>
        <div className="page-title-box">
          <h4 className="page-title page-title-wrap">Select customers to send email</h4>
        </div>
        <Card>
          <CardBody>
            <Form>
              <FormServerErrors errors={errors} />
              <Row>
                <Col lg={6}>
                  <TableSearchField onChange={handleSearchChange} className="w-100 mb-2" />
                </Col>
                <Col lg={6}>
                  <Button
                    color="primary"
                    className="mb-2 w-100"
                    onClick={handleSendEmails}
                    disabled={tableProps.selectedRows.length === 0}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
              {customers.length >= 50 && <span className="text-secondary">Showing first 50 matches</span>}
            </Form>
            <Table
              loading={requestState.isPending}
              data={customers}
              columns={columns}
              sortField={tableProps.sortField}
              sortOrder={tableProps.sortOrder}
              selectionProps={{
                mode: 'checkbox',
                onSelectionChange: (val) => setTableProps({ ...tableProps, selectedRows: val }),
                selected: tableProps.selectedRows,
              }}
              onTableChange={onTableChange}
              scrollBody
              scrollBodyHeight="calc(100vh - 450px)"
            />
          </CardBody>
        </Card>
      </CenterContent>
    </Fade>
  )
}
