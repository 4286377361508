import React from 'react'
import classNames from 'classnames'

type TimelineProps = {
  className?: string
  children?: {}
}

/**
 * TimelineItem
 */
export const TimelineItem = (props: TimelineProps) => {
  const children = props.children || null

  return (
    <div className={classNames('timeline-item', props.className)} {...props}>
      {children}
    </div>
  )
}
