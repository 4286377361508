import React, { useState } from 'react'
import { Button, Card, CardBody, Fade, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useRequest } from 'redux-query-react'
import { useSelector } from 'react-redux'

import { FormServerErrors } from 'components/FormServerErrors'
import { getActiveClient, getEntities } from 'app/selectors'
import { getAuthorizationEmailSettings, sendCustomerEmailAuthRequest } from 'api'
import { MyEditor } from 'components/Editor'
import { CenterContent } from 'components/CenterContent'
import { InfoPopover } from 'components/InfoPopover'
import DOMPurify from 'dompurify'

export const EmailAuthorizationRequest = () => {
  const activeClient = useSelector(getActiveClient)
  const setting = useSelector((state) => getEntities(state).authorizationEmailSetting)
  const [message, setMessage] = useState('')
  const [enableGreeting, setEnableGreeting] = useState(true)
  const [formLoaded, setFormLoaded] = useState(false)
  const [serverErrors, setServerErrors] = useState([] as string[])
  const [complete, setComplete] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const customerIds =
    location.search
      ?.match(/\?ids=([\d,]+)/)?.[1]
      ?.split(',')
      ?.map((id) => Number.parseInt(id)) || []

  const [requestState, doRequest] = useRequest(
    activeClient?.id &&
      getAuthorizationEmailSettings(
        {
          clientId: activeClient.id,
        },
        {
          force: true,
          transform: (body) => {
            return { authorizationEmailSetting: body }
          },
          update: {
            authorizationEmailSetting: (_, newValue) => newValue,
          },
        },
      ),
  )

  const [mutationState, doMutation] = useMutation((ids: number[], message: string) =>
    sendCustomerEmailAuthRequest({
      emailAuthorizationRequest: { ids: ids, message: message },
    }),
  )

  const doSendEmail = () => {
    doMutation(customerIds, message)?.then((response) => {
      response?.body?.messages?.length > 0 && setServerErrors(response.body.messages)
      if (response?.body?.status < 400) {
        setComplete(true)
      }
    })
  }

  if (!formLoaded && requestState.isFinished && setting?.customEmailMessage !== undefined) {
    setMessage(DOMPurify.sanitize(setting.customEmailMessage))
    setEnableGreeting(setting.enableGreeting)
    setFormLoaded(true)
  }

  const isPending = requestState.isPending || mutationState.isPending
  return (
    <>
      <Modal isOpen={complete}>
        <ModalHeader>Complete</ModalHeader>
        <ModalBody>
          {serverErrors.length ? (
            <FormServerErrors errors={serverErrors} />
          ) : (
            <p>The email has been sent successfully.</p>
          )}
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={() => history.push('/client/customers')} className="ml-2">
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <CenterContent>
        <div className="page-title-box">
          <h4 className="page-title">Email Authorization Request</h4>
          <p>
            This is a preview of the authorization email that will be sent to your customers. Any changes made in this
            window will be applied on this send only. For permanent changes, edit the default email message in your
            Authorization Settings.
          </p>
        </div>
        {!complete && <FormServerErrors errors={serverErrors} />}
        {requestState.isPending ? (
          <div>loading</div>
        ) : (
          <Fade>
            <Card>
              <CardBody>
                {enableGreeting && (
                  <p>
                    Hi (customer's name),{' '}
                    <InfoPopover popoverText="The email greeting will automatically include the customer name stored in Rotessa. This can be turned off in the Authorization Settings." />
                  </p>
                )}
                <MyEditor onChange={setMessage} value={message} />
                <div className="text-right mt-2">
                  <Button
                    disabled={isPending}
                    onClick={() => history.goBack()}
                    className="btn-rotessa-secondary min-width"
                  >
                    Cancel
                  </Button>
                  <Button disabled={isPending} onClick={doSendEmail} color="primary" className="ml-1">
                    Send Email
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Fade>
        )}
      </CenterContent>
    </>
  )
}
