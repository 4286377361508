// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionReportCustomer
 */
export interface TransactionReportCustomer  {
    /**
     * 
     * @type {string}
     * @memberof TransactionReportCustomer
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionReportCustomer
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionReportCustomer
     */
    customIdentifier?: string;
}

export function TransactionReportCustomerFromJSON(json: any): TransactionReportCustomer {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customIdentifier': !exists(json, 'custom_identifier') ? undefined : json['custom_identifier'],
    };
}

export function TransactionReportCustomerToJSON(value?: TransactionReportCustomer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'id': value.id,
        'custom_identifier': value.customIdentifier,
    };
}


