import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { useHistory } from 'react-router'

import { CenterContent } from 'components/CenterContent'

export const ResetPasswordSuccess = () => {
  const history = useHistory()

  return (
    <CenterContent>
      <Card className="mt-4 text-center">
        <CardBody>
          <h3>Success!</h3>
          <p>Your password has been updated.</p>
          <Button color="primary" onClick={() => history.replace('/')}>
            OK, let's go back to the app
          </Button>
        </CardBody>
      </Card>
    </CenterContent>
  )
}
