// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface Microdeposit
 */
export interface Microdeposit  {
    /**
     * 
     * @type {number}
     * @memberof Microdeposit
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Microdeposit
     */
    amountOne?: number;
    /**
     * 
     * @type {number}
     * @memberof Microdeposit
     */
    amountTwo?: number;
    /**
     * 
     * @type {number}
     * @memberof Microdeposit
     */
    clientId?: number;
    /**
     * 
     * @type {number}
     * @memberof Microdeposit
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof Microdeposit
     */
    microdepositBatchId?: number;
    /**
     * 
     * @type {number}
     * @memberof Microdeposit
     */
    attempts?: number;
    /**
     * 
     * @type {string}
     * @memberof Microdeposit
     */
    formUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Microdeposit
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Microdeposit
     */
    statusReason?: string;
    /**
     * 
     * @type {Date}
     * @memberof Microdeposit
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Microdeposit
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Customer}
     * @memberof Microdeposit
     */
    customer?: Customer;
}

export function MicrodepositFromJSON(json: any): Microdeposit {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amountOne': !exists(json, 'amount_one') ? undefined : json['amount_one'],
        'amountTwo': !exists(json, 'amount_two') ? undefined : json['amount_two'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'microdepositBatchId': !exists(json, 'microdeposit_batch_id') ? undefined : json['microdeposit_batch_id'],
        'attempts': !exists(json, 'attempts') ? undefined : json['attempts'],
        'formUrl': !exists(json, 'form_url') ? undefined : json['form_url'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusReason': !exists(json, 'status_reason') ? undefined : json['status_reason'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'customer': !exists(json, 'customer') ? undefined : CustomerFromJSON(json['customer']),
    };
}

export function MicrodepositToJSON(value?: Microdeposit): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'amount_one': value.amountOne,
        'amount_two': value.amountTwo,
        'client_id': value.clientId,
        'customer_id': value.customerId,
        'microdeposit_batch_id': value.microdepositBatchId,
        'attempts': value.attempts,
        'form_url': value.formUrl,
        'status': value.status,
        'status_reason': value.statusReason,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'customer': CustomerToJSON(value.customer),
    };
}


