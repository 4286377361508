import React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { useRequest } from 'redux-query-react'

import { DonorAuthFormManager } from './donorAuthFormManager'
import { getDonorAuthorizationDetails } from 'api'
import { getEntities, justReplace } from 'app/selectors'
import { Loading } from 'components/Loading'

export const DonorAuthFormPage = () => {
  const { code } = useParams() as any
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo) || {}

  const [requestState, _doRequest] = useRequest(
    getDonorAuthorizationDetails(
      { code: code },
      {
        force: true,
        transform: (body) => ({ donorAuthInfo: body }),
        update: { donorAuthInfo: justReplace },
      },
    ),
  )

  if (donorAuthInfo?.id && donorAuthInfo.id !== code) {
    return <Redirect to={`/donation/${donorAuthInfo.id}`} />
  }

  return requestState.isPending ? <Loading /> : <DonorAuthFormManager />
}
