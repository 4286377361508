// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Notification,
    NotificationFromJSON,
    NotificationToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNotifications200Response
 */
export interface GetNotifications200Response  {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof GetNotifications200Response
     */
    records?: Array<Notification>;
}

export function GetNotifications200ResponseFromJSON(json: any): GetNotifications200Response {
    return {
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(NotificationFromJSON),
    };
}

export function GetNotifications200ResponseToJSON(value?: GetNotifications200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(NotificationToJSON),
    };
}


