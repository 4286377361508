// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CsvCustomerImport
 */
export interface CsvCustomerImport  {
    /**
     * 
     * @type {number}
     * @memberof CsvCustomerImport
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    customIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    zipCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CsvCustomerImport
     */
    transitNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof CsvCustomerImport
     */
    institutionNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof CsvCustomerImport
     */
    routingNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof CsvCustomerImport
     */
    accountNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    bankAccountType?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    authorizationType?: string;
    /**
     * 
     * @type {number}
     * @memberof CsvCustomerImport
     */
    authorizationAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CsvCustomerImport
     */
    customerType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CsvCustomerImport
     */
    valid?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CsvCustomerImport
     */
    errors?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CsvCustomerImport
     */
    existingCustomer?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CsvCustomerImport
     */
    customerImportId?: number;
}

export function CsvCustomerImportFromJSON(json: any): CsvCustomerImport {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'customIdentifier': !exists(json, 'custom_identifier') ? undefined : json['custom_identifier'],
        'homePhone': !exists(json, 'home_phone') ? undefined : json['home_phone'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'transitNumber': !exists(json, 'transit_number') ? undefined : json['transit_number'],
        'institutionNumber': !exists(json, 'institution_number') ? undefined : json['institution_number'],
        'routingNumber': !exists(json, 'routing_number') ? undefined : json['routing_number'],
        'accountNumber': !exists(json, 'account_number') ? undefined : json['account_number'],
        'bankAccountType': !exists(json, 'bank_account_type') ? undefined : json['bank_account_type'],
        'authorizationType': !exists(json, 'authorization_type') ? undefined : json['authorization_type'],
        'authorizationAmount': !exists(json, 'authorization_amount') ? undefined : json['authorization_amount'],
        'customerType': !exists(json, 'customer_type') ? undefined : json['customer_type'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'existingCustomer': !exists(json, 'existing_customer') ? undefined : json['existing_customer'],
        'customerImportId': !exists(json, 'customer_import_id') ? undefined : json['customer_import_id'],
    };
}

export function CsvCustomerImportToJSON(value?: CsvCustomerImport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'custom_identifier': value.customIdentifier,
        'home_phone': value.homePhone,
        'phone': value.phone,
        'email': value.email,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'province': value.province,
        'state': value.state,
        'postal_code': value.postalCode,
        'zip_code': value.zipCode,
        'transit_number': value.transitNumber,
        'institution_number': value.institutionNumber,
        'routing_number': value.routingNumber,
        'account_number': value.accountNumber,
        'bank_account_type': value.bankAccountType,
        'authorization_type': value.authorizationType,
        'authorization_amount': value.authorizationAmount,
        'customer_type': value.customerType,
        'valid': value.valid,
        'errors': value.errors,
        'existing_customer': value.existingCustomer,
        'customer_import_id': value.customerImportId,
    };
}


