// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountToJSON,
    AddApiKey200Response,
    AddApiKey200ResponseFromJSON,
    AddApiKey200ResponseToJSON,
    AddCustomer200Response,
    AddCustomer200ResponseFromJSON,
    AddCustomer200ResponseToJSON,
    ApplyPromoCode200Response,
    ApplyPromoCode200ResponseFromJSON,
    ApplyPromoCode200ResponseToJSON,
    ApplyPromoCodeBody,
    ApplyPromoCodeBodyFromJSON,
    ApplyPromoCodeBodyToJSON,
    AuthorizationEmailSetting,
    AuthorizationEmailSettingFromJSON,
    AuthorizationEmailSettingToJSON,
    AuthorizationLinkSetting,
    AuthorizationLinkSettingFromJSON,
    AuthorizationLinkSettingToJSON,
    Client,
    ClientFromJSON,
    ClientToJSON,
    ConnectCustomers,
    ConnectCustomersFromJSON,
    ConnectCustomersToJSON,
    ConnectIntegrationCustomers200Response,
    ConnectIntegrationCustomers200ResponseFromJSON,
    ConnectIntegrationCustomers200ResponseToJSON,
    ConnectIntegrationInvoices200Response,
    ConnectIntegrationInvoices200ResponseFromJSON,
    ConnectIntegrationInvoices200ResponseToJSON,
    ConnectInvoices,
    ConnectInvoicesFromJSON,
    ConnectInvoicesToJSON,
    CreateCustomersAndSendAuthorizations,
    CreateCustomersAndSendAuthorizationsFromJSON,
    CreateCustomersAndSendAuthorizationsToJSON,
    Credentials,
    CredentialsFromJSON,
    CredentialsToJSON,
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
    CustomerAction,
    CustomerActionFromJSON,
    CustomerActionToJSON,
    CustomerNote,
    CustomerNoteFromJSON,
    CustomerNoteToJSON,
    CustomersCsvImport200Response,
    CustomersCsvImport200ResponseFromJSON,
    CustomersCsvImport200ResponseToJSON,
    CustomersCsvPreview200Response,
    CustomersCsvPreview200ResponseFromJSON,
    CustomersCsvPreview200ResponseToJSON,
    CustomersImport,
    CustomersImportFromJSON,
    CustomersImportToJSON,
    DonorAuthSetting,
    DonorAuthSettingFromJSON,
    DonorAuthSettingToJSON,
    DonorAuthorization,
    DonorAuthorizationFromJSON,
    DonorAuthorizationToJSON,
    EmailAuthorization,
    EmailAuthorizationFromJSON,
    EmailAuthorizationToJSON,
    EmailAuthorizationRequest,
    EmailAuthorizationRequestFromJSON,
    EmailAuthorizationRequestToJSON,
    ExportSettlementTransactions200Response,
    ExportSettlementTransactions200ResponseFromJSON,
    ExportSettlementTransactions200ResponseToJSON,
    ExportSettlementTransactionsPdf200Response,
    ExportSettlementTransactionsPdf200ResponseFromJSON,
    ExportSettlementTransactionsPdf200ResponseToJSON,
    FormUrl,
    FormUrlFromJSON,
    FormUrlToJSON,
    Get2faUri200Response,
    Get2faUri200ResponseFromJSON,
    Get2faUri200ResponseToJSON,
    GetApiKeys200Response,
    GetApiKeys200ResponseFromJSON,
    GetApiKeys200ResponseToJSON,
    GetBillingInvoiceById200Response,
    GetBillingInvoiceById200ResponseFromJSON,
    GetBillingInvoiceById200ResponseToJSON,
    GetBillingInvoices200Response,
    GetBillingInvoices200ResponseFromJSON,
    GetBillingInvoices200ResponseToJSON,
    GetClient200Response,
    GetClient200ResponseFromJSON,
    GetClient200ResponseToJSON,
    GetClientsClientOnboarding200Response,
    GetClientsClientOnboarding200ResponseFromJSON,
    GetClientsClientOnboarding200ResponseToJSON,
    GetConstants200Response,
    GetConstants200ResponseFromJSON,
    GetConstants200ResponseToJSON,
    GetDonorAuthSettings200Response,
    GetDonorAuthSettings200ResponseFromJSON,
    GetDonorAuthSettings200ResponseToJSON,
    GetDonorAuthorizationDetails200Response,
    GetDonorAuthorizationDetails200ResponseFromJSON,
    GetDonorAuthorizationDetails200ResponseToJSON,
    GetEmailAuthorizationDetails200Response,
    GetEmailAuthorizationDetails200ResponseFromJSON,
    GetEmailAuthorizationDetails200ResponseToJSON,
    GetIntegrationCustomer200Response,
    GetIntegrationCustomer200ResponseFromJSON,
    GetIntegrationCustomer200ResponseToJSON,
    GetIntegrationCustomers200Response,
    GetIntegrationCustomers200ResponseFromJSON,
    GetIntegrationCustomers200ResponseToJSON,
    GetIntegrationInvoices200Response,
    GetIntegrationInvoices200ResponseFromJSON,
    GetIntegrationInvoices200ResponseToJSON,
    GetIntegrations200Response,
    GetIntegrations200ResponseFromJSON,
    GetIntegrations200ResponseToJSON,
    GetLinkAuthorizationDetails200Response,
    GetLinkAuthorizationDetails200ResponseFromJSON,
    GetLinkAuthorizationDetails200ResponseToJSON,
    GetNotificationSettings200Response,
    GetNotificationSettings200ResponseFromJSON,
    GetNotificationSettings200ResponseToJSON,
    GetNotifications200Response,
    GetNotifications200ResponseFromJSON,
    GetNotifications200ResponseToJSON,
    GetOwnerSignupDetails200Response,
    GetOwnerSignupDetails200ResponseFromJSON,
    GetOwnerSignupDetails200ResponseToJSON,
    GetOwnerVeriffUrl200Response,
    GetOwnerVeriffUrl200ResponseFromJSON,
    GetOwnerVeriffUrl200ResponseToJSON,
    GetProcessingAuthSettings200Response,
    GetProcessingAuthSettings200ResponseFromJSON,
    GetProcessingAuthSettings200ResponseToJSON,
    GetProcessingAuthorizationDetails200Response,
    GetProcessingAuthorizationDetails200ResponseFromJSON,
    GetProcessingAuthorizationDetails200ResponseToJSON,
    GetReleaseHelpers200Response,
    GetReleaseHelpers200ResponseFromJSON,
    GetReleaseHelpers200ResponseToJSON,
    GetSettlements200Response,
    GetSettlements200ResponseFromJSON,
    GetSettlements200ResponseToJSON,
    GetTransactionReport200Response,
    GetTransactionReport200ResponseFromJSON,
    GetTransactionReport200ResponseToJSON,
    GetTransactionReportByDeclineDate200Response,
    GetTransactionReportByDeclineDate200ResponseFromJSON,
    GetTransactionReportByDeclineDate200ResponseToJSON,
    Home200Response,
    Home200ResponseFromJSON,
    Home200ResponseToJSON,
    IntegrationUpdate,
    IntegrationUpdateFromJSON,
    IntegrationUpdateToJSON,
    LinkAuthorization,
    LinkAuthorizationFromJSON,
    LinkAuthorizationToJSON,
    MicrodepositVerification,
    MicrodepositVerificationFromJSON,
    MicrodepositVerificationToJSON,
    MicrodepositVerificationFormDetails,
    MicrodepositVerificationFormDetailsFromJSON,
    MicrodepositVerificationFormDetailsToJSON,
    ModelError,
    ModelErrorFromJSON,
    ModelErrorToJSON,
    NewUser,
    NewUserFromJSON,
    NewUserToJSON,
    Notification,
    NotificationFromJSON,
    NotificationToJSON,
    NotificationSettings,
    NotificationSettingsFromJSON,
    NotificationSettingsToJSON,
    PasswordUpdate,
    PasswordUpdateFromJSON,
    PasswordUpdateToJSON,
    ProcessingAuthSetting,
    ProcessingAuthSettingFromJSON,
    ProcessingAuthSettingToJSON,
    ProcessingAuthorization,
    ProcessingAuthorizationFromJSON,
    ProcessingAuthorizationToJSON,
    RegisterUserDefaultResponse,
    RegisterUserDefaultResponseFromJSON,
    RegisterUserDefaultResponseToJSON,
    RequiredEmail,
    RequiredEmailFromJSON,
    RequiredEmailToJSON,
    RequiredEmailRedirect,
    RequiredEmailRedirectFromJSON,
    RequiredEmailRedirectToJSON,
    SearchClients200Response,
    SearchClients200ResponseFromJSON,
    SearchClients200ResponseToJSON,
    SearchCustomerActivity200Response,
    SearchCustomerActivity200ResponseFromJSON,
    SearchCustomerActivity200ResponseToJSON,
    SearchCustomerNotes200Response,
    SearchCustomerNotes200ResponseFromJSON,
    SearchCustomerNotes200ResponseToJSON,
    SearchCustomers200Response,
    SearchCustomers200ResponseFromJSON,
    SearchCustomers200ResponseToJSON,
    SearchFinancialTransactions200Response,
    SearchFinancialTransactions200ResponseFromJSON,
    SearchFinancialTransactions200ResponseToJSON,
    SearchMicrodeposits200Response,
    SearchMicrodeposits200ResponseFromJSON,
    SearchMicrodeposits200ResponseToJSON,
    SearchSettlementTransactions200Response,
    SearchSettlementTransactions200ResponseFromJSON,
    SearchSettlementTransactions200ResponseToJSON,
    SearchTransactionSchedules200Response,
    SearchTransactionSchedules200ResponseFromJSON,
    SearchTransactionSchedules200ResponseToJSON,
    SearchUsers200Response,
    SearchUsers200ResponseFromJSON,
    SearchUsers200ResponseToJSON,
    SendFlinksData200Response,
    SendFlinksData200ResponseFromJSON,
    SendFlinksData200ResponseToJSON,
    SignIn200Response,
    SignIn200ResponseFromJSON,
    SignIn200ResponseToJSON,
    SignIn400Response,
    SignIn400ResponseFromJSON,
    SignIn400ResponseToJSON,
    SignIn401Response,
    SignIn401ResponseFromJSON,
    SignIn401ResponseToJSON,
    SubmitClientOnboardingState200Response,
    SubmitClientOnboardingState200ResponseFromJSON,
    SubmitClientOnboardingState200ResponseToJSON,
    SubmitStateRequest,
    SubmitStateRequestFromJSON,
    SubmitStateRequestToJSON,
    Token,
    TokenFromJSON,
    TokenToJSON,
    TransactionCsvPreview200Response,
    TransactionCsvPreview200ResponseFromJSON,
    TransactionCsvPreview200ResponseToJSON,
    TransactionSchedule,
    TransactionScheduleFromJSON,
    TransactionScheduleToJSON,
    TransactionsImport,
    TransactionsImportFromJSON,
    TransactionsImportToJSON,
    UnsubscribedByUuid200Response,
    UnsubscribedByUuid200ResponseFromJSON,
    UnsubscribedByUuid200ResponseToJSON,
    UpdateAccount200Response,
    UpdateAccount200ResponseFromJSON,
    UpdateAccount200ResponseToJSON,
    UpdateBankAccount,
    UpdateBankAccountFromJSON,
    UpdateBankAccountToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    VerificationCode,
    VerificationCodeFromJSON,
    VerificationCodeToJSON,
} from '../models';

export interface AddApiKeyRequest {
    clientId?: number;
}

export interface AddClientRequest {
    client?: Client;
}

export interface AddCustomerRequest {
    customer?: Customer;
}

export interface AddTransactionScheduleRequest {
    transactionSchedule?: TransactionSchedule;
}

export interface AddUserRequest {
    user?: User;
}

export interface ApplyPromoCodeRequest {
    id: number;
    applyPromoCodeBody?: ApplyPromoCodeBody;
}

export interface ConnectIntegrationCustomersRequest {
    connectCustomers?: ConnectCustomers;
}

export interface ConnectIntegrationInvoicesRequest {
    connectInvoices?: ConnectInvoices;
}

export interface CreateCustomerAndSendAuthorizationsRequest {
    createCustomersAndSendAuthorizations?: CreateCustomersAndSendAuthorizations;
}

export interface CreateCustomerNoteRequest {
    customerNote?: CustomerNote;
}

export interface CustomersCsvImportRequest {
    customersImport?: CustomersImport;
}

export interface CustomersCsvPreviewRequest {
    clientId?: number;
    file?: Blob;
}

export interface DeleteApiKeyRequest {
    id: number;
}

export interface DeleteCustomerRequest {
    id: number;
}

export interface DeleteCustomerNoteRequest {
    id: number;
}

export interface DeleteTransactionScheduleRequest {
    id: number;
}

export interface Disable2faRequest {
    verificationCode?: VerificationCode;
}

export interface DisconnectCustomerRequest {
    id: number;
}

export interface ExportCustomersRequest {
    clientId?: number;
}

export interface ExportSettlementTransactionsRequest {
    id: number;
    sort?: string;
}

export interface ExportSettlementTransactionsPdfRequest {
    id: number;
    sort?: string;
}

export interface ExportTransactionReportPdfRequest {
    clientId?: number;
    startDate?: Date;
    endDate?: Date;
    filter?: string;
    reportType?: string;
}

export interface GetApiKeysRequest {
    clientId?: number;
    skip?: number;
    limit?: number;
}

export interface GetAuthorizationEmailSettingsRequest {
    clientId?: number;
}

export interface GetAuthorizationLinkSettingsRequest {
    clientId?: number;
}

export interface GetBillingInvoiceByIdRequest {
    id: number;
    clientId?: number;
}

export interface GetBillingInvoicesRequest {
    clientId?: number;
    sort?: string;
    limit?: number;
    skip?: number;
}

export interface GetClientRequest {
    id: number;
}

export interface GetClientFileUrlRequest {
    id: number;
    filter?: string;
}

export interface GetClientsClientOnboardingRequest {
    clientId: number;
    processingClientOnboardingId?: number;
}

export interface GetCustsomerPermissionFormRequest {
    id: number;
}

export interface GetDonorAuthSettingsRequest {
    clientId?: number;
}

export interface GetDonorAuthorizationDetailsRequest {
    code?: string;
}

export interface GetEmailAuthorizationDetailsRequest {
    cid: number;
    name: string;
    email: string;
}

export interface GetIntegrationCustomerRequest {
    clientId?: number;
    customerId?: number;
}

export interface GetIntegrationCustomersRequest {
    clientId?: number;
    search?: string;
    filter?: string;
    force?: boolean;
}

export interface GetIntegrationInvoicesRequest {
    clientId?: number;
    startDate?: Date;
    endDate?: Date;
    search?: string;
    filter?: string;
    force?: boolean;
}

export interface GetIntegrationsRequest {
    clientId?: number;
}

export interface GetLinkAuthorizationDetailsRequest {
    formUrl?: string;
}

export interface GetMicrodepositVerificationDetailsRequest {
    formUrl?: string;
}

export interface GetNotificationSettingsRequest {
    clientId: number;
}

export interface GetOwnerSignupDetailsRequest {
    formUrl?: string;
}

export interface GetOwnerVeriffUrlRequest {
    ownerId?: number;
    clientId?: number;
}

export interface GetProcessingAuthSettingsRequest {
    clientId?: number;
}

export interface GetProcessingAuthorizationDetailsRequest {
    code?: string;
}

export interface GetSettlementsRequest {
    clientId?: number;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface GetTransactionReportRequest {
    clientId?: number;
    startDate?: Date;
    endDate?: Date;
    filter?: string;
}

export interface GetTransactionReportByDeclineDateRequest {
    clientId?: number;
    startDate?: Date;
    endDate?: Date;
    filter?: string;
}

export interface HideNotificationRequest {
    notification?: Notification;
}

export interface HomeRequest {
    clientId?: number;
}

export interface MarkClientOnboardingGtmCompletedAccountRequest {
    clientId?: number;
}

export interface OptInFlinksRequest {
    id: number;
}

export interface PerformCustomerActionRequest {
    customerAction?: CustomerAction;
}

export interface ReSendConfirmationEmailRequest {
    requiredEmail?: RequiredEmail;
}

export interface RegisterUserRequest {
    newUser?: NewUser;
}

export interface RemoveUserRequest {
    id: number;
}

export interface ReportErrorRequest {
    modelError?: ModelError;
}

export interface ReportSettlementTransactionsRequest {
    id: number;
}

export interface SearchClientsRequest {
    startsWith?: string;
    filterId?: number;
    skip?: number;
    limit?: number;
    sort?: string;
    includedIds?: Array<number>;
    includePartialClients?: boolean;
}

export interface SearchCustomerActivityRequest {
    customerId?: number;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface SearchCustomerNotesRequest {
    customerId?: number;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface SearchCustomersRequest {
    search?: string;
    filter?: string;
    filters?: { [key: string]: boolean; };
    clientId?: number;
    customerId?: number;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface SearchFinancialTransactionsRequest {
    clientId?: number;
    customerId?: number;
    financialTransactionId?: number;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface SearchMicrodepositsRequest {
    clientId?: number;
    filter?: string;
    startDate?: Date;
    endDate?: Date;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface SearchSettlementTransactionsRequest {
    id: number;
    search?: string;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface SearchTransactionSchedulesRequest {
    search?: string;
    clientId?: number;
    customerId?: number;
    transactionScheduleId?: number;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface SearchUsersRequest {
    search?: string;
    clientId?: number;
    userId?: number;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface SendCustomerEmailAuthRequestRequest {
    emailAuthorizationRequest?: EmailAuthorizationRequest;
}

export interface SendFlinksDataRequest {
    body?: object;
}

export interface SendMicrodepositReminderRequest {
    id: number;
}

export interface SendPasswordResetRequest {
    requiredEmailRedirect?: RequiredEmailRedirect;
}

export interface SetOwnerVerifiedRequest {
    formUrl?: FormUrl;
}

export interface SignInRequest {
    credentials?: Credentials;
}

export interface SubmitClientOnboardingStateRequest {
    submitStateRequest?: SubmitStateRequest;
}

export interface SubmitClientOnboardingVeriffStateRequest {
    submitStateRequest?: SubmitStateRequest;
}

export interface SubmitDonorAuthorizationRequest {
    id: string;
    donorAuthorization?: DonorAuthorization;
}

export interface SubmitEmailAuthorizationRequest {
    emailAuthorization?: EmailAuthorization;
}

export interface SubmitLinkAuthorizationRequest {
    linkAuthorization?: LinkAuthorization;
}

export interface SubmitProcessingAuthorizationRequest {
    id: string;
    processingAuthorization?: ProcessingAuthorization;
}

export interface TransactionCsvPreviewRequest {
    clientId?: number;
    file?: Blob;
}

export interface TransactionsCsvImportRequest {
    transactionsImport?: TransactionsImport;
}

export interface UnlinkIntegrationRequest {
    clientId?: number;
}

export interface UnsubscribedByUuidRequest {
    uuid: string;
    type: string;
}

export interface UpdateAccountRequest {
    account?: Account;
}

export interface UpdateAccountSettingsRequest {
    passwordUpdate?: PasswordUpdate;
}

export interface UpdateAuthorizationEmailSettingsRequest {
    authorizationEmailSetting?: AuthorizationEmailSetting;
}

export interface UpdateAuthorizationLinkSettingsRequest {
    authorizationLinkSetting?: AuthorizationLinkSetting;
}

export interface UpdateBankAccountRequest {
    id: number;
    updateBankAccount?: UpdateBankAccount;
}

export interface UpdateClientRequest {
    id: number;
    client?: Client;
}

export interface UpdateClientLogoRequest {
    id: number;
    logo?: Blob;
}

export interface UpdateCustomerRequest {
    id: number;
    customer?: Customer;
}

export interface UpdateCustomerAuthorizationRequest {
    id: number;
    authForm?: Blob;
}

export interface UpdateCustomersIntegrationSettingsRequest {
    connectCustomers?: ConnectCustomers;
}

export interface UpdateDonorAuthSettingsRequest {
    donorAuthSetting?: DonorAuthSetting;
}

export interface UpdateIntegrationRequest {
    integrationUpdate?: IntegrationUpdate;
}

export interface UpdateNotificationSettingsRequest {
    clientId: number;
    notificationSettings?: NotificationSettings;
}

export interface UpdatePasswordRequest {
    passwordUpdate?: PasswordUpdate;
}

export interface UpdateProcessingAuthSettingsRequest {
    processingAuthSetting?: ProcessingAuthSetting;
}

export interface UpdateTransactionScheduleRequest {
    id: number;
    transactionSchedule?: TransactionSchedule;
}

export interface UpdateUserRequest {
    id: number;
    user?: User;
}

export interface UploadCertificateOfGoodStandingRequest {
    id: number;
    file?: Blob;
}

export interface UploadClientFileRequest {
    id: number;
    file?: Blob;
    title?: string;
}

export interface ValidateTokenRequest {
    token?: Token;
}

export interface Verify2faRequest {
    verificationCode?: VerificationCode;
}

export interface VerifyMicrodepositRequest {
    microdepositVerification?: MicrodepositVerification;
}


/**
 * Adds a new api key for the current client to the system
 * add api key
 */
function addApiKeyRaw<T>(requestParameters: AddApiKeyRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api_keys`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Adds a new api key for the current client to the system
* add api key
*/
export function addApiKey<T>(requestParameters: AddApiKeyRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return addApiKeyRaw(requestParameters, requestConfig);
}

/**
 * Adds a client to the system
 * add client
 */
function addClientRaw<T>(requestParameters: AddClientRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ClientToJSON(requestParameters.client),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Adds a client to the system
* add client
*/
export function addClient<T>(requestParameters: AddClientRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return addClientRaw(requestParameters, requestConfig);
}

/**
 * Add a new customer for a given client
 * add customer
 */
function addCustomerRaw<T>(requestParameters: AddCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, AddCustomer200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CustomerToJSON(requestParameters.customer),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddCustomer200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Add a new customer for a given client
* add customer
*/
export function addCustomer<T>(requestParameters: AddCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, AddCustomer200Response>): QueryConfig<T> {
    return addCustomerRaw(requestParameters, requestConfig);
}

/**
 * Adds a new transaction schedule for a given customer
 * add transaction schedule
 */
function addTransactionScheduleRaw<T>(requestParameters: AddTransactionScheduleRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_schedules`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || TransactionScheduleToJSON(requestParameters.transactionSchedule),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Adds a new transaction schedule for a given customer
* add transaction schedule
*/
export function addTransactionSchedule<T>(requestParameters: AddTransactionScheduleRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return addTransactionScheduleRaw(requestParameters, requestConfig);
}

/**
 * Adds a user to the system
 * add user
 */
function addUserRaw<T>(requestParameters: AddUserRequest, requestConfig: runtime.TypedQueryConfig<T, AddCustomer200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UserToJSON(requestParameters.user),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddCustomer200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Adds a user to the system
* add user
*/
export function addUser<T>(requestParameters: AddUserRequest, requestConfig?: runtime.TypedQueryConfig<T, AddCustomer200Response>): QueryConfig<T> {
    return addUserRaw(requestParameters, requestConfig);
}

/**
 * Apply promo code to client
 * Apply promo code to client
 */
function applyPromoCodeRaw<T>(requestParameters: ApplyPromoCodeRequest, requestConfig: runtime.TypedQueryConfig<T, ApplyPromoCode200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applyPromoCode.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{id}/apply_promo_code`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ApplyPromoCodeBodyToJSON(requestParameters.applyPromoCodeBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ApplyPromoCode200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Apply promo code to client
* Apply promo code to client
*/
export function applyPromoCode<T>(requestParameters: ApplyPromoCodeRequest, requestConfig?: runtime.TypedQueryConfig<T, ApplyPromoCode200Response>): QueryConfig<T> {
    return applyPromoCodeRaw(requestParameters, requestConfig);
}

/**
 * save Rotessa customer data from xero or quickbooks online
 * connect integration customers
 */
function connectIntegrationCustomersRaw<T>(requestParameters: ConnectIntegrationCustomersRequest, requestConfig: runtime.TypedQueryConfig<T, ConnectIntegrationCustomers200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations/customers`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ConnectCustomersToJSON(requestParameters.connectCustomers),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ConnectIntegrationCustomers200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* save Rotessa customer data from xero or quickbooks online
* connect integration customers
*/
export function connectIntegrationCustomers<T>(requestParameters: ConnectIntegrationCustomersRequest, requestConfig?: runtime.TypedQueryConfig<T, ConnectIntegrationCustomers200Response>): QueryConfig<T> {
    return connectIntegrationCustomersRaw(requestParameters, requestConfig);
}

/**
 * save Rotessa invoice data from xero or quickbooks online
 * connect integration invoices
 */
function connectIntegrationInvoicesRaw<T>(requestParameters: ConnectIntegrationInvoicesRequest, requestConfig: runtime.TypedQueryConfig<T, ConnectIntegrationInvoices200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations/invoices`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ConnectInvoicesToJSON(requestParameters.connectInvoices),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ConnectIntegrationInvoices200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* save Rotessa invoice data from xero or quickbooks online
* connect integration invoices
*/
export function connectIntegrationInvoices<T>(requestParameters: ConnectIntegrationInvoicesRequest, requestConfig?: runtime.TypedQueryConfig<T, ConnectIntegrationInvoices200Response>): QueryConfig<T> {
    return connectIntegrationInvoicesRaw(requestParameters, requestConfig);
}

/**
 * Create customers and send a message for a PAD authorization request
 * Create customers and send a PAD authorization request
 */
function createCustomerAndSendAuthorizationsRaw<T>(requestParameters: CreateCustomerAndSendAuthorizationsRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/create_and_send_authorizations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CreateCustomersAndSendAuthorizationsToJSON(requestParameters.createCustomersAndSendAuthorizations),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Create customers and send a message for a PAD authorization request
* Create customers and send a PAD authorization request
*/
export function createCustomerAndSendAuthorizations<T>(requestParameters: CreateCustomerAndSendAuthorizationsRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return createCustomerAndSendAuthorizationsRaw(requestParameters, requestConfig);
}

/**
 * write a new note associated with a customer
 * create customer note
 */
function createCustomerNoteRaw<T>(requestParameters: CreateCustomerNoteRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer_notes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CustomerNoteToJSON(requestParameters.customerNote),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* write a new note associated with a customer
* create customer note
*/
export function createCustomerNote<T>(requestParameters: CreateCustomerNoteRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return createCustomerNoteRaw(requestParameters, requestConfig);
}

/**
 * Imports selected customers CSV
 * import selected customers CSV
 */
function customersCsvImportRaw<T>(requestParameters: CustomersCsvImportRequest, requestConfig: runtime.TypedQueryConfig<T, CustomersCsvImport200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/csv_import`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CustomersImportToJSON(requestParameters.customersImport),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomersCsvImport200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Imports selected customers CSV
* import selected customers CSV
*/
export function customersCsvImport<T>(requestParameters: CustomersCsvImportRequest, requestConfig?: runtime.TypedQueryConfig<T, CustomersCsvImport200Response>): QueryConfig<T> {
    return customersCsvImportRaw(requestParameters, requestConfig);
}

/**
 * Uploads a import csv file for preview
 * upload import csv file for preview
 */
function customersCsvPreviewRaw<T>(requestParameters: CustomersCsvPreviewRequest, requestConfig: runtime.TypedQueryConfig<T, CustomersCsvPreview200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const formData = new FormData();
    if (requestParameters.clientId !== undefined) {
        formData.append('client_id', requestParameters.clientId as any);
    }

    if (requestParameters.file !== undefined) {
        formData.append('file', requestParameters.file as any);
    }

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/csv_preview`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomersCsvPreview200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Uploads a import csv file for preview
* upload import csv file for preview
*/
export function customersCsvPreview<T>(requestParameters: CustomersCsvPreviewRequest, requestConfig?: runtime.TypedQueryConfig<T, CustomersCsvPreview200Response>): QueryConfig<T> {
    return customersCsvPreviewRaw(requestParameters, requestConfig);
}

/**
 * deletes an api key
 * delete api key
 */
function deleteApiKeyRaw<T>(requestParameters: DeleteApiKeyRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteApiKey.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api_keys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* deletes an api key
* delete api key
*/
export function deleteApiKey<T>(requestParameters: DeleteApiKeyRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return deleteApiKeyRaw(requestParameters, requestConfig);
}

/**
 * Deletes a customer in the system
 * delete customer
 */
function deleteCustomerRaw<T>(requestParameters: DeleteCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCustomer.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Deletes a customer in the system
* delete customer
*/
export function deleteCustomer<T>(requestParameters: DeleteCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return deleteCustomerRaw(requestParameters, requestConfig);
}

/**
 * Deletes a customer note in the system
 * delete customer note
 */
function deleteCustomerNoteRaw<T>(requestParameters: DeleteCustomerNoteRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCustomerNote.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer_notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Deletes a customer note in the system
* delete customer note
*/
export function deleteCustomerNote<T>(requestParameters: DeleteCustomerNoteRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return deleteCustomerNoteRaw(requestParameters, requestConfig);
}

/**
 * Deletes a transaction schedule in the system
 * delete transaction schedule
 */
function deleteTransactionScheduleRaw<T>(requestParameters: DeleteTransactionScheduleRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTransactionSchedule.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Deletes a transaction schedule in the system
* delete transaction schedule
*/
export function deleteTransactionSchedule<T>(requestParameters: DeleteTransactionScheduleRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return deleteTransactionScheduleRaw(requestParameters, requestConfig);
}

/**
 * disable 2-factor authentication for the current user
 * disable 2fa
 */
function disable2faRaw<T>(requestParameters: Disable2faRequest, requestConfig: runtime.TypedQueryConfig<T, UpdateAccount200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/2fa`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters || VerificationCodeToJSON(requestParameters.verificationCode),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UpdateAccount200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* disable 2-factor authentication for the current user
* disable 2fa
*/
export function disable2fa<T>(requestParameters: Disable2faRequest, requestConfig?: runtime.TypedQueryConfig<T, UpdateAccount200Response>): QueryConfig<T> {
    return disable2faRaw(requestParameters, requestConfig);
}

/**
 * disconnect selected integration customer
 * disconnect integration customer
 */
function disconnectCustomerRaw<T>(requestParameters: DisconnectCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disconnectCustomer.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/{id}/disconnect_integration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* disconnect selected integration customer
* disconnect integration customer
*/
export function disconnectCustomer<T>(requestParameters: DisconnectCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return disconnectCustomerRaw(requestParameters, requestConfig);
}

/**
 * Export all customers from a given client to a .csv
 * export customers
 */
function exportCustomersRaw<T>(requestParameters: ExportCustomersRequest, requestConfig: runtime.TypedQueryConfig<T, ExportSettlementTransactions200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/export`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ExportSettlementTransactions200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Export all customers from a given client to a .csv
* export customers
*/
export function exportCustomers<T>(requestParameters: ExportCustomersRequest, requestConfig?: runtime.TypedQueryConfig<T, ExportSettlementTransactions200Response>): QueryConfig<T> {
    return exportCustomersRaw(requestParameters, requestConfig);
}

/**
 * Export all settlement transactions from a given settlement to a .csv
 * export settlement transactions
 */
function exportSettlementTransactionsRaw<T>(requestParameters: ExportSettlementTransactionsRequest, requestConfig: runtime.TypedQueryConfig<T, ExportSettlementTransactions200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling exportSettlementTransactions.');
    }

    let queryParameters = {};


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/settlements/{id}/export`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ExportSettlementTransactions200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Export all settlement transactions from a given settlement to a .csv
* export settlement transactions
*/
export function exportSettlementTransactions<T>(requestParameters: ExportSettlementTransactionsRequest, requestConfig?: runtime.TypedQueryConfig<T, ExportSettlementTransactions200Response>): QueryConfig<T> {
    return exportSettlementTransactionsRaw(requestParameters, requestConfig);
}

/**
 * Export all settlement transactions from a given settlement to a .pdf
 * export settlement transactions to pdf
 */
function exportSettlementTransactionsPdfRaw<T>(requestParameters: ExportSettlementTransactionsPdfRequest, requestConfig: runtime.TypedQueryConfig<T, ExportSettlementTransactionsPdf200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling exportSettlementTransactionsPdf.');
    }

    let queryParameters = {};


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/settlements/{id}/export_pdf`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ExportSettlementTransactionsPdf200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Export all settlement transactions from a given settlement to a .pdf
* export settlement transactions to pdf
*/
export function exportSettlementTransactionsPdf<T>(requestParameters: ExportSettlementTransactionsPdfRequest, requestConfig?: runtime.TypedQueryConfig<T, ExportSettlementTransactionsPdf200Response>): QueryConfig<T> {
    return exportSettlementTransactionsPdfRaw(requestParameters, requestConfig);
}

/**
 * get transaction report pdf 
 * get transaction report pdf
 */
function exportTransactionReportPdfRaw<T>(requestParameters: ExportTransactionReportPdfRequest, requestConfig: runtime.TypedQueryConfig<T, ExportSettlementTransactionsPdf200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.endDate !== undefined) {
        queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.filter !== undefined) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.reportType !== undefined) {
        queryParameters['report_type'] = requestParameters.reportType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_report/export_pdf`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ExportSettlementTransactionsPdf200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get transaction report pdf 
* get transaction report pdf
*/
export function exportTransactionReportPdf<T>(requestParameters: ExportTransactionReportPdfRequest, requestConfig?: runtime.TypedQueryConfig<T, ExportSettlementTransactionsPdf200Response>): QueryConfig<T> {
    return exportTransactionReportPdfRaw(requestParameters, requestConfig);
}

/**
 * Retrieve the active user\'s 2-factor setup uri
 * get 2fa uri
 */
function get2faUriRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Get2faUri200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/2fa`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(Get2faUri200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve the active user\'s 2-factor setup uri
* get 2fa uri
*/
export function get2faUri<T>( requestConfig?: runtime.TypedQueryConfig<T, Get2faUri200Response>): QueryConfig<T> {
    return get2faUriRaw( requestConfig);
}

/**
 * Retrieve the active user\'s account details
 * get account
 */
function getAccountRaw<T>( requestConfig: runtime.TypedQueryConfig<T, User> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/account`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UserFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve the active user\'s account details
* get account
*/
export function getAccount<T>( requestConfig?: runtime.TypedQueryConfig<T, User>): QueryConfig<T> {
    return getAccountRaw( requestConfig);
}

/**
 * By passing in the appropriate options, you can search for api keys in the system 
 * get api keys
 */
function getApiKeysRaw<T>(requestParameters: GetApiKeysRequest, requestConfig: runtime.TypedQueryConfig<T, GetApiKeys200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api_keys`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetApiKeys200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* By passing in the appropriate options, you can search for api keys in the system 
* get api keys
*/
export function getApiKeys<T>(requestParameters: GetApiKeysRequest, requestConfig?: runtime.TypedQueryConfig<T, GetApiKeys200Response>): QueryConfig<T> {
    return getApiKeysRaw(requestParameters, requestConfig);
}

/**
 * return the authorization email settings so a user can view and edit them 
 * get authorization email settings
 */
function getAuthorizationEmailSettingsRaw<T>(requestParameters: GetAuthorizationEmailSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, AuthorizationEmailSetting> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authorization_email_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AuthorizationEmailSettingFromJSON(body), text);
    }

    return config;
}

/**
* return the authorization email settings so a user can view and edit them 
* get authorization email settings
*/
export function getAuthorizationEmailSettings<T>(requestParameters: GetAuthorizationEmailSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, AuthorizationEmailSetting>): QueryConfig<T> {
    return getAuthorizationEmailSettingsRaw(requestParameters, requestConfig);
}

/**
 * return the authorization link settings so a user can view and edit them 
 * get authorization link settings
 */
function getAuthorizationLinkSettingsRaw<T>(requestParameters: GetAuthorizationLinkSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, AuthorizationLinkSetting> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authorization_link_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AuthorizationLinkSettingFromJSON(body), text);
    }

    return config;
}

/**
* return the authorization link settings so a user can view and edit them 
* get authorization link settings
*/
export function getAuthorizationLinkSettings<T>(requestParameters: GetAuthorizationLinkSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, AuthorizationLinkSetting>): QueryConfig<T> {
    return getAuthorizationLinkSettingsRaw(requestParameters, requestConfig);
}

/**
 * return the billing_invoice(s) of a client
 * get billing invoice
 */
function getBillingInvoiceByIdRaw<T>(requestParameters: GetBillingInvoiceByIdRequest, requestConfig: runtime.TypedQueryConfig<T, GetBillingInvoiceById200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBillingInvoiceById.');
    }

    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/billing_invoices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetBillingInvoiceById200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* return the billing_invoice(s) of a client
* get billing invoice
*/
export function getBillingInvoiceById<T>(requestParameters: GetBillingInvoiceByIdRequest, requestConfig?: runtime.TypedQueryConfig<T, GetBillingInvoiceById200Response>): QueryConfig<T> {
    return getBillingInvoiceByIdRaw(requestParameters, requestConfig);
}

/**
 * return the billing_invoice(s) of a client
 * get billing invoices
 */
function getBillingInvoicesRaw<T>(requestParameters: GetBillingInvoicesRequest, requestConfig: runtime.TypedQueryConfig<T, GetBillingInvoices200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/billing_invoices`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetBillingInvoices200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* return the billing_invoice(s) of a client
* get billing invoices
*/
export function getBillingInvoices<T>(requestParameters: GetBillingInvoicesRequest, requestConfig?: runtime.TypedQueryConfig<T, GetBillingInvoices200Response>): QueryConfig<T> {
    return getBillingInvoicesRaw(requestParameters, requestConfig);
}

/**
 * get a client in the system by id
 * get client by id
 */
function getClientRaw<T>(requestParameters: GetClientRequest, requestConfig: runtime.TypedQueryConfig<T, GetClient200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClient.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetClient200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get a client in the system by id
* get client by id
*/
export function getClient<T>(requestParameters: GetClientRequest, requestConfig?: runtime.TypedQueryConfig<T, GetClient200Response>): QueryConfig<T> {
    return getClientRaw(requestParameters, requestConfig);
}

/**
 * Gets the clients client file temporary url
 * get client file for client
 */
function getClientFileUrlRaw<T>(requestParameters: GetClientFileUrlRequest, requestConfig: runtime.TypedQueryConfig<T, ExportSettlementTransactionsPdf200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClientFileUrl.');
    }

    let queryParameters = {};


    if (requestParameters.filter !== undefined) {
        queryParameters['filter'] = requestParameters.filter;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{id}/client_files`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ExportSettlementTransactionsPdf200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Gets the clients client file temporary url
* get client file for client
*/
export function getClientFileUrl<T>(requestParameters: GetClientFileUrlRequest, requestConfig?: runtime.TypedQueryConfig<T, ExportSettlementTransactionsPdf200Response>): QueryConfig<T> {
    return getClientFileUrlRaw(requestParameters, requestConfig);
}

/**
 * get current onboarding step and statuses of each step for a client.
 * get clients client onboarding status
 */
function getClientsClientOnboardingRaw<T>(requestParameters: GetClientsClientOnboardingRequest, requestConfig: runtime.TypedQueryConfig<T, GetClientsClientOnboarding200Response> = {}): QueryConfig<T> {
    if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
        throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getClientsClientOnboarding.');
    }

    let queryParameters = {};


    if (requestParameters.processingClientOnboardingId !== undefined) {
        queryParameters['processing_client_onboarding_id'] = requestParameters.processingClientOnboardingId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{client_id}/client_onboardings`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetClientsClientOnboarding200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get current onboarding step and statuses of each step for a client.
* get clients client onboarding status
*/
export function getClientsClientOnboarding<T>(requestParameters: GetClientsClientOnboardingRequest, requestConfig?: runtime.TypedQueryConfig<T, GetClientsClientOnboarding200Response>): QueryConfig<T> {
    return getClientsClientOnboardingRaw(requestParameters, requestConfig);
}

/**
 * get all constants from rails
 * get rails constants
 */
function getConstantsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, GetConstants200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/constants`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetConstants200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get all constants from rails
* get rails constants
*/
export function getConstants<T>( requestConfig?: runtime.TypedQueryConfig<T, GetConstants200Response>): QueryConfig<T> {
    return getConstantsRaw( requestConfig);
}

/**
 * Gets the customer permission form file temporary url
 * get customer permission form
 */
function getCustsomerPermissionFormRaw<T>(requestParameters: GetCustsomerPermissionFormRequest, requestConfig: runtime.TypedQueryConfig<T, ExportSettlementTransactionsPdf200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustsomerPermissionForm.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/{id}/permission_form`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ExportSettlementTransactionsPdf200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Gets the customer permission form file temporary url
* get customer permission form
*/
export function getCustsomerPermissionForm<T>(requestParameters: GetCustsomerPermissionFormRequest, requestConfig?: runtime.TypedQueryConfig<T, ExportSettlementTransactionsPdf200Response>): QueryConfig<T> {
    return getCustsomerPermissionFormRaw(requestParameters, requestConfig);
}

/**
 * return the donor authorization settings so a user can view and edit them
 * get donor auth settings
 */
function getDonorAuthSettingsRaw<T>(requestParameters: GetDonorAuthSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, GetDonorAuthSettings200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/donor_auth_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetDonorAuthSettings200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* return the donor authorization settings so a user can view and edit them
* get donor auth settings
*/
export function getDonorAuthSettings<T>(requestParameters: GetDonorAuthSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetDonorAuthSettings200Response>): QueryConfig<T> {
    return getDonorAuthSettingsRaw(requestParameters, requestConfig);
}

/**
 * retrieve the details of the donor authorization form that have been configured by the client.
 * get donor authorization details
 */
function getDonorAuthorizationDetailsRaw<T>(requestParameters: GetDonorAuthorizationDetailsRequest, requestConfig: runtime.TypedQueryConfig<T, GetDonorAuthorizationDetails200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.code !== undefined) {
        queryParameters['code'] = requestParameters.code;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/donor_authorizations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetDonorAuthorizationDetails200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* retrieve the details of the donor authorization form that have been configured by the client.
* get donor authorization details
*/
export function getDonorAuthorizationDetails<T>(requestParameters: GetDonorAuthorizationDetailsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetDonorAuthorizationDetails200Response>): QueryConfig<T> {
    return getDonorAuthorizationDetailsRaw(requestParameters, requestConfig);
}

/**
 * retrieve the details of the authorization form that have been configured by the client. 
 * get email authorization details
 */
function getEmailAuthorizationDetailsRaw<T>(requestParameters: GetEmailAuthorizationDetailsRequest, requestConfig: runtime.TypedQueryConfig<T, GetEmailAuthorizationDetails200Response> = {}): QueryConfig<T> {
    if (requestParameters.cid === null || requestParameters.cid === undefined) {
        throw new runtime.RequiredError('cid','Required parameter requestParameters.cid was null or undefined when calling getEmailAuthorizationDetails.');
    }

    if (requestParameters.name === null || requestParameters.name === undefined) {
        throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getEmailAuthorizationDetails.');
    }

    if (requestParameters.email === null || requestParameters.email === undefined) {
        throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getEmailAuthorizationDetails.');
    }

    let queryParameters = {};


    if (requestParameters.cid !== undefined) {
        queryParameters['cid'] = requestParameters.cid;
    }


    if (requestParameters.name !== undefined) {
        queryParameters['name'] = requestParameters.name;
    }


    if (requestParameters.email !== undefined) {
        queryParameters['email'] = requestParameters.email;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/email_authorizations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetEmailAuthorizationDetails200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* retrieve the details of the authorization form that have been configured by the client. 
* get email authorization details
*/
export function getEmailAuthorizationDetails<T>(requestParameters: GetEmailAuthorizationDetailsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetEmailAuthorizationDetails200Response>): QueryConfig<T> {
    return getEmailAuthorizationDetailsRaw(requestParameters, requestConfig);
}

/**
 * get integration customer info for the selected client
 * get integration customer
 */
function getIntegrationCustomerRaw<T>(requestParameters: GetIntegrationCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, GetIntegrationCustomer200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.customerId !== undefined) {
        queryParameters['customer_id'] = requestParameters.customerId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations/customer`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetIntegrationCustomer200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get integration customer info for the selected client
* get integration customer
*/
export function getIntegrationCustomer<T>(requestParameters: GetIntegrationCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, GetIntegrationCustomer200Response>): QueryConfig<T> {
    return getIntegrationCustomerRaw(requestParameters, requestConfig);
}

/**
 * get integration customer info for the selected client
 * get integration customers
 */
function getIntegrationCustomersRaw<T>(requestParameters: GetIntegrationCustomersRequest, requestConfig: runtime.TypedQueryConfig<T, GetIntegrationCustomers200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.search !== undefined) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter !== undefined) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.force !== undefined) {
        queryParameters['force'] = requestParameters.force;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations/customers`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetIntegrationCustomers200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get integration customer info for the selected client
* get integration customers
*/
export function getIntegrationCustomers<T>(requestParameters: GetIntegrationCustomersRequest, requestConfig?: runtime.TypedQueryConfig<T, GetIntegrationCustomers200Response>): QueryConfig<T> {
    return getIntegrationCustomersRaw(requestParameters, requestConfig);
}

/**
 * get integration invoice info for the selected client
 * get integration invoices
 */
function getIntegrationInvoicesRaw<T>(requestParameters: GetIntegrationInvoicesRequest, requestConfig: runtime.TypedQueryConfig<T, GetIntegrationInvoices200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.endDate !== undefined) {
        queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.search !== undefined) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter !== undefined) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.force !== undefined) {
        queryParameters['force'] = requestParameters.force;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations/invoices`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetIntegrationInvoices200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get integration invoice info for the selected client
* get integration invoices
*/
export function getIntegrationInvoices<T>(requestParameters: GetIntegrationInvoicesRequest, requestConfig?: runtime.TypedQueryConfig<T, GetIntegrationInvoices200Response>): QueryConfig<T> {
    return getIntegrationInvoicesRaw(requestParameters, requestConfig);
}

/**
 * get integration info for the selected client
 * get integrations
 */
function getIntegrationsRaw<T>(requestParameters: GetIntegrationsRequest, requestConfig: runtime.TypedQueryConfig<T, GetIntegrations200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetIntegrations200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get integration info for the selected client
* get integrations
*/
export function getIntegrations<T>(requestParameters: GetIntegrationsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetIntegrations200Response>): QueryConfig<T> {
    return getIntegrationsRaw(requestParameters, requestConfig);
}

/**
 * retrieve the details of the authorization form that have been configured by the client. 
 * get link authorization details
 */
function getLinkAuthorizationDetailsRaw<T>(requestParameters: GetLinkAuthorizationDetailsRequest, requestConfig: runtime.TypedQueryConfig<T, GetLinkAuthorizationDetails200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.formUrl !== undefined) {
        queryParameters['form_url'] = requestParameters.formUrl;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/link_authorizations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetLinkAuthorizationDetails200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* retrieve the details of the authorization form that have been configured by the client. 
* get link authorization details
*/
export function getLinkAuthorizationDetails<T>(requestParameters: GetLinkAuthorizationDetailsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetLinkAuthorizationDetails200Response>): QueryConfig<T> {
    return getLinkAuthorizationDetailsRaw(requestParameters, requestConfig);
}

/**
 * submit with a form url to retrieve data for a microdeposit verification.
 * get microdeposit verification details
 */
function getMicrodepositVerificationDetailsRaw<T>(requestParameters: GetMicrodepositVerificationDetailsRequest, requestConfig: runtime.TypedQueryConfig<T, MicrodepositVerificationFormDetails> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.formUrl !== undefined) {
        queryParameters['form_url'] = requestParameters.formUrl;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/microdeposit_verifications`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MicrodepositVerificationFormDetailsFromJSON(body), text);
    }

    return config;
}

/**
* submit with a form url to retrieve data for a microdeposit verification.
* get microdeposit verification details
*/
export function getMicrodepositVerificationDetails<T>(requestParameters: GetMicrodepositVerificationDetailsRequest, requestConfig?: runtime.TypedQueryConfig<T, MicrodepositVerificationFormDetails>): QueryConfig<T> {
    return getMicrodepositVerificationDetailsRaw(requestParameters, requestConfig);
}

/**
 * return the notification settings for a client so a user can view and edit them 
 * get a clients notification settings
 */
function getNotificationSettingsRaw<T>(requestParameters: GetNotificationSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, GetNotificationSettings200Response> = {}): QueryConfig<T> {
    if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
        throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getNotificationSettings.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{client_id}/notification_settings`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetNotificationSettings200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* return the notification settings for a client so a user can view and edit them 
* get a clients notification settings
*/
export function getNotificationSettings<T>(requestParameters: GetNotificationSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetNotificationSettings200Response>): QueryConfig<T> {
    return getNotificationSettingsRaw(requestParameters, requestConfig);
}

/**
 * Get all unread notifications for the current user
 * get notifications
 */
function getNotificationsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, GetNotifications200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/notifications`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetNotifications200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get all unread notifications for the current user
* get notifications
*/
export function getNotifications<T>( requestConfig?: runtime.TypedQueryConfig<T, GetNotifications200Response>): QueryConfig<T> {
    return getNotificationsRaw( requestConfig);
}

/**
 * get form details to display during owner signup.
 * get owner signup details
 */
function getOwnerSignupDetailsRaw<T>(requestParameters: GetOwnerSignupDetailsRequest, requestConfig: runtime.TypedQueryConfig<T, GetOwnerSignupDetails200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.formUrl !== undefined) {
        queryParameters['form_url'] = requestParameters.formUrl;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/owner_signup`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetOwnerSignupDetails200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get form details to display during owner signup.
* get owner signup details
*/
export function getOwnerSignupDetails<T>(requestParameters: GetOwnerSignupDetailsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetOwnerSignupDetails200Response>): QueryConfig<T> {
    return getOwnerSignupDetailsRaw(requestParameters, requestConfig);
}

/**
 * get the veriff_url for an owner
 * get owner veriff url
 */
function getOwnerVeriffUrlRaw<T>(requestParameters: GetOwnerVeriffUrlRequest, requestConfig: runtime.TypedQueryConfig<T, GetOwnerVeriffUrl200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.ownerId !== undefined) {
        queryParameters['owner_id'] = requestParameters.ownerId;
    }


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/client_onboardings/owner_veriff_url`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetOwnerVeriffUrl200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get the veriff_url for an owner
* get owner veriff url
*/
export function getOwnerVeriffUrl<T>(requestParameters: GetOwnerVeriffUrlRequest, requestConfig?: runtime.TypedQueryConfig<T, GetOwnerVeriffUrl200Response>): QueryConfig<T> {
    return getOwnerVeriffUrlRaw(requestParameters, requestConfig);
}

/**
 * return the processing authorization settings so a user can view and edit them 
 * get auth settings
 */
function getProcessingAuthSettingsRaw<T>(requestParameters: GetProcessingAuthSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, GetProcessingAuthSettings200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/processing_auth_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetProcessingAuthSettings200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* return the processing authorization settings so a user can view and edit them 
* get auth settings
*/
export function getProcessingAuthSettings<T>(requestParameters: GetProcessingAuthSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetProcessingAuthSettings200Response>): QueryConfig<T> {
    return getProcessingAuthSettingsRaw(requestParameters, requestConfig);
}

/**
 * retrieve the details of the processing authorization form that have been configured by the client.
 * get processing authorization details
 */
function getProcessingAuthorizationDetailsRaw<T>(requestParameters: GetProcessingAuthorizationDetailsRequest, requestConfig: runtime.TypedQueryConfig<T, GetProcessingAuthorizationDetails200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.code !== undefined) {
        queryParameters['code'] = requestParameters.code;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/processing_authorizations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetProcessingAuthorizationDetails200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* retrieve the details of the processing authorization form that have been configured by the client.
* get processing authorization details
*/
export function getProcessingAuthorizationDetails<T>(requestParameters: GetProcessingAuthorizationDetailsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetProcessingAuthorizationDetails200Response>): QueryConfig<T> {
    return getProcessingAuthorizationDetailsRaw(requestParameters, requestConfig);
}

/**
 * Get release helpers for client side (not client specific)
 * Get release helpers for client side (not client specific)
 */
function getReleaseHelpersRaw<T>( requestConfig: runtime.TypedQueryConfig<T, GetReleaseHelpers200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/release_helpers`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetReleaseHelpers200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get release helpers for client side (not client specific)
* Get release helpers for client side (not client specific)
*/
export function getReleaseHelpers<T>( requestConfig?: runtime.TypedQueryConfig<T, GetReleaseHelpers200Response>): QueryConfig<T> {
    return getReleaseHelpersRaw( requestConfig);
}

/**
 * By passing in the appropriate options, you can search for settlements in the system 
 * get settlements
 */
function getSettlementsRaw<T>(requestParameters: GetSettlementsRequest, requestConfig: runtime.TypedQueryConfig<T, GetSettlements200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/settlements`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetSettlements200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* By passing in the appropriate options, you can search for settlements in the system 
* get settlements
*/
export function getSettlements<T>(requestParameters: GetSettlementsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetSettlements200Response>): QueryConfig<T> {
    return getSettlementsRaw(requestParameters, requestConfig);
}

/**
 * get transaction report 
 * get transaction report
 */
function getTransactionReportRaw<T>(requestParameters: GetTransactionReportRequest, requestConfig: runtime.TypedQueryConfig<T, GetTransactionReport200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.endDate !== undefined) {
        queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.filter !== undefined) {
        queryParameters['filter'] = requestParameters.filter;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_report`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetTransactionReport200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get transaction report 
* get transaction report
*/
export function getTransactionReport<T>(requestParameters: GetTransactionReportRequest, requestConfig?: runtime.TypedQueryConfig<T, GetTransactionReport200Response>): QueryConfig<T> {
    return getTransactionReportRaw(requestParameters, requestConfig);
}

/**
 * get transaction report by decline date 
 * get transaction report by decline date
 */
function getTransactionReportByDeclineDateRaw<T>(requestParameters: GetTransactionReportByDeclineDateRequest, requestConfig: runtime.TypedQueryConfig<T, GetTransactionReportByDeclineDate200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.endDate !== undefined) {
        queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.filter !== undefined) {
        queryParameters['filter'] = requestParameters.filter;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_report/by_decline_date`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetTransactionReportByDeclineDate200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* get transaction report by decline date 
* get transaction report by decline date
*/
export function getTransactionReportByDeclineDate<T>(requestParameters: GetTransactionReportByDeclineDateRequest, requestConfig?: runtime.TypedQueryConfig<T, GetTransactionReportByDeclineDate200Response>): QueryConfig<T> {
    return getTransactionReportByDeclineDateRaw(requestParameters, requestConfig);
}

/**
 * When a user clicks \"close\", set the notification as read
 * hide notification
 */
function hideNotificationRaw<T>(requestParameters: HideNotificationRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/notifications`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || NotificationToJSON(requestParameters.notification),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* When a user clicks \"close\", set the notification as read
* hide notification
*/
export function hideNotification<T>(requestParameters: HideNotificationRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return hideNotificationRaw(requestParameters, requestConfig);
}

/**
 * return data to be used to render homepage charts etc. 
 * get homepage data
 */
function homeRaw<T>(requestParameters: HomeRequest, requestConfig: runtime.TypedQueryConfig<T, Home200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/home`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(Home200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* return data to be used to render homepage charts etc. 
* get homepage data
*/
export function home<T>(requestParameters: HomeRequest, requestConfig?: runtime.TypedQueryConfig<T, Home200Response>): QueryConfig<T> {
    return homeRaw(requestParameters, requestConfig);
}

/**
 * Marks the gtm event completedAccount as triggered for the client onboarding
 * Marks the gtm event completedAccount as triggered for the client onboarding
 */
function markClientOnboardingGtmCompletedAccountRaw<T>(requestParameters: MarkClientOnboardingGtmCompletedAccountRequest, requestConfig: runtime.TypedQueryConfig<T, SubmitClientOnboardingState200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const formData = new FormData();
    if (requestParameters.clientId !== undefined) {
        formData.append('client_id', requestParameters.clientId as any);
    }

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/client_onboardings/mark_gtm_completed_account`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SubmitClientOnboardingState200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Marks the gtm event completedAccount as triggered for the client onboarding
* Marks the gtm event completedAccount as triggered for the client onboarding
*/
export function markClientOnboardingGtmCompletedAccount<T>(requestParameters: MarkClientOnboardingGtmCompletedAccountRequest, requestConfig?: runtime.TypedQueryConfig<T, SubmitClientOnboardingState200Response>): QueryConfig<T> {
    return markClientOnboardingGtmCompletedAccountRaw(requestParameters, requestConfig);
}

/**
 * opt-in to the flinks feature for authorizations
 * opt in to flinks
 */
function optInFlinksRaw<T>(requestParameters: OptInFlinksRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling optInFlinks.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/flinks_opt_in/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* opt-in to the flinks feature for authorizations
* opt in to flinks
*/
export function optInFlinks<T>(requestParameters: OptInFlinksRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return optInFlinksRaw(requestParameters, requestConfig);
}

/**
 * Updates a customer in the system
 * perform customer action
 */
function performCustomerActionRaw<T>(requestParameters: PerformCustomerActionRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/action`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CustomerActionToJSON(requestParameters.customerAction),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Updates a customer in the system
* perform customer action
*/
export function performCustomerAction<T>(requestParameters: PerformCustomerActionRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return performCustomerActionRaw(requestParameters, requestConfig);
}

/**
 * send a password reset confirmation email.
 * re-send confirmation email
 */
function reSendConfirmationEmailRaw<T>(requestParameters: ReSendConfirmationEmailRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/auth/confirmation`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || RequiredEmailToJSON(requestParameters.requiredEmail),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* send a password reset confirmation email.
* re-send confirmation email
*/
export function reSendConfirmationEmail<T>(requestParameters: ReSendConfirmationEmailRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return reSendConfirmationEmailRaw(requestParameters, requestConfig);
}

/**
 * send email and password to create a new user.
 * register user
 */
function registerUserRaw<T>(requestParameters: RegisterUserRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/auth`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || NewUserToJSON(requestParameters.newUser),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* send email and password to create a new user.
* register user
*/
export function registerUser<T>(requestParameters: RegisterUserRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return registerUserRaw(requestParameters, requestConfig);
}

/**
 * Removes a user\'s access to the current client.
 * remove user
 */
function removeUserRaw<T>(requestParameters: RemoveUserRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeUser.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Removes a user\'s access to the current client.
* remove user
*/
export function removeUser<T>(requestParameters: RemoveUserRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return removeUserRaw(requestParameters, requestConfig);
}

/**
 * Report an error to the backend
 * report error
 */
function reportErrorRaw<T>(requestParameters: ReportErrorRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/error`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ModelErrorToJSON(requestParameters.modelError),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Report an error to the backend
* report error
*/
export function reportError<T>(requestParameters: ReportErrorRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return reportErrorRaw(requestParameters, requestConfig);
}

/**
 * Report all non-reported settlement transactions from a given settlement to integration
 * report settlement transactions to integration
 */
function reportSettlementTransactionsRaw<T>(requestParameters: ReportSettlementTransactionsRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reportSettlementTransactions.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/settlements/{id}/report_payments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Report all non-reported settlement transactions from a given settlement to integration
* report settlement transactions to integration
*/
export function reportSettlementTransactions<T>(requestParameters: ReportSettlementTransactionsRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return reportSettlementTransactionsRaw(requestParameters, requestConfig);
}

/**
 * By passing in the appropriate options, you can search for clients in the system 
 * search clients
 */
function searchClientsRaw<T>(requestParameters: SearchClientsRequest, requestConfig: runtime.TypedQueryConfig<T, SearchClients200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.startsWith !== undefined) {
        queryParameters['starts_with'] = requestParameters.startsWith;
    }


    if (requestParameters.filterId !== undefined) {
        queryParameters['filter_id'] = requestParameters.filterId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.includedIds) {
        queryParameters['included_ids'] = requestParameters.includedIds;
    }


    if (requestParameters.includePartialClients !== undefined) {
        queryParameters['include_partial_clients'] = requestParameters.includePartialClients;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchClients200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* By passing in the appropriate options, you can search for clients in the system 
* search clients
*/
export function searchClients<T>(requestParameters: SearchClientsRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchClients200Response>): QueryConfig<T> {
    return searchClientsRaw(requestParameters, requestConfig);
}

/**
 * Search customer activity 
 * search customer activity
 */
function searchCustomerActivityRaw<T>(requestParameters: SearchCustomerActivityRequest, requestConfig: runtime.TypedQueryConfig<T, SearchCustomerActivity200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.customerId !== undefined) {
        queryParameters['customer_id'] = requestParameters.customerId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer_activity`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchCustomerActivity200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Search customer activity 
* search customer activity
*/
export function searchCustomerActivity<T>(requestParameters: SearchCustomerActivityRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchCustomerActivity200Response>): QueryConfig<T> {
    return searchCustomerActivityRaw(requestParameters, requestConfig);
}

/**
 * Search customer notes 
 * search customer notes
 */
function searchCustomerNotesRaw<T>(requestParameters: SearchCustomerNotesRequest, requestConfig: runtime.TypedQueryConfig<T, SearchCustomerNotes200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.customerId !== undefined) {
        queryParameters['customer_id'] = requestParameters.customerId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer_notes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchCustomerNotes200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Search customer notes 
* search customer notes
*/
export function searchCustomerNotes<T>(requestParameters: SearchCustomerNotesRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchCustomerNotes200Response>): QueryConfig<T> {
    return searchCustomerNotesRaw(requestParameters, requestConfig);
}

/**
 * By passing in the appropriate options, you can search for customers in the system 
 * search customers
 */
function searchCustomersRaw<T>(requestParameters: SearchCustomersRequest, requestConfig: runtime.TypedQueryConfig<T, SearchCustomers200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.search !== undefined) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter !== undefined) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.filters !== undefined) {
        queryParameters['filters'] = requestParameters.filters;
    }


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.customerId !== undefined) {
        queryParameters['customer_id'] = requestParameters.customerId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchCustomers200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* By passing in the appropriate options, you can search for customers in the system 
* search customers
*/
export function searchCustomers<T>(requestParameters: SearchCustomersRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchCustomers200Response>): QueryConfig<T> {
    return searchCustomersRaw(requestParameters, requestConfig);
}

/**
 * Search financial transactions 
 * search financial transactions
 */
function searchFinancialTransactionsRaw<T>(requestParameters: SearchFinancialTransactionsRequest, requestConfig: runtime.TypedQueryConfig<T, SearchFinancialTransactions200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.customerId !== undefined) {
        queryParameters['customer_id'] = requestParameters.customerId;
    }


    if (requestParameters.financialTransactionId !== undefined) {
        queryParameters['financial_transaction_id'] = requestParameters.financialTransactionId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/financial_transactions`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchFinancialTransactions200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Search financial transactions 
* search financial transactions
*/
export function searchFinancialTransactions<T>(requestParameters: SearchFinancialTransactionsRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchFinancialTransactions200Response>): QueryConfig<T> {
    return searchFinancialTransactionsRaw(requestParameters, requestConfig);
}

/**
 * By passing in the appropriate options, you can search for microdeposits in the system
 * search microdeposits
 */
function searchMicrodepositsRaw<T>(requestParameters: SearchMicrodepositsRequest, requestConfig: runtime.TypedQueryConfig<T, SearchMicrodeposits200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.filter !== undefined) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.endDate !== undefined) {
        queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/microdeposits`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchMicrodeposits200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* By passing in the appropriate options, you can search for microdeposits in the system
* search microdeposits
*/
export function searchMicrodeposits<T>(requestParameters: SearchMicrodepositsRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchMicrodeposits200Response>): QueryConfig<T> {
    return searchMicrodepositsRaw(requestParameters, requestConfig);
}

/**
 * Search settlement transactions 
 * search settlement transactions
 */
function searchSettlementTransactionsRaw<T>(requestParameters: SearchSettlementTransactionsRequest, requestConfig: runtime.TypedQueryConfig<T, SearchSettlementTransactions200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling searchSettlementTransactions.');
    }

    let queryParameters = {};


    if (requestParameters.search !== undefined) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/settlements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchSettlementTransactions200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Search settlement transactions 
* search settlement transactions
*/
export function searchSettlementTransactions<T>(requestParameters: SearchSettlementTransactionsRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchSettlementTransactions200Response>): QueryConfig<T> {
    return searchSettlementTransactionsRaw(requestParameters, requestConfig);
}

/**
 * Search transaction schedules 
 * search transaction schedules
 */
function searchTransactionSchedulesRaw<T>(requestParameters: SearchTransactionSchedulesRequest, requestConfig: runtime.TypedQueryConfig<T, SearchTransactionSchedules200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.search !== undefined) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.customerId !== undefined) {
        queryParameters['customer_id'] = requestParameters.customerId;
    }


    if (requestParameters.transactionScheduleId !== undefined) {
        queryParameters['transaction_schedule_id'] = requestParameters.transactionScheduleId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_schedules`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchTransactionSchedules200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Search transaction schedules 
* search transaction schedules
*/
export function searchTransactionSchedules<T>(requestParameters: SearchTransactionSchedulesRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchTransactionSchedules200Response>): QueryConfig<T> {
    return searchTransactionSchedulesRaw(requestParameters, requestConfig);
}

/**
 * By passing in the appropriate options, you can search for users assigned to the given client. 
 * search users
 */
function searchUsersRaw<T>(requestParameters: SearchUsersRequest, requestConfig: runtime.TypedQueryConfig<T, SearchUsers200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.search !== undefined) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }


    if (requestParameters.userId !== undefined) {
        queryParameters['user_id'] = requestParameters.userId;
    }


    if (requestParameters.skip !== undefined) {
        queryParameters['skip'] = requestParameters.skip;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchUsers200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* By passing in the appropriate options, you can search for users assigned to the given client. 
* search users
*/
export function searchUsers<T>(requestParameters: SearchUsersRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchUsers200Response>): QueryConfig<T> {
    return searchUsersRaw(requestParameters, requestConfig);
}

/**
 * Send an email to a customer requesting completion of their bank information in Rotessa.
 * send customer email auth request
 */
function sendCustomerEmailAuthRequestRaw<T>(requestParameters: SendCustomerEmailAuthRequestRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/email_auth_request`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || EmailAuthorizationRequestToJSON(requestParameters.emailAuthorizationRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Send an email to a customer requesting completion of their bank information in Rotessa.
* send customer email auth request
*/
export function sendCustomerEmailAuthRequest<T>(requestParameters: SendCustomerEmailAuthRequestRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return sendCustomerEmailAuthRequestRaw(requestParameters, requestConfig);
}

/**
 * send data from Flinks Connect to the backend for processing
 * send flinks data
 */
function sendFlinksDataRaw<T>(requestParameters: SendFlinksDataRequest, requestConfig: runtime.TypedQueryConfig<T, SendFlinksData200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/flinks`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.body as any,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SendFlinksData200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* send data from Flinks Connect to the backend for processing
* send flinks data
*/
export function sendFlinksData<T>(requestParameters: SendFlinksDataRequest, requestConfig?: runtime.TypedQueryConfig<T, SendFlinksData200Response>): QueryConfig<T> {
    return sendFlinksDataRaw(requestParameters, requestConfig);
}

/**
 * Sends a reminder email about verifying microdeposit amounts
 * Send microdeposit reminder
 */
function sendMicrodepositReminderRaw<T>(requestParameters: SendMicrodepositReminderRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendMicrodepositReminder.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/microdeposits/{id}/reminder`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Sends a reminder email about verifying microdeposit amounts
* Send microdeposit reminder
*/
export function sendMicrodepositReminder<T>(requestParameters: SendMicrodepositReminderRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return sendMicrodepositReminderRaw(requestParameters, requestConfig);
}

/**
 * send a password reset confirmation email.
 * send password reset
 */
function sendPasswordResetRaw<T>(requestParameters: SendPasswordResetRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/auth/password`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || RequiredEmailRedirectToJSON(requestParameters.requiredEmailRedirect),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* send a password reset confirmation email.
* send password reset
*/
export function sendPasswordReset<T>(requestParameters: SendPasswordResetRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return sendPasswordResetRaw(requestParameters, requestConfig);
}

/**
 * Update owner on client signup after veriff is finished.
 * set owner verified
 */
function setOwnerVerifiedRaw<T>(requestParameters: SetOwnerVerifiedRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/owner_signup/verify`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || FormUrlToJSON(requestParameters.formUrl),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Update owner on client signup after veriff is finished.
* set owner verified
*/
export function setOwnerVerified<T>(requestParameters: SetOwnerVerifiedRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return setOwnerVerifiedRaw(requestParameters, requestConfig);
}

/**
 * send email and password to log in to the app.
 */
function signInRaw<T>(requestParameters: SignInRequest, requestConfig: runtime.TypedQueryConfig<T, SignIn200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/auth/sign_in`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CredentialsToJSON(requestParameters.credentials),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SignIn200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* send email and password to log in to the app.
*/
export function signIn<T>(requestParameters: SignInRequest, requestConfig?: runtime.TypedQueryConfig<T, SignIn200Response>): QueryConfig<T> {
    return signInRaw(requestParameters, requestConfig);
}

/**
 * end the user\'s current session.
 * sign out
 */
function signOutRaw<T>( requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/auth/sign_out`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* end the user\'s current session.
* sign out
*/
export function signOut<T>( requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return signOutRaw( requestConfig);
}

/**
 * Submit to move the next state of the onboarding process.
 * Submit client onboarding state
 */
function submitClientOnboardingStateRaw<T>(requestParameters: SubmitClientOnboardingStateRequest, requestConfig: runtime.TypedQueryConfig<T, SubmitClientOnboardingState200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/client_onboardings/submit_state`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || SubmitStateRequestToJSON(requestParameters.submitStateRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SubmitClientOnboardingState200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Submit to move the next state of the onboarding process.
* Submit client onboarding state
*/
export function submitClientOnboardingState<T>(requestParameters: SubmitClientOnboardingStateRequest, requestConfig?: runtime.TypedQueryConfig<T, SubmitClientOnboardingState200Response>): QueryConfig<T> {
    return submitClientOnboardingStateRaw(requestParameters, requestConfig);
}

/**
 * Submit to move the next state of the onboarding veriff process.
 * Submit client onboarding veriff state
 */
function submitClientOnboardingVeriffStateRaw<T>(requestParameters: SubmitClientOnboardingVeriffStateRequest, requestConfig: runtime.TypedQueryConfig<T, SubmitClientOnboardingState200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/client_onboardings/submit_veriff_state`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || SubmitStateRequestToJSON(requestParameters.submitStateRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SubmitClientOnboardingState200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Submit to move the next state of the onboarding veriff process.
* Submit client onboarding veriff state
*/
export function submitClientOnboardingVeriffState<T>(requestParameters: SubmitClientOnboardingVeriffStateRequest, requestConfig?: runtime.TypedQueryConfig<T, SubmitClientOnboardingState200Response>): QueryConfig<T> {
    return submitClientOnboardingVeriffStateRaw(requestParameters, requestConfig);
}

/**
 * submit the donor authorization details from the form.
 * submit donor authorization
 */
function submitDonorAuthorizationRaw<T>(requestParameters: SubmitDonorAuthorizationRequest, requestConfig: runtime.TypedQueryConfig<T, GetDonorAuthorizationDetails200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitDonorAuthorization.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/donor_authorizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || DonorAuthorizationToJSON(requestParameters.donorAuthorization),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetDonorAuthorizationDetails200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* submit the donor authorization details from the form.
* submit donor authorization
*/
export function submitDonorAuthorization<T>(requestParameters: SubmitDonorAuthorizationRequest, requestConfig?: runtime.TypedQueryConfig<T, GetDonorAuthorizationDetails200Response>): QueryConfig<T> {
    return submitDonorAuthorizationRaw(requestParameters, requestConfig);
}

/**
 * Adds a new customer for the given client and authorizes payments according to the set schedule 
 * submit email authorization
 */
function submitEmailAuthorizationRaw<T>(requestParameters: SubmitEmailAuthorizationRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/email_authorizations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || EmailAuthorizationToJSON(requestParameters.emailAuthorization),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Adds a new customer for the given client and authorizes payments according to the set schedule 
* submit email authorization
*/
export function submitEmailAuthorization<T>(requestParameters: SubmitEmailAuthorizationRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return submitEmailAuthorizationRaw(requestParameters, requestConfig);
}

/**
 * Adds a new customer for the given client and authorizes payments according to the set schedule 
 * submit link authorization
 */
function submitLinkAuthorizationRaw<T>(requestParameters: SubmitLinkAuthorizationRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/link_authorizations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || LinkAuthorizationToJSON(requestParameters.linkAuthorization),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Adds a new customer for the given client and authorizes payments according to the set schedule 
* submit link authorization
*/
export function submitLinkAuthorization<T>(requestParameters: SubmitLinkAuthorizationRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return submitLinkAuthorizationRaw(requestParameters, requestConfig);
}

/**
 * submit the processing authorization details from the form.
 * submit processing authorization
 */
function submitProcessingAuthorizationRaw<T>(requestParameters: SubmitProcessingAuthorizationRequest, requestConfig: runtime.TypedQueryConfig<T, GetProcessingAuthorizationDetails200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitProcessingAuthorization.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/processing_authorizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || ProcessingAuthorizationToJSON(requestParameters.processingAuthorization),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetProcessingAuthorizationDetails200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* submit the processing authorization details from the form.
* submit processing authorization
*/
export function submitProcessingAuthorization<T>(requestParameters: SubmitProcessingAuthorizationRequest, requestConfig?: runtime.TypedQueryConfig<T, GetProcessingAuthorizationDetails200Response>): QueryConfig<T> {
    return submitProcessingAuthorizationRaw(requestParameters, requestConfig);
}

/**
 * Uploads a import csv file for import
 * upload import csv file
 */
function transactionCsvPreviewRaw<T>(requestParameters: TransactionCsvPreviewRequest, requestConfig: runtime.TypedQueryConfig<T, TransactionCsvPreview200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const formData = new FormData();
    if (requestParameters.clientId !== undefined) {
        formData.append('client_id', requestParameters.clientId as any);
    }

    if (requestParameters.file !== undefined) {
        formData.append('file', requestParameters.file as any);
    }

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_schedules/csv_preview`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TransactionCsvPreview200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Uploads a import csv file for import
* upload import csv file
*/
export function transactionCsvPreview<T>(requestParameters: TransactionCsvPreviewRequest, requestConfig?: runtime.TypedQueryConfig<T, TransactionCsvPreview200Response>): QueryConfig<T> {
    return transactionCsvPreviewRaw(requestParameters, requestConfig);
}

/**
 * Imports CSV transactions
 * import CSV transactions
 */
function transactionsCsvImportRaw<T>(requestParameters: TransactionsCsvImportRequest, requestConfig: runtime.TypedQueryConfig<T, CustomersCsvImport200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_schedules/csv_import`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || TransactionsImportToJSON(requestParameters.transactionsImport),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomersCsvImport200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Imports CSV transactions
* import CSV transactions
*/
export function transactionsCsvImport<T>(requestParameters: TransactionsCsvImportRequest, requestConfig?: runtime.TypedQueryConfig<T, CustomersCsvImport200Response>): QueryConfig<T> {
    return transactionsCsvImportRaw(requestParameters, requestConfig);
}

/**
 * unlink integration for the selected client
 * unlink integration
 */
function unlinkIntegrationRaw<T>(requestParameters: UnlinkIntegrationRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = {};


    if (requestParameters.clientId !== undefined) {
        queryParameters['client_id'] = requestParameters.clientId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations/unlink`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* unlink integration for the selected client
* unlink integration
*/
export function unlinkIntegration<T>(requestParameters: UnlinkIntegrationRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return unlinkIntegrationRaw(requestParameters, requestConfig);
}

/**
 * unsubscribe a customer from notifications by the customer UUID
 * unsubscribe a customer by uuid
 */
function unsubscribedByUuidRaw<T>(requestParameters: UnsubscribedByUuidRequest, requestConfig: runtime.TypedQueryConfig<T, UnsubscribedByUuid200Response> = {}): QueryConfig<T> {
    if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
        throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling unsubscribedByUuid.');
    }

    if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling unsubscribedByUuid.');
    }

    let queryParameters = {};


    if (requestParameters.type !== undefined) {
        queryParameters['type'] = requestParameters.type;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/unsubscribe/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UnsubscribedByUuid200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* unsubscribe a customer from notifications by the customer UUID
* unsubscribe a customer by uuid
*/
export function unsubscribedByUuid<T>(requestParameters: UnsubscribedByUuidRequest, requestConfig?: runtime.TypedQueryConfig<T, UnsubscribedByUuid200Response>): QueryConfig<T> {
    return unsubscribedByUuidRaw(requestParameters, requestConfig);
}

/**
 * Updates the active user\'s account details
 * update account
 */
function updateAccountRaw<T>(requestParameters: UpdateAccountRequest, requestConfig: runtime.TypedQueryConfig<T, UpdateAccount200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/account`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || AccountToJSON(requestParameters.account),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UpdateAccount200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Updates the active user\'s account details
* update account
*/
export function updateAccount<T>(requestParameters: UpdateAccountRequest, requestConfig?: runtime.TypedQueryConfig<T, UpdateAccount200Response>): QueryConfig<T> {
    return updateAccountRaw(requestParameters, requestConfig);
}

/**
 * update user account settings
 * update account settings
 */
function updateAccountSettingsRaw<T>(requestParameters: UpdateAccountSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/auth`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || PasswordUpdateToJSON(requestParameters.passwordUpdate),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* update user account settings
* update account settings
*/
export function updateAccountSettings<T>(requestParameters: UpdateAccountSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateAccountSettingsRaw(requestParameters, requestConfig);
}

/**
 * update authorization email settings
 */
function updateAuthorizationEmailSettingsRaw<T>(requestParameters: UpdateAuthorizationEmailSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authorization_email_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AuthorizationEmailSettingToJSON(requestParameters.authorizationEmailSetting),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* update authorization email settings
*/
export function updateAuthorizationEmailSettings<T>(requestParameters: UpdateAuthorizationEmailSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateAuthorizationEmailSettingsRaw(requestParameters, requestConfig);
}

/**
 * update authorization link settings
 */
function updateAuthorizationLinkSettingsRaw<T>(requestParameters: UpdateAuthorizationLinkSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authorization_link_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AuthorizationLinkSettingToJSON(requestParameters.authorizationLinkSetting),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* update authorization link settings
*/
export function updateAuthorizationLinkSettings<T>(requestParameters: UpdateAuthorizationLinkSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateAuthorizationLinkSettingsRaw(requestParameters, requestConfig);
}

/**
 * verify the current account to set new bank account details.
 * update settlement account
 */
function updateBankAccountRaw<T>(requestParameters: UpdateBankAccountRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBankAccount.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{id}/update_bank_account`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UpdateBankAccountToJSON(requestParameters.updateBankAccount),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* verify the current account to set new bank account details.
* update settlement account
*/
export function updateBankAccount<T>(requestParameters: UpdateBankAccountRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateBankAccountRaw(requestParameters, requestConfig);
}

/**
 * Updates a client in the system
 * update client
 */
function updateClientRaw<T>(requestParameters: UpdateClientRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateClient.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || ClientToJSON(requestParameters.client),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Updates a client in the system
* update client
*/
export function updateClient<T>(requestParameters: UpdateClientRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateClientRaw(requestParameters, requestConfig);
}

/**
 * Updates a clients logo in the system
 * update client logo
 */
function updateClientLogoRaw<T>(requestParameters: UpdateClientLogoRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateClientLogo.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const formData = new FormData();
    if (requestParameters.logo !== undefined) {
        formData.append('logo', requestParameters.logo as any);
    }

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{id}/update_logo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Updates a clients logo in the system
* update client logo
*/
export function updateClientLogo<T>(requestParameters: UpdateClientLogoRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateClientLogoRaw(requestParameters, requestConfig);
}

/**
 * Updates a customer in the system
 * update customer
 */
function updateCustomerRaw<T>(requestParameters: UpdateCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomer.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || CustomerToJSON(requestParameters.customer),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Updates a customer in the system
* update customer
*/
export function updateCustomer<T>(requestParameters: UpdateCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateCustomerRaw(requestParameters, requestConfig);
}

/**
 * Updates a customers authorization in the system
 * update customer authorization
 */
function updateCustomerAuthorizationRaw<T>(requestParameters: UpdateCustomerAuthorizationRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomerAuthorization.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const formData = new FormData();
    if (requestParameters.authForm !== undefined) {
        formData.append('auth_form', requestParameters.authForm as any);
    }

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customers/{id}/update_authorization`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Updates a customers authorization in the system
* update customer authorization
*/
export function updateCustomerAuthorization<T>(requestParameters: UpdateCustomerAuthorizationRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateCustomerAuthorizationRaw(requestParameters, requestConfig);
}

/**
 * save Rotessa customer settings for automatic import/updates from xero or quickbooks online
 * update integration customers settings
 */
function updateCustomersIntegrationSettingsRaw<T>(requestParameters: UpdateCustomersIntegrationSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, ConnectIntegrationCustomers200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations/customer_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ConnectCustomersToJSON(requestParameters.connectCustomers),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ConnectIntegrationCustomers200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* save Rotessa customer settings for automatic import/updates from xero or quickbooks online
* update integration customers settings
*/
export function updateCustomersIntegrationSettings<T>(requestParameters: UpdateCustomersIntegrationSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, ConnectIntegrationCustomers200Response>): QueryConfig<T> {
    return updateCustomersIntegrationSettingsRaw(requestParameters, requestConfig);
}

/**
 * update donor auth settings
 */
function updateDonorAuthSettingsRaw<T>(requestParameters: UpdateDonorAuthSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, GetDonorAuthSettings200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/donor_auth_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || DonorAuthSettingToJSON(requestParameters.donorAuthSetting),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetDonorAuthSettings200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* update donor auth settings
*/
export function updateDonorAuthSettings<T>(requestParameters: UpdateDonorAuthSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetDonorAuthSettings200Response>): QueryConfig<T> {
    return updateDonorAuthSettingsRaw(requestParameters, requestConfig);
}

/**
 * update integration for the selected client
 * update integration
 */
function updateIntegrationRaw<T>(requestParameters: UpdateIntegrationRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/integrations/update_account`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || IntegrationUpdateToJSON(requestParameters.integrationUpdate),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* update integration for the selected client
* update integration
*/
export function updateIntegration<T>(requestParameters: UpdateIntegrationRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateIntegrationRaw(requestParameters, requestConfig);
}

/**
 * update client notification settings
 */
function updateNotificationSettingsRaw<T>(requestParameters: UpdateNotificationSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, GetNotificationSettings200Response> = {}): QueryConfig<T> {
    if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
        throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling updateNotificationSettings.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{client_id}/notification_settings`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || NotificationSettingsToJSON(requestParameters.notificationSettings),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetNotificationSettings200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* update client notification settings
*/
export function updateNotificationSettings<T>(requestParameters: UpdateNotificationSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetNotificationSettings200Response>): QueryConfig<T> {
    return updateNotificationSettingsRaw(requestParameters, requestConfig);
}

/**
 * update a user\'s password
 * update password
 */
function updatePasswordRaw<T>(requestParameters: UpdatePasswordRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/auth/password`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || PasswordUpdateToJSON(requestParameters.passwordUpdate),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* update a user\'s password
* update password
*/
export function updatePassword<T>(requestParameters: UpdatePasswordRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updatePasswordRaw(requestParameters, requestConfig);
}

/**
 * update auth settings
 */
function updateProcessingAuthSettingsRaw<T>(requestParameters: UpdateProcessingAuthSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, GetProcessingAuthSettings200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/processing_auth_settings`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ProcessingAuthSettingToJSON(requestParameters.processingAuthSetting),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetProcessingAuthSettings200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* update auth settings
*/
export function updateProcessingAuthSettings<T>(requestParameters: UpdateProcessingAuthSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, GetProcessingAuthSettings200Response>): QueryConfig<T> {
    return updateProcessingAuthSettingsRaw(requestParameters, requestConfig);
}

/**
 * Updates a transaction schedule in the system
 * update transaction schedule
 */
function updateTransactionScheduleRaw<T>(requestParameters: UpdateTransactionScheduleRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTransactionSchedule.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/transaction_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || TransactionScheduleToJSON(requestParameters.transactionSchedule),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Updates a transaction schedule in the system
* update transaction schedule
*/
export function updateTransactionSchedule<T>(requestParameters: UpdateTransactionScheduleRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateTransactionScheduleRaw(requestParameters, requestConfig);
}

/**
 * Updates a user in the system
 * update user
 */
function updateUserRaw<T>(requestParameters: UpdateUserRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUser.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || UserToJSON(requestParameters.user),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Updates a user in the system
* update user
*/
export function updateUser<T>(requestParameters: UpdateUserRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return updateUserRaw(requestParameters, requestConfig);
}

/**
 * Upload a file to verify US clients
 * upload a certificate of good standing
 */
function uploadCertificateOfGoodStandingRaw<T>(requestParameters: UploadCertificateOfGoodStandingRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadCertificateOfGoodStanding.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const formData = new FormData();
    if (requestParameters.file !== undefined) {
        formData.append('file', requestParameters.file as any);
    }

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{id}/certificate_of_good_standing`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Upload a file to verify US clients
* upload a certificate of good standing
*/
export function uploadCertificateOfGoodStanding<T>(requestParameters: UploadCertificateOfGoodStandingRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return uploadCertificateOfGoodStandingRaw(requestParameters, requestConfig);
}

/**
 * Upload a file to client
 * upload a client file
 */
function uploadClientFileRaw<T>(requestParameters: UploadClientFileRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadClientFile.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const formData = new FormData();
    if (requestParameters.file !== undefined) {
        formData.append('file', requestParameters.file as any);
    }

    if (requestParameters.title !== undefined) {
        formData.append('title', requestParameters.title as any);
    }

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/clients/{id}/upload_file`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* Upload a file to client
* upload a client file
*/
export function uploadClientFile<T>(requestParameters: UploadClientFileRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return uploadClientFileRaw(requestParameters, requestConfig);
}

/**
 * validate tokens on return visits to the app.
 * validate token
 */
function validateTokenRaw<T>(requestParameters: ValidateTokenRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/auth/validate_token`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters || TokenToJSON(requestParameters.token),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* validate tokens on return visits to the app.
* validate token
*/
export function validateToken<T>(requestParameters: ValidateTokenRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return validateTokenRaw(requestParameters, requestConfig);
}

/**
 * verify 2-factor authentication code
 * verify 2fa
 */
function verify2faRaw<T>(requestParameters: Verify2faRequest, requestConfig: runtime.TypedQueryConfig<T, UpdateAccount200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/2fa`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || VerificationCodeToJSON(requestParameters.verificationCode),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UpdateAccount200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* verify 2-factor authentication code
* verify 2fa
*/
export function verify2fa<T>(requestParameters: Verify2faRequest, requestConfig?: runtime.TypedQueryConfig<T, UpdateAccount200Response>): QueryConfig<T> {
    return verify2faRaw(requestParameters, requestConfig);
}

/**
 * submit amounts matching the amounts deposited into the bank account to verify bank account information.
 * verify microdeposit
 */
function verifyMicrodepositRaw<T>(requestParameters: VerifyMicrodepositRequest, requestConfig: runtime.TypedQueryConfig<T, AddApiKey200Response> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/microdeposit_verifications`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || MicrodepositVerificationToJSON(requestParameters.microdepositVerification),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AddApiKey200ResponseFromJSON(body), text);
    }

    return config;
}

/**
* submit amounts matching the amounts deposited into the bank account to verify bank account information.
* verify microdeposit
*/
export function verifyMicrodeposit<T>(requestParameters: VerifyMicrodepositRequest, requestConfig?: runtime.TypedQueryConfig<T, AddApiKey200Response>): QueryConfig<T> {
    return verifyMicrodepositRaw(requestParameters, requestConfig);
}

