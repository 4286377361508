import React, { useRef } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { Client } from 'api'
import { isPermitted } from 'utilities'

export function UserNotificationAccordionItem({
  client,
  isOpen,
  btnOnClick,
}: {
  client: Client
  isOpen: boolean
  btnOnClick: () => void
}) {
  const contentRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className={`card mb-0 ${isOpen ? 'active' : ''} ${
        client.status === 'Approved' ? 'client-available' : 'client-unavailable'
      }`}
    >
      <div className="card-header pl-0" onClick={btnOnClick}>
        <h4 className="m-0 custom-accordion-title d-block py-1" style={{ fontWeight: 500 }}>
          {client.businessName}
          {client.status === 'Approved' ? (
            <i className={`mdi mdi-chevron-right accordion-arrow ${isOpen ? 'rotate' : ''} float-right`}></i>
          ) : (
            <span className="float-right">{client.status}</span>
          )}
        </h4>
      </div>
      <div className={`collapse ${isOpen ? 'show' : ''}`}>
        <div ref={contentRef} className="card-body accordion-item-content">
          <AvField hidden name={`clients.${client.id}.id`} type="number" value={client?.id} />
          <AvField
            name={`clients.${client.id}.userNotifications.newAuthorizationEmail`}
            label="New authorization is submitted"
            type="checkbox"
            value={client?.userNotifications?.newAuthorizationEmail}
          />
          <AvField
            name={`clients.${client.id}.userNotifications.dailySummaryEmail`}
            label="Daily summary of settlement and declines"
            type="checkbox"
            value={client?.userNotifications?.dailySummaryEmail}
          />
          {isPermitted('integrations.disconnect', client) && (
            <AvField
              name={`clients.${client.id}.userNotifications.integrationDisconnectEmail`}
              label="Integration with QBO or Xero becomes disconnected"
              type="checkbox"
              value={client?.userNotifications?.integrationDisconnectEmail}
            />
          )}
          <AvField
            name={`clients.${client.id}.userNotifications.microdepositSentEmail`}
            label="Microdeposit sent"
            type="checkbox"
            value={client?.userNotifications?.microdepositSentEmail}
          />
          <AvField
            name={`clients.${client.id}.userNotifications.microdepositVerifiedEmail`}
            label="Microdeposit verified"
            type="checkbox"
            value={client?.userNotifications?.microdepositVerifiedEmail}
          />
          <AvField
            name={`clients.${client.id}.userNotifications.microdepositRejectedEmail`}
            label="Microdeposit failed/declined"
            type="checkbox"
            value={client?.userNotifications?.microdepositRejectedEmail}
          />
        </div>
      </div>
    </div>
  )
}
