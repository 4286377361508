// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FinancialTransaction,
    FinancialTransactionFromJSON,
    FinancialTransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchFinancialTransactions200Response
 */
export interface SearchFinancialTransactions200Response  {
    /**
     * 
     * @type {number}
     * @memberof SearchFinancialTransactions200Response
     */
    count?: number;
    /**
     * 
     * @type {Array<FinancialTransaction>}
     * @memberof SearchFinancialTransactions200Response
     */
    records?: Array<FinancialTransaction>;
}

export function SearchFinancialTransactions200ResponseFromJSON(json: any): SearchFinancialTransactions200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(FinancialTransactionFromJSON),
    };
}

export function SearchFinancialTransactions200ResponseToJSON(value?: SearchFinancialTransactions200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(FinancialTransactionToJSON),
    };
}


