// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettlementsTotal
 */
export interface SettlementsTotal  {
    /**
     * 
     * @type {number}
     * @memberof SettlementsTotal
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementsTotal
     */
    count?: number;
}

export function SettlementsTotalFromJSON(json: any): SettlementsTotal {
    return {
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function SettlementsTotalToJSON(value?: SettlementsTotal): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amount': value.amount,
        'count': value.count,
    };
}


