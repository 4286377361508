// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectCustomersCustomersInner
 */
export interface ConnectCustomersCustomersInner  {
    /**
     * 
     * @type {number}
     * @memberof ConnectCustomersCustomersInner
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectCustomersCustomersInner
     */
    autoImportInvoices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectCustomersCustomersInner
     */
    autoUpdate?: boolean;
}

export function ConnectCustomersCustomersInnerFromJSON(json: any): ConnectCustomersCustomersInner {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'autoImportInvoices': !exists(json, 'auto_import_invoices') ? undefined : json['auto_import_invoices'],
        'autoUpdate': !exists(json, 'auto_update') ? undefined : json['auto_update'],
    };
}

export function ConnectCustomersCustomersInnerToJSON(value?: ConnectCustomersCustomersInner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'auto_import_invoices': value.autoImportInvoices,
        'auto_update': value.autoUpdate,
    };
}


