import React from 'react'
import { Alert } from 'reactstrap'

import { useWindowSize } from 'hooks/useWindowSize'

export interface NotificationProps {
  text: string
  align?: string
  color?: string
  icon?: string
}

export const Notification = (props: NotificationProps) => {
  const windowSize = useWindowSize()

  return (
    <div
      style={{
        position: 'fixed',
        top: 80,
        left: windowSize.width / 2,
        zIndex: 99999,
      }}
    >
      <Alert color={props.color || 'info'}>
        {props.icon && <i className={`mdi ${props.icon}`} />}
        {props.text}
      </Alert>
    </div>
  )
}
