// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendFlinksData200Response
 */
export interface SendFlinksData200Response  {
    /**
     * 
     * @type {string}
     * @memberof SendFlinksData200Response
     */
    status?: string;
}

export function SendFlinksData200ResponseFromJSON(json: any): SendFlinksData200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function SendFlinksData200ResponseToJSON(value?: SendFlinksData200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
    };
}


