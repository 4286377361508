import React, { useState } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { randomString } from 'utilities'

export interface ToolTipProps {
  children: React.ReactNode
  tooltipContent: string | React.ReactNode
}

export const ToolTip = (props: ToolTipProps) => {
  const [id, _setId] = useState(`popover${randomString(10)}`)

  return (
    <>
      <span id={id}>{props.children}</span>
      {props.tooltipContent && (
        <UncontrolledTooltip style={{ whiteSpace: 'nowrap', maxWidth: '500px' }} placement="bottom" target={id}>
          {props.tooltipContent}
        </UncontrolledTooltip>
      )}
    </>
  )
}
