import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'

import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col, UncontrolledAlert, Fade } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { Client, updateAccount, User } from 'api'
import { getActiveUser, justReplace, getClients } from 'app/selectors'
import { Loading } from 'components/Loading'
import logo from 'assets/images/logo-blue.png'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { FormServerErrors } from 'components/FormServerErrors'
import { searchClientsConfig } from 'features/clients/ClientManager'
import { UserNotificationAccordion } from 'components/UserNotificationAccordion'
import { useTranslation } from 'react-i18next'
import { ADMIN_ROLES } from 'app/constants'

export const MyAccount = () => {
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const [success, setSuccess] = useState(false)
  const activeUser = useSelector(getActiveUser)
  const clients: Client[] = useSelector(getClients) || []
  const [requestState, refreshClients] = useRequest(searchClientsConfig())
  const { t } = useTranslation()
  const [mutationState, saveChanges] = useMutation((user: User, clients: Client[]) =>
    updateAccount(
      {
        account: { user: user, clients: clients },
      },
      {
        force: true,
        transform: (body) => {
          return { account: body.user }
        },
        update: {
          account: justReplace,
          clients: justReplace,
        },
      },
    ),
  )

  function handleValidSubmit(_event, values) {
    saveChanges(values.user, values.clients)?.then((response) => {
      setServerErrors(response?.body?.messages || {})
      if (response?.body?.status < 400) {
        setSuccess(true)
        setTimeout(() => setSuccess(false), 5000)
      }
      refreshClients()
    })
  }

  const isPending = mutationState.isPending
  const isMobilePhoneRequired = clients.some((client) => ADMIN_ROLES.includes(client.permissions!['role'][0]))

  return (
    <div>
      <Link to="/" className="logo text-center logo-light non-menu-logo">
        <span className="logo logo-lg non-menu-logo">
          <img src={logo} alt="logo" height="20" />
        </span>
      </Link>
      <Row className="ml-1 mr-1 mb-4">
        <Col xl={{ size: 4, offset: 4 }} lg={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
          <div className="page-title-box">
            <h4 className="page-title">My Account</h4>
          </div>
          <Fade in={success} mountOnEnter={true} unmountOnExit={true}>
            <UncontrolledAlert>Account Update Successful</UncontrolledAlert>
          </Fade>
          <FormServerErrors errors={(serverErrors && serverErrors['base']) || []} />
          <Fade>
            <AvForm disabled={isPending} onValidSubmit={handleValidSubmit}>
              <Card>
                <CardBody>
                  <h4 className="header-title mb-3">Profile</h4>
                  <Row>
                    <Col>
                      <AvField
                        name="user.firstName"
                        label="First Name"
                        type="text"
                        placeholder="John"
                        required
                        onChange={handleFieldChange}
                        validate={{
                          async: validate,
                          required: {
                            value: true,
                            errorMessage: t('required_field'),
                          },
                        }}
                        value={activeUser?.firstName}
                      />
                    </Col>
                    <Col>
                      <AvField
                        name="user.lastName"
                        label="Last Name"
                        type="text"
                        placeholder="Smith"
                        required
                        onChange={handleFieldChange}
                        validate={{
                          async: validate,
                          required: {
                            value: true,
                            errorMessage: t('required_field'),
                          },
                        }}
                        value={activeUser?.lastName}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AvField
                        name="user.email"
                        label="Email"
                        type="text"
                        placeholder="john@smith.com"
                        disabled
                        value={activeUser?.email}
                      />
                    </Col>
                    <Col>
                      <AvField
                        name="user.mobilePhone"
                        label="Mobile Phone"
                        type="text"
                        placeholder="234 567 8901"
                        onChange={handleFieldChange}
                        required={isMobilePhoneRequired}
                        validate={{
                          async: validate,
                          required: {
                            value: isMobilePhoneRequired,
                            errorMessage: t('required_field'),
                          },
                        }}
                        value={activeUser?.mobilePhone}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h4 className="header-title mb-3">Security</h4>
                  <Link to="/change_password">
                    <i className="mdi mdi-textbox-password mr-2" />
                    Change your password
                  </Link>
                  <br />
                  <Link to="/two_factor_authentication">
                    <i className="mdi mdi-two-factor-authentication mr-2" />
                    Two-factor authentication settings
                  </Link>
                </CardBody>
              </Card>

              {!activeUser?.customerId && !activeUser?.rotessaStaff && (
                <Card>
                  <CardBody>
                    <h4 className="header-title mb-3">Email Notifications</h4>

                    {clients && clients.length > 0 && <UserNotificationAccordion clients={clients} />}
                  </CardBody>
                </Card>
              )}

              <div className="text-right">
                <Link to="/">
                  <Button disabled={isPending} className="btn-rotessa-secondary min-width mb-2 ml-1">
                    Close
                  </Button>
                </Link>
                <Button disabled={isPending} color="primary" className="mb-2 ml-1">
                  Submit
                </Button>
              </div>
            </AvForm>
          </Fade>
        </Col>
      </Row>
    </div>
  )
}
