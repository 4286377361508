import { combineReducers } from '@reduxjs/toolkit'
import { entitiesReducer, queriesReducer } from 'redux-query'
import { authReducer, logout } from './authReducer'

const appReducer = combineReducers({
  auth: authReducer,
  entities: entitiesReducer,
  queries: queriesReducer,
})

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined
  }

  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
