import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'reactstrap'
import Dropzone from 'react-dropzone'

type FileUploaderProps = {
  name?: string
  onFileUpload?: Function
  showPreview?: boolean
  accept?: string
  maxFiles?: number
  disabled?: boolean
  maxSize?: number
}

export const getFileErrorMessages = (file: any, maxSize?: number, minSize?: number) => {
  var messages = ''
  var key = 'message'
  var message = ''
  file.errors?.forEach((hash, i) => {
    if (hash[key]) {
      if (hash['code'] === 'file-too-large') {
        message = 'File is larger than '.concat(formatBytes(maxSize || 0))
      } else if (hash['code'] === 'file-too-small') {
        message = 'File is smaller than '.concat(formatBytes(minSize || 0))
      } else {
        message = hash[key]
      }
      messages = messages.concat(' ', message, '.')
    }
  })
  return 'File not accepted.'.concat(messages)
}

/**
 * Formats the size
 */
const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const FileUploader = (props: FileUploaderProps) => {
  const [selectedFile, setSelectedFile] = useState<any>()

  /**
   * Handled the accepted files and shows the preview
   */
  const handleAcceptedFile = (file: any) => {
    file = file[0]
    if (props.showPreview) {
      Object.assign(file, {
        preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
        formattedSize: formatBytes(file.size),
      })
      setSelectedFile(file)
    }

    if (props.onFileUpload) props.onFileUpload(file, true)
  }

  const handleFileRejections = (file: any) => {
    file = file[0]
    if (props.onFileUpload) props.onFileUpload(file, false)
  }

  const handleFileAdded = (acceptedFile: any, fileRejections: any) => {
    if (acceptedFile.length > 0) {
      handleAcceptedFile(acceptedFile)
    } else {
      handleFileRejections(fileRejections)
    }
  }

  return (
    <>
      <Dropzone {...props} onDrop={(acceptedFile, fileRejections) => handleFileAdded(acceptedFile, fileRejections)}>
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <i className="h1 text-muted dripicons-cloud-upload"></i>
              <h5>Drop file here or click to upload.</h5>
            </div>
          </div>
        )}
      </Dropzone>
      {props.showPreview && selectedFile && (
        <div className="dropzone-previews mt-3" id="file-previews">
          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
            <div className="p-2">
              <Row className="align-items-center">
                {selectedFile.preview ? (
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      className="avatar-sm rounded bg-light"
                      alt={selectedFile.name}
                      src={selectedFile.preview}
                    />
                  </Col>
                ) : (
                  <Col className="col-auto">
                    <div className="avatar-sm">
                      <span className="avatar-title bg-primary rounded">{selectedFile.type.split('/')[0]}</span>
                    </div>
                  </Col>
                )}
                <Col className="pl-0">
                  <Link to="#" className="text-muted font-weight-bold">
                    {selectedFile.name}
                  </Link>
                  <p className="mb-0">
                    <strong>{selectedFile.formattedSize}</strong>
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}
    </>
  )
}
