// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessCategory,
    BusinessCategoryFromJSON,
    BusinessCategoryToJSON,
    GetConstants200ResponseCollectionClient,
    GetConstants200ResponseCollectionClientFromJSON,
    GetConstants200ResponseCollectionClientToJSON,
    Province,
    ProvinceFromJSON,
    ProvinceToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetConstants200ResponseCollection
 */
export interface GetConstants200ResponseCollection  {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConstants200ResponseCollection
     */
    ownerTypes?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetConstants200ResponseCollection
     */
    transactionQuestionOptions?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetConstants200ResponseCollection
     */
    transactionStatuses?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetConstants200ResponseCollection
     */
    transactionFrequencies?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetConstants200ResponseCollection
     */
    frequencies?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetConstants200ResponseCollection
     */
    customerActions?: { [key: string]: string; };
    /**
     * 
     * @type {Array<BusinessCategory>}
     * @memberof GetConstants200ResponseCollection
     */
    businessCategories?: Array<BusinessCategory>;
    /**
     * 
     * @type {Array<Province>}
     * @memberof GetConstants200ResponseCollection
     */
    states?: Array<Province>;
    /**
     * 
     * @type {Array<Province>}
     * @memberof GetConstants200ResponseCollection
     */
    provinces?: Array<Province>;
    /**
     * 
     * @type {GetConstants200ResponseCollectionClient}
     * @memberof GetConstants200ResponseCollection
     */
    client?: GetConstants200ResponseCollectionClient;
}

export function GetConstants200ResponseCollectionFromJSON(json: any): GetConstants200ResponseCollection {
    return {
        'ownerTypes': !exists(json, 'owner_types') ? undefined : json['owner_types'],
        'transactionQuestionOptions': !exists(json, 'transaction_question_options') ? undefined : json['transaction_question_options'],
        'transactionStatuses': !exists(json, 'transaction_statuses') ? undefined : json['transaction_statuses'],
        'transactionFrequencies': !exists(json, 'transaction_frequencies') ? undefined : json['transaction_frequencies'],
        'frequencies': !exists(json, 'frequencies') ? undefined : json['frequencies'],
        'customerActions': !exists(json, 'customer_actions') ? undefined : json['customer_actions'],
        'businessCategories': !exists(json, 'business_categories') ? undefined : (json['business_categories'] as Array<any>).map(BusinessCategoryFromJSON),
        'states': !exists(json, 'states') ? undefined : (json['states'] as Array<any>).map(ProvinceFromJSON),
        'provinces': !exists(json, 'provinces') ? undefined : (json['provinces'] as Array<any>).map(ProvinceFromJSON),
        'client': !exists(json, 'client') ? undefined : GetConstants200ResponseCollectionClientFromJSON(json['client']),
    };
}

export function GetConstants200ResponseCollectionToJSON(value?: GetConstants200ResponseCollection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'owner_types': value.ownerTypes,
        'transaction_question_options': value.transactionQuestionOptions,
        'transaction_statuses': value.transactionStatuses,
        'transaction_frequencies': value.transactionFrequencies,
        'frequencies': value.frequencies,
        'customer_actions': value.customerActions,
        'business_categories': value.businessCategories === undefined ? undefined : (value.businessCategories as Array<any>).map(BusinessCategoryToJSON),
        'states': value.states === undefined ? undefined : (value.states as Array<any>).map(ProvinceToJSON),
        'provinces': value.provinces === undefined ? undefined : (value.provinces as Array<any>).map(ProvinceToJSON),
        'client': GetConstants200ResponseCollectionClientToJSON(value.client),
    };
}


