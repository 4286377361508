import React from 'react'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import NumberFormat from 'react-number-format'
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import { Loading } from 'components/Loading'
import { useWindowSize } from 'hooks/useWindowSize'
import { formatDateGreg, randomString } from 'utilities'
import { TRANSACTION_STATUS_CLASSES } from 'app/constants'

import emptyTableImage from 'assets/images/empty-table.png'
import actionRequired from 'assets/images/customer_dashboard_icons/Action-required-icon-2.png'
import { LoadingMaskNoBackground } from './LoadingMask'

export interface TableState {
  page: number
  pageSize: number
  sortField: string
  sortOrder: string
}

export interface ColumnProps {
  dataField: string
  headerFormatter?: Function
  formatter?: (cell: any, row?: any, rowIndex?: any) => any
  text: string
  sort: boolean
  headerAlign?: string
  align?: string
  hidden?: boolean
  style?: any
  headerStyle?: any
  headerAttrs?: any
  classes?: string
  headerClasses?: string
  events?: any
}

export interface OnTableChangeProps {
  page: number
  sizePerPage: number
  filter: string
  sortField: string
  sortOrder: string
}

export const CurrencyFormatter = (cell) => {
  return (
    <NumberFormat
      value={cell}
      displayType={'text'}
      fixedDecimalScale={true}
      decimalScale={2}
      thousandSeparator={true}
      prefix={'$'}
      renderText={(value) => <div>{value}</div>}
    />
  )
}

export const TransactionStatusFormatter = (status, row) => {
  if (!status?.length) return ''
  return (
    <>
      <Badge
        pill
        color={TRANSACTION_STATUS_CLASSES[status]}
        className={`status-badge badge-${TRANSACTION_STATUS_CLASSES[status]}-lighten mr-1`}
      >
        {row.statusReason || status}
      </Badge>
    </>
  )
}

export const LinkFormatter = (cell, row) => {
  return <Link to={`customers/${row.id}/dashboard`}>{cell}</Link>
}

export const TooltipFormatter = (cell) => {
  const id = `ellipsis-cell-${randomString(5)}`
  return (
    <>
      <span id={id}>{cell}</span>
      <UncontrolledTooltip target={id}>{cell}</UncontrolledTooltip>
    </>
  )
}

export const DateFormatter = (cell) => {
  return !cell || isNaN(Date.parse(cell)) ? cell : formatDateGreg(cell)
}

export interface TableAction {
  name: string | JSX.Element
  onClick: (row) => void
  hidden?: (row) => boolean
  disabled?: (row) => boolean
  potentialRequiredAction?: string
}

const determineActionRequired = (potentalAction: string, row) => {
  return row.requiredAction && row.requiredAction === potentalAction
}

const determineActionPriority = (potentalAction: string, row) => {
  return row.requiredAction && row.requiredAction === potentalAction ? 1 : 0
}

export const ActionFormatterFactory = (actions: TableAction[]) => {
  return (cell, row) => {
    let hasActionsRequired = false
    const dropdownItems = (
      <>
        {actions
          ?.filter((action) => {
            return !action.hidden || !action.hidden(row)
          })
          ?.sort((a, b) =>
            (a.potentialRequiredAction ? determineActionPriority(a.potentialRequiredAction, row) : 0) >
            (b.potentialRequiredAction ? determineActionPriority(b.potentialRequiredAction, row) : 0)
              ? -1
              : 1,
          )
          ?.map((action, index) => {
            if (action.potentialRequiredAction && determineActionRequired(action.potentialRequiredAction, row)) {
              hasActionsRequired = true
            }

            return (
              <DropdownItem
                key={index}
                onClick={() => action.onClick(row)}
                disabled={action.disabled && action.disabled(row)}
              >
                {action.potentialRequiredAction && determineActionRequired(action.potentialRequiredAction, row) && (
                  <img src={actionRequired} height={15} className="mr-1" style={{ marginBottom: '4px' }} alt="" />
                )}
                {action.name}
              </DropdownItem>
            )
          })}
      </>
    )
    return (
      <UncontrolledButtonDropdown>
        <DropdownToggle tag="button" className="d-flex align-items-center btn btn-link arrow-none card-drop p-0">
          {hasActionsRequired && <img src={actionRequired} height={15} className="mr-1 " alt="" />}
          <i className="mdi mdi-dots-horizontal mr-1"></i>
        </DropdownToggle>
        <DropdownMenu container={document.body}>{dropdownItems}</DropdownMenu>
      </UncontrolledButtonDropdown>
    )
  }
}

export const NoDataIndication = (loading, data, displayLoadingMask) => {
  if (loading) {
    return !displayLoadingMask ? <Loading /> : null
  } else if (data.length === 0) {
    return (
      <div>
        <img src={emptyTableImage} height={100} className="mb-2 mt-2" alt="" />
        <br />
        <span>Nothing here</span>
      </div>
    )
  } else {
    return ''
  }
}

export const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
  <UncontrolledButtonDropdown className="mb-2">
    <DropdownToggle caret className="btn-rotessa-secondary">
      <span className="mr-1">{currSizePerPage}</span>
    </DropdownToggle>
    <DropdownMenu>
      {options.map((option, i) => (
        <DropdownItem key={i} onClick={() => onSizePerPageChange(option.page)}>
          {option.text}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledButtonDropdown>
)

export const getPaginationOptions = (props: TablePaginationProps) => {
  var options: any = {
    sizePerPageRenderer: props.sizePerPageRenderer || sizePerPageRenderer,
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    paginationSize: 5,
    pageStartIndex: 1,
    withFirstAndLast: false,
    alwaysShowAllBtns: false,
    prePageText: '<',
    nextPageText: '>',
    showTotal: true,
  }
  if (props.fillHeight) {
    options.hideSizePerPage = true
  } else {
    options.sizePerPageList = (props.pageSizeOptions || [5, 10, 25, 50]).map((n) => ({ text: n.toString(), value: n }))
  }
  return options
}

export interface TablePaginationProps {
  page: number
  totalSize: number
  sizePerPage: number
  fillHeight?: boolean
  pageSizeOptions?: number[]
  sizePerPageRenderer?: Function
}

export interface TableSelectionProps {
  mode: string
  clickToSelect?: boolean
  onSelectionChange: Function
  onSelect?: Function
  onSelectAll?: Function
  selected?: any[]
  nonSelectable?: any[]
  selectColumnPosition?: string
  selectColumnStyle?: object
}

export interface TableProps {
  loading: boolean
  displayLoadingMask?: boolean
  data: any[]
  columns: ColumnProps[]
  keyField?: string
  sortField: string
  sortOrder: string
  paginationProps?: TablePaginationProps
  selectionProps?: TableSelectionProps
  onTableChange: (type: string, props: OnTableChangeProps) => void
  scrollBody?: boolean
  scrollBodyHeight?: string
  rowClasses?: string | ((row, index) => string)
  onRowClick?: (e: Event, row: any, rowIndex: number) => void
  style?: object
}

const TABLE_ROW_HEIGHT = 44
const BELOW_TABLE_HEIGHT = 190

export const Table = ({
  loading,
  displayLoadingMask,
  data,
  columns,
  keyField,
  sortField,
  sortOrder,
  paginationProps,
  selectionProps,
  onTableChange,
  scrollBody,
  scrollBodyHeight,
  rowClasses,
  onRowClick,
  style,
}: TableProps) => {
  const realKeyField = keyField || 'id'
  const selected = selectionProps?.selected || []

  const windowSize = useWindowSize()
  const id = columns.map((c) => c.dataField).join('-')
  const ref = document.getElementById(id)
  if (ref && paginationProps?.fillHeight) {
    const rect = ref.getBoundingClientRect()
    const sizePerPage = Math.floor((windowSize.height - rect.y - BELOW_TABLE_HEIGHT) / TABLE_ROW_HEIGHT)
    if (paginationProps && sizePerPage !== paginationProps.sizePerPage) {
      onTableChange('resize', {
        page: paginationProps.page,
        sizePerPage: sizePerPage,
        filter: '',
        sortField: sortField,
        sortOrder: sortOrder,
      })
    }
  }

  const paginationOptions =
    paginationProps && paginationProps.totalSize > 5 ? paginationFactory(getPaginationOptions(paginationProps)) : null

  const onSelect = (row, isSelected) => {
    if (selectionProps?.onSelect) {
      selectionProps.onSelect(row, isSelected)
    } else {
      if (isSelected) {
        if (!selected.includes(row)) {
          selectionProps?.onSelectionChange([...selected, row])
        }
      } else {
        if (selected.includes(row)) {
          selectionProps?.onSelectionChange(selected.filter((data) => data[realKeyField] !== row[realKeyField]))
        }
      }
    }
  }

  const onSelectAll = (isSelected, rows) => {
    if (selectionProps?.onSelectAll) {
      selectionProps.onSelectAll(isSelected, rows)
    } else {
      selectionProps?.onSelectionChange(isSelected ? rows : [])
    }
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      onRowClick?.(e, row, rowIndex)
    },
  }

  const getRowClasses = () => {
    if (typeof rowClasses === 'function') {
      return rowClasses
    }
    let classes = rowClasses || ''
    if (onRowClick) {
      classes += ' clickable-row'
    }
    return classes
  }

  return (
    <>
      <style>
        {`
          .scroll-table-wrapper {
            height: ${scrollBodyHeight};
          }
        `}
      </style>
      {displayLoadingMask && loading && <LoadingMaskNoBackground />}
      <BootstrapTable
        id={id}
        bootstrap4
        condensed
        hover
        remote
        bordered={false}
        keyField={keyField || 'id'}
        loading={loading}
        data={data}
        columns={columns}
        sort={{ dataField: sortField, order: sortOrder }}
        pagination={paginationOptions}
        wrapperClasses={'table-responsive' + (scrollBody ? ' scroll-table-wrapper' : '')}
        onTableChange={onTableChange}
        noDataIndication={NoDataIndication(loading, data, displayLoadingMask)}
        selectRow={
          selectionProps && {
            ...selectionProps,
            onSelect: onSelect,
            onSelectAll: onSelectAll,
            selected: selected.map((row) => row[realKeyField]),
          }
        }
        rowEvents={rowEvents}
        rowClasses={getRowClasses()}
        style={style}
      />
    </>
  )
}
