import React, { useState } from 'react'
import { useMutation } from 'redux-query-react'

import { Link } from 'react-router-dom'
import { Alert, Button, CardBody, CardGroup } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { sendPasswordReset } from 'api'
import logo from 'assets/images/logo-blue.png'

import AuthFluidDisplay from './AuthFluidDisplay'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [{ isPending, isFinished }, doSendPasswordReset] = useMutation((email: string) => {
    return sendPasswordReset({
      requiredEmailRedirect: {
        email: email,
        redirectUrl: `${window.location.protocol}//${window.location.host}/reset_password`,
      },
    })
  })

  function handleValidSubmit(_event, fields) {
    setEmail(fields.email)
    doSendPasswordReset(fields.email)
  }

  return (
    <div className="auth-fluid">
      <div className="auth-fluid-form-box">
        <CardGroup className="align-items-center d-flex h-100">
          <CardBody>
            <div className="auth-brand text-center text-lg-left">
              <img src={logo} alt="logo" height="20" />
            </div>
            <h4>Reset Password</h4>
            {isFinished ? (
              <Alert color="info">
                If an account is found with {email}, an email will be sent with a link to reset your password.
              </Alert>
            ) : (
              <>
                <p className="text-muted mb-4">
                  Enter your email address and we'll send you an email with instructions to reset your password.
                </p>
                <AvForm disabled={isPending} onValidSubmit={handleValidSubmit}>
                  <AvField name="email" label="Email address" type="text" placeholder="Enter your email" required />
                  <Button disabled={isPending} color="primary" className="mt-4 mb-2 btn-block">
                    <i className="mdi mdi-lock-reset" /> Reset Password
                  </Button>
                </AvForm>
              </>
            )}
            <footer className="footer footer-alt">
              <p className="text-muted">
                Back to <Link to="/login">Log In</Link>
              </p>
            </footer>
          </CardBody>
        </CardGroup>
      </div>
      <AuthFluidDisplay />
    </div>
  )
}

export default ForgotPassword
