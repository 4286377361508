import React from 'react'
import { Alert } from 'reactstrap'
import { InfoPopover } from './InfoPopover'

export const NotificationsText = ({
  notifications,
  displayAlert = false,
  color,
  allowHTML = false,
}: {
  notifications: string[]
  displayAlert?: boolean
  color?: string
  allowHTML?: boolean
}) => {
  if (notifications.length <= 0) {
    return null
  }
  return (
    <div>
      {notifications.map((notification) =>
        displayAlert ? (
          <Alert color={color} className="mb-1">
            {formatNotification(notification, allowHTML)}
          </Alert>
        ) : (
          <p className={`mb-1 ${color ? ' text-' + color : ''}`}>{formatNotification(notification, allowHTML)}</p>
        ),
      )}
    </div>
  )
}

const formatNotification = (notification: string, allowHTML: boolean) => {
  if (allowHTML) {
    return <span dangerouslySetInnerHTML={{ __html: notification }} />
  } else {
    return notification
  }
}
