import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest, useMutation } from 'redux-query-react'
import QRCode from 'qrcode.react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody, Fade } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { disable2fa, get2faUri, VerificationCode, verify2fa } from 'api'
import { FormServerErrors } from 'components/FormServerErrors'
import { getEntities } from 'app/selectors'
import { Loading } from 'components/Loading'
import logo from 'assets/images/logo-blue.png'

const verificationCode: VerificationCode = {}

const VerifyTwoFactorForm = ({ onFinished }) => {
  const totpUri = useSelector((state) => getEntities(state).totpUri)
  const [errors, setErrors] = useState([])
  const [{ isPending }, doMutation] = useMutation((values: VerificationCode) =>
    verify2fa(
      { verificationCode: values },
      {
        force: true,
        transform: (body) => {
          return { account: body.user }
        },
        update: {
          account: (_, newValue) => newValue,
        },
      },
    ),
  )

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (errors.length) return
    doMutation(values)?.then((response) => {
      setErrors(response?.body?.messages || [])
      if (response.body.status < 400) {
        onFinished()
      }
    })
  }

  return (
    <AvForm model={verificationCode} disabled={isPending} onSubmit={handleSubmit}>
      <p>
        Two-factor authentication uses 6-digit codes generated on a smart phone to verify your identity each time you
        log in.
      </p>
      <p>
        Scan the QRCode below using an authenticator app such as Microsoft Authenticator, then enter the verification
        code to enable two-factor authentication on your account.
      </p>
      <FormServerErrors errors={errors} />
      <Row>
        <Col>{totpUri && <QRCode value={totpUri} />}</Col>
        <Col>
          <AvField
            name="code"
            label="Verification Code"
            type="text"
            required
            placeholder="6 digits"
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
          />
          <div className="text-right">
            <Link to="/my_account">
              <Button color="light" className="ml-1 mb-1">
                Cancel
              </Button>
            </Link>
            <Button disabled={isPending} color="primary" className="ml-1 mb-1">
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </AvForm>
  )
}

const DisableTwoFactorForm = ({ onFinished }) => {
  const [errors, setErrors] = useState([])
  const [{ isPending }, doMutation] = useMutation((values: VerificationCode) =>
    disable2fa(
      { verificationCode: values },
      {
        force: true,
        transform: (body) => {
          return { account: body.user }
        },
        update: {
          account: (_, newValue) => newValue,
        },
      },
    ),
  )

  const handleSubmit = (event, errors, values) => {
    if (errors.length) return
    doMutation(values)?.then((response) => {
      setErrors(response?.body?.messages || [])
      if (response.body.status < 400) {
        onFinished()
      }
    })
  }

  return (
    <AvForm model={verificationCode} disabled={isPending} onSubmit={handleSubmit}>
      <p>
        Enter your verification code and click submit to disable two-factor authentication on your account. If you
        cannot access your verification code, please contact support.
      </p>
      <FormServerErrors errors={errors} />
      <AvField
        name="code"
        label="Verification Code"
        type="text"
        required
        placeholder="6 digits"
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault()
        }}
      />
      <div className="text-right">
        <Link to="/my_account">
          <Button color="light">Cancel</Button>
        </Link>
        <Button disabled={isPending} color="primary" className="ml-1">
          Submit
        </Button>
      </div>
    </AvForm>
  )
}

export const TwoFactorAuthentication = () => {
  const twoFactorEnabled = useSelector((state) => getEntities(state).twoFactorEnabled)
  const [isFinished, setIsFinished] = useState(false)
  const [requestState, doRequest] = useRequest(
    get2faUri({
      force: true,
      transform: (body) => {
        return { totpUri: body.uri, twoFactorEnabled: body.enabled }
      },
      update: {
        totpUri: (_, newValue) => newValue,
        twoFactorEnabled: (_, newValue) => newValue,
      },
    }),
  )

  const cardContent = (() => {
    if (requestState.isPending) return <Loading />
    if (isFinished)
      return (
        <Modal isOpen={true}>
          <ModalHeader>Success</ModalHeader>
          <ModalBody>
            <p>
              Two-factor authentication is now <b>{twoFactorEnabled ? 'disabled' : 'enabled'}</b> on your account.
            </p>
            <div className="text-right">
              <Link to="/my_account">
                <Button color="primary">OK</Button>
              </Link>
            </div>
          </ModalBody>
        </Modal>
      )
    if (twoFactorEnabled)
      return (
        <Fade>
          <DisableTwoFactorForm onFinished={() => setIsFinished(true)} />
        </Fade>
      )
    else
      return (
        <Fade>
          <VerifyTwoFactorForm onFinished={() => setIsFinished(true)} />
        </Fade>
      )
  })()

  const cardTitle = (() => {
    if (twoFactorEnabled) return <h4 className="page-title">Disable Two-Factor Authentication</h4>
    else return <h4 className="page-title">Enable Two-Factor Authentication</h4>
  })()

  return (
    <div>
      <Link to="/" className="logo text-center logo-light non-menu-logo">
        <span className="logo logo-lg non-menu-logo">
          <img src={logo} alt="logo" height="20" />
        </span>
      </Link>
      <Row className="ml-1 mr-1 mb-4">
        <Col md={{ size: 6, offset: 3 }}>
          <div className="page-title-box">{cardTitle}</div>
          <Card>
            <CardBody>{cardContent}</CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
