// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CsvCustomerImport,
    CsvCustomerImportFromJSON,
    CsvCustomerImportToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomersCsvPreview200Response
 */
export interface CustomersCsvPreview200Response  {
    /**
     * 
     * @type {number}
     * @memberof CustomersCsvPreview200Response
     */
    count?: number;
    /**
     * 
     * @type {Array<CsvCustomerImport>}
     * @memberof CustomersCsvPreview200Response
     */
    records?: Array<CsvCustomerImport>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersCsvPreview200Response
     */
    errors?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomersCsvPreview200Response
     */
    fileId?: string;
}

export function CustomersCsvPreview200ResponseFromJSON(json: any): CustomersCsvPreview200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(CsvCustomerImportFromJSON),
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'fileId': !exists(json, 'file_id') ? undefined : json['file_id'],
    };
}

export function CustomersCsvPreview200ResponseToJSON(value?: CustomersCsvPreview200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(CsvCustomerImportToJSON),
        'errors': value.errors,
        'file_id': value.fileId,
    };
}


