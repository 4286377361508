import React, { useState } from 'react'

import { Button, Fade } from 'reactstrap'

import { ExternalLink } from 'components/ExternalLink'
import { MdEditor } from 'components/Editor'
import { useSelector } from 'react-redux'
import { getActiveClient, getEntities } from 'app/selectors'
import { useMutation, useRequest } from 'redux-query-react'
import { AuthorizationEmailSetting, getAuthorizationEmailSettings, updateAuthorizationEmailSettings } from 'api'
import { FormServerErrors } from 'components/FormServerErrors'
import { Errors } from 'hooks/useFormServerErrors'
import { Loading } from 'components/Loading'
import { htmlToMarkdown, markdownToHtml } from 'utilities'
import { switchIntegration } from 'components/Integrations'

export const AuthorizationEmailSettings = () => {
  const activeClient = useSelector(getActiveClient)
  const setting = useSelector((state) => getEntities(state).authorizationEmailSetting)

  const [terms, setTerms] = useState('')
  const [message, setMessage] = useState('')
  const [formLoaded, setFormLoaded] = useState(false)
  const [serverErrors, setServerErrors] = useState<Errors>()

  const [requestState, doRequest] = useRequest(
    activeClient?.id &&
      getAuthorizationEmailSettings(
        {
          clientId: activeClient.id,
        },
        {
          force: true,
          transform: (body) => {
            return { authorizationEmailSetting: body }
          },
          update: {
            authorizationEmailSetting: (_, newValue) => newValue,
          },
        },
      ),
  )

  const [mutationState, doMutation] = useMutation((values: AuthorizationEmailSetting) =>
    updateAuthorizationEmailSettings({ authorizationEmailSetting: values }),
  )

  const handleSubmit = () => {
    doMutation({
      clientId: activeClient.id,
      customEmailMessage: markdownToHtml(message),
      customTermsAndConditions: markdownToHtml(terms),
    })?.then((response) => {
      setServerErrors(response?.body?.messages)
      if (response?.body?.status === 200) {
        doRequest()
      }
    })
  }

  if (
    !formLoaded &&
    requestState.isFinished &&
    setting?.customEmailMessage !== undefined &&
    setting?.customTermsAndConditions !== undefined
  ) {
    setMessage(htmlToMarkdown(setting.customEmailMessage))
    setTerms(htmlToMarkdown(setting.customTermsAndConditions))
    setFormLoaded(true)
    return <Loading />
  }

  if (requestState.isPending || !setting) {
    return <Loading />
  }

  return (
    <Fade>
      <h4 className="header-title mb-3">Authorization Email Settings</h4>
      <p>
        Once you have{' '}
        <ExternalLink to="https://support.rotessa.com/article/71-add-customers-manually">manually added</ExternalLink>,{' '}
        <ExternalLink to="https://support.rotessa.com/article/14-customer-csv-import">imported</ExternalLink>, or{' '}
        <ExternalLink
          to={
            activeClient?.integration === 'xero'
              ? 'https://support.rotessa.com/article/67-connect-customers-from-xero'
              : 'https://support.rotessa.com/article/64-connect-customers-from-quickbooks-online'
          }
        >
          synced
        </ExternalLink>{' '}
        your customers you can send them an authorization request.
      </p>
      <p>
        This Authorization Request is a personalized, ready-made form, to send directly to your customers for all future
        payments until the customer cancels the agreement.
      </p>
      <p>
        This is an{' '}
        <ExternalLink to="https://support.rotessa.com/article/42-overview-of-authorizations">open authorization</ExternalLink>{' '}
        agreement with your customers which gives you the flexibility to take both recurring and variable payments at
        different dollar amounts.
      </p>

      <h5 className="page-title">Authorization terms and conditions</h5>
      <FormServerErrors errors={(serverErrors && serverErrors['custom_terms_and_conditions']) || []} />
      <MdEditor onChange={setTerms} value={terms} />

      <h5 className="page-title">Email template</h5>
      <FormServerErrors errors={(serverErrors && serverErrors['custom_email_message']) || []} />
      <MdEditor onChange={setMessage} value={message} />
      <div className="text-right">
        <Button onClick={handleSubmit} color="primary" className="mt-2">
          Save
        </Button>
      </div>
    </Fade>
  )
}
