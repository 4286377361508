import React, { useRef, useState } from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Badge,
  Input,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'
import RotessaDatepicker from 'components/Datepicker'
import {
  ColumnProps,
  CurrencyFormatter,
  DateFormatter,
  OnTableChangeProps,
  Table,
  TransactionStatusFormatter,
} from 'components/Table'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getActiveClient, getEntities } from 'app/selectors'
import { useRequest } from 'redux-query-react'
import { Loading } from 'components/Loading'
import { CSVLink } from 'react-csv'
import { getTransactionReport, exportTransactionReportPdf } from 'api'
import { TRANSACTION_STATUS_CLASSES } from 'app/constants'
import { daysToMillis, formatCurrency, formatDateGreg } from 'utilities'
import NumberFormat from 'react-number-format'
import { useIsMobile } from 'hooks/useIsMobile'
import { ExportButton, ExportButtonCSVLink } from 'components/ExportButton'
import { LoadingMask } from 'components/LoadingMask'

export const MobileTransactionReportFormatter = (_processDate, row) => {
  return (
    <>
      <Row noGutters className="clearfix">
        <Col xs="auto">{row.customer?.name}</Col>
        <Col className={`text-right text-${TRANSACTION_STATUS_CLASSES[row.status]}`}>{formatCurrency(row.amount)}</Col>
      </Row>
      <Row>
        <Col>
          <small className="text-medium">{formatDateGreg(row.processDate)}</small>
        </Col>
        <Col className="text-right">
          <small className="text-medium">{row.statusReason}</small>
        </Col>
      </Row>
    </>
  )
}

export const CustomerLinkFormatter = ({ name, id }, row) => {
  if (!name || !id) return null
  return <Link to={`/client/customers/${id}`}>{name}</Link>
}

export const TransactionTotalBadge = ({ sum, count, status }) => (
  <Badge
    pill
    color={TRANSACTION_STATUS_CLASSES[status]}
    className={`status-badge totals badge-${TRANSACTION_STATUS_CLASSES[status]}-lighten mr-1`}
  >
    <NumberFormat
      value={sum}
      displayType={'text'}
      fixedDecimalScale={true}
      decimalScale={2}
      thousandSeparator={true}
      prefix={'$'}
    />
    &nbsp;{status}&nbsp;({count})
  </Badge>
)

export const calculateTransactionReportTotals = (transactionReportRecords) => {
  const result = {}

  transactionReportRecords.forEach((record) => {
    const amount = Number.parseFloat(record.amount)
    let status = record.status

    if (!result[status]) result[status] = { sum: 0, count: 0 }
    result[status].sum += amount
    result[status].count += 1
  })

  return result
}

export const getData = (records: any[], tableProps: any) => {
  var sortVal = (obj) => obj[tableProps.sortField]
  var sortVal2 = (obj) => new Date(obj['processDate'])
  switch (tableProps.sortField) {
    case 'customer':
      sortVal = (obj) => obj['customer']['name'].toLowerCase()
      break
    case 'amount':
      sortVal = (obj) => Number.parseFloat(obj['amount'])
      break
    case 'comment':
      sortVal = (obj) => obj['comment'].toLowerCase()
      break
    case 'processDate':
      sortVal = (obj) => new Date(obj['processDate']).getTime()
      sortVal2 = (obj) => obj['customer']['name'].toLowerCase()
  }
  let result = records.sort((a, b) => {
    let useSecond = false
    let valA = sortVal(a)
    let valB = sortVal(b)
    if (valA === valB) {
      useSecond = true
      valA = sortVal2(a)
      valB = sortVal2(b)
    }
    return useSecond || tableProps.sortOrder === 'asc' ? (valA < valB ? -1 : 1) : valA > valB ? -1 : 1
  })

  return result.map((v, i) => {
    return { ...v, _localid: i }
  })
}

export const TransactionReportTable = () => {
  const query = new URLSearchParams(useLocation().search)

  const today: Date = new Date()
  const thirtyDaysAgo: Date = new Date(today.valueOf() - daysToMillis(30))
  const activeClient = useSelector(getActiveClient)
  const history = useHistory()
  const transactionReportRecords = useSelector((state) => getEntities(state).transactionReportRecords) || []
  const transactionReportTotals = useSelector((state) => getEntities(state).transactionReportTotals) || {}
  const queryStartDate = query.get('start_date')
  const queryEndDate = query.get('end_date')
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    queryStartDate ? new Date(queryStartDate) : thirtyDaysAgo,
  )
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(queryEndDate ? new Date(queryEndDate) : today)
  const [startDate, setStartDate] = useState<Date>(queryStartDate ? new Date(queryStartDate) : thirtyDaysAgo)
  const [endDate, setEndDate] = useState<Date>(queryEndDate ? new Date(queryEndDate) : today)
  const [selectedFilter, setSelectedFilter] = useState(query.get('filter') || 'All')
  const [filter, setFilter] = useState(query.get('filter') || 'All')
  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: 5,
    sortField: 'processDate',
    sortOrder: 'desc',
  })
  const isMobile = useIsMobile()
  const [tableLoaded, setTableLoaded] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    setStartDate(selectedStartDate)
    setEndDate(selectedEndDate)
    setFilter(selectedFilter)
  }
  const csvExportLink = useRef<CSVLink>()

  const [requestState, doRequest] = useRequest(
    activeClient?.id &&
      getTransactionReport(
        {
          clientId: activeClient.id,
          startDate: startDate,
          endDate: endDate,
          filter: filter,
        },
        {
          force: true,
          transform: (body) => {
            return {
              transactionReportRecords: body?.records,
              transactionReportTotals: calculateTransactionReportTotals(body?.records || []),
            }
          },
          update: {
            transactionReportRecords: (_, newValue) => newValue,
            transactionReportTotals: (_, newValue) => newValue,
          },
        },
      ),
  )

  if (!tableLoaded && requestState.isFinished) {
    setTableLoaded(true)
  }

  const transactionReportColumns: ColumnProps[] = isMobile
    ? [
        {
          dataField: 'processDate',
          text: 'Process Date',
          sort: true,
          formatter: MobileTransactionReportFormatter,
        },
      ]
    : [
        {
          dataField: 'processDate',
          formatter: DateFormatter,
          text: 'Process Date',
          sort: true,
          classes: 'ellipsis',
        },
        {
          dataField: 'amount',
          formatter: CurrencyFormatter,
          text: 'Amount',
          sort: true,
        },
        {
          dataField: 'customer',
          formatter: CustomerLinkFormatter,
          text: 'Customer',
          sort: true,
          classes: 'ellipsis',
        },
        {
          dataField: 'status',
          formatter: TransactionStatusFormatter,
          text: 'Status',
          sort: true,
          classes: 'td-badge',
        },
        {
          dataField: 'comment',
          text: 'Comment',
          sort: true,
          classes: 'ellipsis',
        },
      ]

  function onTableChange(type, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) {
    setTableProps({
      page: page || tableProps.page,
      pageSize: sizePerPage || tableProps.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  const onRowClick = (e, row, rowIndex) => {
    history.push(`/client/customers/${row.customer.id}`)
  }

  const csvHeader = [
    { label: 'Process Date', key: 'processDate' },
    { label: 'Amount', key: 'amount' },
    { label: 'Customer', key: 'customer.name' },
    { label: 'Customer Custom Identifier', key: 'customer.customIdentifier' },
    { label: 'Status', key: 'status' },
    { label: 'Status Reason', key: 'statusReason' },
    { label: 'Settled Date', key: 'settledDate' },
    { label: 'Comment', key: 'comment' },
  ]
  let showLoadingMask = !tableLoaded || requestState.isPending || loadingExport
  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Transaction Report</h4>
            <p>Select dates to display totals of transactions you have scheduled and processed.</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            {showLoadingMask && <LoadingMask />}
            <CardBody>
              <Row noGutters className="clearfix">
                <Col xs="auto" className="mr-1 mb-1">
                  <RotessaDatepicker
                    onChange={(v) => setSelectedStartDate(new Date(v))}
                    hideAddon={false}
                    startDate={formatDateGreg(startDate)}
                    addonPrepended={true}
                    addOnContent="Start Date"
                  />
                </Col>
                <Col xs="auto" className="mr-1 mb-1">
                  <RotessaDatepicker
                    onChange={(v) => setSelectedEndDate(new Date(v))}
                    hideAddon={false}
                    startDate={formatDateGreg(endDate)}
                    addonPrepended={true}
                    addOnContent="End Date"
                  />
                </Col>
                <Col xs="auto" className="mr-1 mb-1">
                  <Input
                    name="status"
                    type="select"
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                  >
                    {['All', 'Sent to Bank', 'Processed', 'Future', 'Approved', 'Declined', 'Chargeback'].map(
                      (status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ),
                    )}
                  </Input>
                </Col>
                <Col xs="auto" className="mr-1 mb-1">
                  <Button disabled={requestState.isPending} onClick={handleSubmit} color="primary" className="mb-2">
                    Search
                  </Button>
                </Col>
              </Row>
              {requestState.isPending ? (
                <Loading />
              ) : (
                <>
                  <Row className="mb-2">
                    <Col xs="auto">
                      {Object.keys(transactionReportTotals).map((status, i) => (
                        <TransactionTotalBadge
                          key={i}
                          sum={transactionReportTotals[status].sum}
                          count={transactionReportTotals[status].count}
                          status={status}
                        />
                      ))}
                    </Col>
                  </Row>
                  <Table
                    loading={false}
                    data={getData(transactionReportRecords, tableProps)}
                    keyField="_localid"
                    columns={transactionReportColumns}
                    sortField={tableProps.sortField}
                    sortOrder={tableProps.sortOrder}
                    onTableChange={onTableChange}
                    onRowClick={onRowClick}
                    scrollBody
                    scrollBodyHeight="calc(100vh - 370px)"
                  />
                  {tableLoaded && (
                    <Row>
                      <Col className="text-center">
                        <UncontrolledButtonDropdown className="ml-1 mb-2 centered-dropdown" direction="down">
                          <DropdownToggle caret color="tertiary" className="btn-rotessa-tertiary">
                            <i className="mdi mdi-download-outline mr-1" />
                            Export transaction report
                          </DropdownToggle>
                          <DropdownMenu className="narrow">
                            <DropdownItem>
                              <ExportButton
                                noIcon
                                exportName={`transactions`}
                                exportQueryConfig={exportTransactionReportPdf(
                                  {
                                    clientId: activeClient.id,
                                    startDate: startDate,
                                    endDate: endDate,
                                    filter: filter,
                                  },
                                  {
                                    force: true,
                                  },
                                )}
                                setLoadingHandler={setLoadingExport}
                              >
                                PDF
                              </ExportButton>
                            </DropdownItem>
                            <DropdownItem>
                              <ExportButtonCSVLink
                                noIcon
                                csvLink={
                                  <CSVLink
                                    data={transactionReportRecords}
                                    headers={csvHeader}
                                    hidden
                                    filename={'transactions.csv'}
                                    target="_blank"
                                    ref={csvExportLink}
                                  />
                                }
                              >
                                CSV
                              </ExportButtonCSVLink>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
