// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationInvoice
 */
export interface IntegrationInvoice  {
    /**
     * 
     * @type {number}
     * @memberof IntegrationInvoice
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationInvoice
     */
    rotessaId?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    customerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    dueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationInvoice
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    connectionStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    bankVerification?: string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationInvoice
     */
    rotessaCustomerId?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    rotessaCustomerName?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    rotessaCustomerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    rotessaProcessDate?: string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationInvoice
     */
    rotessaAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationInvoice
     */
    rotessaComment?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationInvoice
     */
    errors?: Array<string>;
}

export function IntegrationInvoiceFromJSON(json: any): IntegrationInvoice {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rotessaId': !exists(json, 'rotessa_id') ? undefined : json['rotessa_id'],
        'customerName': !exists(json, 'customer_name') ? undefined : json['customer_name'],
        'customerEmail': !exists(json, 'customer_email') ? undefined : json['customer_email'],
        'invoiceNumber': !exists(json, 'invoice_number') ? undefined : json['invoice_number'],
        'dueDate': !exists(json, 'due_date') ? undefined : json['due_date'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'connectionStatus': !exists(json, 'connection_status') ? undefined : json['connection_status'],
        'bankVerification': !exists(json, 'bank_verification') ? undefined : json['bank_verification'],
        'rotessaCustomerId': !exists(json, 'rotessa_customer_id') ? undefined : json['rotessa_customer_id'],
        'rotessaCustomerName': !exists(json, 'rotessa_customer_name') ? undefined : json['rotessa_customer_name'],
        'rotessaCustomerEmail': !exists(json, 'rotessa_customer_email') ? undefined : json['rotessa_customer_email'],
        'rotessaProcessDate': !exists(json, 'rotessa_process_date') ? undefined : json['rotessa_process_date'],
        'rotessaAmount': !exists(json, 'rotessa_amount') ? undefined : json['rotessa_amount'],
        'rotessaComment': !exists(json, 'rotessa_comment') ? undefined : json['rotessa_comment'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    };
}

export function IntegrationInvoiceToJSON(value?: IntegrationInvoice): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'rotessa_id': value.rotessaId,
        'customer_name': value.customerName,
        'customer_email': value.customerEmail,
        'invoice_number': value.invoiceNumber,
        'due_date': value.dueDate,
        'amount': value.amount,
        'connection_status': value.connectionStatus,
        'bank_verification': value.bankVerification,
        'rotessa_customer_id': value.rotessaCustomerId,
        'rotessa_customer_name': value.rotessaCustomerName,
        'rotessa_customer_email': value.rotessaCustomerEmail,
        'rotessa_process_date': value.rotessaProcessDate,
        'rotessa_amount': value.rotessaAmount,
        'rotessa_comment': value.rotessaComment,
        'errors': value.errors,
    };
}


