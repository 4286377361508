import React, { useEffect, useState } from 'react'
import { useMutation } from 'redux-query-react'

import { sendFlinksData } from 'api'
import { LoadingMaskCentered } from 'components/LoadingMask'
import { Loading } from 'components/Loading'
import { useTranslation } from 'react-i18next'

export interface FlinksConnectParams {
  countryCode: string
  tag: string
  height: number
  onStep: Function
  onComplete?: () => void
  type: string
  accountType?: string
}

export const FlinksConnect = ({
  countryCode,
  tag,
  height,
  onStep,
  onComplete,
  type,
  accountType,
}: FlinksConnectParams) => {
  const [initialLoad, setInitialLoad] = useState(true)
  const [mutationState, doMutation] = useMutation((data: any) => sendFlinksData({ body: data }))
  const [complete, setComplete] = useState(false)
  const [hide, setHide] = useState(false)
  const [_, i18n] = useTranslation()

  const handleMessage = (e) => {
    if (e.origin.indexOf(process.env[`REACT_APP_FLINKS_ORIGIN_${countryCode}`]) === -1) return

    initialLoad && setInitialLoad(false)
    if (e?.data?.step === 'REDIRECT') {
      setHide(true)
    } else if (hide) {
      setHide(false)
    }

    onStep(e?.data?.step)

    doMutation({
      ...e.data,
      tag: tag,
      type: type,
      account_type: accountType,
    })?.then((response) => {
      if (response?.body?.status === 'complete' && !complete) {
        setComplete(true)
        onComplete && onComplete()
      }
    })
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  })

  let loading = <></>
  if (initialLoad || mutationState.isPending) {
    loading = hide ? <Loading /> : <LoadingMaskCentered />
  }

  const flinksURL = process.env[`REACT_APP_FLINKS_ENDPOINT_${countryCode}`] + `&language=${i18n.language || 'en'}`

  return (
    <div className="w-100 position-relative">
      {loading}
      <iframe id="flinks_connect_iframe" className="flinksconnect" height={hide ? 0 : height} src={flinksURL} />
    </div>
  )
}
