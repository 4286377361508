import React, { useState } from 'react'
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap'
import { randomString } from 'utilities'

export interface ImagePopoverProps {
  popoverImagePath: string
  popoverImageAlt?: string
}

export const ImagePopover = (props) => {
  const [id, _setId] = useState(`popover${randomString(10)}`)

  return (
    <>
      <Button type="button" id={id} color="link" className="p-0">
        <i className="mdi mdi-information-outline ml-1" />
      </Button>
      <UncontrolledPopover placement="bottom" target={id} trigger="hover">
        <PopoverBody>
          <img src={props.popoverImagePath} width="250" alt={props.popoverImageAlt || ''} />
        </PopoverBody>
      </UncontrolledPopover>
    </>
  )
}
