import React, { ChangeEventHandler } from 'react'
import { Label, CustomInput } from 'reactstrap'

export interface PermissionToggleProps {
  onChange: ChangeEventHandler<HTMLInputElement>
  name: string
  id?: string
  labelHeading: string
  labelDescription?: string
  inputClassName?: string
  labelClassName?: string
  disabled?: boolean
  checked?: boolean
}

export const PermissionToggle = (props: PermissionToggleProps) => {
  const id = props.id || props.name
  return (
    <div className="d-flex justify-content-between align-items-center">
      <Label htmlFor={id} style={{ lineHeight: 1.1 }} className={`w-100 pr-3 ${props.labelClassName}`}>
        <h4 className={`mb-0 ${props.disabled ? 'text-muted mb-1' : 'mb-1'}`} style={{ fontWeight: 500 }}>
          {props.labelHeading}
        </h4>
        {props.labelDescription && (
          <p className={`mb-0 ${props.disabled ? 'text-muted' : 'text-secondary'}`}>{props.labelDescription}</p>
        )}
      </Label>
      <CustomInput
        type="switch"
        id={id}
        name={props.name}
        className={props.inputClassName}
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </div>
  )
}
