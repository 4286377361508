/*
  Inspired by https://github.com/amplitude/redux-query/blob/0687bf301dad8d78576ba1c7a0dcc39762651b1c/packages/redux-query-interface-superagent/src/index.js
  Make the network interface not really abort the request.
  Don't execute the callback if a request has been aborted, but still collect auth headers.
*/
import superagent from 'superagent'
import { httpMethods } from 'redux-query'

import type { NetworkInterface } from 'redux-query'

import store from 'app/store'
import { handleResponseAuthHeaders } from 'app/middleware'

const createRequest = (url: string, method: string, body?: any) => {
  switch (method) {
    case httpMethods.HEAD:
      return superagent.head(url, body)
    case httpMethods.GET:
      return superagent.get(url, body)
    case httpMethods.POST:
      return superagent.post(url, body)
    case httpMethods.PUT:
      return superagent.put(url, body)
    case httpMethods.PATCH:
      return superagent.patch(url, body)
    case httpMethods.DELETE:
      return superagent.delete(url, body)
    default:
      throw new Error(`Unsupported HTTP method: ${method}`)
  }
}

const ABORTED = 'aborted'

export const RotessaNetworkInterface: NetworkInterface = (url, method, { body, headers, credentials } = {}) => {
  const request = createRequest(url, method, body)

  if (headers) {
    request.set(headers)
  }

  if (credentials === 'include') {
    request.withCredentials()
  }

  const execute = (callback) =>
    request.end((err, response) => {
      const resStatus = (response && response.status) || 0
      const resBody = (response && response.body) || undefined
      const resText = (response && response.text) || undefined
      const resHeaders = (response && response.header) || undefined

      if (request.get(ABORTED) === ABORTED) {
        // if request was aborted, just collect auth headers
        handleResponseAuthHeaders(resBody, resHeaders, store)
      } else {
        callback(err, resStatus, resBody, resText, resHeaders)
      }
    })

  const abort = () => {
    request.set(ABORTED, ABORTED)
  }

  return {
    abort,
    execute,
  }
}
