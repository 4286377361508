// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProcessingAuthSetting,
    ProcessingAuthSettingFromJSON,
    ProcessingAuthSettingToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProcessingAuthSettings200Response
 */
export interface GetProcessingAuthSettings200Response  {
    /**
     * 
     * @type {number}
     * @memberof GetProcessingAuthSettings200Response
     */
    status?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetProcessingAuthSettings200Response
     */
    messages?: { [key: string]: string; };
    /**
     * 
     * @type {ProcessingAuthSetting}
     * @memberof GetProcessingAuthSettings200Response
     */
    processingAuthSetting?: ProcessingAuthSetting;
}

export function GetProcessingAuthSettings200ResponseFromJSON(json: any): GetProcessingAuthSettings200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
        'processingAuthSetting': !exists(json, 'processing_auth_setting') ? undefined : ProcessingAuthSettingFromJSON(json['processing_auth_setting']),
    };
}

export function GetProcessingAuthSettings200ResponseToJSON(value?: GetProcessingAuthSettings200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'messages': value.messages,
        'processing_auth_setting': ProcessingAuthSettingToJSON(value.processingAuthSetting),
    };
}


