import React, { useEffect, useState } from 'react'
import { Row, Col, Input, FormFeedback, Fade, Modal, ModalBody, ModalHeader, ModalFooter, Alert } from 'reactstrap'

import { FormGroup, InputGroup, InputGroupAddon, Button, Label, CustomInput, Badge, Card, CardBody } from 'reactstrap'

import { Link, useHistory } from 'react-router-dom'
import { isReleased, processingAuthBaseUrl, validatePhone } from 'utilities'
import { Errors } from 'hooks/useFormServerErrors'

import { ProcessingAuthFormManager } from 'features/authorizations/processing/processingAuthFormManager'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveClient, getEntities, justReplace } from 'app/selectors'
import { useMutation, useRequest } from 'redux-query-react'
import {
  ProcessingAuthSetting,
  ProcessingAuthSettingFromJSON,
  getProcessingAuthSettings,
  updateProcessingAuthSettings,
  optInFlinks,
} from 'api'
import { Loading } from 'components/Loading'
import { updateEntities } from 'redux-query'
import { InfoPopover } from 'components/InfoPopover'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { LoadingMask } from 'components/LoadingMask'
import { Notification } from 'components/Notification'
import { useIsPermitted } from 'hooks/useIsPermitted'

import flinksPrice from 'assets/images/Rotessa-flinks-price-image.png'
import { Accordion } from 'components/Accordion'
import { AccordionItem } from 'components/AccordionItem'
import { CenterContentWide } from 'components/CenterContent'
import { MyEditor } from 'components/Editor'
import { FormServerErrors } from 'components/FormServerErrors'
import { PostalCodeField } from 'components/PostalCodeField'
import { StateProvinceSelect } from 'components/ProvinceSelect'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import deepEqual from 'deep-equal'
import { cloneDeep } from 'lodash'
import { ExternalLink } from 'components/ExternalLink'

const PREVIEW_STEPS = ['intro', 'contact', 'bank', 'finished']

export type optionType = {
  type: string
  name?: string
  label?: string | JSX.Element
  path?: string
  options?: {
    value: string
    label: string
  }[]
  info?: string
  disabled?: boolean
  new?: boolean
  code?: JSX.Element
}
export type optionsType = {
  type: string
  name: string
  info?: string
  items: optionType[]
  anchor?: string
}[]

export const getProcessingAuthSettingsConfig = (clientId: number) =>
  getProcessingAuthSettings(
    { clientId: clientId },
    {
      force: true,
      transform: (body) => ({ processingAuthSetting: body.processingAuthSetting }),
      update: { processingAuthSetting: justReplace },
    },
  )

export const ProcessingAuthSettingsManager = () => {
  const dispatch = useDispatch()
  const activeClient = useSelector(getActiveClient)
  const processingAuthSetting = useSelector((state) => getEntities(state).processingAuthSetting)
  const [originalProcessingAuthSetting, setOriginalProcessingAuthSetting] = useState<ProcessingAuthSetting>()
  const [waitForFinished, setWaitForFinished] = useState(true)
  const [previewStep, setPreviewStep] = useState(0)
  const [changesSaved, setChangesSaved] = useState(false)
  const [hideChangesSavedTimeout, setHideChangesSavedTimeout] = useState<NodeJS.Timeout>()
  const [showEnableFlinksModel, setShowEnableFlinksModel] = useState(false)
  const hash = window.location.hash.replace(/[#\/]/gm, '')

  const previewSteps = processingAuthSetting?.enableIntroduction ? PREVIEW_STEPS : PREVIEW_STEPS.slice(1)

  const [serverErrors, setServerErrors] = useState<Errors>()

  useClearEntitiesOnUnmount(['processingAuthInfo', 'processingAuthSetting'])

  useEffect(() => {
    if (!originalProcessingAuthSetting && processingAuthSetting) {
      setOriginalProcessingAuthSetting(cloneDeep(processingAuthSetting))
    }
  }, [processingAuthSetting, waitForFinished])

  const disableSave = !originalProcessingAuthSetting || deepEqual(originalProcessingAuthSetting, processingAuthSetting)

  useEffect(() => {
    dispatch(
      updateEntities({
        processingAuthInfo: (oldProcessingAuthInfo: any) => ({
          ...oldProcessingAuthInfo,
          id: 'Testing',
          address: processingAuthSetting?.address,
          email: processingAuthSetting?.email,
          phone: processingAuthSetting?.phone,
          client: {
            businessName: activeClient?.operatingAs || activeClient?.businessName,
            countryCode: activeClient?.countryCode,

            logo: activeClient?.logo,
          },
          step: {
            name: previewSteps[previewStep],
            introduction: processingAuthSetting?.introduction,
            require_name: true,
            require_email: true,
            require_phone: processingAuthSetting?.requirePhone,
            require_address: processingAuthSetting?.requireAddress,
            terms_and_conditions: processingAuthSetting?.termsAndConditions,
            enable_flinks: processingAuthSetting?.enableFlinks,
            require_verification: true,
            require_authorization_amount: processingAuthSetting?.requireAuthorizationAmount,
            name_default: processingAuthSetting?.nameDefault,
            verification_method: processingAuthSetting?.enableFlinks === true ? 'flinks' : 'microdeposit',
          },
          language: processingAuthSetting?.defaultLanguage,
        }),
      }),
    )
  }, [processingAuthSetting, activeClient, previewStep])

  const instant_bank_opt_in_enabled = useIsPermitted('authorization_settings.opt_in_instant_bank_verification')

  const [requestState, doRequest] = useRequest(activeClient && getProcessingAuthSettingsConfig(activeClient.id))

  const [mutationState, doMutation] = useMutation((values: ProcessingAuthSetting) =>
    updateProcessingAuthSettings({
      processingAuthSetting: { ...values, clientId: activeClient.id },
    }),
  )
  const [optInFlinksMutationState, doOptInFlinksMutation] = useMutation(() => optInFlinks({ id: activeClient.id }))

  const handleMutationResponse = (response) => {
    if (response?.body?.status === 200) {
      setChangesSaved(true)
      hideChangesSavedTimeout && clearTimeout(hideChangesSavedTimeout)
      setHideChangesSavedTimeout(setTimeout(() => setChangesSaved(false), 3000))
    }
    setServerErrors(response?.body?.messages || {})
    const newProcessingAuthSetting = ProcessingAuthSettingFromJSON(response?.body?.processing_auth_setting)
    dispatch(updateEntities({ processingAuthSetting: (_old) => newProcessingAuthSetting }))
    setOriginalProcessingAuthSetting(cloneDeep(newProcessingAuthSetting))
  }

  const handleToggle = (event) => {
    if (event.target.name === 'enableIntroduction' && previewStep !== 0) {
      if (event.target.checked) {
        setPreviewStep(previewStep + 1)
      } else {
        setPreviewStep(Math.max(0, previewStep - 1))
      }
    }

    const update = {
      requireVerification:
        event.target.name === 'enableFlinks' && event.target.checked === true
          ? true
          : processingAuthSetting?.requireVerification,
      [event.target.name]: event.target.checked,
    }
    dispatch(
      updateEntities({
        processingAuthSetting: (oldProcessingAuthSetting: ProcessingAuthSetting) => {
          return { ...oldProcessingAuthSetting, ...update }
        },
      }),
    )
  }

  const handleFlinksEnabledToggle = (event) => {
    if (!processingAuthSetting?.flinksOptedIn && event.target.checked === true) {
      setShowEnableFlinksModel(true)
    } else {
      handleToggle(event)
    }
  }

  const handleClickOptIn = () => {
    doOptInFlinksMutation()?.then((response) => {
      if (response?.body?.status === 200) {
        setShowEnableFlinksModel(false)
        dispatch(
          updateEntities({
            processingAuthSetting: (oldProcessingAuthSetting: ProcessingAuthSetting) => {
              return { ...oldProcessingAuthSetting, enableFlinks: true, requireVerification: true }
            },
          }),
        )
      }
    })
  }

  const handleRadio = (event) => {
    dispatch(
      updateEntities({
        processingAuthSetting: (oldProcessingAuthSetting: ProcessingAuthSetting) => {
          return { ...oldProcessingAuthSetting, [event.target.name]: event.target.value }
        },
      }),
    )
  }

  const handleFieldChange = (name: string, value: any, root?: string) => {
    dispatch(
      updateEntities({
        processingAuthSetting: (oldProcessingAuthSetting: ProcessingAuthSetting) => {
          let change = { ...oldProcessingAuthSetting }
          if (root) {
            if (!change[root]) {
              change[root] = {}
            }
            change[root][name] = value
          } else {
            change[name] = value
          }
          return change
        },
      }),
    )
  }

  const handleSave = () => {
    doMutation(processingAuthSetting)?.then((response) => {
      handleMutationResponse(response)
    })
  }

  const generateOption = (option: optionType, i: number) => {
    let optionContent
    switch (option.type) {
      case 'toggle':
        optionContent = (
          <CustomInput
            type="switch"
            id={option.name!}
            name={option.name!}
            label={option.label}
            className="inline-block"
            checked={processingAuthSetting?.[option.name!]}
            onChange={handleToggle}
            disabled={option.disabled}
          />
        )
        break
      case 'radio':
        optionContent = (
          <>
            <Label inline className="mb-0">
              {option.label}
            </Label>
            {option.options?.map((radio, i) => (
              <CustomInput
                key={i}
                type="radio"
                id={`${option.name!}_${radio.value}`}
                name={option.name!}
                value={radio.value}
                label={radio.label}
                className="inline-block ml-3"
                checked={processingAuthSetting?.[option.name!] === radio.value}
                onChange={handleRadio}
                disabled={option.disabled}
              />
            ))}
          </>
        )
        break
      case 'code':
        optionContent = option.code
        break
      case 'label':
        optionContent = <Label className="mb-0">{option.label}</Label>
        break
      case 'editor':
        optionContent = (
          <>
            <Label>{option.label}</Label>
            <MyEditor
              onChange={(text) => {
                handleFieldChange(option.name!, text)
              }}
              value={processingAuthSetting[option.name!] || ''}
              withBorder
            />
          </>
        )
        break
    }

    return (
      <div key={i} className="mb-1">
        {optionContent}
        {option.info && <InfoPopover popoverText={option.info} />}
        {option.new && (
          <Badge pill color="info-lighten" className="ml-1">
            New
          </Badge>
        )}
      </div>
    )
  }

  if (requestState.isFinished && waitForFinished && processingAuthSetting) {
    setWaitForFinished(false)
  }

  if (requestState.isPending || waitForFinished) return <Loading />

  const customLinkUrlCode = (
    <FormGroup>
      <InputGroup>
        <Input readOnly value={processingAuthBaseUrl} />
        <InputGroupAddon addonType="append" className="gray-input-background">
          <Input
            name="code"
            value={processingAuthSetting.code}
            onChange={(event) => handleFieldChange('code', event.target.value)}
            invalid={!!serverErrors?.code}
          />
        </InputGroupAddon>

        <FormFeedback>{serverErrors?.code}</FormFeedback>
      </InputGroup>
    </FormGroup>
  )

  const logoSettingsCode = (
    <div>
      <Link to="/client/processing_authorization_settings/edit_logo" className="auth-setting-logo text-muted">
        {activeClient?.logo ? (
          <img src={activeClient?.logo?.url} alt="Upload image file" />
        ) : (
          <div>
            <div className="d-flex justify-content-center">
              <i className="mdi mdi-arrow-up"></i>
            </div>
            <div>Upload image file</div>
          </div>
        )}
      </Link>
    </div>
  )

  const contactInfoCode = (
    <>
      <Row>
        <Col md={6}>
          <AvField
            type="text"
            name="address.address1"
            label="Address"
            onChange={(event) => handleFieldChange('address1', event.target.value, 'address')}
          />
        </Col>
        <Col md={6}>
          <AvField
            type="text"
            name="address.city"
            label="City"
            onChange={(event) => handleFieldChange('city', event.target.value, 'address')}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <StateProvinceSelect
            name="address.provinceCode"
            countryCode={activeClient.countryCode}
            onChange={(event) => handleFieldChange('provinceCode', event.target.value, 'address')}
          />
        </Col>
        <Col md={6}>
          <PostalCodeField
            name="address.postalCode"
            countryCode={activeClient.countryCode}
            onChange={(event) => handleFieldChange('postalCode', event.target.value, 'address')}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AvField
            type="email"
            name="email"
            label="Email"
            onChange={(event) => handleFieldChange('email', event.target.value)}
          />
        </Col>
        <Col md={6}>
          <AvField
            type="phone"
            name="phone"
            label="Phone number"
            validate={{ tel: validatePhone }}
            onChange={(event) => handleFieldChange('phone', event.target.value)}
          />
        </Col>
      </Row>
    </>
  )

  const enableFlinksModel = (
    <>
      <CustomInput
        type="switch"
        id="enableFlinks"
        name="enableFlinks"
        label="Enable Instant Bank Verification"
        className="inline-block"
        checked={processingAuthSetting?.enableFlinks}
        onChange={handleFlinksEnabledToggle}
        disabled={!instant_bank_opt_in_enabled}
      />
      <InfoPopover popoverText="Customers will verify their bank information and identity through online banking. If they are unable to complete this step, they will be redirected to a microdeposit." />
      <Modal isOpen={showEnableFlinksModel}>
        {optInFlinksMutationState.isPending && <LoadingMask />}
        <ModalHeader>
          <h4 className="mt-0 mb-0">Instant Bank Verification</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col style={{ fontSize: '.8rem' }}>
              <p>
                With Instant Bank Verification, your customers can use their online banking credentials to{' '}
                <b>verify their identity</b> and provide the bank information required for the authorization form.
              </p>
              <p>
                This service is provided by a third party, and therefore will have a one-time{' '}
                <b>$2 service charge per successful customer verification.</b>
              </p>
            </Col>
            <Col xs={3}>
              <img src={flinksPrice} className="d-block w-100 h-auto" alt="$2 per verification" />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-rotessa-secondary"
            onClick={() => {
              setShowEnableFlinksModel(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="btn-rotessa-primary"
            disabled={!instant_bank_opt_in_enabled}
            onClick={handleClickOptIn}
          >
            Turn On
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )

  const options: optionsType = [
    {
      type: 'header',
      name: 'Logo',
      anchor: 'logo',
      items: [
        {
          type: 'code',
          name: 'logoSettings',
          code: logoSettingsCode,
        },
      ],
    },
    {
      type: 'header',
      name: 'Contact information',
      anchor: 'contact_information',
      items: [
        {
          type: 'code',
          name: 'contactInformation',
          code: contactInfoCode,
        },
      ],
    },
    {
      type: 'header',
      name: 'Introduction page',
      anchor: 'introduction_page',
      items: [
        {
          type: 'toggle',
          name: 'enableIntroduction',
          label: 'Show introduction page',
          info:
            'This optional page can be used to add content or further instructions for your customer on how to process with the authorization form.',
        },
        {
          type: processingAuthSetting?.enableIntroduction ? 'editor' : 'hidden',
          name: 'introduction',
          label: 'Edit introduction',
        },
      ],
    },
    {
      type: 'header',
      name: 'Terms and conditions',
      anchor: 'terms_and_conditions',
      items: [
        {
          type: 'editor',
          name: 'termsAndConditions',
          label:
            activeClient.countryCode == 'CA' ? (
              <Alert color="info">
                <p className="mb-1">
                  As of January 1, 2024, under{' '}
                  <ExternalLink to="https://rotessa.com/2023/12/05/important-information-about-pad-agreements/">
                    Rule H1
                  </ExternalLink>{' '}
                  pre-authorized debits require the following:
                </p>
                <ul className="pl-3 mb-0">
                  {!isReleased('h1_terms_2024_released', activeClient) && (
                    <li>Identification of payment service provider</li>
                  )}
                  <li>
                    PAD agreements are required for one-time transactions, valid for only that transaction with the
                    amount and date stated
                  </li>
                  <li>
                    PAD agreement must identify the event that triggers a pre-authorized withdrawal when not on a set
                    schedule (i.e. payments are sporadic)
                  </li>
                </ul>
              </Alert>
            ) : (
              ''
            ),
        },
      ],
    },
    {
      type: 'header',
      name: 'Language',
      anchor: 'language',
      items: [
        {
          type: 'radio',
          name: 'defaultLanguage',
          label: 'Default authorization language:',
          options: [
            { value: 'en', label: 'English' },
            { value: 'fr', label: 'French' },
          ],
          new: true,
        },
      ],
    },
    {
      type: 'header',
      name: 'Fields',
      anchor: 'fields',
      items: [
        {
          type: 'radio',
          name: 'nameDefault',
          label: 'Default name field(s)',
          options: [
            { value: 'Personal', label: 'Personal' },
            { value: 'Business', label: 'Business' },
          ],
          info:
            'Select the default name format your customer will be asked to provide. The customer can still select an alternate name format when submitting the form.',
        },
        {
          type: 'toggle',
          name: 'requirePhone',
          label: 'Require customer phone number',
          info: '',
        },
        {
          type: 'toggle',
          name: 'requireAddress',
          label: 'Require customer address',
          info: '',
        },
      ],
    },
    {
      type: 'header',
      name: 'Instant bank verification',
      anchor: 'instant_verification',
      items: [
        {
          type: 'code',
          name: 'enableFlinks',
          code: enableFlinksModel,
        },
        {
          type: activeClient?.countryCode === 'CA' ? 'toggle' : 'hidden',
          name: 'requireVerification',
          label: 'Require microdeposit verification on email',
          info:
            'If enabled, microdeposits will be sent to new customer accounts from both Link and Email authorizations. If disabled, microdeposits will still be sent to new customer accounts only from Link authorizations.',
          disabled: processingAuthSetting?.enableFlinks,
        },
      ],
    },
    {
      type: 'header',
      name: 'Email',
      anchor: 'email',
      items: [
        {
          type: 'label',
          label: 'Edit default email message',
        },
        {
          type: 'toggle',
          name: 'enableGreeting',
          label: "Hi (customer's name),",
          info: 'If enabled, the email greeting will automatically include the customer name stored in Rotessa.',
        },
        {
          type: 'editor',
          name: 'emailMessage',
          label: '',
        },
      ],
    },
    {
      type: 'header',
      name: 'Link URL',
      anchor: 'link',
      info: 'Customize the URL to something your customers will recognize (Ex: company name).',
      items: [
        {
          type: 'code',
          name: 'customLinkUrl',
          code: customLinkUrlCode,
        },
      ],
    },
  ]

  return (
    <CenterContentWide>
      <Fade in={changesSaved}>
        <Notification text="Changes saved" color="success" />
      </Fade>
      <div className="page-title-box">
        <h4 className="page-title">Authorization Settings</h4>
      </div>
      {(requestState.isPending || mutationState.isPending) && <LoadingMask />}
      <Row>
        <Col lg={7}>
          <AvForm
            disabled={requestState.isPending || mutationState.isPending}
            onValidSubmit={handleSave}
            model={processingAuthSetting}
          >
            <Card>
              <CardBody className="p-0">
                <FormServerErrors errors={(serverErrors && serverErrors?.global) || []} />
                <Accordion startingIndex={options.findIndex((o) => o.anchor === hash)}>
                  {options.map((header, i) => {
                    return (
                      <AccordionItem
                        key={i}
                        header={
                          <span>
                            {header.name}
                            {header.info && <InfoPopover popoverText={header.info} />}
                          </span>
                        }
                        body={<>{header.items.filter((option) => option.type !== 'hidden').map(generateOption)}</>}
                        className="px-3"
                      />
                    )
                  })}
                </Accordion>
              </CardBody>
            </Card>
            <Button disabled={disableSave} color="primary" className="ml-1 mb-2">
              Save
            </Button>
          </AvForm>
        </Col>

        <Col lg={5} className="position-relative">
          <div className="auth-preview-controls text-center">
            <Button
              color="link"
              onClick={() => setPreviewStep(Math.max(0, previewStep - 1))}
              disabled={previewStep === 0}
            >
              <i className="mdi mdi-arrow-left-drop-circle" />
            </Button>
            Preview: Step {previewStep + 1} of {previewSteps.length}
            <Button
              color="link"
              onClick={() => setPreviewStep(Math.min(previewSteps.length - 1, previewStep + 1))}
              disabled={previewStep === previewSteps.length - 1}
            >
              <i className="mdi mdi-arrow-right-drop-circle" />
            </Button>
          </div>
          <div className="auth-preview-header">
            <i className="dot ml-2" />
            <i className="dot ml-1" />
            <i className="dot ml-1" />
          </div>
          <div className="auth-preview-container mb-4">
            <ProcessingAuthFormManager readOnly />
          </div>
        </Col>
      </Row>
    </CenterContentWide>
  )
}
