import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { useSelector } from 'react-redux'
import { getEntities } from 'app/selectors'
import { titleify } from 'utilities'

export const BreadcrumbView = () => {
  const location = useLocation()
  const pathItems = location.pathname.replace('/client/', '').split('/')
  const breadcrumbTitles = useSelector(
    (state) => getEntities(state).breadcrumbTitles,
    (left, right) => {
      return JSON.stringify(left) !== JSON.stringify(right)
    },
  )

  const breadcrumbs = pathItems
    .map((pathItem, i) => {
      var title = titleify(pathItem)
      if (breadcrumbTitles && breadcrumbTitles[pathItem]) {
        title = breadcrumbTitles[pathItem]
      }
      return {
        title: title,
        link: '/client/' + pathItems.slice(0, i + 1).join('/'),
      }
    })
    .filter((item) => item.title !== '')

  return (
    <Breadcrumb listClassName="mb-1 mt-1">
      {['Home', 'Signup', 'Pending'].indexOf(breadcrumbs[0]?.title) === -1 && (
        <BreadcrumbItem>
          <Link to="/client/home">Home</Link>
        </BreadcrumbItem>
      )}
      {breadcrumbs.map((breadcrumb, i) => (
        <BreadcrumbItem key={i}>
          {i === breadcrumbs.length - 1 ? breadcrumb.title : <Link to={breadcrumb.link}>{breadcrumb.title}</Link>}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}
