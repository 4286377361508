// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationUpdate
 */
export interface IntegrationUpdate  {
    /**
     * 
     * @type {number}
     * @memberof IntegrationUpdate
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationUpdate
     */
    paymentAccountId?: string;
}

export function IntegrationUpdateFromJSON(json: any): IntegrationUpdate {
    return {
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'paymentAccountId': !exists(json, 'payment_account_id') ? undefined : json['payment_account_id'],
    };
}

export function IntegrationUpdateToJSON(value?: IntegrationUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client_id': value.clientId,
        'payment_account_id': value.paymentAccountId,
    };
}


