import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import { CSVLink } from 'react-csv'

import {
  Card,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  Button,
} from 'reactstrap'

import { exportCustomers, performCustomerAction, searchCustomers } from 'api'
import { getCustomersCount, getActiveClient, getCustomers, getCustomerActions } from 'app/selectors'
import { ColumnProps, OnTableChangeProps, ActionFormatterFactory, DateFormatter } from 'components/Table'
import { TableSearchField } from 'components/TableHeader'

import { formatDateGreg, getSort, isPermitted } from 'utilities'
import { Link, useHistory } from 'react-router-dom'
import { TRANSACTION_STATUS_CLASSES } from 'app/constants'
import { FormServerErrors } from 'components/FormServerErrors'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { Table, sizePerPageRenderer } from 'components/Table'
import { LoadingMask } from 'components/LoadingMask'
import { QuickFilters } from './quickFilters'
import { ExportButton } from 'components/ExportButton'
import { useIsMobile } from 'hooks/useIsMobile'
import { useWindowSize } from 'hooks/useWindowSize'
import { CustomerIcons } from './customerIcons'
import { FormServerNotifications } from 'components/FormServerNotifications'
import complete from 'assets/images/customer_dashboard_icons/Blue-checkmark-ready-to-process.png'
import { integrationName } from 'components/Integrations'

export const MobileCustomerReportFormatter = (customerName, row) => {
  return (
    <>
      <Row noGutters className="clearfix">
        <Col xs="auto">{row['name']}</Col>
      </Row>
      <Row noGutters className="clearfix">
        <Col xs="auto">{<small className="text-medium">{row['email']}</small>}</Col>
      </Row>
      <Row className="mt-1 mb-1">
        <Col>{CustomerStatusesFormatter(row['statuses'], row)}</Col>
      </Row>
      <Row>
        <Col>Next: {CustomerNextPaymentFormatter({ ...row['nextPayment'] }, row)}</Col>
      </Row>
      <Row>
        <Col>Last: {CustomerLastPaymentFormatter({ ...row['lastPayment'] }, row)}</Col>
      </Row>
    </>
  )
}

const PAGE_SIZE_OPTIONS = [25, 100, 200]
const DROPDOWN_BREAKPOINT = 1265

export const CustomerNameFormatter = (name, row) => {
  return (
    <Link to={`customers/${row.id}`}>
      <span>
        {name}
        {row.statuses['complete'] && (
          <img src={complete} height="13" className="ml-1" style={{ marginBottom: '4px' }} alt="" />
        )}
        {row.phone && <i className="mdi mdi-phone-outline ml-1"></i>}
        {row.email && <i className="mdi mdi-email-outline ml-1"></i>}
      </span>
    </Link>
  )
}

export const CustomerStatusesFormatter = (statuses, row) => {
  return <CustomerIcons statuses={statuses} customer={row} className="table-icon" />
}

export const CustomerPaymentFormatter = ({ amount, processDate, status }) => {
  return (
    <>
      <span className={'text-' + (TRANSACTION_STATUS_CLASSES[status] || 'info') + ' mr-2'}>${amount}</span>
      <span>{formatDateGreg(processDate)}</span>
    </>
  )
}

export const CustomerNextPaymentFormatter = ({ amount, processDate, status }, row) => {
  if (amount && processDate) {
    return CustomerPaymentFormatter({ amount, processDate, status })
  } else {
    return <span className="text-medium">No payments scheduled</span>
  }
}

export const CustomerLastPaymentFormatter = ({ amount, processDate, status }, row) => {
  if (amount && processDate) {
    return CustomerPaymentFormatter({ amount, processDate, status })
  } else {
    return <span className="text-medium">No payment history</span>
  }
}

const csvHeaderCanada = [
  { label: 'name', key: 'name' },
  { label: 'custom_identifier', key: 'customIdentifier' },
  { label: 'status', key: 'status' },
  { label: 'email', key: 'email' },
  { label: 'active', key: 'active' },
  { label: 'address1', key: 'address.address1' },
  { label: 'address2', key: 'address.address2' },
  { label: 'city', key: 'address.city' },
  { label: 'province_code', key: 'address.provinceCode' },
  { label: 'postal_code', key: 'address.postalCode' },
]

const csvHeaderUS = [
  { label: 'name', key: 'name' },
  { label: 'custom_identifier', key: 'customIdentifier' },
  { label: 'status', key: 'status' },
  { label: 'email', key: 'email' },
  { label: 'active', key: 'active' },
  { label: 'address1', key: 'address.address1' },
  { label: 'address2', key: 'address.address2' },
  { label: 'city', key: 'address.city' },
  { label: 'state_code', key: 'address.stateCode' },
  { label: 'zip_code', key: 'address.postalCode' },
]

export const CustomersTable = () => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [selectedFilters, setSelectedFilters] = useState({ active: true })
  const [errors, setErrors] = useState<string[]>([])
  const [notifications, setNotifications] = useState<string[]>([])
  const activeClient = useSelector(getActiveClient) || {}
  const customers = useSelector(getCustomers) || []
  const customersCount = useSelector(getCustomersCount) || 0
  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
    selectedRows: [],
    sortField: 'createdAt',
    sortOrder: 'desc',
  })
  const readOnly = activeClient?.status === 'Closed'
  const csvHeaders = activeClient?.countryCode === 'CA' ? csvHeaderCanada : csvHeaderUS
  const csvExportLink = useRef<CSVLink>()
  const [isLoading, setIsLoadingExport] = useState(false)
  const isMobile = useIsMobile()
  const windowSize = useWindowSize()

  useClearEntitiesOnUnmount(['customers', 'customersCount', 'includeQboFilter', 'includeXeroFilter'])

  const [requestState, doRequest] = useRequest(
    !activeClient?.id
      ? null
      : searchCustomers(
          {
            clientId: activeClient.id,
            skip: (tableProps.page - 1) * tableProps.pageSize,
            limit: tableProps.pageSize,
            search: search,
            filters: selectedFilters,
            sort: getSort(tableProps.sortField, tableProps.sortOrder),
          },
          {
            force: true,
            transform: (response) => {
              return {
                customers: response.records,
                customersCount: response.count,
                includeQboFilter: response.includeQboFilter,
                includeXeroFilter: response.includeXeroFilter,
              }
            },
            update: {
              customers: (_, newValue) => newValue,
              customersCount: (_, newValue) => newValue,
              includeQboFilter: (_, newValue) => newValue,
              includeXeroFilter: (_, newValue) => newValue,
            },
          },
        ),
  )

  const [mutationState, doMutation] = useMutation((customerIds: number[], action: string, isBulk: boolean) =>
    performCustomerAction({
      customerAction: {
        ids: customerIds,
        name: action,
        isBulk: isBulk,
      },
    }),
  )

  const doAction = (ids: number[], action: string, isBulk: boolean) => {
    doMutation(ids, action, isBulk)?.then((response) => {
      setErrors(response?.body?.messages?.errors || [])
      setNotifications(response?.body?.messages?.notifications || [])
      setTableProps({
        ...tableProps,
        selectedRows: [],
      })
      doRequest()
    })
  }

  const onTableChange = (_type: string, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) => {
    setTableProps({
      page: page || tableProps.page,
      pageSize: sizePerPage || tableProps.pageSize,
      selectedRows: [],
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }
  const customerActions = useSelector(getCustomerActions) || {}

  const actions = [
    {
      name: 'Edit Contact Details',
      onClick: (row) => history.push(`/client/customers/${row.id}/edit_contact_details`),
      hidden: (_row) => !isPermitted('customers.edit', activeClient),
    },
    {
      name: 'Edit Bank Details',
      onClick: (row) => history.push(`/client/customers/${row.id}/edit_bank_and_auth_details`),
      hidden: (_row) => !isPermitted('customers.edit', activeClient),
      potentialRequiredAction: 'complete_bank_details',
    },
    {
      name: 'New Transaction',
      onClick: (row) => history.push(`/client/customers/${row.id}/new_transaction_schedule`),
      hidden: (row) => !isPermitted('transaction_schedules.new', activeClient) || !row.statuses['complete'],
    },
    {
      name: 'Email Authorization Request',
      onClick: (row) => history.push(`/client/email_customer_authorization_request?ids=${row.id}`),
      hidden: (row) =>
        !isPermitted('customers.email_auth_request', activeClient) ||
        (row.statuses['complete'] && row.requiredAction !== 'send_auth_request'),
      potentialRequiredAction: 'send_auth_request',
    },
    {
      name: 'Archive',
      onClick: (row) => doAction([row.id], customerActions.archive, false),
      hidden: (row) => !isPermitted('customers.archive', activeClient) || !row.active,
    },
    {
      name: 'Unarchive',
      onClick: (row) => doAction([row.id], customerActions.unarchive, false),
      hidden: (row) => !isPermitted('customers.archive', activeClient) || row.active,
    },
    {
      name: `Automatically import invoices from ${integrationName(activeClient.integration)}`,
      onClick: (row) => doAction([row.id], customerActions.enableAutoImportInvoices, false),
      hidden: (row) =>
        !isPermitted('customers.auto_import_invoices', activeClient) || !row.apiService || row.autoImportInvoices,
    },
    {
      name: `Disable automatic invoice imports from ${integrationName(activeClient.integration)}`,
      onClick: (row) => doAction([row.id], customerActions.disableAutoImportInvoices, false),
      hidden: (row) =>
        !isPermitted('customers.auto_import_invoices', activeClient) || !row.apiService || !row.autoImportInvoices,
    },
    {
      name: `Automatically update customer from ${integrationName(activeClient.integration)}`,
      onClick: (row) => doAction([row.id], customerActions.enableAutoUpdate, false),
      hidden: (row) =>
        !isPermitted('customers.auto_update_customers', activeClient) || !row.apiService || row.autoUpdate,
    },
    {
      name: `Disable automatic update customer from ${integrationName(activeClient.integration)}`,
      onClick: (row) => doAction([row.id], customerActions.disableAutoUpdate, false),
      hidden: (row) =>
        !isPermitted('customers.auto_update_customers', activeClient) || !row.apiService || !row.autoUpdate,
    },
    {
      name: 'Send Microdeposit',
      onClick: (row) => doAction([row.id], customerActions.sendMicrodeposit, false),
      hidden: (row) =>
        !isPermitted('customers.send_microdeposit', activeClient) ||
        ['Verified', 'Requested'].some((term) => row.bankVerification?.indexOf(term) !== -1),
      potentialRequiredAction: 'send_md',
    },
  ]

  const columns: ColumnProps[] = [
    {
      dataField: 'name',
      formatter: CustomerNameFormatter,
      text: 'Name',
      sort: true,
      classes: 'td-fit-width-content',
    },
    {
      dataField: 'statuses',
      formatter: CustomerStatusesFormatter,
      text: '',
      sort: false,
      classes: 'td-badge',
    },
    {
      dataField: 'nextPayment',
      formatter: CustomerNextPaymentFormatter,
      text: 'Next Payment',
      sort: false,
    },
    {
      dataField: 'lastPayment',
      formatter: CustomerLastPaymentFormatter,
      text: 'Last Payment',
      sort: false,
    },
    {
      dataField: 'createdAt',
      text: 'Created Date',
      formatter: DateFormatter,
      sort: true,
      hidden: true,
    },
  ]
  if (
    [
      'customers.edit',
      'transaction_schedules.new',
      'customers.email_auth_request',
      'customers.archive',
      'customers.auto_import_invoices',
      'customers.send_microdeposit',
    ].some((pName) => isPermitted(pName, activeClient))
  ) {
    columns.push({
      dataField: '',
      text: '',
      formatter: ActionFormatterFactory(actions),
      sort: false,
      align: 'right',
      classes: 'sticky-right',
      style: {
        cursor: 'default',
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation()
        },
      },
    })
  }

  var bulkActions: any[] = [
    {
      name: 'Archive',
      icon: <i className="mdi mdi-folder-outline mr-1" />,
      permissionName: 'customers.archive',
      onClick: () =>
        doAction(
          tableProps.selectedRows.map((r: any) => r.id),
          customerActions.archive,
          true,
        ),
    },
    {
      name: 'Unarchive',
      icon: <i className="mdi mdi-folder-open-outline mr-1" />,
      permissionName: 'customers.archive',
      onClick: () =>
        doAction(
          tableProps.selectedRows.map((r: any) => r.id),
          customerActions.unarchive,
          true,
        ),
    },
    {
      name: 'Email Authorization Request',
      icon: <i className="mdi mdi-send-outline mr-1" />,
      permissionName: 'customers.email_auth_request',
      onClick: () => {
        if (tableProps.selectedRows.length === 0) {
          setErrors(['Please select one or more customers for the bulk action.'])
        } else if (
          tableProps.selectedRows.some((row: any) => row?.statuses['complete'] || row?.statuses['auth_requested'])
        ) {
          setErrors([
            'One or more selected customers are not eligible for bulk email authorization because they are already completed or have a pending authorization request.',
          ])
        } else if (tableProps.selectedRows.some((row: any) => !row?.email)) {
          setErrors([
            'One or more selected customers are not eligible for bulk email authorization because they do not have an email.',
          ])
        } else {
          const idParams = tableProps.selectedRows.map((r: any) => r.id).join(',')
          history.push(`/client/email_customer_authorization_request?ids=${idParams}`)
        }
      },
    },
    {
      name: (
        <>
          <CSVLink
            data={tableProps.selectedRows}
            headers={csvHeaders}
            filename="customers.csv"
            target="_blank"
            hidden
            ref={csvExportLink}
          />
          Export Selection
        </>
      ),
      icon: <i className="mdi mdi-download-outline mr-1" />,
      permissionName: 'customers.export',
      onClick: () => csvExportLink.current?.link?.click(),
    },
  ]

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setTableProps({ ...tableProps, page: 1 })
  }

  const handleFilterChange = (filter) => {
    setSelectedFilters((prev) => ({ ...prev, ...filter }))
    setTableProps({ ...tableProps, page: 1 })
  }

  const onRowClick = (e, row, rowIndex) => {
    history.push(`/client/customers/${row.id}`)
  }

  const bulkActionId = 'customer-bulk-action-dropdown'

  let showLoadingMask =
    isLoading || (customers && customers.length > 0 && requestState.isPending) || mutationState.isPending

  return (
    <div>
      <div className="page-title-box">
        <h4 className="page-title text-wrap">Customers </h4>
      </div>
      <Card>
        <CardBody>
          {showLoadingMask && <LoadingMask />}
          <FormServerErrors errors={errors} />
          <FormServerNotifications notifications={notifications} />
          <Row className="justify-content-between align-items-center pl-1">
            <Col>
              <QuickFilters selectedFilters={selectedFilters} onFilterChange={handleFilterChange} />
            </Col>
            {!readOnly && (
              <Col className="text-right" xs="auto">
                {(isPermitted('customers.new', activeClient) ||
                  isPermitted('transaction_schedules.new', activeClient)) && (
                  <Button
                    onClick={() => history.push('/client/new-item')}
                    color="primary"
                    className="btn no-wrap ml-1 mb-2"
                  >
                    <i className="mdi mdi-plus-circle mr-1" />
                    New
                  </Button>
                )}
              </Col>
            )}
          </Row>
          <Row className="clearfix mb-2">
            <Col xs="auto">
              <Form inline>
                <TableSearchField onChange={handleSearchChange} />
              </Form>
            </Col>
            {!readOnly && (
              <Col>
                {(windowSize.width > 0 &&
                  windowSize.width < DROPDOWN_BREAKPOINT &&
                  bulkActions.some((action) => isPermitted(action.permissionName, activeClient)) && (
                    <>
                      <UncontrolledButtonDropdown
                        className="mb-2"
                        disabled={tableProps.selectedRows.length === 0}
                        id={bulkActionId}
                      >
                        <DropdownToggle caret className="btn-rotessa-secondary min-width">
                          <span className="mr-1">Bulk Action</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          {bulkActions
                            .filter((action) => isPermitted(action.permissionName, activeClient))
                            .map((action, i) => (
                              <DropdownItem key={i} onClick={action?.onClick}>
                                {action.icon}
                                {action.name}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                      {tableProps.selectedRows.length === 0 && document.getElementById(bulkActionId) && (
                        <UncontrolledTooltip target={bulkActionId}>
                          Select 1 or more customers to use the bulk action.
                        </UncontrolledTooltip>
                      )}
                    </>
                  )) || (
                  <div className="h-100 pb-2 d-flex justify-content-start">
                    <span className="d-block mt-1 text-muted">{tableProps.selectedRows.length} selected</span>
                    {bulkActions.some((action) => isPermitted(action.permissionName, activeClient)) && (
                      <Col>
                        {bulkActions
                          .filter((action) => isPermitted(action.permissionName, activeClient))
                          .map((action, i) => (
                            <Button
                              key={i}
                              onClick={action?.onClick}
                              className="btn btn-rotessa-tertiary no-wrap"
                              disabled={tableProps.selectedRows.length === 0}
                            >
                              {action.icon}
                              {action.name}
                            </Button>
                          ))}
                      </Col>
                    )}
                  </div>
                )}
              </Col>
            )}
          </Row>
          {windowSize.width > 0 && windowSize.width < DROPDOWN_BREAKPOINT && (
            <Row>
              <span className="ml-2 text-muted">{tableProps.selectedRows.length} selected</span>
            </Row>
          )}
          <Table
            loading={requestState.isPending || mutationState.isPending}
            data={customers}
            columns={columns}
            sortField={tableProps.sortField}
            sortOrder={tableProps.sortOrder}
            paginationProps={{
              page: tableProps.page,
              totalSize: customersCount,
              sizePerPage: tableProps.pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              sizePerPageRenderer: sizePerPageRenderer,
            }}
            selectionProps={{
              mode: 'checkbox',
              onSelectionChange: (val) => setTableProps({ ...tableProps, selectedRows: val }),
              selected: tableProps.selectedRows,
            }}
            onTableChange={onTableChange}
            scrollBody
            scrollBodyHeight={isMobile ? 'calc(100vh - 175px)' : 'calc(100vh - 475px)'}
            onRowClick={onRowClick}
          />
          <Row>
            <Col className="text-center">
              {isPermitted('customers.export', activeClient) && (
                <ExportButton
                  exportName={`Rotessa Customer Export ${new Date()}.csv`}
                  exportQueryConfig={exportCustomers(
                    {
                      clientId: activeClient.id,
                    },
                    {
                      force: true,
                    },
                  )}
                  setErrorsHandler={setErrors}
                  setLoadingHandler={setIsLoadingExport}
                >
                  Export all customers
                </ExportButton>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
