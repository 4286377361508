import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { logout } from 'app/authReducer'
import userProfileImage from 'assets/images/Rotessa-ui-profile-icon.png'
import userProfileImageWhite from 'assets/images/Rotessa-ui-profile-icon-white.png'
import { getEntities } from 'app/selectors'

export const ProfileDropdown = () => {
  const dispatch = useDispatch()
  const account = useSelector((state) => getEntities(state).account) || {}

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle className="nav-link dropdown-toggle nav-user arrow-none mr-0" tag="button" data-togle="dropdown">
        <span className="account-user-avatar lg">
          <img src={userProfileImage} className="rounded-circle" alt="user" />
        </span>
        <span className="account-user-avatar sm">
          <img src={userProfileImageWhite} className="rounded-circle" alt="user" />
        </span>
        <span className="account-user-name">{account?.name}</span>
      </DropdownToggle>
      <DropdownMenu className="profile-dropdown">
        <Link to="/my_account">
          <DropdownItem>
            <i className="mdi mdi-account-outline mr-2" />
            My Account
          </DropdownItem>
        </Link>
        <DropdownItem onClick={() => dispatch(logout())}>
          <i className="mdi mdi-logout mr-2" />
          Sign Out
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
