import React from 'react'
import TestimonialShowcase from './templates/TestimonialShowcase'

const AuthFluidDisplay = (): JSX.Element => {
  // To change background image update the $auth-bg-pattern-img css variable
  return (
    <div className="auth-fluid-right text-center">
      <TestimonialShowcase
        title="Fernie Brewing Company trusts Rotessa to collect payments from their suppliers"
        buttonLink="https://rotessa.com/blog/testimonial/fernie-brewing-company/"
        buttonText="Watch now"
      />
    </div>
  )
}

export default AuthFluidDisplay
