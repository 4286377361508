// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageThumb,
    ImageThumbFromJSON,
    ImageThumbToJSON,
} from './';

/**
 * 
 * @export
 * @interface Image
 */
export interface Image  {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    url?: string;
    /**
     * 
     * @type {ImageThumb}
     * @memberof Image
     */
    thumb?: ImageThumb;
}

export function ImageFromJSON(json: any): Image {
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
        'thumb': !exists(json, 'thumb') ? undefined : ImageThumbFromJSON(json['thumb']),
    };
}

export function ImageToJSON(value?: Image): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
        'thumb': ImageThumbToJSON(value.thumb),
    };
}


