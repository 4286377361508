// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientCustomersLimitsInner
 */
export interface ClientCustomersLimitsInner  {
    /**
     * 
     * @type {number}
     * @memberof ClientCustomersLimitsInner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCustomersLimitsInner
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientCustomersLimitsInner
     */
    customerSpecificMonthlyLimit?: number;
}

export function ClientCustomersLimitsInnerFromJSON(json: any): ClientCustomersLimitsInner {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'customerSpecificMonthlyLimit': !exists(json, 'customer_specific_monthly_limit') ? undefined : json['customer_specific_monthly_limit'],
    };
}

export function ClientCustomersLimitsInnerToJSON(value?: ClientCustomersLimitsInner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'customer_specific_monthly_limit': value.customerSpecificMonthlyLimit,
    };
}


