import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Loading } from 'components/Loading'

import { Provider } from 'react-redux'
import { Provider as ReduxQueryProvider } from 'redux-query-react'

import { getQueries } from 'app/selectors'
import store from 'app/store'
import App from 'app/App'

import './_i18n/i18n'

import TagManager from 'react-gtm-module'

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
})

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ReduxQueryProvider queriesSelector={getQueries}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <App />
          </Suspense>
        </BrowserRouter>
      </ReduxQueryProvider>
    </Provider>,
    document.getElementById('root'),
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render)
}
