import React from 'react'
import { Card, CardBody, Fade } from 'reactstrap'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'

const VerificationComplete = () => {
  return (
    <Fade>
      <div className="donation-content">
        <Card className="loading-container-min-height">
          <CardBody>
            <div className="text-center">
              <h2 className="text-primary">Thank you!</h2>
              <p>Your identity verification has been submitted.</p>
            </div>
          </CardBody>
        </Card>
        <PoweredByRotessaFooter type="donation_footer" />
      </div>
    </Fade>
  )
}

export default VerificationComplete
