import React, { useEffect, useState } from 'react'
import { useMutation, useRequest } from 'redux-query-react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { requestAsync, updateEntities } from 'redux-query'
import { Button, Col, Row, Badge, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from 'reactstrap'

import { getActiveClient, getEntities } from 'app/selectors'
import { ColumnProps, OnTableChangeProps } from 'components/Table'
import {
  connectIntegrationCustomers,
  getIntegrationCustomers,
  GetIntegrationCustomers200ResponseFromJSON,
  IntegrationCustomer,
} from 'api'
import { FormServerErrors } from 'components/FormServerErrors'
import { LoadingMaskCentered } from 'components/LoadingMask'
import { integrationName } from 'components/Integrations'
import { TableSearchField } from 'components/TableHeader'
import { SideBySideTables } from 'components/SideBySideTables'
import { TitleBar } from 'features/customers/customerFlow/titleBar'
import { truncateText } from 'utilities'
import { ToolTip } from 'components/ToolTip'

export const IntegrationCustomers = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [search, setSearch] = useState('')
  const activeClient = useSelector(getActiveClient)
  const unimportedIntegrationCustomers = useSelector((state) => getEntities(state).unimportedIntegrationCustomers) || {}
  const importedIntegrationCustomers = useSelector((state) => getEntities(state).importedIntegrationCustomers) || {}
  const [unimportedCustomersList, setUnimportedCustomers] = useState(
    [] as (IntegrationCustomer & { selected?: Boolean })[],
  )
  const [importedCustomersList, setImportedCustomersList] = useState(
    [] as (IntegrationCustomer & { selected?: Boolean })[],
  )
  const [loading, setLoading] = useState(false)
  const [customersToImport, setCustomersToImport] = useState([] as IntegrationCustomer[])
  const [serverErrors, setServerErrors] = useState([] as string[])
  const [responseStatus, setResponseStatus] = useState(0)
  const [customerConnectionTotal, setCustomerConnectionTotal] = useState(0)
  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: unimportedIntegrationCustomers.customersSearchTotal || unimportedIntegrationCustomers.customersTotal,
    selectedRows: [],
    sortField: 'name',
    sortOrder: 'asc',
  })
  const [unimportedCustomersRequestState, _doUnimportedCustomersRequest] = useRequest(
    activeClient?.id &&
      getIntegrationCustomers(
        {
          clientId: activeClient.id,
          search: search,
          filter: 'not_connected',
        },
        {
          force: true,
          transform: (body) => {
            return { unimportedIntegrationCustomers: body }
          },
          update: { unimportedIntegrationCustomers: (_, newValue) => newValue },
        },
      ),
  )

  const [importedCustomersRequestState, doImportedCustomersRequest] = useRequest(
    activeClient?.id &&
      getIntegrationCustomers(
        {
          clientId: activeClient.id,
          filter: 'connected',
        },
        {
          force: true,
          transform: (body) => {
            return { importedIntegrationCustomers: body }
          },
          update: { importedIntegrationCustomers: (_, newValue) => newValue },
        },
      ),
  )

  function onTableChange(_type: string, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) {
    setTableProps({
      page: page || tableProps.page,
      pageSize: sizePerPage || tableProps.pageSize,
      selectedRows: [],
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  const [mutationState, doMutation] = useMutation(() =>
    connectIntegrationCustomers({
      connectCustomers: {
        clientId: activeClient.id,
        filter: 'not_connected',
        customers: customersToImport.map((r: any) => {
          return {
            id: r.id,
            autoImportInvoices: r.autoImportInvoices,
            autoUpdate: r.autoUpdate,
          }
        }),
      },
    }),
  )

  const handleSubmit = () => {
    setServerErrors([])
    if (customersToImport.length === 0) {
      setServerErrors(['Please select at least one customer to connect'])
      return
    }
    doMutation()?.then((response) => {
      if (response?.status === 200) {
        setResponseStatus(response?.body?.status)
        setCustomerConnectionTotal(response?.body?.selected_count)
        if (response?.body?.status !== 200 && response?.body?.selected_count !== response?.body?.newly_imported_count) {
          var message =
            response?.body?.selected_count -
            response?.body?.newly_imported_count +
            ' selected customer(s) encountered errors while connecting.'
          setServerErrors([message])
        } else {
          setServerErrors([response?.body?.error_message] || [])
        }
        dispatch(
          updateEntities({
            unimportedIntegrationCustomers: (_) => GetIntegrationCustomers200ResponseFromJSON(response.body),
          }),
        )
        doImportedCustomersRequest()
      } else {
        setServerErrors(['An unknown error occured. If this continues please contact support.'])
      }
    })
  }

  function getNonSelectableRows() {
    var nonSelectableRows: number[] = []
    unimportedIntegrationCustomers.customers?.forEach((record) => {
      if (record.errors && record.errors.length !== 0 && !customersToImport.some((cust) => record.id === cust.id)) {
        nonSelectableRows.push(record.id)
      }
    })
    return nonSelectableRows
  }

  const AutoImportInvoicesFormatter = (_cell, row) => {
    const selectedRow = customersToImport.find((r) => r.id === row.id)
    return (
      <div className="d-flex align-items-center float-right">
        <CustomInput
          type="switch"
          id={`autoImportInvoices_${row.id}`}
          name={'autoImportInvoices'}
          checked={selectedRow ? selectedRow.autoImportInvoices : row.autoImportInvoices}
          disabled={importedIntegrationCustomers && importedIntegrationCustomers.customers.find((c) => c.id === row.id)}
          onChange={(event) => {
            setUnimportedCustomers((prev) => {
              let updatedRows = [...prev]
              let index = updatedRows.findIndex((r) => r.id === row.id)
              let customer = updatedRows[index]
              if (customer) {
                updatedRows[index] = { ...customer, autoImportInvoices: event.target.checked }
              }
              return updatedRows
            })
            setCustomersToImport((prev) => {
              let updatedRows = [...prev]
              let index = updatedRows.findIndex((r) => r.id === row.id)
              let customer = updatedRows[index]
              if (customer) {
                updatedRows[index] = { ...customer, autoImportInvoices: event.target.checked }
              }
              return updatedRows
            })
          }}
        />
      </div>
    )
  }

  const AutoUpdateFormatter = (_cell, row) => {
    const selectedRow = customersToImport.find((r) => r.id === row.id)
    return (
      <div className="d-flex align-items-center float-right">
        <CustomInput
          type="switch"
          id={`autoUpdate_${row.id}`}
          name={'autoUpdate'}
          checked={selectedRow ? selectedRow.autoUpdate : row.autoUpdate}
          disabled={importedIntegrationCustomers && importedIntegrationCustomers.customers.find((c) => c.id === row.id)}
          onChange={(event) => {
            setUnimportedCustomers((prev) => {
              let updatedRows = [...prev]
              let index = updatedRows.findIndex((r) => r.id == row.id)
              let customer = updatedRows[index]
              if (customer) {
                updatedRows[index] = { ...customer, autoUpdate: event.target.checked }
              }
              return updatedRows
            })
            setCustomersToImport((prev) => {
              let updatedRows = [...prev]
              let index = updatedRows.findIndex((r) => r.id == row.id)
              let customer = updatedRows[index]
              if (customer) {
                updatedRows[index] = { ...customer, autoUpdate: event.target.checked }
              }
              return updatedRows
            })
          }}
        />
      </div>
    )
  }

  const IntegrationCustomerDetailsFormatter = (cell, row) => {
    let truncatedName = truncateText(row.name, 20)
    let tooltipContent = truncatedName.endsWith('...') ? (
      <p className="m-0">
        <span>{row.name}</span>
      </p>
    ) : null
    let customerName = (
      <ToolTip tooltipContent={tooltipContent}>
        <span>{truncatedName}</span>
      </ToolTip>
    )

    return (
      <p className="mb-0 align-middle" style={{ lineHeight: 1 }}>
        {customerName}
        <br />
        <small className="text-muted">{row.email}</small>
        {row?.errors?.map((errorMessage) => (
          <>
            <br />
            <span className="text-danger">{errorMessage}</span>
          </>
        ))}
      </p>
    )
  }

  const ConnectionStatusFormatter = (cell, row) => {
    const statusClasses = {
      'Archived match found in Rotessa': 'secondary',
      'Match found in Rotessa': 'info',
    }

    return (
      <>
        {statusClasses[cell] && (
          <Badge pill color={statusClasses[cell]} className={`status-badge badge-${statusClasses[cell]}-lighten w-100`}>
            {cell}
          </Badge>
        )}
      </>
    )
  }

  const RotessaCustomerDetailsFormatter = (_cell: any, row: any) => {
    let truncatedName = truncateText(row.name, 20)
    let tooltipContent = truncatedName.endsWith('...') ? (
      <p className="m-0">
        <span>{row.name}</span>
      </p>
    ) : null
    let customerName = (
      <ToolTip tooltipContent={tooltipContent}>
        <span>{truncatedName}</span>
      </ToolTip>
    )

    if (importedIntegrationCustomers && importedIntegrationCustomers.customers.find((c) => c.id === row.id)) {
      return (
        <p className="mb-0 align-middle text-muted" style={{ lineHeight: 1 }}>
          {customerName}
          <br />
          <small className="text-muted">{row.email}</small>
          {row?.errors?.map((errorMessage) => (
            <>
              <br />
              <span className="text-danger">{errorMessage}</span>
            </>
          ))}
        </p>
      )
    } else {
      return (
        <p className="mb-0 align-middle" style={{ lineHeight: 1 }}>
          {customerName}
          {NewBadgeFormatter(row.id)}
          <br />
          <small className="text-muted">{row.email}</small>
          {row?.errors?.map((errorMessage) => (
            <>
              <br />
              <span className="text-danger">{errorMessage}</span>
            </>
          ))}
        </p>
      )
    }
  }

  const AutoImportInvoicesHeaderFormatter = () => {
    return (
      <div>
        <div className="text-right mb-1">Automatic invoice import</div>
        <div className="float-right">
          <CustomInput
            type="switch"
            id={`autoImportInvoices_all`}
            name={'autoImportInvoices'}
            checked={customersToImport.length > 0 && customersToImport.every((r) => r.autoImportInvoices)}
            disabled={customersToImport.length === 0}
            className="d-flex justify-content-center"
            onChange={(event) => {
              setUnimportedCustomers((prev) => {
                let updatedRows = [...prev]
                updatedRows.forEach((r) => {
                  r.autoImportInvoices = event.target.checked
                })
                return updatedRows
              })
              setCustomersToImport((prev) => {
                let updatedRows = [...prev]
                updatedRows.forEach((r) => {
                  r.autoImportInvoices = event.target.checked
                })
                return updatedRows
              })
            }}
          />
        </div>
      </div>
    )
  }

  const AutoUpdateHeaderFormatter = () => {
    return (
      <div>
        <div className="text-right mb-1">Automatic customer update</div>
        <div className="float-right">
          <CustomInput
            type="switch"
            id={`autoUpdate_all`}
            name={'autoUpdate'}
            checked={customersToImport.length > 0 && customersToImport.every((r) => r.autoUpdate)}
            disabled={customersToImport.length === 0}
            className="d-flex justify-content-center"
            onChange={(event) => {
              setUnimportedCustomers((prev) => {
                let updatedRows = [...prev]
                updatedRows.forEach((r) => {
                  r.autoUpdate = event.target.checked
                })
                return updatedRows
              })
              setCustomersToImport((prev) => {
                let updatedRows = [...prev]
                updatedRows.forEach((r) => {
                  r.autoUpdate = event.target.checked
                })
                return updatedRows
              })
            }}
          />
        </div>
      </div>
    )
  }

  const NewBadgeFormatter = (customerId) => {
    if (customersToImport.find((r) => r.id === customerId)) {
      return (
        <Badge pill color="info" className={`ml-1 badge-info-lighten`} style={{ height: 'fit-content' }}>
          NEW
        </Badge>
      )
    }
  }

  const columnsLeft: ColumnProps[] = [
    {
      dataField: 'integrationCustomerDetails',
      text: 'Name',
      formatter: IntegrationCustomerDetailsFormatter,
      sort: false,
      classes: 'align-middle',
    },
    {
      dataField: 'connectionStatus',
      text: '',
      formatter: ConnectionStatusFormatter,
      sort: false,
      classes: 'align-middle',
    },
  ]

  const columnsRight: ColumnProps[] = [
    {
      dataField: 'rotessaCustomerDetails',
      text: `Name`,
      formatter: RotessaCustomerDetailsFormatter,
      sort: false,
      classes: 'align-middle',
    },
    {
      dataField: 'autoImportInvoices',
      text: '',
      formatter: AutoImportInvoicesFormatter,
      sort: false,
      classes: 'align-middle',
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation()
        },
      },
      headerClasses: 'sticky-header text-center',
      headerFormatter: AutoImportInvoicesHeaderFormatter,
    },
    {
      dataField: 'autoUpdate',
      text: '',
      formatter: AutoUpdateFormatter,
      sort: false,
      classes: 'align-middle',
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation()
        },
      },
      headerClasses: 'sticky-header text-center',
      headerFormatter: AutoUpdateHeaderFormatter,
    },
  ]

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setTableProps({ ...tableProps, page: 1 })
  }

  useEffect(() => {
    if (unimportedCustomersRequestState.isFinished) {
      setUnimportedCustomers(unimportedIntegrationCustomers.customers)
      const unImportedIds = unimportedIntegrationCustomers.customers.map((cust) => cust.id)
      setCustomersToImport((prev) => {
        return prev
          .filter((cust) => unImportedIds.includes(cust.id))
          .map((custToImport) => {
            const errors = unimportedIntegrationCustomers.customers.find((cust) => cust.id === custToImport.id)?.errors
            return { ...custToImport, errors: errors }
          })
      })
    }

    if (importedCustomersRequestState.isFinished) {
      setImportedCustomersList(importedIntegrationCustomers.customers)
    }
  }, [unimportedIntegrationCustomers.customers, importedIntegrationCustomers.customers])

  const isPending = unimportedCustomersRequestState.isPending || mutationState.isPending

  const handleClickAcknowledge = () => {
    history.push(`/client/customers`)
  }

  const actionButtonRight = (
    <Button
      onClick={handleSubmit}
      disabled={isPending || customersToImport.length === 0}
      color="primary"
      className="w-100"
    >
      Import New Customers
    </Button>
  )

  const onSelectLeft = (row, isSelected) => {
    if (isSelected) {
      setCustomersToImport([...customersToImport, row])
    } else {
      setCustomersToImport(customersToImport.filter((c) => c.id !== row.id))
    }
  }

  const onSelectAllLeft = (isSelected) => {
    if (isSelected) {
      setCustomersToImport(unimportedCustomersList.filter((cust) => !getNonSelectableRows().includes(cust.id!)))
    } else {
      setCustomersToImport([])
    }
  }

  const handleRefreshCustomers = () => {
    setLoading(true)
    ;(dispatch(
      requestAsync(
        activeClient?.id &&
          getIntegrationCustomers(
            {
              clientId: activeClient.id,
              search: search,
              filter: 'not_connected',
              force: true,
            },
            {
              force: true,
              transform: (body) => {
                return { unimportedIntegrationCustomers: body }
              },
              update: { unimportedIntegrationCustomers: (_, newValue) => newValue },
            },
          ),
      ),
    ) as any).then(() => setLoading(false))
  }

  const tableSearch = (
    <>
      <Row className="mb-1">
        <Col>
          <TableSearchField onChange={(event) => handleSearchChange(event)} />
        </Col>
        <Col className="text-right">
          <Button className="" color="primary" onClick={handleRefreshCustomers}>
            Refresh
            <i className="ml-1 mdi mdi-sync" />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          {unimportedIntegrationCustomers.customersSearchTotal >= 0 && (
            <span className="text-muted">
              {unimportedIntegrationCustomers.customersSearchTotal}/{unimportedIntegrationCustomers.customersTotal}{' '}
              matches
            </span>
          )}
        </Col>
      </Row>
    </>
  )

  const rotessaCustomers = (): IntegrationCustomer[] => {
    return customersToImport.concat(importedCustomersList)
  }

  return (
    <>
      <Modal isOpen={responseStatus === 200}>
        <ModalHeader>{customerConnectionTotal} Customers Connected</ModalHeader>
        <ModalBody>
          <p>The selected customers have been connected successfully.</p>
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={handleClickAcknowledge}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <TitleBar title={`Import customers from ${integrationName(activeClient?.integration)}`} />

      <Row>
        <Col>
          {responseStatus !== 200 && serverErrors && <FormServerErrors errors={serverErrors || []} />}
          <SideBySideTables
            loadingLeft={isPending || loading}
            loadingRight={isPending || loading}
            displayLoadingMaskLeft={true}
            displayLoadingMaskRight={true}
            titleLeft={`NEW ${
              integrationName(activeClient?.integration) === 'Xero' ? 'Contacts' : 'Customers'
            } FOUND IN ${integrationName(activeClient?.integration)}`}
            titleRight={`CUSTOMERS IN ROTESSA`}
            dataLeft={unimportedCustomersList}
            dataRight={rotessaCustomers()}
            columnsLeft={columnsLeft}
            columnsRight={columnsRight}
            sortFieldLeft={tableProps.sortField}
            sortFieldRight={tableProps.sortField}
            sortOrderLeft={tableProps.sortOrder}
            sortOrderRight={tableProps.sortOrder}
            onTableChangeLeft={onTableChange}
            onTableChangeRight={onTableChange}
            selectionPropsLeft={{
              mode: 'checkbox',
              clickToSelect: true,
              onSelectionChange: () => {},
              onSelect: onSelectLeft,
              onSelectAll: onSelectAllLeft,
              selected: customersToImport,
              selectColumnStyle: {
                verticalAlign: 'middle',
              },
              nonSelectable: getNonSelectableRows(),
              nonSelectableClasses: 'non-selectable-integration-row',
            }}
            scrollBody
            scrollBodyHeight={'calc(100vh - 300px)'}
            onRowClickLeft={() => {}}
            headerLeft={tableSearch}
            headerRight={<Row className="mb-2" />}
            footerRight={actionButtonRight}
          />
        </Col>
      </Row>
    </>
  )
}
