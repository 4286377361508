import * as api from 'api'
import { justReplace } from './selectors'

export const accountQuery = (force: boolean) => {
  return api.getAccount({
    force: force,
    transform: (body) => ({ account: body }),
    update: { account: justReplace },
  })
}

export const customerQuery = (force: boolean, customerId?: number) => {
  return !customerId
    ? null
    : api.searchCustomers(
        {
          customerId: customerId,
        },
        {
          force: force,
          transform: (response) => ({ customer: response.records?.[0] }),
          update: { customer: justReplace },
        },
      )
}
