// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BankAccount,
    BankAccountFromJSON,
    BankAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateBankAccount
 */
export interface UpdateBankAccount  {
    /**
     * 
     * @type {BankAccount}
     * @memberof UpdateBankAccount
     */
    currentAccount?: BankAccount;
    /**
     * 
     * @type {BankAccount}
     * @memberof UpdateBankAccount
     */
    newAccount?: BankAccount;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccount
     */
    accountType?: string;
}

export function UpdateBankAccountFromJSON(json: any): UpdateBankAccount {
    return {
        'currentAccount': !exists(json, 'current_account') ? undefined : BankAccountFromJSON(json['current_account']),
        'newAccount': !exists(json, 'new_account') ? undefined : BankAccountFromJSON(json['new_account']),
        'accountType': !exists(json, 'account_type') ? undefined : json['account_type'],
    };
}

export function UpdateBankAccountToJSON(value?: UpdateBankAccount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'current_account': BankAccountToJSON(value.currentAccount),
        'new_account': BankAccountToJSON(value.newAccount),
        'account_type': value.accountType,
    };
}


