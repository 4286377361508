// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerActivity
 */
export interface CustomerActivity  {
    /**
     * 
     * @type {number}
     * @memberof CustomerActivity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerActivity
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerActivity
     */
    dateAndTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerActivity
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerActivity
     */
    type?: string;
}

export function CustomerActivityFromJSON(json: any): CustomerActivity {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'user_name') ? undefined : json['user_name'],
        'dateAndTime': !exists(json, 'date_and_time') ? undefined : json['date_and_time'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function CustomerActivityToJSON(value?: CustomerActivity): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'user_name': value.userName,
        'date_and_time': value.dateAndTime,
        'action': value.action,
        'type': value.type,
    };
}


