// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCustomersAndSendAuthorizationsCustomersInner
 */
export interface CreateCustomersAndSendAuthorizationsCustomersInner  {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomersAndSendAuthorizationsCustomersInner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomersAndSendAuthorizationsCustomersInner
     */
    name?: string;
}

export function CreateCustomersAndSendAuthorizationsCustomersInnerFromJSON(json: any): CreateCustomersAndSendAuthorizationsCustomersInner {
    return {
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function CreateCustomersAndSendAuthorizationsCustomersInnerToJSON(value?: CreateCustomersAndSendAuthorizationsCustomersInner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'name': value.name,
    };
}


