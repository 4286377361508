import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Fade, CardGroup } from 'reactstrap'

import yesPAD from 'assets/images/customerFlow/Yes_pad.png'
import noPAD from 'assets/images/customerFlow/No_pad.png'
import { TitleBar } from './titleBar'
import { CenterContent } from 'components/CenterContent'
import { InfoPopover } from 'components/InfoPopover'

export interface CustomerPADProps {
  clientId?: number
}

export const CustomerAuthorization = () => {
  return (
    <Fade>
      <TitleBar
        title="Do you have an authorization agreement?"
        subtitle={
          <>
            <p style={{ textAlign: 'center' }}>
              You need an authorization agreement before withdrawing money from your customer's bank account.
              <InfoPopover popoverText="An authorization agreement is a written payment agreement form. The authorization agreement will outline the frequency, amounts (variable or set), and any additional information that is required." />
            </p>
          </>
        }
      ></TitleBar>
      <CenterContent>
        <Col m>
          <CardGroup className="justify-content-center">
            <Card className="m-2 customer-flow-card">
              <Link to="/client/customers/new/authorization-information">
                <CardHeader className="text-center">
                  <img src={yesPAD} height={100} className="mt-1 mb-1" alt="Yes, I have an authorization agreement" />
                </CardHeader>
                <CardBody className="text-center">
                  <p className="card-label">Yes</p>
                </CardBody>
              </Link>
            </Card>
            <Card className="m-2 customer-flow-card">
              <Link to="/client/customers/new/collect-authorization">
                <CardHeader className="text-center">
                  <img
                    src={noPAD}
                    height={100}
                    className="mt-1 mb-1"
                    alt="No, I don't have an authorization agreement"
                  />
                </CardHeader>
                <CardBody className="text-center">
                  <p className="card-label">No</p>
                </CardBody>
              </Link>
            </Card>
          </CardGroup>
        </Col>
      </CenterContent>
    </Fade>
  )
}
