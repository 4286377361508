// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetIntegrations200ResponsePaymentAccountsInner,
    GetIntegrations200ResponsePaymentAccountsInnerFromJSON,
    GetIntegrations200ResponsePaymentAccountsInnerToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetIntegrations200Response
 */
export interface GetIntegrations200Response  {
    /**
     * 
     * @type {string}
     * @memberof GetIntegrations200Response
     */
    integrationName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntegrations200Response
     */
    integrationStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntegrations200Response
     */
    lastImportedInvoices?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntegrations200Response
     */
    selectedPaymentAccountId?: string;
    /**
     * 
     * @type {Array<GetIntegrations200ResponsePaymentAccountsInner>}
     * @memberof GetIntegrations200Response
     */
    paymentAccounts?: Array<GetIntegrations200ResponsePaymentAccountsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetIntegrations200Response
     */
    organization?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntegrations200Response
     */
    quickbooksOauthUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntegrations200Response
     */
    xeroOauthUrl?: string;
}

export function GetIntegrations200ResponseFromJSON(json: any): GetIntegrations200Response {
    return {
        'integrationName': !exists(json, 'integration_name') ? undefined : json['integration_name'],
        'integrationStatus': !exists(json, 'integration_status') ? undefined : json['integration_status'],
        'lastImportedInvoices': !exists(json, 'last_imported_invoices') ? undefined : json['last_imported_invoices'],
        'selectedPaymentAccountId': !exists(json, 'selected_payment_account_id') ? undefined : json['selected_payment_account_id'],
        'paymentAccounts': !exists(json, 'payment_accounts') ? undefined : (json['payment_accounts'] as Array<any>).map(GetIntegrations200ResponsePaymentAccountsInnerFromJSON),
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'quickbooksOauthUrl': !exists(json, 'quickbooks_oauth_url') ? undefined : json['quickbooks_oauth_url'],
        'xeroOauthUrl': !exists(json, 'xero_oauth_url') ? undefined : json['xero_oauth_url'],
    };
}

export function GetIntegrations200ResponseToJSON(value?: GetIntegrations200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'integration_name': value.integrationName,
        'integration_status': value.integrationStatus,
        'last_imported_invoices': value.lastImportedInvoices,
        'selected_payment_account_id': value.selectedPaymentAccountId,
        'payment_accounts': value.paymentAccounts === undefined ? undefined : (value.paymentAccounts as Array<any>).map(GetIntegrations200ResponsePaymentAccountsInnerToJSON),
        'organization': value.organization,
        'quickbooks_oauth_url': value.quickbooksOauthUrl,
        'xero_oauth_url': value.xeroOauthUrl,
    };
}


