import { getBillingInvoiceById, getBillingInvoices } from 'api'
import { getActiveClient, getEntities } from 'app/selectors'
import { FormServerErrors } from 'components/FormServerErrors'
import { ActionFormatterFactory, ColumnProps, DateFormatter, OnTableChangeProps, Table } from 'components/Table'
import { TableHeader } from 'components/TableHeader'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import { getSort } from 'utilities'
import { openPdfFile } from 'utilities'
import NumberFormat from 'react-number-format'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

export interface InvoicesTableProps {
  condensedView?: boolean
}

export const InvoicesTable = ({ condensedView }: InvoicesTableProps) => {
  const PAGE_SIZE_OPTIONS = [5, 15, 25]
  const history = useHistory()

  const CurrencyFormatter = (cell, row) => {
    return (
      <NumberFormat
        value={cell}
        displayType={'text'}
        fixedDecimalScale={true}
        decimalScale={2}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <span>{value}</span>}
      />
    )
  }

  const activeClient = useSelector(getActiveClient) || {}
  const [errors, setErrors] = useState([])

  const [tableState, setTableState] = useState({
    page: 1,
    pageSize: condensedView ? PAGE_SIZE_OPTIONS[0] : PAGE_SIZE_OPTIONS[2],
    sortField: 'dueDate',
    sortOrder: 'desc',
  })
  const billingInvoices = useSelector((state) => getEntities(state).billingInvoices) || []
  const billingInvoicesTotalCount = useSelector((state) => getEntities(state).billingInvoicesTotalCount) || []

  const [requestQueryState, handleRefresh] = useRequest(
    !activeClient?.id
      ? null
      : getBillingInvoices(
          {
            clientId: activeClient.id,
            sort: getSort(tableState.sortField, tableState.sortOrder),
            skip: (tableState.page - 1) * tableState.pageSize,
            limit: tableState.pageSize,
          },
          {
            force: true,
            transform: (response) => {
              return {
                billingInvoices: response.billingInvoices,
                billingInvoicesTotalCount: response.billingInvoicesTotalCount,
              }
            },
            update: {
              billingInvoices: (oldValue, newValue) => newValue,
              billingInvoicesTotalCount: (oldValue, newValue) => newValue,
            },
          },
        ),
  )
  const actions = [
    {
      name: 'View Declined Transactions',
      onClick: (row) => {
        history.push(`/client/settings/invoices/${row.id}/declined_transactions`)
      },
    },
    {
      name: 'Download PDF',
      onClick: (row) => {
        doDownload(row.id)?.then((response) => {
          setErrors(response?.body?.errors || [])
          if (response?.body?.billing_invoice?.pdf?.url) {
            const pdf = response?.body?.billing_invoice?.pdf?.url
            if (pdf) {
              openPdfFile(pdf, `invoice.pdf`)
            }
          }
        })
      },
    },
  ]

  const [downloadState, doDownload] = useMutation((billingInvoiceId: number) =>
    getBillingInvoiceById({
      clientId: activeClient.id,
      id: billingInvoiceId,
    }),
  )

  const columns: ColumnProps[] = [
    {
      dataField: 'invoiceNumber',
      text: 'Invoice Number',
      sort: false,
      hidden: condensedView || false,
    },
    {
      dataField: 'reference',
      text: 'Reference',
      sort: true,
    },
    {
      dataField: 'subtotal',
      text: 'Subtotal',
      sort: true,
      formatter: CurrencyFormatter,
      hidden: condensedView || false,
    },
    {
      dataField: 'total',
      text: 'Total',
      sort: true,
      formatter: CurrencyFormatter,
    },
    {
      dataField: 'invoiceDate',
      text: 'Invoice Date',
      sort: true,
      formatter: DateFormatter,
    },
    {
      dataField: 'dueDate',
      text: 'Due Date',
      sort: true,
      formatter: DateFormatter,
    },
    {
      dataField: '',
      text: '',
      formatter: ActionFormatterFactory(actions),
      sort: false,
      align: 'right',
      classes: 'sticky-right',
    },
  ]

  function onTableChange(type, { page, sizePerPage, filter, sortField, sortOrder }: OnTableChangeProps) {
    setTableState({
      page: page || tableState.page,
      pageSize: sizePerPage || tableState.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  return (
    <>
      <FormServerErrors errors={(errors && errors['base']) || []} />
      <TableHeader hideImport hideExport />
      <Table
        loading={requestQueryState.isPending}
        data={billingInvoices}
        columns={columns}
        sortField={tableState.sortField}
        sortOrder={tableState.sortOrder}
        onTableChange={onTableChange}
        paginationProps={{
          page: tableState.page,
          totalSize: billingInvoicesTotalCount,
          sizePerPage: tableState.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </>
  )
}
