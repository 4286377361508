// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface DonorAuthSetting
 */
export interface DonorAuthSetting  {
    /**
     * 
     * @type {boolean}
     * @memberof DonorAuthSetting
     */
    requireAdditionalInformation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthSetting
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof DonorAuthSetting
     */
    clientId: number;
    /**
     * 
     * @type {Date}
     * @memberof DonorAuthSetting
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DonorAuthSetting
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthSetting
     */
    termsAndConditions: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthSetting
     */
    commentDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DonorAuthSetting
     */
    enableCommentDescription?: boolean;
    /**
     * 
     * @type {Address}
     * @memberof DonorAuthSetting
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthSetting
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthSetting
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthSetting
     */
    defaultLanguage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DonorAuthSetting
     */
    enableGreeting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DonorAuthSetting
     */
    emailMessage: string;
}

export function DonorAuthSettingFromJSON(json: any): DonorAuthSetting {
    return {
        'requireAdditionalInformation': !exists(json, 'require_additional_information') ? undefined : json['require_additional_information'],
        'code': json['code'],
        'clientId': json['client_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'termsAndConditions': json['terms_and_conditions'],
        'commentDescription': !exists(json, 'comment_description') ? undefined : json['comment_description'],
        'enableCommentDescription': !exists(json, 'enable_comment_description') ? undefined : json['enable_comment_description'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'defaultLanguage': !exists(json, 'default_language') ? undefined : json['default_language'],
        'enableGreeting': !exists(json, 'enable_greeting') ? undefined : json['enable_greeting'],
        'emailMessage': json['email_message'],
    };
}

export function DonorAuthSettingToJSON(value?: DonorAuthSetting): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'require_additional_information': value.requireAdditionalInformation,
        'code': value.code,
        'client_id': value.clientId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'terms_and_conditions': value.termsAndConditions,
        'comment_description': value.commentDescription,
        'enable_comment_description': value.enableCommentDescription,
        'address': AddressToJSON(value.address),
        'email': value.email,
        'phone': value.phone,
        'default_language': value.defaultLanguage,
        'enable_greeting': value.enableGreeting,
        'email_message': value.emailMessage,
    };
}


