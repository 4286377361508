import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, Col, CustomInput, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getEntities, justReplace } from 'app/selectors'
import { Link } from 'react-router-dom'
import { updateEntities } from 'redux-query'
import { Errors } from 'hooks/useFormServerErrors'
import {
  getNotificationSettings,
  NotificationSettings,
  NotificationSettingsFromJSON,
  updateNotificationSettings,
} from 'api'
import { useMutation, useRequest } from 'redux-query-react'
import { cloneDeep } from 'lodash'
import { InfoPopover } from 'components/InfoPopover'

const NotificationSettingsCard = (): JSX.Element => {
  const notificationSettings = useSelector((state) => getEntities(state).notificationSettings)
  const activeClient = useSelector((state) => getEntities(state).activeClient)
  const dispatch = useDispatch()
  const [_changesSaved, setChangesSaved] = useState(false)
  const [hideChangesSavedTimeout, setHideChangesSavedTimeout] = useState<NodeJS.Timeout>()
  const [_originalNotificationSettings, setOriginalNotificationSettings] = useState<NotificationSettings>()
  const [_serverErrors, setServerErrors] = useState<Errors>()

  const [_requestState, _doRequest] = useRequest(
    activeClient?.id &&
      getNotificationSettings(
        { clientId: activeClient.id },
        {
          force: true,
          transform: (body) => ({ notificationSettings: body.notificationSettings }),
          update: { notificationSettings: justReplace },
        },
      ),
  )

  const [_mutationState, doUpdateNotificationSettings] = useMutation((values: NotificationSettings) =>
    updateNotificationSettings({
      clientId: activeClient.id,
      notificationSettings: { ...values },
    }),
  )

  const handleMutationResponse = (response) => {
    if (response?.body?.status === 200) {
      setChangesSaved(true)
      hideChangesSavedTimeout && clearTimeout(hideChangesSavedTimeout)
      setHideChangesSavedTimeout(setTimeout(() => setChangesSaved(false), 3000))
      const newNotificationSettings = NotificationSettingsFromJSON(response?.body?.notification_settings)
      dispatch(updateEntities({ notificationSettings: (_old) => newNotificationSettings }))
      setOriginalNotificationSettings(cloneDeep(newNotificationSettings))
    }
    setServerErrors(response?.body?.messages || {})
  }

  const updateEnabledByDefault = (newValue: boolean, type: string) => {
    const newNotificationSettings = cloneDeep(notificationSettings)
    newNotificationSettings[type].enabledByDefault = newValue
    doUpdateNotificationSettings(newNotificationSettings)?.then((response) => {
      handleMutationResponse(response)
    })
  }

  const handleToggleEnabledByDefault = (event, type) => {
    updateEnabledByDefault(event.target.checked, type)
  }

  return (
    <Card>
      <CardBody>
        <h4 className="header-title mb-3">Notification Settings</h4>
        <Row>
          <Col>
            <CustomInput
              type="switch"
              id="paymentReminderenabledByDefault"
              name="enabledByDefault"
              className="inline-block"
              checked={notificationSettings?.paymentReminder?.enabledByDefault}
              onChange={(event) => {
                handleToggleEnabledByDefault(event, 'paymentReminder')
              }}
            />
            Enable <strong>payment reminders</strong> for new customers
            <InfoPopover popoverText="Enable for existing customers in Advanced Settings" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <CustomInput
              type="switch"
              id="paymentReceiptenabledByDefault"
              name="enabledByDefault"
              className="inline-block"
              checked={notificationSettings?.paymentReceipt?.enabledByDefault}
              onChange={(event) => {
                handleToggleEnabledByDefault(event, 'paymentReceipt')
              }}
            />
            Enable <strong>payment receipts</strong> for new customers
            <InfoPopover popoverText="Enable for existing customers in Advanced Settings" />
          </Col>
        </Row>
        <Link to="/client/settings/notification_settings">
          <Button color="light">Advanced Settings</Button>
        </Link>
      </CardBody>
    </Card>
  )
}

export default NotificationSettingsCard
