// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationSettings,
    NotificationSettingsFromJSON,
    NotificationSettingsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNotificationSettings200Response
 */
export interface GetNotificationSettings200Response  {
    /**
     * 
     * @type {number}
     * @memberof GetNotificationSettings200Response
     */
    status?: number;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GetNotificationSettings200Response
     */
    messages?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {NotificationSettings}
     * @memberof GetNotificationSettings200Response
     */
    notificationSettings?: NotificationSettings;
}

export function GetNotificationSettings200ResponseFromJSON(json: any): GetNotificationSettings200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
        'notificationSettings': !exists(json, 'notification_settings') ? undefined : NotificationSettingsFromJSON(json['notification_settings']),
    };
}

export function GetNotificationSettings200ResponseToJSON(value?: GetNotificationSettings200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'messages': value.messages,
        'notification_settings': NotificationSettingsToJSON(value.notificationSettings),
    };
}


