import React, { useEffect } from 'react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { OnboardingPageProps, NextButton } from './onboardingManager'
import { useTranslation } from 'react-i18next'
import { CustomRadioGroup, CustomRadioInput } from 'components/CustomRadio'
import { useSelector } from 'react-redux'
import { getTransactionQuestionOptions } from 'app/selectors'

export const AverageTransactionAmountComponent = ({
  onNext,
  validate,
  handleFieldChange,
  activeClient,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const transactionQuestionOptions = useSelector(getTransactionQuestionOptions)
  const handleNext = (event, values) => {
    onNext({ e: event, clientValues: values })
  }

  useEffect(() => {
    setCurrentPage((prev) => ({ ...prev!, state: 'transaction_questions' }))
  }, [])

  return (
    <AvForm model={activeClient} onValidSubmit={handleNext} ref={formRef}>
      <h3>Average Transaction Amount</h3>
      <CustomRadioGroup
        name="expectedAverageTransactionAmountRange"
        label="What is the average amount of each transaction that you plan to process with Rotessa?"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        handleFieldChange={handleFieldChange}
      >
        {transactionQuestionOptions?.expected_average_transaction_amount_ranges?.map((option, i) => (
          <CustomRadioInput key={i} name="expectedAverageTransactionAmountRange" label={option} value={option} />
        ))}
      </CustomRadioGroup>
      <NextButton />
    </AvForm>
  )
}

export const CustomerCountComponent = ({
  onNext,
  validate,
  handleFieldChange,
  activeClient,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const transactionQuestionOptions = useSelector(getTransactionQuestionOptions)
  const handleNext = (event, values) => {
    onNext({ e: event, clientValues: values })
  }

  useEffect(() => {
    setCurrentPage((prev) => ({ ...prev!, state: 'transaction_questions' }))
  }, [])

  return (
    <AvForm model={activeClient} onValidSubmit={handleNext} ref={formRef}>
      <h3>Number of Customers</h3>
      <CustomRadioGroup
        name="expectedCustomerCountRange"
        label="How many customers do you expect to process payments for using Rotessa in a normal month?"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        handleFieldChange={handleFieldChange}
      >
        {transactionQuestionOptions?.expected_customer_count_ranges?.map((option, i) => (
          <CustomRadioInput key={i} name="expectedCustomerCountRange" label={option} value={option} />
        ))}
      </CustomRadioGroup>
      <NextButton />
    </AvForm>
  )
}

export const MonthlyTransactionCountComponent = ({
  onNext,
  validate,
  handleFieldChange,
  activeClient,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const transactionQuestionOptions = useSelector(getTransactionQuestionOptions)
  const handleNext = (event, values) => {
    onNext({ e: event, clientValues: values })
  }

  useEffect(() => {
    setCurrentPage((prev) => ({ ...prev!, state: 'transaction_questions' }))
  }, [])

  return (
    <AvForm model={activeClient} onValidSubmit={handleNext} ref={formRef}>
      <h3>Number of Transactions</h3>
      <CustomRadioGroup
        name="expectedMonthlyTransactionCountRange"
        label="How many transactions do you expect to process through Rotessa in a normal month?"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        handleFieldChange={handleFieldChange}
      >
        {transactionQuestionOptions?.expected_monthly_transaction_count_ranges?.map((option, i) => (
          <CustomRadioInput key={i} name="expectedMonthlyTransactionCountRange" label={option} value={option} />
        ))}
      </CustomRadioGroup>
      <NextButton />
    </AvForm>
  )
}

export const MonthlyTransactionAmountComponent = ({
  onNext,
  validate,
  handleFieldChange,
  activeClient,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const transactionQuestionOptions = useSelector(getTransactionQuestionOptions)
  const handleNext = (event, values) => {
    onNext({ e: event, clientValues: values })
  }

  useEffect(() => {
    setCurrentPage((prev) => ({ ...prev!, state: 'transaction_questions' }))
  }, [])

  return (
    <AvForm model={activeClient} onValidSubmit={handleNext} ref={formRef}>
      <h3>Monthly Transaction Total</h3>
      <CustomRadioGroup
        name="expectedMonthlyTransactionAmountRange"
        label="What is the expected total amount of all transactions you will process through Rotessa in a normal month?"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        handleFieldChange={handleFieldChange}
      >
        {transactionQuestionOptions?.expected_monthly_transaction_amount_ranges?.map((option, i) => (
          <CustomRadioInput key={i} name="expectedMonthlyTransactionAmountRange" label={option} value={option} />
        ))}
      </CustomRadioGroup>
      <NextButton />
    </AvForm>
  )
}

export const MaximumTransactionAmountComponent = ({
  onNext,
  validate,
  handleFieldChange,
  activeClient,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()

  const handleNext = (event, values) => {
    onNext({ e: event, clientValues: values })
  }

  useEffect(() => {
    setCurrentPage((prev) => ({ ...prev!, state: 'transaction_questions' }))
  }, [])

  return (
    <AvForm model={activeClient} onValidSubmit={handleNext} ref={formRef}>
      <h3>Highest Transaction</h3>
      <AvField
        name="expectedMaximumTransactionAmount"
        type="number"
        label="What is the highest single transaction amount you expect to process through Rotessa?"
        placeholder="Ex: $250"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          number: {
            value: true,
            errorMessage: 'Please enter a valid number',
          },
          async: validate,
        }}
        onChange={handleFieldChange}
      />
      <NextButton />
    </AvForm>
  )
}
