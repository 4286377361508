import { getEntities } from 'app/selectors'
import React from 'react'
import { Col, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import donation_box_icon from 'assets/images/donor_auth_icons/donation_box_icon.svg'
import checkmark_circle from 'assets/images/donor_auth_icons/checkmark_circle.svg'

export const DonorAuthFinished = () => {
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo)
  const { t } = useTranslation()

  const content =
    donorAuthInfo?.step?.name === 'new_customer_finished' ? (
      <>
        <div className="mt-2 text-center text-almost-black">
          <img src={donation_box_icon} alt="Donation box icon" height={35} />
          <h3>{t('authorization.donation.new_cust_finished.success_thank_you')}</h3>
        </div>
        <Row className="ml-2 mr-2 mb-2">
          <Col xs="auto" className="p-0">
            <img src={checkmark_circle} alt="checkbox circle icon" height={24} />
          </Col>
          <Col>
            <Trans i18nKey="authorization.donation.new_cust_finished.confirmation" components={{ bold: <strong /> }} />
          </Col>
        </Row>
      </>
    ) : (
      <div className="text-center">
        <img src={donation_box_icon} alt="Donation box icon" height={35} />
        <h3 className="mt-3 text-almost-black">{t('authorization.donation.schedule_finished.verified')}</h3>
        <Trans
          i18nKey="authorization.donation.schedule_finished.confirmation"
          values={{ business_name: donorAuthInfo?.client?.businessName }}
          components={{ bold: <strong /> }}
        />
      </div>
    )

  return <>{content}</>
}

export const DonorAuthFinishedFooter = () => {
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo)
  const { t } = useTranslation()

  const content =
    donorAuthInfo?.step?.name === 'new_customer_finished' ? (
      <div className="text-center">
        {t('authorization.donation.new_cust_finished.not_received_intro', {
          business_name: donorAuthInfo?.client?.businessName,
        })}
        {donorAuthInfo?.email &&
          t('authorization.donation.new_cust_finished.not_received_client_email', {
            client_email: donorAuthInfo?.email,
          })}
        .
      </div>
    ) : (
      <div className="text-center">
        {t('authorization.donation.schedule_finished.not_received_intro', {
          business_name: donorAuthInfo?.client?.businessName,
        })}
        {donorAuthInfo?.email &&
          t('authorization.donation.schedule_finished.not_received_client_email', {
            client_email: donorAuthInfo?.email,
          })}
        .
      </div>
    )

  return <>{content}</>
}
