import { useIsMobile } from 'hooks/useIsMobile'
import React, { useState } from 'react'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'
import { randomString } from 'utilities'

type placementType =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'

export interface RotessaPopoverProps {
  popoverContent: string | JSX.Element
  children: any
  placement?: placementType
  mobilePlacement?: placementType
  className?: string
  popoverClassName?: string
  boundariesElement?: 'window' | 'viewport' | 'scrollParent'
}

export const RotessaPopover = (props: RotessaPopoverProps) => {
  const [id, _setId] = useState(`popover${randomString(10)}`)
  const isMobile = useIsMobile()
  let placement = props.placement || 'bottom'
  if (props.mobilePlacement && isMobile) {
    placement = props.mobilePlacement
  }

  return (
    <>
      <div id={id} className={props.className}>
        {props.children}
      </div>

      <UncontrolledPopover
        placement={placement}
        target={id}
        trigger="hover"
        boundariesElement={props.boundariesElement || 'viewport'}
      >
        <PopoverBody className={props.popoverClassName}>{props.popoverContent}</PopoverBody>
      </UncontrolledPopover>
    </>
  )
}
