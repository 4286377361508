// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadedFile
 */
export interface UploadedFile  {
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    url?: string;
}

export function UploadedFileFromJSON(json: any): UploadedFile {
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function UploadedFileToJSON(value?: UploadedFile): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
    };
}


