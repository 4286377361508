import { prepInput } from 'utilities'

export interface Errors {
  [key: string]: string[]
}

export const useFormServerErrors = (errors: Errors | undefined, setErrors: (errors: Errors) => void) => {
  const handleFieldChange = (event) => {
    var inputId = prepInput(event.target.name)
    if (errors && inputId && errors[inputId]) {
      delete errors[inputId]
      setErrors(errors)
    }
  }

  const validate = (value, ctx, input, cb) => {
    if (!errors) {
      cb(true)
      return
    }

    var inputId = prepInput(input.props.name)
    if (errors[inputId]) {
      setTimeout(() => cb(errors[inputId]?.[0] || true), 100)
    } else {
      cb(true)
    }
  }

  return { handleFieldChange, validate }
}
