import React from 'react'
import { Label } from 'reactstrap'
import { CA, US } from 'country-flag-icons/react/3x2'
import { t } from 'i18next'
import { CustomRadioGroup, CustomRadioInput } from './CustomRadio'

export interface CountryRadioProps {
  name?: string
  label?: string | JSX.Element
  className?: string
  required?: boolean
  onChange?: Function
  validate?: { [key: string]: any }
}

export const CountryRadio = ({ name, label, className, required, onChange, validate }: CountryRadioProps) => {
  name = name || 'countryCode'
  return (
    <div>
      <CustomRadioGroup
        name={name}
        label={label}
        className={className}
        handleFieldChange={onChange}
        validate={validate}
        required={required}
      >
        <CustomRadioInput
          name={name}
          label={
            <div className="country-radio-item">
              <CA title="Canada" />
              <span>Canada</span>
            </div>
          }
          value={'CA'}
        />
        <CustomRadioInput
          name={name}
          label={
            <div className="country-radio-item">
              <US title="United States" />
              <span>United States</span>
            </div>
          }
          value={'US'}
        />
      </CustomRadioGroup>
    </div>
  )
}
