import React from 'react'
import classNames from 'classnames'

type TimelineProps = {
  tag?: string
  className?: string
  children?: {}
}

/**
 * Timeline
 */
export const Timeline = (props: TimelineProps) => {
  const children = props.children || null

  return (
    <div className={classNames('timeline-alt', 'pb-0', props.className)} {...props}>
      {children}
    </div>
  )
}
