import React from 'react'
import { AuthorizationSettingsPage } from './authorizationSettingsPage'
import { ProcessingAuthSettingsManager } from 'features/auth_settings/processing/processingAuthSettingsManager'
import { DonorAuthSettingsManager } from 'features/auth_settings/donor/donorAuthSettingsManager'
import { useSelector } from 'react-redux'
import { getActiveClient } from 'app/selectors'
import { Client } from 'api'

export const AuthorizationSettingsDecider = () => {
  const activeClient: Client = useSelector(getActiveClient)

  return (
    <>
      {activeClient?.clientType === 'Donation' ? (
        <DonorAuthSettingsManager />
      ) : activeClient?.newAuthSettingsEnabled ? (
        <ProcessingAuthSettingsManager />
      ) : (
        <AuthorizationSettingsPage />
      )}
    </>
  )
}
