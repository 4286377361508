// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetConstants200ResponseCollectionClientBusinessCategoriesInner,
    GetConstants200ResponseCollectionClientBusinessCategoriesInnerFromJSON,
    GetConstants200ResponseCollectionClientBusinessCategoriesInnerToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetConstants200ResponseCollectionClient
 */
export interface GetConstants200ResponseCollectionClient  {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetConstants200ResponseCollectionClient
     */
    addressCategories?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetConstants200ResponseCollectionClient
     */
    addressTypes?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConstants200ResponseCollectionClient
     */
    businessTypes?: Array<string>;
    /**
     * 
     * @type {Array<GetConstants200ResponseCollectionClientBusinessCategoriesInner>}
     * @memberof GetConstants200ResponseCollectionClient
     */
    businessCategories?: Array<GetConstants200ResponseCollectionClientBusinessCategoriesInner>;
}

export function GetConstants200ResponseCollectionClientFromJSON(json: any): GetConstants200ResponseCollectionClient {
    return {
        'addressCategories': !exists(json, 'address_categories') ? undefined : json['address_categories'],
        'addressTypes': !exists(json, 'address_types') ? undefined : json['address_types'],
        'businessTypes': !exists(json, 'business_types') ? undefined : json['business_types'],
        'businessCategories': !exists(json, 'business_categories') ? undefined : (json['business_categories'] as Array<any>).map(GetConstants200ResponseCollectionClientBusinessCategoriesInnerFromJSON),
    };
}

export function GetConstants200ResponseCollectionClientToJSON(value?: GetConstants200ResponseCollectionClient): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'address_categories': value.addressCategories,
        'address_types': value.addressTypes,
        'business_types': value.businessTypes,
        'business_categories': value.businessCategories === undefined ? undefined : (value.businessCategories as Array<any>).map(GetConstants200ResponseCollectionClientBusinessCategoriesInnerToJSON),
    };
}


