// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransactionSchedule,
    TransactionScheduleFromJSON,
    TransactionScheduleToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchTransactionSchedules200Response
 */
export interface SearchTransactionSchedules200Response  {
    /**
     * 
     * @type {number}
     * @memberof SearchTransactionSchedules200Response
     */
    count?: number;
    /**
     * 
     * @type {Array<TransactionSchedule>}
     * @memberof SearchTransactionSchedules200Response
     */
    records?: Array<TransactionSchedule>;
}

export function SearchTransactionSchedules200ResponseFromJSON(json: any): SearchTransactionSchedules200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(TransactionScheduleFromJSON),
    };
}

export function SearchTransactionSchedules200ResponseToJSON(value?: SearchTransactionSchedules200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(TransactionScheduleToJSON),
    };
}


