import React from 'react'

import { AvForm } from 'availity-reactstrap-validation'
import { Button, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'

import { getEntities } from 'app/selectors'
import { PrependStateProvinceSelect } from 'components/ProvinceSelect'
import { PrependPostalCodeField } from 'components/PostalCodeField'
import { useTranslation } from 'react-i18next'
import { PrependAvField } from 'components/PrependAvField'
import { validatePhone } from 'utilities'

export interface AuthContactInfoProps {
  onSubmit: Function
  validate: Function
  handleFieldChange: Function
}

export const DonorAuthContactInfo = (props: AuthContactInfoProps) => {
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo)
  const { t } = useTranslation()

  return (
    <AvForm onValidSubmit={(_event, values) => props.onSubmit(values)}>
      {donorAuthInfo.step.require_additional_information && (
        <>
          <PrependAvField
            name="phone"
            label={<i className="mdi mdi-phone-outline" />}
            placeholder={t('contact.phone_number')}
            type="tel"
            required
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
              async: props.validate,
              tel: validatePhone,
            }}
            onChange={props.handleFieldChange}
          />
          <Row>
            <Col>
              <PrependAvField
                name="address.address1"
                label={<i className="mdi mdi-map-marker-outline" />}
                placeholder={t('contact.street')}
                type="text"
                required
                validate={{
                  required: {
                    value: true,
                    errorMessage: t('required_field'),
                  },
                  async: props.validate,
                }}
                onChange={props.handleFieldChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PrependAvField
                name="address.city"
                label={<i className="mdi mdi-city" />}
                placeholder={t('contact.city')}
                type="text"
                required
                validate={{
                  required: {
                    value: true,
                    errorMessage: t('required_field'),
                  },
                  async: props.validate,
                }}
                onChange={props.handleFieldChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="donation-select">
                <link
                  rel="stylesheet"
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                />
                <PrependStateProvinceSelect
                  required
                  name="address.provinceCode"
                  countryCode={donorAuthInfo?.client?.countryCode}
                  serverErrors={donorAuthInfo?.errors}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <PrependPostalCodeField name="address.postalCode" countryCode={donorAuthInfo?.client?.countryCode} />
            </Col>
          </Row>
        </>
      )}

      <Button className="continue-btn" color="primary">
        {t('authorization.donation.common.donate')} <i className="mdi mdi-arrow-right-thick" />
      </Button>
    </AvForm>
  )
}
