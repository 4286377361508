import React from 'react'
import bluelogo from 'assets/images/logo-blue.png'
import blacklogo from 'assets/images/Rotessa_logo_full_black.png'
import { useTranslation } from 'react-i18next'
import { ExternalLink } from './ExternalLink'

export interface PoweredByRotessaProps {
  type?: string
  text?: string
  link_to_rotessa?: boolean
  source?: 'authPage' | 'unsubscribePage'
  noMargin?: boolean
}

export const PoweredByRotessaFooter = ({
  type,
  text,
  link_to_rotessa = true,
  source,
  noMargin,
}: PoweredByRotessaProps) => {
  const { t } = useTranslation()
  let utm = ''
  switch (source) {
    case 'authPage':
      utm = '?utm_source=authpagelogo'
      break
    case 'unsubscribePage':
      utm = '?utm_source=notificationsunsubscribepagelogo'
      break
  }

  const rotessaImg =
    type === 'donation_footer' ? (
      <img src={blacklogo} height={15} alt="Rotessa" />
    ) : (
      <img src={bluelogo} height={15} alt="Rotessa" />
    )
  return (
    <div className={`text-center${noMargin ? '' : ' mb-4'}`}>
      <p className="text-muted mb-1">
        <small>{text || t('authorization.common.footer')}</small>
      </p>
      {link_to_rotessa ? <ExternalLink to={`https://rotessa.com${utm}`}>{rotessaImg}</ExternalLink> : rotessaImg}
    </div>
  )
}
