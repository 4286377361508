// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportSettlementTransactions200Response
 */
export interface ExportSettlementTransactions200Response  {
    /**
     * 
     * @type {number}
     * @memberof ExportSettlementTransactions200Response
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportSettlementTransactions200Response
     */
    csv?: string;
}

export function ExportSettlementTransactions200ResponseFromJSON(json: any): ExportSettlementTransactions200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'csv': !exists(json, 'csv') ? undefined : json['csv'],
    };
}

export function ExportSettlementTransactions200ResponseToJSON(value?: ExportSettlementTransactions200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'csv': value.csv,
    };
}


