import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row, Button } from 'reactstrap'

import { RootState } from 'app/rootReducer'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { maskBankInfo } from 'utilities'
import actionRequired from 'assets/images/customer_dashboard_icons/Action-required-icon-2.png'
import { OpenFileLink } from 'components/ExportButton'

import { getCustsomerPermissionForm } from 'api'

export interface CustomerBankAndAuthDetailsProps {
  readOnly?: boolean
}

export const CustomerBankAndAuthDetails = (props: CustomerBankAndAuthDetailsProps) => {
  const customer = useSelector((state: RootState) => state.entities.customer) || {}
  const permitEdit = useIsPermitted('customers.edit')
  const permitViewAuth = useIsPermitted('customers.view_permission_form')
  var rows: any[] = []

  if (customer.bankAccount?.accountNumber?.length > 0) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-bank mr-2" />
        </td>
        <td>
          {[
            customer.bankAccount.bankAccountType || '',
            maskBankInfo(customer.bankAccount.routingNumber || ''),
            maskBankInfo(customer.bankAccount.transitNumber || ''),
            maskBankInfo(customer.bankAccount.institutionNumber || ''),
            maskBankInfo(customer.bankAccount.accountNumber || ''),
          ]
            .filter((a) => a)
            .join(', ')}
        </td>
      </tr>
    ))
  }

  if (customer.authorizationType && customer.authorizationType !== 'Grandfathered') {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-shield-check mr-2" />
        </td>
        <td>{customer.authorizationType}</td>
      </tr>
    ))
  }

  if (customer.authorizationAmount) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-currency-usd mr-2" />
        </td>
        <td>${customer.authorizationAmount}</td>
      </tr>
    ))
  }

  if (customer.permissionForm?.url && permitViewAuth) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td>
          <i className="mdi mdi-paperclip mr-2" />
        </td>
        <td>
          <OpenFileLink
            exportQueryConfig={getCustsomerPermissionForm(
              {
                id: customer.id || 0,
              },
              {
                force: true,
              },
            )}
          >
            Authorization Form
          </OpenFileLink>
        </td>
      </tr>
    ))
  }

  return (
    <Card>
      <CardBody>
        <Row className="d-flex align-items-center">
          <Col>
            <h4 className="header-title no-wrap mb-3">{'Bank & Authorization Details'}</h4>
          </Col>
          <Col>
            {!props.readOnly && permitEdit && customer.statuses && (
              <Link to={`${customer.id}/edit_bank_and_auth_details`} className="float-right mb-3">
                <span>
                  {customer.statuses && customer.requiredAction === 'complete_bank_details' && (
                    <img src={actionRequired} height={15} style={{ marginBottom: '4px' }} className="mr-1" alt="" />
                  )}
                  Edit
                </span>
              </Link>
            )}
          </Col>
        </Row>

        {rows.length > 0 ? (
          <table>
            <tbody>
              {rows.map((Row, i) => (
                <Row key={i} />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <i className="mdi mdi-information mr-2" />
            No data
          </div>
        )}
      </CardBody>
    </Card>
  )
}
