import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest, useMutation } from 'redux-query-react'
import { Redirect, useLocation } from 'react-router-dom'
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import { Button, Card, CardBody, Row, Col, Fade } from 'reactstrap'

import { LinkAuthorization, submitLinkAuthorization, getLinkAuthorizationDetails } from 'api'
import { getEntities, getTransactionFrequencies } from 'app/selectors'
import { ProcessingAcceptTermsCheckbox } from './processingAuthAcceptTermsCheckbox'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'
import { AvDatePicker } from 'components/Datepicker'
import { FormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { BankAccountFields } from 'components/BankAccountFields'
import { CenterContent } from 'components/CenterContent'
import { validatePhone } from 'utilities'
import { InfoPopover } from 'components/InfoPopover'

export const LinkAuthorizationForm = () => {
  const [processDate, setProcessDate] = useState<Date>()
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const [finished, setFinished] = useState(false)
  const query = new URLSearchParams(useLocation().search)
  const { client, settings } = useSelector((state) => getEntities(state).linkAuthDetails) || {}
  const transactionFrequencies = useSelector(getTransactionFrequencies)
  const address = client?.address || {}
  const formUrl = query.get('form_url')
  const [frequency, setFrequency] = useState('')

  const newLinkAuth: LinkAuthorization = {
    name: '',
    email: '',
    formUrl: formUrl || '',
  }
  const [requestState, refresh] = useRequest(
    !formUrl
      ? null
      : getLinkAuthorizationDetails(
          {
            formUrl: formUrl,
          },
          {
            force: true,
            transform: (body) => {
              return {
                linkAuthDetails: body,
              }
            },
            update: {
              linkAuthDetails: (_, newValue) => newValue,
            },
          },
        ),
  )

  const [{ isPending }, doSubmit] = useMutation((values: LinkAuthorization) => {
    return submitLinkAuthorization({
      linkAuthorization: values,
    })
  })

  const isSpecialFrequency = ['Once'].indexOf(frequency) !== -1

  function handleValidSubmit(event, values) {
    doSubmit({
      ...values,
      processDate: processDate,
      installments: frequency === 'Once' ? 1 : values.installments,
    })?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      if (response?.body?.status === 200) {
        setFinished(true)
      }
    })
  }

  if (requestState.isPending) {
    return <p>...</p>
  }

  if (requestState.isFinished && requestState.status !== 200) {
    return <p>Something went wrong...</p>
  }

  if (client?.newAuthSettingsEnabled) {
    return <Redirect to={`/authorize/${formUrl}`} />
  }

  return (
    <CenterContent>
      <Fade>
        <Card className="mt-4">
          <CardBody>
            {finished ? (
              <div className="text-center">
                {client?.logo?.url && (
                  <img src={client.logo.url} style={{ maxHeight: '200px', maxWidth: '200px' }} alt="" />
                )}
                <h2>Nice job, you're all set up!</h2>
                <p>A copy of the terms and conditions for your bank payment agreement has been sent to your inbox.</p>
              </div>
            ) : (
              <AvForm disabled={isPending} onValidSubmit={handleValidSubmit} model={newLinkAuth}>
                <AvInput hidden name="formUrl" />

                {client?.logo?.url && (
                  <img src={client.logo.url} style={{ maxHeight: '200px', maxWidth: '200px' }} alt="" />
                )}
                <Row className="mb-2">
                  <Col md={6}>
                    <h4>{client?.businessName}</h4>
                    {settings?.displayClientAddress && address?.address1 && (
                      <p className="text-muted">
                        {address?.address1}
                        <br />
                        {address?.city}, {address?.provinceCode}
                        <br />
                        {address?.postalCode}
                      </p>
                    )}
                  </Col>
                  <Col md={6}>
                    <h4 className="text-right">AUTHORIZATION REQUEST</h4>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    {settings?.introduction && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: settings.introduction,
                        }}
                      />
                    )}
                  </Col>
                </Row>

                <FormServerErrors errors={(serverErrors && serverErrors['base']) || []} />

                <h4 className="header-title">Contact Information</h4>
                <Row className="mb-2">
                  <Col md={settings?.requirePhone ? 4 : 6}>
                    <AvField
                      name="name"
                      label="Name"
                      type="text"
                      placeholder="First Last"
                      required
                      validate={{ async: validate }}
                      onChange={handleFieldChange}
                    />
                  </Col>
                  <Col md={settings?.requirePhone ? 4 : 6}>
                    <AvField
                      name="email"
                      label="Email"
                      type="text"
                      placeholder="ex@mple.com"
                      required
                      validate={{ async: validate }}
                      onChange={handleFieldChange}
                    />
                  </Col>
                  {settings?.requirePhone && (
                    <Col md={4}>
                      <AvField
                        name="phone"
                        label="Phone"
                        type="text"
                        placeholder="(123) 456-7890"
                        required
                        validate={{ tel: validatePhone, async: validate }}
                        onChange={handleFieldChange}
                      />
                    </Col>
                  )}
                </Row>

                {settings?.requireAddress && (
                  <>
                    <h4 className="header-title">Address</h4>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="address1"
                          label="Street"
                          type="text"
                          required
                          validate={{ async: validate }}
                          onChange={handleFieldChange}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="city"
                          label="City"
                          type="text"
                          required
                          validate={{ async: validate }}
                          onChange={handleFieldChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col lg={6}>
                        <AvField
                          name="provinceCode"
                          label="State/Province"
                          type="text"
                          required
                          validate={{ async: validate }}
                          onChange={handleFieldChange}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="postalCode"
                          label="Zip/Postal Code"
                          type="text"
                          required
                          validate={{ async: validate }}
                          onChange={handleFieldChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <h4 className="header-title">Banking Information</h4>
                {settings?.allowMicrodeposit && (
                  <Row className="mb-2">
                    <Col>
                      <small className="text-muted">
                        In order to verify your account details, two microdeposits will be deposited in your bank
                        account. A validation form will be emailed to you to confirm the two amounts deposited to your
                        account. It typically takes one to two business days for the amounts to appear in your account.
                      </small>
                    </Col>
                  </Row>
                )}
                <BankAccountFields countryCode={client?.countryCode} required={true} serverErrors={serverErrors} />

                {(settings?.requireMaximumAmount || client?.countryCode === 'US') && (
                  <>
                    <h4 className="header-title">Maximum Amount</h4>
                    <Row className="mb-2">
                      <Col md={8}>
                        I allow {client?.businessName} to debit my account for future charges based on agreed amounts up
                        to a daily maximum of:
                      </Col>
                      <Col md={4}>
                        <AvField
                          name="authorizationAmount"
                          type="number"
                          required
                          validate={{ async: validate }}
                          onChange={handleFieldChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {settings?.allowTransactionSchedule && (
                  <>
                    <h4 className="header-title">Payment Details</h4>
                    <Row>
                      <Col md={4}>
                        <AvField
                          name="amount"
                          label="Amount"
                          type="number"
                          required
                          validate={{ async: validate }}
                          onChange={handleFieldChange}
                        />
                      </Col>
                      <Col md={4}>
                        <AvDatePicker
                          name="processDate"
                          label="Process Date"
                          value={processDate}
                          onChange={(v) => setProcessDate(v)}
                          required
                          serverErrors={serverErrors && serverErrors['process_date']}
                        />
                      </Col>
                      <Col md={4}>
                        <AvField
                          name="frequency"
                          label="Frequency"
                          type="select"
                          required
                          validate={{ async: validate }}
                          onChange={(e, v) => {
                            handleFieldChange(e)
                            setFrequency(v)
                          }}
                        >
                          <option></option>
                          {transactionFrequencies &&
                            Object.values<string>(transactionFrequencies)
                              .filter((f) => f !== transactionFrequencies.twice)
                              .map((frequency, i) => (
                                <option key={i} value={frequency}>
                                  {frequency}
                                </option>
                              ))}
                        </AvField>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={4}>
                        <AvField
                          name="installments"
                          label={
                            <span>
                              Installments
                              <InfoPopover popoverText={`Leave installments blank for indefinite.`} />
                            </span>
                          }
                          type="number"
                          value={frequency === transactionFrequencies.once ? 1 : undefined}
                          disabled={isSpecialFrequency}
                          validate={{ async: validate }}
                          onChange={handleFieldChange}
                        />
                      </Col>
                      <Col md={8}>
                        <AvField
                          name="comment"
                          label="Leave a comment"
                          type="text"
                          validate={{ async: validate }}
                          onChange={handleFieldChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <h4 className="header-title">Terms & Conditions</h4>
                <Row className="mb-2">
                  <Col>
                    <ProcessingAcceptTermsCheckbox
                      businessName={client?.businessName}
                      terms={settings?.termsAndConditions}
                      serverErrors={serverErrors}
                    />
                  </Col>
                </Row>

                <div className="text-center">
                  <Button disabled={isPending} color="primary">
                    <i className="mdi mdi-lock-outline mr-1" />
                    Authorize
                  </Button>
                </div>
              </AvForm>
            )}
          </CardBody>
        </Card>
        <PoweredByRotessaFooter type="blue_footer" />
      </Fade>
    </CenterContent>
  )
}
