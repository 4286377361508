import { getEntities } from 'app/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { ProcessingAuthHeader } from './processingAuthHeader'
import { useTranslation, Trans } from 'react-i18next'

export const ProcessingAuthFinished = () => {
  const processingAuthInfo = useSelector((state) => getEntities(state).processingAuthInfo)
  const { t } = useTranslation()

  const content = processingAuthInfo?.step?.require_verification ? (
    <>
      {processingAuthInfo?.step.verification_method === 'microdeposit' && (
        <p className="text-center">
          <Trans i18nKey="authorization.processing.manual_bank_info.info">
            In 1-2 business days, two microdeposits will be deposited into your account to
            <b>verify your bank</b> information. You will receive a validation email to <b>confirm the two amounts</b>
            deposited into your account.
          </Trans>
          <br />
          <br />
          {t('authorization.processing.finished.success_terms_sent')}
        </p>
      )}
      {processingAuthInfo?.step.verification_method === 'flinks' && (
        <p className="text-center">
          {t('authorization.processing.finished.success_thank_you')}
          <br />
          {t('authorization.processing.flinks.verifying_details')}
          <br />
          <br />
          <Trans i18nKey="authorization.processing.flinks.confirmation">
            You will receive a <b>confirmation email</b> with a copy of the terms and conditions for your authorization
            agreement when your bank details have been successfully verified.
          </Trans>
        </p>
      )}
    </>
  ) : (
    <div className="text-center">
      <h2 className="text-primary">{t('authorization.processing.finished.success')}</h2>
      <p>
        {t('authorization.processing.finished.success_thank_you')}
        <br />
        {t('authorization.processing.finished.success_terms_sent')}
      </p>
    </div>
  )

  return (
    <>
      <ProcessingAuthHeader />
      {content}
    </>
  )
}

export const ProcessingAuthFinishedFooter = () => {
  const processingAuthInfo = useSelector((state) => getEntities(state).processingAuthInfo)
  const { t } = useTranslation()

  const content = (
    <div className="text-center">
      {t('authorization.processing.finished.not_received_intro', {
        business_name: processingAuthInfo?.client?.businessName,
      })}
      {processingAuthInfo?.email &&
        t('authorization.processing.finished.not_received_client_email', {
          client_email: processingAuthInfo?.email,
        })}
      .
    </div>
  )

  return <>{content}</>
}
