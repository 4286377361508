import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CustomInput, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useMutation, useRequest } from 'redux-query-react'
import { Link } from 'react-router-dom'

import { RootState } from 'app/rootReducer'
import { getIntegrationCustomer, disconnectCustomer } from 'api'
import { customerQuery } from 'app/queries'
import { getActiveClient, getCustomer, getEntities, getCustomerActions } from 'app/selectors'
import { Loading } from 'components/Loading'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { ActionPromiseValue } from 'redux-query'
import { integrationName } from 'components/Integrations'
import { QUICKBOOKS } from 'app/constants'
import { InfoPopover } from 'components/InfoPopover'
import { isPermitted } from 'utilities'

export interface CustomerIntegrationDetailsProps {
  doAction: (action: string, onResponse?: (response: ActionPromiseValue<unknown>) => void) => void
}

export const CustomerIntegrationDetails = ({ doAction }: CustomerIntegrationDetailsProps) => {
  const customer = useSelector(getCustomer) || {}
  const integrationCustomer = useSelector((state: RootState) => getEntities(state).integrationCustomer) || []
  const activeClient = useSelector(getActiveClient) || {}
  const customerActions = useSelector(getCustomerActions) || {}
  const [customerToDisconnect, setCustomerToDisconnect] = useState(false)
  const [disconnectCustomerQueryState, doDisconnectCustomer] = useMutation((customerId: number) =>
    disconnectCustomer({ id: customerId }),
  )
  const [customerRequestState, doCustomerRequest] = useRequest(customerQuery(true, customer.id))

  useClearEntitiesOnUnmount(['integrationCustomer'])

  const [requestState, doRequest] = useRequest(
    customer?.id &&
      getIntegrationCustomer(
        {
          clientId: activeClient?.id || 0,
          customerId: customer.id,
        },
        {
          force: true,
          transform: (response) => {
            return {
              integrationCustomer: response.integrationCustomer,
            }
          },
          update: {
            integrationCustomer: (oldValue, newValue) => newValue,
          },
        },
      ),
  )

  var rows: any[] = []

  if (integrationCustomer?.name) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td className="td-fit-width-content-no-padding">
          <i className={'mdi mdi-account mr-2'} />
        </td>
        <td>{integrationCustomer.name}</td>
      </tr>
    ))
  }

  if (integrationCustomer?.phone) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td className="td-fit-width-content-no-padding">
          <i className="mdi mdi-phone-outline mr-2" />
        </td>
        <td>{integrationCustomer.phone}</td>
      </tr>
    ))
  }

  if (integrationCustomer?.email) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td className="td-fit-width-content-no-padding">
          <i className="mdi mdi-email-outline mr-2" />
        </td>
        <td>{integrationCustomer.email}</td>
      </tr>
    ))
  }

  if (integrationCustomer.address?.address1) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td className="td-fit-width-content-no-padding">
          <i className="mdi mdi-map-marker-outline mr-2" />
        </td>
        <td>
          {integrationCustomer.address?.address1} {integrationCustomer.address?.city},{' '}
          {integrationCustomer.address?.provinceCode} {integrationCustomer.address?.postalCode}
        </td>
      </tr>
    ))
  }

  rows.push(() => (
    <tr style={{ verticalAlign: 'top' }}>
      <td colSpan={2}>
        <CustomInput
          type="switch"
          id={`autoImportInvoices`}
          name={'autoImportInvoices'}
          label={<span className="font-weight-normal text-secondary">Automatically import customer invoices</span>}
          className="mt-2"
          checked={customer?.autoImportInvoices}
          disabled={!isPermitted('integrations.customers', activeClient)}
          onChange={(event) => {
            const action = event.target.checked
              ? customerActions.enableAutoImportInvoices
              : customerActions.disableAutoImportInvoices
            doAction(action)
          }}
        />
      </td>
    </tr>
  ))

  rows.push(() => (
    <tr style={{ verticalAlign: 'top' }}>
      <td colSpan={2}>
        <CustomInput
          type="switch"
          id={`autoUpdate`}
          name={'autoUpdate'}
          label={
            <span className="font-weight-normal text-secondary">
              Automatically update customer
              <InfoPopover
                popoverText={`Customer name and contact information from ${integrationName(
                  activeClient?.integration,
                )} will automatically update and override information in Rotessa.`}
              />
            </span>
          }
          checked={customer?.autoUpdate}
          disabled={!isPermitted('integrations.customers', activeClient)}
          onChange={(event) => {
            const action = event.target.checked ? customerActions.enableAutoUpdate : customerActions.disableAutoUpdate
            doAction(action)
          }}
        />
      </td>
    </tr>
  ))

  if (isPermitted('integrations.disconnect', activeClient)) {
    rows.push(() => (
      <tr style={{ verticalAlign: 'top' }}>
        <td colSpan={2} className="pt-2">
          <Link to="#" onClick={() => setCustomerToDisconnect(true)} className="text-muted">
            <i className="mdi mdi-close-octagon mr-1" />
            Disconnect customer from {integrationName(customer.apiService)}
          </Link>
        </td>
      </tr>
    ))
  }

  const loading = requestState.isPending ? (
    <Loading />
  ) : (
    <div className="text-center">
      <i className="mdi mdi-information mr-2" />
      No data
    </div>
  )

  function handleClickDisconnect() {
    if (customerToDisconnect) {
      doDisconnectCustomer(customer.id)?.then(() => doCustomerRequest())
    } else {
      doCustomerRequest()
    }
    setCustomerToDisconnect(false)
  }

  return (
    <>
      {
        <Modal isOpen={customerToDisconnect}>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalBody>
            Are you sure you want to disconnect {customer.name} from {integrationName(customer.apiService)}?
          </ModalBody>
          <ModalFooter className="text-right">
            <Button onClick={() => setCustomerToDisconnect(false)} className="btn-rotessa-secondary min-width">
              No
            </Button>
            <Button color="primary" onClick={handleClickDisconnect} className="ml-2">
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      }
      <Card>
        <CardBody>
          <h4 className="header-title mb-3">
            {integrationName(customer.apiService)}{' '}
            {integrationName(customer.apiService) === QUICKBOOKS ? 'Customer' : 'Contact'} Information
          </h4>
          {rows.length > 1 ? (
            <table>
              <tbody>
                {rows.map((Row, i) => (
                  <Row key={i} />
                ))}
              </tbody>
            </table>
          ) : (
            loading
          )}
        </CardBody>
      </Card>
    </>
  )
}
