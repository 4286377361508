// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerStatuses
 */
export interface CustomerStatuses  {
    [key: string]: any | any;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerStatuses
     */
    complete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerStatuses
     */
    authType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerStatuses
     */
    authRequested?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerStatuses
     */
    mdVerification?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerStatuses
     */
    flinksVerification?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerStatuses
     */
    verified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerStatuses
     */
    integration?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerStatuses
     */
    api?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerStatuses
     */
    archived?: boolean;
}

export function CustomerStatusesFromJSON(json: any): CustomerStatuses {
    return {
            ...json,
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'authType': !exists(json, 'auth_type') ? undefined : json['auth_type'],
        'authRequested': !exists(json, 'auth_requested') ? undefined : json['auth_requested'],
        'mdVerification': !exists(json, 'md_verification') ? undefined : json['md_verification'],
        'flinksVerification': !exists(json, 'flinks_verification') ? undefined : json['flinks_verification'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'integration': !exists(json, 'integration') ? undefined : json['integration'],
        'api': !exists(json, 'api') ? undefined : json['api'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
    };
}

export function CustomerStatusesToJSON(value?: CustomerStatuses): any {
    if (value === undefined) {
        return undefined;
    }
    return {
            ...value,
        'complete': value.complete,
        'auth_type': value.authType,
        'auth_requested': value.authRequested,
        'md_verification': value.mdVerification,
        'flinks_verification': value.flinksVerification,
        'verified': value.verified,
        'integration': value.integration,
        'api': value.api,
        'archived': value.archived,
    };
}


