// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntegrationInvoice,
    IntegrationInvoiceFromJSON,
    IntegrationInvoiceToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetIntegrationInvoices200Response
 */
export interface GetIntegrationInvoices200Response  {
    /**
     * 
     * @type {number}
     * @memberof GetIntegrationInvoices200Response
     */
    invoicesAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof GetIntegrationInvoices200Response
     */
    invoicesOutstanding?: number;
    /**
     * 
     * @type {number}
     * @memberof GetIntegrationInvoices200Response
     */
    invoicesImported?: number;
    /**
     * 
     * @type {Date}
     * @memberof GetIntegrationInvoices200Response
     */
    minDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetIntegrationInvoices200Response
     */
    maxDate?: Date;
    /**
     * 
     * @type {Array<IntegrationInvoice>}
     * @memberof GetIntegrationInvoices200Response
     */
    invoices?: Array<IntegrationInvoice>;
}

export function GetIntegrationInvoices200ResponseFromJSON(json: any): GetIntegrationInvoices200Response {
    return {
        'invoicesAvailable': !exists(json, 'invoices_available') ? undefined : json['invoices_available'],
        'invoicesOutstanding': !exists(json, 'invoices_outstanding') ? undefined : json['invoices_outstanding'],
        'invoicesImported': !exists(json, 'invoices_imported') ? undefined : json['invoices_imported'],
        'minDate': !exists(json, 'min_date') ? undefined : new Date(json['min_date']),
        'maxDate': !exists(json, 'max_date') ? undefined : new Date(json['max_date']),
        'invoices': !exists(json, 'invoices') ? undefined : (json['invoices'] as Array<any>).map(IntegrationInvoiceFromJSON),
    };
}

export function GetIntegrationInvoices200ResponseToJSON(value?: GetIntegrationInvoices200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'invoices_available': value.invoicesAvailable,
        'invoices_outstanding': value.invoicesOutstanding,
        'invoices_imported': value.invoicesImported,
        'min_date': value.minDate === undefined ? undefined : value.minDate.toISOString().substring(0,10),
        'max_date': value.maxDate === undefined ? undefined : value.maxDate.toISOString().substring(0,10),
        'invoices': value.invoices === undefined ? undefined : (value.invoices as Array<any>).map(IntegrationInvoiceToJSON),
    };
}


