// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettlementTransactionsClientOnboardingSettlementAccount
 */
export interface SettlementTransactionsClientOnboardingSettlementAccount  {
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactionsClientOnboardingSettlementAccount
     */
    verificationMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementTransactionsClientOnboardingSettlementAccount
     */
    verificationStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementTransactionsClientOnboardingSettlementAccount
     */
    flinksAttempts?: number;
}

export function SettlementTransactionsClientOnboardingSettlementAccountFromJSON(json: any): SettlementTransactionsClientOnboardingSettlementAccount {
    return {
        'verificationMethod': !exists(json, 'verification_method') ? undefined : json['verification_method'],
        'verificationStatus': !exists(json, 'verification_status') ? undefined : json['verification_status'],
        'flinksAttempts': !exists(json, 'flinks_attempts') ? undefined : json['flinks_attempts'],
    };
}

export function SettlementTransactionsClientOnboardingSettlementAccountToJSON(value?: SettlementTransactionsClientOnboardingSettlementAccount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'verification_method': value.verificationMethod,
        'verification_status': value.verificationStatus,
        'flinks_attempts': value.flinksAttempts,
    };
}


