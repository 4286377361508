import React from 'react'
import { Card, CardBody, CardGroup, CardHeader, Col, Row } from 'reactstrap'

export interface IntegrationTypesProps {
  logo?: string
  content: {
    imgSrc: string
    text: string
  }[]
}

export const IntegrationInfoPanel = ({ logo, content }: IntegrationTypesProps) => {
  return (
    <>
      {logo && (
        <Row className="justify-content-center">
          <img src={logo} height={100} className="mt-2 mb-2" alt="" />
        </Row>
      )}

      <Row className="justify-content-space-between">
        <Col>
          <CardGroup className="d-flex justify-content-between ">
            {content &&
              content.map((obj, index) => (
                <Card className={index !== 0 ? 'ml-2' : ''}>
                  <CardHeader className="p-0 align-middle ">
                    <img style={{ objectFit: 'cover', width: '100%' }} src={obj.imgSrc} alt="" />
                  </CardHeader>
                  <CardBody className="text-center d-flex align-items-center p-1">
                    <p className="text-muted mb-0" style={{ lineHeight: '1.2', padding: '1rem' }}>
                      {obj.text}
                    </p>
                  </CardBody>
                </Card>
              ))}
          </CardGroup>
        </Col>
      </Row>
    </>
  )
}

export const IntegrationInfoPanelFixed = ({ logo, content }: IntegrationTypesProps) => {
  return (
    <>
      {logo && (
        <Row className="justify-content-center">
          <img src={logo} height={100} className="mt-2 mb-2" alt="" />
        </Row>
      )}

      <Row className="justify-content-center">
        {content &&
          content.map((obj) => (
            <Card className="integration-card m-2">
              <CardHeader className="p-0 align-middle">
                <img width={250} style={{ objectFit: 'cover', objectPosition: '' }} src={obj.imgSrc} alt="" />
              </CardHeader>
              <CardBody className="text-center d-flex align-items-center p-0">
                <p className="text-muted mb-0" style={{ lineHeight: '1.2', padding: '1rem' }}>
                  {obj.text}
                </p>
              </CardBody>
            </Card>
          ))}
      </Row>
    </>
  )
}
