// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateCustomersAndSendAuthorizationsCustomersInner,
    CreateCustomersAndSendAuthorizationsCustomersInnerFromJSON,
    CreateCustomersAndSendAuthorizationsCustomersInnerToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateCustomersAndSendAuthorizations
 */
export interface CreateCustomersAndSendAuthorizations  {
    /**
     * 
     * @type {Array<CreateCustomersAndSendAuthorizationsCustomersInner>}
     * @memberof CreateCustomersAndSendAuthorizations
     */
    customers?: Array<CreateCustomersAndSendAuthorizationsCustomersInner>;
    /**
     * 
     * @type {number}
     * @memberof CreateCustomersAndSendAuthorizations
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomersAndSendAuthorizations
     */
    message?: string;
}

export function CreateCustomersAndSendAuthorizationsFromJSON(json: any): CreateCustomersAndSendAuthorizations {
    return {
        'customers': !exists(json, 'customers') ? undefined : (json['customers'] as Array<any>).map(CreateCustomersAndSendAuthorizationsCustomersInnerFromJSON),
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function CreateCustomersAndSendAuthorizationsToJSON(value?: CreateCustomersAndSendAuthorizations): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'customers': value.customers === undefined ? undefined : (value.customers as Array<any>).map(CreateCustomersAndSendAuthorizationsCustomersInnerToJSON),
        'client_id': value.clientId,
        'message': value.message,
    };
}


