import React from 'react'

import { Card, CardImg, CardBody, Row, Col, Alert } from 'reactstrap'

import wordIcon from 'assets/images/Microsoft-word.png'
import googleDocsIcon from 'assets/images/Google-docs.png'
import applePagesIcon from 'assets/images/Apple-pages.png'
import pdfIcon from 'assets/images/pdf.png'
import { ExternalLink } from 'components/ExternalLink'

export const AuthorizationTemplates = () => {
  return (
    <>
      <h4 className="header-title mb-3">Templates for In-person</h4>
      <p>To collect an authorization in-person, download our pre-made authorization form in your preferred format.</p>
      <p>Edits can be made to personalize the agreement between you and your customer.</p>
      <Row>
        <Col md={6} lg={3}>
          <ExternalLink to="/authorization_templates/Rotessa_Pre-authorization_Template.docx" download>
            <Card className="equal-height-card">
              <CardImg src={wordIcon} />
              <CardBody className="text-center">Microsoft Word</CardBody>
            </Card>
          </ExternalLink>
        </Col>
        <Col md={6} lg={3}>
          <ExternalLink to="https://docs.google.com/document/d/1OIwNaVq7_1HAhhqCSkvgxhExTnDV5siCeZdQPvKsY0k/edit?usp=sharing">
            <Card className="equal-height-card">
              <CardImg src={googleDocsIcon} />
              <CardBody className="text-center">Google Docs</CardBody>
            </Card>
          </ExternalLink>
        </Col>
        <Col md={6} lg={3}>
          <ExternalLink to="/authorization_templates/Rotessa_Pre-authorization_Template.pages" download>
            <Card className="equal-height-card">
              <CardImg src={applePagesIcon} />
              <CardBody className="text-center">Apple Pages</CardBody>
            </Card>
          </ExternalLink>
        </Col>
        <Col md={6} lg={3}>
          <ExternalLink to="/authorization_templates/Rotessa_Pre-authorization_Template.pdf" download>
            <Card className="equal-height-card">
              <CardImg src={pdfIcon} />
              <CardBody className="text-center">PDF</CardBody>
            </Card>
          </ExternalLink>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert color="info" className="alert-with-icon">
            <i className="mdi mdi-information-outline mr-2" />
            You are required to retain a copy of a customer's completed authorization agreement at least one year after
            the most recent transaction. We encourage you to upload a scan or photo of the completed authorization form
            to the customer's profile in Rotessa.
          </Alert>
        </Col>
      </Row>
    </>
  )
}
