import React from 'react'
import { useRequest } from 'redux-query-react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap'

import { getEntities } from 'app/selectors'
import { ProfileDropdown } from 'features/topbar/profileDropdown'
import { TransactionSchedulesTable } from 'features/customers/transactionSchedules/transactionSchedulesTable'
import { TransactionHistoryTable } from 'features/customers/transactionHistory/transactionHistoryTable'
import { customerQuery } from 'app/queries'

import logo from 'assets/images/logo-blue.png'
import { Loading } from 'components/Loading'
import { Link } from 'react-router-dom'
import { CustomerDetails } from 'features/customers/customerDetails'

export const StudentManager = () => {
  const account = useSelector((state) => getEntities(state).account) || {}
  const [requestStatus, doRequest] = useRequest(customerQuery(true, account?.customerId))

  return (
    <>
      <Row className="student-navbar">
        <Col>
          <Link to="/" className="logo text-center logo-light">
            <span className="logo logo-lg">
              <img src={logo} alt="logo" height="20" />
            </span>
          </Link>
        </Col>
        <Col className="text-right">
          <span className="topbar-right-menu float-right">
            <ProfileDropdown />
          </span>
        </Col>
      </Row>
      {requestStatus.isPending ? (
        <Loading />
      ) : (
        <div style={{ paddingTop: '70px' }}>
          <div className="text-center m-2">
            <h2>Hello, {account?.firstName}</h2>
          </div>
          <Row className="m-1">
            <Col lg={9}>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <TransactionSchedulesTable readOnly />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <TransactionHistoryTable />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={3}>
              <CustomerDetails readOnly />
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}
