import React from 'react'
import { useSelector } from 'react-redux'
import { getProvinces, getStates } from 'app/selectors'
import { Province } from 'api'

import { AvField } from 'availity-reactstrap-validation'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { useTranslation } from 'react-i18next'
import PrependAvField from './PrependAvField'

export interface StateProvinceSelectProps {
  name: string
  countryCode?: string
  required?: boolean
  serverErrors?: Errors
  placeholderLabel?: boolean
  onChange?: Function
  placeholder?: string
}

export const StateProvinceSelect = ({
  name,
  countryCode,
  required,
  serverErrors,
  placeholderLabel = false,
  onChange,
  placeholder,
}: StateProvinceSelectProps) => {
  const { t } = useTranslation()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, (_) => null)
  const provinces: Province[] = useSelector(getProvinces)
  const states: Province[] = useSelector(getStates)
  const options: Province[] = countryCode === 'US' ? states : provinces
  const label = countryCode === 'US' ? t('contact.state') : t('contact.province')
  const handleOnChange = (event) => {
    handleFieldChange(event)
    onChange && onChange(event)
  }
  return (
    <AvField
      name={name}
      label={placeholderLabel ? undefined : label}
      placeholder={placeholderLabel ? label : placeholder}
      type="select"
      required={required}
      validate={{
        required: {
          value: required,
          errorMessage: t('required_field'),
        },
        async: validate,
      }}
      onChange={handleOnChange}
    >
      {placeholderLabel ? (
        <option value="" disabled selected hidden>
          {label}
        </option>
      ) : (
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
      )}
      {options?.map((option, i) => (
        <option key={i} value={option.code}>
          {option.name}
        </option>
      ))}
    </AvField>
  )
}

export const PrependStateProvinceSelect = ({ name, countryCode, required, serverErrors }: StateProvinceSelectProps) => {
  const { t } = useTranslation()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, (_) => null)
  const provinces: Province[] = useSelector(getProvinces)
  const states: Province[] = useSelector(getStates)
  const options: Province[] = countryCode === 'US' ? states : provinces
  const label = countryCode === 'US' ? t('contact.state') : t('contact.province')
  return (
    <PrependAvField
      name={name}
      label={<i className="mdi mdi-map-outline" />}
      placeholder={label}
      type="select"
      required={required}
      validate={{
        required: {
          value: true,
          errorMessage: t('required_field'),
        },
        async: validate,
      }}
      onChange={handleFieldChange}
    >
      <option value="" disabled selected hidden>
        {label}
      </option>
      {options?.map((option, i) => (
        <option key={i} value={option.code}>
          {option.name}
        </option>
      ))}
    </PrependAvField>
  )
}
