import React, { useEffect } from 'react'
import { NextButton, OnboardingPageProps, ExtendedClientOnboardingType } from './onboardingManager'
import { AvForm } from 'availity-reactstrap-validation'
import { updateEntities } from 'redux-query'
import { useDispatch } from 'react-redux'
import { Client } from 'api'
import { Button, Col, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { CustomRadioGroup, CustomRadioInput } from 'components/CustomRadio'

export const ThirdPartyComponent = ({
  onNext,
  clientOnboarding,
  potentialThirdPartyClients,
  processingClientOnboarding,
  formRef,
}: OnboardingPageProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleNext = (values) => {
    onNext({
      clientValues: { thirdPartyId: values.thirdPartyId },
    })?.then((response) => {
      if (response?.body?.status === 200) {
        dispatch(
          updateEntities({
            clientOnboarding: (prev: ExtendedClientOnboardingType) => ({
              ...prev,
              stashedClientDetails: {
                ...prev?.stashedClientDetails,
                thirdPartyId: values.thirdPartyId,
              },
            }),
          }),
        )
      }
    })
  }

  const handleAddThirdPartyCompanyNext = () => {
    onNext({
      processingClientOnboardingValues: {
        clientData: {
          ...processingClientOnboarding?.clientData,
          countryCode: clientOnboarding.stashedClientDetails?.countryCode,
          referredBy: clientOnboarding.stashedClientDetails?.referredBy,
          promoCode: clientOnboarding.stashedClientDetails?.promoCode,
        },
      },
    })?.then((response) => {
      if (response?.body?.status === 200) {
        dispatch(
          updateEntities({
            clientOnboarding: (prev: ExtendedClientOnboardingType) => ({
              ...prev,
              state: 'business_country',
              stashedClientDetails: {
                clientType: 'Third Party',
              },
            }),
            activeClient: (prev: Client) => ({
              ...prev,
              clientType: 'Third Party',
            }),
          }),
        )
      }
    })
  }

  useEffect(() => {
    if (potentialThirdPartyClients?.length === 0) handleAddThirdPartyCompanyNext()
  }, [potentialThirdPartyClients])

  return (
    <>
      <AvForm onValidSubmit={(_event, values) => handleNext(values)} ref={formRef}>
        <>
          <h3>Select an account</h3>
          <CustomRadioGroup
            name="thirdPartyId"
            label={"Select from the list of your existing accounts which you'd like to use to manage this new company."}
            className="mb-4"
            required
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
            }}
          >
            {potentialThirdPartyClients?.map((client, index) => (
              <CustomRadioInput
                key={index}
                name={'thirdPartyClient' + index}
                label={client.operatingAs}
                value={client.id!}
              />
            ))}
          </CustomRadioGroup>

          <Row className="mt-1">
            <Col>
              <span className="subtext">
                Are you managing this new account from a different account than listed above?
              </span>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="text-center">
              <Button
                name="doAddThirdParty"
                className="btn btn-onboarding-add-item"
                onClick={() => handleAddThirdPartyCompanyNext()}
              >
                <span>
                  <i className="mdi mdi-plus mr-1 " />
                  Add a new account
                </span>
              </Button>
            </Col>
          </Row>
        </>
        <NextButton />
      </AvForm>
    </>
  )
}

export const TransitionFromThirdPartyComponent = ({
  processingClientOnboarding,
  setCurrentPage,
  setSteps,
  formRef,
}: OnboardingPageProps) => {
  const dispatch = useDispatch()

  const handleNext = (_) => {
    let state = processingClientOnboarding?.clientData?.countryCode ? 'business_type' : 'business_country'
    dispatch(
      updateEntities({
        clientOnboarding: () => ({
          ...processingClientOnboarding,
          state: state,
          stashedClientDetails: processingClientOnboarding?.clientData,
        }),
        activeClient: () => processingClientOnboarding?.clientData,
      }),
    )
  }

  useEffect(() => {
    setCurrentPage((prev) => ({ ...prev!, rightPane: <></> }))
    setSteps((prev) =>
      prev.map((step) => {
        let newStep = { ...step }
        if (step.name === 'owners') {
          newStep.subSteps = []
        }
        return newStep
      }),
    )
  }, [])

  return (
    <AvForm onValidSubmit={handleNext} ref={formRef}>
      <div className="page-title-box">
        <h3 className="mb-0 mt-0">Switching gears. Lets hear about the business you're representing</h3>
        <p className="text-muted mt-2">
          Your information has been submitted for your own company, now we will gather information for the business you
          are representing.
        </p>
        <NextButton />
      </div>
    </AvForm>
  )
}
