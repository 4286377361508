import React from 'react'
import { useSelector } from 'react-redux'

import { Button, Row, Col, Card, CardBody, Fade, CardDeck, CardImg } from 'reactstrap'
import { Bar } from 'react-chartjs-2'

import { home } from 'api'
import { getActiveClient, getEntities } from 'app/selectors'
import { useRequest } from 'redux-query-react'

import frenchAuthLogo from 'assets/images/french-authorization-announcement-icon.png'
import { formatCurrency, formatDateGreg, formatDateLong } from 'utilities'
import { Loading } from 'components/Loading'
import { Link, useHistory } from 'react-router-dom'

export const HomePage = () => {
  const history = useHistory()
  const account = useSelector((state) => getEntities(state).account) || {}
  const activeClient = useSelector(getActiveClient)
  const homeData = useSelector((state) => getEntities(state).homeData) || {}
  const [homeRequestState, refreshHome] = useRequest(
    activeClient?.id &&
      home(
        {
          clientId: activeClient.id,
        },
        {
          force: true,
          transform: (body) => {
            return {
              homeData: body,
            }
          },
          update: {
            homeData: (oldValue, newValue) => newValue,
          },
        },
      ),
  )

  function dataToChart(item) {
    return {
      x: item.processDate,
      y: item.amount,
    }
  }

  const formatLabel = (context) => {
    return transactionsBarChartData.datasets[context[0]?.datasetIndex]?.label || ''
  }

  const datasetDefaults = {
    // barPercentage: 0.7,
    // categoryPercentage: 0.5,
  }

  const transactionsBarChartData = {
    datasets: [
      {
        label: 'Approved',
        data: homeData?.approvedTransactions?.map(dataToChart) || [],
        backgroundColor: 'rgba(55, 179, 74, 0.4)',
      },
      {
        label: 'Declined',
        data: homeData?.declinedTransactions?.map(dataToChart) || [],
        backgroundColor: 'rgba(250, 92, 124, 0.4)',
      },
      {
        label: 'Sent to Bank',
        data: homeData?.sentToBankTransactions?.map(dataToChart) || [],
        backgroundColor: 'rgba(0, 184, 177, 0.4)',
      },
      {
        label: 'Processed',
        data: homeData?.processedTransactions?.map(dataToChart) || [],
        backgroundColor: 'rgba(3, 61, 136, 0.3)',
      },
      {
        label: 'Future',
        data: homeData?.futureTransactions?.map(dataToChart) || [],
        backgroundColor: 'rgba(250, 175, 64, 0.4)',
      },
    ],
  }

  const settlementsBarChartData = {
    datasets: [
      {
        label: 'Pending',
        data: homeData?.settlementTransactions?.map(dataToChart) || [],
        backgroundColor: 'rgba(57, 175, 209, 0.4)',
      },
    ],
  }

  const transactionsBarChartOpts = {
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
    },
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#777',
      bodyFontColor: '#777',
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: (context) => formatLabel(context),
        label: (context) => formatDateGreg(context.label),
        afterLabel: (context) => formatCurrency(context.value),
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: (value) => formatCurrency(value),
            maxTicksLimit: 4,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          type: 'time',
          ticks: {
            min: homeData.startDate,
            max: homeData.endDate,
          },
          time: {
            unit: 'day',
          },
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
    },
    hover: {
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
      },
    },
  }

  const settlementsBarChartOpts = {
    maintainAspectRatio: false,
    legend: false,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#777',
      bodyFontColor: '#777',
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: (context) => formatDateGreg(context?.[0]?.label),
        label: (context) => formatCurrency(context.value),
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            callback: (value) => formatCurrency(value),
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day',
          },
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  }

  if (!account?.email || homeRequestState.isPending) return <Loading />

  return (
    <Fade>
      <div className="page-title-box">
        <h4 className="page-title">Welcome back, {account.firstName}</h4>
        <p>
          Here is an overview of {homeData?.startDate && formatDateLong(homeData.startDate)} to{' '}
          {homeData?.endDate && formatDateLong(homeData.endDate)} for {activeClient?.businessName}
        </p>
      </div>
      <Row className="mb-3">
        <Col>
          <CardDeck>
            <Card>
              <Link to="/client/customers">
                <CardBody className="text-center">
                  <h3 className="home-page-stats">{homeData?.customersCount}</h3>
                  <p>Number of Customers</p>
                </CardBody>
              </Link>
            </Card>

            <Card>
              <Link
                to={
                  '/client/transaction_report?start_date=' +
                  homeData?.startDate?.toISOString() +
                  '&end_date=' +
                  homeData?.endDate?.toISOString()
                }
              >
                <CardBody className="text-center">
                  <h3 className="home-page-stats">{homeData?.transactionsCount}</h3>
                  <p>Number of Transactions</p>
                </CardBody>
              </Link>
            </Card>

            <Card>
              <Link
                to={
                  '/client/transaction_report?start_date=' +
                  homeData?.startDate?.toISOString() +
                  '&end_date=' +
                  homeData?.endDate?.toISOString()
                }
              >
                <CardBody className="text-center">
                  <h3 className="home-page-stats">{formatCurrency(homeData?.transactionsSum)}</h3>
                  <p>Total Amount</p>
                </CardBody>
              </Link>
            </Card>

            <Card>
              <Link
                to={
                  '/client/transaction_report?filter=Declined&start_date=' +
                  homeData?.startDate?.toISOString() +
                  '&end_date=' +
                  homeData?.endDate?.toISOString()
                }
              >
                <CardBody className="text-center">
                  <h3 className="home-page-stats">{formatCurrency(homeData?.declinesSum)}</h3>
                  <p>Declined Amount</p>
                </CardBody>
              </Link>
            </Card>
          </CardDeck>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h4 className="header-title mb-3">Transactions</h4>
              <div style={{ height: '300px' }} className="chartjs-chart">
                {homeData?.startDate && (
                  <Bar
                    data={transactionsBarChartData}
                    options={transactionsBarChartOpts}
                    onElementsClick={(elems) => {
                      var date = transactionsBarChartData?.datasets[0]?.data[elems[0]?._index]?.x
                      if (date) {
                        date = new Date(date)
                        history.push(
                          `/client/transaction_report?start_date=${date?.toISOString()}&end_date=${date?.toISOString()}`,
                        )
                      }
                    }}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <h4 className="header-title mb-3">Upcoming Settlements</h4>
              <div style={{ height: '113px' }} className="chartjs-chart">
                {homeData?.startDate && <Bar data={settlementsBarChartData} options={settlementsBarChartOpts} />}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardBody className="text-center">
              <CardImg
                src={frenchAuthLogo}
                height={100}
                style={{ objectFit: 'contain', objectPosition: 'center top' }}
              />
              <h4>Authorizations Now Available in French</h4>
              <Button
                color="primary"
                className="btn-rotessa-primary"
                onClick={() => history.push('/client/authorizations/settings')}
              >
                Authorization Settings
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fade>
  )
}
