import { Client, getClientFileUrl, uploadClientFile } from 'api'
import { CLIENT_MAX_FILE_SIZE } from 'app/constants'
import { getEntities } from 'app/selectors'
import instantVerificationIcon from 'assets/images/Rotessa-instant-bank-verification.png'
import { AvForm } from 'availity-reactstrap-validation'
import { BankAccountFields } from 'components/BankAccountFields'
import { CustomRadioGroup, CustomRadioInput } from 'components/CustomRadio'
import { OpenFileLink } from 'components/ExportButton'
import { ExternalLink } from 'components/ExternalLink'
import { FormServerErrors } from 'components/FormServerErrors'
import { MinimalFileUploader, getFileErrorMessages } from 'components/MinimalFileUploader'
import { FlinksConnect } from 'features/flinks/FlinksConnect'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { Errors } from 'hooks/useFormServerErrors'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap'
import { updateEntities } from 'redux-query'
import { useMutation } from 'redux-query-react'
import { NextButton, OnboardingPageProps } from './onboardingManager'

export const SettlementAccountRightPanel = () => {
  const dispatch = useDispatch()
  const doSettlementFlinks: boolean = useSelector((state) => getEntities(state).doSettlementFlinks)
  const canDoSettlementFlinks: boolean = useSelector((state) => getEntities(state).canDoSettlementFlinks)
  return (
    <>
      <h3>Why we ask</h3>
      {doSettlementFlinks ? (
        <>
          <p>
            Using Flinks connects you directly to your online banking so that we can verify that you have access to the
            bank information you provide. It's also an easy way to provide your bank information without hunting for a
            void cheque, or worrying about manual entry errors.
          </p>
          <Link
            to={window.location}
            onClick={() => {
              dispatch(
                updateEntities({
                  doSettlementFlinks: () => false,
                  settlementFlinksAccepted: () => false,
                }),
              )
            }}
          >
            <u>My bank is not listed</u>
          </Link>
        </>
      ) : (
        <>
          <p>
            Uploading a void cheque and a recent bank statement might seem redundant but it helps us verify that you
            have the permission required to provide the bank information for this account.
          </p>
          {canDoSettlementFlinks && (
            <Link
              to={window.location}
              onClick={() => {
                dispatch(
                  updateEntities({
                    doSettlementFlinks: () => true,
                    settlementFlinksAccepted: () => false,
                  }),
                )
              }}
            >
              <u>Find my bank in Flinks</u>
            </Link>
          )}
        </>
      )}
      <Alert color="info" className="mt-3">
        <i className="mdi mdi-information-outline mr-2" />
        There will be no charges on your account until after you start processing transactions.
      </Alert>
    </>
  )
}

export const SettlementAccountComponent = ({
  onNext,
  serverErrors,
  activeClient,
  doStateRefresh,
  setSteps,
  clientOnboarding,
  formRef,
}: OnboardingPageProps) => {
  const dispatch = useDispatch()
  const [selectedChequeFile, setSelectedChequeFile] = useState<File>()
  const [selectedBankStatementFile, setSelectedBankStatementFile] = useState<File>()
  const [uploadErrors, setUploadErrors] = useState({} as Errors)
  const doSettlementFlinks: boolean = useSelector((state) => getEntities(state).doSettlementFlinks)
  const canDoSettlementFlinks: boolean = useSelector((state) => getEntities(state).canDoSettlementFlinks)
  const settlementFlinksAccepted: boolean = useSelector((state) => getEntities(state).settlementFlinksAccepted)
  useClearEntitiesOnUnmount(['doSettlementFlinks', 'canDoSettlementFlinks', 'settlementFlinksAccepted'])

  useEffect(() => {
    if (!canDoSettlementFlinks) {
      let tempCanDoFlinks = (clientOnboarding.settlementAccount?.['flinksAttempts'] || 0) < 1
      dispatch(
        updateEntities({
          canDoSettlementFlinks: () => tempCanDoFlinks,
        }),
      )
      if (!tempCanDoFlinks) {
        setSteps((prev) =>
          prev.map((step) => {
            let newStep = { ...step }
            if (step.name === 'financial_info') {
              newStep.subSteps = [
                {
                  label: 'Settlement account',
                  name: 'settlement_account',
                  status: 'failed',
                },
              ]
              let billingStep = step.subSteps?.find((subStep) => subStep.name === 'billing_account')
              if ((billingStep?.status === 'pending', billingStep?.status === 'failed')) {
                newStep.subSteps.push(billingStep)
              }
            }
            return newStep
          }),
        )
      }
    }
    if (!doSettlementFlinks) {
      dispatch(
        updateEntities({
          doSettlementFlinks: () =>
            !activeClient?.settlementBankAccount &&
            !activeClient?.settlementRecentBankStatement &&
            !activeClient?.settlementVoidChequeOrAccountAuth &&
            (clientOnboarding.settlementAccount?.['flinksAttempts'] || 0) < 1,
          settlementFlinksAccepted: () => false,
        }),
      )
    }
  }, [activeClient?.id])

  const [altFlowQuestion, setAltFlowQuestion] = useState("Can't Connect?")
  const windowSize = useWindowSize()
  const { t } = useTranslation()

  const [fileMutationState, doFileMutation] = useMutation((id: number, file: File, title: string) =>
    uploadClientFile({
      id: id,
      file: file,
      title: title,
    }),
  )

  const uploadFile = (client_id: number, file: File, title: string, event: any, clientValues: Client) => {
    doFileMutation(client_id, file, title)?.then((response) => {
      if (response.body?.status === 200) {
        onNext({ e: event, clientValues: clientValues })
      } else {
        setUploadErrors((prev) => ({ ...prev, [title]: response.body?.messages?.file || response.body?.errors || ['Unable to upload file.'] }))
      }
    })
  }

  const handleChangeFile = (file: File, accepted: boolean, setAcceptedFile: Function, title: string) => {
    if (accepted) {
      setAcceptedFile(file)
    } else {
      setUploadErrors((prev) => ({ ...prev, [title]: [getFileErrorMessages(file, CLIENT_MAX_FILE_SIZE)] || [] }))
    }
  }

  const handleSubmit = (event, values: Client) => {
    if (activeClient?.id) {
      if (
        (!selectedChequeFile && !activeClient.settlementVoidChequeOrAccountAuth) ||
        (!selectedBankStatementFile && !activeClient.settlementRecentBankStatement)
      ) {
        let fileErrors: Errors = {}
        if (!selectedChequeFile && !activeClient.settlementVoidChequeOrAccountAuth) {
          fileErrors['Settlement Void Cheque or Account Auth'] = ['Void cheque or account authorization is required.']
        }
        if (!selectedBankStatementFile && !activeClient.settlementRecentBankStatement) {
          fileErrors['Settlement Recent Bank Statement'] = ['Recent bank statement is required. ']
        }
        if (Object.keys(fileErrors).length > 0) {
          setUploadErrors(fileErrors)
        }
      } else if (selectedChequeFile && selectedBankStatementFile) {
        doFileMutation(activeClient.id, selectedChequeFile, 'Settlement Void Cheque or Account Auth')?.then(
          (response) => {
            if (response.body?.status === 200) {
              uploadFile(response.body.id, selectedBankStatementFile, 'Settlement Recent Bank Statement', event, values)
            } else {
              setUploadErrors((prev) => ({
                ...prev,
                ['Settlement Void Cheque or Account Auth']:
                  response.body?.messages?.file || response.body?.errors || ['Unable to upload file.'],
              }))
            }
          },
        )
      } else if (selectedChequeFile) {
        uploadFile(activeClient.id, selectedChequeFile, 'Settlement Void Cheque or Account Auth', event, values)
      } else if (selectedBankStatementFile) {
        uploadFile(activeClient.id, selectedBankStatementFile, 'Settlement Recent Bank Statement', event, values)
      } else {
        onNext({ e: event, clientValues: values })
      }
    }
  }

  const reallyPending = fileMutationState.isPending
  let globalFileErrors = { ...uploadErrors }
  delete globalFileErrors['Settlement Void Cheque or Account Auth']
  delete globalFileErrors['Settlement Recent Bank Statement']
  const settlementDescription =
    'The Settlement account is the information Rotessa will use to deposit funds collected from customers. '

  if (doSettlementFlinks) {
    if (!settlementFlinksAccepted) {
      return (
        <>
          <h3>Settlement account</h3>
          <p className="subtext">{settlementDescription}</p>
          <Card>
            <CardBody>
              <div className="text-center">
                <img src={instantVerificationIcon} height={100} className="mt-3 mb-2" alt="" />
                <p style={{ fontSize: 24 }}>
                  <Trans i18nKey="flinks.header">
                    We use <b>Flinks</b> to instantly verify your bank information
                  </Trans>
                </p>
              </div>
              <Row className="ml-2 mr-2 mb-2">
                <Col xs="auto" className="p-0">
                  <i className="mdi mdi-lock mdi-24px" />
                </Col>
                <Col>
                  <span>
                    <b>{t('flinks.secure')}</b>
                    <br />
                    {t('flinks.secure_text')}
                  </span>
                </Col>
              </Row>
              <Row className="ml-2 mr-2 mb-2">
                <Col xs="auto" className="p-0">
                  <i className="mdi mdi-eye-off mdi-24px" />
                </Col>
                <Col>
                  <span>
                    <b>{t('flinks.private')}</b>
                    <br />
                    {t('flinks.private_text')}
                  </span>
                </Col>
              </Row>
              <div className="text-center">
                <p style={{ lineHeight: '100%', color: '#bbb' }}>
                  <small>
                    {t('flinks.selecting')}
                    <br />
                    <ExternalLink to={t('flinks.agree_link')}>{t('flinks.agree_link_text')}</ExternalLink>
                  </small>
                  <br />
                </p>
                <Button
                  className="mb-2"
                  color="primary"
                  onClick={() =>
                    dispatch(
                      updateEntities({
                        settlementFlinksAccepted: () => true,
                      }),
                    )
                  }
                >
                  {t('continue')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </>
      )
    }
    return (
      <>
        <h3>Settlement account</h3>
        <p className="subtext">{settlementDescription}</p>
        <FlinksConnect
          countryCode={activeClient?.countryCode || 'CA'}
          tag={String(activeClient?.id)}
          height={Math.max(450, windowSize.height - 700)}
          onStep={(step) => step === 'INSTITUTION_SELECTED' && setAltFlowQuestion("Don't see your bank?")}
          onComplete={doStateRefresh}
          type="Onboarding"
          accountType="settlement_bank_account"
        />
      </>
    )
  } else {
    return (
      <AvForm model={activeClient} onValidSubmit={(e, v) => handleSubmit(e, v)} ref={formRef}>
        <h3>Settlement account</h3>
        <p className="subtext">{settlementDescription}</p>
        <BankAccountFields
          countryCode={activeClient?.countryCode}
          fieldNamePrefix="settlementBankAccount"
          serverErrors={serverErrors}
          required={true}
          threeRows
        />
        <Row className="mt-3 mb-2">
          <Col>
            <MinimalFileUploader
              onFileUpload={(file, accepted) => {
                handleChangeFile(file, accepted, setSelectedChequeFile, 'Settlement Void Cheque or Account Auth')
              }}
              accept=".jpeg, .jpg, .pdf, .png"
              showPreview={true}
              maxFiles={1}
              disabled={reallyPending}
              maxSize={CLIENT_MAX_FILE_SIZE}
              uploadText="Upload a Void Cheque"
            />
            <Row>
              {uploadErrors['Settlement Void Cheque or Account Auth'] && (
                <Col className="invalid-feedback d-block">
                  {uploadErrors['Settlement Void Cheque or Account Auth'][0]}
                </Col>
              )}
              {activeClient?.settlementVoidChequeOrAccountAuth?.url && activeClient.id && (
                <Col>
                  <div className="text-right">
                    <OpenFileLink
                      exportQueryConfig={getClientFileUrl(
                        {
                          id: activeClient.id,
                          filter: 'Settlement Void Cheque or Account Auth',
                        },
                        {
                          force: true,
                        },
                      )}
                      setErrorsHandler={(errors) =>
                        setUploadErrors((prev) => ({ ...prev, 'Settlement Void Cheque or Account Auth': errors }))
                      }
                    >
                      Current uploaded void cheque
                    </OpenFileLink>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <MinimalFileUploader
              onFileUpload={(file, accepted) => {
                handleChangeFile(file, accepted, setSelectedBankStatementFile, 'Settlement Recent Bank Statement')
              }}
              accept=".jpeg, .jpg, .pdf, .png"
              showPreview={true}
              maxFiles={1}
              disabled={reallyPending}
              maxSize={CLIENT_MAX_FILE_SIZE}
              uploadText="Upload a Recent Bank Statement"
            />
            <Row>
              {uploadErrors['Settlement Recent Bank Statement'] && (
                <Col className="invalid-feedback d-block">{uploadErrors['Settlement Recent Bank Statement'][0]}</Col>
              )}
              {activeClient?.settlementRecentBankStatement?.url && activeClient.id && (
                <Col>
                  <div className="text-right">
                    <OpenFileLink
                      exportQueryConfig={getClientFileUrl(
                        {
                          id: activeClient.id,
                          filter: 'Settlement Recent Bank Statement',
                        },
                        {
                          force: true,
                        },
                      )}
                      setErrorsHandler={(errors) =>
                        setUploadErrors((prev) => ({ ...prev, 'Settlement Recent Bank Statement': errors }))
                      }
                    >
                      Current uploaded bank statement
                    </OpenFileLink>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {Object.values(globalFileErrors).length > 0 && (
          <Row>
            <Col>
              <FormServerErrors errors={Object.values(globalFileErrors).flat()} />
            </Col>
          </Row>
        )}
        <NextButton />
      </AvForm>
    )
  }
}

export const BillingAccountRightPanel = () => {
  const dispatch = useDispatch()
  const doBillingFlinks: boolean = useSelector((state) => getEntities(state).doBillingFlinks)
  const canDoBillingFlinks: boolean = useSelector((state) => getEntities(state).canDoBillingFlinks)
  const sameAsSettlementAccount: boolean = useSelector((state) => getEntities(state).sameAsSettlementAccount)

  if (sameAsSettlementAccount === undefined) {
    return (
      <>
        <h3>Why we ask</h3>
        <p>
          Rotessa provides the option to separate the settlement of funds, from the billing of your fees. Rotessa fees
          are charged on the due date of the invoice we send at the end of each processing month.
        </p>
      </>
    )
  } else {
    return (
      <>
        <h3>Why we ask</h3>
        {doBillingFlinks ? (
          <>
            <p>
              Using Flinks connects you directly to your online banking so that we can verify that you have access to
              the bank information you provide. It's also an easy way to provide your bank information without hunting
              for a void cheque, or worrying about manual entry errors.
            </p>
            <Link
              to={window.location}
              onClick={() => {
                dispatch(
                  updateEntities({
                    doBillingFlinks: () => false,
                    billingFlinksAccepted: () => false,
                  }),
                )
              }}
            >
              <u>My bank is not listed</u>
            </Link>
          </>
        ) : (
          <>
            <p>
              Uploading a void cheque and a recent bank statement might seem redundant but it helps us verify that you
              have the permission required to provide the bank information for this account.
            </p>
            {canDoBillingFlinks && (
              <Link
                to={window.location}
                onClick={() => {
                  dispatch(
                    updateEntities({
                      doBillingFlinks: () => true,
                      billingFlinksAccepted: () => false,
                    }),
                  )
                }}
              >
                <u>Find my bank in Flinks</u>
              </Link>
            )}
          </>
        )}
        <Alert color="info" className="mt-3">
          <i className="mdi mdi-information-outline mr-2" />
          There will be no charges on your account until after you start processing transactions.
        </Alert>
      </>
    )
  }
}

export const BillingAccountComponent = ({
  onNext,
  serverErrors,
  activeClient,
  doStateRefresh,
  setSteps,
  clientOnboarding,
  formRef,
}: OnboardingPageProps) => {
  const dispatch = useDispatch()
  const [selectedChequeFile, setSelectedChequeFile] = useState<File>()
  const [selectedBankStatementFile, setSelectedBankStatementFile] = useState<File>()
  const [uploadErrors, setUploadErrors] = useState({} as Errors)
  const sameAsSettlementAccount: boolean | undefined = useSelector(
    (state) => getEntities(state).sameAsSettlementAccount,
  )
  const doBillingFlinks: boolean = useSelector((state) => getEntities(state).doBillingFlinks)
  const canDoBillingFlinks: boolean = useSelector((state) => getEntities(state).canDoBillingFlinks)
  const billingFlinksAccepted: boolean = useSelector((state) => getEntities(state).billingFlinksAccepted)
  useClearEntitiesOnUnmount(['doBillingFlinks', 'canDoBillingFlinks', 'billingFlinksAccepted'])

  useEffect(() => {
    if (!canDoBillingFlinks) {
      let tempCanDoFlinks = (clientOnboarding.billingAccount?.['flinksAttempts'] || 0) < 1
      dispatch(
        updateEntities({
          canDoBillingFlinks: () => tempCanDoFlinks,
        }),
      )
      if (!tempCanDoFlinks) {
        setSteps((prev) =>
          prev.map((step) => {
            let newStep = { ...step }
            if (step.name === 'financial_info') {
              newStep.subSteps = []
              let settlementStep = step.subSteps?.find((subStep) => subStep.name === 'settlement_account')
              if ((settlementStep?.status === 'pending', settlementStep?.status === 'failed')) {
                newStep.subSteps?.push(settlementStep)
              }
              newStep.subSteps.push({
                label: 'billing account',
                name: 'billing_account',
                status: 'failed',
              })
            }
            return newStep
          }),
        )
      }
    }
    if (!doBillingFlinks) {
      dispatch(
        updateEntities({
          doBillingFlinks: () =>
            !activeClient?.billingBankAccount &&
            !activeClient?.billingRecentBankStatement &&
            !activeClient?.billingVoidChequeOrAccountAuth &&
            (clientOnboarding.billingAccount?.['flinksAttempts'] || 0) < 1,
          billingFlinksAccepted: () => false,
        }),
      )
    }
  }, [activeClient?.id])

  const [altFlowQuestion, setAltFlowQuestion] = useState("Can't Connect?")
  const windowSize = useWindowSize()
  const { t } = useTranslation()

  const [fileMutationState, doFileMutation] = useMutation((id: number, file: File, title: string) =>
    uploadClientFile({
      id: id,
      file: file,
      title: title,
    }),
  )

  const uploadFile = (client_id: number, file: File, title: string, event: any, client_values: Client) => {
    doFileMutation(client_id, file, title)?.then((response) => {
      if (response.body?.status === 200) {
        onNext({ e: event, clientValues: client_values })
      } else {
        setUploadErrors((prev) => ({ ...prev, [title]: response.body?.messages?.file || response.body?.errors || ['Unable to upload file.'] }))
      }
    })
  }

  const handleChangeFile = (file: File, accepted: boolean, setAcceptedFile: Function, title: string) => {
    if (accepted) {
      setAcceptedFile(file)
    } else {
      setUploadErrors((prev) => ({ ...prev, [title]: [getFileErrorMessages(file, CLIENT_MAX_FILE_SIZE)] || [] }))
    }
  }

  const handleSubmit = (event, values: Client) => {
    if (activeClient?.id) {
      if (
        (!selectedChequeFile && !activeClient.billingVoidChequeOrAccountAuth) ||
        (!selectedBankStatementFile && !activeClient.billingRecentBankStatement)
      ) {
        let fileErrors: Errors = {}
        if (!selectedChequeFile && !activeClient.billingVoidChequeOrAccountAuth) {
          fileErrors['Billing Void Cheque or Account Auth'] = ['Void cheque or account authorization is required.']
        }
        if (!selectedBankStatementFile && !activeClient.billingRecentBankStatement) {
          fileErrors['Billing Recent Bank Statement'] = ['Recent bank statement is required. ']
        }
        if (Object.keys(fileErrors).length > 0) {
          setUploadErrors(fileErrors)
        }
      } else if (selectedChequeFile && selectedBankStatementFile) {
        doFileMutation(activeClient.id, selectedChequeFile, 'Billing Void Cheque or Account Auth')?.then((response) => {
          if (response.body?.status === 200) {
            uploadFile(response.body.id, selectedBankStatementFile, 'Billing Recent Bank Statement', event, values)
          } else {
            setUploadErrors((prev) => ({
              ...prev,
              ['Billing Void Cheque or Account Auth']: response.body?.messages?.file || response.body?.errors || ['Unable to upload file.'],
            }))
          }
        })
      } else if (selectedChequeFile) {
        uploadFile(activeClient.id, selectedChequeFile, 'Billing Void Cheque or Account Auth', event, values)
      } else if (selectedBankStatementFile) {
        uploadFile(activeClient.id, selectedBankStatementFile, 'Billing Recent Bank Statement', event, values)
      } else {
        onNext({ e: event, clientValues: values })
      }
    }
  }

  const handleUseSettlementAccountQuestion = (event, values) => {
    if (values['useSettlementAccount'] == 'true') {
      onNext({
        e: event,
        clientValues: {},
      })
    } else {
      dispatch(
        updateEntities({
          sameAsSettlementAccount: () => false,
        }),
      )
    }
  }

  const reallyPending = fileMutationState.isPending
  let globalFileErrors = { ...uploadErrors }
  delete globalFileErrors['Billing Void Cheque or Account Auth']
  delete globalFileErrors['Billing Recent Bank Statement']

  if (sameAsSettlementAccount === undefined && !activeClient?.billingBankAccount) {
    return (
      <AvForm
        name="operatingAddress"
        onValidSubmit={(event, values) => handleUseSettlementAccountQuestion(event, values)}
        ref={formRef}
      >
        <h3>Billing account</h3>
        <CustomRadioGroup
          name="useSettlementAccount"
          label="Please indicate if you would like to separate your billing information from your settlement information."
          required
        >
          <CustomRadioInput label="Yes, use the settlement account" value="true" name="useSettlementAccount" />
          <CustomRadioInput
            label="No, setup a different account for billing"
            value="false"
            name="useSettlementAccount"
          />
        </CustomRadioGroup>
        <NextButton />
      </AvForm>
    )
  } else if (doBillingFlinks) {
    if (!billingFlinksAccepted) {
      return (
        <>
          <h3>Billing account</h3>
          <Card>
            <CardBody>
              <div className="text-center">
                <img src={instantVerificationIcon} height={100} className="mt-3 mb-2" alt="" />
                <p style={{ fontSize: 24 }}>
                  <Trans i18nKey="flinks.header">
                    We use <b>Flinks</b> to instantly verify your bank information
                  </Trans>
                </p>
              </div>
              <Row className="ml-2 mr-2 mb-2">
                <Col xs="auto" className="p-0">
                  <i className="mdi mdi-lock mdi-24px" />
                </Col>
                <Col>
                  <span>
                    <b>{t('flinks.secure')}</b>
                    <br />
                    {t('flinks.secure_text')}
                  </span>
                </Col>
              </Row>
              <Row className="ml-2 mr-2 mb-2">
                <Col xs="auto" className="p-0">
                  <i className="mdi mdi-eye-off mdi-24px" />
                </Col>
                <Col>
                  <span>
                    <b>{t('flinks.private')}</b>
                    <br />
                    {t('flinks.private_text')}
                  </span>
                </Col>
              </Row>
              <div className="text-center">
                <p style={{ lineHeight: '100%', color: '#bbb' }}>
                  <small>
                    {t('flinks.selecting')}
                    <br />
                    <ExternalLink to={t('flinks.agree_link')}>{t('flinks.agree_link_text')}</ExternalLink>
                  </small>
                  <br />
                </p>
                <Button
                  className="mb-2"
                  color="primary"
                  onClick={() =>
                    dispatch(
                      updateEntities({
                        billingFlinksAccepted: () => true,
                      }),
                    )
                  }
                >
                  {t('continue')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </>
      )
    }
    return (
      <>
        <h3>Billing account</h3>
        <FlinksConnect
          countryCode={activeClient?.countryCode || 'CA'}
          tag={String(activeClient?.id)}
          height={Math.max(450, windowSize.height - 700)}
          onStep={(step) => step === 'INSTITUTION_SELECTED' && setAltFlowQuestion("Don't see your bank?")}
          onComplete={doStateRefresh}
          type="Onboarding"
          accountType="billing_bank_account"
        />
      </>
    )
  } else {
    return (
      <AvForm model={activeClient} onValidSubmit={(e, v) => handleSubmit(e, v)} ref={formRef}>
        <h3>Billing account</h3>
        <BankAccountFields
          countryCode={activeClient?.countryCode}
          fieldNamePrefix="billingBankAccount"
          serverErrors={serverErrors}
          required={true}
          threeRows
        />
        <Row className="mt-3 mb-2">
          <Col>
            <MinimalFileUploader
              onFileUpload={(file, accepted) => {
                handleChangeFile(file, accepted, setSelectedChequeFile, 'Billing Void Cheque or Account Auth')
              }}
              accept=".jpeg, .jpg, .pdf, .png"
              showPreview={true}
              maxFiles={1}
              disabled={reallyPending}
              maxSize={CLIENT_MAX_FILE_SIZE}
              uploadText="Upload a Void Cheque"
            />
            <Row>
              {uploadErrors['Billing Void Cheque or Account Auth'] && (
                <Col className="invalid-feedback d-block">{uploadErrors['Billing Void Cheque or Account Auth'][0]}</Col>
              )}
              {activeClient?.billingVoidChequeOrAccountAuth?.url && activeClient.id && (
                <Col>
                  <div className="text-right">
                    <OpenFileLink
                      exportQueryConfig={getClientFileUrl(
                        {
                          id: activeClient.id,
                          filter: 'Billing Void Cheque or Account Auth',
                        },
                        {
                          force: true,
                        },
                      )}
                      setErrorsHandler={(errors) =>
                        setUploadErrors((prev) => ({ ...prev, 'Billing Void Cheque or Account Auth': errors }))
                      }
                    >
                      Current uploaded void cheque
                    </OpenFileLink>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <MinimalFileUploader
              onFileUpload={(file, accepted) => {
                handleChangeFile(file, accepted, setSelectedBankStatementFile, 'Billing Recent Bank Statement')
              }}
              accept=".jpeg, .jpg, .pdf, .png"
              showPreview={true}
              maxFiles={1}
              disabled={reallyPending}
              maxSize={CLIENT_MAX_FILE_SIZE}
              uploadText="Upload a Recent Bank Statement"
            />
            <Row>
              {uploadErrors['Billing Recent Bank Statement'] && (
                <Col className="invalid-feedback d-block">{uploadErrors['Billing Recent Bank Statement'][0]}</Col>
              )}
              {activeClient?.billingRecentBankStatement?.url && activeClient.id && (
                <Col>
                  <div className="text-right">
                    <OpenFileLink
                      exportQueryConfig={getClientFileUrl(
                        {
                          id: activeClient.id,
                          filter: 'Billing Recent Bank Statement',
                        },
                        {
                          force: true,
                        },
                      )}
                      setErrorsHandler={(errors) =>
                        setUploadErrors((prev) => ({ ...prev, 'Billing Recent Bank Statement': errors }))
                      }
                    >
                      Current uploaded bank statement
                    </OpenFileLink>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {Object.values(globalFileErrors).length > 0 && (
          <Row>
            <Col>
              <FormServerErrors errors={Object.values(globalFileErrors).flat()} />
            </Col>
          </Row>
        )}
        <NextButton />
      </AvForm>
    )
  }
}
