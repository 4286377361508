// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntegrationCustomer,
    IntegrationCustomerFromJSON,
    IntegrationCustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetIntegrationCustomers200Response
 */
export interface GetIntegrationCustomers200Response  {
    /**
     * 
     * @type {number}
     * @memberof GetIntegrationCustomers200Response
     */
    customersTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof GetIntegrationCustomers200Response
     */
    customersSearchTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof GetIntegrationCustomers200Response
     */
    customersConnected?: number;
    /**
     * 
     * @type {number}
     * @memberof GetIntegrationCustomers200Response
     */
    customersMatched?: number;
    /**
     * 
     * @type {number}
     * @memberof GetIntegrationCustomers200Response
     */
    customersNotConnected?: number;
    /**
     * 
     * @type {Array<IntegrationCustomer>}
     * @memberof GetIntegrationCustomers200Response
     */
    customers?: Array<IntegrationCustomer>;
}

export function GetIntegrationCustomers200ResponseFromJSON(json: any): GetIntegrationCustomers200Response {
    return {
        'customersTotal': !exists(json, 'customers_total') ? undefined : json['customers_total'],
        'customersSearchTotal': !exists(json, 'customers_search_total') ? undefined : json['customers_search_total'],
        'customersConnected': !exists(json, 'customers_connected') ? undefined : json['customers_connected'],
        'customersMatched': !exists(json, 'customers_matched') ? undefined : json['customers_matched'],
        'customersNotConnected': !exists(json, 'customers_not_connected') ? undefined : json['customers_not_connected'],
        'customers': !exists(json, 'customers') ? undefined : (json['customers'] as Array<any>).map(IntegrationCustomerFromJSON),
    };
}

export function GetIntegrationCustomers200ResponseToJSON(value?: GetIntegrationCustomers200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'customers_total': value.customersTotal,
        'customers_search_total': value.customersSearchTotal,
        'customers_connected': value.customersConnected,
        'customers_matched': value.customersMatched,
        'customers_not_connected': value.customersNotConnected,
        'customers': value.customers === undefined ? undefined : (value.customers as Array<any>).map(IntegrationCustomerToJSON),
    };
}


