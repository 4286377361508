import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'
import { Card, CardBody, CardFooter, Fade } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import {
  ProcessingAuthorization,
  submitProcessingAuthorization,
  GetProcessingAuthorizationDetails200ResponseFromJSON,
} from 'api'
import { getEntities } from 'app/selectors'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { ProcessingAuthIntro } from './processingAuthIntro'
import { ProcessingAuthContactInfo } from './processingAuthContactInfo'
import { ProcessingAuthBankInfo } from './processingAuthBankInfo'
import { Loading } from 'components/Loading'
import { updateEntities } from 'redux-query'
import { ProcessingAuthFinished, ProcessingAuthFinishedFooter } from './processingAuthFinished'
import { FormServerErrors } from 'components/FormServerErrors'
import { LoadingMask } from 'components/LoadingMask'
import { Link } from 'react-router-dom'

export interface AuthFormManagerProps {
  readOnly?: boolean
}

export const ProcessingAuthFormManager = (props: AuthFormManagerProps) => {
  const dispatch = useDispatch()
  const processingAuthInfo = useSelector((state) => getEntities(state).processingAuthInfo) || {}

  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)

  const [t, i18n] = useTranslation()

  const [mutationState, doMutation] = useMutation((values: ProcessingAuthorization) =>
    submitProcessingAuthorization({ id: processingAuthInfo.id, processingAuthorization: values }),
  )

  function handleSubmit(values: ProcessingAuthorization) {
    if (props.readOnly) return
    doMutation({ language: processingAuthInfo.language, ...values })?.then((response) => {
      setServerErrors(response?.body?.messages || {})
      if (response?.body?.status === 200) {
        dispatch(
          updateEntities({
            processingAuthInfo: (_oldValue: any) => {
              let result = { ..._oldValue }
              let newValue = GetProcessingAuthorizationDetails200ResponseFromJSON(response.body)
              Object.keys(newValue).forEach((key) => {
                if (newValue[key] !== undefined) {
                  result[key] = newValue[key]
                }
              })
              return result
            },
          }),
        )
      }
    })
  }

  useEffect(() => {
    i18n.changeLanguage(processingAuthInfo.language)

    return () => {
      if (props.readOnly && i18n.language !== 'en') {
        i18n.changeLanguage('en')
      }
    }
  }, [processingAuthInfo.language])

  const toggleLanguage = (event) => {
    event.preventDefault()
    const newLanguage = processingAuthInfo.language === 'en' ? 'fr' : 'en'
    handleSubmit({ language: newLanguage, submittedStep: processingAuthInfo?.step?.name })

    if (props.readOnly) {
      dispatch(
        updateEntities({
          processingAuthInfo: (oldProcessingAuthInfo: any) => ({
            ...oldProcessingAuthInfo,
            language: newLanguage,
          }),
        }),
      )
    }
  }

  let content
  let footer_content = <></>

  switch (processingAuthInfo?.step?.name) {
    case 'intro':
      content = <ProcessingAuthIntro onSubmit={() => handleSubmit({ submittedStep: 'intro' })} />
      break
    case 'contact':
      content = (
        <ProcessingAuthContactInfo
          handleFieldChange={handleFieldChange}
          validate={validate}
          onSubmit={(values) => handleSubmit({ ...values, submittedStep: 'contact' })}
        />
      )
      break
    case 'bank':
      content = (
        <ProcessingAuthBankInfo
          readOnly={props.readOnly}
          handleFieldChange={handleFieldChange}
          validate={validate}
          onSubmit={(values) => handleSubmit({ ...values, submittedStep: 'bank' })}
          serverErrors={serverErrors}
        />
      )
      break
    case 'finished':
      content = <ProcessingAuthFinished />
      footer_content = <ProcessingAuthFinishedFooter />
      break
  }

  const errors = (processingAuthInfo?.messages && processingAuthInfo?.messages['base']) || []
  if (!content && errors.length === 0) {
    content = <Loading />
  }

  return (
    <div style={{ maxWidth: 400, margin: 'auto' }}>
      <Fade>
        <Card className="loading-container-min-height processing-content">
          <CardBody>
            <div>
              {mutationState.isPending && <LoadingMask />}
              <FormServerErrors errors={errors} />
              <Fade>{content}</Fade>
            </div>
          </CardBody>
          {['finished'].includes(processingAuthInfo?.step?.name) && <CardFooter>{footer_content}</CardFooter>}
        </Card>
        {!mutationState.isPending && (
          <div className="text-center mt-3">
            <Link to={() => ({})} onClick={toggleLanguage}>
              <u>{t('language_toggle')}</u>
            </Link>
          </div>
        )}
        <PoweredByRotessaFooter type="blue_footer" source="authPage" />
      </Fade>
    </div>
  )
}
