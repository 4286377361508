import React from 'react'

import paymentsCanada from 'assets/images/payments-canada-logo.png'
import { ExternalLink } from 'components/ExternalLink'

export const AuthorizationResources = () => {
  return (
    <>
      <h4 className="header-title mb-3">Resources</h4>
      <p>
        If you have any questions or looking for more information on authorization requirements, visit Payments Canada.
      </p>

      <ExternalLink to="https://www.payments.ca/resources/payment-guides/financial-institution-guides/pre-authorized-debits">
        <div className="text-center">
          <img src={paymentsCanada} className="max-width-100" alt="Payments Canada" />
        </div>
      </ExternalLink>
    </>
  )
}
