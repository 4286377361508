// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Microdeposit,
    MicrodepositFromJSON,
    MicrodepositToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchMicrodeposits200Response
 */
export interface SearchMicrodeposits200Response  {
    /**
     * 
     * @type {number}
     * @memberof SearchMicrodeposits200Response
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchMicrodeposits200Response
     */
    status?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchMicrodeposits200Response
     */
    messages?: Array<string>;
    /**
     * 
     * @type {Array<Microdeposit>}
     * @memberof SearchMicrodeposits200Response
     */
    records?: Array<Microdeposit>;
}

export function SearchMicrodeposits200ResponseFromJSON(json: any): SearchMicrodeposits200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(MicrodepositFromJSON),
    };
}

export function SearchMicrodeposits200ResponseToJSON(value?: SearchMicrodeposits200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'status': value.status,
        'messages': value.messages,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(MicrodepositToJSON),
    };
}


