import React from 'react'
import { Col, Row } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { InfoPopover } from 'components/InfoPopover'

export interface TitleBarProps {
  title: string | JSX.Element
  info?: string
  subtitle?: string | JSX.Element
  noBack?: boolean
}

export const TitleBar = (props: TitleBarProps) => {
  const history = useHistory()

  let subtitle: JSX.Element | null = null

  if (props.subtitle && typeof props.subtitle == 'string') {
    subtitle = (
      <Row className="mb-3">
        <Col></Col>
        <Col lg="6" className="pr-4 pl-4">
          <p style={{ textAlign: 'center' }}>{props.subtitle}</p>
        </Col>
        <Col></Col>
      </Row>
    )
  } else if (props.subtitle) {
    subtitle = (
      <Row className="mb-3">
        <Col></Col>
        <Col lg="6" className="pr-4 pl-4">
          {props.subtitle}
        </Col>
        <Col></Col>
      </Row>
    )
  }

  return (
    <>
      <Row className={`mt-3 ${props.subtitle ? 'mb-2' : 'mb-4'}`}>
        <Col>
          {!props.noBack && (
            <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
              <i className="mdi mdi-keyboard-backspace" /> Back
            </span>
          )}
        </Col>

        <Col lg="8" className="pr-4 pl-4 mt-2">
          <div className="page-title-box">
            <h4 style={{ lineHeight: '40px', textAlign: 'center' }} className="page-title text-wrap">
              {props.title}
              {props.info && <InfoPopover popoverText={props.info} />}
            </h4>
          </div>
        </Col>
        <Col></Col>
      </Row>
      {subtitle}
    </>
  )
}
