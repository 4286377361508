import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest, useMutation } from 'redux-query-react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useHistory } from 'react-router-dom'
import { Alert, Button, Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { addTransactionSchedule, searchTransactionSchedules, TransactionSchedule, updateTransactionSchedule } from 'api'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { getCustomer, getEntities, getTransactionFrequencies } from 'app/selectors'
import { Loading } from 'components/Loading'
import { AvDatePicker } from 'components/Datepicker'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { customerQuery } from 'app/queries'
import { useSetBreadcrumbTitle } from 'hooks/useSetBreadcrumbTitle'
import { fixTimeZone } from 'utilities'
import { NotificationsText } from 'components/NotificationsText'

export interface TransactionScheduleFormProps {
  customerId?: number
  transactionScheduleId?: number
}

export const TransactionScheduleForm = ({ customerId, transactionScheduleId }: TransactionScheduleFormProps) => {
  const newTransactionSchedule: TransactionSchedule = {
    customerId: customerId || 0,
  }
  const transactionSchedule = useSelector((state) => getEntities(state).transactionSchedule) || newTransactionSchedule
  const [serverErrors, setServerErrors] = useState<Errors>()
  const [successfulTransactionMessages, setSuccessfulTransactionMessages] = useState([] as string[])
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const [frequency, setFrequency] = useState('')
  const [processDate, setProcessDate] = useState<Date>()
  const [secondProcessDate, setSecondProcessDate] = useState<Date>()
  const [formLoaded, setFormLoaded] = useState(!transactionScheduleId)
  const history = useHistory()
  const customer = useSelector(getCustomer) || {}
  const transactionFrequencies = useSelector(getTransactionFrequencies)
  const formRef = useRef<AvForm>()
  useRequest(customerQuery(true, customerId))
  useSetBreadcrumbTitle(customerId?.toString(), customer.name)
  useClearEntitiesOnUnmount(['transactionSchedule', 'customer'])

  const [requestState, doRequest] = useRequest(
    !transactionScheduleId
      ? null
      : searchTransactionSchedules(
          {
            transactionScheduleId: transactionScheduleId,
          },
          {
            force: true,
            transform: (body) => {
              return {
                transactionSchedule: body?.records?.[0],
              }
            },
            update: {
              transactionSchedule: (_, newValue) => newValue,
            },
          },
        ),
  )

  const isSpecialFrequency = ['Once', 'Twice'].indexOf(frequency) !== -1

  const [{ isPending }, doMutation] = useMutation((values: TransactionSchedule) => {
    if (transactionScheduleId) {
      return updateTransactionSchedule({
        id: transactionScheduleId,
        transactionSchedule: values,
      })
    }
    return addTransactionSchedule({ transactionSchedule: values })
  })

  const handleValidSubmit = (event, values) => {
    doMutation({
      ...values,
      installments:
        frequency === transactionFrequencies?.once
          ? 1
          : frequency === transactionFrequencies?.twice
          ? 2
          : values.installments,
      customerId: customerId,
    })?.then((response) => {
      if (response?.body?.status === 200) {
        setSuccessfulTransactionMessages(
          ['Your transaction has been scheduled.'].concat((response?.body?.messages?.notifications as string[]) || []),
        )
      } else {
        setServerErrors(response?.body?.messages || [])
      }
    })
  }

  if (!formLoaded && requestState.isFinished && requestState.status === 200 && transactionSchedule?.id) {
    setFrequency(transactionSchedule.frequency)
    transactionSchedule.processDate && setProcessDate(fixTimeZone(transactionSchedule.processDate))
    transactionSchedule.secondProcessDate && setSecondProcessDate(new Date(transactionSchedule.secondProcessDate))
    setFormLoaded(true)
    return <Loading />
  }

  if (requestState.isPending || !formLoaded) {
    return <Loading />
  }

  const handleClickAcknowledge = () => {
    history.push(`/client/customers/${customerId}`)
  }

  return (
    <>
      <Modal isOpen={successfulTransactionMessages.length > 0}>
        <ModalHeader>Transaction Created</ModalHeader>
        <ModalBody>
          <NotificationsText notifications={successfulTransactionMessages} allowHTML />
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={handleClickAcknowledge}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col md={{ size: 4, offset: 4 }}>
          <div className="page-title-box">
            <h4 className="page-title">{transactionScheduleId ? 'Edit' : 'New'} Schedule</h4>
          </div>
          {customer.notifications && customer.notifications.length > 0 && (
            <Alert color="warning">
              <NotificationsText notifications={customer.notifications} allowHTML />
            </Alert>
          )}
          <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
          <AvForm
            disabled={isPending}
            onValidSubmit={handleValidSubmit}
            model={{
              ...transactionSchedule,
              processDate: processDate,
              secondProcessDate: secondProcessDate,
            }}
            ref={formRef}
          >
            <Card>
              <CardBody>
                <AvField
                  name="amount"
                  label="Amount"
                  type="number"
                  required
                  onChange={handleFieldChange}
                  validate={{ async: validate }}
                  onWheel={(e) => e.target.blur()}
                />
                <AvField
                  name="frequency"
                  label="Frequency"
                  type="select"
                  required
                  disabled={!!transactionScheduleId}
                  validate={{ async: validate }}
                  onChange={(e, v) => {
                    handleFieldChange(e)
                    setFrequency(v)
                  }}
                >
                  <option></option>
                  {transactionFrequencies &&
                    Object.values<string>(transactionFrequencies).map((f, i) => (
                      <option key={i} value={f}>
                        {f}
                      </option>
                    ))}
                </AvField>
                <AvField
                  name="installments"
                  label="Installments"
                  placeholder="Leave blank for indefinite"
                  type="number"
                  value={
                    frequency === transactionFrequencies?.once
                      ? 1
                      : frequency === transactionFrequencies?.twice
                      ? 2
                      : undefined
                  }
                  disabled={!!transactionScheduleId || isSpecialFrequency}
                  onChange={handleFieldChange}
                  validate={{ async: validate }}
                  onWheel={(e) => e.target.blur()}
                />
                <AvDatePicker
                  label="Process Date"
                  name="processDate"
                  value={processDate}
                  onChange={(v) => setProcessDate(v)}
                  disabled={!!transactionScheduleId}
                  required
                  serverErrors={serverErrors && serverErrors['process_date']}
                />
                {frequency === 'Twice' && (
                  <AvDatePicker
                    label="Second Process Date"
                    name="secondProcessDate"
                    value={secondProcessDate}
                    onChange={(v) => setSecondProcessDate(v)}
                    disabled={!!transactionScheduleId}
                    required={frequency === 'Twice'}
                    serverErrors={serverErrors && serverErrors['second_process_date']}
                  />
                )}
                <AvField
                  name="comment"
                  label="Comment"
                  type="text"
                  onChange={handleFieldChange}
                  validate={{ async: validate }}
                />
              </CardBody>
            </Card>

            <div className="text-right">
              <Button onClick={() => history.goBack()} disabled={isPending} className="btn-rotessa-secondary min-width">
                Cancel
              </Button>
              <Button className="ml-1" disabled={isPending} color="primary">
                Submit
              </Button>
            </div>
          </AvForm>
        </Col>
      </Row>
    </>
  )
}
