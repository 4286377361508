import React from 'react'
import { Redirect } from 'react-router-dom'
import { PublicOwners } from 'features/onboarding/owners'
import LoginPage from 'features/login/loginPage'
import ForgotPassword from 'features/login/forgotPassword'
import VerificationComplete from 'features/veriff/verificationComplete'
import { MyAccount } from 'features/account/MyAccount'
import { ChangePassword } from 'features/account/ChangePassword'
import { TwoFactorAuthentication } from 'features/account/TwoFactorAuthentication'
import { LinkAuthorizationForm } from 'features/authorizations/processing/linkAuthorizationForm'
import { EmailAuthorizationForm } from 'features/authorizations/processing/emailAuthorizationForm'
import { MicrodepositVerificationPage } from 'features/microdeposits/microdepositVerification'
import { CreateAccountPage } from 'features/account/CreateAccount'
import { ClientManager } from '../features/clients/ClientManager'
import { PostOauthRedirect } from 'features/integrations/postOauthRedirect'
import { StudentManager } from 'features/student/studentManager'
import { ResetPassword } from 'features/login/resetPassword'
import { ResetPasswordSuccess } from 'features/login/resetPasswordSuccess'
import { ProcessingAuthFormPage } from 'features/authorizations/processing/processingAuthFormPage'
import { DonorAuthFormPage } from 'features/authorizations/donor/donorAuthFormPage'
import { DonorAuthEmailExpired } from 'features/authorizations/donor/donorAuthEmailExpired'
import { CustomerUnsubscribePage } from 'features/notifications/customerUnsubscribePage'

export interface RouteConfig {
  path: string
  component: any
  exact?: boolean | false
  name?: string
  icon?: string
  noMenu?: boolean
  permissionName?: string
  releaseHelperName?: string
  hideMenu?: boolean
  useNoClientReleaseHelper?: boolean
}

export const LOGGED_OUT_ROUTES: RouteConfig[] = [
  {
    path: '/login',
    exact: true,
    component: LoginPage,
    noMenu: true,
  },
  {
    path: '/users/sign_in',
    exact: true,
    component: () => <Redirect to="/login" />,
    noMenu: true,
  },
  {
    path: '/create_account',
    exact: true,
    component: CreateAccountPage,
    noMenu: true,
    hideMenu: true,
  },
  {
    path: '/join',
    exact: true,
    component: () => <Redirect to="/create_account" />,
    noMenu: true,
  },
  {
    path: '/join/new_account',
    exact: true,
    component: () => <Redirect to="/create_account" />,
    noMenu: true,
  },
  {
    path: '/signup',
    exact: true,
    component: () => <Redirect to="/create_account" />,
    noMenu: true,
  },
  {
    path: '/forgot_password',
    exact: true,
    component: ForgotPassword,
    noMenu: true,
  },
  {
    path: '/verification_complete',
    exact: true,
    component: VerificationComplete,
    noMenu: true,
  },
]

export const PUBLIC_ROUTES: RouteConfig[] = [
  {
    path: '/authorize/:code',
    component: ProcessingAuthFormPage,
    noMenu: true,
  },
  {
    path: '/authorize',
    component: ProcessingAuthFormPage,
    noMenu: true,
  },
  {
    path: '/donation/email_verification_expired',
    exact: true,
    component: DonorAuthEmailExpired,
    noMenu: true,
  },
  {
    path: '/donation/:code',
    component: DonorAuthFormPage,
    noMenu: true,
  },
  {
    path: '/email_authorizations',
    exact: true,
    component: EmailAuthorizationForm,
    noMenu: true,
  },
  {
    path: '/link_authorizations',
    exact: true,
    component: LinkAuthorizationForm,
    noMenu: true,
  },
  {
    path: '/customer_authorizations',
    exact: true,
    component: LinkAuthorizationForm,
    noMenu: true,
  },
  {
    path: '/microdeposit_verifications',
    exact: true,
    component: MicrodepositVerificationPage,
    noMenu: true,
  },
  {
    path: '/owner_signup/:code',
    component: PublicOwners,
    noMenu: true,
  },
  {
    path: '/reset_password',
    exact: true,
    component: ResetPassword,
    noMenu: true,
  },
  {
    path: '/reset_password_success',
    exact: true,
    component: ResetPasswordSuccess,
    noMenu: true,
  },
  {
    path: '/unsubscribe/:uuid',
    exact: true,
    component: CustomerUnsubscribePage,
    noMenu: true,
  },
]

export const CLIENT_ROUTE: RouteConfig = {
  path: '/client',
  component: ClientManager,
  noMenu: true,
}

export const STUDENT_ROUTE: RouteConfig = {
  path: '/student',
  component: StudentManager,
  noMenu: true,
}

export const LOGGED_IN_ROUTES: RouteConfig[] = [
  {
    path: '/post_oauth_redirect/:clientId',
    component: PostOauthRedirect,
    noMenu: true,
  },
  {
    path: '/my_account',
    exact: true,
    component: MyAccount,
    noMenu: true,
  },
  {
    path: '/change_password',
    exact: true,
    component: ChangePassword,
    noMenu: true,
  },
  {
    path: '/two_factor_authentication',
    exact: true,
    component: TwoFactorAuthentication,
    noMenu: true,
  },
]
