// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkAuthorization
 */
export interface LinkAuthorization  {
    /**
     * 
     * @type {number}
     * @memberof LinkAuthorization
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    formUrl: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    provinceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    transitNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    institutionNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    routingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    bankAccountType?: string;
    /**
     * 
     * @type {Date}
     * @memberof LinkAuthorization
     */
    processDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof LinkAuthorization
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    frequency?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    installments?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    termsAndConditions?: string;
    /**
     * 
     * @type {number}
     * @memberof LinkAuthorization
     */
    customerId?: number;
    /**
     * 
     * @type {Date}
     * @memberof LinkAuthorization
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LinkAuthorization
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof LinkAuthorization
     */
    authorizationLinkSettingId?: number;
    /**
     * 
     * @type {number}
     * @memberof LinkAuthorization
     */
    authorizationAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkAuthorization
     */
    comment?: string;
}

export function LinkAuthorizationFromJSON(json: any): LinkAuthorization {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'formUrl': json['form_url'],
        'name': json['name'],
        'email': json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address1': !exists(json, 'address_1') ? undefined : json['address_1'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'provinceCode': !exists(json, 'province_code') ? undefined : json['province_code'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'transitNumber': !exists(json, 'transit_number') ? undefined : json['transit_number'],
        'institutionNumber': !exists(json, 'institution_number') ? undefined : json['institution_number'],
        'routingNumber': !exists(json, 'routing_number') ? undefined : json['routing_number'],
        'accountNumber': !exists(json, 'account_number') ? undefined : json['account_number'],
        'bankAccountType': !exists(json, 'bank_account_type') ? undefined : json['bank_account_type'],
        'processDate': !exists(json, 'process_date') ? undefined : new Date(json['process_date']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'installments': !exists(json, 'installments') ? undefined : json['installments'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'termsAndConditions': !exists(json, 'terms_and_conditions') ? undefined : json['terms_and_conditions'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'authorizationLinkSettingId': !exists(json, 'authorization_link_setting_id') ? undefined : json['authorization_link_setting_id'],
        'authorizationAmount': !exists(json, 'authorization_amount') ? undefined : json['authorization_amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function LinkAuthorizationToJSON(value?: LinkAuthorization): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'form_url': value.formUrl,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'address_1': value.address1,
        'city': value.city,
        'province_code': value.provinceCode,
        'postal_code': value.postalCode,
        'transit_number': value.transitNumber,
        'institution_number': value.institutionNumber,
        'routing_number': value.routingNumber,
        'account_number': value.accountNumber,
        'bank_account_type': value.bankAccountType,
        'process_date': value.processDate === undefined ? undefined : value.processDate.toISOString(),
        'amount': value.amount,
        'frequency': value.frequency,
        'installments': value.installments,
        'introduction': value.introduction,
        'terms_and_conditions': value.termsAndConditions,
        'customer_id': value.customerId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'authorization_link_setting_id': value.authorizationLinkSettingId,
        'authorization_amount': value.authorizationAmount,
        'comment': value.comment,
    };
}


