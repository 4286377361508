import React, { useEffect, useState } from 'react'
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation'
import { OnboardingPageProps, NextButton, StepType, RightPaneComponentType } from './onboardingManager'
import { useDispatch, useSelector } from 'react-redux'
import { getEntities } from 'app/selectors'
import { Badge, Button, Col, Input, InputGroup, Row } from 'reactstrap'
import { Client, Owner, getOwnerVeriffUrl, submitClientOnboardingVeriffState } from 'api'
import { requestAsync, updateEntities } from 'redux-query'
import { Errors } from 'hooks/useFormServerErrors'
import { FormServerErrors } from 'components/FormServerErrors'
import { StateProvinceSelect } from 'components/ProvinceSelect'
import { PostalCodeField } from 'components/PostalCodeField'
import { fixTimeZone } from 'utilities'
import { VERIFF_STATES } from 'app/constants'
import { CustomRadioGroup, CustomRadioInput } from 'components/CustomRadio'
import { useMutation } from 'redux-query-react'
import copy from 'clipboard-copy'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import { useTranslation } from 'react-i18next'
import { getOwnerTypes } from 'app/selectors'
interface OwnershipFieldsProps {
  index?: number
  ownerName?: string
  share?: number
  validate?: Function
  hideShare?: boolean
}

const dynamicSubSteps = (
  prevSubSteps: StepType[],
  owners?: Owner[],
  activeOwner?: Owner,
  activeState?: string,
  activeClient?: Client,
) => {
  let namedSteps: StepType[] =
    owners?.map((val) => {
      return {
        label: val?.name + ' information',
        name: 'owners_info_' + val.name,
        status: val.id === activeOwner?.id ? 'active' : '',
      }
    }) || []

  let subSteps = [...prevSubSteps.filter((step) => !step.name.startsWith('owners_info'))]
  subSteps.splice(subSteps.findIndex((step) => step.name === 'owners_names') + 1, 0, ...namedSteps)
  if (activeClient?.businessType === 'Proprietorship') {
    subSteps = subSteps.filter((step) => step.name !== 'primary_owner')
  }
  return subSteps
}

const OwnershipFields = ({ index, ownerName, share, validate, hideShare = false }: OwnershipFieldsProps) => {
  const { t } = useTranslation()
  return (
    <AvGroup>
      <Row>
        <Col xs={hideShare ? 12 : 8} xl={hideShare ? 12 : 9}>
          <AvField
            name={'[' + index + '][name]'}
            type="text"
            validate={{
              async: validate,
            }}
            required={document.getElementsByName('[' + index + '][share]')?.[0]?.['value'] !== '' || index === 0}
            value={ownerName}
            disabled={ownerName}
            label="Owner Full Name"
          />
        </Col>
        <Col xs={4} xl={3} className={`pl-0${hideShare ? ' d-none' : ''}`}>
          <AvField
            name={'[' + index + '][share]'}
            type="number"
            required={document.getElementsByName('[' + index + '][name]')?.[0]?.['value'] !== '' || index === 0}
            max={100}
            accuracy={2}
            min={25}
            validate={{
              required: {
                errorMessage: t('required_field'),
              },
              min: {
                value: 25,
                errorMessage: 'Must be 25% or greater',
              },
              max: {
                value: 100,
                errorMessage: 'Must be 100% or less',
              },
              async: validate,
            }}
            value={share}
            label="Share %"
            inputClass="input-holder share-input"
          />
        </Col>
      </Row>
    </AvGroup>
  )
}

export const OwnershipRightPanel = ({ activeClient }: RightPaneComponentType) => {
  return (
    <>
      <h3>Why we ask</h3>
      {activeClient?.businessType === 'Proprietorship' ? (
        <p>
          Ownership is an important piece of business verification. We need to verify the identity of the owner in order
          to pass our Know Your Customer (KYC) requirements. If you are not the owner, please follow the steps to
          provide the correct owner information.
        </p>
      ) : (
        <p>
          Ownership is an important piece of business verification. We need to identify stakeholders that have 25% or
          more in the company. Answering this accurately will ensure a smooth onboarding experience.
        </p>
      )}
    </>
  )
}

export const OwnersNamesRightPanel = ({ activeClient }: RightPaneComponentType) => {
  return (
    <>
      <h3>Why we ask</h3>
      {activeClient?.businessType === 'Proprietorship' ? (
        <p>
          Since you are not the owner of this business, we will need to confirm the identity of the owner in order to
          satisfy our Know Your Customer (KYC) requirements. Please provide the correct owner information in the
          following steps.
        </p>
      ) : (
        <p>
          Ownership is an important piece of business verification. We need to identify stakeholders that have 25% or
          more in the company. The owner with the highest percentage stake will be asked to complete an identity
          verification in the next step.
        </p>
      )}
    </>
  )
}

export const OwnershipComponent = ({
  onNext,
  validate,
  setCurrentPage,
  activeClient,
  formRef,
}: OnboardingPageProps) => {
  const [ownership, setOwnership] = useState(undefined)
  const account = useSelector((state) => getEntities(state).account) || {}
  const dispatch = useDispatch()
  const isProprietorship = activeClient.businessType === 'Proprietorship'

  const handleSubmitMultipleOwners = (event, values: Owner[]) => {
    let owners = Object.values(values).map((val, index) => {
      if (val['name'] !== '' && val['share'] !== undefined) {
        return { ...val, clientId: activeClient?.id, ...(activeClient?.ownersAttributes?.[index] || []) }
      }
    })

    onNext({ e: event, ownersValues: owners as Owner[] })
  }

  const handleOwnershipSubmit = (event, values) => {
    if (isProprietorship && values['ownership'] === 'true') {
      handleSubmitMultipleOwners(event, [{ name: account.name, share: 100, userId: account.id }])
    } else {
      setOwnership(values['ownership'])
      if (values['ownership'] === 'true') {
        dispatch(
          updateEntities({
            activeClient: (prev: Client) => {
              return {
                ...prev,
                ownersAttributes: [{ name: account.name, userId: account.id }],
              }
            },
          }),
        )
      }
    }
  }

  useEffect(() => {
    if ((activeClient?.ownersAttributes || []).length > 0 || ownership !== undefined) {
      setCurrentPage((prev) => ({ ...prev!, state: 'owners_names', rightPane: OwnersNamesRightPanel }))
    } else {
      setCurrentPage((prev) => ({ ...prev!, state: 'ownership', rightPane: OwnershipRightPanel }))
    }
  }, [activeClient?.ownersAttributes, ownership])

  if ((activeClient?.ownersAttributes || []).length > 0 || ownership !== undefined) {
    return (
      <AvForm onValidSubmit={(event, values) => handleSubmitMultipleOwners(event, values)} ref={formRef}>
        <h3>{isProprietorship ? 'Name of owner' : 'Names of owners with more than 25% share'}</h3>

        {isProprietorship ? (
          <>
            <OwnershipFields
              share={100}
              ownerName={activeClient?.ownersAttributes?.[0]?.name}
              validate={validate}
              hideShare={true}
            ></OwnershipFields>
          </>
        ) : (
          Array.from({ length: 4 }, (_, i) => (
            <OwnershipFields
              index={i}
              share={activeClient?.ownersAttributes?.[i]?.share}
              ownerName={activeClient?.ownersAttributes?.[i]?.name}
              validate={validate}
            ></OwnershipFields>
          ))
        )}

        <NextButton />
      </AvForm>
    )
  } else {
    return (
      <AvForm onValidSubmit={(event, values) => handleOwnershipSubmit(event, values)} ref={formRef}>
        <h3>Ownership</h3>
        <CustomRadioGroup
          name="ownership"
          label={isProprietorship ? 'Do you own the business?' : 'Do you own more than 25% of the business?'}
          required
        >
          <CustomRadioInput
            label={isProprietorship ? 'I own the business' : 'I own more than 25% of the business'}
            value="true"
            name={'ownership_true'}
          />
          <CustomRadioInput
            label={isProprietorship ? 'Someone else owns the business' : 'I do not own more than 25% of the business'}
            value="false"
            name={'ownership_false'}
          />
        </CustomRadioGroup>
        <NextButton />
      </AvForm>
    )
  }
}

export const OwnersInfoComponent = ({
  onNext,
  handleFieldChange,
  serverErrors,
  validate,
  setSteps,
  activeClient,
  formRef,
}: OnboardingPageProps) => {
  const ownerTypes: string[] = useSelector(getOwnerTypes)
  const owner = activeClient?.ownersAttributes?.find((value) => {
    return !!value.completionStatus === false
  })

  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(() =>
    owner?.dateOfBirth ? fixTimeZone(owner.dateOfBirth) : undefined,
  )

  const handleDateFieldChange = (event) => {
    handleFieldChange(event)
    setDateOfBirth(fixTimeZone(event.target.value))
  }

  const handleValidSubmit = (event, values) => {
    onNext({ e: event, ownersValues: [{ ...owner, ...values, dateOfBirth: dateOfBirth }] })
  }
  const isProprietorship = activeClient.businessType === 'Proprietorship'

  useEffect(() => {
    setSteps((prev) =>
      prev.map((step) => {
        let newStep = { ...step }
        if (step.name === 'owners') {
          newStep.subSteps = dynamicSubSteps(
            step.subSteps!,
            activeClient?.ownersAttributes,
            owner,
            undefined,
            activeClient,
          )
        }
        return newStep
      }),
    )
  }, [owner])

  return (
    <AvForm
      key={owner?.id}
      model={owner}
      onValidSubmit={(event, values) => handleValidSubmit(event, values)}
      ref={formRef}
    >
      <h3>Information for {owner?.name}</h3>
      {isProprietorship ? (
        <AvField type="hidden" name="position" value="Owner" />
      ) : (
        <AvField required type="select" label="Position" name="position" onChange={handleFieldChange}>
          <option value="" disabled selected hidden>
            Choose one...
          </option>
          {ownerTypes?.map((value, i) => (
            <option key={i} value={value}>
              {value}
            </option>
          ))}
        </AvField>
      )}
      <AvField
        name="date_of_birth"
        type="date"
        label="Date of Birth"
        className="mb-2"
        required
        validate={{ async: validate }}
        onChange={handleDateFieldChange}
      />
      <AvField
        name="addressesAttributes[0].address1"
        type="text"
        label="Personal Street Address"
        placeholder="Ex: 123 First Street"
        className="mb-2"
        required
        validate={{ async: validate }}
        onChange={handleFieldChange}
      />
      <AvField
        name="addressesAttributes[0].city"
        type="text"
        label="Town or City"
        placeholder="Enter the town or city"
        className="mb-2"
        required
        validate={{ async: validate }}
        onChange={handleFieldChange}
      />
      <StateProvinceSelect
        required
        name="addressesAttributes[0].provinceCode"
        serverErrors={serverErrors}
        onChange={handleFieldChange}
        countryCode={activeClient?.countryCode}
        placeholder="Choose one..."
      />
      <PostalCodeField
        required
        name="addressesAttributes[0].postalCode"
        countryCode={activeClient?.countryCode}
        onChange={handleFieldChange}
        serverErrors={serverErrors}
        examplePlaceholder={true}
      />
      <NextButton />
    </AvForm>
  )
}

export const PrimaryOwnerComponent = ({ onNext, setSteps, activeClient, formRef }: OnboardingPageProps) => {
  const handleValidSubmit = (event, values) => {
    onNext({ clientValues: { ...activeClient, primaryOwnerId: parseInt(values['primary_owner']) } })
  }

  let eligibleOwners = activeClient?.ownersAttributes?.filter((owner) => owner.position?.match(/Owner/)) || []
  if (eligibleOwners.length === 0) {
    eligibleOwners = activeClient?.ownersAttributes || []
  }

  useEffect(() => {
    setSteps((prev) =>
      prev.map((step) => {
        let newStep = { ...step }
        if (step.name === 'owners') {
          newStep.subSteps = dynamicSubSteps(
            step.subSteps!,
            activeClient?.ownersAttributes,
            undefined,
            'primary_owner',
            activeClient,
          )
        }
        return newStep
      }),
    )
  }, [])

  return (
    <AvForm onValidSubmit={(event, values) => handleValidSubmit(event, values)} ref={formRef}>
      <h3>Who is the primary beneficial owner?</h3>
      <CustomRadioGroup name="primary_owner" label="Choose One" required>
        {eligibleOwners &&
          eligibleOwners.map((owner, index) => {
            return <CustomRadioInput key={index} label={owner.name} value={owner.id || -1} name={owner.name || ''} />
          })}
      </CustomRadioGroup>
      <NextButton />
    </AvForm>
  )
}

export const OwnerVerificationComponent = ({
  onNext,
  clientOnboarding,
  setSteps,
  activeClient,
  formRef,
}: OnboardingPageProps) => {
  const dispatch = useDispatch()
  const primaryOwner: Owner =
    activeClient?.ownersAttributes?.find((owner) => owner.id === activeClient.primaryOwnerId) || {}
  const code = activeClient?.ownerFormUrl || ''
  const link = `${window.location.host}/owner_signup/${code}`
  const [errors, setErrors] = useState<Errors>({})
  const [finished, setFinished] = useState(primaryOwner?.verified)

  const [verifyOwnerState, doVerifyOwner] = useMutation(() => {
    return submitClientOnboardingVeriffState(
      {
        submitStateRequest: {
          clientId: activeClient?.id,
        },
      },
      {
        transform: (body) => ({ ...body }),
      },
    )
  })

  const handleVeriff = (values: Owner) => {
    if (primaryOwner?.id && activeClient?.id) {
      ;(dispatch(
        requestAsync(
          getOwnerVeriffUrl(
            {
              ownerId: primaryOwner.id,
              clientId: activeClient.id,
            },
            {
              transform: (body) => ({ ...body }),
              force: true,
            },
          ),
        ),
      ) as any).then((response) => {
        setErrors(response?.body?.messages || [])
        if (response?.body?.status < 400) {
          if (response?.transformed?.veriffUrl) {
            doVeriff(response.transformed.veriffUrl, () => {
              doVerifyOwner()?.then((response) => {
                onNext({ clientValues: { ...activeClient } })?.then((response) => {
                  setErrors(response?.body?.messages || {})
                })
                setFinished(true)
              })
            })
          } else {
            setErrors(response?.body?.messages || [])
          }
        }
      })
    }
  }

  const doVeriff = (url: string, onFinished: Function) => {
    createVeriffFrame({
      url: url,
      onEvent: (msg) => {
        if (msg === MESSAGES.FINISHED) {
          onFinished()
        }
      },
    })
  }

  const handleValidSubmit = (event, values) => {
    onNext({ clientValues: { ...activeClient, primaryOwnerId: parseInt(values['primary_owner']) } })
  }

  let renderVeriff = () => {
    switch (clientOnboarding.veriffState) {
      case VERIFF_STATES['incomplete']:
        if (activeClient?.signupUserIsPrimaryOwner) {
          return (
            <>
              <Button onClick={(event) => handleVeriff(primaryOwner)} className="w-100 veriff-button">
                Verify ID
              </Button>
            </>
          )
        } else
          return (
            <>
              <Row>
                <Col className="align-items-center text-center">
                  <InputGroup className="align-items-center text-center copy">
                    <Input style={{ backgroundColor: 'white' }} readOnly value={link} />
                    <Button
                      onClick={() => {
                        copy(link)
                      }}
                      color="blue"
                      id="copy-auth-link-url-button"
                      className="ml-1"
                    >
                      <i className="mdi mdi-content-copy mr-1 ml-1" />
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Badge
                    className="text-center w-100"
                    style={{ color: 'black', backgroundColor: 'rgba(57, 175, 209, 0.25)', fontSize: 16 }}
                  >
                    <b>Send this link to {primaryOwner?.name} to complete</b>
                  </Badge>
                </Col>
              </Row>
            </>
          )

      case VERIFF_STATES['complete']:
        return (
          <Row className="mt-2 mb-3">
            <Col>
              <Badge className="text-center w-100" color="success" style={{ color: 'black', fontSize: 16 }}>
                <b>Verification complete</b>
              </Badge>
            </Col>
          </Row>
        )

      case VERIFF_STATES['failed']:
        return (
          <Row className="mt-2 mb-3">
            <Col>
              <Badge className="text-center w-100" color="danger" style={{ color: 'black', fontSize: 16 }}>
                <b>Verification failed</b>
              </Badge>
            </Col>
          </Row>
        )
    }
  }

  useEffect(() => {
    setSteps((prev) =>
      prev.map((step) => {
        let newStep = { ...step }
        if (step.name === 'owners') {
          newStep.subSteps = dynamicSubSteps(
            step.subSteps!,
            activeClient?.ownersAttributes,
            undefined,
            'owner_verification',
            activeClient,
          )
        }
        return newStep
      }),
    )
  }, [])

  return (
    <>
      <FormServerErrors errors={errors['base'] || []} />
      <AvForm onValidSubmit={(event, values) => handleValidSubmit(event, values)} ref={formRef}>
        <h3>ID verification</h3>
        <Row className="mb-3">
          <Col>
            <div className="subtext">
              {activeClient?.signupUserIsPrimaryOwner && activeClient?.businessType === 'Proprietorship'
                ? 'In order to complete your account, you will need to complete the ID verification process at this link.'
                : `In order to complete your account, the ${
                    activeClient?.businessType === 'Proprietorship' ? 'owner' : 'primary beneficial owner'
                  } needs to complete the ID verification process at this link.`}
            </div>
          </Col>
        </Row>

        {clientOnboarding.veriffState && renderVeriff()}

        {clientOnboarding.veriffState === VERIFF_STATES['incomplete'] && (
          <Row className="mb-2 mt-3">
            <Col>
              <div className="subtext">You will be emailed when this step is complete.</div>
            </Col>
          </Row>
        )}

        {(finished || !activeClient?.signupUserIsPrimaryOwner) && <NextButton />}
      </AvForm>
    </>
  )
}
