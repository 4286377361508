// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    SettlementTransactionsClientOnboardingSettlementAccount,
    SettlementTransactionsClientOnboardingSettlementAccountFromJSON,
    SettlementTransactionsClientOnboardingSettlementAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientOnboarding
 */
export interface ClientOnboarding  {
    /**
     * 
     * @type {number}
     * @memberof ClientOnboarding
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientOnboarding
     */
    thirdPartyId?: number;
    /**
     * 
     * @type {Client}
     * @memberof ClientOnboarding
     */
    clientData?: Client;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    state?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClientOnboarding
     */
    statuses?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    veriffState?: string;
    /**
     * 
     * @type {SettlementTransactionsClientOnboardingSettlementAccount}
     * @memberof ClientOnboarding
     */
    settlementAccount?: SettlementTransactionsClientOnboardingSettlementAccount;
    /**
     * 
     * @type {SettlementTransactionsClientOnboardingSettlementAccount}
     * @memberof ClientOnboarding
     */
    billingAccount?: SettlementTransactionsClientOnboardingSettlementAccount;
    /**
     * 
     * @type {boolean}
     * @memberof ClientOnboarding
     */
    gtmCompletedAccountTriggered?: boolean;
}

export function ClientOnboardingFromJSON(json: any): ClientOnboarding {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'thirdPartyId': !exists(json, 'third_party_id') ? undefined : json['third_party_id'],
        'clientData': !exists(json, 'client_data') ? undefined : ClientFromJSON(json['client_data']),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'statuses': !exists(json, 'statuses') ? undefined : json['statuses'],
        'veriffState': !exists(json, 'veriff_state') ? undefined : json['veriff_state'],
        'settlementAccount': !exists(json, 'settlement_account') ? undefined : SettlementTransactionsClientOnboardingSettlementAccountFromJSON(json['settlement_account']),
        'billingAccount': !exists(json, 'billing_account') ? undefined : SettlementTransactionsClientOnboardingSettlementAccountFromJSON(json['billing_account']),
        'gtmCompletedAccountTriggered': !exists(json, 'gtm_completed_account_triggered') ? undefined : json['gtm_completed_account_triggered'],
    };
}

export function ClientOnboardingToJSON(value?: ClientOnboarding): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'third_party_id': value.thirdPartyId,
        'client_data': ClientToJSON(value.clientData),
        'state': value.state,
        'statuses': value.statuses,
        'veriff_state': value.veriffState,
        'settlement_account': SettlementTransactionsClientOnboardingSettlementAccountToJSON(value.settlementAccount),
        'billing_account': SettlementTransactionsClientOnboardingSettlementAccountToJSON(value.billingAccount),
        'gtm_completed_account_triggered': value.gtmCompletedAccountTriggered,
    };
}


