import { HomePage } from 'features/home/HomePage'
import { UsersView } from 'features/users/usersView'
import { UserForm } from 'features/users/userForm'
import { CustomersTable } from 'features/customers/customersTable'
import { CustomerDashboard } from 'features/customers/customerDashboard'
import { ApiKeysView } from 'features/apiKeys/apiKeysView'
import { CustomerNoteForm } from 'features/customers/customerNotes/customerNoteForm'
import { TransactionScheduleForm } from 'features/customers/transactionSchedules/transactionScheduleForm'
import { MicrodepositsTable } from 'features/microdeposits/microdepositsTable'
import { CLIENT_STATUS } from 'app/constants'
import { IntegrationManager } from 'features/integrations/manager'
import { TransactionReportTable } from 'features/transactionReport/TransactionReportTable'
import { Settlements } from 'features/settlements/settlementsTable'
import { EmailAuthorizationRequest } from 'features/customers/emailAuthorizationRequest'
import { BusinessInfoForm } from 'features/clients/businessInfoForm'
import { ClientBankAccountForm } from 'features/clients/clientBankAccountForm'
import { RouteConfig } from 'app/routes'
import { TransactionHistoryDetails } from 'features/customers/transactionHistory/transactionHistoryDetails'
import { SettlementDetails } from 'features/settlements/settlementDetails'
import { Client } from 'api'
import { isPermitted, isReleased } from 'utilities'
import { CustomerPreview } from 'features/imports/customerPreview'
import { TransactionPreview } from 'features/imports/transactionPreview'
import { AuthLogoEditor } from 'features/auth_settings/authLogoEditor'
import { AuthorizationSettingsDecider } from 'features/authorization_settings/authorizationSettingsDecider'
import { CustomerImportPage } from 'features/imports/customerImportPage'
import { TransactionImportPage } from 'features/imports/transactionImportPage'
import { NewItemFlow } from 'features/customers/customerFlow/newItemFlow'
import { CustomerAuthorization } from 'features/customers/customerFlow/customerAuthorization'
import { CustomerAuthorizationInfo } from 'features/customers/customerFlow/customerAuthorizationInfo'
import { CustomerBankInfo } from 'features/customers/customerFlow/customerBankInfo'
import { CustomerOptionalInfo } from 'features/customers/customerFlow/customerOptionalInfo'
import { CustomerAuthorizationCollect } from 'features/customers/customerFlow/customerAuthorizationCollect'
import { CustomerAuthorizationEmail } from 'features/customers/customerFlow/customerAuthorizationEmail'
import { CustomerAuthorizationLink } from 'features/customers/customerFlow/customerAuthorizationLink'
import { NewTransactionSchedule } from 'features/customers/transactionSchedules/newTransactionSchedule'
import { ManualTransactionSchedule } from 'features/customers/transactionSchedules/manualTransactionSchedule'
import { NewCustomerFlow } from 'features/customers/customerFlow/newCustomerFlow'
import { CustomerContactForm } from 'features/customers/customerContactForm'
import { CustomerBankAndAuthForm } from 'features/customers/customerBankAndAuthForm'

import microdepositGreyIcon from 'assets/images/Micro-deposit-grey-menu-icon.png'
import authorizationGreyIcon from 'assets/images/Authorization-grey-menu-icon.png'
import { IntegrationCustomers } from 'features/integrations/integrationCustomers'
import { IntegrationInvoices } from 'features/integrations/integrationInvoices'
import { AuthorizationPage } from 'features/authorizations/authorizationsPage'
import { EmailAuthorizationSelect } from 'features/customers/emailAuthorizationSelect'
import { OnboardingManager } from 'features/onboarding/onboardingManager'
import { InvoicesView } from 'features/invoices/InvoicesView'
import { NotificationSettingsPage } from 'features/notification_settings/notificationSettingsPage'
import { DeclinedTransactionReport } from 'features/transactionReport/DeclinedTransactionReport'

interface ClientStatusRoutes {
  [key: string]: RouteConfig[]
}

export const NO_CLIENT_ROUTES: RouteConfig[] = [
  {
    path: '/client/signup',
    icon: 'mdi mdi-check-circle-outline',
    name: 'Complete your account',
    noMenu: true,
    hideMenu: true,
    component: OnboardingManager,
  },
]

export const CLIENT_STATUS_ROUTES: ClientStatusRoutes = {
  [CLIENT_STATUS.created]: [
    {
      path: '/client/signup',
      icon: 'mdi mdi-check-circle-outline',
      name: 'Complete your account',
      noMenu: true,
      hideMenu: true,
      component: OnboardingManager,
    },
    {
      path: '/client/new_business',
      exact: true,
      component: OnboardingManager,
      noMenu: true,
      hideMenu: true,
      useNoClientReleaseHelper: true,
    },
  ],
  [CLIENT_STATUS.pending]: [
    {
      path: '/client/signup_complete',
      icon: 'mdi mdi-check-circle-outline',
      name: 'Your account is pending approval',
      noMenu: true,
      hideMenu: true,
      component: OnboardingManager,
    },
  ],
  [CLIENT_STATUS.approved]: [
    {
      path: '/client/home',
      exact: true,
      name: 'Home',
      icon: 'mdi mdi-home-outline',
      component: HomePage,
      permissionName: 'menu.home',
    },
    {
      path: 'divider',
      name: 'divider',
      component: null,
    },
    {
      path: '/client/update_settlement_account',
      exact: true,
      component: ClientBankAccountForm,
      noMenu: true,
      permissionName: 'menu.settings',
    },
    {
      path: '/client/update_billing_account',
      exact: true,
      component: ClientBankAccountForm,
      noMenu: true,
      permissionName: 'menu.settings',
    },
    {
      path: '/client/customers/new',
      exact: true,
      component: NewCustomerFlow,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/customers/connect',
      exact: true,
      component: IntegrationCustomers,
      noMenu: true,
      permissionName: 'menu.integrations',
    },
    {
      path: '/client/customers/import-invoices',
      exact: true,
      component: IntegrationInvoices,
      noMenu: true,
      permissionName: 'menu.integrations',
    },
    {
      path: '/client/new-item',
      name: 'New',
      component: NewItemFlow,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/new-item',
      name: 'New',
      component: NewItemFlow,
      noMenu: true,
      permissionName: 'transaction_schedules.new',
    },
    {
      path: '/client/customers/new/authorization',
      exact: true,
      component: CustomerAuthorization,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/customers/new/authorization-information',
      exact: true,
      component: CustomerAuthorizationInfo,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/customers/new/bank-information',
      exact: true,
      component: CustomerBankInfo,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/customers/new/optional-information/:customerId',
      exact: true,
      component: CustomerOptionalInfo,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/customers/new/collect-authorization',
      exact: true,
      component: CustomerAuthorizationCollect,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/customers/new/authorization-email',
      exact: true,
      component: CustomerAuthorizationEmail,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/customers/new/public-link',
      exact: true,
      component: CustomerAuthorizationLink,
      noMenu: true,
      permissionName: 'customers.new',
    },
    {
      path: '/client/customers/imports/preview-customers',
      name: 'Preview Customer Import',
      component: CustomerPreview,
      noMenu: true,
      permissionName: 'customers.csv_imports',
    },
    {
      path: '/client/customers/imports/preview-transactions',
      name: 'Preview Transaction Import',
      component: TransactionPreview,
      noMenu: true,
      permissionName: 'transaction_schedules.csv_imports',
    },
    {
      path: '/client/customers/new/imports/csv',
      name: 'CSV Imports',
      component: CustomerImportPage,
      noMenu: true,
      permissionName: 'customers.csv_imports',
    },
    {
      path: '/client/transactions/new/imports/csv',
      name: 'CSV Imports',
      component: TransactionImportPage,
      noMenu: true,
      permissionName: 'transaction_schedules.csv_imports',
    },
    {
      path: '/client/transactions/new',
      exact: true,
      name: 'CSV Imports',
      component: NewTransactionSchedule,
      noMenu: true,
      permissionName: 'transaction_schedules.new',
    },
    {
      path: '/client/transactions/new/manual',
      name: 'CSV Imports',
      component: ManualTransactionSchedule,
      noMenu: true,
      permissionName: 'transaction_schedules.new',
    },
    {
      path: '/client/transactions/new/imports/csv',
      name: 'CSV Imports',
      component: TransactionImportPage,
      noMenu: true,
      permissionName: 'transaction_schedules.csv_imports',
    },
    {
      path: '/client/customers/:customerId',
      exact: true,
      component: CustomerDashboard,
      noMenu: true,
      permissionName: 'menu.customers',
    },
    {
      path: '/client/customers/:customerId/edit_contact_details',
      component: CustomerContactForm,
      noMenu: true,
      permissionName: 'customers.edit',
    },
    {
      path: '/client/customers/:customerId/edit_bank_and_auth_details',
      component: CustomerBankAndAuthForm,
      noMenu: true,
      permissionName: 'customers.edit',
    },
    {
      path: '/client/customers/:customerId/new_note',
      component: CustomerNoteForm,
      noMenu: true,
      permissionName: 'customer_notes.new',
    },
    {
      path: '/client/email_customer_authorization_request',
      component: EmailAuthorizationRequest,
      noMenu: true,
      permissionName: 'customers.email_auth_request',
    },
    {
      path: '/client/email_customer_authorization_select',
      component: EmailAuthorizationSelect,
      noMenu: true,
      permissionName: 'customers.email_auth_request',
    },
    {
      path: '/client/customers',
      exact: true,
      name: 'Customers',
      icon: 'mdi mdi-account-box-multiple-outline',
      component: CustomersTable,
      permissionName: 'menu.customers',
    },
    {
      path: '/client/customers/:customerId/new_transaction_schedule',
      component: TransactionScheduleForm,
      noMenu: true,
      permissionName: 'transaction_schedules.new',
    },
    {
      path: '/client/customers/:customerId/edit_transaction_schedule/:transactionScheduleId',
      component: TransactionScheduleForm,
      noMenu: true,
      permissionName: 'transaction_schedules.edit',
    },
    {
      path: '/client/customers/:customerId/transaction_history/:financialTransactionId',
      component: TransactionHistoryDetails,
      noMenu: true,
      permissionName: 'menu.customers',
    },
    {
      path: '/client/integrations',
      name: 'Integrations',
      icon: 'mdi mdi-transit-connection-variant',
      component: IntegrationManager,
      permissionName: 'menu.integrations',
    },
    {
      path: '/client/processing_authorization_settings/edit_logo',
      component: AuthLogoEditor,
      permissionName: 'authorization_settings.edit_authorization_settings',
      noMenu: true,
    },
    {
      path: '/client/authorizations/settings',
      name: 'Authorization Settings',
      icon: authorizationGreyIcon,
      component: AuthorizationSettingsDecider,
      permissionName: 'authorization_settings.edit_authorization_settings',
      noMenu: true,
    },
    {
      path: '/client/authorizations',
      name: 'Authorizations',
      icon: authorizationGreyIcon,
      component: AuthorizationPage,
      permissionName: 'menu.authorizations',
    },
    {
      path: '/client/donor_authorization_settings/edit_logo',
      component: AuthLogoEditor,
      permissionName: 'authorization_settings.edit_authorization_settings',
      noMenu: true,
    },
    {
      path: 'divider',
      name: 'divider',
      component: null,
    },
    {
      path: '/client/transaction_report',
      name: 'Transaction Report',
      icon: 'mdi mdi-bank-transfer-out',
      component: TransactionReportTable,
      permissionName: 'menu.transaction_report',
    },
    {
      path: '/client/settlements/:settlementId',
      component: SettlementDetails,
      noMenu: true,
      permissionName: 'menu.settlements',
    },
    {
      path: '/client/settlements',
      name: 'Settlements',
      icon: 'mdi mdi-chart-bar',
      component: Settlements,
      permissionName: 'menu.settlements',
    },
    {
      path: '/client/settings/users',
      exact: true,
      name: 'Users',
      icon: 'mdi mdi-account-multiple-outline',
      component: UsersView,
      noMenu: true,
      permissionName: 'settings.users',
    },
    {
      path: '/client/settings/new_user',
      exact: true,
      component: UserForm,
      noMenu: true,
      permissionName: 'users.new',
    },
    {
      path: '/client/settings/edit_user/:userId',
      component: UserForm,
      noMenu: true,
      permissionName: 'users.edit',
    },
    {
      path: '/client/settings/notification_settings',
      exact: true,
      name: 'Notification Settings',
      component: NotificationSettingsPage,
      noMenu: true,
      permissionName: 'customers.update_customer_notifications',
      releaseHelperName: 'customer_notifications_released',
    },
    {
      path: '/client/microdeposits',
      name: 'Microdeposits',
      icon: microdepositGreyIcon,
      component: MicrodepositsTable,
      permissionName: 'menu.microdeposits',
    },
    {
      path: '/client/settings/api_keys',
      name: 'API Keys',
      icon: 'mdi mdi-api',
      component: ApiKeysView,
      noMenu: true,
      permissionName: 'settings.api_keys',
    },
    {
      path: '/client/settings/invoices/:invoiceId/declined_transactions',
      name: 'Declined Transactions',
      icon: 'mdi mdi-bank-transfer-out',
      component: DeclinedTransactionReport,
      noMenu: true,
      permissionName: 'menu.transaction_report',
    },
    {
      path: '/client/settings/invoices',
      name: 'Invoices',
      icon: 'mdi mdi-api',
      component: InvoicesView,
      noMenu: true,
      permissionName: 'settings.read_billing_invoices',
    },
    {
      path: 'divider',
      name: 'divider',
      component: null,
    },
    {
      path: '/client/settings',
      exact: true,
      name: 'Settings',
      icon: 'mdi mdi-settings',
      component: BusinessInfoForm,
      permissionName: 'menu.settings',
    },
  ],
  [CLIENT_STATUS.closed]: [
    {
      path: '/client/customers',
      exact: true,
      name: 'Customers',
      icon: 'mdi mdi-account-box-multiple-outline',
      component: CustomersTable,
      permissionName: 'menu.customers',
    },
    {
      path: '/client/customers/:customerId',
      exact: true,
      component: CustomerDashboard,
      noMenu: true,
      permissionName: 'menu.customers',
    },
    {
      path: '/client/customers/:customerId/transaction_history/:financialTransactionId',
      component: TransactionHistoryDetails,
      noMenu: true,
      permissionName: 'menu.customers',
    },
    {
      path: '/client/transaction_report',
      name: 'Transaction Report',
      icon: 'mdi mdi-bank-transfer-out',
      component: TransactionReportTable,
      permissionName: 'menu.transaction_report',
    },
    {
      path: '/client/settlements/:settlementId',
      component: SettlementDetails,
      noMenu: true,
      permissionName: 'menu.settlements',
    },
    {
      path: '/client/settlements',
      name: 'Settlements',
      icon: 'mdi mdi-chart-bar',
      component: Settlements,
      permissionName: 'menu.settlements',
    },
    {
      path: '/client/settings',
      exact: true,
      name: 'Settings',
      icon: 'mdi mdi-settings',
      component: BusinessInfoForm,
      permissionName: 'menu.settings',
    },
    {
      path: '/client/settings/users',
      exact: true,
      name: 'Users',
      icon: 'mdi mdi-account-multiple-outline',
      component: UsersView,
      noMenu: true,
      permissionName: 'settings.users',
    },
    {
      path: '/client/settings/new_user',
      exact: true,
      component: UserForm,
      noMenu: true,
      permissionName: 'users.new',
    },
    {
      path: '/client/settings/edit_user/:userId',
      component: UserForm,
      noMenu: true,
      permissionName: 'users.edit',
    },
    {
      path: '/client/settings/invoices',
      name: 'Invoices',
      icon: 'mdi mdi-api',
      component: InvoicesView,
      noMenu: true,
      permissionName: 'settings.read_billing_invoices',
    },
  ],
}

export const getClientRoutes = (client: Client, allowNoClientRoutes = false) => {
  let routes: RouteConfig[] = []
  if (client && client.status) {
    routes = CLIENT_STATUS_ROUTES[client.status]
  } else {
    routes = allowNoClientRoutes ? NO_CLIENT_ROUTES : []
  }

  return (
    routes?.filter((route) => {
      if (route.name === 'divider') return true
      if (
        route.releaseHelperName &&
        ((route.useNoClientReleaseHelper && !isReleased(route.releaseHelperName)) ||
          !isReleased(route.releaseHelperName, client))
      )
        return false
      return !route.permissionName || isPermitted(route.permissionName, client)
    }) || []
  )
}
