import React, { useState } from 'react'
import DOMPurify from 'dompurify'

import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Row, Col, CustomInput } from 'reactstrap'
import { useSelector } from 'react-redux'

import { DonorAuthAcceptTermsCheckbox } from './donorAuthAcceptTermsCheckbox'
import { getEntities, getTransactionFrequencies } from 'app/selectors'
import { useTranslation } from 'react-i18next'
import { PrependAvDatePicker } from 'components/Datepicker'
import { Errors } from 'hooks/useFormServerErrors'
import { PrependAvField } from 'components/PrependAvField'
import { useIsMobile } from 'hooks/useIsMobile'

export interface DonorAuthDonationInfoProps {
  onSubmit: Function
  validate: Function
  handleFieldChange: Function
  serverErrors: Errors
}

export const DonorAuthDonationInfo = (props: DonorAuthDonationInfoProps) => {
  const donorAuthInfo = useSelector((state) => getEntities(state).donorAuthInfo)
  const transactionFrequencies = useSelector(getTransactionFrequencies)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [frequency, setFrequency] = useState('')
  const [processDate, setProcessDate] = useState<Date>()
  const [indefinite, setIndefinite] = useState(false)
  const [installments, setInstallments] = useState<number>()
  const isMobile = useIsMobile()

  const commentDescription = () => ({ __html: DOMPurify.sanitize(donorAuthInfo.step.comment_description) })

  const { t } = useTranslation()

  const isSpecialFrequency = [transactionFrequencies.once].indexOf(frequency) !== -1
  const isEmailAuthorization = donorAuthInfo.step.email_message ? true : false

  const handleIndefiniteChange = (e) => {
    if (!indefinite) {
      setInstallments(undefined)
      ;(document.getElementById('installments') as HTMLInputElement).value = ''
    }
    setIndefinite((oldIndefinite) => {
      return !oldIndefinite
    })
  }

  const calculateInstallments = () => {
    let calculatedInstallments = installments
    if (isSpecialFrequency) {
      calculatedInstallments = 1
    } else if (indefinite) {
      calculatedInstallments = 0
    }
    return calculatedInstallments
  }

  return (
    <>
      <AvForm
        className="donation-form"
        onValidSubmit={(_event, values) =>
          props.onSubmit({ ...values, processDate: processDate, installments: calculateInstallments() })
        }
      >
        <PrependAvField
          name="amount"
          label={<i className="mdi mdi-currency-usd" />}
          placeholder={t('authorization.donation.donation_info.amount')}
          type="text"
          required
          inputMode="decimal"
          onChange={(e) => {
            props.handleFieldChange(e)
          }}
          autoComplete="off"
          validate={{
            number: { value: true, errorMessage: t('invalid_field') },
            pattern: { value: '/^[0-9]+.?[0-9]{0,2}$/', errorMessage: t('invalid_field') },
            required: {
              value: true,
              errorMessage: t('required_field'),
            },
            min: {
              value: 1,
              errorMessage: t('min_number', { number: 1 }),
            },
            async: props.validate,
          }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
        <PrependAvDatePicker
          name="processDate"
          label={<i className="mdi mdi-calendar-blank" />}
          value={processDate}
          onChange={(v) => {
            setProcessDate(v)
          }}
          required
          serverErrors={props.serverErrors && props.serverErrors['process_date']}
          inputMode={isMobile ? 'none' : 'text'}
          placeholder={t('authorization.donation.donation_info.process_date')}
        />
        <div className="donation-select">
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <PrependAvField
            name="frequency"
            label={<i className="mdi mdi-clock-outline" />}
            placeholder={t('authorization.donation.donation_info.frequency')}
            value={frequency}
            type="select"
            required
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
              async: props.validate,
            }}
            onChange={(e, v) => {
              props.handleFieldChange(e)
              setFrequency(v)
            }}
            onWheel={(e) => e.target.blur()}
            appendBottomLightBackground={true}
            appendBottom={
              !(frequency === 'Once' || frequency === '') && (
                <div className="custom-disabled d-flex flex-column pt-1">
                  <div className="d-flex mb-1">
                    <div className="d-flex align-items-center mb-1" style={{ height: 'calc(3rem + 2px)' }}>
                      <CustomInput
                        type="radio"
                        name="indefinite"
                        id="indefinite_false"
                        value="false"
                        checked={!indefinite}
                        onChange={(e) => {
                          handleIndefiniteChange(e)
                        }}
                      />
                    </div>
                    <AvField
                      name="installments"
                      formControlNarrow
                      placeholder={t('authorization.donation.donation_info.installments')}
                      className="form-control-small-font override-prepend"
                      type="text"
                      inputMode="numeric"
                      groupAttrs={{ className: 'mb-0', style: { 'text-align': 'start' } }}
                      disabled={isSpecialFrequency || indefinite}
                      value={installments}
                      autoComplete="off"
                      onChange={(e) => {
                        setInstallments(e.target.value)
                        props.handleFieldChange(e)
                      }}
                      validate={
                        !indefinite && !isSpecialFrequency
                          ? {
                              number: { value: true, errorMessage: t('invalid_field') },
                              pattern: { value: '/^[0-9]+$/', errorMessage: t('invalid_field') },
                              required: {
                                value: true,
                                errorMessage: t('required_field'),
                              },
                              min: {
                                value: 1,
                                errorMessage: t('min_number', { number: 1 }),
                              },
                              async: props.validate,
                            }
                          : {
                              async: props.validate,
                            }
                      }
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  <CustomInput
                    type="radio"
                    id="indefinite_true"
                    name="indefinite"
                    value="true"
                    label={
                      <span className={!indefinite ? 'text-muted' : ''}>
                        {t('authorization.donation.donation_info.ongoing')}
                      </span>
                    }
                    labelClass={!indefinite && ' text-muted'}
                    className="text-left mb-1"
                    checked={indefinite}
                    onChange={(e) => {
                      handleIndefiniteChange(e)
                    }}
                  />
                </div>
              )
            }
          >
            <option value="" disabled selected hidden>
              {t('authorization.donation.donation_info.frequency')}
            </option>
            {transactionFrequencies &&
              Object.values<string>(transactionFrequencies).map(
                (f, i) =>
                  f !== transactionFrequencies.twice && (
                    <option key={i} value={f}>
                      {f}
                    </option>
                  ),
              )}
          </PrependAvField>
        </div>
        <div>
          <PrependAvField
            name="comment"
            label={<i className="mdi mdi-message-outline" />}
            placeholder={t('authorization.donation.donation_info.comment')}
            appendBottom={
              donorAuthInfo.step.enable_comment_description &&
              donorAuthInfo.step.comment_description && (
                <span className="comment-description" dangerouslySetInnerHTML={commentDescription()} />
              )
            }
            type="text"
            onChange={(e) => {
              props.handleFieldChange(e)
            }}
            validate={{ async: props.validate }}
          />
        </div>
        <PrependAvField
          name="email"
          label={<i className="mdi mdi-email-outline" />}
          placeholder={t('contact.email')}
          type="email"
          required
          value={donorAuthInfo.step.email}
          disabled={isEmailAuthorization}
          validate={{
            async: props.validate,
            email: {
              value: true,
              errorMessage: t('contact.email_error'),
            },
            required: {
              value: true,
              errorMessage: t('required_field'),
            },
          }}
          onChange={(e) => {
            props.handleFieldChange(e)
          }}
        />
        {!donorAuthInfo.step.email_message && (
          <>
            <PrependAvField
              name="firstName"
              label={<i className="mdi mdi-account-outline" />}
              type="text"
              placeholder={t('contact.first_name')}
              value={firstName}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: t('required_field'),
                },
                async: props.validate,
              }}
              onChange={(e, v) => {
                props.handleFieldChange(e)
                setFirstName(v)
              }}
            />
            <PrependAvField
              name="lastName"
              label={<i className="mdi mdi-account-outline" />}
              type="text"
              placeholder={t('contact.last_name')}
              value={lastName}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: t('required_field'),
                },
                async: props.validate,
              }}
              onChange={(e, v) => {
                props.handleFieldChange(e)
                setLastName(v)
              }}
            />
          </>
        )}

        <Row className="mb-2">
          <Col>
            <DonorAuthAcceptTermsCheckbox
              businessName={donorAuthInfo.client.businessName}
              terms={donorAuthInfo.step.terms_and_conditions || ''}
            />
          </Col>
        </Row>
        <div className="text-center">
          <Button className="continue-btn" color="primary">
            {t('authorization.donation.common.donate')} <i className="mdi mdi-arrow-right-thick" />
          </Button>
        </div>
      </AvForm>
    </>
  )
}
