import React, { useState, useEffect } from 'react'

import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import { Button, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap'
import { useSelector } from 'react-redux'

import { ProcessingAcceptTermsCheckbox } from './processingAuthAcceptTermsCheckbox'
import { getEntities } from 'app/selectors'
import { StateProvinceSelect } from 'components/ProvinceSelect'
import { PostalCodeField } from 'components/PostalCodeField'
import { ProcessingAuthHeader } from './processingAuthHeader'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { validatePhone } from 'utilities'

export interface AuthContactInfoProps {
  onSubmit: Function
  validate: Function
  handleFieldChange: Function
}

export const ProcessingAuthContactInfo = (props: AuthContactInfoProps) => {
  const processingAuthInfo = useSelector((state) => getEntities(state).processingAuthInfo)
  const [personalNameField, setPersonalNameField] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const { t } = useTranslation()

  const swapNameField = () => {
    setFirstName('')
    setLastName('')
    setCompanyName('')
  }

  useEffect(() => {
    setPersonalNameField(processingAuthInfo.step.name_default === 'Personal')
  }, [processingAuthInfo.step.name_default])

  useEffect(() => {
    swapNameField()
  }, [personalNameField])

  return (
    <AvForm onValidSubmit={(_event, values) => props.onSubmit(values)} className="gray-input-background">
      <ProcessingAuthHeader />
      {processingAuthInfo.step.require_name && (
        <>
          <AvField name="personalName" hidden value={personalNameField} />
          {personalNameField && (
            <>
              <AvField
                name="firstName"
                type="text"
                label={t('contact.first_name')}
                placeholder={t('contact.first_name')}
                value={firstName}
                required
                validate={{
                  required: {
                    value: true,
                    errorMessage: t('required_field'),
                  },
                  async: props.validate,
                }}
                onChange={(e, v) => {
                  props.handleFieldChange(e)
                  setFirstName(v)
                }}
              />
              <AvField
                name="lastName"
                type="text"
                label={t('contact.last_name')}
                placeholder={t('contact.last_name')}
                value={lastName}
                required
                validate={{
                  required: {
                    value: true,
                    errorMessage: t('required_field'),
                  },
                  async: props.validate,
                }}
                onChange={(e, v) => {
                  props.handleFieldChange(e)
                  setLastName(v)
                }}
                groupAttrs={{ className: 'mb-0' }}
              />
              <div className="mb-2">
                <Link
                  className="input-swap-link"
                  to={() => ({})}
                  onClick={() => setPersonalNameField(!personalNameField)}
                >
                  {t('contact.use_business_name')}
                </Link>
              </div>
            </>
          )}
          {!personalNameField && (
            <>
              <Row className="mb-0-form-group">
                <Col>
                  <AvField
                    name="companyName"
                    type="text"
                    label={t('contact.business_name')}
                    placeholder={t('contact.business_name')}
                    value={companyName}
                    required
                    validate={{
                      required: {
                        value: true,
                        errorMessage: t('required_field'),
                      },
                      async: props.validate,
                    }}
                    onChange={(e, v) => {
                      props.handleFieldChange(e)
                      setCompanyName(v)
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Link
                    className="input-swap-link"
                    to={() => ({})}
                    onClick={() => setPersonalNameField(!personalNameField)}
                  >
                    {t('contact.use_personal_name')}
                  </Link>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      {processingAuthInfo.step.require_email && (
        <AvField
          name="email"
          type="text"
          label={t('contact.email')}
          placeholder="ex@mple.com"
          required
          validate={{
            required: {
              value: true,
              errorMessage: t('required_field'),
            },
            async: props.validate,
            email: {
              value: true,
              errorMessage: t('contact.email_error'),
            },
          }}
          onChange={props.handleFieldChange}
        />
      )}
      {processingAuthInfo.step.require_phone && (
        <AvField
          name="phone"
          type="text"
          label={t('contact.phone_number')}
          placeholder="(123) 456-7890"
          required
          validate={{
            required: {
              value: true,
              errorMessage: t('required_field'),
            },
            async: props.validate,
            tel: validatePhone,
          }}
          onChange={props.handleFieldChange}
        />
      )}

      {processingAuthInfo.step.require_address && (
        <>
          <AvField
            name="address.address1"
            label={t('contact.street')}
            type="text"
            required
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
              async: props.validate,
            }}
            onChange={props.handleFieldChange}
          />
          <AvField
            name="address.city"
            label={t('contact.city')}
            type="text"
            required
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
              async: props.validate,
            }}
            onChange={props.handleFieldChange}
          />
          <StateProvinceSelect
            required
            name="address.provinceCode"
            countryCode={processingAuthInfo?.client?.countryCode}
            serverErrors={processingAuthInfo?.errors}
          />
          <PostalCodeField name="address.postalCode" countryCode={processingAuthInfo?.client?.countryCode} />
        </>
      )}

      {(processingAuthInfo?.step?.require_authorization_amount || processingAuthInfo?.client?.countryCode === 'US') && (
        <Row className="mb-3">
          <Col>
            <h4 className="header-title">{t('contact.max_amount')}</h4>
            {t('contact.us_max_description', { business_name: processingAuthInfo?.client?.businessName })}
            <InputGroup>
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <AvInput
                name="authorizationAmount"
                type="number"
                required
                validate={{
                  required: {
                    value: true,
                    errorMessage: t('contact.us_max_error'),
                  },
                  async: props.validate,
                }}
                onChange={props.handleFieldChange}
              />
            </InputGroup>
          </Col>
        </Row>
      )}

      <Row className="mb-2">
        <Col>
          <ProcessingAcceptTermsCheckbox
            businessName={processingAuthInfo.client.businessName}
            terms={processingAuthInfo.step.terms_and_conditions || ''}
          />
        </Col>
      </Row>
      <div className="text-center">
        <Button color="primary" className="w-100">
          {t('continue')}
        </Button>
      </div>
    </AvForm>
  )
}
