// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetClient200Response
 */
export interface GetClient200Response  {
    /**
     * 
     * @type {Client}
     * @memberof GetClient200Response
     */
    client?: Client;
}

export function GetClient200ResponseFromJSON(json: any): GetClient200Response {
    return {
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
    };
}

export function GetClient200ResponseToJSON(value?: GetClient200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client': ClientToJSON(value.client),
    };
}


