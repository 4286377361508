// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BusinessCategory
 */
export interface BusinessCategory  {
    /**
     * 
     * @type {string}
     * @memberof BusinessCategory
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessCategory
     */
    subcategories?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCategory
     */
    customSubcategory?: boolean;
}

export function BusinessCategoryFromJSON(json: any): BusinessCategory {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'subcategories': !exists(json, 'subcategories') ? undefined : json['subcategories'],
        'customSubcategory': !exists(json, 'custom_subcategory') ? undefined : json['custom_subcategory'],
    };
}

export function BusinessCategoryToJSON(value?: BusinessCategory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'subcategories': value.subcategories,
        'custom_subcategory': value.customSubcategory,
    };
}


