import React, { useState, useEffect } from 'react'
import { unsubscribedByUuid } from 'api'
import { Loading } from 'components/Loading'
import { PoweredByRotessaFooter } from 'components/PoweredByRotessa'
import { useIsMobile } from 'hooks/useIsMobile'
import { useTranslation } from 'react-i18next'

import { Link, useLocation, useParams } from 'react-router-dom'
import { Card, CardBody, CardFooter, Fade } from 'reactstrap'
import { useRequest } from 'redux-query-react'
import i18n from '_i18n/i18n'
import { getEntities } from 'app/selectors'
import { useSelector } from 'react-redux'

export const CustomerUnsubscribePage = (): JSX.Element => {
  const { uuid } = useParams() as any
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const query = new URLSearchParams(useLocation().search)
  const [language, setLanguage] = useState(
    query.get('language') || (navigator.language.split('-')[0] === 'fr' ? 'fr' : 'en'),
  )
  const notificationType = query.get('type') || ''
  const storeKey = `notificationType${notificationType}`
  const unsubscribeState = useSelector((state) => getEntities(state)[storeKey]) || []

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const [requestState, _doRequest] = useRequest(
    unsubscribedByUuid(
      {
        uuid: uuid,
        type: notificationType,
      },
      {
        transform: (response) => {
          return { [storeKey]: { alreadyUnsubscribed: response.alreadyUnsubscribed } }
        },
        update: {
          [storeKey]: (oldValue, newValue) => newValue,
        },
      },
    ),
  )

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'fr' : 'en')
  }

  let content
  let footer = t('notifications.unsubscribe.footer_success')

  if (requestState.isPending) {
    return <Loading />
  }

  if (requestState.status === 400) {
    content = (
      <div className="text-center">
        <h2 className="text-danger">{t('notifications.unsubscribe.header_error')}</h2>
        <p>{t('notifications.unsubscribe.body_error')}</p>
      </div>
    )

    footer = t('notifications.unsubscribe.footer_error')
  } else {
    if (unsubscribeState.alreadyUnsubscribed) {
      content = (
        <div className="text-center">
          <h2 className="text-primary">{t('notifications.unsubscribe.header_already_unsubscribed')}</h2>
          <p>
            {t('notifications.unsubscribe.body_already_unsubscribed', {
              notification_type: t('notifications.notification_types.' + notificationType),
            })}
          </p>
        </div>
      )
    } else {
      content = (
        <div className="text-center">
          <h2 className="text-primary">{t('notifications.unsubscribe.header_success')}</h2>
          <p>
            {t('notifications.unsubscribe.body_success', {
              notification_type: t('notifications.notification_types.' + notificationType),
            })}
          </p>
        </div>
      )
    }
  }

  return (
    (requestState.isPending && <Loading />) || (
      <div className={isMobile ? '' : 'mt-4'}>
        <div style={{ maxWidth: 400, margin: 'auto' }}>
          <Fade>
            <Card className="loading-container-min-height processing-content">
              <CardBody>
                <div>{content}</div>
              </CardBody>
              <CardFooter className="text-center">{footer}</CardFooter>
            </Card>
            <div className="text-center mt-3">
              <span style={{ cursor: 'pointer' }} onClick={toggleLanguage}>
                <u>{t('language_toggle')}</u>
              </span>
            </div>
            <PoweredByRotessaFooter type="blue_footer" source="unsubscribePage" />
          </Fade>
        </div>
      </div>
    )
  )
}
