// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectCustomersCustomersInner,
    ConnectCustomersCustomersInnerFromJSON,
    ConnectCustomersCustomersInnerToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConnectCustomers
 */
export interface ConnectCustomers  {
    /**
     * 
     * @type {number}
     * @memberof ConnectCustomers
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectCustomers
     */
    filter?: string;
    /**
     * 
     * @type {Array<ConnectCustomersCustomersInner>}
     * @memberof ConnectCustomers
     */
    customers?: Array<ConnectCustomersCustomersInner>;
}

export function ConnectCustomersFromJSON(json: any): ConnectCustomers {
    return {
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
        'customers': !exists(json, 'customers') ? undefined : (json['customers'] as Array<any>).map(ConnectCustomersCustomersInnerFromJSON),
    };
}

export function ConnectCustomersToJSON(value?: ConnectCustomers): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client_id': value.clientId,
        'filter': value.filter,
        'customers': value.customers === undefined ? undefined : (value.customers as Array<any>).map(ConnectCustomersCustomersInnerToJSON),
    };
}


