import React from 'react'
import { Alert } from 'reactstrap'

export interface FormServerNotificationsProps {
  notifications: (string | JSX.Element)[]
  className?: string
}

export const FormServerNotifications = ({ notifications, className }: FormServerNotificationsProps) => {
  return (
    <>
      {notifications.map((error, i) => (
        <Alert color="warning" key={i} className={className}>
          {error}
        </Alert>
      ))}
    </>
  )
}
