// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SettlementTransactionsClientOnboardingSettlementAccount,
    SettlementTransactionsClientOnboardingSettlementAccountFromJSON,
    SettlementTransactionsClientOnboardingSettlementAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettlementTransactionsClientOnboarding
 */
export interface SettlementTransactionsClientOnboarding  {
    /**
     * 
     * @type {SettlementTransactionsClientOnboardingSettlementAccount}
     * @memberof SettlementTransactionsClientOnboarding
     */
    settlementAccount?: SettlementTransactionsClientOnboardingSettlementAccount;
}

export function SettlementTransactionsClientOnboardingFromJSON(json: any): SettlementTransactionsClientOnboarding {
    return {
        'settlementAccount': !exists(json, 'settlement_account') ? undefined : SettlementTransactionsClientOnboardingSettlementAccountFromJSON(json['settlement_account']),
    };
}

export function SettlementTransactionsClientOnboardingToJSON(value?: SettlementTransactionsClientOnboarding): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'settlement_account': SettlementTransactionsClientOnboardingSettlementAccountToJSON(value.settlementAccount),
    };
}


