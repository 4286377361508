import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'
import { Link, useHistory } from 'react-router-dom'
import { Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap'
import { User, removeUser, searchUsers, updateUser } from 'api'
import { getActiveClient, getUsers, getUsersCount } from 'app/selectors'
import { ColumnProps, ActionFormatterFactory, DateFormatter, Table, OnTableChangeProps } from 'components/Table'
import { TableHeader } from 'components/TableHeader'
import { ROLES } from 'app/constants'
import { getSort, isPermitted } from 'utilities'
import { Errors } from 'hooks/useFormServerErrors'
import { FormServerErrors } from 'components/FormServerErrors'

const linkToEditFormatter = (cell, row) => {
  return (
    <Link to={`edit_user/${row.userId}`}>
      <span>{cell}</span>
    </Link>
  )
}

const RoleFormatter = (cell) => {
  const role = ROLES.find((r) => r.value === cell) || { label: cell, color: '' }

  if (cell === 'client_primary') {
    const adminRole = ROLES.find((r) => r.value === 'client_admin') || { label: cell, color: '' }
    return (
      <>
        <Badge pill color={adminRole.color} className="status-badge mr-1">
          {adminRole.label}
        </Badge>
        <Badge pill color={role.color} className="status-badge mr-1">
          {role.label}
        </Badge>
      </>
    )
  }
  return (
    <Badge pill color={role.color} className="status-badge mr-1">
      {role.label}
    </Badge>
  )
}

export interface UsersTableProps {
  condensedView?: boolean
}

export const UsersTable = ({ condensedView }: UsersTableProps) => {
  const [search, setSearch] = useState('')
  const [userToDelete, setUserToDelete] = useState<User>()
  const [userToTransferPrimary, setUserToTransferPrimary] = useState<User>()
  const activeClient = useSelector(getActiveClient) || {}
  const users = useSelector(getUsers) || []
  const usersCount = useSelector(getUsersCount)
  const history = useHistory()
  const [serverErrors, setServerErrors] = useState<Errors>()
  const PAGE_SIZE_OPTIONS = [5, 15, 25]

  const [tableProps, setTableProps] = useState({
    page: 1,
    pageSize: condensedView == true ? PAGE_SIZE_OPTIONS[0] : PAGE_SIZE_OPTIONS[2],
    sortField: 'firstName',
    sortOrder: 'asc',
  })

  const [requestState, doRequest] = useRequest(
    !activeClient?.id
      ? null
      : searchUsers(
          {
            clientId: activeClient.id,
            search: search,
            skip: (tableProps.page - 1) * tableProps.pageSize,
            limit: tableProps.pageSize,
            sort: getSort(tableProps.sortField, tableProps.sortOrder),
          },
          {
            force: true,
            transform: (response) => {
              return {
                users: response.records,
                usersCount: response.count,
              }
            },
            update: {
              users: (_, newValue) => newValue,
              usersCount: (_, newValue) => newValue,
            },
          },
        ),
  )

  const onTableChange = (_type: string, props: OnTableChangeProps) => {
    setTableProps({
      page: props.page || tableProps.page,
      pageSize: props.sizePerPage || tableProps.pageSize,
      sortField: props.sortField || tableProps.sortField,
      sortOrder: props.sortOrder || tableProps.sortOrder,
    })
  }

  const [removeUserQueryState, doRemoveUser] = useMutation((userId: number) => removeUser({ id: userId }))

  const [transferPrimaryQueryState, doTransferPrimary] = useMutation((user: User) => {
    return updateUser({
      id: user!.roleId!,
      user: user,
    })
  })

  const columns: ColumnProps[] = [
    {
      dataField: 'firstName',
      text: 'First Name',
      sort: true,
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'role',
      formatter: RoleFormatter,
      text: 'Role',
      sort: true,
    },
    {
      dataField: 'lastSignInAt',
      formatter: DateFormatter,
      text: 'Last Sign In',
      sort: true,
      hidden: condensedView || false,
    },
    {
      dataField: '',
      text: '',
      formatter: ActionFormatterFactory([
        {
          name: 'Edit',
          onClick: (row) => {
            history.push(`/client/settings/edit_user/${row.userId}`)
          },
        },
        {
          hidden: (row) => {
            return !(
              row.role === 'client_admin' && (isPermitted('users.transfer_primary_contact', activeClient) as boolean)
            )
          },
          name: <span className="text-info">Set as Primary Contact</span>,
          onClick: (rowUser) => {
            setUserToTransferPrimary(rowUser)
          },
        },
        {
          hidden: (row) => {
            return row.role === 'client_primary'
          },
          name: <span className="text-danger">Remove</span>,
          onClick: (rowUser) => {
            setUserToDelete(rowUser)
          },
        },
      ]),
      sort: false,
      align: 'right',
      classes: 'sticky-right',
    },
  ]

  function handleSearchChange(event) {
    setSearch(event.target.value)
  }

  function handleClickDelete() {
    if (userToDelete?.roleId) {
      doRemoveUser(userToDelete.roleId)?.then(() => doRequest())
    } else {
      doRequest()
    }
    setUserToDelete(undefined)
  }

  const handleClickTransferPrimary = () => {
    if (userToTransferPrimary?.roleId) {
      let user = { ...userToTransferPrimary, role: 'client_primary' }
      doTransferPrimary(user)?.then((response) => {
        setServerErrors(response?.body?.messages || [])
        doRequest()
      })
    } else {
      doRequest()
    }
    setUserToTransferPrimary(undefined)
  }

  let formErrors: string[] = []
  for (const error in serverErrors) {
    formErrors.push(serverErrors[error][0])
  }
  return (
    <>
      <Modal isOpen={userToDelete?.roleId !== undefined}>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>Are you sure you want to remove {userToDelete?.email}?</ModalBody>
        <ModalFooter className="text-right">
          <Button onClick={() => setUserToDelete(undefined)} className="btn-rotessa-secondary min-width">
            No
          </Button>
          <Button color="primary" onClick={handleClickDelete} className="ml-2">
            Yes
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={userToTransferPrimary?.roleId !== undefined}>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>
          Are you sure you want to set {userToTransferPrimary?.email} as the primary contact? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter className="text-right">
          <Button onClick={() => setUserToTransferPrimary(undefined)} className="btn-rotessa-secondary min-width">
            No
          </Button>
          <Button color="primary" onClick={handleClickTransferPrimary} className="ml-2">
            Yes
          </Button>
        </ModalFooter>
      </Modal>

      <FormServerErrors errors={formErrors} />
      <TableHeader
        newLink={'/client/settings/new_user'}
        onChangeSearch={handleSearchChange}
        hideImport={true}
        hideExport={true}
      />
      <Table
        data={users}
        loading={requestState.isPending}
        columns={columns}
        paginationProps={{
          page: tableProps.page,
          totalSize: usersCount,
          sizePerPage: tableProps.pageSize,
          fillHeight: condensedView ? false : true,
        }}
        keyField="userId"
        sortField={tableProps.sortField}
        sortOrder={tableProps.sortOrder}
        onTableChange={onTableChange}
      />
    </>
  )
}

export const UsersView = () => {
  return (
    <>
      <div className="page-title-box">
        <h4 className="page-title">Users</h4>
      </div>
      <Card>
        <CardBody>
          <UsersTable />
        </CardBody>
      </Card>
    </>
  )
}
