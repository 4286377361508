import React, { useEffect, useState } from 'react'
import { useMutation, useRequest } from 'redux-query-react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { requestAsync, updateEntities } from 'redux-query'
import { Button, Col, Row, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label } from 'reactstrap'

import { getActiveClient, getEntities } from 'app/selectors'
import { ColumnProps, OnTableChangeProps } from 'components/Table'
import {
  connectIntegrationInvoices,
  getIntegrationInvoices,
  GetIntegrationInvoices200ResponseFromJSON,
  IntegrationInvoice,
} from 'api'
import { FormServerErrors } from 'components/FormServerErrors'
import { LoadingMaskCentered } from 'components/LoadingMask'
import { formatDateGreg, utcSafeDate, truncateText } from 'utilities'
import { integrationName } from 'components/Integrations'
import { TableSearchField } from 'components/TableHeader'
import { SideBySideTables } from 'components/SideBySideTables'
import { TitleBar } from 'features/customers/customerFlow/titleBar'
import { ToolTip } from 'components/ToolTip'
import RotessaDatepicker from 'components/Datepicker'
import actionRequired from 'assets/images/customer_dashboard_icons/Action-required-icon-2.png'

export const IntegrationInvoices = () => {
  const dispatch = useDispatch()

  const history = useHistory()
  const [search, setSearch] = useState('')
  const activeClient = useSelector(getActiveClient)

  const unimportedIntegrationInvoices = useSelector((state) => getEntities(state).unimportedIntegrationInvoices) || {}
  const importedIntegrationInvoices = useSelector((state) => getEntities(state).importedIntegrationInvoices) || {}

  const [unimportedInvoicesList, setUnimportedInvoicesList] = useState(
    [] as (IntegrationInvoice & { selected?: Boolean })[],
  )
  const [importedInvoicesList, setImportedInvoicesList] = useState(
    [] as (IntegrationInvoice & { selected?: Boolean })[],
  )
  const [loading, setLoading] = useState(false)
  const [invoicesToImport, setInvoicesToImport] = useState([] as IntegrationInvoice[])
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [serverErrors, setServerErrors] = useState([] as string[])
  const [responseStatus, setResponseStatus] = useState(0)
  const [invoiceImportedTotal, setInvoiceImportTotal] = useState(0)
  const [tableProps, setTableProps] = useState({
    page: 1,
    selectedRows: [],
    sortField: 'name',
    sortOrder: 'asc',
  })

  const [unimportedInvoicesRequestState, _doUnimportedInvoicesRequest] = useRequest(
    activeClient?.id &&
      getIntegrationInvoices(
        {
          clientId: activeClient.id,
          startDate: startDate,
          endDate: endDate,
          search: search,
          filter: 'not_imported',
        },
        {
          force: true,
          transform: (body) => {
            return { unimportedIntegrationInvoices: body }
          },
          update: { unimportedIntegrationInvoices: (_, newValue) => newValue },
        },
      ),
  )

  const [importedInvoicesRequestState, doImportedInvoicesRequest] = useRequest(
    activeClient?.id &&
      getIntegrationInvoices(
        {
          clientId: activeClient.id,
          startDate: startDate,
          endDate: endDate,
          filter: 'imported',
        },
        {
          force: true,
          transform: (body) => {
            return { importedIntegrationInvoices: body }
          },
          update: { importedIntegrationInvoices: (_, newValue) => newValue },
        },
      ),
  )

  const handleStartDateSubmit = (date) => {
    setStartDate(utcSafeDate(date))
    setInvoicesToImport([])
  }

  const handleEndDateSubmit = (date) => {
    setEndDate(utcSafeDate(date))
    setInvoicesToImport([])
  }

  useEffect(() => {
    if (unimportedInvoicesRequestState.isFinished) {
      setUnimportedInvoicesList(unimportedIntegrationInvoices.invoices)
      const unImportedIds = unimportedIntegrationInvoices.invoices.map((inv) => inv.id)
      setInvoicesToImport((prev) => {
        return prev
          .filter((inv) => unImportedIds.includes(inv.id))
          .map((invoiceToImport) => {
            const errors = unimportedIntegrationInvoices.invoices.find((inv) => inv.id === invoiceToImport.id)?.errors
            return { ...invoiceToImport, errors: errors }
          })
      })
    }

    if (importedInvoicesRequestState.isFinished) {
      setImportedInvoicesList(importedIntegrationInvoices.invoices)
    }
  }, [unimportedIntegrationInvoices.invoices, importedIntegrationInvoices.invoices])

  function getNonSelectableRows() {
    var nonSelectableRows: number[] = []
    unimportedIntegrationInvoices.invoices?.forEach((record) => {
      if (
        record.connectionStatus !== 'Ready to Create in Rotessa' ||
        (record.errors && record.errors.length !== 0 && !invoicesToImport.some((inv) => record.id === inv.id))
      ) {
        nonSelectableRows.push(record.id)
      }
    })
    return nonSelectableRows
  }

  const IntegrationCustomerDetailsFormatter = (_cell, row) => {
    return CustomerDetailsFormatter(row, row.customerName, row.customerEmail)
  }

  const RotessaCustomerDetailsFormatter = (_cell, row) => {
    return row.rotessaCustomerId ? CustomerDetailsFormatter(row, row.rotessaCustomerName, row.rotessaCustomerEmail) : ''
  }

  const CustomerDetailsFormatter = (row, name, email) => {
    let truncatedName = truncateText(name, 20)
    let status = ''
    let muted_style = ''
    if (row.connectionStatus !== 'Ready to Create in Rotessa') {
      muted_style = 'text-muted'
    }
    if (!['Ready to Create in Rotessa', 'Scheduled in Rotessa'].includes(row.connectionStatus)) {
      status = row.connectionStatus
    }

    let tooltipContent = status ? <span className="text-warning">{status}</span> : null

    if (truncatedName.endsWith('...')) {
      tooltipContent = (
        <p className="m-0">
          <span>{name}</span>
          <br />
          <span className="text-warning">{status}</span>
        </p>
      )
    }

    return (
      <p className="mb-0 align-middle" style={{ lineHeight: 1 }}>
        <ToolTip tooltipContent={tooltipContent}>
          <span className={muted_style}>{truncatedName}</span>
          {status && <img src={actionRequired} height={15} className="ml-1" style={{ marginBottom: '4px' }} alt="" />}
        </ToolTip>
        <br />
        <small className="text-medium">{email}</small>
      </p>
    )
  }

  const InvoiceFormatter = (_cell, row) => {
    var muted_style = ''
    if (importedIntegrationInvoices?.invoices?.find((i) => i.id === row.id)) {
      muted_style = ' text-muted'
      return (
        <p className={'mb-0 align-middle' + muted_style} style={{ lineHeight: 1 }}>
          {PaymentFormatter(row.rotessaAmount, row.rotessaProcessDate)}
          <br />
          <small className="text-medium">Comment: {row.rotessaComment}</small>
        </p>
      )
    } else {
      if (row.connectionStatus !== 'Ready to create in Rotessa') {
        muted_style = 'text-muted'
      }
      let errors = []
      if (row.errors?.length > 0) {
        errors = row.errors.map((error) => {
          return error === 'Api document has already been taken'
            ? 'Invoice was previously imported, only one has been scheduled.'
            : error
        })
      }
      return (
        <p className={'mb-0 align-middle' + muted_style} style={{ lineHeight: 1 }}>
          {PaymentFormatter(row.amount, row.dueDate)}
          {NewBadgeFormatter(row.id)}
          <br />
          <small className="text-medium">Invoice {row.invoiceNumber}</small>
          {errors.map((errorMessage) => (
            <>
              <br />
              <span className="text-danger">{errorMessage}</span>
            </>
          ))}
        </p>
      )
    }
  }

  const PaymentFormatter = (amount, date) => {
    return (
      <>
        <span className={'text-info mr-2'}>${amount}</span>
        <span>{formatDateGreg(date)}</span>
      </>
    )
  }

  const NewBadgeFormatter = (invoiceId) => {
    if (invoicesToImport.find((r) => r.id === invoiceId)) {
      return (
        <Badge pill color="info" className={`ml-1 badge-info-lighten`} style={{ height: 'fit-content' }}>
          NEW
        </Badge>
      )
    }
  }

  const columnsLeft: ColumnProps[] = [
    {
      dataField: 'customerName',
      text: 'Name',
      formatter: IntegrationCustomerDetailsFormatter,
      sort: false,
      classes: 'align-middle',
    },
    {
      dataField: 'invoiceNumber',
      text: 'Transaction Details',
      formatter: InvoiceFormatter,
      sort: false,
      classes: 'align-middle',
    },
  ]

  const columnsRight: ColumnProps[] = [
    {
      dataField: 'rotessaCustomerName',
      text: 'Name',
      formatter: RotessaCustomerDetailsFormatter,
      sort: false,
      classes: 'align-middle',
    },
    {
      dataField: 'rotessaInvoiceNumber',
      text: 'Transaction Details',
      formatter: InvoiceFormatter,
      sort: false,
      classes: 'align-middle',
    },
  ]

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const [mutationState, doMutation] = useMutation(() =>
    connectIntegrationInvoices({
      connectInvoices: {
        clientId: activeClient.id,
        startDate: startDate,
        endDate: endDate,
        filter: 'not_imported',
        ids: invoicesToImport.map((r: any) => r.id),
      },
    }),
  )

  const handleSubmit = () => {
    setServerErrors([])
    if (invoicesToImport.length === 0) {
      setServerErrors(['Please select at least one invoice to import'])
      return
    }
    doMutation()?.then((response) => {
      if (response?.status === 200) {
        setResponseStatus(response?.body?.status)
        setInvoiceImportTotal(response?.body?.selected_count)
        if (response?.body?.status !== 200 && response?.body?.selected_count !== response?.body?.newly_imported_count) {
          var message =
            response?.body?.selected_count -
            response?.body?.newly_imported_count +
            ' selected invoice(s) encountered errors while importing.'
          setServerErrors([message])
        } else {
          setServerErrors([response?.body?.error_message] || [])
        }
        dispatch(
          updateEntities({
            unimportedIntegrationInvoices: (_) => GetIntegrationInvoices200ResponseFromJSON(response.body),
          }),
        )
        doImportedInvoicesRequest()
      } else {
        setServerErrors(['An unknown error occured. If this continues please contact support.'])
      }
    })
  }

  function onTableChange(_type: string, { page, sizePerPage, sortField, sortOrder }: OnTableChangeProps) {
    setTableProps({
      page: page || tableProps.page,
      selectedRows: [],
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  const onSelectLeft = (row, isSelected) => {
    if (isSelected) {
      setInvoicesToImport((prev) => [...prev, row])
    } else {
      setInvoicesToImport((prev) => prev.filter((c) => c.id !== row.id))
    }
  }

  const onSelectAllLeft = (isSelected) => {
    if (isSelected) {
      setInvoicesToImport(unimportedInvoicesList.filter((inv) => !getNonSelectableRows().includes(inv.id!)))
    } else {
      setInvoicesToImport([])
    }
  }

  const handleRefreshInvoices = () => {
    setLoading(true)
    Promise.all([
      dispatch(
        requestAsync(
          activeClient?.id &&
            getIntegrationInvoices(
              {
                clientId: activeClient.id,
                startDate: startDate,
                search: search,
                filter: 'not_imported',
                force: true,
              },
              {
                force: true,
                transform: (body) => {
                  return { unimportedIntegrationInvoices: body }
                },
                update: { unimportedIntegrationInvoices: (_, newValue) => newValue },
              },
            ),
        ),
      ) as any,
      dispatch(
        requestAsync(
          activeClient?.id &&
            getIntegrationInvoices(
              {
                clientId: activeClient.id,
                startDate: startDate,
                endDate: endDate,
                filter: 'imported',
              },
              {
                force: true,
                transform: (body) => {
                  return { importedIntegrationInvoices: body }
                },
                update: { importedIntegrationInvoices: (_, newValue) => newValue },
              },
            ),
        ),
      ) as any,
    ]).then(() => setLoading(false))
  }

  const tableSearch = (
    <>
      <Row className="mb-2">
        <Col>
          <TableSearchField onChange={(event) => handleSearchChange(event)} className="w-100" />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={handleRefreshInvoices}>
            Refresh
            <i className="ml-1 mdi mdi-sync" />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          {unimportedIntegrationInvoices.invoicesSearchTotal >= 0 && (
            <span className="text-muted">
              {unimportedIntegrationInvoices.invoicesSearchTotal}/{unimportedIntegrationInvoices.invoicesTotal} matches
            </span>
          )}
        </Col>
      </Row>
    </>
  )

  const isPending = unimportedInvoicesRequestState.isPending || mutationState.isPending

  const actionButtonRight = (
    <Button
      onClick={handleSubmit}
      disabled={isPending || invoicesToImport.length === 0}
      color="primary"
      className="mb-2 w-100"
    >
      Create New Transactions
    </Button>
  )

  const rotessaInvoices = () => {
    return invoicesToImport.concat(importedInvoicesList)
  }

  const handleClickAcknowledge = () => {
    history.push(`/client/customers`)
  }

  return (
    <>
      <Modal isOpen={responseStatus === 200}>
        <ModalHeader>{invoiceImportedTotal} Invoices Imported</ModalHeader>
        <ModalBody>
          <p>The selected invoices have been imported successfully.</p>
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={handleClickAcknowledge}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <TitleBar title={`Import invoices from ${integrationName(activeClient?.integration)}`} />
      <Row>
        <Col>
          {responseStatus !== 200 && serverErrors && <FormServerErrors errors={serverErrors || []} />}
          <SideBySideTables
            loadingLeft={isPending || loading}
            loadingRight={isPending || loading}
            displayLoadingMaskLeft={true}
            displayLoadingMaskRight={true}
            titleLeft={`NEW INVOICES FOUND IN ${integrationName(activeClient?.integration)}`}
            titleRight={`${integrationName(activeClient?.integration)} TRANSACTIONS IN ROTESSA`}
            dataLeft={unimportedInvoicesList}
            dataRight={rotessaInvoices()}
            columnsLeft={columnsLeft}
            columnsRight={columnsRight}
            sortFieldLeft={tableProps.sortField}
            sortFieldRight={tableProps.sortField}
            sortOrderLeft={tableProps.sortOrder}
            sortOrderRight={tableProps.sortOrder}
            onTableChangeLeft={onTableChange}
            onTableChangeRight={onTableChange}
            selectionPropsLeft={{
              mode: 'checkbox',
              clickToSelect: true,
              onSelectionChange: () => {},
              onSelect: onSelectLeft,
              onSelectAll: onSelectAllLeft,
              selected: invoicesToImport,
              selectColumnStyle: {
                verticalAlign: 'middle',
              },
              nonSelectable: getNonSelectableRows(),
              nonSelectableClasses: 'non-selectable-integration-row',
            }}
            scrollBody
            scrollBodyHeight={'calc(100vh - 300px)'}
            onRowClickLeft={() => {}}
            headerLeft={tableSearch}
            headerRight={<Row className="mb-2" />}
            footerLeft={
              unimportedIntegrationInvoices.minDate &&
              unimportedIntegrationInvoices.maxDate && (
                <Row className="align-items-center justify-content-start mb-2">
                  <Col xs="auto">
                    <Form inline>
                      <Label className="mr-2">Invoices from</Label>
                      <RotessaDatepicker
                        hideAddon
                        onChange={handleStartDateSubmit}
                        minDate={unimportedIntegrationInvoices.minDate}
                        maxDate={unimportedIntegrationInvoices.maxDate}
                      />
                      <Label className="ml-2">to</Label>
                      <RotessaDatepicker
                        hideAddon
                        onChange={handleEndDateSubmit}
                        startDate={unimportedIntegrationInvoices.maxDate}
                        minDate={unimportedIntegrationInvoices.minDate}
                        maxDate={unimportedIntegrationInvoices.maxDate}
                      />
                    </Form>
                  </Col>
                </Row>
              )
            }
            footerRight={actionButtonRight}
          />
        </Col>
      </Row>
    </>
  )
}
