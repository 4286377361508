// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface SignIn200Response
 */
export interface SignIn200Response  {
    /**
     * 
     * @type {User}
     * @memberof SignIn200Response
     */
    data?: User;
}

export function SignIn200ResponseFromJSON(json: any): SignIn200Response {
    return {
        'data': !exists(json, 'data') ? undefined : UserFromJSON(json['data']),
    };
}

export function SignIn200ResponseToJSON(value?: SignIn200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'data': UserToJSON(value.data),
    };
}


