import React from 'react'

import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import OutsideClickHandler from 'react-outside-click-handler'

import { AppMenu } from 'features/menu/AppMenu'

import logo from 'assets/images/logo.png'
import { ClientDropdown } from 'features/menu/clientDropdown'
import { Announcement } from 'features/announcements/announcement'

export const LeftSidebar = () => {
  const enableAnnouncement = true

  return (
    <OutsideClickHandler
      onOutsideClick={(e) => {
        if (document.body && document.body.classList.contains('sidebar-enable') && e.target.id !== 'mobile-menu-open') {
          document.body.classList.remove('sidebar-enable')
        }
      }}
    >
      <div className="left-side-menu">
        <Link to="/" className="logo text-center logo-light">
          <span className="logo logo-lg">
            <img src={logo} alt="logo" height="20" />
          </span>
        </Link>

        <ClientDropdown />

        <SimpleBar style={{ maxHeight: 'calc(100% - 75px)' }} timeout={500} scrollbarMaxSize={320} autoHide={false}>
          <AppMenu />
          {enableAnnouncement && <Announcement />}
        </SimpleBar>
      </div>
    </OutsideClickHandler>
  )
}
