// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchClients200Response
 */
export interface SearchClients200Response  {
    /**
     * 
     * @type {number}
     * @memberof SearchClients200Response
     */
    count?: number;
    /**
     * 
     * @type {Array<Client>}
     * @memberof SearchClients200Response
     */
    records?: Array<Client>;
}

export function SearchClients200ResponseFromJSON(json: any): SearchClients200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(ClientFromJSON),
    };
}

export function SearchClients200ResponseToJSON(value?: SearchClients200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(ClientToJSON),
    };
}


