// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerActivity,
    CustomerActivityFromJSON,
    CustomerActivityToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchCustomerActivity200Response
 */
export interface SearchCustomerActivity200Response  {
    /**
     * 
     * @type {number}
     * @memberof SearchCustomerActivity200Response
     */
    count?: number;
    /**
     * 
     * @type {Array<CustomerActivity>}
     * @memberof SearchCustomerActivity200Response
     */
    records?: Array<CustomerActivity>;
}

export function SearchCustomerActivity200ResponseFromJSON(json: any): SearchCustomerActivity200Response {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<any>).map(CustomerActivityFromJSON),
    };
}

export function SearchCustomerActivity200ResponseToJSON(value?: SearchCustomerActivity200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'records': value.records === undefined ? undefined : (value.records as Array<any>).map(CustomerActivityToJSON),
    };
}


