import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'

export interface ColumnProps {
  dataField: string
  formatter?: (cell: any, row?: any) => any
  text: string
  align?: string
}

export interface ImportPreviewTableProps {
  loading: boolean
  data: any[]
  columns: ColumnProps[]
  keyField?: string
  mode: string
  clickToSelect: boolean
  setSelectedRows: Function
  selectedRows: any[]
  nonSelectable: number[]
}

export function ImportPreviewTable({
  loading,
  data,
  columns,
  keyField,
  mode,
  clickToSelect,
  setSelectedRows,
  selectedRows,
  nonSelectable,
}: ImportPreviewTableProps) {
  const realKeyField = keyField || 'id'
  const selectRow = {
    mode: mode,
    clickToSelect: clickToSelect,
    onSelect: onSelect,
    onSelectAll: onSelectAll,
    nonSelectable: nonSelectable,
    nonSelectableClasses: 'non-selectable-row',
  }

  function onSelect(row, isSelected) {
    if (isSelected) {
      if (!selectedRows.includes(row)) {
        setSelectedRows([...selectedRows, row])
      }
    } else {
      if (selectedRows.includes(row)) {
        const filter = selectedRows.filter((data) => {
          return data[realKeyField] !== row[realKeyField]
        })
        setSelectedRows(filter)
      }
    }
  }

  function onSelectAll(isSelected, rows) {
    setSelectedRows(isSelected ? rows : [])
  }

  return (
    <BootstrapTable
      bootstrap4
      condensed
      hover
      remote
      bordered={false}
      keyField={realKeyField}
      loading={loading}
      data={data}
      columns={columns}
      wrapperClasses="table-responsive"
      selectRow={selectRow}
    />
  )
}
