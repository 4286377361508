// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MicrodepositVerification
 */
export interface MicrodepositVerification  {
    /**
     * 
     * @type {string}
     * @memberof MicrodepositVerification
     */
    formUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof MicrodepositVerification
     */
    amountOneAttempt?: number;
    /**
     * 
     * @type {number}
     * @memberof MicrodepositVerification
     */
    amountTwoAttempt?: number;
    /**
     * 
     * @type {string}
     * @memberof MicrodepositVerification
     */
    language?: string;
}

export function MicrodepositVerificationFromJSON(json: any): MicrodepositVerification {
    return {
        'formUrl': !exists(json, 'form_url') ? undefined : json['form_url'],
        'amountOneAttempt': !exists(json, 'amount_one_attempt') ? undefined : json['amount_one_attempt'],
        'amountTwoAttempt': !exists(json, 'amount_two_attempt') ? undefined : json['amount_two_attempt'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function MicrodepositVerificationToJSON(value?: MicrodepositVerification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'form_url': value.formUrl,
        'amount_one_attempt': value.amountOneAttempt,
        'amount_two_attempt': value.amountTwoAttempt,
        'language': value.language,
    };
}


