// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    BankAccount,
    BankAccountFromJSON,
    BankAccountToJSON,
    CustomerNotificationSettings,
    CustomerNotificationSettingsFromJSON,
    CustomerNotificationSettingsToJSON,
    CustomerPayment,
    CustomerPaymentFromJSON,
    CustomerPaymentToJSON,
    CustomerPermissionForm,
    CustomerPermissionFormFromJSON,
    CustomerPermissionFormToJSON,
    CustomerStatuses,
    CustomerStatusesFromJSON,
    CustomerStatusesToJSON,
} from './';

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer  {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Customer
     */
    status?: Array<string>;
    /**
     * 
     * @type {CustomerPayment}
     * @memberof Customer
     */
    nextPayment?: CustomerPayment;
    /**
     * 
     * @type {CustomerPayment}
     * @memberof Customer
     */
    lastPayment?: CustomerPayment;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    bankVerification?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    phone?: string;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    customIdentifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    apiCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    apiService?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    linkedToApiService?: boolean;
    /**
     * 
     * @type {CustomerPermissionForm}
     * @memberof Customer
     */
    permissionForm?: CustomerPermissionForm;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    customerType?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    authorizationType?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    authorizationAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    autoImportInvoices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    autoUpdate?: boolean;
    /**
     * 
     * @type {Address}
     * @memberof Customer
     */
    address?: Address;
    /**
     * 
     * @type {BankAccount}
     * @memberof Customer
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {CustomerStatuses}
     * @memberof Customer
     */
    statuses?: CustomerStatuses;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    requiredAction?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Customer
     */
    notifications?: Array<string>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Customer
     */
    addressesAttributes?: Array<Address>;
    /**
     * 
     * @type {CustomerNotificationSettings}
     * @memberof Customer
     */
    notificationSettings?: CustomerNotificationSettings;
}

export function CustomerFromJSON(json: any): Customer {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'nextPayment': !exists(json, 'next_payment') ? undefined : CustomerPaymentFromJSON(json['next_payment']),
        'lastPayment': !exists(json, 'last_payment') ? undefined : CustomerPaymentFromJSON(json['last_payment']),
        'bankVerification': !exists(json, 'bank_verification') ? undefined : json['bank_verification'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'homePhone': !exists(json, 'home_phone') ? undefined : json['home_phone'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'customIdentifier': !exists(json, 'custom_identifier') ? undefined : json['custom_identifier'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'apiCustomerId': !exists(json, 'api_customer_id') ? undefined : json['api_customer_id'],
        'apiService': !exists(json, 'api_service') ? undefined : json['api_service'],
        'linkedToApiService': !exists(json, 'linked_to_api_service') ? undefined : json['linked_to_api_service'],
        'permissionForm': !exists(json, 'permission_form') ? undefined : CustomerPermissionFormFromJSON(json['permission_form']),
        'customerType': !exists(json, 'customer_type') ? undefined : json['customer_type'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'authorizationType': !exists(json, 'authorization_type') ? undefined : json['authorization_type'],
        'authorizationAmount': !exists(json, 'authorization_amount') ? undefined : json['authorization_amount'],
        'autoImportInvoices': !exists(json, 'auto_import_invoices') ? undefined : json['auto_import_invoices'],
        'autoUpdate': !exists(json, 'auto_update') ? undefined : json['auto_update'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'bankAccount': !exists(json, 'bank_account') ? undefined : BankAccountFromJSON(json['bank_account']),
        'statuses': !exists(json, 'statuses') ? undefined : CustomerStatusesFromJSON(json['statuses']),
        'requiredAction': !exists(json, 'required_action') ? undefined : json['required_action'],
        'notifications': !exists(json, 'notifications') ? undefined : json['notifications'],
        'addressesAttributes': !exists(json, 'addresses_attributes') ? undefined : (json['addresses_attributes'] as Array<any>).map(AddressFromJSON),
        'notificationSettings': !exists(json, 'notification_settings') ? undefined : CustomerNotificationSettingsFromJSON(json['notification_settings']),
    };
}

export function CustomerToJSON(value?: Customer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'status': value.status,
        'next_payment': CustomerPaymentToJSON(value.nextPayment),
        'last_payment': CustomerPaymentToJSON(value.lastPayment),
        'bank_verification': value.bankVerification,
        'name': value.name,
        'identifier': value.identifier,
        'email': value.email,
        'home_phone': value.homePhone,
        'phone': value.phone,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'client_id': value.clientId,
        'custom_identifier': value.customIdentifier,
        'active': value.active,
        'api_customer_id': value.apiCustomerId,
        'api_service': value.apiService,
        'linked_to_api_service': value.linkedToApiService,
        'permission_form': CustomerPermissionFormToJSON(value.permissionForm),
        'customer_type': value.customerType,
        'country_code': value.countryCode,
        'authorization_type': value.authorizationType,
        'authorization_amount': value.authorizationAmount,
        'auto_import_invoices': value.autoImportInvoices,
        'auto_update': value.autoUpdate,
        'address': AddressToJSON(value.address),
        'bank_account': BankAccountToJSON(value.bankAccount),
        'statuses': CustomerStatusesToJSON(value.statuses),
        'required_action': value.requiredAction,
        'notifications': value.notifications,
        'addresses_attributes': value.addressesAttributes === undefined ? undefined : (value.addressesAttributes as Array<any>).map(AddressToJSON),
        'notification_settings': CustomerNotificationSettingsToJSON(value.notificationSettings),
    };
}


