// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HomepageTransactionsChartDataInner,
    HomepageTransactionsChartDataInnerFromJSON,
    HomepageTransactionsChartDataInnerToJSON,
} from './';

/**
 * 
 * @export
 * @interface Home200Response
 */
export interface Home200Response  {
    /**
     * 
     * @type {Date}
     * @memberof Home200Response
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Home200Response
     */
    endDate?: Date;
    /**
     * 
     * @type {Array<HomepageTransactionsChartDataInner>}
     * @memberof Home200Response
     */
    approvedTransactions?: Array<HomepageTransactionsChartDataInner>;
    /**
     * 
     * @type {Array<HomepageTransactionsChartDataInner>}
     * @memberof Home200Response
     */
    declinedTransactions?: Array<HomepageTransactionsChartDataInner>;
    /**
     * 
     * @type {Array<HomepageTransactionsChartDataInner>}
     * @memberof Home200Response
     */
    futureTransactions?: Array<HomepageTransactionsChartDataInner>;
    /**
     * 
     * @type {Array<HomepageTransactionsChartDataInner>}
     * @memberof Home200Response
     */
    pendingTransactions?: Array<HomepageTransactionsChartDataInner>;
    /**
     * 
     * @type {Array<HomepageTransactionsChartDataInner>}
     * @memberof Home200Response
     */
    sentToBankTransactions?: Array<HomepageTransactionsChartDataInner>;
    /**
     * 
     * @type {Array<HomepageTransactionsChartDataInner>}
     * @memberof Home200Response
     */
    processedTransactions?: Array<HomepageTransactionsChartDataInner>;
    /**
     * 
     * @type {Array<HomepageTransactionsChartDataInner>}
     * @memberof Home200Response
     */
    settlementTransactions?: Array<HomepageTransactionsChartDataInner>;
    /**
     * 
     * @type {number}
     * @memberof Home200Response
     */
    transactionsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Home200Response
     */
    customersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Home200Response
     */
    transactionsSum?: number;
    /**
     * 
     * @type {number}
     * @memberof Home200Response
     */
    declinesSum?: number;
}

export function Home200ResponseFromJSON(json: any): Home200Response {
    return {
        'startDate': !exists(json, 'start_date') ? undefined : new Date(json['start_date']),
        'endDate': !exists(json, 'end_date') ? undefined : new Date(json['end_date']),
        'approvedTransactions': !exists(json, 'approved_transactions') ? undefined : (json['approved_transactions'] as Array<any>).map(HomepageTransactionsChartDataInnerFromJSON),
        'declinedTransactions': !exists(json, 'declined_transactions') ? undefined : (json['declined_transactions'] as Array<any>).map(HomepageTransactionsChartDataInnerFromJSON),
        'futureTransactions': !exists(json, 'future_transactions') ? undefined : (json['future_transactions'] as Array<any>).map(HomepageTransactionsChartDataInnerFromJSON),
        'pendingTransactions': !exists(json, 'pending_transactions') ? undefined : (json['pending_transactions'] as Array<any>).map(HomepageTransactionsChartDataInnerFromJSON),
        'sentToBankTransactions': !exists(json, 'sent_to_bank_transactions') ? undefined : (json['sent_to_bank_transactions'] as Array<any>).map(HomepageTransactionsChartDataInnerFromJSON),
        'processedTransactions': !exists(json, 'processed_transactions') ? undefined : (json['processed_transactions'] as Array<any>).map(HomepageTransactionsChartDataInnerFromJSON),
        'settlementTransactions': !exists(json, 'settlement_transactions') ? undefined : (json['settlement_transactions'] as Array<any>).map(HomepageTransactionsChartDataInnerFromJSON),
        'transactionsCount': !exists(json, 'transactions_count') ? undefined : json['transactions_count'],
        'customersCount': !exists(json, 'customers_count') ? undefined : json['customers_count'],
        'transactionsSum': !exists(json, 'transactions_sum') ? undefined : json['transactions_sum'],
        'declinesSum': !exists(json, 'declines_sum') ? undefined : json['declines_sum'],
    };
}

export function Home200ResponseToJSON(value?: Home200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'start_date': value.startDate === undefined ? undefined : value.startDate.toISOString().substring(0,10),
        'end_date': value.endDate === undefined ? undefined : value.endDate.toISOString().substring(0,10),
        'approved_transactions': value.approvedTransactions === undefined ? undefined : (value.approvedTransactions as Array<any>).map(HomepageTransactionsChartDataInnerToJSON),
        'declined_transactions': value.declinedTransactions === undefined ? undefined : (value.declinedTransactions as Array<any>).map(HomepageTransactionsChartDataInnerToJSON),
        'future_transactions': value.futureTransactions === undefined ? undefined : (value.futureTransactions as Array<any>).map(HomepageTransactionsChartDataInnerToJSON),
        'pending_transactions': value.pendingTransactions === undefined ? undefined : (value.pendingTransactions as Array<any>).map(HomepageTransactionsChartDataInnerToJSON),
        'sent_to_bank_transactions': value.sentToBankTransactions === undefined ? undefined : (value.sentToBankTransactions as Array<any>).map(HomepageTransactionsChartDataInnerToJSON),
        'processed_transactions': value.processedTransactions === undefined ? undefined : (value.processedTransactions as Array<any>).map(HomepageTransactionsChartDataInnerToJSON),
        'settlement_transactions': value.settlementTransactions === undefined ? undefined : (value.settlementTransactions as Array<any>).map(HomepageTransactionsChartDataInnerToJSON),
        'transactions_count': value.transactionsCount,
        'customers_count': value.customersCount,
        'transactions_sum': value.transactionsSum,
        'declines_sum': value.declinesSum,
    };
}


