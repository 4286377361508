// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddCustomer200Response
 */
export interface AddCustomer200Response  {
    /**
     * 
     * @type {number}
     * @memberof AddCustomer200Response
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof AddCustomer200Response
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AddCustomer200Response
     */
    messages?: { [key: string]: string; };
}

export function AddCustomer200ResponseFromJSON(json: any): AddCustomer200Response {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function AddCustomer200ResponseToJSON(value?: AddCustomer200Response): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'id': value.id,
        'messages': value.messages,
    };
}


